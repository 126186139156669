import React from 'react';

import styles from './DealPageLeaderboard.module.css';
import sparks from './../../images/sparks.svg'

const DealPageLeaderboard = () => (
  <div className={styles.DealPageLeaderboard}>
   
<div className={styles.leaderboardcontainer}>

<div className={styles.rewardslevelcontainer}>
<img src={sparks}/>
<div className={styles.rewardslevelholder} >
  <div className={styles.rewardslevel}>rewards level</div>
  <div className={styles.rewardslevelname}>Vespa</div>
</div>

</div>
<div className={styles.interstitial}>•</div>
<div>
    <div className={styles.rewardsgenericcontainer}>
      <div className={styles.rewardsgenericamount}>$100+</div>
      <div className={styles.rewardsgenericdescription}>My Savings</div>
    </div>
</div>
<div className={styles.interstitial}>•</div>
<div>
<div className={styles.rewardsgenericcontainer}>
      <div className={styles.rewardsgenericamount}>700+</div>
      <div className={styles.rewardsgenericdescription}>My Points</div>
    </div>

</div>


   </div>


  </div>
);



export default DealPageLeaderboard;
