import React from 'react';

import styles from './DealPageLoginTop.module.css';
import arrow from '../../images/arrow2.svg'

const DealPageLoginTop = () => (
  <div className={styles.DealPageLoginTop}>
    <div>

    Login to see your exclusive deals.
    </div>
    <div>
      <img src={arrow}/>
    </div>
   
  </div>
);



export default DealPageLoginTop;
