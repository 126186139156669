import React from 'react';
import PropTypes from 'prop-types';
import styles from './BrandingHeader.module.css';
import DealPageMenu from '../DealPageMenu/DealPageMenu';
import BrandingProfilePic from '../BrandingProfilePic/BrandingProfilePic';


const displayCallButton=(props)=>{
  if(props.callbutton == 'true'){
    return(
      <>
      <div>call</div>
      <div>url </div>
      </>
    )
  } else {
  }
}

const BrandingHeader = (props) => (
  <>
    <div className='brandingHolder'>
        <div className='brandingProfilePicHolder'>
          <img className='brandingProfilePic' src={props.merchantimage} ></img>
        </div>
    
        <div>
          <div className="brandingHeader">
           { props.merchantname}  
          </div>
      
          <div className='handlename'>
            @{ props.merchanthandle}
          </div>
        </div>
        {props.merchantMenu && 
        (<DealPageMenu merchantMenu = {props.merchantMenu}/>)
        }
        
        <div>
        {displayCallButton(props)}
        </div>
        
</div>
<hr style={{ border: '0', height: '0.0625rem', backgroundColor: '#d9d9d9' }}></hr>
  </>
);

BrandingHeader.propTypes = {};

export default BrandingHeader;



