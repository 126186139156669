import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './CustomerWall.module.css';
import { customerprofiles } from '../../utils/customerprofiles';

const CustomerWall = () => {
    const [merchants, setMerchants] = useState([]);
    const [filterName, setFilterName] = useState('');

    useEffect(() => {
        const transformedMerchants = customerprofiles.flatMap(profile => {
            return Object.entries(profile).map(([merchantName, { info }]) => ({
                merchantName,
                ...info
            }));
        });
        setMerchants(transformedMerchants);
    }, []);

    const handleNameFilterChange = (event) => {
        setFilterName(event.target.value);
    };

    const filteredMerchants = merchants.filter(merchant =>
        filterName === '' || merchant.merchantName.toLowerCase().includes(filterName.toLowerCase())
    );

    return (
        <div className={styles.CustomerWall}>
            <h1>Merchant Profiles</h1>

            <div>
                <label>
                    Filter by Company Name:
                    <input type="text" value={filterName} onChange={handleNameFilterChange} placeholder="Enter company name" />
                </label>
                <p>Results: {filteredMerchants.length}</p>
            </div>

            <div>
                {filteredMerchants.map((merchant, index) => (
                    <div key={index} className={styles.MerchantCard}>
                        <img src={merchant.merchantImage} className={styles.MerchantImage} alt={merchant.merchantName} />
                        <div>
                            <div>
                                <a href={merchant.websiteUrl} target="_blank" rel="noopener noreferrer">
                                    <h5>{merchant.merchantName} - [{merchant.websiteUrl}]</h5>
                                </a>
                            </div>
                            <p><strong>City:</strong> {merchant.city}, {merchant.state}</p>
                            <p><strong>Phone:</strong> {merchant.phone}</p>
                            <p><strong>Cafe.Bio Handle:</strong> 
                                <a href={`https://www.cafe.bio/${merchant.merchantHandle}`} target="_blank" rel="noopener noreferrer">
                                    https://www.cafe.bio/{merchant.merchantHandle}
                                </a>
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

CustomerWall.propTypes = {};

CustomerWall.defaultProps = {};

export default CustomerWall;
