import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { dbref, dbOnValue, database } from "../../objects/dataobject";
import styles from "./insightsScreen.module.css";
import { basePath } from "../../utils/sessions-constants";
import { AppContext } from "../../AppContext";
import { Divider, Typography, Table } from "antd";
import PromoItem from "../promoItem/promoItem";

function InsightsTable() {
  const [sessions, setSessions] = useState([]);
  const [promos, setPromos] = useState([]);
  const [selectedPromoDetails, setSelectedPromoDetails] = useState(null);
  const { appMerchantHandle } = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    getAllRedemptions(appMerchantHandle);
  }, [appMerchantHandle]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const promoId = queryParams.get("promoId");
    const selectedPromo = promos.find((promo) => promo.promoId === promoId);
    setSelectedPromoDetails(selectedPromo || null);

    // Scroll to the selected promo item
    const promoItem = document.getElementById(`promoItem-${promoId}`);
    if (promoItem) {
      promoItem.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, [location.search, promos]);

  const getAllRedemptions = (merchant) => {
    const tempSessionsArray = [];
    const tempPromosArray = [];

    const promosRef = dbref(database, basePath(merchant) + "/promos/");

    dbOnValue(promosRef, (snapshot) => {
      snapshot.forEach((promo) => {
        const promoId = promo.key;
        const redemptionRef = dbref(
          database,
          basePath(merchant) + `/promos/${promoId}/redemptions`
        );

        dbOnValue(redemptionRef, (redemptionSnapshot) => {
          redemptionSnapshot.forEach((redemption) => {
            tempSessionsArray.push({
              ...redemption.val(),
              key: `${promoId}-${redemption.key}`,
              promoId: promoId,
            });
          });

          tempPromosArray.push({ promoId, ...promo.val() });
        });
      });
      console.log("promos: ", tempPromosArray);
      setPromos(tempPromosArray);
      setSessions(tempSessionsArray);
    });
  };

  const handlePromoItemClick = (promoId, promoDetails) => {
    const queryParams = new URLSearchParams(location.search);
    const currentPromoId = queryParams.get("promoId");
    if (currentPromoId !== promoId) {
      setSelectedPromoDetails(promoDetails);
      queryParams.set("promoId", promoId);
      navigate(`${location.pathname}?${queryParams.toString()}`);
    }
  };

  const filteredSessions = selectedPromoDetails
    ? sessions.filter(
        (session) => session.promoId === selectedPromoDetails.promoId
      )
    : sessions;

  const columns = [
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      className: styles.phoneCell,
    },
    {
      title: "Redemption Date",
      dataIndex: "redeemedAt",
      key: "redeemedAt",
    },
    {
      title: "Visit Date",
      dataIndex: "visitDate",
      key: "visitDate",
    },
    {
      title: "Visit Time",
      dataIndex: "visitTime",
      key: "visitTime",
    },
    {
      title: "New Customer",
      dataIndex: "isNewUser",
      key: "isNewUser",
      render: (isNewUser) => <span>{isNewUser ? "Yes" : "No"}</span>,
    },
  ];

  const { Text, Title } = Typography;

  return (
    <div className={styles.sessionsContainer}>
      <Divider />
      <div className={styles.promosAndTableContainer}>
        <div className={styles.promosContainer}>
          <Title className={styles.title} level={4}>
            Campaigns
          </Title>
          {promos.map((promo) => (
            <div
              key={promo.promoId}
              onClick={() => handlePromoItemClick(promo.promoId, promo)}
            >
              <PromoItem
                trackAnalytics="true"
                wholepromo={promo}
                key={promo.promoname}
                promoimageheader={promo.style.imagename}
                promotitle={promo.promotitle}
                promomessage={promo.promomessage}
                promobuttontext={promo.promobuttontext}
                promoprimarycolor={promo.style.primarycolor}
                promoname={promo.promoname}
                promosecondarycolor={promo.style.secondarycolor}
                merchanthandle={promo.merchantname}
                messagecolor={promo.style.secondarycolor}
                disable="false"
                promotype={promo.style.promotype}
              />
            </div>
          ))}
        </div>
        <div className={styles.tableContainer}>
          {selectedPromoDetails && (
            <div>
              <Text className={styles.title}>
                {selectedPromoDetails.campaign_name}
              </Text>
              <br />
              <Text className={styles.subtitle}>
                {selectedPromoDetails.promotitle}
              </Text>
              <br />
              <Text>{selectedPromoDetails.promomessage}</Text>
              <br />
              <Text>Start: {selectedPromoDetails.datestart}</Text>
              <br />
              <Text>End: {selectedPromoDetails.dateend}</Text>
              <Divider />
            </div>
          )}
          <div className={styles.title}>
            <Text strong>Redemptions</Text>
          </div>
          <Table columns={columns} dataSource={filteredSessions} />
        </div>
      </div>
    </div>
  );
}

export default InsightsTable;
