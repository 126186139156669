export const customerprofiles = [
{
  "hhfreshfish" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(831) 462-3474",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Nl1C4R2oNYbfBnbdJFa7KXfBZ0g3HKac.png",
      "instagramHandle" : "hhfreshfish",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "hhfreshfish",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "H&H Fresh Fish",
      "city" : "San Jose",
      "companyName" : "H&H Fresh Fish",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.hhfreshfish.com\/?utm_source=google&utm_medium=gmb&utm_campaign=business%20card",
      "email" : ""
    }
  },
  "casablancacaf" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 917-5778",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/2ce118a14111a21e29d4834b3fd4147ab4a0f352\/original.png",
      "instagramHandle" : "casablancacaf",
      "twitterHandle" : "",
      "merchantHandle" : "casablancacaf",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Casablanca Café",
      "city" : "Sacramento",
      "companyName" : "Casablanca Café",
      "websiteUrl" : "http:\/\/www.casablancacaters.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "theshop" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(509) 534-1647",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/37f16e5b6c37efd8c4450c482872dbc2\/original.jpeg",
      "instagramHandle" : "theshop",
      "merchantHandle" : "theshop",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Shop",
      "city" : "Spokane",
      "companyName" : "The Shop",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/m.facebook.com\/The-Shop-234156503367737\/",
      "email" : ""
    }
  },
  "tamaleselkora" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(616) 427-3616",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/89PGh09eMqgGdTLzaBYaU0e0P3L9y8GU.png",
      "instagramHandle" : "tamaleselkora",
      "merchantHandle" : "tamaleselkora",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Tamales El Kora",
      "city" : "Grand Rapids",
      "companyName" : "Tamales El Kora",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "trademarklegion" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(833) 333-8891",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/BenWq8HiYQLWT0aHH7Ve6gp5lErtUCX0.png",
      "instagramHandle" : "trademarklegion",
      "premium" : false,
      "merchantHandle" : "trademarklegion",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Trademark Legion",
      "city" : "San Francisco",
      "companyName" : "Trademark Legion",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "dinosaurcoffee" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(323) 284-8544",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/2c8afc1c517cdd849ef4c153c555782f\/original.jpeg",
      "instagramHandle" : "dinosaurcoffee",
      "premium" : false,
      "merchantHandle" : "dinosaurcoffee",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Dinosaur Coffee",
      "city" : "Glendale",
      "companyName" : "Dinosaur Coffee",
      "websiteUrl" : "http:\/\/www.dinosaurcoffee.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "nidosbackyard" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(510) 500-9757",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Z2fDk3oVe6Hwkwh9Yh1kT4m2QlpXs3Mf.png",
      "instagramHandle" : "nidosbackyard",
      "merchantHandle" : "nidosbackyard",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "NIDO's BackYard",
      "city" : "Oakland",
      "companyName" : "NIDO's BackYard",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.backyardoakland.com\/",
      "email" : ""
    }
  },
  "clayparlormidtownstudio" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(805) 600-5262",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/0535f0e7ec8e33151cb17cfbc57758eb93921945\/original.png",
      "instagramHandle" : "clayparlormidtownstudio",
      "merchantHandle" : "clayparlormidtownstudio",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Clay Parlor-Midtown Studio",
      "city" : "Oxnard",
      "companyName" : "Clay Parlor-Midtown Studio",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/clayparlor.com\/",
      "email" : ""
    }
  },
  "froghollerfarm" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(517) 592-8017",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/VXk0okwbsO31bi20haL4IuW7zS7IUBMo.png",
      "instagramHandle" : "froghollerfarm",
      "twitterHandle" : "",
      "merchantHandle" : "froghollerfarm",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Frog Holler Farm",
      "city" : "Ann Arbor",
      "companyName" : "Frog Holler Farm",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/froghollerorganic.com\/",
      "email" : ""
    }
  },
  "energysystems" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(800) 845-8519",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/hIqzyIrmeiKIxc9ihH5Cn71uReRb2DBT.png",
      "instagramHandle" : "energysystems",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "energysystems",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Energy Systems",
      "city" : "Stockton",
      "companyName" : "Energy Systems",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/energysystems.com\/",
      "email" : ""
    }
  },
  "lionheartla" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(949) 378-9805",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Y4INb2tHAc7QuqHPCT1Xne9CLb6CdajO.png",
      "instagramHandle" : "lionheartla",
      "merchantHandle" : "lionheartla",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "LionHeartLA",
      "city" : "Irvine",
      "companyName" : "LionHeartLA",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "jpatricechocolatestudio" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(734) 510-4499",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ewnO4C9PT1x7SFJ7oz9kHXPhtDdI5yZh.png",
      "instagramHandle" : "jpatricechocolatestudio",
      "merchantHandle" : "jpatricechocolatestudio",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "J Patrice chocolate Studio",
      "city" : "Ann Arbor",
      "companyName" : "J Patrice chocolate Studio",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/jpatricechocolatestudio.com\/",
      "email" : ""
    }
  },
  "nicowine" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/b05702c4c7f25f83c00516169162d62307de48b3\/original.png",
      "instagramHandle" : "nicowine",
      "merchantHandle" : "nicowine",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Nico Wine",
      "city" : "Sacramento",
      "companyName" : "Nico Wine",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/nicowine.co\/",
      "email" : ""
    }
  },
  "savortheflavor" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(616) 375-5564",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Q15zoWhqNusoBut1t061Kiqa0fGLmapV.png",
      "instagramHandle" : "savortheflavor",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "savortheflavor",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Savor The Flavor",
      "city" : "Grand Rapids",
      "companyName" : "Savor The Flavor",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.savortheflavorllc.com\/",
      "email" : ""
    }
  },
  "olivegrillcafe" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(323) 522-6227",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/eyl4hGTKgZM9H5fKbHWemP5g4s5CcOOl.png",
      "instagramHandle" : "olivegrillcafe",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "olivegrillcafe",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "OLIVE GRILL & CAFE",
      "city" : "Glendale",
      "companyName" : "OLIVE GRILL & CAFE",
      "websiteUrl" : "https:\/\/www.olivegrillcafe.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "northeastsisters" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(949) 735-8885",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/0sVKw7cfZiAWNFhw3qNjsH97akJYp9Ub.png",
      "instagramHandle" : "northeastsisters",
      "merchantHandle" : "northeastsisters",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Northeast Sisters",
      "city" : "Santa Ana",
      "companyName" : "Northeast Sisters",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/northeastsisters.square.site\/",
      "email" : ""
    }
  },
  "tacollynn" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(877) 490-7625",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/759712e96cfd70b9f0131a2bf3e725b895ff7c68\/original.jpeg",
      "instagramHandle" : "tacollynn",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "tacollynn",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "TACOLLYNN",
      "city" : "Riverside",
      "companyName" : "TACOLLYNN",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.tacollynn.com\/",
      "email" : ""
    }
  },
  "evergreendonut" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(408) 274-1585",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "evergreendonut",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "evergreendonut",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Evergreen Donut",
      "city" : "San Jose",
      "companyName" : "Evergreen Donut",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "newdelhiindianrestaurant" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 397-8470",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/OVnVpNYWpSaCRPYiYBHKaBh5N0xXHwMC.png",
      "instagramHandle" : "newdelhiindianrestaurant",
      "twitterHandle" : "",
      "merchantHandle" : "newdelhiindianrestaurant",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "New Delhi Indian Restaurant",
      "city" : "San Francisco",
      "companyName" : "New Delhi Indian Restaurant",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/newdelhirestaurant.com\/",
      "email" : ""
    }
  },
  "jaijus" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(619) 794-0021",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/4fvXnrp5yHaFwGS1gCKeyvIEi2JUDoOd.png",
      "instagramHandle" : "jaijus",
      "twitterHandle" : "",
      "merchantHandle" : "jaijus",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Jai Jus",
      "city" : "Chula Vista",
      "companyName" : "Jai Jus",
      "websiteUrl" : "https:\/\/jaijus.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "flameskewersmediterraneanrestaurant" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(661) 328-1400",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/17526ab38624a4779c87fafe0a95c4e7af53add4\/original.jpeg",
      "instagramHandle" : "flameskewersmediterraneanrestaurant",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "flameskewersmediterraneanrestaurant",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Flame & Skewers Mediterranean Restaurant",
      "city" : "Bakersfield",
      "companyName" : "Flame & Skewers Mediterranean Restaurant",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/flameandskewers.com\/",
      "email" : ""
    }
  },
  "kobycraftsmen" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(734) 891-9474",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/1bc006212900787b4809f0d8ac8289d778f620b8\/original.png",
      "instagramHandle" : "kobycraftsmen",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "kobycraftsmen",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Koby Craftsmen",
      "city" : "Ann Arbor",
      "companyName" : "Koby Craftsmen",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.kobycraftsmen.com\/",
      "email" : ""
    }
  },
  "mocksbottomcafe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(360) 947-4536",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/28fa7c6e0e41f065d4a17edcfb909a26\/original.jpeg",
      "instagramHandle" : "mocksbottomcafe",
      "merchantHandle" : "mocksbottomcafe",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Mocks Bottom Cafe",
      "city" : "Vancouver",
      "companyName" : "Mocks Bottom Cafe",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/mocksbottomcafe.square.site\/",
      "email" : ""
    }
  },
  "bangos" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/T9Ren5eurDYzgInt0e8eImUVFO7WGxeP.png",
      "instagramHandle" : "bangos",
      "merchantHandle" : "bangos",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Bangos",
      "city" : "Lansing",
      "companyName" : "Bangos",
      "websiteUrl" : "http:\/\/www.bangosbangosbangos.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "satorisushiandteriyakigrill" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 993-9696",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "satorisushiandteriyakigrill",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "satorisushiandteriyakigrill",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Satori Sushi and Teriyaki Grill",
      "city" : "Sacramento",
      "companyName" : "Satori Sushi and Teriyaki Grill",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.satorisushiandgrill.com\/",
      "email" : ""
    }
  },
  "valleycandles" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(509) 201-7667",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/T59BMl6PgIJaeiEhiY3GFZpM2A3Ud6Rh.png",
      "instagramHandle" : "valleycandles",
      "twitterHandle" : "",
      "merchantHandle" : "valleycandles",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Valley Candles",
      "city" : "Spokane",
      "companyName" : "Valley Candles",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.valleycandlesllc.com\/",
      "email" : ""
    }
  },
  "sugarpopsweetshoppe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(562) 435-1824",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/ef2c351e931d06b3bffa9ed8104259be7e4aab80\/original.png",
      "instagramHandle" : "sugarpopsweetshoppe",
      "twitterHandle" : "",
      "merchantHandle" : "sugarpopsweetshoppe",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sugar Pop Sweet Shoppe",
      "city" : "Long Beach",
      "companyName" : "Sugar Pop Sweet Shoppe",
      "websiteUrl" : "http:\/\/sugarpopsweetshoppe.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "happytimeadultbookstore" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(951) 653-0115",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/DtGMWRyTbFvsVbMaruZm4qtmhwEqyaE1.png",
      "instagramHandle" : "happytimeadultbookstore",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "happytimeadultbookstore",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Happy Time Adult Book Store",
      "city" : "Moreno Valley",
      "companyName" : "Happy Time Adult Book Store",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "jenandjuicemobilebar" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 271-5043",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "jenandjuicemobilebar",
      "premium" : false,
      "merchantHandle" : "jenandjuicemobilebar",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "Juice Bar",
      "squareSandboxKey" : "",
      "merchantName" : "Jen and Juice Mobile Bar",
      "city" : "Anaheim",
      "companyName" : "Jen and Juice Mobile Bar",
      "websiteUrl" : "https:\/\/sippinjenandjuice.square.site\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "tarahthaikitchen" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(669) 231-4113",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ayhCLQ0VbR33pCRRfwZFGu3zeajMoxSq.png",
      "instagramHandle" : "tarahthaikitchen",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "tarahthaikitchen",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Tarah Thai Kitchen",
      "city" : "San Jose",
      "companyName" : "Tarah Thai Kitchen",
      "websiteUrl" : "http:\/\/www.tarahthai.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "elementdetroitatmetropolitan" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(313) 306-2400",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/myNG0c3J4KzxaDZS0goMvjSC82Y6gGeT.png",
      "instagramHandle" : "elementdetroitatmetropolitan",
      "twitterHandle" : "",
      "merchantHandle" : "elementdetroitatmetropolitan",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Element Detroit at the Metropolitan",
      "city" : "Detroit",
      "companyName" : "Element Detroit at the Metropolitan",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.marriott.com\/en-us\/hotels\/dtwel-element-detroit-at-the-metropolitan\/overview\/?scid=f2ae0541-1279-4f24-b197-a979c79310b0",
      "email" : ""
    }
  },
  "bewarecoffee" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/EzVeGZaa0WgQuQZnrXYsRNO8snaEXGVM.png",
      "instagramHandle" : "bewarecoffee",
      "merchantHandle" : "bewarecoffee",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Beware Coffee",
      "city" : "Tacoma",
      "companyName" : "Beware Coffee",
      "websiteUrl" : "https:\/\/www.bewarecoffee.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "communalcoffee" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(619) 228-9797",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/vLIcWVtXkCrELPkiJJfOh0KsUt3aMs9G.png",
      "instagramHandle" : "communalcoffee",
      "premium" : false,
      "merchantHandle" : "communalcoffee",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Communal Coffee",
      "city" : "Chula Vista",
      "companyName" : "Communal Coffee",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.communalcoffee.com\/",
      "email" : ""
    }
  },
  "stateumnightlife" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/XVJbWNaKRkWosqcyw8VrwFk9z0r0Frnl.png",
      "instagramHandle" : "stateumnightlife",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "stateumnightlife",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Stateum Nightlife",
      "city" : "Warren",
      "companyName" : "Stateum Nightlife",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.stateumnightlife.com\/",
      "email" : ""
    }
  },
  "hotpotminiworkshop" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 481-3637",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/c90cb93f0cc1f0e941605e3bc41fb856dbf28dda\/original.jpeg",
      "instagramHandle" : "hotpotminiworkshop",
      "merchantHandle" : "hotpotminiworkshop",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Hot pot mini workshop",
      "city" : "San Bernardino",
      "companyName" : "Hot pot mini workshop",
      "websiteUrl" : "http:\/\/www.hotpotminiworkshop.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "luckyllamacoffeehouse" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(805) 684-8811",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/ddef62ed3b7f7436effbf06e06e6f1b2\/original.jpeg",
      "instagramHandle" : "luckyllamacoffeehouse",
      "premium" : false,
      "merchantHandle" : "luckyllamacoffeehouse",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Lucky Llama Coffee House",
      "city" : "Oxnard",
      "companyName" : "Lucky Llama Coffee House",
      "websiteUrl" : "https:\/\/www.luckyllamacoffee.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "stovetopcoffeeroasters" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 855-5353",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/e4e5394bad90c69c68d936862d54eedbf778054e\/original.png",
      "instagramHandle" : "stovetopcoffeeroasters",
      "twitterHandle" : "",
      "merchantHandle" : "stovetopcoffeeroasters",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Stovetop Coffee Roasters",
      "city" : "Grand Rapids",
      "companyName" : "Stovetop Coffee Roasters",
      "websiteUrl" : "https:\/\/stovetoproasters.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "westcoastbbqshop" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(619) 482-2468",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/ac55ef83478d9df2c325ef9fa205784fe8524b86\/original.jpeg",
      "instagramHandle" : "westcoastbbqshop",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "westcoastbbqshop",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "West Coast BBQ Shop",
      "city" : "Chula Vista",
      "companyName" : "West Coast BBQ Shop",
      "websiteUrl" : "https:\/\/westcoastbbqshop.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "nonnaspantry" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(616) 920-7377",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/e31202996c126a0e1d11222980505f432c160652\/original.jpeg",
      "instagramHandle" : "nonnaspantry",
      "merchantHandle" : "nonnaspantry",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Nonna's Pantry",
      "city" : "Grand Rapids",
      "companyName" : "Nonna's Pantry",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/nonnaspantry.com\/",
      "email" : ""
    }
  },
  "persiannightsrestaurant" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 879-7729",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/qRC4BCqKFTz8xPvxzE9tssOKDAQCqsW0.png",
      "instagramHandle" : "persiannightsrestaurant",
      "premium" : false,
      "merchantHandle" : "persiannightsrestaurant",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Persian Nights Restaurant",
      "city" : "Oakland",
      "companyName" : "Persian Nights Restaurant",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.persiannights.co\/",
      "email" : ""
    }
  },
  "chienyingyang" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 948-7641",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/9c4efb25abd12a50fddb3942d2b6e47748a95c19\/original.jpeg",
      "instagramHandle" : "chienyingyang",
      "merchantHandle" : "chienyingyang",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "CHIEN-YING YANG",
      "city" : "Anaheim",
      "companyName" : "CHIEN-YING YANG",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "clinicalafamilia" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 468-2335",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/195701680ee1f89371d763ed2622197c3579b8b7\/original.jpeg",
      "instagramHandle" : "clinicalafamilia",
      "merchantHandle" : "clinicalafamilia",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Clinica La Familia",
      "city" : "Stockton",
      "companyName" : "Clinica La Familia",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "vegetarianveganglutenfreecateringmealdelivery" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(971) 231-4248",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ZLysD0OtHFgsBEh1Yoz3bEW4m6BGjNW7.png",
      "instagramHandle" : "vegetarianveganglutenfreecateringmealdelivery",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "vegetarianveganglutenfreecateringmealdelivery",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Vegetarian•Vegan•Gluten Free Catering & Meal Delivery",
      "city" : "Portland",
      "companyName" : "Vegetarian•Vegan•Gluten Free Catering & Meal Delivery",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.meals4heels.com\/",
      "email" : ""
    }
  },
  "sweetsweetdelight" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 583-0540",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/1d647a3f906bd3a97dc0f7ccbc89224da76cf392\/original.jpeg",
      "instagramHandle" : "sweetsweetdelight",
      "premium" : false,
      "merchantHandle" : "sweetsweetdelight",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Sweet Sweet Delight",
      "city" : "Moreno Valley",
      "companyName" : "Sweet Sweet Delight",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "simplyuniqueseamoss" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(404) 862-7060",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/uUk6yLEKVEMOElJn6Zi2NfQH8enDpLZd.png",
      "instagramHandle" : "simplyuniqueseamoss",
      "merchantHandle" : "simplyuniqueseamoss",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Simply Unique Sea Moss",
      "city" : "Riverside",
      "companyName" : "Simply Unique Sea Moss",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "boxlunch" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(360) 326-1337",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/qQCunBDbZJas1M30GPxxDQUIhRbB57xD.png",
      "instagramHandle" : "boxlunch",
      "premium" : false,
      "merchantHandle" : "boxlunch",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "BoxLunch",
      "city" : "Vancouver",
      "companyName" : "BoxLunch",
      "websiteUrl" : "https:\/\/www.boxlunch.com\/stores-details?StoreId=4715",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "panaderialapequea" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 817-8254",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/vWTkLLcOL0EYjtuaxKQJ0JjgJdGFfMih.jpg",
      "instagramHandle" : "panaderialapequea",
      "twitterHandle" : "",
      "merchantHandle" : "panaderialapequea",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Panaderia La Pequeña",
      "city" : "Anaheim",
      "companyName" : "Panaderia La Pequeña",
      "websiteUrl" : "https:\/\/instagram.com\/lapequenia_?igshid=6dza0i35btf4",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "elantojotacomamckinleyave" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(253) 475-0375",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/kE3EDC4wY6sXEfO7Sk2uCxnkil1tj8aZ.png",
      "instagramHandle" : "elantojotacomamckinleyave",
      "merchantHandle" : "elantojotacomamckinleyave",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "El Antojo Tacoma McKinley Ave",
      "city" : "Tacoma",
      "companyName" : "El Antojo Tacoma McKinley Ave",
      "websiteUrl" : "http:\/\/www.elantojo.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "celebrationssweetboutique" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(509) 315-5973",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/FnT0jQ5s2tBfsY7g0FnOBdyejjfTwhhv.png",
      "instagramHandle" : "celebrationssweetboutique",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "celebrationssweetboutique",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Celebrations Sweet Boutique",
      "city" : "Spokane",
      "companyName" : "Celebrations Sweet Boutique",
      "websiteUrl" : "http:\/\/www.celebrationssweetboutique.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "cleanandcolorfulkitchen" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(858) 264-2872",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/wraFeVi9lDaoaSnX8qVa7MesQ342Ay4h.png",
      "instagramHandle" : "cleanandcolorfulkitchen",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "cleanandcolorfulkitchen",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Clean And Colorful Kitchen",
      "city" : "Chula Vista",
      "companyName" : "Clean And Colorful Kitchen",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "chilismithfamilyfoods" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(916) 524-7071",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/ce62cbdf0c2a70e5a18f59ca0d582b05eded054d\/original.png",
      "instagramHandle" : "chilismithfamilyfoods",
      "merchantHandle" : "chilismithfamilyfoods",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Chili Smith Family Foods",
      "city" : "Sacramento",
      "companyName" : "Chili Smith Family Foods",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/chilismith.com\/",
      "email" : ""
    }
  },
  "pugwine" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/7606783e483bacfce6ab0631e5b5ac06\/original.jpeg",
      "instagramHandle" : "pugwine",
      "merchantHandle" : "pugwine",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Pug Wine",
      "city" : "San Francisco",
      "companyName" : "Pug Wine",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "realbowls" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(925) 818-8157",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/501cbe133e4a07bdd89a6fefb433f17bf4c9f54d\/original.png",
      "instagramHandle" : "realbowls",
      "twitterHandle" : "",
      "merchantHandle" : "realbowls",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Real Bowls",
      "city" : "Stockton",
      "companyName" : "Real Bowls",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.realbowls.com\/",
      "email" : ""
    }
  },
  "tacosnochistlan" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(951) 943-0580",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "tacosnochistlan",
      "merchantHandle" : "tacosnochistlan",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Tacos Nochistlan",
      "city" : "Riverside",
      "companyName" : "Tacos Nochistlan",
      "websiteUrl" : "https:\/\/www.tacosnochistlan.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "wingshack" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/kNpB1GCh4IH020lYw6CZ0J9IX2y4a6YD.png",
      "instagramHandle" : "wingshack",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "wingshack",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Wing Shack",
      "city" : "Ann Arbor",
      "companyName" : "Wing Shack",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "peanutshop" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(517) 374-0008",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Lz5Jxfe1GZFd2d9hh7ayIKPJpX1eldNS.png",
      "instagramHandle" : "peanutshop",
      "twitterHandle" : "",
      "merchantHandle" : "peanutshop",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Peanut Shop",
      "city" : "Lansing",
      "companyName" : "Peanut Shop",
      "websiteUrl" : "http:\/\/www.lansingpeanutshop.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "redbaycoffee" : {
    "analytics" : {
      "records" : {
        "landing-page" : {
          "gyFHevtd" : {
            "pathname" : "\/redbaycoffee",
            "time" : "2024-04-29T01:18:00.558Z",
            "merchantHandle" : "redbaycoffee",
            "host" : "cafe.bio",
            "timezone" : "America\/Los_Angeles",
            "userAgent" : "Mozilla\/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/124.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "cKJ9dBh3" : {
            "pathname" : "\/redbaycoffee",
            "time" : "2024-06-14T02:55:38.410Z",
            "merchantHandle" : "redbaycoffee",
            "host" : "cafe.bio",
            "timezone" : "America\/Los_Angeles",
            "userAgent" : "Mozilla\/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/125.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          }
        }
      }
    }
  },
  "thecleaningauthority" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 818-7235",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "thecleaningauthority",
      "merchantHandle" : "thecleaningauthority",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Cleaning Authority",
      "city" : "Grand Rapids",
      "companyName" : "The Cleaning Authority",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.thecleaningauthority.com\/grandrapids",
      "email" : ""
    }
  },
  "fortsidebrewingcompany" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(360) 524-4692",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/d4ab1cda0dd05be17fedaf7cb361b63594cd5ca3\/original.png",
      "instagramHandle" : "fortsidebrewingcompany",
      "premium" : false,
      "merchantHandle" : "fortsidebrewingcompany",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Fortside Brewing Company",
      "city" : "Vancouver",
      "companyName" : "Fortside Brewing Company",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/fortsidebrewing.com\/",
      "email" : ""
    }
  },
  "adleinternational" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(415) 874-5637",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/aa4qs7Yad66S0C0ZM6djboEd8FOzp3Mp.jpg",
      "instagramHandle" : "adleinternational",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "adleinternational",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "ADLE International",
      "city" : "Portland",
      "companyName" : "ADLE International",
      "websiteUrl" : "https:\/\/www.adleinternational.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "cornucopianaturalfoods" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 575-1650",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "cornucopianaturalfoods",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "cornucopianaturalfoods",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Cornucopia Natural Foods",
      "city" : "Modesto",
      "companyName" : "Cornucopia Natural Foods",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "cunvietnamesestreetfood" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/3OigvvS8GUHLv049y9gSpOdi0Hr6CcEa.png",
      "instagramHandle" : "cunvietnamesestreetfood",
      "twitterHandle" : "",
      "merchantHandle" : "cunvietnamesestreetfood",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Cuốn - Vietnamese Street Food",
      "city" : "Portland",
      "companyName" : "Cuốn - Vietnamese Street Food",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.cuonpdx.com\/",
      "email" : ""
    }
  },
  "bridenbeckerracletterestaurant" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/zZklesjWxX3uGjwu0uO8saXALYI8CKLi.png",
      "instagramHandle" : "bridenbeckerracletterestaurant",
      "premium" : false,
      "merchantHandle" : "bridenbeckerracletterestaurant",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Bridenbecker Raclette Restaurant",
      "city" : "Huntington Beach",
      "companyName" : "Bridenbecker Raclette Restaurant",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "revampsignsdesigns" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 323-5522",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/a5535d4203a7c15ef0e23870e9d352cdbb50816b\/original.jpeg",
      "instagramHandle" : "revampsignsdesigns",
      "twitterHandle" : "",
      "merchantHandle" : "revampsignsdesigns",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "REVAMP Signs & Designs",
      "city" : "Stockton",
      "companyName" : "REVAMP Signs & Designs",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.revampsigns.com\/",
      "email" : ""
    }
  },
  "tropicanaraspadosytejuinos" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 243-9000",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/mQIlSNzqGIbuRMFvCK5uEQbgkZchR93J.png",
      "instagramHandle" : "tropicanaraspadosytejuinos",
      "premium" : false,
      "merchantHandle" : "tropicanaraspadosytejuinos",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "TROPICANA Raspados y Tejuinos",
      "city" : "Moreno Valley",
      "companyName" : "TROPICANA Raspados y Tejuinos",
      "websiteUrl" : "https:\/\/www.instagram.com\/tropicanaraspados\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "blairsrestaurant" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(323) 739-0990",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "blairsrestaurant",
      "merchantHandle" : "blairsrestaurant",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Blair's Restaurant",
      "city" : "Glendale",
      "companyName" : "Blair's Restaurant",
      "websiteUrl" : "https:\/\/blairsrestaurant.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "southportland" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/hi1EJLf8fviKRkMCHGouy38IRPvJREIv.png",
      "instagramHandle" : "southportland",
      "twitterHandle" : "",
      "merchantHandle" : "southportland",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "South Portland",
      "city" : "Portland",
      "companyName" : "South Portland",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "sweettoothcandy" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(951) 398-3161",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/d334133e733c811df4718c006b75ffb939b92392\/original.jpeg",
      "instagramHandle" : "sweettoothcandy",
      "twitterHandle" : "",
      "merchantHandle" : "sweettoothcandy",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sweet Tooth Candy",
      "city" : "Riverside",
      "companyName" : "Sweet Tooth Candy",
      "websiteUrl" : "https:\/\/sweettoothcandybuffets.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "minimal" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 214-4215",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/lxLTaJTZKi4gT3iavOXh0jW9HfSVizV6.png",
      "instagramHandle" : "minimal",
      "premium" : false,
      "merchantHandle" : "minimal",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "minimal",
      "city" : "San Francisco",
      "companyName" : "minimal",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "binnyscocktailbarrestaurant" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 671-1661",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/q6cqcgwQlw8xIKzuDlGN5vUWQ1HmfJsf.png",
      "instagramHandle" : "binnyscocktailbarrestaurant",
      "merchantHandle" : "binnyscocktailbarrestaurant",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Binny's Cocktail Bar & Restaurant",
      "city" : "Oakland",
      "companyName" : "Binny's Cocktail Bar & Restaurant",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.binnysoakland.com\/",
      "email" : ""
    }
  },
  "johnsoncandycompany" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(253) 272-8504",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/zVllWeOsaE1Eapzd8cdqwexH32CEWrKU.png",
      "instagramHandle" : "johnsoncandycompany",
      "twitterHandle" : "",
      "merchantHandle" : "johnsoncandycompany",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Johnson Candy Company",
      "city" : "Tacoma",
      "companyName" : "Johnson Candy Company",
      "websiteUrl" : "http:\/\/www.johnsoncandyco.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "booksmortar" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 214-8233",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/PJDMaFwGoA9urw1OqLNznAtqKfWe6tXz.png",
      "instagramHandle" : "booksmortar",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "booksmortar",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Books & Mortar",
      "city" : "Grand Rapids",
      "companyName" : "Books & Mortar",
      "websiteUrl" : "http:\/\/www.booksandmortar.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "gozenexpress" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(310) 218-7934",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/sFn27j9datLMwn3oG8XzDPwEG8pUEaCo.png",
      "instagramHandle" : "gozenexpress",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "gozenexpress",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Gozen Express",
      "city" : "Glendale",
      "companyName" : "Gozen Express",
      "websiteUrl" : "https:\/\/gozenbistro.com\/foodtruck",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "oventroy" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(248) 731-7874",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/1dJBtPgSgu8Kkya46pUdxCV1D0jIlBW0.png",
      "instagramHandle" : "oventroy",
      "merchantHandle" : "oventroy",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Oven 360 Troy",
      "city" : "Warren",
      "companyName" : "Oven 360 Troy",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.oven360.com\/",
      "email" : ""
    }
  },
  "thequeenbeancoffeehouse" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 521-8000",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/nY1mCbTUStY1oXflHbt8RvIp9K123CQ3.png",
      "instagramHandle" : "thequeenbeancoffeehouse",
      "merchantHandle" : "thequeenbeancoffeehouse",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Queen Bean Coffee House",
      "city" : "Modesto",
      "companyName" : "The Queen Bean Coffee House",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.facebook.com\/queenbeancoffeehouse",
      "email" : ""
    }
  },
  "partyhouse" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/fffdca6c4850f230ddd26e053867cd1c97620ea3\/original.jpeg",
      "instagramHandle" : "partyhouse",
      "twitterHandle" : "",
      "merchantHandle" : "partyhouse",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Party House",
      "city" : "Tacoma",
      "companyName" : "Party House",
      "websiteUrl" : "http:\/\/www.partyhousetacoma.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "olsenfarms" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(509) 685-1548",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/ae4adec442a681e3648c5bc4f44d761a4dfb841a\/original.jpeg",
      "instagramHandle" : "olsenfarms",
      "merchantHandle" : "olsenfarms",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Olsen Farms",
      "city" : "Spokane",
      "companyName" : "Olsen Farms",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.olsenfarms.com\/",
      "email" : ""
    }
  },
  "oceankingmarket" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(360) 719-2118",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/0a9pdV0L0T4KX9Y2zVhugMztysUIDPqM.png",
      "instagramHandle" : "oceankingmarket",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "oceankingmarket",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Ocean King Market",
      "city" : "Vancouver",
      "companyName" : "Ocean King Market",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "bierdistillerybrewery" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(616) 888-9746",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/FUQRW4YJqJtpg8zFVGH1znS5ipCp5ZQX.png",
      "instagramHandle" : "bierdistillerybrewery",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "bierdistillerybrewery",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Bier Distillery & Brewery",
      "city" : "Grand Rapids",
      "companyName" : "Bier Distillery & Brewery",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/bierdistillery.com\/",
      "email" : ""
    }
  },
  "pandaexpress" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 739-1098",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/n1PdMSRyKb6H4CgEdsCcnVomnJKdGe5h.png",
      "instagramHandle" : "pandaexpress",
      "twitterHandle" : "",
      "merchantHandle" : "pandaexpress",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Panda Express",
      "city" : "Sacramento",
      "companyName" : "Panda Express",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.pandaexpress.com\/userlocation\/1904\/CA\/Sacramento\/6000-J-Street",
      "email" : ""
    }
  },
  "thegreenolivebelmontheights" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(562) 343-7791",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/B0jedXv2bLmP7d9xd6iLEng0g3eLwaYD.png",
      "instagramHandle" : "thegreenolivebelmontheights",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "thegreenolivebelmontheights",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Green Olive Belmont Heights",
      "city" : "Long Beach",
      "companyName" : "The Green Olive Belmont Heights",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/thegreenolivelb.com\/home-bh\/",
      "email" : ""
    }
  },
  "aburaya" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 502-7743",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/EBK4akV5yAbL6l260yDfi74S0ijVM9ws.png",
      "instagramHandle" : "aburaya",
      "merchantHandle" : "aburaya",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Aburaya",
      "city" : "Oakland",
      "companyName" : "Aburaya",
      "websiteUrl" : "https:\/\/aburayaoakland.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "juicykitchen" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(734) 585-5562",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/f3848be65d2d4a5b1da4b53ce224defcc8e915c2\/original.jpeg",
      "instagramHandle" : "juicykitchen",
      "twitterHandle" : "",
      "merchantHandle" : "juicykitchen",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Juicy Kitchen",
      "city" : "Ann Arbor",
      "companyName" : "Juicy Kitchen",
      "websiteUrl" : "http:\/\/www.juicykitchen-a2.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "shamrocklounge" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(810) 227-8265",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "shamrocklounge",
      "merchantHandle" : "shamrocklounge",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Shamrock Lounge",
      "city" : "Ann Arbor",
      "companyName" : "Shamrock Lounge",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/shamrockbrighton.com\/",
      "email" : ""
    }
  },
  "harrisonnutrition" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/a026ea747eea020ed3d0825c79dd89aeccbeed11\/original.png",
      "instagramHandle" : "harrisonnutrition",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "harrisonnutrition",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Harrison Nutrition",
      "city" : "Warren",
      "companyName" : "Harrison Nutrition",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.facebook.com\/harrisontownshipnutrition?mibextid=9R9pXO",
      "email" : ""
    }
  },
  "michiganbythebottletastingroom" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(586) 843-3690",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Stbj9Bi5XPHpmJMrHWIsDxom7BqzbbAm.png",
      "instagramHandle" : "michiganbythebottletastingroom",
      "merchantHandle" : "michiganbythebottletastingroom",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Michigan By The Bottle Tasting Room",
      "city" : "Warren",
      "companyName" : "Michigan By The Bottle Tasting Room",
      "websiteUrl" : "http:\/\/www.mbtbtasting.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sunsetsweets" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 712-8764",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/CA1DRTxLwKjz2NTdXSu53nQTr0DfSHGM.png",
      "instagramHandle" : "sunsetsweets",
      "merchantHandle" : "sunsetsweets",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sunset Sweets",
      "city" : "Stockton",
      "companyName" : "Sunset Sweets",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.sunsetsweetsdelta.com\/",
      "email" : ""
    }
  },
  "lionheartkombuchaheadquarters" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(503) 289-1568",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/v4HX0LKEebkvIJ00ZPYdNA4RTnfIFzok.png",
      "instagramHandle" : "lionheartkombuchaheadquarters",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "lionheartkombuchaheadquarters",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Lion Heart Kombucha Headquarters",
      "city" : "Vancouver",
      "companyName" : "Lion Heart Kombucha Headquarters",
      "websiteUrl" : "http:\/\/www.lionheartkombucha.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "tertuliacoffee" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(510) 350-7871",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/slvmczV6GMWSB8VjmPBk0F0Lv0rAjlly.png",
      "instagramHandle" : "tertuliacoffee",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "tertuliacoffee",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Tertulia Coffee",
      "city" : "Oakland",
      "companyName" : "Tertulia Coffee",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "clementinespastries" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(586) 477-0359",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/9a28e05f206e8e3694d54a38423798ea3106b82f\/original.jpeg",
      "instagramHandle" : "clementinespastries",
      "merchantHandle" : "clementinespastries",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Clementine's Pastries",
      "city" : "Warren",
      "companyName" : "Clementine's Pastries",
      "websiteUrl" : "https:\/\/clementinespastries.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "cafsierra" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/12091c7d782ed1eb9a0e1191da75b7d4dfeadc97\/original.png",
      "instagramHandle" : "cafsierra",
      "premium" : false,
      "merchantHandle" : "cafsierra",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Café Sierra",
      "city" : "Fontana",
      "companyName" : "Café Sierra",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "consumptioncoach" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(949) 614-5284",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/xqahyxofgvzSLNXuBZhYDQhBxgpFFl5h.png",
      "instagramHandle" : "consumptioncoach",
      "twitterHandle" : "",
      "merchantHandle" : "consumptioncoach",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Consumption Coach",
      "city" : "Huntington Beach",
      "companyName" : "Consumption Coach",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "chamyacatering" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 666-8800",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/6ee33bc2186e14cff0467b74852dfd5d35ac9177\/original.jpeg",
      "instagramHandle" : "chamyacatering",
      "merchantHandle" : "chamyacatering",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "Catering Company",
      "merchantName" : "Cham-ya Catering",
      "city" : "Anaheim",
      "companyName" : "Cham-ya Catering",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/chamyaonline.com\/",
      "email" : "admin@sooninc.com"
    }
  },
  "kitchentaproom" : {
    "info" : {
      "state" : "MT\r",
      "posSystem" : "Square",
      "phone" : "(406) 969-2599",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/gcWrbbXOWpBX9ywm8JPyDAySo4fEa4zT.png",
      "instagramHandle" : "kitchentaproom",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "kitchentaproom",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "406 Kitchen & Taproom",
      "city" : "Billings",
      "companyName" : "406 Kitchen & Taproom",
      "websiteUrl" : "http:\/\/www.406kitchenbillings.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "wardaptisserie" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(313) 262-6977",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/gIlPBcSlauBP4Xf6hzRZEjoZL6QaIWqV.png",
      "instagramHandle" : "wardaptisserie",
      "twitterHandle" : "",
      "merchantHandle" : "wardaptisserie",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Warda Pâtisserie",
      "city" : "Detroit",
      "companyName" : "Warda Pâtisserie",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/warda-patisserie-midtown.square.site\/",
      "email" : ""
    }
  },
  "riseauthenticbakingco" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 288-7969",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/yNNmUJBsBTifr8iZ1vX7gOdx13uQ0pqU.png",
      "instagramHandle" : "riseauthenticbakingco",
      "premium" : false,
      "merchantHandle" : "riseauthenticbakingco",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Rise Authentic Baking Co.",
      "city" : "Grand Rapids",
      "companyName" : "Rise Authentic Baking Co.",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.riseauthenticbaking.com\/",
      "email" : ""
    }
  },
  "snapbodbeautywellnessllc" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 297-7876",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/94f4e9f784b111835105dee602277665cfab19e0\/original.png",
      "instagramHandle" : "snapbodbeautywellnessllc",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "snapbodbeautywellnessllc",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Snap Bod Beauty & Wellness LLC",
      "city" : "Riverside",
      "companyName" : "Snap Bod Beauty & Wellness LLC",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.snapbod.com\/",
      "email" : ""
    }
  },
  "gdbroburger" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(562) 595-5059",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/e040F4E9nE0HTP62GF3fI0cHab2Kt9Aq.png",
      "instagramHandle" : "gdbroburger",
      "premium" : false,
      "merchantHandle" : "gdbroburger",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "GD Bro Burger",
      "city" : "Long Beach",
      "companyName" : "GD Bro Burger",
      "websiteUrl" : "http:\/\/www.gdbroburger.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "republicservices" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 466-3604",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/1b841f06eaa56564ca17b9bcaa79642e2c0c700c\/original.png",
      "instagramHandle" : "republicservices",
      "merchantHandle" : "republicservices",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Republic Services",
      "city" : "Stockton",
      "companyName" : "Republic Services",
      "websiteUrl" : "http:\/\/www.republicservices.com\/?utm_source=yext.com&utm_medium=referral&utm_campaign=Yext_Profile_Click_Google&utm_term=205",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "rattos" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 832-6503",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/pwPTZNl45Zu8labowg12C2hbd2ZvLOgi.png",
      "instagramHandle" : "rattos",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "rattos",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Ratto's",
      "city" : "Oakland",
      "companyName" : "Ratto's",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/rattos.com\/",
      "email" : ""
    }
  },
  "miflavourmodernfrenchbakery" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(509) 315-4516",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/fbb9a63d7a42406d212422d572ab3cd1965335e9\/original.png",
      "instagramHandle" : "miflavourmodernfrenchbakery",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "miflavourmodernfrenchbakery",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "miFLAVOUR Modern French Bakery",
      "city" : "Spokane",
      "companyName" : "miFLAVOUR Modern French Bakery",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.miflavour.com\/",
      "email" : ""
    }
  },
  "mistersoftee" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 388-2663",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/j7LXQhalUabTY43lYWEvEauCiJaoXQOO.png",
      "instagramHandle" : "mistersoftee",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "mistersoftee",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Mister Softee",
      "city" : "Oxnard",
      "companyName" : "Mister Softee",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.mistersofteesocal.com\/",
      "email" : ""
    }
  },
  "alwayscaturdaycatcafbrand" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/V6qMsYmZ3XcZUn8sm20W0VSkGrGZZKP0.png",
      "instagramHandle" : "alwayscaturdaycatcafbrand",
      "premium" : false,
      "merchantHandle" : "alwayscaturdaycatcafbrand",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "Cafe",
      "squareSandboxKey" : "",
      "merchantName" : "Always Caturday • Cat Café & Brand",
      "city" : "Anaheim",
      "companyName" : "Always Caturday • Cat Café & Brand",
      "websiteUrl" : "http:\/\/www.etsy.com\/shop\/alwayscaturday",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "muddypawcoffeeparkeaglerock" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(213) 269-4802",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/rZqLrR12VyoZAOHN9JgdST3VsurUp0EU.png",
      "instagramHandle" : "muddypawcoffeeparkeaglerock",
      "twitterHandle" : "",
      "merchantHandle" : "muddypawcoffeeparkeaglerock",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Muddy Paw Coffee & Park Eagle Rock",
      "city" : "Glendale",
      "companyName" : "Muddy Paw Coffee & Park Eagle Rock",
      "websiteUrl" : "http:\/\/www.muddypawla.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "somtumthaikitchen" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(503) 227-3653",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/6024ce885f0f934074414833d154de66d532fbf2\/original.jpeg",
      "instagramHandle" : "somtumthaikitchen",
      "twitterHandle" : "",
      "merchantHandle" : "somtumthaikitchen",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Somtum Thai Kitchen",
      "city" : "Portland",
      "companyName" : "Somtum Thai Kitchen",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.somtumthaikitchen.com\/",
      "email" : ""
    }
  },
  "fiftyfivesouth" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(408) 288-6000",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/0c175e7681709b9f6d26ab2c09ee8c6d2996191f\/original.jpeg",
      "instagramHandle" : "fiftyfivesouth",
      "twitterHandle" : "",
      "merchantHandle" : "fiftyfivesouth",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "55 - Fifty Five South",
      "city" : "San Jose",
      "companyName" : "55 - Fifty Five South",
      "websiteUrl" : "http:\/\/www.the55south.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "swisschardfarm" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 818-6705",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/fbf4ad40585d57e5e5a7292c93477711fd7c876f\/original.png",
      "instagramHandle" : "swisschardfarm",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "swisschardfarm",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Swiss Chard Farm",
      "city" : "Riverside",
      "companyName" : "Swiss Chard Farm",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "moomoonubo" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(916) 476-3407",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Ukte1SDU5lBK0dZIu6rF33WuEcP0Zn5a.png",
      "instagramHandle" : "moomoonubo",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "moomoonubo",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "mOO mOo & Nubo",
      "city" : "Sacramento",
      "companyName" : "mOO mOo & Nubo",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "baobao" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(503) 477-8911",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/adae7ff537f2ed57a00a4eb6e5f10265d734c5e2\/original.png",
      "instagramHandle" : "baobao",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "baobao",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Bao Bao",
      "city" : "Portland",
      "companyName" : "Bao Bao",
      "websiteUrl" : "http:\/\/www.baobaopdx.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "davidriochaibar" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 865-0677",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/4okxij5adawFaqqum4N0da5ZjeO4021B.png",
      "instagramHandle" : "davidriochaibar",
      "merchantHandle" : "davidriochaibar",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "David Rio Chai Bar",
      "city" : "San Francisco",
      "companyName" : "David Rio Chai Bar",
      "websiteUrl" : "http:\/\/www.chaibarsf.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "seabutterwestlakevillage" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 221-6096",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/4VDaSK57afue5vqyPFq7rVS2ShRI9FRF.png",
      "instagramHandle" : "seabutterwestlakevillage",
      "twitterHandle" : "",
      "merchantHandle" : "seabutterwestlakevillage",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Seabutter - Westlake Village",
      "city" : "Oxnard",
      "companyName" : "Seabutter - Westlake Village",
      "websiteUrl" : "http:\/\/www.seabuttersushi.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "tastythai" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/5675f12ef59b7eeaf7ce41adf74eceb2808b04bc\/original.png",
      "instagramHandle" : "tastythai",
      "twitterHandle" : "",
      "merchantHandle" : "tastythai",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Tasty Thai",
      "city" : "Fresno",
      "companyName" : "Tasty Thai",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "tigerplate" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 486-2333",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/01b57a0a03da1f7a53cf88bccc51ae291a55d2aa\/original.jpeg",
      "instagramHandle" : "tigerplate",
      "twitterHandle" : "",
      "merchantHandle" : "tigerplate",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Tiger Plate",
      "city" : "Santa Ana",
      "companyName" : "Tiger Plate",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/tigerplate.com\/",
      "email" : ""
    }
  },
  "wingenbakery" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(925) 495-4503",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/wtHqTdav8bUznkQ9o6NjDPsUNmKNWD5V.png",
      "instagramHandle" : "wingenbakery",
      "premium" : false,
      "merchantHandle" : "wingenbakery",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Wingen Bakery",
      "city" : "Stockton",
      "companyName" : "Wingen Bakery",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.wingenbakery.com\/",
      "email" : ""
    }
  },
  "basecamprestaurant" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(734) 882-2882",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "basecamprestaurant",
      "premium" : false,
      "merchantHandle" : "basecamprestaurant",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "BASE CAMP RESTAURANT",
      "city" : "Ann Arbor",
      "companyName" : "BASE CAMP RESTAURANT",
      "websiteUrl" : "https:\/\/www.basecampa2.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "drinkmamey" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/OTmaW9j4TvQBg8kXkPGuXM0gb5mBAAHk.png",
      "instagramHandle" : "drinkmamey",
      "premium" : false,
      "merchantHandle" : "drinkmamey",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Drink Mamey",
      "city" : "Vancouver",
      "companyName" : "Drink Mamey",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "ubatubaacaisilverlake" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(213) 595-3868",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/zbnF3jj2oIcH747U2yzLoPo1THZJ8VMK.png",
      "instagramHandle" : "ubatubaacaisilverlake",
      "premium" : false,
      "merchantHandle" : "ubatubaacaisilverlake",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Ubatuba Acai - Silverlake",
      "city" : "Glendale",
      "companyName" : "Ubatuba Acai - Silverlake",
      "websiteUrl" : "https:\/\/ubatubaacai.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "elcachofishtacostand" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(619) 500-5730",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/28nD6yyCmFDUDVLWGhqLuoJ01SLlny4c.png",
      "instagramHandle" : "elcachofishtacostand",
      "merchantHandle" : "elcachofishtacostand",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "El Cacho Fish Taco Stand",
      "city" : "Chula Vista",
      "companyName" : "El Cacho Fish Taco Stand",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "clarkcountyfoodbank" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(360) 693-0939",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "clarkcountyfoodbank",
      "twitterHandle" : "",
      "merchantHandle" : "clarkcountyfoodbank",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Clark County Food Bank",
      "city" : "Vancouver",
      "companyName" : "Clark County Food Bank",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/clarkcountyfoodbank.org\/",
      "email" : ""
    }
  },
  "mountainlakesbrewingcompany" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(509) 596-0943",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ArjMOEsrzWn2aI2028PmNIdQe41oZoyM.png",
      "instagramHandle" : "mountainlakesbrewingcompany",
      "twitterHandle" : "",
      "merchantHandle" : "mountainlakesbrewingcompany",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Mountain Lakes Brewing Company",
      "city" : "Spokane",
      "companyName" : "Mountain Lakes Brewing Company",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/mountainlakesbrewco.com\/",
      "email" : ""
    }
  },
  "foodhall" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 872-6822",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/0nv0e4I0SMImvwzLrIhQ40vI1FBz1yV4.png",
      "instagramHandle" : "foodhall",
      "premium" : false,
      "merchantHandle" : "foodhall",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Foodhall",
      "city" : "San Francisco",
      "companyName" : "Foodhall",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/foodhallsf.com\/",
      "email" : ""
    }
  },
  "xuangracely" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 486-4529",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/L6kbx1LPTDwlao9g2tW3pF3sW0M6DaWV.png",
      "instagramHandle" : "xuangracely",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "xuangracely",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Xuan Grace Ly",
      "city" : "Moreno Valley",
      "companyName" : "Xuan Grace Ly",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "dontaco" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(360) 993-0999",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/aeW7tSiKFaJzbVNZnt6HK0ZgwB4i36We.png",
      "instagramHandle" : "dontaco",
      "merchantHandle" : "dontaco",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Don Taco",
      "city" : "Vancouver",
      "companyName" : "Don Taco",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/m.facebook.com\/pages\/Don-Taco\/115614395134977",
      "email" : ""
    }
  },
  "stjohncaferestaurant" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(949) 225-4891",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "stjohncaferestaurant",
      "merchantHandle" : "stjohncaferestaurant",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "St John Cafe & Restaurant",
      "city" : "Irvine",
      "companyName" : "St John Cafe & Restaurant",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.stjohncafe.com\/",
      "email" : ""
    }
  },
  "elcaminocafe" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 573-5898",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/31c3e2aad3a39bc608cb5653a75f870c97b33f32\/original.jpeg",
      "instagramHandle" : "elcaminocafe",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "elcaminocafe",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "El Camino Cafe",
      "city" : "Long Beach",
      "companyName" : "El Camino Cafe",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.elcaminocaffe.com\/",
      "email" : ""
    }
  },
  "collectbeerbar" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/cRaY5p2P29Og0QVOIwFd4UNuBLP2uEM5.png",
      "instagramHandle" : "collectbeerbar",
      "merchantHandle" : "collectbeerbar",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Collect Beer Bar",
      "city" : "Detroit",
      "companyName" : "Collect Beer Bar",
      "websiteUrl" : "http:\/\/collect-beerbar.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "kawaexpress" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(616) 255-3685",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/BTKpLSTWgVY59mhHpBbenZCUbC4IuZzU.png",
      "instagramHandle" : "kawaexpress",
      "merchantHandle" : "kawaexpress",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Kawa Express",
      "city" : "Grand Rapids",
      "companyName" : "Kawa Express",
      "websiteUrl" : "http:\/\/www.kawagr.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "bobop" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 525-3959",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/hY15Sw91tZ03TXL00eBWUycO2NAC6quK.png",
      "instagramHandle" : "bobop",
      "merchantHandle" : "bobop",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "BOBOP",
      "city" : "San Francisco",
      "companyName" : "BOBOP",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "bellepomme" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/7d5a6042fb0bc35813816432806062ea5f8700f5\/original.jpeg",
      "instagramHandle" : "bellepomme",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "bellepomme",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Belle Pomme",
      "city" : "Portland",
      "companyName" : "Belle Pomme",
      "websiteUrl" : "http:\/\/thebellepomme.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "woodsbarbrewery" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(510) 253-2463",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ws7hasXuKv6hsF53tYDZD44F42Kor0rN.png",
      "instagramHandle" : "woodsbarbrewery",
      "merchantHandle" : "woodsbarbrewery",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Woods Bar & Brewery",
      "city" : "Oakland",
      "companyName" : "Woods Bar & Brewery",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "theworkerbeecaf" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(805) 745-1828",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/QSQsw9rda2vgnpZEpkqiX3ShQ2SgpbDz.png",
      "instagramHandle" : "theworkerbeecaf",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "theworkerbeecaf",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Worker Bee Café",
      "city" : "Oxnard",
      "companyName" : "The Worker Bee Café",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.theworkerbeecafe.com\/",
      "email" : ""
    }
  },
  "simplyrelaxedllc" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(586) 842-2001",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/2529208cc038bfa59c3b376817fd2d217ede7b3b\/original.jpeg",
      "instagramHandle" : "simplyrelaxedllc",
      "twitterHandle" : "",
      "merchantHandle" : "simplyrelaxedllc",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Simply relaxed LLC",
      "city" : "Warren",
      "companyName" : "Simply relaxed LLC",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.simplyrelaxed.biz\/",
      "email" : ""
    }
  },
  "thebeanbox" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(619) 301-5153",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/3a42d39121a26c1bcd853e3cbd12874f8553296b\/original.jpeg",
      "instagramHandle" : "thebeanbox",
      "merchantHandle" : "thebeanbox",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Bean Box 701",
      "city" : "Chula Vista",
      "companyName" : "The Bean Box 701",
      "websiteUrl" : "http:\/\/www.thebeanboxca.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "gogetemtiger" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(213) 298-3300",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/CGxQQuNhcgiivMWMhsivfqAlu5audZ4t.png",
      "instagramHandle" : "gogetemtiger",
      "premium" : false,
      "merchantHandle" : "gogetemtiger",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Go Get Em Tiger",
      "city" : "Glendale",
      "companyName" : "Go Get Em Tiger",
      "websiteUrl" : "https:\/\/gget.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "happyhemstudio" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 277-8367",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "happyhemstudio",
      "merchantHandle" : "happyhemstudio",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Happy Hem Studio",
      "city" : "Huntington Beach",
      "companyName" : "Happy Hem Studio",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sparkysbirdstore" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(509) 489-3019",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "sparkysbirdstore",
      "premium" : false,
      "merchantHandle" : "sparkysbirdstore",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sparky's Bird Store",
      "city" : "Spokane",
      "companyName" : "Sparky's Bird Store",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "mommasmarket" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 524-2782",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "mommasmarket",
      "premium" : false,
      "merchantHandle" : "mommasmarket",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Momma's Market",
      "city" : "Sacramento",
      "companyName" : "Momma's Market",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/mommasmarkets.com\/?utm_campaign=gmb",
      "email" : ""
    }
  },
  "allergyeatables" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/hnSmzyUbYdaSNl3SbSa0u9O1EagVfwP1.png",
      "instagramHandle" : "allergyeatables",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "allergyeatables",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Allergy Eatables",
      "city" : "Detroit",
      "companyName" : "Allergy Eatables",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/allergyeatables.com\/",
      "email" : ""
    }
  },
  "licked" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(279) 234-1868",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "licked",
      "merchantHandle" : "licked",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Licked",
      "city" : "Sacramento",
      "companyName" : "Licked",
      "websiteUrl" : "https:\/\/lickedsacramento.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "bunnybunny" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(313) 974-6122",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "bunnybunny",
      "twitterHandle" : "",
      "merchantHandle" : "bunnybunny",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Bunny Bunny",
      "city" : "Detroit",
      "companyName" : "Bunny Bunny",
      "websiteUrl" : "http:\/\/bunnybunnydetroit.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "mrtipplesjazzclub" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 384-9365",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/a6dc735883276363b488116d5e65619888428d05\/original.jpeg",
      "instagramHandle" : "mrtipplesjazzclub",
      "merchantHandle" : "mrtipplesjazzclub",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Mr. Tipple's Jazz Club",
      "city" : "San Francisco",
      "companyName" : "Mr. Tipple's Jazz Club",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/mrtipplessf.com\/",
      "email" : ""
    }
  },
  "mintristoranteitaliano" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 543-5100",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/gV0Z6fVoqql9UBFPos3L6PWScexatrUw.png",
      "instagramHandle" : "mintristoranteitaliano",
      "premium" : false,
      "merchantHandle" : "mintristoranteitaliano",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "54 Mint Ristorante Italiano",
      "city" : "San Francisco",
      "companyName" : "54 Mint Ristorante Italiano",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.54mint.com\/",
      "email" : ""
    }
  },
  "vivalaboba" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(951) 390-3527",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/468d687fe608b8bf74786c6e2eb9a58cf69e683d\/original.png",
      "instagramHandle" : "vivalaboba",
      "premium" : false,
      "merchantHandle" : "vivalaboba",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Viva La Boba",
      "city" : "San Bernardino",
      "companyName" : "Viva La Boba",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/vivalaboba.com\/",
      "email" : ""
    }
  },
  "alsacelorrainebakery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(562) 427-5992",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/I6N35LB2lOQFnSTpWMLf0NdWtk40VkNG.png",
      "instagramHandle" : "alsacelorrainebakery",
      "merchantHandle" : "alsacelorrainebakery",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Alsace Lorraine Bakery",
      "city" : "Long Beach",
      "companyName" : "Alsace Lorraine Bakery",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.alsacelorrainefinepastries.com\/contact_us.html",
      "email" : ""
    }
  },
  "addyssandwichbar" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(503) 267-0994",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/pLGPfalItsQC4i0DqdEeTmPUMVIehc5O.png",
      "instagramHandle" : "addyssandwichbar",
      "merchantHandle" : "addyssandwichbar",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Addy's Sandwich Bar",
      "city" : "Portland",
      "companyName" : "Addy's Sandwich Bar",
      "websiteUrl" : "http:\/\/addyssandwichbar.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "walmart" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 355-6922",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "walmart",
      "premium" : false,
      "merchantHandle" : "walmart",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Walmart",
      "city" : "Fontana",
      "companyName" : "Walmart",
      "websiteUrl" : "https:\/\/www.walmart.com\/store\/1756-fontana-ca",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "daxswingznthingz" : {
    "info" : {
      "state" : "MT\r",
      "posSystem" : "Square",
      "phone" : "(406) 534-2553",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/8HlE79gtrxXzDja0zpUEbFYXfDAfVd5A.png",
      "instagramHandle" : "daxswingznthingz",
      "merchantHandle" : "daxswingznthingz",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Dax's Wingz N Thingz",
      "city" : "Billings",
      "companyName" : "Dax's Wingz N Thingz",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "myherosubs" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 784-7370",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/orbztyFjIOZCQuxbbD97NaKJGWxOWJee.png",
      "instagramHandle" : "myherosubs",
      "merchantHandle" : "myherosubs",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "My Hero Subs",
      "city" : "Riverside",
      "companyName" : "My Hero Subs",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.myherosubsriverside.com\/",
      "email" : ""
    }
  },
  "voyager" : {
    "analytics" : {
      "records" : {
        "landing-page" : {
          "982WEd8E" : {
            "pathname" : "\/voyager",
            "time" : "2024-04-17T23:49:29.722Z",
            "merchantHandle" : "voyager",
            "host" : "cafe.bio",
            "timezone" : "America\/Los_Angeles",
            "userAgent" : "Mozilla\/5.0 (iPhone; CPU iPhone OS 17_4 like Mac OS X) AppleWebKit\/605.1.15 (KHTML, like Gecko) CriOS\/123.0.6312.52 Mobile\/15E148 Safari\/604.1",
            "page" : "landing-page\/"
          },
          "h8DXPDDK" : {
            "pathname" : "\/voyager",
            "time" : "2024-04-17T23:42:15.097Z",
            "merchantHandle" : "voyager",
            "host" : "cafe.bio",
            "timezone" : "America\/Los_Angeles",
            "userAgent" : "Mozilla\/5.0 (iPhone; CPU iPhone OS 17_4 like Mac OS X) AppleWebKit\/605.1.15 (KHTML, like Gecko) CriOS\/123.0.6312.52 Mobile\/15E148 Safari\/604.1",
            "page" : "landing-page\/"
          },
          "rVDKbrGe" : {
            "pathname" : "\/voyager",
            "time" : "2024-04-17T23:52:34.993Z",
            "merchantHandle" : "voyager",
            "host" : "cafe.bio",
            "timezone" : "America\/Los_Angeles",
            "userAgent" : "Mozilla\/5.0 (iPhone; CPU iPhone OS 17_4 like Mac OS X) AppleWebKit\/605.1.15 (KHTML, like Gecko) CriOS\/123.0.6312.52 Mobile\/15E148 Safari\/604.1",
            "page" : "landing-page\/"
          }
        }
      }
    }
  },
  "taavakitchen" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(510) 531-5912",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/1f8c704a3fd23291888f02c9e13159c348fad740\/original.jpeg",
      "instagramHandle" : "taavakitchen",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "taavakitchen",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Taava Kitchen",
      "city" : "Oakland",
      "companyName" : "Taava Kitchen",
      "websiteUrl" : "http:\/\/www.taavakitchen.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "frescoinc" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/VxCgcz01HvRSIRaEqOf9tdeRi3omwBjn.png",
      "instagramHandle" : "frescoinc",
      "premium" : false,
      "merchantHandle" : "frescoinc",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Fresco Inc",
      "city" : "Tacoma",
      "companyName" : "Fresco Inc",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "goodskinandbodywellness" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 534-9584",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/94f4e9f784b111835105dee602277665cfab19e0\/original.png",
      "instagramHandle" : "goodskinandbodywellness",
      "premium" : false,
      "merchantHandle" : "goodskinandbodywellness",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Good skin and body wellness",
      "city" : "San Bernardino",
      "companyName" : "Good skin and body wellness",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.goodskinandwellness.com\/",
      "email" : ""
    }
  },
  "generationsdiner" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(226) 674-1005",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/g8hRPjWugg0Rh0npf69TTqyv1IkhM3mR.png",
      "instagramHandle" : "generationsdiner",
      "merchantHandle" : "generationsdiner",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Generations Diner",
      "city" : "Detroit",
      "companyName" : "Generations Diner",
      "websiteUrl" : "https:\/\/l.facebook.com\/l.php?u=https%3A%2F%2Forder.online%2Fbusiness%2Fgenerations-diner-11245419%3Ffbclid%3DIwAR0hFxp5g-dFqaoUODupCh34grdTCzXZ3k_Wb2Mhgpcpv4MaYCizVIdtVK0&h=AT05UHD2WO8r941ZQQMgwXJVJr139PxRtMT733yaBvB0Tjiypnlcnuupp6_81mec79ynwrWAoyDCZSQO1oSQTvbmp4Q3tJlS_O1sYgkMcsk11vMdP3uKVr2zJOq-5Y90tEQ",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "jitterygeeks" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(661) 862-9534",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/c43f4e4224e88d039907ec2ac1ed930b0e2876da\/original.png",
      "instagramHandle" : "jitterygeeks",
      "premium" : false,
      "merchantHandle" : "jitterygeeks",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Jittery Geeks",
      "city" : "Bakersfield",
      "companyName" : "Jittery Geeks",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "7sevenwondersllc" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(517) 234-7338",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/e02d1bb154ab5c47ea491103e52310908c77a9c1\/original.png",
      "instagramHandle" : "7sevenwondersllc",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "7sevenwondersllc",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "7SeveNWondErs LLC.",
      "city" : "Lansing",
      "companyName" : "7SeveNWondErs LLC.",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "thekitchenstonebrier" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 932-9322",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/PkS9evPYCipUkSXPogo4Mw0FM1zAvSqM.png",
      "instagramHandle" : "thekitchenstonebrier",
      "merchantHandle" : "thekitchenstonebrier",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Kitchen @ Stonebrier",
      "city" : "Stockton",
      "companyName" : "The Kitchen @ Stonebrier",
      "websiteUrl" : "http:\/\/thekitchenatsb.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "elbazarazteca" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(408) 642-4200",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/OGcycgrtp6U38IlHCzyDJO6qd1ZfSPVx.png",
      "instagramHandle" : "elbazarazteca",
      "merchantHandle" : "elbazarazteca",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "El Bazar Azteca",
      "city" : "San Jose",
      "companyName" : "El Bazar Azteca",
      "websiteUrl" : "https:\/\/www.elbazarazteca.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thejuice" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(323) 644-0250",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/HkrrmBBJGzPIX4Epj6l7lhHA7BfweZyB.png",
      "instagramHandle" : "thejuice",
      "twitterHandle" : "",
      "merchantHandle" : "thejuice",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Juice",
      "city" : "Glendale",
      "companyName" : "The Juice",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.thejuicela.com\/",
      "email" : ""
    }
  },
  "baoboys" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/90199a31cf9023bce5145b6ab18ac6e349bee3f9\/original.png",
      "instagramHandle" : "baoboys",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "baoboys",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Bao Boys",
      "city" : "Ann Arbor",
      "companyName" : "Bao Boys",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.eatbaoboys.com\/",
      "email" : ""
    }
  },
  "bigwigdonuts" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(971) 719-4000",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/9Z46Hy0AMibKOlfvwj8jE0NGcFDbtZra.png",
      "instagramHandle" : "bigwigdonuts",
      "twitterHandle" : "",
      "merchantHandle" : "bigwigdonuts",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Bigwig Donuts",
      "city" : "Salem",
      "companyName" : "Bigwig Donuts",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/bigwigdonuts.com\/",
      "email" : ""
    }
  },
  "mrpurejuice" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(586) 755-9500",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/a9MCmJbmS3xzfFBRTjD21CCJJiKfYc8o.png",
      "instagramHandle" : "mrpurejuice",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "mrpurejuice",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Mr Pure Juice",
      "city" : "Warren",
      "companyName" : "Mr Pure Juice",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "veggoplantbasedfastfood" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 213-6233",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/PMvOB9x6YM4R2koBN0B0B5pgaAtVFpuZ.png",
      "instagramHandle" : "veggoplantbasedfastfood",
      "merchantHandle" : "veggoplantbasedfastfood",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Veg & Go Plant Based Fast Food",
      "city" : "Riverside",
      "companyName" : "Veg & Go Plant Based Fast Food",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/instagram.com\/vegandgofastfood",
      "email" : ""
    }
  },
  "baboysatbahaykainan" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(310) 518-6273",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/u9oTShSF13JpMVCa0cwB9clRxgtakuHx.png",
      "instagramHandle" : "baboysatbahaykainan",
      "twitterHandle" : "",
      "merchantHandle" : "baboysatbahaykainan",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "BaBoys at Bahay Kainan",
      "city" : "Long Beach",
      "companyName" : "BaBoys at Bahay Kainan",
      "websiteUrl" : "http:\/\/baboyscarson.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "osakateriyakibbqhouse" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(818) 953-7195",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/1h6HTsfMpViRjpAdtqhp7pmX40F2PZrl.png",
      "instagramHandle" : "osakateriyakibbqhouse",
      "merchantHandle" : "osakateriyakibbqhouse",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Osaka Teriyaki & BBQ House",
      "city" : "Glendale",
      "companyName" : "Osaka Teriyaki & BBQ House",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/osakabbq.com\/",
      "email" : ""
    }
  },
  "hesperiabranchlibrary" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(760) 552-6050",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/4961ea7e59985a00f37a3b3919400980fb575e86\/original.jpeg",
      "instagramHandle" : "hesperiabranchlibrary",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "hesperiabranchlibrary",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Hesperia Branch Library",
      "city" : "San Bernardino",
      "companyName" : "Hesperia Branch Library",
      "websiteUrl" : "https:\/\/sbclib.org\/library-locations\/hesperia-branch-library\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "ediblesrexcatering" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(313) 922-3000",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "ediblesrexcatering",
      "merchantHandle" : "ediblesrexcatering",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Edibles Rex Catering",
      "city" : "Detroit",
      "companyName" : "Edibles Rex Catering",
      "websiteUrl" : "https:\/\/www.ediblesrex.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "fluidstatebeergarden" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(805) 628-3107",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/77bnPLYZZCQd7QUcbaN2HPr8QydQwBBS.png",
      "instagramHandle" : "fluidstatebeergarden",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "fluidstatebeergarden",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Fluid State Beer Garden",
      "city" : "Oxnard",
      "companyName" : "Fluid State Beer Garden",
      "websiteUrl" : "https:\/\/www.fluidstatebeer.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "ojisanramen" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(541) 337-3116",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/THUZKlfwv1iCdiujhFKaqYsLOXJDe57J.png",
      "instagramHandle" : "ojisanramen",
      "merchantHandle" : "ojisanramen",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Ojisan Ramen",
      "city" : "Eugene",
      "companyName" : "Ojisan Ramen",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.ojisanramen.com\/",
      "email" : ""
    }
  },
  "knickies" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(586) 804-4650",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/m3oON2cNcXGOCAagQ96MvZxHHIvN8ycb.png",
      "instagramHandle" : "knickies",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "knickies",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "K'nickies",
      "city" : "Warren",
      "companyName" : "K'nickies",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "cellar" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(360) 693-2700",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/oUe3d73sKDHU33ZWaguIpbLt017nG2j0.png",
      "instagramHandle" : "cellar",
      "merchantHandle" : "cellar",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Cellar 55",
      "city" : "Vancouver",
      "companyName" : "Cellar 55",
      "websiteUrl" : "https:\/\/cellar55.wine\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "torogrillhouse" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(818) 500-0889",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/89367d6f19478203d79efd3c2a09b3aa9da92b98\/original.jpeg",
      "instagramHandle" : "torogrillhouse",
      "merchantHandle" : "torogrillhouse",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "TORO Grillhouse",
      "city" : "Glendale",
      "companyName" : "TORO Grillhouse",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.torogrillhouse.com\/",
      "email" : ""
    }
  },
  "eastmichigannativeplants" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(810) 333-7501",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/c2b49eaa2258a2ec61bff2cb66c95500a708039a\/original.png",
      "instagramHandle" : "eastmichigannativeplants",
      "merchantHandle" : "eastmichigannativeplants",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "East Michigan Native Plants",
      "city" : "Flint",
      "companyName" : "East Michigan Native Plants",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/eastmichnatives.com\/",
      "email" : ""
    }
  },
  "woodpilebbqshack" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI",
      "phone" : "(248) 565-8149",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/D0ASSkezoxmkd5lBn44Zd1tBJooZ1I9o.png",
      "instagramHandle" : "woodpilebbqshack",
      "merchantHandle" : "woodpilebbqshack",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Woodpile BBQ Shack",
      "city" : "Warren",
      "companyName" : "Woodpile BBQ Shack",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/woodpilebbqshack.com\/",
      "email" : ""
    }
  },
  "thecoffeebeantealeaf" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(805) 485-8112",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/hZVMBzORWg5FiQ8aN45IaS3EKliGXVSs.png",
      "instagramHandle" : "thecoffeebeantealeaf",
      "twitterHandle" : "",
      "merchantHandle" : "thecoffeebeantealeaf",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Coffee Bean & Tea Leaf",
      "city" : "Oxnard",
      "companyName" : "The Coffee Bean & Tea Leaf",
      "websiteUrl" : "https:\/\/www.coffeebean.com\/store\/usa\/oxnard\/oxnard?utm_source=GMB&utm_medium=Yext",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "rloautobody" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 996-9043",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/57bqE7FKsfWhQDmJClrqIVFIw29aLTQe.png",
      "instagramHandle" : "rloautobody",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "rloautobody",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Rlo Auto Body",
      "city" : "Modesto",
      "companyName" : "Rlo Auto Body",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "brooklynbagelbakery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(213) 318-5123",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "brooklynbagelbakery",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "brooklynbagelbakery",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Brooklyn Bagel Bakery",
      "city" : "Glendale",
      "companyName" : "Brooklyn Bagel Bakery",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.brooklynbagella.com\/",
      "email" : ""
    }
  },
  "broadstreetoystercompany" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(424) 323-4401",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/z6RQL9FhpgT08TqwEbm6fPl9vJXBfnd6.png",
      "instagramHandle" : "broadstreetoystercompany",
      "merchantHandle" : "broadstreetoystercompany",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Broad Street Oyster Company",
      "city" : "Oxnard",
      "companyName" : "Broad Street Oyster Company",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.broadstreetoyster.com\/",
      "email" : ""
    }
  },
  "birrieriaeltacosolito" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(559) 233-0507",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/cCUzIleWM05mJ09daCH1ObXl8sq9J6ah.jpg",
      "instagramHandle" : "birrieriaeltacosolito",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "birrieriaeltacosolito",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Birrieria El Taco Solito",
      "city" : "Fresno",
      "companyName" : "Birrieria El Taco Solito",
      "websiteUrl" : "http:\/\/www.eltacosolito.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "keckgraduateinstitute" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 607-7855",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/HXjuHEBqx0aNatQQh8FmblvcFnFG0gQr.png",
      "instagramHandle" : "keckgraduateinstitute",
      "twitterHandle" : "",
      "merchantHandle" : "keckgraduateinstitute",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Keck Graduate Institute",
      "city" : "Fontana",
      "companyName" : "Keck Graduate Institute",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.kgi.edu\/",
      "email" : ""
    }
  },
  "crumbleugene" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(541) 246-6565",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/0a0a4ceaced78842a4aec0a851ccc01c7fbea08f\/original.jpeg",
      "instagramHandle" : "crumbleugene",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "crumbleugene",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Crumbl - Eugene",
      "city" : "Eugene",
      "companyName" : "Crumbl - Eugene",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/crumblcookies.com\/eugene?utm_source=google+business&utm_medium=profile&utm_campaign=CRUMBL-US+%7C+GOOGLE+BUSINESS+%7C+STORE+PAGE+%7C+ONGOING+%7C+CONSIDERATION+%7C+PROFILE",
      "email" : ""
    }
  },
  "angelcakes" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(510) 301-4757",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/RCL6KQ9NRVJoGAP2qgMdWUx03052abnk.png",
      "instagramHandle" : "angelcakes",
      "merchantHandle" : "angelcakes",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Angel Cakes",
      "city" : "Oakland",
      "companyName" : "Angel Cakes",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.angelcakes510.com\/",
      "email" : ""
    }
  },
  "sohotaco" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 793-9392",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/6ee33bc2186e14cff0467b74852dfd5d35ac9177\/original.jpeg",
      "instagramHandle" : "sohotaco",
      "twitterHandle" : "",
      "merchantHandle" : "sohotaco",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "SOHO TACO",
      "city" : "Santa Ana",
      "companyName" : "SOHO TACO",
      "websiteUrl" : "http:\/\/www.sohotaco.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "faivorfreshproduceflowers" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(989) 323-0341",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/9727680e3a111e585c39969c44c47c34\/original.jpeg",
      "instagramHandle" : "faivorfreshproduceflowers",
      "premium" : false,
      "merchantHandle" : "faivorfreshproduceflowers",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Faivor Fresh Produce & Flowers",
      "city" : "Lansing",
      "companyName" : "Faivor Fresh Produce & Flowers",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/squareup.com\/market\/faivor-fresh-produce",
      "email" : ""
    }
  },
  "birdiespieshop" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(509) 241-3192",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/I3zYAQQfSW47Us7aIg842GvrlL7mjRX5.png",
      "instagramHandle" : "birdiespieshop",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "birdiespieshop",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Birdie's Pie Shop",
      "city" : "Spokane",
      "companyName" : "Birdie's Pie Shop",
      "websiteUrl" : "https:\/\/birdiespies.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "rawcanesuperjuice" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(323) 537-6611",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/WDbQ7K0az1MG1SeDsN2eRCM49x3fd4X4.png",
      "instagramHandle" : "rawcanesuperjuice",
      "merchantHandle" : "rawcanesuperjuice",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Raw Cane SuperJuice",
      "city" : "Glendale",
      "companyName" : "Raw Cane SuperJuice",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/sugarcanejuice.org\/",
      "email" : ""
    }
  },
  "joibirds" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/m5MrajR0J2Zfd6E4dMUdbT1YQakWRKoX.png",
      "instagramHandle" : "joibirds",
      "twitterHandle" : "",
      "merchantHandle" : "joibirds",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "JOiBIRDS",
      "city" : "Oxnard",
      "companyName" : "JOiBIRDS",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.joibirds.com\/",
      "email" : ""
    }
  },
  "rafaelskitchen" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 884-4559",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/zZklesjWxX3uGjwu0uO8saXALYI8CKLi.png",
      "instagramHandle" : "rafaelskitchen",
      "merchantHandle" : "rafaelskitchen",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "RAFAEL'S KITCHEN",
      "city" : "Santa Ana",
      "companyName" : "RAFAEL'S KITCHEN",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.rafaelskitchen.com\/",
      "email" : ""
    }
  },
  "braunct" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/54TzYOM2rZhdOupOjf9KoMunhM0l8Twk.png",
      "instagramHandle" : "braunct",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "braunct",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "327 Braun Ct",
      "city" : "Ann Arbor",
      "companyName" : "327 Braun Ct",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "portrestaurantandbar" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/UHROjUiF0VjInXoCiVKsX8bL7fFBig2B.png",
      "instagramHandle" : "portrestaurantandbar",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "portrestaurantandbar",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Port Restaurant and Bar",
      "city" : "Irvine",
      "companyName" : "Port Restaurant and Bar",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.portcdm.com\/",
      "email" : ""
    }
  },
  "iammovement" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(949) 783-9803",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "iammovement",
      "merchantHandle" : "iammovement",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "I AM Movement",
      "city" : "Irvine",
      "companyName" : "I AM Movement",
      "websiteUrl" : "https:\/\/iam-movement.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "jollityfarmgoatdairy" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(530) 444-0805",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/U3zi00YlZ9yIV729Wab2ipmss5UdfK7s.png",
      "instagramHandle" : "jollityfarmgoatdairy",
      "twitterHandle" : "",
      "merchantHandle" : "jollityfarmgoatdairy",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Jollity Farm Goat Dairy",
      "city" : "Sacramento",
      "companyName" : "Jollity Farm Goat Dairy",
      "websiteUrl" : "http:\/\/www.jollityfarm.net\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "barkside" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(313) 469-7434",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/tiWbTt1DH7A2Oj07OxDU3BBzWaPPy1KL.png",
      "instagramHandle" : "barkside",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "barkside",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Barkside",
      "city" : "Detroit",
      "companyName" : "Barkside",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.barksidedogbar.com\/",
      "email" : ""
    }
  },
  "sumtineat" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(810) 369-6601",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/2ZnWyijg0PkyrsdkGwY5IAnrECEKBnya.png",
      "instagramHandle" : "sumtineat",
      "twitterHandle" : "",
      "merchantHandle" : "sumtineat",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Sumtin' 2 Eat",
      "city" : "Flint",
      "companyName" : "Sumtin' 2 Eat",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/sumtin2eat.com\/",
      "email" : ""
    }
  },
  "nynebarbistro" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(509) 474-1621",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/884399ffb4a14ed6bb6fb260d2a92fec\/original.png",
      "instagramHandle" : "nynebarbistro",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "nynebarbistro",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "nYne Bar & Bistro",
      "city" : "Spokane",
      "companyName" : "nYne Bar & Bistro",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.nynebar.com\/",
      "email" : ""
    }
  },
  "kindnessmischiefcoffee" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/gV0fjF9KJDkuDxRcZMpsB6mc7C3sQz0O.png",
      "instagramHandle" : "kindnessmischiefcoffee",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "kindnessmischiefcoffee",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Kindness & Mischief Coffee",
      "city" : "Glendale",
      "companyName" : "Kindness & Mischief Coffee",
      "websiteUrl" : "http:\/\/kandmcoffee.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "caminobrewingcoandbeergarden" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(408) 352-5331",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/LymS0AR8d3QrpqOBwn8ty9xt70lGIisb.png",
      "instagramHandle" : "caminobrewingcoandbeergarden",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "caminobrewingcoandbeergarden",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Camino Brewing Co. and Beer Garden",
      "city" : "San Jose",
      "companyName" : "Camino Brewing Co. and Beer Garden",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "morganterritorybrewing" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 834-8664",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/fSfGvJ4UF03eK2fnPxZDtCQa3g9LsVBs.png",
      "instagramHandle" : "morganterritorybrewing",
      "merchantHandle" : "morganterritorybrewing",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Morgan Territory Brewing",
      "city" : "Stockton",
      "companyName" : "Morgan Territory Brewing",
      "websiteUrl" : "https:\/\/morganterritorybrewing.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "kasbahmoroccancafe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(971) 544-0875",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/aJfxnSWjcgLiOTziDyvezkCayLPEUqdm.jpg",
      "instagramHandle" : "kasbahmoroccancafe",
      "merchantHandle" : "kasbahmoroccancafe",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Kasbah Moroccan Cafe",
      "city" : "Portland",
      "companyName" : "Kasbah Moroccan Cafe",
      "websiteUrl" : "http:\/\/www.kasbahmoroccancafe.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "hapabarkada" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(503) 384-2254",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/V1s78g0Eu9YNa8Us2asTDapsh13cGf54.png",
      "instagramHandle" : "hapabarkada",
      "twitterHandle" : "",
      "merchantHandle" : "hapabarkada",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Hapa Barkada",
      "city" : "Portland",
      "companyName" : "Hapa Barkada",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/hapabarkadapdx.com\/",
      "email" : ""
    }
  },
  "ilivefordessert" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 222-4737",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/3f1c1aa8191ac97f02f06442682bcfa820f4366c\/original.jpeg",
      "instagramHandle" : "ilivefordessert",
      "merchantHandle" : "ilivefordessert",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "I Live For Dessert",
      "city" : "Stockton",
      "companyName" : "I Live For Dessert",
      "websiteUrl" : "https:\/\/ilivefordessert.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sweetbeezushoney" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(559) 709-1821",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/jiLihwxW2KyPy5ScZcJL0wG9ffonSn1V.png",
      "instagramHandle" : "sweetbeezushoney",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "sweetbeezushoney",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Sweet Beezus Honey",
      "city" : "Fresno",
      "companyName" : "Sweet Beezus Honey",
      "websiteUrl" : "http:\/\/sweetbeezushoney.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "flintcrepecompany" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(810) 336-3308",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/54BsggH3XxV0PlHIR8eGkQCe0mpnQ02s.png",
      "instagramHandle" : "flintcrepecompany",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "flintcrepecompany",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Flint Crepe Company",
      "city" : "Flint",
      "companyName" : "Flint Crepe Company",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/flintcrepe.com\/",
      "email" : ""
    }
  },
  "theminidonutcompany" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(971) 386-5445",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ax8p53ibOmhycXp8zmw1aMzTZIquRlhn.png",
      "instagramHandle" : "theminidonutcompany",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "theminidonutcompany",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Mini Donut Company",
      "city" : "Portland",
      "companyName" : "The Mini Donut Company",
      "websiteUrl" : "https:\/\/getminidonuts.com\/?utm_source=GBP-P",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "loveatfirstbitecatering" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 375-5566",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/bc4779fe7a3f28fdbaf2d289c740342dcb92956d\/original.png",
      "instagramHandle" : "loveatfirstbitecatering",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "loveatfirstbitecatering",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Love At First Bite Catering",
      "city" : "Irvine",
      "companyName" : "Love At First Bite Catering",
      "websiteUrl" : "http:\/\/www.lafbcatering.com\/?utm_source=google&utm_medium=wix_google_business_profile&utm_campaign=7202078055617761405",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "whitehousebanquetseventcenter" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 827-3836",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "whitehousebanquetseventcenter",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "whitehousebanquetseventcenter",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "White House Banquets & Event Center",
      "city" : "Anaheim",
      "companyName" : "White House Banquets & Event Center",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.whitehouseeventcenter.com\/",
      "email" : ""
    }
  },
  "philzcoffee" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(408) 971-4212",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/B5ctj4GSMxaALJa2vezbId4u0Eit0aBv.png",
      "instagramHandle" : "philzcoffee",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "philzcoffee",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Philz Coffee",
      "city" : "San Jose",
      "companyName" : "Philz Coffee",
      "websiteUrl" : "http:\/\/www.philzcoffee.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "watertable" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 845-4776",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/kGM7cmWQ02zTyPgzpDfidXqrpy6Bkakf.png",
      "instagramHandle" : "watertable",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "watertable",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Watertable",
      "city" : "Huntington Beach",
      "companyName" : "Watertable",
      "websiteUrl" : "http:\/\/www.watertablehb.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "dantescoffeeandtea" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/0ddc6843be719e9c32bb4bcb6417a09efcda9ff1\/original.jpeg",
      "instagramHandle" : "dantescoffeeandtea",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "dantescoffeeandtea",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Dante's Coffee and Tea",
      "city" : "Santa Ana",
      "companyName" : "Dante's Coffee and Tea",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "allfourone" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(313) 442-3604",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/sJnM2kRNlXxcvl2QqLv7MO9vmnrfhYGh.png",
      "instagramHandle" : "allfourone",
      "twitterHandle" : "",
      "merchantHandle" : "allfourone",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "All Four One",
      "city" : "Detroit",
      "companyName" : "All Four One",
      "websiteUrl" : "https:\/\/www.allfouronedetroit.org\/contact",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "chimulitaurbanmexicanfood" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 560-0333",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/2f26f76147300fe84d1ea0c485d5b644b9314337\/original.png",
      "instagramHandle" : "chimulitaurbanmexicanfood",
      "merchantHandle" : "chimulitaurbanmexicanfood",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Chimulita Urban Mexican Food",
      "city" : "Stockton",
      "companyName" : "Chimulita Urban Mexican Food",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "blondiesbar" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 864-2419",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/JAGTC67Vk1AtmkG3YFkH5I4b5Yzj3waD.png",
      "instagramHandle" : "blondiesbar",
      "twitterHandle" : "",
      "merchantHandle" : "blondiesbar",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Blondie's Bar",
      "city" : "San Francisco",
      "companyName" : "Blondie's Bar",
      "websiteUrl" : "https:\/\/www.blondiesbarsf.com\/?y_source=1_MjMyMTYwOTctNzE1LWxvY2F0aW9uLndlYnNpdGU%3D",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "dtsjbrewingco" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(408) 320-2300",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/77aa96ad39cdca15185d7a4e2d7435b113aff118\/original.png",
      "instagramHandle" : "dtsjbrewingco",
      "twitterHandle" : "",
      "merchantHandle" : "dtsjbrewingco",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "DTSJ Brewing Co",
      "city" : "San Jose",
      "companyName" : "DTSJ Brewing Co",
      "websiteUrl" : "http:\/\/www.dtsjbrewing.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sugashack" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(661) 335-2970",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/f8dcbd32411938bbb2fa48481cf5bef60890fc66\/original.jpeg",
      "instagramHandle" : "sugashack",
      "premium" : false,
      "merchantHandle" : "sugashack",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Suga Shack",
      "city" : "Bakersfield",
      "companyName" : "Suga Shack",
      "websiteUrl" : "http:\/\/lovesugashack.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "ourbar" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/cd4752beeee1bb7cf6bd1788816a704ad7e3b161\/original.jpeg",
      "instagramHandle" : "ourbar",
      "premium" : false,
      "merchantHandle" : "ourbar",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Our 206 Bar",
      "city" : "Flint",
      "companyName" : "Our 206 Bar",
      "websiteUrl" : "http:\/\/www.our206bar.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "contrivancewinemeadco" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(253) 246-7702",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "contrivancewinemeadco",
      "twitterHandle" : "",
      "merchantHandle" : "contrivancewinemeadco",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Contrivance Wine & Mead Co.",
      "city" : "Tacoma",
      "companyName" : "Contrivance Wine & Mead Co.",
      "websiteUrl" : "http:\/\/www.drinkcontrivance.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "ohanashack" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(559) 273-9161",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "ohanashack",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "ohanashack",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Ohana Shack",
      "city" : "Fresno",
      "companyName" : "Ohana Shack",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/808ohanashack.com\/",
      "email" : ""
    }
  },
  "fishsauce" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(503) 227-8000",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/QhNJHkO5krzSQVtHBYZEGDSxztlZMEEV.png",
      "instagramHandle" : "fishsauce",
      "twitterHandle" : "",
      "merchantHandle" : "fishsauce",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Fish Sauce",
      "city" : "Portland",
      "companyName" : "Fish Sauce",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/fishsaucepdx.com\/",
      "email" : ""
    }
  },
  "yetisshaveice" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/19ZPBUCicjt5cygTcMW2DiRaUaampGNj.png",
      "instagramHandle" : "yetisshaveice",
      "twitterHandle" : "",
      "merchantHandle" : "yetisshaveice",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Yeti's Shave Ice",
      "city" : "Eugene",
      "companyName" : "Yeti's Shave Ice",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.facebook.com\/share\/Y5HykTW2M68jsais\/?mibextid=qi2Omg",
      "email" : ""
    }
  },
  "madeinitalybistro" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(805) 370-8667",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/144ce9b3b52325387be1d24cb4ccd2ef4669a9b8\/original.png",
      "instagramHandle" : "madeinitalybistro",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "madeinitalybistro",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Made In Italy Bistro",
      "city" : "Oxnard",
      "companyName" : "Made In Italy Bistro",
      "websiteUrl" : "http:\/\/www.madeinitalybistro.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "vertexcoffeeroastersannarbor" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(734) 780-7060",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "vertexcoffeeroastersannarbor",
      "merchantHandle" : "vertexcoffeeroastersannarbor",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Vertex Coffee Roasters Ann Arbor",
      "city" : "Ann Arbor",
      "companyName" : "Vertex Coffee Roasters Ann Arbor",
      "websiteUrl" : "https:\/\/www.vertex-coffee.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "danielsgourmetfoods" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(805) 625-7200",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/f0y8RBa4F0VMBaCxJafjPctvLLCgpk8y.png",
      "instagramHandle" : "danielsgourmetfoods",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "danielsgourmetfoods",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Daniel's Gourmet Foods",
      "city" : "Oxnard",
      "companyName" : "Daniel's Gourmet Foods",
      "websiteUrl" : "https:\/\/www.danielsgourmetfoods.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "bigwormsbakeryanddeli" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 451-3375",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "bigwormsbakeryanddeli",
      "merchantHandle" : "bigwormsbakeryanddeli",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Bigworm's Bakery and Deli",
      "city" : "Stockton",
      "companyName" : "Bigworm's Bakery and Deli",
      "websiteUrl" : "https:\/\/www.visitstockton.org\/directory\/bigworms-bakery-and-deli\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "happycampercreamery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 283-4252",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/AL1x0J2tLF57j7cjlbrcVp5rPoErVeoo.png",
      "instagramHandle" : "happycampercreamery",
      "twitterHandle" : "",
      "merchantHandle" : "happycampercreamery",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Happy Camper Creamery",
      "city" : "San Bernardino",
      "companyName" : "Happy Camper Creamery",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.happycampercreamery.com\/",
      "email" : ""
    }
  },
  "supercone" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(989) 494-1441",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/CW9WXGEkTvJtX9ZCb29tLKvBP0slXdE1.jpg",
      "instagramHandle" : "supercone",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "supercone",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Supercone",
      "city" : "Lansing",
      "companyName" : "Supercone",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.facebook.com\/superconecorunna\/",
      "email" : ""
    }
  },
  "lattedacoffeehousewinebar" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(360) 448-7651",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ZTsNDk9L8FO0QcSTHjdxFzqrB4fGl30H.png",
      "instagramHandle" : "lattedacoffeehousewinebar",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "lattedacoffeehousewinebar",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Latte da Coffeehouse & Wine Bar",
      "city" : "Vancouver",
      "companyName" : "Latte da Coffeehouse & Wine Bar",
      "websiteUrl" : "https:\/\/lattedacoffeehouse.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "motivtcoffeeroasters" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 314-4475",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/tOwEq6OnzMedSyY9VYqO4LoGX9WW1EL8.png",
      "instagramHandle" : "motivtcoffeeroasters",
      "merchantHandle" : "motivtcoffeeroasters",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Motivāt Coffee Roasters",
      "city" : "Oakland",
      "companyName" : "Motivāt Coffee Roasters",
      "websiteUrl" : "http:\/\/motivatcoffee.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "lafonditaoaxaquea" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(971) 570-0165",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/0HHUmaXINa4agWe24r7SA5UFXHtaxYhf.png",
      "instagramHandle" : "lafonditaoaxaquea",
      "premium" : false,
      "merchantHandle" : "lafonditaoaxaquea",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "La Fondita Oaxaqueña",
      "city" : "Portland",
      "companyName" : "La Fondita Oaxaqueña",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "amadeusrestaurant" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(503) 362-8830",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/0250421d43bf0bae8cfbbbda9a9a4cfd058be546\/original.png",
      "instagramHandle" : "amadeusrestaurant",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "amadeusrestaurant",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Amadeus Restaurant",
      "city" : "Salem",
      "companyName" : "Amadeus Restaurant",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "mammamiasitalianrestaurantcatering" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(509) 467-7786",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/XYhfGYMT51gAOO3vGkNTXhacdgzx1weX.png",
      "instagramHandle" : "mammamiasitalianrestaurantcatering",
      "merchantHandle" : "mammamiasitalianrestaurantcatering",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Mamma Mia's Italian Restaurant & Catering",
      "city" : "Spokane",
      "companyName" : "Mamma Mia's Italian Restaurant & Catering",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.mammamiaspokane.com\/",
      "email" : ""
    }
  },
  "mattsbbq" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(503) 504-0870",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/0tqc0XcU8afEkF2nTxH00Cw5TiVYMT84.png",
      "instagramHandle" : "mattsbbq",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "mattsbbq",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Matt's BBQ",
      "city" : "Vancouver",
      "companyName" : "Matt's BBQ",
      "websiteUrl" : "http:\/\/www.mattsbbqpdx.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "doopedonbroadway" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(313) 989-4454",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/USWG3CfVNJuscK7e0aGdvaWypoZEMuZn.png",
      "instagramHandle" : "doopedonbroadway",
      "premium" : false,
      "merchantHandle" : "doopedonbroadway",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Dooped on Broadway",
      "city" : "Detroit",
      "companyName" : "Dooped on Broadway",
      "websiteUrl" : "http:\/\/www.getdooped.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "oakberryacai" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(562) 784-1319",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/MePKzoRbxvwwoAj5TbqLx0FSvGYpD1LQ.png",
      "instagramHandle" : "oakberryacai",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "oakberryacai",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Oakberry Acai",
      "city" : "Huntington Beach",
      "companyName" : "Oakberry Acai",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.oakberry.com\/",
      "email" : ""
    }
  },
  "amethystbeecakery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/552ff6eb5634f1d832fcca829d7b3d8b7c08e738\/original.png",
      "instagramHandle" : "amethystbeecakery",
      "merchantHandle" : "amethystbeecakery",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Amethyst Bee Cakery",
      "city" : "Grand Rapids",
      "companyName" : "Amethyst Bee Cakery",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "pinkelephantcupcakes" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(586) 552-5862",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/b0a3f76293d6e9c4cb2afab3257aa905412a5e37\/original.jpeg",
      "instagramHandle" : "pinkelephantcupcakes",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "pinkelephantcupcakes",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Pink Elephant Cupcakes",
      "city" : "Warren",
      "companyName" : "Pink Elephant Cupcakes",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.pinkelephantcupcakes.com\/",
      "email" : ""
    }
  },
  "eltaquerojuanhernandez" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 697-4423",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/00v6TEgZfsndR0zkguDNuLUBLAyAupap.png",
      "instagramHandle" : "eltaquerojuanhernandez",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "eltaquerojuanhernandez",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "El Taquero Juan Hernandez",
      "city" : "Santa Ana",
      "companyName" : "El Taquero Juan Hernandez",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "sweetstreetdesserts" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "sweetstreetdesserts",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "sweetstreetdesserts",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Sweet Street Desserts",
      "city" : "Tacoma",
      "companyName" : "Sweet Street Desserts",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "bluelinefoodservicedistributionlittlecaesarspizzadistributioncenter" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 829-1700",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/c7467e1c3f0b8ecf868fb73cf78def5672aa2fd9\/original.jpeg",
      "instagramHandle" : "bluelinefoodservicedistributionlittlecaesarspizzadistributioncenter",
      "merchantHandle" : "bluelinefoodservicedistributionlittlecaesarspizzadistributioncenter",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Blue Line Food Service Distribution\/Little Caesars Pizza Distribution Center",
      "city" : "Anaheim",
      "companyName" : "Blue Line Food Service Distribution\/Little Caesars Pizza Distribution Center",
      "websiteUrl" : "http:\/\/bluelinedist.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "preciousthingsfermentationproject" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(703) 945-9768",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ePuvgZkkz9GedlwyqQ2VVOEbyhaSbqcG.png",
      "instagramHandle" : "preciousthingsfermentationproject",
      "merchantHandle" : "preciousthingsfermentationproject",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Precious Things Fermentation Project",
      "city" : "Spokane",
      "companyName" : "Precious Things Fermentation Project",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.preciousthingsbeer.com\/",
      "email" : ""
    }
  },
  "upscalescateringkitchen" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 650-6748",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/N6We4O8geKzvmThMx6IHG3JPtiqv5rGz.png",
      "instagramHandle" : "upscalescateringkitchen",
      "premium" : false,
      "merchantHandle" : "upscalescateringkitchen",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Upscales Catering Kitchen",
      "city" : "Stockton",
      "companyName" : "Upscales Catering Kitchen",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "shakabrah" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(253) 572-2787",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/PW6TcGYuQ26m8jdM9zpZbGdayPNagLdV.png",
      "instagramHandle" : "shakabrah",
      "premium" : false,
      "merchantHandle" : "shakabrah",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Shakabrah",
      "city" : "Tacoma",
      "companyName" : "Shakabrah",
      "websiteUrl" : "http:\/\/shakabrah.love\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "somcordial" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(971) 800-3129",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/gs3yPQh3VbLquzl0KcCGWLAm1qNpaZYa.png",
      "instagramHandle" : "somcordial",
      "merchantHandle" : "somcordial",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Som Cordial",
      "city" : "Portland",
      "companyName" : "Som Cordial",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "rockcreekcoffeeroasters" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MT\r",
      "phone" : "(406) 896-1600",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/64c88891906aa133a5c6872e5b6cea4465270195\/original.jpeg",
      "instagramHandle" : "rockcreekcoffeeroasters",
      "premium" : false,
      "merchantHandle" : "rockcreekcoffeeroasters",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Rock Creek Coffee Roasters",
      "city" : "Billings",
      "companyName" : "Rock Creek Coffee Roasters",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/rockcreekcoffee.com\/",
      "email" : ""
    }
  },
  "douglassalchemyllc" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(971) 204-8759",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/0fIUPJvK8LCcBu0XoxH4CTz8SQFHDFfv.png",
      "instagramHandle" : "douglassalchemyllc",
      "twitterHandle" : "",
      "merchantHandle" : "douglassalchemyllc",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Douglass Alchemy LLC",
      "city" : "Portland",
      "companyName" : "Douglass Alchemy LLC",
      "websiteUrl" : "http:\/\/www.douglassalchemy.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "chosenvintage" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(509) 443-3602",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/s4XDOcyxu72Tmch8u7VwZAd9vJssg85d.png",
      "instagramHandle" : "chosenvintage",
      "twitterHandle" : "",
      "merchantHandle" : "chosenvintage",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Chosen Vintage",
      "city" : "Spokane",
      "companyName" : "Chosen Vintage",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "oregonictonicllc" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(503) 890-8273",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/b42ddf4c00533625f537935eff56c42d\/original.jpeg",
      "instagramHandle" : "oregonictonicllc",
      "merchantHandle" : "oregonictonicllc",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Oregonic Tonic LLC",
      "city" : "Vancouver",
      "companyName" : "Oregonic Tonic LLC",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "squeezetheday" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(810) 845-2480",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/a2bbc4f18a0c1894c2dd797b5945cf8ecd99a2d6\/original.jpeg",
      "instagramHandle" : "squeezetheday",
      "premium" : false,
      "merchantHandle" : "squeezetheday",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Squeeze The Day",
      "city" : "Flint",
      "companyName" : "Squeeze The Day",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.squeezetheday.shop\/",
      "email" : ""
    }
  },
  "lacebollita" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 979-1144",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/OmaeaZgxSBZ9AXBItJXRVt467mgm8SE2.png",
      "instagramHandle" : "lacebollita",
      "twitterHandle" : "",
      "merchantHandle" : "lacebollita",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "La Cebollita",
      "city" : "Santa Ana",
      "companyName" : "La Cebollita",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/la-cebollita.com\/",
      "email" : ""
    }
  },
  "drexl" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(510) 858-5398",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "drexl",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "drexl",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "DREXL",
      "city" : "Oakland",
      "companyName" : "DREXL",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.drexl.bar\/",
      "email" : ""
    }
  },
  "phothisway" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(408) 499-4841",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/zd4zhaFVtxAdiLoHHrv8GciY3w4c6FTm.png",
      "instagramHandle" : "phothisway",
      "merchantHandle" : "phothisway",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Pho This Way",
      "city" : "San Jose",
      "companyName" : "Pho This Way",
      "websiteUrl" : "https:\/\/pho-this-way-inc.square.site\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "choccookies" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(669) 238-5996",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/62d34e28fac798a325199a06b8d884e869a1b3ca\/original.jpeg",
      "instagramHandle" : "choccookies",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "choccookies",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Choc Cookies",
      "city" : "San Jose",
      "companyName" : "Choc Cookies",
      "websiteUrl" : "https:\/\/www.choccookies.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "winchellsdonuthouse" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 774-1885",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Zt2o3PLrrXPj3p0SVtKlugQ8LwLyaPPj.png",
      "instagramHandle" : "winchellsdonuthouse",
      "merchantHandle" : "winchellsdonuthouse",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Winchell's Donut House",
      "city" : "Anaheim",
      "companyName" : "Winchell's Donut House",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "thewashburnecaf" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(541) 746-7999",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/auCGqkZKQrnrYjU1QaXz1BU12irtuglv.png",
      "instagramHandle" : "thewashburnecaf",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "thewashburnecaf",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Washburne Café",
      "city" : "Eugene",
      "companyName" : "The Washburne Café",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.washburnecafe.com\/",
      "email" : ""
    }
  },
  "hardwoodbarsmokery" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 796-2437",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/RjMwjcOB8CHpD2wV1Ft8BBRO59eV0x7p.png",
      "instagramHandle" : "hardwoodbarsmokery",
      "premium" : false,
      "merchantHandle" : "hardwoodbarsmokery",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Hardwood Bar & Smokery",
      "city" : "San Francisco",
      "companyName" : "Hardwood Bar & Smokery",
      "websiteUrl" : "https:\/\/www.hardwoodsf.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "lacentralbakery" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(805) 483-8434",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/gKG2kL5cSsNWkDzFuErw0i9bARrEITpF.png",
      "instagramHandle" : "lacentralbakery",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "lacentralbakery",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "La Central Bakery",
      "city" : "Oxnard",
      "companyName" : "La Central Bakery",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/lacentralbakery.com\/",
      "email" : ""
    }
  },
  "thecheesecakefactory" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(253) 474-1112",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/0T6jekEBLdfzJfgGGoWFDmprQ48WrPaQ.png",
      "instagramHandle" : "thecheesecakefactory",
      "merchantHandle" : "thecheesecakefactory",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Cheesecake Factory",
      "city" : "Tacoma",
      "companyName" : "The Cheesecake Factory",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/locations.thecheesecakefactory.com\/wa\/tacoma-199.html?utm_source=Google&utm_medium=Maps&utm_campaign=Google+Places",
      "email" : ""
    }
  },
  "harvestcafe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 667-8386",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "harvestcafe",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "harvestcafe",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Harvest Cafe",
      "city" : "Oxnard",
      "companyName" : "Harvest Cafe",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.harvestcafeventura.com\/",
      "email" : ""
    }
  },
  "roasters" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(323) 352-8805",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/cf5e85e53f594fb517156d95a868d731f997a749\/original.jpeg",
      "instagramHandle" : "roasters",
      "merchantHandle" : "roasters",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "1802 Roasters",
      "city" : "Glendale",
      "companyName" : "1802 Roasters",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.1802roasters.com\/",
      "email" : ""
    }
  },
  "davenportconstructionandmechanical" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(253) 683-1590",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/xxhAhIa27BrBIZpTqKCPv5rOu7qoSD2u.png",
      "instagramHandle" : "davenportconstructionandmechanical",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "davenportconstructionandmechanical",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Davenport Construction and Mechanical",
      "city" : "Tacoma",
      "companyName" : "Davenport Construction and Mechanical",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "ahipoki" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 910-0302",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/gngXIqGMU89CH3mjE7a63a7nwST0rXbT.png",
      "instagramHandle" : "ahipoki",
      "premium" : false,
      "merchantHandle" : "ahipoki",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "AhiPoki",
      "city" : "Stockton",
      "companyName" : "AhiPoki",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.ahipokibowl.com\/",
      "email" : ""
    }
  },
  "partyparadise" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(586) 268-9800",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/5f07671e737f3184bea824d3c831405bd217a3b3\/original.png",
      "instagramHandle" : "partyparadise",
      "merchantHandle" : "partyparadise",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Party Paradise",
      "city" : "Sterling Heights",
      "companyName" : "Party Paradise",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.partyparadise.com\/",
      "email" : ""
    }
  },
  "udoggee" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 530-1924",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/BsxkRkKIJa5ao2TGBePWia6NOqHEWGMF.png",
      "instagramHandle" : "udoggee",
      "merchantHandle" : "udoggee",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "Catering",
      "squareSandboxKey" : "",
      "merchantName" : "U-Doggee",
      "city" : "Anaheim",
      "companyName" : "U-Doggee",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "palmettosuperfoodssantanarow" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/PAJ6aDZfOwxyy8wGZ8RfJVihPa7vLAwU.png",
      "instagramHandle" : "palmettosuperfoodssantanarow",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "palmettosuperfoodssantanarow",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Palmetto Superfoods - Santana Row",
      "city" : "San Jose",
      "companyName" : "Palmetto Superfoods - Santana Row",
      "websiteUrl" : "http:\/\/www.palmettosuperfoods.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "kabbtrlley" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 404-9756",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/H7AvwUkwVYF8eHNa9kgSLjCPnIDB6ZNj.png",
      "instagramHandle" : "kabbtrlley",
      "twitterHandle" : "",
      "merchantHandle" : "kabbtrlley",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Kaböb Trölley",
      "city" : "Oakland",
      "companyName" : "Kaböb Trölley",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.kabobtrolley.com\/",
      "email" : ""
    }
  },
  "verdecocinaenlaperla" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(503) 894-9321",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/5Oz16gb3Enr7PB0a91qEwBZGpW9ij4dy.png",
      "instagramHandle" : "verdecocinaenlaperla",
      "twitterHandle" : "",
      "merchantHandle" : "verdecocinaenlaperla",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Verde Cocina En La Perla",
      "city" : "Portland",
      "companyName" : "Verde Cocina En La Perla",
      "websiteUrl" : "https:\/\/www.verdecocinamarket.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "nonavopizza" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(360) 843-9696",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/oKcrfw7cZ0v6s02JGZa9pMpc2wY18usL.png",
      "instagramHandle" : "nonavopizza",
      "twitterHandle" : "",
      "merchantHandle" : "nonavopizza",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Nonavo Pizza",
      "city" : "Vancouver",
      "companyName" : "Nonavo Pizza",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/nonavopizza.com\/",
      "email" : ""
    }
  },
  "ramenpokesushi" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 379-0876",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/W0xTq95BEypSYHAaSXrqzB6anvZhnj0f.png",
      "instagramHandle" : "ramenpokesushi",
      "merchantHandle" : "ramenpokesushi",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "369 Ramen & Poke & Sushi",
      "city" : "Moreno Valley",
      "companyName" : "369 Ramen & Poke & Sushi",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "sanfranciscomeatco" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 529-2349",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "sanfranciscomeatco",
      "merchantHandle" : "sanfranciscomeatco",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "San Francisco Meat Co.",
      "city" : "San Francisco",
      "companyName" : "San Francisco Meat Co.",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.sfmeatco.com\/",
      "email" : ""
    }
  },
  "brewyardbeercompany" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(818) 409-9448",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/ed09f7bfe3c09c165734184aa21edd30369dee39\/original.jpeg",
      "instagramHandle" : "brewyardbeercompany",
      "premium" : false,
      "merchantHandle" : "brewyardbeercompany",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Brewyard Beer Company",
      "city" : "Glendale",
      "companyName" : "Brewyard Beer Company",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.brewyardbeercompany.com\/",
      "email" : ""
    }
  },
  "ganeshaindiancuisinesweetscatering" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(408) 246-7778",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/418KzQkYUpql8C76OM3qkV8TqwtKFyQ9.png",
      "instagramHandle" : "ganeshaindiancuisinesweetscatering",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "ganeshaindiancuisinesweetscatering",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Ganesha Indian Cuisine Sweets & Catering",
      "city" : "San Jose",
      "companyName" : "Ganesha Indian Cuisine Sweets & Catering",
      "websiteUrl" : "https:\/\/www.ganeshasantaclara.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "bravaempanadas" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(248) 716-0075",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/04673428151efaf955b05d8b993e55a7629f85eb\/original.jpeg",
      "instagramHandle" : "bravaempanadas",
      "premium" : false,
      "merchantHandle" : "bravaempanadas",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Brava Empanadas",
      "city" : "Ann Arbor",
      "companyName" : "Brava Empanadas",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.brava-empanadas.com\/",
      "email" : ""
    }
  },
  "sandorsgourmetcatering" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 292-4419",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "sandorsgourmetcatering",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "sandorsgourmetcatering",
      "companyType" : "Catering",
      "squareSandboxKey" : "",
      "merchantName" : "Sandor's Gourmet Catering",
      "city" : "Anaheim",
      "companyName" : "Sandor's Gourmet Catering",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/sandorsgourmetcatering.com\/?fbclid=IwAR3Hqn3f6IeAraRpKdAR4-DwtwH2EJuR43aloTtiKb75cBiG9xpI3DKzCi4",
      "email" : ""
    }
  },
  "mugshotcoffeeroasters" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(213) 264-1365",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/SQQdVE6d1q2eKZ0YtFy9QvJMWiUK7Em0.png",
      "instagramHandle" : "mugshotcoffeeroasters",
      "merchantHandle" : "mugshotcoffeeroasters",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Mugshot COFFEE Roasters",
      "city" : "Glendale",
      "companyName" : "Mugshot COFFEE Roasters",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "driftwood" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Ymyu7LLKMOuPL0eftZep1OLARL2w1tfp.png",
      "instagramHandle" : "driftwood",
      "premium" : false,
      "merchantHandle" : "driftwood",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Driftwood",
      "city" : "San Francisco",
      "companyName" : "Driftwood",
      "websiteUrl" : "https:\/\/www.driftwoodbarsf.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "melonseeddelifrozenyogurt" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(253) 279-0029",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/7a04fb79b89825c62760795a306c1b92\/original.png",
      "instagramHandle" : "melonseeddelifrozenyogurt",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "melonseeddelifrozenyogurt",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Melon Seed Deli & Frozen Yogurt",
      "city" : "Tacoma",
      "companyName" : "Melon Seed Deli & Frozen Yogurt",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/themelonseed.com\/",
      "email" : ""
    }
  },
  "jackboysdogbakery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 783-7939",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/8b11c02768e9b25db86d448cdd385f0c0d3c5fb2\/original.jpeg",
      "instagramHandle" : "jackboysdogbakery",
      "merchantHandle" : "jackboysdogbakery",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "Dog Treats",
      "merchantName" : "Jackboy's Dog Bakery",
      "city" : "Anaheim",
      "companyName" : "Jackboy's Dog Bakery",
      "websiteUrl" : "https:\/\/www.jackboydogbakery.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "revolucincoffeehouse" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(503) 224-3174",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/CcJIdlK0h8SqBMyMHzqqgkj57GzsZwzD.png",
      "instagramHandle" : "revolucincoffeehouse",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "revolucincoffeehouse",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Revolución Coffee House",
      "city" : "Portland",
      "companyName" : "Revolución Coffee House",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.revolucioncoffeehouse.com\/",
      "email" : ""
    }
  },
  "alpinenutrition" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(616) 608-6399",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/A9FGajzmr7C9fhKKUqBU1ojOG9HyHc1n.png",
      "instagramHandle" : "alpinenutrition",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "alpinenutrition",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Alpine Nutrition",
      "city" : "Grand Rapids",
      "companyName" : "Alpine Nutrition",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "mangiacateringcompany" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(844) 462-6442",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/f22853ba2753259e858bb6b51e451e51\/original.png",
      "instagramHandle" : "mangiacateringcompany",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "mangiacateringcompany",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Mangia Catering Company",
      "city" : "Spokane",
      "companyName" : "Mangia Catering Company",
      "websiteUrl" : "http:\/\/www.mangiacateringco.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "hesherspizzataproom" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(510) 990-1598",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/5pfgWqojWzoAvRZ06Qk4A1V1h07vaHaj.png",
      "instagramHandle" : "hesherspizzataproom",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "hesherspizzataproom",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Hesher's Pizza & Taproom",
      "city" : "Oakland",
      "companyName" : "Hesher's Pizza & Taproom",
      "websiteUrl" : "http:\/\/hesherspizza.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "fratefood" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(226) 975-5227",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "fratefood",
      "twitterHandle" : "",
      "merchantHandle" : "fratefood",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Frate Food",
      "city" : "Detroit",
      "companyName" : "Frate Food",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "tuesdayssweetshoppe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(562) 204-6029",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/U00aVrLegdCyDw4l5rbuqkCDay40ofme.png",
      "instagramHandle" : "tuesdayssweetshoppe",
      "twitterHandle" : "",
      "merchantHandle" : "tuesdayssweetshoppe",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Tuesday's Sweet Shoppe",
      "city" : "Long Beach",
      "companyName" : "Tuesday's Sweet Shoppe",
      "websiteUrl" : "http:\/\/www.tuesdaysla.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "cardaddicts" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(951) 563-0038",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "cardaddicts",
      "merchantHandle" : "cardaddicts",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Card Addicts",
      "city" : "Moreno Valley",
      "companyName" : "Card Addicts",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "kojakitchensacramento" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(916) 628-6829",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/c2df79582fd28c83f0a1a7bc0f133b978fc9676f\/original.png",
      "instagramHandle" : "kojakitchensacramento",
      "merchantHandle" : "kojakitchensacramento",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Koja Kitchen Sacramento",
      "city" : "Sacramento",
      "companyName" : "Koja Kitchen Sacramento",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.kojakitchensactown.com\/",
      "email" : ""
    }
  },
  "extendedstayamericatacomasouth" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(253) 475-6565",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/37rw8Ok1rKjClOOS91S81jeCG7jwAZs8.png",
      "instagramHandle" : "extendedstayamericatacomasouth",
      "premium" : false,
      "merchantHandle" : "extendedstayamericatacomasouth",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Extended Stay America - Tacoma - South",
      "city" : "Tacoma",
      "companyName" : "Extended Stay America - Tacoma - South",
      "websiteUrl" : "https:\/\/www.extendedstayamerica.com\/hotels\/wa\/tacoma\/south?channel=gmb-listing&utm_source=google&utm_medium=organic&utm_campaign=gmb_listing",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sugaricedcakesllc" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/W4erL6OpQjp2RSxCOYx6uj1bq9Ea8aDU.png",
      "instagramHandle" : "sugaricedcakesllc",
      "premium" : false,
      "merchantHandle" : "sugaricedcakesllc",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "SugarIced Cakes LLC",
      "city" : "Warren",
      "companyName" : "SugarIced Cakes LLC",
      "websiteUrl" : "https:\/\/sugaricedcakes-104159.square.site\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sanantoniorestaurant" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(424) 405-4205",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/b853b86bc9d78d05f01a0c3ec65aca399e5e5a2e\/original.jpeg",
      "instagramHandle" : "sanantoniorestaurant",
      "merchantHandle" : "sanantoniorestaurant",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "SAN ANTONIO RESTAURANT",
      "city" : "Long Beach",
      "companyName" : "SAN ANTONIO RESTAURANT",
      "websiteUrl" : "https:\/\/sanantoniorestaurantca.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "grapeape" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(503) 231-3467",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/bvYFfeUMeq83lqvmJ6ma6sOtGS33rmjo.png",
      "instagramHandle" : "grapeape",
      "premium" : false,
      "merchantHandle" : "grapeape",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Grape Ape",
      "city" : "Portland",
      "companyName" : "Grape Ape",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "miyazakisushi" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(586) 421-5731",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/xrgkse6zrWS6ayFSQByfBQ16tCAHyI6b.png",
      "instagramHandle" : "miyazakisushi",
      "twitterHandle" : "",
      "merchantHandle" : "miyazakisushi",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "MIYAZAKI Sushi",
      "city" : "Sterling Heights",
      "companyName" : "MIYAZAKI Sushi",
      "websiteUrl" : "http:\/\/miyazakiusa.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sourdoughco" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 312-9977",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/c3v5daeEdpbILVzwn3J8T8TDylgEQ1Zj.png",
      "instagramHandle" : "sourdoughco",
      "premium" : false,
      "merchantHandle" : "sourdoughco",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Sourdough & Co.",
      "city" : "Modesto",
      "companyName" : "Sourdough & Co.",
      "websiteUrl" : "https:\/\/sourdoughandco.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "civicwinerywines" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(541) 636-2990",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/zS84vTchOdv6a471Op0FsFuA7ckQHS80.png",
      "instagramHandle" : "civicwinerywines",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "civicwinerywines",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Civic Winery & Wines",
      "city" : "Eugene",
      "companyName" : "Civic Winery & Wines",
      "websiteUrl" : "http:\/\/www.civicwinery.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thesaltypearl" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/rboK59TjPIuRmza3dPIB0u7AIn4dXHEk.png",
      "instagramHandle" : "thesaltypearl",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "thesaltypearl",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Salty Pearl",
      "city" : "Oakland",
      "companyName" : "The Salty Pearl",
      "websiteUrl" : "https:\/\/www.saltypearl.us\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "scullery" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 440-4497",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/PxXNmzF9lD1Zvj756uH3yomv9Oiwtn0E.png",
      "instagramHandle" : "scullery",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "scullery",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Scullery",
      "city" : "San Francisco",
      "companyName" : "Scullery",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.scullerysf.com\/",
      "email" : ""
    }
  },
  "thesaltedcupcake" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(616) 990-9300",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/8OM78tqmZuuDrWvZnq5diuoziAdGXcRF.png",
      "instagramHandle" : "thesaltedcupcake",
      "premium" : false,
      "merchantHandle" : "thesaltedcupcake",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Salted Cupcake",
      "city" : "Grand Rapids",
      "companyName" : "The Salted Cupcake",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.thesaltedcupcake.com\/",
      "email" : ""
    }
  },
  "thecentersf" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 923-8180",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/6725605faf0701a933ff430b48cc0924d7e20fa1\/original.png",
      "instagramHandle" : "thecentersf",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "thecentersf",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Center SF",
      "city" : "San Francisco",
      "companyName" : "The Center SF",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.thecentersf.com\/",
      "email" : ""
    }
  },
  "angryhotchickennashvillehotchicken" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(408) 708-7203",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/7BlsIn0YqeWvrbkJ4qd5LuadE68C3F1M.png",
      "instagramHandle" : "angryhotchickennashvillehotchicken",
      "merchantHandle" : "angryhotchickennashvillehotchicken",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "ANGRY HOT CHICKEN (Nashville Hot chicken )",
      "city" : "San Jose",
      "companyName" : "ANGRY HOT CHICKEN (Nashville Hot chicken )",
      "websiteUrl" : "http:\/\/theangryhotchicken.business.site\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "raysredhots" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(734) 998-3647",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "raysredhots",
      "merchantHandle" : "raysredhots",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Ray's Red Hots",
      "city" : "Ann Arbor",
      "companyName" : "Ray's Red Hots",
      "websiteUrl" : "http:\/\/raysredhots.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sweetyos" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 202-4793",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/CaWkkTZs26LN8dQBVcBoL8GvPOUROPtj.png",
      "instagramHandle" : "sweetyos",
      "merchantHandle" : "sweetyos",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Sweet Yo's",
      "city" : "Grand Rapids",
      "companyName" : "Sweet Yo's",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "speerbbq" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 834-4204",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/bb244ad49af816d0ac5d98ec84acbdfb091276a8\/original.jpeg",
      "instagramHandle" : "speerbbq",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "speerbbq",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Speer BBQ",
      "city" : "Stockton",
      "companyName" : "Speer BBQ",
      "websiteUrl" : "https:\/\/www.speerbbq.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "theburgerden" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(562) 437-1992",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/tKiTyj6zVZPe55uPe01QoNyBI4qrppyX.png",
      "instagramHandle" : "theburgerden",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "theburgerden",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Burger Den",
      "city" : "Long Beach",
      "companyName" : "The Burger Den",
      "websiteUrl" : "https:\/\/theburgerden.com\/?y_source=1_MjU4NzM1NDgtNzE1LWxvY2F0aW9uLndlYnNpdGU%3D",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "hibachigrillbuffet" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 862-2888",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/e21a805786abbaebcae3a348f03439719070ff04\/original.jpeg",
      "instagramHandle" : "hibachigrillbuffet",
      "twitterHandle" : "",
      "merchantHandle" : "hibachigrillbuffet",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Hibachi grill buffet",
      "city" : "San Bernardino",
      "companyName" : "Hibachi grill buffet",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "heavenlyfreeze" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(559) 835-1300",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/0Fha0yytZIEncO21yatpTquGoSLIj8FQ.jpeg",
      "instagramHandle" : "heavenlyfreeze",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "heavenlyfreeze",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Heavenly Freeze",
      "city" : "Fresno",
      "companyName" : "Heavenly Freeze",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.heavenlyfreeze.com\/",
      "email" : ""
    }
  },
  "jitisindianfusionfoodbestindiancurrybestpizzabestfusionfoodrestaurant" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(248) 432-2766",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/4d43e60f77eb38c0e04783232a6a172e7c0819b2\/original.jpeg",
      "instagramHandle" : "jitisindianfusionfoodbestindiancurrybestpizzabestfusionfoodrestaurant",
      "merchantHandle" : "jitisindianfusionfoodbestindiancurrybestpizzabestfusionfoodrestaurant",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Jitis Indian Fusion Food | Best Indian Curry | Best Pizza | Best Fusion Food Restaurant",
      "city" : "Sterling Heights",
      "companyName" : "Jitis Indian Fusion Food | Best Indian Curry | Best Pizza | Best Fusion Food Restaurant",
      "websiteUrl" : "http:\/\/jitisfastfood.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "jean" : {
    "links" : {
      "jean-link-qpHKf" : {
        "linkItem" : {
          "linkID" : "jean-link-qpHKf",
          "linkStart" : "12\/12\/2024",
          "linkStyle" : {
            "fontColor" : "000000",
            "primaryColor" : "817BD1",
            "secondaryColor" : "50ED33"
          },
          "linkDescription" : "Great event coming soon",
          "linkUrl" : "www.example.com",
          "linkDate" : "12\/12\/2024",
          "linkType" : "event",
          "linkTitle" : "Great event ",
          "merchantname" : "jean",
          "linkEnd" : "12\/12\/2024"
        }
      }
    },
    "info" : {
      "posKey" : "-",
      "instagramHandle" : "https:\/\/www.instagram.com\/jean",
      "merchantHandle" : "jean",
      "companyName" : "CafeShop",
      "posSystem" : "Unknown",
      "merchantName" : "CafeShop",
      "tikTokHandle" : "https:\/\/www.tiktok.com\/@jean",
      "twitterHandle" : "https:\/\/twitter.com\/jean",
      "websiteUrl" : "http:\/\/cafeshop.com"
    }
  },
  "shadrachmeshachabeantogocoffeeroasters" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "",
      "phone" : "",
      "merchantImage" : "shadrachmeshachabeantogocoffeeroasters",
      "instagramHandle" : " Meshach & ABeanToGo Coffee Roasters\"",
      "merchantHandle" : "shadrachmeshachabeantogocoffeeroasters",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "(810) 636-9334",
      "squareSandboxKey" : "",
      "merchantName" : "https:\/\/square-web-production-f.squarecdn.com\/files\/50a7a4bdb7187ab817bad3e01080a909edfc4636\/original.jpeg",
      "city" : "",
      "companyName" : "\"Shadrach",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : "http:\/\/abeantogo.com\/"
    }
  },
  "tastiespastries" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(541) 255-2525",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/3d7a7e058f98c86ed67bf08d501fe2d89efddd90\/original.jpeg",
      "instagramHandle" : "tastiespastries",
      "premium" : false,
      "merchantHandle" : "tastiespastries",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Tasties Pastries",
      "city" : "Eugene",
      "companyName" : "Tasties Pastries",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/facebook.com\/TastiesJC\/menu",
      "email" : ""
    }
  },
  "dork" : {
    "analytics" : {
      "records" : {
        "landing-page" : {
          "gHVFHQQ7" : {
            "pathname" : "\/dork",
            "time" : "2024-06-27T05:38:10.530Z",
            "merchantHandle" : "dork",
            "host" : "cafe.bio",
            "timezone" : "America\/Los_Angeles",
            "userAgent" : "Mozilla\/5.0 (iPad; CPU OS 17_5 like Mac OS X) AppleWebKit\/605.1.15 (KHTML, like Gecko) CriOS\/126.0.6478.54 Mobile\/15E148 Safari\/604.1",
            "page" : "landing-page\/"
          }
        }
      }
    }
  },
  "angelinasgreekgyros" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(503) 477-5173",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/7e7efae652e46f7aaf289ffb7315847ffa6f3e38\/original.png",
      "instagramHandle" : "angelinasgreekgyros",
      "merchantHandle" : "angelinasgreekgyros",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Angelina's Greek Gyros",
      "city" : "Portland",
      "companyName" : "Angelina's Greek Gyros",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/pdxgyros.com\/",
      "email" : ""
    }
  },
  "kjscafe" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/MLez0RDxTEky5J8S7H7ZosNcGi2nkU6r.png",
      "instagramHandle" : "kjscafe",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "kjscafe",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "KJs Cafe",
      "city" : "San Jose",
      "companyName" : "KJs Cafe",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "epicureangroup" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(415) 895-2800",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/kNzni8MxBDLYQPFe344TwzYWR4FQOoEI.png",
      "instagramHandle" : "epicureangroup",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "epicureangroup",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Epicurean Group",
      "city" : "Portland",
      "companyName" : "Epicurean Group",
      "websiteUrl" : "http:\/\/www.epicurean-group.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "koezeretailknapp" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(616) 364-8214",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "koezeretailknapp",
      "merchantHandle" : "koezeretailknapp",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Koeze Retail - Knapp",
      "city" : "Grand Rapids",
      "companyName" : "Koeze Retail - Knapp",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.koeze.com\/",
      "email" : ""
    }
  },
  "icecreamcoandgrill" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 578-3112",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/7pGnJztFXzGqZ7jT0fv7ck3DyiZvzD3C.png",
      "instagramHandle" : "icecreamcoandgrill",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "icecreamcoandgrill",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Ice Cream Co and Grill",
      "city" : "Modesto",
      "companyName" : "Ice Cream Co and Grill",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.icecreamcompany.org\/",
      "email" : ""
    }
  },
  "lilikoiboba" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/MHvkvHQbuWxq5Sos54kgIu0wwgTviZcp.png",
      "instagramHandle" : "lilikoiboba",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "lilikoiboba",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Lilikoi Boba",
      "city" : "San Jose",
      "companyName" : "Lilikoi Boba",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/lilikoiwillowglen.com\/",
      "email" : ""
    }
  },
  "huntingtonbeach" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/dkEVnndmk7eCPDkyjaNF2acLlfXoeUXQ.png",
      "instagramHandle" : "huntingtonbeach",
      "premium" : false,
      "merchantHandle" : "huntingtonbeach",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "huntington beach",
      "city" : "Huntington Beach",
      "companyName" : "huntington beach",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "beyondjuiceryeatery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(586) 307-6111",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/rsi8Ct8axTTBwhDQcNH4Y9S3zDaej7LS.png",
      "instagramHandle" : "beyondjuiceryeatery",
      "twitterHandle" : "",
      "merchantHandle" : "beyondjuiceryeatery",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Beyond Juicery + Eatery",
      "city" : "Warren",
      "companyName" : "Beyond Juicery + Eatery",
      "websiteUrl" : "https:\/\/beyondjuiceryeatery.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "mazcafconlechecoffeeshoporangecounty" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 479-0104",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/eLCxwdzxP3l0YKLpPzDzIZG9kgqhzWAs.png",
      "instagramHandle" : "mazcafconlechecoffeeshoporangecounty",
      "merchantHandle" : "mazcafconlechecoffeeshoporangecounty",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "MAZ Café Con Leche - Coffee Shop Orange County",
      "city" : "Santa Ana",
      "companyName" : "MAZ Café Con Leche - Coffee Shop Orange County",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.maz-cafe.com\/",
      "email" : ""
    }
  },
  "oasisvegetariancafe" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(951) 688-5423",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/093fd4b0293cf6edb69423b8cbafd83e75355a27\/original.png",
      "instagramHandle" : "oasisvegetariancafe",
      "premium" : false,
      "merchantHandle" : "oasisvegetariancafe",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Oasis Vegetarian Cafe",
      "city" : "Riverside",
      "companyName" : "Oasis Vegetarian Cafe",
      "websiteUrl" : "http:\/\/www.oasisvegetarian.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "lagunacookieco" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 546-6855",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/9a3d788a1f80206a3591e2626bc4356cad496e17\/original.jpeg",
      "instagramHandle" : "lagunacookieco",
      "premium" : false,
      "merchantHandle" : "lagunacookieco",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Laguna Cookie Co",
      "city" : "Santa Ana",
      "companyName" : "Laguna Cookie Co",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "honeybuttermacarons" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(949) 997-0500",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/v6MzIyrwE7vUuRSwTX2ruv0CMpsBZxxV.png",
      "instagramHandle" : "honeybuttermacarons",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "honeybuttermacarons",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Honey & Butter Macarons",
      "city" : "Irvine",
      "companyName" : "Honey & Butter Macarons",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.honeynbutter.com\/",
      "email" : ""
    }
  },
  "merchant" : {
    "info" : {
      "posKey" : "-",
      "instagramHandle" : "https:\/\/www.instagram.com\/cafetest",
      "merchantHandle" : "merchant",
      "companyName" : "Cafe Test",
      "posSystem" : "Unknown",
      "merchantName" : "Cafe Test",
      "tikTokHandle" : "https:\/\/www.tiktok.com\/@cafetest",
      "twitterHandle" : "https:\/\/twitter.com\/cafetest",
      "websiteUrl" : "http:\/\/cafetest.com"
    }
  },
  "betsyfry" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(616) 450-8883",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "betsyfry",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "betsyfry",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Betsy Fry",
      "city" : "Grand Rapids",
      "companyName" : "Betsy Fry",
      "websiteUrl" : "http:\/\/www.betsyfry.com\/ContactMe",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "7thwest" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 290-6501",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/5X9zJZgf8xZp07ErSGsIEhkUjZnTZ7F3.png",
      "instagramHandle" : "7thwest",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "7thwest",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "7th West",
      "city" : "Oakland",
      "companyName" : "7th West",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.7thwest.com\/",
      "email" : ""
    }
  },
  "kroger" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(517) 393-6622",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/OhhRf5wKYCuLgMENa02BRrVQz0aWuQTm.png",
      "instagramHandle" : "kroger",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "kroger",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Kroger",
      "city" : "Lansing",
      "companyName" : "Kroger",
      "websiteUrl" : "https:\/\/www.kroger.com\/stores\/grocery\/mi\/lansing\/holmes-logan\/018\/00884?cid=loc_01800884_gmb",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "spoonpork" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(323) 922-6061",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/uLxauWWr09903za7EHoanFsEBaleRwU6.png",
      "instagramHandle" : "spoonpork",
      "merchantHandle" : "spoonpork",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Spoon & Pork",
      "city" : "Glendale",
      "companyName" : "Spoon & Pork",
      "websiteUrl" : "http:\/\/spoonandpork.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "originalkabobfactory" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(818) 500-9500",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Xv1nFrvc9fusTDkMDyk148UCwxQJvWgC.png",
      "instagramHandle" : "originalkabobfactory",
      "merchantHandle" : "originalkabobfactory",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Original Kabob Factory",
      "city" : "Glendale",
      "companyName" : "Original Kabob Factory",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.originalkabobfactory.com\/",
      "email" : ""
    }
  },
  "icecreamlandtruck" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(619) 752-0116",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ot18wtzA4P9rsGRWDu6BDMXdnCuk88yy.png",
      "instagramHandle" : "icecreamlandtruck",
      "merchantHandle" : "icecreamlandtruck",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Ice Cream Land Truck",
      "city" : "Chula Vista",
      "companyName" : "Ice Cream Land Truck",
      "websiteUrl" : "https:\/\/icecreamlandtruck.com\/?utm_campaign=gmb",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "productivityparlourforartfulliving" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(253) 271-9174",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/25ae29d652484a5d87132cd4722f7f2363db2dc0\/original.png",
      "instagramHandle" : "productivityparlourforartfulliving",
      "merchantHandle" : "productivityparlourforartfulliving",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Productivity Parlour for Artful Living",
      "city" : "Tacoma",
      "companyName" : "Productivity Parlour for Artful Living",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "esleinc" : {
    "info" : {
      "state" : "",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "esleinc",
      "instagramHandle" : " Inc.\"",
      "premium" : false,
      "merchantHandle" : "esleinc",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "(916) 242-4422",
      "merchantName" : "No Logo Image Found on Website",
      "city" : "",
      "companyName" : "\"ESLE",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : "http:\/\/www.esleinc.com\/"
    }
  },
  "unfurlcuisine" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 757-0349",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/4qKVFjq9NjOOl5ULvS9C0akfoaY1JBp9.png",
      "instagramHandle" : "unfurlcuisine",
      "premium" : false,
      "merchantHandle" : "unfurlcuisine",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Unfurl Cuisine",
      "city" : "San Francisco",
      "companyName" : "Unfurl Cuisine",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "tomatoalleyfreehouse" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 228-4880",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/gFG9korq9ieosCbrt8Janjo0AquUXnRI.jpeg",
      "instagramHandle" : "tomatoalleyfreehouse",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "tomatoalleyfreehouse",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Tomato Alley Free House",
      "city" : "Sacramento",
      "companyName" : "Tomato Alley Free House",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/tomatoalley.com\/",
      "email" : ""
    }
  },
  "vikingbrewingcompany" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(541) 653-8371",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/aCrIo2VCRa9Dbv5dtvEASdZ8w5Ij79vI.png",
      "instagramHandle" : "vikingbrewingcompany",
      "merchantHandle" : "vikingbrewingcompany",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Viking Brewing Company",
      "city" : "Eugene",
      "companyName" : "Viking Brewing Company",
      "websiteUrl" : "http:\/\/www.drinkviking.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sanjose" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "sanjose",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "sanjose",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "San Jose",
      "city" : "San Jose",
      "companyName" : "San Jose",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "localefarmtotableeatery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(661) 322-9090",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/8fvtC8CRZvu2xxWSsSvNCduGzRv4vQ1s.png",
      "instagramHandle" : "localefarmtotableeatery",
      "twitterHandle" : "",
      "merchantHandle" : "localefarmtotableeatery",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Locale Farm to Table Eatery",
      "city" : "Bakersfield",
      "companyName" : "Locale Farm to Table Eatery",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.localefarmtotable.com\/contact.html",
      "email" : ""
    }
  },
  "iehpcommunityresourcecenterriverside" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(866) 228-4347",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/3a94bf5205b96ba727efdd3b61354c27b1f9fe2a\/original.png",
      "instagramHandle" : "iehpcommunityresourcecenterriverside",
      "twitterHandle" : "",
      "merchantHandle" : "iehpcommunityresourcecenterriverside",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "IEHP COMMUNITY RESOURCE CENTER RIVERSIDE",
      "city" : "Riverside",
      "companyName" : "IEHP COMMUNITY RESOURCE CENTER RIVERSIDE",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.iehp.org\/crc",
      "email" : ""
    }
  },
  "breakfastcure" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(541) 600-4253",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/OipuhdzOMSziWsiPtt8acRFFSVza54KH.png",
      "instagramHandle" : "breakfastcure",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "breakfastcure",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Breakfast Cure",
      "city" : "Eugene",
      "companyName" : "Breakfast Cure",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/breakfastcure.com\/",
      "email" : ""
    }
  },
  "parliamentchocolate" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/49a5a4ed02e8c2501d366bfde51941b3aea46fa6\/original.jpeg",
      "instagramHandle" : "parliamentchocolate",
      "premium" : false,
      "merchantHandle" : "parliamentchocolate",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Parliament Chocolate",
      "city" : "San Bernardino",
      "companyName" : "Parliament Chocolate",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/parliamentchocolate.com\/",
      "email" : ""
    }
  },
  "rusebrewingcrustcollective" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(360) 953-8394",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/hmKYJjwVUUWUF5sQrtzBaODrgDy6F6FP.png",
      "instagramHandle" : "rusebrewingcrustcollective",
      "twitterHandle" : "",
      "merchantHandle" : "rusebrewingcrustcollective",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Ruse Brewing Crust Collective",
      "city" : "Vancouver",
      "companyName" : "Ruse Brewing Crust Collective",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/crustcollective.com\/",
      "email" : ""
    }
  },
  "docfoodwine" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(503) 946-8592",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/G0sYXuue4Xa9dvpPqBisuhI0k07XJaIg.png",
      "instagramHandle" : "docfoodwine",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "docfoodwine",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "DOC food + wine",
      "city" : "Vancouver",
      "companyName" : "DOC food + wine",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "levantkitchen" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(734) 359-5155",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/c37eefe3b0154b9ed91026721f8c6f6d0f324992\/original.jpeg",
      "instagramHandle" : "levantkitchen",
      "twitterHandle" : "",
      "merchantHandle" : "levantkitchen",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Levant Kitchen",
      "city" : "Ann Arbor",
      "companyName" : "Levant Kitchen",
      "websiteUrl" : "http:\/\/levantkitchen.co\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thekebabshop" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 656-4458",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ToY1m3oOdRljIUKM4rj3V5Fg8vLoWdF0.png",
      "instagramHandle" : "thekebabshop",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "thekebabshop",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Kebab Shop",
      "city" : "Santa Ana",
      "companyName" : "The Kebab Shop",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/thekebabshop.com\/locations\/ca\/orange-county\/walnut-village\/",
      "email" : ""
    }
  },
  "hotdogonastick" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 656-0538",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/o5c3sIInEUSVxNlWay9q0IuCkBezdHpV.jpeg",
      "instagramHandle" : "hotdogonastick",
      "merchantHandle" : "hotdogonastick",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Hot Dog on a Stick",
      "city" : "Moreno Valley",
      "companyName" : "Hot Dog on a Stick",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/hotdogonastick.com\/?olonwp=JjBtp_vMLk25gkYh_bnoiQ",
      "email" : ""
    }
  },
  "hisushi" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(586) 510-6037",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/o9p2dN9g4lRzC5T60Ctla5LhLzMUR21m.png",
      "instagramHandle" : "hisushi",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "hisushi",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Hi Sushi",
      "city" : "Warren",
      "companyName" : "Hi Sushi",
      "websiteUrl" : "http:\/\/hisushiwarren.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "heyneighborcafe" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 619-4091",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/BzOqkD44llMqn1mYa6nPQdZA6CZlju5M.png",
      "instagramHandle" : "heyneighborcafe",
      "twitterHandle" : "",
      "merchantHandle" : "heyneighborcafe",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Hey Neighbor Cafe",
      "city" : "San Francisco",
      "companyName" : "Hey Neighbor Cafe",
      "websiteUrl" : "https:\/\/heyneighborcafe.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "saadhalalmeatscanton" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(734) 738-1168",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/6d1cd12dd809aeba7e951d96074879f5912d750f\/original.png",
      "instagramHandle" : "saadhalalmeatscanton",
      "merchantHandle" : "saadhalalmeatscanton",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Saad Halal Meats Canton",
      "city" : "Ann Arbor",
      "companyName" : "Saad Halal Meats Canton",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/saadmeatscanton.com\/",
      "email" : ""
    }
  },
  "lsxo" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 374-0038",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/BpFs0e4JINDFhTJ8FHOX5EBiZzbK1dyR.png",
      "instagramHandle" : "lsxo",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "lsxo",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "LSXO",
      "city" : "Huntington Beach",
      "companyName" : "LSXO",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.dinebluegold.com\/",
      "email" : ""
    }
  },
  "gonzarfarmsinc" : {
    "info" : {
      "state" : "",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "gonzarfarmsinc",
      "instagramHandle" : " Inc\"",
      "twitterHandle" : "",
      "merchantHandle" : "gonzarfarmsinc",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "(805) 351-0224",
      "squareSandboxKey" : "",
      "merchantName" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/1LJwjs9bDfF5TnQOl0YhhFFAU3or9mZa.jpeg",
      "city" : "",
      "companyName" : "\"Gonzar Farms",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : "http:\/\/www.gonzarfarms.com\/"
    }
  },
  "westsidemarket" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(503) 227-7092",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/qzc0ctS5c3Np2SospPunoEvzDEleNGan.png",
      "instagramHandle" : "westsidemarket",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "westsidemarket",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Westside Market",
      "city" : "Portland",
      "companyName" : "Westside Market",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "dreamericecream" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(206) 395-4193",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ZQHDZCKy7G20LPP0rpWl5bIQcfJAqNsO.png",
      "instagramHandle" : "dreamericecream",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "dreamericecream",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Dreamer Ice Cream",
      "city" : "Tacoma",
      "companyName" : "Dreamer Ice Cream",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/dreamer-ice-cream.square.site\/",
      "email" : ""
    }
  },
  "backalleybowlingglendaleformerlyjewelcitybowl" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(818) 243-1188",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/9EoBTpFMejGsvoEX0qPQFGYmGSlO23W1.png",
      "instagramHandle" : "backalleybowlingglendaleformerlyjewelcitybowl",
      "twitterHandle" : "",
      "merchantHandle" : "backalleybowlingglendaleformerlyjewelcitybowl",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Back Alley Bowling - Glendale (formerly Jewel City Bowl)",
      "city" : "Glendale",
      "companyName" : "Back Alley Bowling - Glendale (formerly Jewel City Bowl)",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.backalleybowling.com\/",
      "email" : ""
    }
  },
  "kuppajoycoffeehousegrandcafefresno" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(559) 207-3101",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ZHKdlAN0a4EuH5rbhNXWMqabzVDSgugl.png",
      "instagramHandle" : "kuppajoycoffeehousegrandcafefresno",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "kuppajoycoffeehousegrandcafefresno",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Kuppa Joy Coffee House - Grand Cafe Fresno",
      "city" : "Fresno",
      "companyName" : "Kuppa Joy Coffee House - Grand Cafe Fresno",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.kuppajoy.com\/",
      "email" : ""
    }
  },
  "stellas" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(509) 290-1262",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/f5b444e69fca0a4583ecc86a4a7885c90593b2da\/original.jpeg",
      "instagramHandle" : "stellas",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "stellas",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Stella’s",
      "city" : "Spokane",
      "companyName" : "Stella’s",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "countrywesternmobilehomervpark" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 850-7704",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "countrywesternmobilehomervpark",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "countrywesternmobilehomervpark",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Country Western Mobile Home & RV Park",
      "city" : "Modesto",
      "companyName" : "Country Western Mobile Home & RV Park",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thefreshplatemarketcateringcompany" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(509) 808-6195",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/lMottD0fUC6K5fvVSJXPRamhQXIB2LKX.png",
      "instagramHandle" : "thefreshplatemarketcateringcompany",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "thefreshplatemarketcateringcompany",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Fresh Plate Market & Catering Company",
      "city" : "Spokane",
      "companyName" : "The Fresh Plate Market & Catering Company",
      "websiteUrl" : "https:\/\/www.freshplatemarket.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "f8nightclubbar" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 857-1192",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/YR6R9tHv06BvYjB0hCZEwDD93aPbJ0k8.png",
      "instagramHandle" : "f8nightclubbar",
      "premium" : false,
      "merchantHandle" : "f8nightclubbar",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "F8 Nightclub & Bar",
      "city" : "San Francisco",
      "companyName" : "F8 Nightclub & Bar",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/feightsf.com\/",
      "email" : ""
    }
  },
  "pacificextremesports" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(916) 646-3474",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/n7m1QKt6a6VOd0wzJkJasnO01CbDVMAJ.png",
      "instagramHandle" : "pacificextremesports",
      "twitterHandle" : "",
      "merchantHandle" : "pacificextremesports",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Pacific Extreme Sports",
      "city" : "Sacramento",
      "companyName" : "Pacific Extreme Sports",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "brewedcafepub" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(360) 597-3386",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/MdVCulps0YU5xzjzj787Wxz7opQ0eUCH.png",
      "instagramHandle" : "brewedcafepub",
      "merchantHandle" : "brewedcafepub",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Brewed Cafe & Pub",
      "city" : "Vancouver",
      "companyName" : "Brewed Cafe & Pub",
      "websiteUrl" : "https:\/\/www.brewedcafepub.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "mikeys" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 518-7014",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/C06LOmsQAittwGaDFFb4nyg90bycX1lX.png",
      "instagramHandle" : "mikeys",
      "premium" : false,
      "merchantHandle" : "mikeys",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Mikey's",
      "city" : "Stockton",
      "companyName" : "Mikey's",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "baobabfare" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(313) 265-3093",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/0KMNgbuaiAAYEVjXckp6a1Jj2HyD0Uot.png",
      "instagramHandle" : "baobabfare",
      "merchantHandle" : "baobabfare",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Baobab Fare",
      "city" : "Detroit",
      "companyName" : "Baobab Fare",
      "websiteUrl" : "http:\/\/www.baobabfare.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "themudbar" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/cc60dd0c71d450c7b89e627e88e397349d13c0ea\/original.jpeg",
      "instagramHandle" : "themudbar",
      "merchantHandle" : "themudbar",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Mudbar",
      "city" : "Chula Vista",
      "companyName" : "The Mudbar",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/m.facebook.com\/the-mudbar-121653161197242\/",
      "email" : ""
    }
  },
  "hiddencafe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 484-9674",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/51a0a51470413710677608b9ddbc5f1f979793ef\/original.png",
      "instagramHandle" : "hiddencafe",
      "merchantHandle" : "hiddencafe",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Hidden Cafe",
      "city" : "Oxnard",
      "companyName" : "Hidden Cafe",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thegentlepenguin" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Be31RsNfEqm1Bo6UupHP0zLBrK4a5nxl.png",
      "instagramHandle" : "thegentlepenguin",
      "merchantHandle" : "thegentlepenguin",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Gentle Penguin",
      "city" : "Chula Vista",
      "companyName" : "The Gentle Penguin",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "kvltmead" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(253) 666-8739",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/aF5bnMJ8ygcVNIGqMm0NiIlahBs5hSWo.png",
      "instagramHandle" : "kvltmead",
      "merchantHandle" : "kvltmead",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "KVLT Mead",
      "city" : "Tacoma",
      "companyName" : "KVLT Mead",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "candified" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(562) 588-9008",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "candified",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "candified",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Candified",
      "city" : "Long Beach",
      "companyName" : "Candified",
      "websiteUrl" : "http:\/\/www.itscandified.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "highlyflavrd" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(517) 798-0871",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/0LrnN9lFjw89icqAZ62td5eLdclyleLc.png",
      "instagramHandle" : "highlyflavrd",
      "merchantHandle" : "highlyflavrd",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Highly Flavrd",
      "city" : "Lansing",
      "companyName" : "Highly Flavrd",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "aquitainewinebarbistro" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 658-7863",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/69089ede3af05ffe5f55e71677eecbedfe843783\/original.png",
      "instagramHandle" : "aquitainewinebarbistro",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "aquitainewinebarbistro",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Aquitaine Wine Bar & Bistro",
      "city" : "San Francisco",
      "companyName" : "Aquitaine Wine Bar & Bistro",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/aquitainesf.com\/",
      "email" : ""
    }
  },
  "kokiorepublic" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 376-8394",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/1fa3abf9501b4bcf6a546d28d27f332e4d7dec91\/original.png",
      "instagramHandle" : "kokiorepublic",
      "twitterHandle" : "",
      "merchantHandle" : "kokiorepublic",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Kokio Republic",
      "city" : "San Francisco",
      "companyName" : "Kokio Republic",
      "websiteUrl" : "http:\/\/www.kokiorepublicsf.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "haberdasher" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(408) 792-7356",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/JamqCag8tAqHwXZl5YdOJa2RNnuzJB0J.png",
      "instagramHandle" : "haberdasher",
      "merchantHandle" : "haberdasher",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Haberdasher",
      "city" : "San Jose",
      "companyName" : "Haberdasher",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "lecadeauachocolateboutique" : {
    "info" : {
      "state" : "",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "lecadeauachocolateboutique",
      "instagramHandle" : " a Chocolate Boutique\"",
      "premium" : false,
      "merchantHandle" : "lecadeauachocolateboutique",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "(916) 485-8000",
      "squareSandboxKey" : "",
      "merchantName" : "https:\/\/square-web-production-f.squarecdn.com\/files\/ff19370cc933e2ea3cb5806bdaa223f015490e18\/original.png",
      "city" : "",
      "companyName" : "\"Le Cadeau",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : "http:\/\/mylecadeau.com\/"
    }
  },
  "ellerbakery" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 716-5850",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/IeZ1k0QP6SruHHmUq0xhd8aCArNlz22g.png",
      "instagramHandle" : "ellerbakery",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "ellerbakery",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Eller Bakery",
      "city" : "Huntington Beach",
      "companyName" : "Eller Bakery",
      "websiteUrl" : "http:\/\/ellerbakery.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thebigdipper" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 838-7063",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/TaiaC4ABNRsloEqzvgxondkjiSPqGwwo.png",
      "instagramHandle" : "thebigdipper",
      "merchantHandle" : "thebigdipper",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Big Dipper",
      "city" : "Modesto",
      "companyName" : "The Big Dipper",
      "websiteUrl" : "https:\/\/the-big-dipper-908559.square.site\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thebeerhall" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 800-7416",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/jV8nTqAyDUfGIEucojEj8LFAJdgm3BFU.png",
      "instagramHandle" : "thebeerhall",
      "twitterHandle" : "",
      "merchantHandle" : "thebeerhall",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Beer Hall",
      "city" : "San Francisco",
      "companyName" : "The Beer Hall",
      "websiteUrl" : "https:\/\/www.thebeerhallsf.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "antoniouspizzacafe" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(951) 682-9100",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/c6MRWXQcniKRKjuIJk65pp1wBoy0Ipnh.png",
      "instagramHandle" : "antoniouspizzacafe",
      "merchantHandle" : "antoniouspizzacafe",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Antonious Pizza Cafe",
      "city" : "Riverside",
      "companyName" : "Antonious Pizza Cafe",
      "websiteUrl" : "https:\/\/www.antoniouspizzanw.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "terracoffee" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/3e214f265f61f6153ca1362ea2bc058bdfd590ef\/original.png",
      "instagramHandle" : "terracoffee",
      "merchantHandle" : "terracoffee",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Terra Coffee",
      "city" : "Stockton",
      "companyName" : "Terra Coffee",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.terracoffee.com\/",
      "email" : ""
    }
  },
  "magiclanterntheatre" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(509) 209-2383",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/b466e17e81e37280e243b22c4805c02f7a2bbf33\/original.jpeg",
      "instagramHandle" : "magiclanterntheatre",
      "merchantHandle" : "magiclanterntheatre",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Magic Lantern Theatre",
      "city" : "Spokane",
      "companyName" : "Magic Lantern Theatre",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.magiclanternonmain.com\/",
      "email" : ""
    }
  },
  "equihuaoutlet" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 346-0536",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/AHSqbNpZOML59eK1ZGYagyLXScl52BCc.png",
      "instagramHandle" : "equihuaoutlet",
      "twitterHandle" : "",
      "merchantHandle" : "equihuaoutlet",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Equihua Outlet",
      "city" : "Fontana",
      "companyName" : "Equihua Outlet",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "frenchpresscoffeeandcrepes" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(503) 581-1716",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/K44b5npahNacBkN90mqrt20jTRjBY17Z.png",
      "instagramHandle" : "frenchpresscoffeeandcrepes",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "frenchpresscoffeeandcrepes",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "French Press Coffee and Crepes",
      "city" : "Salem",
      "companyName" : "French Press Coffee and Crepes",
      "websiteUrl" : "http:\/\/www.frenchpressroasters.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "blnds" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 215-1342",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/t0nKMyNivD0weU0XJ4kqBa2r9DwSavrC.png",
      "instagramHandle" : "blnds",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "blnds",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "BLNDS",
      "city" : "San Bernardino",
      "companyName" : "BLNDS",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "northcorkvineyardwinery" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 838-0721",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/04WbGZN34iqmbYSqwdEqNueBEiclH20l.png",
      "instagramHandle" : "northcorkvineyardwinery",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "northcorkvineyardwinery",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "North Cork Vineyard & Winery",
      "city" : "San Bernardino",
      "companyName" : "North Cork Vineyard & Winery",
      "websiteUrl" : "https:\/\/north-cork-vineyard-winery.square.site\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "itsabakerything" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(989) 720-2254",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/qe3J9HyT6BuBe7l0Q2UKlumRTwIM2iF9.png",
      "instagramHandle" : "itsabakerything",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "itsabakerything",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Itsa Bakery Thing",
      "city" : "Lansing",
      "companyName" : "Itsa Bakery Thing",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.itsabakerything.com\/",
      "email" : ""
    }
  },
  "chicaoakland" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 735-9748",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/62270ea3252c347f93ca0597889daa9315c1cacd\/original.jpeg",
      "instagramHandle" : "chicaoakland",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "chicaoakland",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "CHICA Oakland",
      "city" : "Oakland",
      "companyName" : "CHICA Oakland",
      "websiteUrl" : "http:\/\/www.chicaoakland.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "cafeimmortelle" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(248) 250-9858",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/82fd40ecef229cfc7ec448c60c1f17aec9bc4b65\/original.jpeg",
      "instagramHandle" : "cafeimmortelle",
      "premium" : false,
      "merchantHandle" : "cafeimmortelle",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Cafe Immortelle",
      "city" : "Warren",
      "companyName" : "Cafe Immortelle",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/cafeimmortelle.square.site\/",
      "email" : ""
    }
  },
  "blacklistbagels" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/cx8fbMWcpUapMho3CCFlKP6axcwtps8n.png",
      "instagramHandle" : "blacklistbagels",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "blacklistbagels",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Blacklist Bagels",
      "city" : "Grand Rapids",
      "companyName" : "Blacklist Bagels",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.blacklistbagels.com\/",
      "email" : ""
    }
  },
  "wisesonsjewishdeli" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 590-7955",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/gFren1SDdFatnHRCC40bJ0WGBURCjlG0.png",
      "instagramHandle" : "wisesonsjewishdeli",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "wisesonsjewishdeli",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Wise Sons Jewish Deli",
      "city" : "San Francisco",
      "companyName" : "Wise Sons Jewish Deli",
      "websiteUrl" : "https:\/\/wisesonsdeli.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "royalgourmetcookies" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(562) 208-5802",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/0ad6e6e036b3b6b4267c94b1934c93082e1e5505\/original.jpeg",
      "instagramHandle" : "royalgourmetcookies",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "royalgourmetcookies",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Royal Gourmet Cookies",
      "city" : "Long Beach",
      "companyName" : "Royal Gourmet Cookies",
      "websiteUrl" : "http:\/\/www.royalgourmetcookies.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "longbeachcreamery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(562) 513-3493",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/bjdFA0KBgMsAFMtg1oZPaba2ZzHIdKtl.png",
      "instagramHandle" : "longbeachcreamery",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "longbeachcreamery",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Long Beach Creamery",
      "city" : "Long Beach",
      "companyName" : "Long Beach Creamery",
      "websiteUrl" : "http:\/\/longbeachcreamery.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "nationalgroceriescatering" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(586) 268-8225",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "nationalgroceriescatering",
      "merchantHandle" : "nationalgroceriescatering",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "National Groceries & Catering",
      "city" : "Sterling Heights",
      "companyName" : "National Groceries & Catering",
      "websiteUrl" : "https:\/\/nationalcag.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "hudsonscookies" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(949) 932-0405",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/bd167c93a5aa5ce10faeeefb15e737564427b3e2\/original.jpeg",
      "instagramHandle" : "hudsonscookies",
      "premium" : false,
      "merchantHandle" : "hudsonscookies",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Hudson’s Cookies",
      "city" : "Irvine",
      "companyName" : "Hudson’s Cookies",
      "websiteUrl" : "http:\/\/hudsonscookies.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "lyonstreetcafe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 635-2194",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/OcKI2NurnHoha032nreBBWNPj9RqWuSu.png",
      "instagramHandle" : "lyonstreetcafe",
      "twitterHandle" : "",
      "merchantHandle" : "lyonstreetcafe",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Lyon Street Cafe",
      "city" : "Grand Rapids",
      "companyName" : "Lyon Street Cafe",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/lyonstreetcafe.com\/",
      "email" : ""
    }
  },
  "chronictacos" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 278-2643",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/F1t6pDEthRc5A7vhhjZQ05a3grTUH5e5.png",
      "instagramHandle" : "chronictacos",
      "merchantHandle" : "chronictacos",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Chronic Tacos",
      "city" : "Riverside",
      "companyName" : "Chronic Tacos",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.chronictacos.com\/",
      "email" : ""
    }
  },
  "stephyscakery" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 200-6610",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/1f40d1073a1b169f9e31a09cb7d4b827f843fbb5\/original.png",
      "instagramHandle" : "stephyscakery",
      "premium" : false,
      "merchantHandle" : "stephyscakery",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Stephy’s Cakery",
      "city" : "Riverside",
      "companyName" : "Stephy’s Cakery",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/stephyscakery.com\/",
      "email" : ""
    }
  },
  "luckyjoe" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/2aeb5f49778e931a05b8868b49e38f68a9476bc8\/original.jpeg",
      "instagramHandle" : "luckyjoe",
      "twitterHandle" : "",
      "merchantHandle" : "luckyjoe",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Lucky Joe",
      "city" : "Bakersfield",
      "companyName" : "Lucky Joe",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "littlewinehouse" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(408) 471-8039",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/nkm6wIWdOc9WdmNv3wRmaO5woZGTtCkD.png",
      "instagramHandle" : "littlewinehouse",
      "merchantHandle" : "littlewinehouse",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Little Wine House",
      "city" : "San Jose",
      "companyName" : "Little Wine House",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/littlewinehousesj.com\/",
      "email" : ""
    }
  },
  "doordashkitchens" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(408) 519-3387",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "doordashkitchens",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "doordashkitchens",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "DoorDash Kitchens",
      "city" : "San Jose",
      "companyName" : "DoorDash Kitchens",
      "websiteUrl" : "https:\/\/sanjose.doordash-kitchens.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "lionslair" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(509) 456-5678",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/33XYNcMp0auP0GiDXrSdCYbLA3bRjQhW.png",
      "instagramHandle" : "lionslair",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "lionslair",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Lion's Lair",
      "city" : "Spokane",
      "companyName" : "Lion's Lair",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.facebook.com\/LionsLairSpokane\/",
      "email" : ""
    }
  },
  "empirebuffet" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(951) 742-5716",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/aa9be018aec94d491a00f40429958ff0d9edd975\/original.jpeg",
      "instagramHandle" : "empirebuffet",
      "merchantHandle" : "empirebuffet",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Empire Buffet",
      "city" : "Moreno Valley",
      "companyName" : "Empire Buffet",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "subvegansantaana" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 852-3896",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/cc282ef7f71976df7d93ecfcf0892f9d66b2ee79\/original.png",
      "instagramHandle" : "subvegansantaana",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "subvegansantaana",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "SubVegan - Santa Ana",
      "city" : "Santa Ana",
      "companyName" : "SubVegan - Santa Ana",
      "websiteUrl" : "https:\/\/subvegan2.square.site\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "alejandrabriseno" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 266-2757",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/5GjELcaDzKT8dawALcDiWDmgqw6ScHKZ.png",
      "instagramHandle" : "alejandrabriseno",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "alejandrabriseno",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Alejandra Briseno",
      "city" : "San Bernardino",
      "companyName" : "Alejandra Briseno",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "nonnasitalianrestaurant" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 726-1787",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/4805277220da06c9023047258c395085c778500b\/original.jpeg",
      "instagramHandle" : "nonnasitalianrestaurant",
      "merchantHandle" : "nonnasitalianrestaurant",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Nonna's Italian Restaurant",
      "city" : "San Bernardino",
      "companyName" : "Nonna's Italian Restaurant",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "core209athleticperformance" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 923-0871",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/57794e8dde5e0f65377e6a804c6fece6d911d3b5\/original.jpeg",
      "instagramHandle" : "core209athleticperformance",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "core209athleticperformance",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "CORE209 Athletic Performance",
      "city" : "Modesto",
      "companyName" : "CORE209 Athletic Performance",
      "websiteUrl" : "http:\/\/core209.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "detroitinstituteofbagels" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(313) 512-8292",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/kulKZLr9yv23VwkxZrlka0ecd9pTjpfE.png",
      "instagramHandle" : "detroitinstituteofbagels",
      "merchantHandle" : "detroitinstituteofbagels",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Detroit Institute of Bagels",
      "city" : "Detroit",
      "companyName" : "Detroit Institute of Bagels",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/detroitinstituteofbagels.com\/",
      "email" : ""
    }
  },
  "ecocoffee" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(562) 425-6000",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/X3USPSN0rwAe83tE2CAFMOnowi9xc7gk.png",
      "instagramHandle" : "ecocoffee",
      "premium" : false,
      "merchantHandle" : "ecocoffee",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Eco Coffee",
      "city" : "Long Beach",
      "companyName" : "Eco Coffee",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.instagram.com\/ecocoffeehouse\/",
      "email" : ""
    }
  },
  "wddickinson" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(858) 848-6914",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/AYaJcUc0pHE0WG82GmC7oIKbFbdwctRC.png",
      "instagramHandle" : "wddickinson",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "wddickinson",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "W.D. Dickinson",
      "city" : "Chula Vista",
      "companyName" : "W.D. Dickinson",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.wddickinson.com\/",
      "email" : ""
    }
  },
  "princesaboutiqueandgifts" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 719-9883",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "princesaboutiqueandgifts",
      "twitterHandle" : "",
      "merchantHandle" : "princesaboutiqueandgifts",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Princesa Boutique and Gifts",
      "city" : "Sacramento",
      "companyName" : "Princesa Boutique and Gifts",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "dencafe" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 835-8840",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/tKiTyj6zVZPe55uPe01QoNyBI4qrppyX.png",
      "instagramHandle" : "dencafe",
      "merchantHandle" : "dencafe",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Den Cafe",
      "city" : "Santa Ana",
      "companyName" : "Den Cafe",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.dencafeoc.com\/",
      "email" : ""
    }
  },
  "puremextacostequila" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(616) 284-8138",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/766a37555813a1b0b254b557ea3b93f97a511a5c\/original.png",
      "instagramHandle" : "puremextacostequila",
      "twitterHandle" : "",
      "merchantHandle" : "puremextacostequila",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Pure Mex Tacos & Tequila",
      "city" : "Grand Rapids",
      "companyName" : "Pure Mex Tacos & Tequila",
      "websiteUrl" : "https:\/\/puremextacosandtequila.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "panpasta" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 294-6233",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/4805277220da06c9023047258c395085c778500b\/original.jpeg",
      "instagramHandle" : "panpasta",
      "merchantHandle" : "panpasta",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Pan Pasta",
      "city" : "Fontana",
      "companyName" : "Pan Pasta",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "basilurteacoffee" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 722-0288",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/VjuSSoIHOVSRS7Sq8PCvVNu4aP0YAodK.png",
      "instagramHandle" : "basilurteacoffee",
      "premium" : false,
      "merchantHandle" : "basilurteacoffee",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "Coffee Shop",
      "squareSandboxKey" : "",
      "merchantName" : "Basilur Tea Coffee",
      "city" : "Anaheim",
      "companyName" : "Basilur Tea Coffee",
      "websiteUrl" : "http:\/\/www.basilurteacoffee.com\/",
      "tikTokHandle" : "",
      "email" : "info@basilurtea.com"
    }
  },
  "latinorestaurant" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 227-5322",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/zaqnPdvUpCv5p1klvUPi84n0i8CPthfw.png",
      "instagramHandle" : "latinorestaurant",
      "merchantHandle" : "latinorestaurant",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Latino Restaurant",
      "city" : "Stockton",
      "companyName" : "Latino Restaurant",
      "websiteUrl" : "http:\/\/orderlatinorestaurantca.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "rawaastreetsweets" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(586) 991-5380",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/7976d742c984e677a8572707643f0e5d8a6e1bce\/original.jpeg",
      "instagramHandle" : "rawaastreetsweets",
      "twitterHandle" : "",
      "merchantHandle" : "rawaastreetsweets",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Rawaa Street Sweets",
      "city" : "Sterling Heights",
      "companyName" : "Rawaa Street Sweets",
      "websiteUrl" : "https:\/\/streetsweetsdet.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "reborncoffeeanaheim" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(562) 365-8414",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/OS1jIE6uqJvBadVL6xZRx5vFLfWVaFcS.png",
      "instagramHandle" : "reborncoffeeanaheim",
      "premium" : false,
      "merchantHandle" : "reborncoffeeanaheim",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Reborn Coffee - Anaheim",
      "city" : "Anaheim",
      "companyName" : "Reborn Coffee - Anaheim",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/reborncoffee.com\/",
      "email" : ""
    }
  },
  "musubiplug" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/7c2e0d1134ac7c8288b5982d789de2474817c667\/original.png",
      "instagramHandle" : "musubiplug",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "musubiplug",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Musubi Plug",
      "city" : "Santa Ana",
      "companyName" : "Musubi Plug",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.instagram.com\/musubi.plug\/",
      "email" : ""
    }
  },
  "olphfoodpantryfooddistributioncenter" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(951) 689-8921",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/lkppD8lYfal6NnmD5ipOQ8TNr0r54QmL.png",
      "instagramHandle" : "olphfoodpantryfooddistributioncenter",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "olphfoodpantryfooddistributioncenter",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Olph Food Pantry - Food Distribution Center",
      "city" : "Riverside",
      "companyName" : "Olph Food Pantry - Food Distribution Center",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.olphriverside.org\/food-pantry\/",
      "email" : ""
    }
  },
  "hibachisan" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 827-3456",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/e21a805786abbaebcae3a348f03439719070ff04\/original.jpeg",
      "instagramHandle" : "hibachisan",
      "merchantHandle" : "hibachisan",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Hibachi-San",
      "city" : "Riverside",
      "companyName" : "Hibachi-San",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/dining.ucr.edu\/campus-restaurants\/hub-food-court#hibachisan",
      "email" : ""
    }
  },
  "arekieindianfusioncuisine" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(541) 797-3741",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ILhKIlgc5r8nbPkbGx2M1BWxj2ldPPK2.png",
      "instagramHandle" : "arekieindianfusioncuisine",
      "premium" : false,
      "merchantHandle" : "arekieindianfusioncuisine",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Arekie - Indian Fusion Cuisine",
      "city" : "Eugene",
      "companyName" : "Arekie - Indian Fusion Cuisine",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.arekie.com\/",
      "email" : ""
    }
  },
  "lastcallbrewingco" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 322-3781",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/QXPHKMTgUfyftnpo6J1d9I23uVwFSUFR.png",
      "instagramHandle" : "lastcallbrewingco",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "lastcallbrewingco",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Last Call Brewing Co.",
      "city" : "Modesto",
      "companyName" : "Last Call Brewing Co.",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/lastcallbrewco.com\/",
      "email" : ""
    }
  },
  "besthomekitchenbath" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(619) 628-8230",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/c5b125e4d73caf23a98a9d84bc4588deac989292\/original.jpeg",
      "instagramHandle" : "besthomekitchenbath",
      "twitterHandle" : "",
      "merchantHandle" : "besthomekitchenbath",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Best Home Kitchen & Bath",
      "city" : "Chula Vista",
      "companyName" : "Best Home Kitchen & Bath",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.besthomesd.com\/",
      "email" : ""
    }
  },
  "teahaus" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(734) 622-0460",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/05Xgm9kC8HUigtwpysZpxPOI7EAB4eJi.png",
      "instagramHandle" : "teahaus",
      "merchantHandle" : "teahaus",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "TeaHaus",
      "city" : "Ann Arbor",
      "companyName" : "TeaHaus",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.teahaus.com\/",
      "email" : ""
    }
  },
  "limitlessaxesandales" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 594-0429",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/nFZ8YSOmI2L90xGTTvyGjkloiGZBgCPu.png",
      "instagramHandle" : "limitlessaxesandales",
      "merchantHandle" : "limitlessaxesandales",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Limitless Axes and Ales",
      "city" : "Stockton",
      "companyName" : "Limitless Axes and Ales",
      "websiteUrl" : "https:\/\/limitlessaxes.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "loadeddicebrewery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/BKSsMhzWK9ZInPVgSIHVhA2EztJUum9Y.png",
      "instagramHandle" : "loadeddicebrewery",
      "premium" : false,
      "merchantHandle" : "loadeddicebrewery",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Loaded Dice Brewery",
      "city" : "Warren",
      "companyName" : "Loaded Dice Brewery",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.loadeddicebrewery.com\/",
      "email" : ""
    }
  },
  "jscottcellars" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(541) 255-0207",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/3jgEr9Ehh70RZwlZhyfQoYlo2YBPATq4.png",
      "instagramHandle" : "jscottcellars",
      "premium" : false,
      "merchantHandle" : "jscottcellars",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "J. Scott Cellars",
      "city" : "Eugene",
      "companyName" : "J. Scott Cellars",
      "websiteUrl" : "https:\/\/jscottcellars.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "juicenbowls" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 606-0559",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/t0nKMyNivD0weU0XJ4kqBa2r9DwSavrC.png",
      "instagramHandle" : "juicenbowls",
      "merchantHandle" : "juicenbowls",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Juice n' Bowls",
      "city" : "Fontana",
      "companyName" : "Juice n' Bowls",
      "websiteUrl" : "http:\/\/www.juice-n-bowls.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "ciderliciouscidergardentapcart" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/zaCQjyEKqRT933oiasBIrvYPA7sbnaoa.png",
      "instagramHandle" : "ciderliciouscidergardentapcart",
      "premium" : false,
      "merchantHandle" : "ciderliciouscidergardentapcart",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Ciderlicious Cider Garden & Tap Cart",
      "city" : "Eugene",
      "companyName" : "Ciderlicious Cider Garden & Tap Cart",
      "websiteUrl" : "https:\/\/ciderlicious.creator-spring.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "zhengyalovhatz" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(818) 696-9339",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ZpwfCCZ3Gbx3Yx3K3TR8auYLpqEzOLC5.png",
      "instagramHandle" : "zhengyalovhatz",
      "premium" : false,
      "merchantHandle" : "zhengyalovhatz",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Zhengyalov Hatz",
      "city" : "Glendale",
      "companyName" : "Zhengyalov Hatz",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "luvsbrownies" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(408) 757-8009",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/qt59H2dI6LaIHBTDeX7lFlRCCJsIfkQW.png",
      "instagramHandle" : "luvsbrownies",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "luvsbrownies",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Luv's Brownies",
      "city" : "San Jose",
      "companyName" : "Luv's Brownies",
      "websiteUrl" : "http:\/\/www.luvsbrownies.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "smokinwoodsbbqfresno" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(559) 421-0020",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/UCNvXH4QHJVdbOEfJf9s5aq4XnfeDF9h.png",
      "instagramHandle" : "smokinwoodsbbqfresno",
      "merchantHandle" : "smokinwoodsbbqfresno",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Smokin Woods BBQ Fresno",
      "city" : "Fresno",
      "companyName" : "Smokin Woods BBQ Fresno",
      "websiteUrl" : "http:\/\/smokinwoodsbbq.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "wongexpresshouse" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(810) 227-5255",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/JQWvHEod316d4ZvLp08e0bVeR8sEl8GP.png",
      "instagramHandle" : "wongexpresshouse",
      "merchantHandle" : "wongexpresshouse",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Wong Express House",
      "city" : "Ann Arbor",
      "companyName" : "Wong Express House",
      "websiteUrl" : "http:\/\/www.wongexpress.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "floresirene" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 566-0717",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "floresirene",
      "merchantHandle" : "floresirene",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Flores Irene",
      "city" : "San Francisco",
      "companyName" : "Flores Irene",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "kravings" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(248) 967-1161",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/8bf01618beae50d27cf249f51aff8e43402e8cb0\/original.jpeg",
      "instagramHandle" : "kravings",
      "twitterHandle" : "",
      "merchantHandle" : "kravings",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Kravings",
      "city" : "Warren",
      "companyName" : "Kravings",
      "websiteUrl" : "http:\/\/www.kravingsdetroit.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "islandstylefoodbbq" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(509) 315-9478",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/599c588b500bb0bc5a0e231846e6cd5818af133b\/original.png",
      "instagramHandle" : "islandstylefoodbbq",
      "premium" : false,
      "merchantHandle" : "islandstylefoodbbq",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Island Style Food & BBQ",
      "city" : "Spokane",
      "companyName" : "Island Style Food & BBQ",
      "websiteUrl" : "https:\/\/www.islandstylefood.net\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "appannie" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 638-6840",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "appannie",
      "merchantHandle" : "appannie",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "App Annie",
      "city" : "San Francisco",
      "companyName" : "App Annie",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.appannie.com\/",
      "email" : ""
    }
  },
  "honeyfishpoke" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/53222261fc5adbe23d1f70b4f63e50dd88335f7b\/original.png",
      "instagramHandle" : "honeyfishpoke",
      "merchantHandle" : "honeyfishpoke",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Honeyfish Poke",
      "city" : "Anaheim",
      "companyName" : "Honeyfish Poke",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "roadside" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(925) 844-2008",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "roadside",
      "premium" : false,
      "merchantHandle" : "roadside",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Roadside 22",
      "city" : "Stockton",
      "companyName" : "Roadside 22",
      "websiteUrl" : "https:\/\/roadside22.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "petuniascoffeewinemarketplace" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(509) 328-4257",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/pxwzKN3c6bZoNc0XIAEQ6erPebONW5Ls.png",
      "instagramHandle" : "petuniascoffeewinemarketplace",
      "merchantHandle" : "petuniascoffeewinemarketplace",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Petunias Coffee & Wine Marketplace",
      "city" : "Spokane",
      "companyName" : "Petunias Coffee & Wine Marketplace",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "oakandiron" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 630-1638",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/F40HGtm2KFEUIZJJm8ODXTgtaPdLnYSO.png",
      "instagramHandle" : "oakandiron",
      "merchantHandle" : "oakandiron",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Oak and Iron",
      "city" : "Oxnard",
      "companyName" : "Oak and Iron",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.oakandiron.com\/",
      "email" : ""
    }
  },
  "georgeoliverihairdesign" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(562) 421-4744",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/b373527d8549a69a4a37c3620ce40ceed0eb3d2b\/original.jpeg",
      "instagramHandle" : "georgeoliverihairdesign",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "georgeoliverihairdesign",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "George Oliveri Hair Design",
      "city" : "Long Beach",
      "companyName" : "George Oliveri Hair Design",
      "websiteUrl" : "http:\/\/www.georgeoliverisalon.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "batterrebellion" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 748-6000",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/yPnrMfz1UOT5Rz230kyaNlgOzp1el3jv.png",
      "instagramHandle" : "batterrebellion",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "batterrebellion",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Batter Rebellion",
      "city" : "San Bernardino",
      "companyName" : "Batter Rebellion",
      "websiteUrl" : "http:\/\/www.batterrebellion.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sanderssonsgelato" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 633-9077",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Sgf7iKyljY9JQTvcJwa06qL77SQMvOOI.png",
      "instagramHandle" : "sanderssonsgelato",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "sanderssonsgelato",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "SANDERS & SONS GELATO",
      "city" : "Oxnard",
      "companyName" : "SANDERS & SONS GELATO",
      "websiteUrl" : "http:\/\/www.sandersandsonsgelato.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "tacoselpelon" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(323) 434-4590",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/6647e5b8b3e2b35fc53d7b5ebbe6c56dd79ccd4b\/original.jpeg",
      "instagramHandle" : "tacoselpelon",
      "merchantHandle" : "tacoselpelon",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Tacos el pelon",
      "city" : "Moreno Valley",
      "companyName" : "Tacos el pelon",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "speciationcellars" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(616) 666-6783",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/s0E5a23tjcbVXZdoF83nNM97BaE7Fcl0.png",
      "instagramHandle" : "speciationcellars",
      "merchantHandle" : "speciationcellars",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Speciation Cellars",
      "city" : "Grand Rapids",
      "companyName" : "Speciation Cellars",
      "websiteUrl" : "http:\/\/www.speciationartisanales.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thenutritionplace" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(619) 484-7580",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "thenutritionplace",
      "merchantHandle" : "thenutritionplace",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Nutrition Place",
      "city" : "Chula Vista",
      "companyName" : "The Nutrition Place",
      "websiteUrl" : "https:\/\/www.thenutritionplacecv.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "ezkebabcatering" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(949) 933-7149",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/332069db479e367a6ee5bc360a32180c3e70a09d\/original.jpeg",
      "instagramHandle" : "ezkebabcatering",
      "merchantHandle" : "ezkebabcatering",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "EZ Kebab Catering",
      "city" : "Huntington Beach",
      "companyName" : "EZ Kebab Catering",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "vendettabrewingco" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/791QQwMrhmHa0tOi1ZKArBR2XXIF8wQp.png",
      "instagramHandle" : "vendettabrewingco",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "vendettabrewingco",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Vendetta Brewing Co.",
      "city" : "San Bernardino",
      "companyName" : "Vendetta Brewing Co.",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/vendettabrewing.co\/",
      "email" : ""
    }
  },
  "salemfirstbaptistchurch" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(503) 364-2285",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/0841f8f73e19a4afb90d21f625d5bf4c1f7db587\/original.jpeg",
      "instagramHandle" : "salemfirstbaptistchurch",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "salemfirstbaptistchurch",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Salem First Baptist Church",
      "city" : "Salem",
      "companyName" : "Salem First Baptist Church",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.fbcsalem.org\/",
      "email" : ""
    }
  },
  "desserteuropeanbakery" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 529-1707",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/2ded0fafc1726a53c73bf84229b6bd710f8a2374\/original.jpeg",
      "instagramHandle" : "desserteuropeanbakery",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "desserteuropeanbakery",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Dessert European Bakery",
      "city" : "Modesto",
      "companyName" : "Dessert European Bakery",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/desserteuropeanbakery.com\/",
      "email" : ""
    }
  },
  "pursuitofhappinessco" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 805-3180",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ayGNMrmmZ70HB0iUsBpg0kS7yUJxuTS0.png",
      "instagramHandle" : "pursuitofhappinessco",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "pursuitofhappinessco",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Pursuit of Happiness Co.",
      "city" : "Grand Rapids",
      "companyName" : "Pursuit of Happiness Co.",
      "websiteUrl" : "http:\/\/pursuitofhappiness.co\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "figthistlemarket" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(256) 651-9903",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "figthistlemarket",
      "merchantHandle" : "figthistlemarket",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Fig & Thistle Market",
      "city" : "San Francisco",
      "companyName" : "Fig & Thistle Market",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.figandthistlesf.com\/",
      "email" : ""
    }
  },
  "yetbunheongbakery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 462-3254",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/jq5eprHeZl3IxaXs1yoKmgUzp0OgdJ0A.png",
      "instagramHandle" : "yetbunheongbakery",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "yetbunheongbakery",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Yet Bun Heong Bakery",
      "city" : "Stockton",
      "companyName" : "Yet Bun Heong Bakery",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "inclineciderhouse" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(253) 327-1923",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/8j61V2UyXHUEUo0GXW3jR0INXq6QDR7K.png",
      "instagramHandle" : "inclineciderhouse",
      "merchantHandle" : "inclineciderhouse",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Incline Cider House",
      "city" : "Tacoma",
      "companyName" : "Incline Cider House",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.inclinecider.com\/tap-room",
      "email" : ""
    }
  },
  "tacoselgoloso" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(310) 935-2114",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Y0Q2KtWilvFoZQHmutw4qoZ25xhXh0YF.png",
      "instagramHandle" : "tacoselgoloso",
      "twitterHandle" : "",
      "merchantHandle" : "tacoselgoloso",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Tacos El Goloso",
      "city" : "Long Beach",
      "companyName" : "Tacos El Goloso",
      "websiteUrl" : "https:\/\/tacoselgoloso.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "tacoselvaquero" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(619) 902-2027",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/HiXbO6bn6daqQA5UyX0arAyHasUmEFiA.png",
      "instagramHandle" : "tacoselvaquero",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "tacoselvaquero",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Tacos el Vaquero",
      "city" : "Chula Vista",
      "companyName" : "Tacos el Vaquero",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "hahdough" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 691-9570",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/52hMJ1jsdORiZzau4aBgixd2uyRNa6Pk.png",
      "instagramHandle" : "hahdough",
      "merchantHandle" : "hahdough",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Hahdough",
      "city" : "San Francisco",
      "companyName" : "Hahdough",
      "websiteUrl" : "https:\/\/www.hahdough.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "arthurmacstapandsnack" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 823-2509",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/FlO2H1LaUTyjlsZ13N7VwXzeUQ9rJ8oI.png",
      "instagramHandle" : "arthurmacstapandsnack",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "arthurmacstapandsnack",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Arthur Mac's Tap and Snack",
      "city" : "Oakland",
      "companyName" : "Arthur Mac's Tap and Snack",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.arthurmacs.com\/",
      "email" : ""
    }
  },
  "papelonarepabar" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/58d28cae5dadca28df027f13c4c1b1984d833f69\/original.jpeg",
      "instagramHandle" : "papelonarepabar",
      "merchantHandle" : "papelonarepabar",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Papelon Arepa Bar",
      "city" : "Warren",
      "companyName" : "Papelon Arepa Bar",
      "websiteUrl" : "http:\/\/www.papelonarepabar.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "myvicefoodspirits" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(503) 477-4252",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/C9XthZYzMr0ctgqHmfsb7GrXve6m6J77.png",
      "instagramHandle" : "myvicefoodspirits",
      "twitterHandle" : "",
      "merchantHandle" : "myvicefoodspirits",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "My Vice Food & Spirits",
      "city" : "Vancouver",
      "companyName" : "My Vice Food & Spirits",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/myvicepdx.com\/?utm_source=google-gmb-maps&utm_medium=gmb-website-button&utm_campaign=locl.io&utm_content=427a1d89-e996-47d1-bf98-706bc0ac88a9",
      "email" : ""
    }
  },
  "kminamotosanfrancisco" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 989-1645",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "kminamotosanfrancisco",
      "twitterHandle" : "",
      "merchantHandle" : "kminamotosanfrancisco",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "K Minamoto | San Francisco",
      "city" : "San Francisco",
      "companyName" : "K Minamoto | San Francisco",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.kitchoan.com\/",
      "email" : ""
    }
  },
  "currylife" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(408) 320-1867",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/PVWRxYmDdaSaNvQIzEw83LpcdispChw0.png",
      "instagramHandle" : "currylife",
      "merchantHandle" : "currylife",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Curry Life",
      "city" : "San Jose",
      "companyName" : "Curry Life",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.sjcurrylife.com\/",
      "email" : ""
    }
  },
  "thesplit" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/XYeRbN8YNRCfkrQi3Cb0hjSDBMDaTFjm.png",
      "instagramHandle" : "thesplit",
      "merchantHandle" : "thesplit",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Split",
      "city" : "Detroit",
      "companyName" : "The Split",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "blendful" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(253) 238-3447",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/bb21d053d0b36b3a839060f7ef9e2713e7a05099\/original.png",
      "instagramHandle" : "blendful",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "blendful",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Blendful",
      "city" : "Tacoma",
      "companyName" : "Blendful",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.blendfulwa.com\/",
      "email" : ""
    }
  },
  "kornerkitchen" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(734) 531-6760",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/DkpzXoFImHd0y7XT2v0CuhIL3Ouaei05.png",
      "instagramHandle" : "kornerkitchen",
      "merchantHandle" : "kornerkitchen",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Korner Kitchen",
      "city" : "Ann Arbor",
      "companyName" : "Korner Kitchen",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "fye" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 653-8892",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/g0N9vxPiUK1FpAA7BWdJO8i9fA2QQ6Zp.png",
      "instagramHandle" : "fye",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "fye",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "FYE",
      "city" : "Moreno Valley",
      "companyName" : "FYE",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/stores.fye.com\/ca\/moreno-valley\/22500-towngate-cir.html",
      "email" : ""
    }
  },
  "venturacoastbrewingcompany" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 667-8640",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/EZRHU9oierEB6BWhCVnMcXOfbkWa9rRk.png",
      "instagramHandle" : "venturacoastbrewingcompany",
      "merchantHandle" : "venturacoastbrewingcompany",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Ventura Coast Brewing Company",
      "city" : "Oxnard",
      "companyName" : "Ventura Coast Brewing Company",
      "websiteUrl" : "http:\/\/vcbc.beer\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "pomella" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 250-9215",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/dR5AbzmBQfKsO5vokaaHBYBgsLTsbK2i.png",
      "instagramHandle" : "pomella",
      "twitterHandle" : "",
      "merchantHandle" : "pomella",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Pomella",
      "city" : "Oakland",
      "companyName" : "Pomella",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.pomellaoakland.com\/",
      "email" : ""
    }
  },
  "ayahfaqhi" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 587-9007",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/aaSZTfLrrk1xjae6sUviSEaW9WI02AeS.png",
      "instagramHandle" : "ayahfaqhi",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "ayahfaqhi",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Ayah Faqhi",
      "city" : "Huntington Beach",
      "companyName" : "Ayah Faqhi",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "prontodiner" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(248) 544-7900",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Z5esIMcDRBp023MbSyfBSG1fwO4WVf8K.png",
      "instagramHandle" : "prontodiner",
      "twitterHandle" : "",
      "merchantHandle" : "prontodiner",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Pronto! Diner",
      "city" : "Warren",
      "companyName" : "Pronto! Diner",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/prontodiner.com\/",
      "email" : ""
    }
  },
  "thesparrowbakerypdx" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(971) 386-2985",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "thesparrowbakerypdx",
      "premium" : false,
      "merchantHandle" : "thesparrowbakerypdx",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Sparrow Bakery PDX",
      "city" : "Vancouver",
      "companyName" : "The Sparrow Bakery PDX",
      "websiteUrl" : "https:\/\/thesparrowbakery.net\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "dessertsbysara" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(509) 922-6039",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/07ce1482136fb71df9110f81a9c9c83f81b6c33f\/original.jpeg",
      "instagramHandle" : "dessertsbysara",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "dessertsbysara",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Desserts By Sara",
      "city" : "Spokane",
      "companyName" : "Desserts By Sara",
      "websiteUrl" : "http:\/\/www.dessertsbysara.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "stonylakebrewingco" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(734) 757-4820",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/LZvFYp7eka0HckL9Ft7bO0XWk0cD830U.png",
      "instagramHandle" : "stonylakebrewingco",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "stonylakebrewingco",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Stony Lake Brewing Co.",
      "city" : "Ann Arbor",
      "companyName" : "Stony Lake Brewing Co.",
      "websiteUrl" : "http:\/\/stonylakebrewing.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "whitewolfjapanesepatisserie" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(248) 268-3349",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/6bKyoox8vqQWDrIZAP3ooxIY6H6FGFLb.png",
      "instagramHandle" : "whitewolfjapanesepatisserie",
      "premium" : false,
      "merchantHandle" : "whitewolfjapanesepatisserie",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "White Wolf Japanese Patisserie",
      "city" : "Warren",
      "companyName" : "White Wolf Japanese Patisserie",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.whitewolfbakery.com\/",
      "email" : ""
    }
  },
  "canvasskillcanvaswalldisplay" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 662-7239",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/c6MRWXQcniKRKjuIJk65pp1wBoy0Ipnh.png",
      "instagramHandle" : "canvasskillcanvaswalldisplay",
      "twitterHandle" : "",
      "merchantHandle" : "canvasskillcanvaswalldisplay",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Canvas Skill - Canvas Wall Display",
      "city" : "Fontana",
      "companyName" : "Canvas Skill - Canvas Wall Display",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/canvasskill.com\/",
      "email" : ""
    }
  },
  "lizzieloustoo" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(253) 284-4611",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/1a65ba40ccdfcdb1a9edf1b82ee5fd0a20c946bb\/original.jpeg",
      "instagramHandle" : "lizzieloustoo",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "lizzieloustoo",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Lizzie Lou's Too",
      "city" : "Tacoma",
      "companyName" : "Lizzie Lou's Too",
      "websiteUrl" : "http:\/\/www.eatatlizzielous.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "whiteelephant" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 603-7630",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/a1a96740fb743e1c3b0d04031802d93a1e6bf2fc\/original.png",
      "instagramHandle" : "whiteelephant",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "whiteelephant",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "White Elephant",
      "city" : "Anaheim",
      "companyName" : "White Elephant",
      "websiteUrl" : "http:\/\/welephant.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "pinkpinkteashoppe" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(408) 372-9249",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/NwP5f7Z6ZE0YiTDzM8gnhZapnukNoLHQ.png",
      "instagramHandle" : "pinkpinkteashoppe",
      "merchantHandle" : "pinkpinkteashoppe",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Pink Pink Tea Shoppe",
      "city" : "San Jose",
      "companyName" : "Pink Pink Tea Shoppe",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/pinkpinkteashoppe.square.site\/",
      "email" : ""
    }
  },
  "lehrisson" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 864-9045",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/rc1UUcIp16x4zAOodU4SQ5lReHHxyyC6.png",
      "instagramHandle" : "lehrisson",
      "premium" : false,
      "merchantHandle" : "lehrisson",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Le Hérisson",
      "city" : "Oxnard",
      "companyName" : "Le Hérisson",
      "websiteUrl" : "https:\/\/www.herissoncafe.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "electriccoffeehouse" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(253) 447-8621",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/qjg0ES1HTw7T4rR7B50rQovby0l010ay.png",
      "instagramHandle" : "electriccoffeehouse",
      "merchantHandle" : "electriccoffeehouse",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Electric Coffee House",
      "city" : "Tacoma",
      "companyName" : "Electric Coffee House",
      "websiteUrl" : "http:\/\/electriccoffee.co\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "silsilatheflamingtandoor" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(971) 373-8641",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/6c1633382a374614c51b7f3bc2f67fc258a1fe52\/original.jpeg",
      "instagramHandle" : "silsilatheflamingtandoor",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "silsilatheflamingtandoor",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Silsila The Flaming Tandoor",
      "city" : "Vancouver",
      "companyName" : "Silsila The Flaming Tandoor",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/indianfoodportland.com\/",
      "email" : ""
    }
  },
  "purbowlsacaibowls" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(310) 817-4320",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/pgSOabw07RHumsmeSOMPVka0AO3l01AG.png",
      "instagramHandle" : "purbowlsacaibowls",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "purbowlsacaibowls",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "pur Bowls : Acai Bowls",
      "city" : "Long Beach",
      "companyName" : "pur Bowls : Acai Bowls",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.purbowls.com\/",
      "email" : ""
    }
  },
  "zolman" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "zolman",
      "premium" : false,
      "merchantHandle" : "zolman",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Zolman",
      "city" : "Sterling Heights",
      "companyName" : "Zolman",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "libertyciderworks" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(509) 720-7374",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/bQIB6WyQL9vLCiqcQICYdxEwA1bGadEm.png",
      "instagramHandle" : "libertyciderworks",
      "merchantHandle" : "libertyciderworks",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Liberty Ciderworks",
      "city" : "Spokane",
      "companyName" : "Liberty Ciderworks",
      "websiteUrl" : "http:\/\/libertycider.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "smhokinpot" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(424) 454-8424",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/adc795a9c1515002ff0ad116f3fa916bfb5fc53c\/original.jpeg",
      "instagramHandle" : "smhokinpot",
      "premium" : false,
      "merchantHandle" : "smhokinpot",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Smhokin Pot",
      "city" : "Long Beach",
      "companyName" : "Smhokin Pot",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "laveganamexicana" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(949) 988-4268",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/c9RmNaqSDn0MIokpqNXv8vTabFiFlshH.png",
      "instagramHandle" : "laveganamexicana",
      "merchantHandle" : "laveganamexicana",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "La Vegana Mexicana",
      "city" : "Santa Ana",
      "companyName" : "La Vegana Mexicana",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "ateliercakeshoppe" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(408) 256-3038",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/f9bb656a5b789c3e07376d83634f4124ac67bd28\/original.png",
      "instagramHandle" : "ateliercakeshoppe",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "ateliercakeshoppe",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Atelier Cake Shoppe",
      "city" : "San Jose",
      "companyName" : "Atelier Cake Shoppe",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.instagram.com\/ateliercakeshoppe\/?hl=en",
      "email" : ""
    }
  },
  "crossstreetcoffee" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(734) 484-4684",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/567e2cd527e465940ca88f8aaf5d9e0be6a89f0a\/original.png",
      "instagramHandle" : "crossstreetcoffee",
      "merchantHandle" : "crossstreetcoffee",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Cross Street Coffee",
      "city" : "Ann Arbor",
      "companyName" : "Cross Street Coffee",
      "websiteUrl" : "https:\/\/www.northerngrindcoffee.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "lerendezvouscafe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 424-0355",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/2164bbdcb763f02a04072ed1c76e254689ce43a7\/original.jpeg",
      "instagramHandle" : "lerendezvouscafe",
      "premium" : false,
      "merchantHandle" : "lerendezvouscafe",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Le Rendez-Vous Cafe",
      "city" : "Riverside",
      "companyName" : "Le Rendez-Vous Cafe",
      "websiteUrl" : "https:\/\/lrvcafe.wixsite.com\/lrvcafe",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "rocketfizz" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(510) 263-8435",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/pVhkF9r5YazzAajNVaWdppXKYWbRHUvh.png",
      "instagramHandle" : "rocketfizz",
      "merchantHandle" : "rocketfizz",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Rocket Fizz",
      "city" : "Oakland",
      "companyName" : "Rocket Fizz",
      "websiteUrl" : "https:\/\/rocketfizz.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "lemonpoppykitchen" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(323) 739-0012",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/11b426b2daf6d30e595235da30b33a43ccda5bb7\/original.jpeg",
      "instagramHandle" : "lemonpoppykitchen",
      "merchantHandle" : "lemonpoppykitchen",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Lemon Poppy Kitchen",
      "city" : "Glendale",
      "companyName" : "Lemon Poppy Kitchen",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/lemonpoppykitchen.com\/",
      "email" : ""
    }
  },
  "cafegossip" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 485-9780",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/8126f81d34f9bc030d2e131e4b145fe43a958a90\/original.png",
      "instagramHandle" : "cafegossip",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "cafegossip",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Cafe Gossip",
      "city" : "Moreno Valley",
      "companyName" : "Cafe Gossip",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "burkeblack" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 723-1955",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/411a68f3a292970e7995ccaa5db63daac2050466\/original.png",
      "instagramHandle" : "burkeblack",
      "twitterHandle" : "",
      "merchantHandle" : "burkeblack",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Burke & Black",
      "city" : "San Francisco",
      "companyName" : "Burke & Black",
      "websiteUrl" : "http:\/\/www.burkeandblack.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "pantesscellars" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(805) 388-3279",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/b06b24aa79df5b5ca389355ff997f6cb5a1871cb\/original.jpeg",
      "instagramHandle" : "pantesscellars",
      "twitterHandle" : "",
      "merchantHandle" : "pantesscellars",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Pantess Cellars",
      "city" : "Oxnard",
      "companyName" : "Pantess Cellars",
      "websiteUrl" : "http:\/\/www.pantesscellars.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "menjos" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(313) 863-3934",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/cdYDg5q9G4ZGAVyuG9jGyENiyLZCgb4p.png",
      "instagramHandle" : "menjos",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "menjos",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Menjo's",
      "city" : "Detroit",
      "companyName" : "Menjo's",
      "websiteUrl" : "http:\/\/www.menjoscomplexdetroit.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "ojaivalleybrewerysidestreetwine" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 798-9282",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/JRXJn9qetIBc4GmmTrcuY8yLdJ2C0xAg.png",
      "instagramHandle" : "ojaivalleybrewerysidestreetwine",
      "merchantHandle" : "ojaivalleybrewerysidestreetwine",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Ojai Valley Brewery & Side Street Wine",
      "city" : "Oxnard",
      "companyName" : "Ojai Valley Brewery & Side Street Wine",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/ojaivalleybrewery.com\/",
      "email" : ""
    }
  },
  "daddysdoughnuts" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Rb3dKxuEruVmQAewbiHpQ0Hx3vQwHGyC.png",
      "instagramHandle" : "daddysdoughnuts",
      "merchantHandle" : "daddysdoughnuts",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Daddy's Doughnuts",
      "city" : "Sterling Heights",
      "companyName" : "Daddy's Doughnuts",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "fastcatcoffee" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 999-0323",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/NaLY9BQTKFX0lys0iv0fjeu30RmNKpAZ.png",
      "instagramHandle" : "fastcatcoffee",
      "merchantHandle" : "fastcatcoffee",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Fast Cat Coffee",
      "city" : "Sacramento",
      "companyName" : "Fast Cat Coffee",
      "websiteUrl" : "http:\/\/www.fastcatcoffee.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "crackersnacksbbq" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(248) 343-0500",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/kvqrabzwpI2uHTaz9i0Q9isjH905HK0M.png",
      "instagramHandle" : "crackersnacksbbq",
      "twitterHandle" : "",
      "merchantHandle" : "crackersnacksbbq",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Crackersnacks BBQ",
      "city" : "Flint",
      "companyName" : "Crackersnacks BBQ",
      "websiteUrl" : "http:\/\/crackersnacksbbq.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "bigbarnbrewingcompany" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(509) 710-2962",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/U8pPey8drFoP9E4YFaGbyNgUzuc5cdyr.png",
      "instagramHandle" : "bigbarnbrewingcompany",
      "premium" : false,
      "merchantHandle" : "bigbarnbrewingcompany",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Big Barn Brewing Company",
      "city" : "Spokane",
      "companyName" : "Big Barn Brewing Company",
      "websiteUrl" : "http:\/\/www.bigbarnbrewing.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "simonesdonuts" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(562) 493-4104",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Ieqk7QgHBG4Oq61o4SKIbD2Hu3ZvatwK.png",
      "instagramHandle" : "simonesdonuts",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "simonesdonuts",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Simone's Donuts",
      "city" : "Long Beach",
      "companyName" : "Simone's Donuts",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "moya" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 431-5544",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/5Jkz3laoTkyQTtYOr4t0Ccktlg8qpDUt.png",
      "instagramHandle" : "moya",
      "premium" : false,
      "merchantHandle" : "moya",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Moya",
      "city" : "San Francisco",
      "companyName" : "Moya",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/eatmoya.square.site\/",
      "email" : ""
    }
  },
  "saverinositaliandelimarket" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 335-6000",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/YMaRnJixknQ0JtnB2yYSP0vm0vvjWZs0.png",
      "instagramHandle" : "saverinositaliandelimarket",
      "merchantHandle" : "saverinositaliandelimarket",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Saverino's Italian Deli & Market",
      "city" : "San Bernardino",
      "companyName" : "Saverino's Italian Deli & Market",
      "websiteUrl" : "https:\/\/saverinosdeli.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "triplet" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(657) 301-2071",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/ec33f35fbe44cd4da723e5bf4e4fd4c9f98f4168\/original.jpeg",
      "instagramHandle" : "triplet",
      "twitterHandle" : "",
      "merchantHandle" : "triplet",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Triple-T",
      "city" : "Huntington Beach",
      "companyName" : "Triple-T",
      "websiteUrl" : "https:\/\/triple-thb.square.site\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "waveinternationalgroupinc" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(424) 443-4558",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/pFX6ddXGlh6rjMPpl8roFVGpNDnCRH9P.png",
      "instagramHandle" : "waveinternationalgroupinc",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "waveinternationalgroupinc",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "WAVE INTERNATIONAL GROUP Inc.",
      "city" : "Santa Ana",
      "companyName" : "WAVE INTERNATIONAL GROUP Inc.",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "greenbeancaf" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(519) 997-4710",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/lAQDeE1WW3yfR88cYMTHNMoCbQVKeJiO.png",
      "instagramHandle" : "greenbeancaf",
      "premium" : false,
      "merchantHandle" : "greenbeancaf",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Green Bean Café",
      "city" : "Detroit",
      "companyName" : "Green Bean Café",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "farleyseast" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(510) 835-7898",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/JvbQF16dxdxrf9Fnf4UgwAaakR7wZYmp.png",
      "instagramHandle" : "farleyseast",
      "premium" : false,
      "merchantHandle" : "farleyseast",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Farley's East",
      "city" : "Oakland",
      "companyName" : "Farley's East",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/farleyscoffee.com\/",
      "email" : ""
    }
  },
  "vonzabernsurgical" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(951) 734-7215",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/zjIsLm1hParyemQwYZqztGJtb9kaamdk.png",
      "instagramHandle" : "vonzabernsurgical",
      "merchantHandle" : "vonzabernsurgical",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Von Zabern Surgical",
      "city" : "Riverside",
      "companyName" : "Von Zabern Surgical",
      "websiteUrl" : "http:\/\/vzs.net\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "mudpiestand" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 431-7969",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/3da143c3f12afef44a8900fb809410f052285c69\/original.png",
      "instagramHandle" : "mudpiestand",
      "merchantHandle" : "mudpiestand",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Mud Pie Stand",
      "city" : "Sacramento",
      "companyName" : "Mud Pie Stand",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "marthascatering" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(408) 841-6827",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/c568ebf96084b4ca44f7552e6aa1650874639f16\/original.jpeg",
      "instagramHandle" : "marthascatering",
      "merchantHandle" : "marthascatering",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "MARTHA’S CATERING",
      "city" : "San Jose",
      "companyName" : "MARTHA’S CATERING",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/marthacatering.com\/",
      "email" : ""
    }
  },
  "barcasavale" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(503) 477-9081",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/CMumcSx6EBqxaaZAsuyVV0S6vat2HWvC.png",
      "instagramHandle" : "barcasavale",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "barcasavale",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Bar Casa Vale",
      "city" : "Portland",
      "companyName" : "Bar Casa Vale",
      "websiteUrl" : "https:\/\/www.barcasavale.com\/?utm_source=googlemybusiness",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "velvet" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MT\r",
      "phone" : "(406) 294-0308",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/doOuwMWDJI9vLeFr9Xykivk9eeRayFpb.png",
      "instagramHandle" : "velvet",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "velvet",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Velvet",
      "city" : "Billings",
      "companyName" : "Velvet",
      "websiteUrl" : "http:\/\/www.velvetcravings.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "wondergoods" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/fGvaNWurL4WOT2TdJ73AuFMfoeW57tDu.png",
      "instagramHandle" : "wondergoods",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "wondergoods",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Wonder Goods",
      "city" : "Grand Rapids",
      "companyName" : "Wonder Goods",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.wondergoods.com\/",
      "email" : ""
    }
  },
  " and Fantasy\"" : {
    "info" : {
      "state" : "(714) 844-2245",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "requiemcoffeeteaandfantasy",
      "instagramHandle" : " Tea",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : " Tea",
      "merchantHandle" : " and Fantasy\"",
      "companyType" : "",
      "squareSandboxKey" : " and Fantasy\"",
      "merchantName" : "requiemcoffeeteaandfantasy",
      "city" : "https:\/\/www.requiemcoffee.com\/",
      "companyName" : "\"Requiem: Coffee",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "naughtypienature" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(213) 358-5631",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/I3V8vcBK7sroCbOGihCZaVpWOAcKNeCm.png",
      "instagramHandle" : "naughtypienature",
      "merchantHandle" : "naughtypienature",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Naughty Pie Nature",
      "city" : "Glendale",
      "companyName" : "Naughty Pie Nature",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/naughtypienature.com\/",
      "email" : ""
    }
  },
  "organicchoicemediterranean" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(408) 248-1525",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/UScLj0mxRg5TbGaIB5ZLyNiJa9abR96u.png",
      "instagramHandle" : "organicchoicemediterranean",
      "merchantHandle" : "organicchoicemediterranean",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Organic Choice Mediterranean",
      "city" : "San Jose",
      "companyName" : "Organic Choice Mediterranean",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sweetellecafe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 841-1270",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/xyy07rCx6Tsz1Qnr2HhxJbH74mmvA2GO.png",
      "instagramHandle" : "sweetellecafe",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "sweetellecafe",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sweet Elle Cafe",
      "city" : "Huntington Beach",
      "companyName" : "Sweet Elle Cafe",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/sweetellecafe.com\/",
      "email" : ""
    }
  },
  "oakdalefitnutrition" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 412-4669",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/2c34097cf81794a1f0599ddd1874927be154012c\/original.jpeg",
      "instagramHandle" : "oakdalefitnutrition",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "oakdalefitnutrition",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Oakdale Fit Nutrition",
      "city" : "Stockton",
      "companyName" : "Oakdale Fit Nutrition",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "dreamicecreamparlor" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 888-5450",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "dreamicecreamparlor",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "dreamicecreamparlor",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Dream Ice Cream Parlor",
      "city" : "Stockton",
      "companyName" : "Dream Ice Cream Parlor",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.dreamicecreamparlor.com\/",
      "email" : ""
    }
  },
  "clubhousedrive" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/lRrkbt8iB2WZFoniSnC3o5LAllMSqbPv.png",
      "instagramHandle" : "clubhousedrive",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "clubhousedrive",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Clubhouse Drive",
      "city" : "Chula Vista",
      "companyName" : "Clubhouse Drive",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "kickasscupcakes" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(408) 421-6328",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/zgWmaip7pB0NYJBrQ1vMobAMY4kfIbks.png",
      "instagramHandle" : "kickasscupcakes",
      "premium" : false,
      "merchantHandle" : "kickasscupcakes",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Kick-Ass Cupcakes",
      "city" : "San Jose",
      "companyName" : "Kick-Ass Cupcakes",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.kacupcakes.com\/",
      "email" : ""
    }
  },
  "somisomi" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(949) 387-6333",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/OQrxDc6Sa6Gvku3vxFaAzxxw0n2C3hRs.png",
      "instagramHandle" : "somisomi",
      "premium" : false,
      "merchantHandle" : "somisomi",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "SOMISOMI",
      "city" : "Santa Ana",
      "companyName" : "SOMISOMI",
      "websiteUrl" : "https:\/\/www.somisomi.com\/locations",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sweetheartbakingco" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(360) 601-9121",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/HrwywZHdgLthGtJdWLGd2McSoen6KC2K.png",
      "instagramHandle" : "sweetheartbakingco",
      "merchantHandle" : "sweetheartbakingco",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Sweetheart Baking Co",
      "city" : "Vancouver",
      "companyName" : "Sweetheart Baking Co",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "eddunnebackgirlsfarm" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 784-0058",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/51cfxWrr1ylB60JIIUAw72ORvr5Ce5Vd.png",
      "instagramHandle" : "eddunnebackgirlsfarm",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "eddunnebackgirlsfarm",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Ed Dunneback & Girls Farm",
      "city" : "Grand Rapids",
      "companyName" : "Ed Dunneback & Girls Farm",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.dunnebackgirls.com\/",
      "email" : ""
    }
  },
  "happylemon" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 680-9399",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/d49f61f0e2c2abec58987f6344e5c09e8ed5d51e\/original.png",
      "instagramHandle" : "happylemon",
      "merchantHandle" : "happylemon",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Happy Lemon",
      "city" : "Sacramento",
      "companyName" : "Happy Lemon",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.happylemonsacramento.com\/menu",
      "email" : ""
    }
  },
  "offthewaffledowntown" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(541) 632-4225",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/MBhaGf22ZUhLLfDw7TNVMqLMccJ25zzm.png",
      "instagramHandle" : "offthewaffledowntown",
      "merchantHandle" : "offthewaffledowntown",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Off The Waffle Downtown",
      "city" : "Eugene",
      "companyName" : "Off The Waffle Downtown",
      "websiteUrl" : "http:\/\/www.offthewaffle.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "redlobster" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(509) 489-2777",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Fi0GgxLgrzFMzhTRaGqaGYYaPIoOA1s0.png",
      "instagramHandle" : "redlobster",
      "twitterHandle" : "",
      "merchantHandle" : "redlobster",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Red Lobster",
      "city" : "Spokane",
      "companyName" : "Red Lobster",
      "websiteUrl" : "https:\/\/www.redlobster.com\/seafood-restaurants\/locations\/wa\/spokane\/4703-n-division-street",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "valleygolfcenter" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(559) 439-2712",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/2H0vPl1gPlkpsEYglBtm1L1mBbYVikxU.png",
      "instagramHandle" : "valleygolfcenter",
      "twitterHandle" : "",
      "merchantHandle" : "valleygolfcenter",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Valley Golf Center",
      "city" : "Fresno",
      "companyName" : "Valley Golf Center",
      "websiteUrl" : "http:\/\/www.valleygolfcenterfresno.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "fakasislandgrill" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 514-0593",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/oZqSSFUhAaa2mz34FEbBzWpj9xy0y0cG.png",
      "instagramHandle" : "fakasislandgrill",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "fakasislandgrill",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Fakas Island Grill",
      "city" : "Santa Ana",
      "companyName" : "Fakas Island Grill",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.fakasislandgrill.com\/",
      "email" : ""
    }
  },
  "yoyoteashoppe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(949) 899-3671",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Fu2dOBSEDnHWCaiLaHIj0lar8QcGh04V.png",
      "instagramHandle" : "yoyoteashoppe",
      "twitterHandle" : "",
      "merchantHandle" : "yoyoteashoppe",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "YOYO TEA SHOPPE",
      "city" : "Santa Ana",
      "companyName" : "YOYO TEA SHOPPE",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "universityofmichiganflintbookstore" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(810) 762-3030",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "universityofmichiganflintbookstore",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "universityofmichiganflintbookstore",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "University of Michigan - Flint Bookstore",
      "city" : "Flint",
      "companyName" : "University of Michigan - Flint Bookstore",
      "websiteUrl" : "http:\/\/umflint.bncollege.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thesconeagebakery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(562) 452-3999",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/a4bd128e0518ae508467123cdee6a3af8f4ddd94\/original.jpeg",
      "instagramHandle" : "thesconeagebakery",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "thesconeagebakery",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Sconeage Bakery",
      "city" : "Long Beach",
      "companyName" : "The Sconeage Bakery",
      "websiteUrl" : "https:\/\/www.sconeage.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "flordecafebakery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(818) 543-1401",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/a17fd13113de02a10a2a9fb6eea842572fa5e349\/original.png",
      "instagramHandle" : "flordecafebakery",
      "premium" : false,
      "merchantHandle" : "flordecafebakery",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Flor De Cafe Bakery",
      "city" : "Glendale",
      "companyName" : "Flor De Cafe Bakery",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "brianbarrett" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 473-2383",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/zxoeXqLZMqIJozeoswO2wPfwsGbwVci0.png",
      "instagramHandle" : "brianbarrett",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "brianbarrett",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Brian Barrett",
      "city" : "Stockton",
      "companyName" : "Brian Barrett",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "ediblearrangements" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 924-9911",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/5GjELcaDzKT8dawALcDiWDmgqw6ScHKZ.png",
      "instagramHandle" : "ediblearrangements",
      "premium" : false,
      "merchantHandle" : "ediblearrangements",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Edible Arrangements",
      "city" : "Moreno Valley",
      "companyName" : "Edible Arrangements",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.ediblearrangements.com\/stores\/26150-iris-ave-moreno-valley-ca-92555",
      "email" : ""
    }
  },
  "bigvicsbbq" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 596-2131",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ezne6DE2c3CZn89boaGsUyc95DzNIstU.png",
      "instagramHandle" : "bigvicsbbq",
      "merchantHandle" : "bigvicsbbq",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Big Vic's BBQ",
      "city" : "Modesto",
      "companyName" : "Big Vic's BBQ",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.ltdbbq.com\/",
      "email" : ""
    }
  },
  "rowdysbrewco" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 929-2722",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/zBJtN8eF9tyepjFyZUC0t36zgZMNcLXN.png",
      "instagramHandle" : "rowdysbrewco",
      "twitterHandle" : "",
      "merchantHandle" : "rowdysbrewco",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Rowdy's Brew Co.",
      "city" : "San Bernardino",
      "companyName" : "Rowdy's Brew Co.",
      "websiteUrl" : "http:\/\/rowdysbrewco.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "historiabakerycafe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 371-1127",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/23e33e16cea7e67090d258368e5aaa59477d0999\/original.jpeg",
      "instagramHandle" : "historiabakerycafe",
      "twitterHandle" : "",
      "merchantHandle" : "historiabakerycafe",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Historia Bakery Cafe",
      "city" : "Oxnard",
      "companyName" : "Historia Bakery Cafe",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/historiabakerycafe.com\/",
      "email" : ""
    }
  },
  "alaskanseafoodguys" : {
    "info" : {
      "state" : "MT\r",
      "posSystem" : "Square",
      "phone" : "(406) 534-2778",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/OkM6Xkp0jLYQlgyQv085L6rag2d40w56.png",
      "instagramHandle" : "alaskanseafoodguys",
      "premium" : false,
      "merchantHandle" : "alaskanseafoodguys",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Alaskan Seafood Guys",
      "city" : "Billings",
      "companyName" : "Alaskan Seafood Guys",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.alaskanseafoodguys.com\/",
      "email" : ""
    }
  },
  "yumvillage" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/zhWah5R029GCp106dXpVyawy0IZIlQBa.png",
      "instagramHandle" : "yumvillage",
      "premium" : false,
      "merchantHandle" : "yumvillage",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Yumvillage",
      "city" : "Detroit",
      "companyName" : "Yumvillage",
      "websiteUrl" : "http:\/\/www.yumvillagemarketpantry.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "tappedapplecider" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(888) 327-4120",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/8HwUBmtWJ0JAlkbTGILTx0Eo7ivjvlwY.png",
      "instagramHandle" : "tappedapplecider",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "tappedapplecider",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Tapped Apple Cider",
      "city" : "Stockton",
      "companyName" : "Tapped Apple Cider",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.tappedapplecider.com\/",
      "email" : ""
    }
  },
  "appusturmericcafelongbeachveganindianfood" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(562) 431-5120",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/cCsCy3F9InM40G9Yax8nxdt1bxNHMXyZ.png",
      "instagramHandle" : "appusturmericcafelongbeachveganindianfood",
      "premium" : false,
      "merchantHandle" : "appusturmericcafelongbeachveganindianfood",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Appu's Turmeric Cafe Long Beach - Vegan Indian Food",
      "city" : "Long Beach",
      "companyName" : "Appu's Turmeric Cafe Long Beach - Vegan Indian Food",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.appuscafe.us\/",
      "email" : ""
    }
  },
  "brooklynbargrill" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/JfPBHW7UsVzQoTIHx6urKkn54mNXD0eM.png",
      "instagramHandle" : "brooklynbargrill",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "brooklynbargrill",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Brooklyn Bar & Grill",
      "city" : "Chula Vista",
      "companyName" : "Brooklyn Bar & Grill",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "theroyaleaglefinedining" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(313) 521-1894",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/X14wyrjhmANMKZgXkQk6zFguL0Eyd5yl.png",
      "instagramHandle" : "theroyaleaglefinedining",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "theroyaleaglefinedining",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Royal Eagle Fine Dining",
      "city" : "Warren",
      "companyName" : "The Royal Eagle Fine Dining",
      "websiteUrl" : "http:\/\/www.theroyaleagle.net\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "cafecitoorganicoatwater" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(213) 550-3738",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/cv9IEfa5s7k0tWZaiDNBQld8LeHbRdki.png",
      "instagramHandle" : "cafecitoorganicoatwater",
      "twitterHandle" : "",
      "merchantHandle" : "cafecitoorganicoatwater",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Cafecito Organico Atwater",
      "city" : "Glendale",
      "companyName" : "Cafecito Organico Atwater",
      "websiteUrl" : "http:\/\/cafecitoatwater.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "lovelysfiftyfifty" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(503) 281-4060",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/TOjMFqJk0xudrmN50f9F9VxHNNG64i5s.png",
      "instagramHandle" : "lovelysfiftyfifty",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "lovelysfiftyfifty",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Lovely's Fifty Fifty",
      "city" : "Vancouver",
      "companyName" : "Lovely's Fifty Fifty",
      "websiteUrl" : "https:\/\/lovelys5050.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "coffeedose" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(949) 994-1292",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/7HCSONtSo9GL5270OQ0t0H61HO6VDS0V.png",
      "instagramHandle" : "coffeedose",
      "merchantHandle" : "coffeedose",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Coffee Dose",
      "city" : "Santa Ana",
      "companyName" : "Coffee Dose",
      "websiteUrl" : "http:\/\/coffeedose.cafe\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "weldonspasties" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(586) 264-8370",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/4Gl8ypZMHrYl1gaGRoTBggW5N25OfoTE.png",
      "instagramHandle" : "weldonspasties",
      "twitterHandle" : "",
      "merchantHandle" : "weldonspasties",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Weldon's Pasties",
      "city" : "Sterling Heights",
      "companyName" : "Weldon's Pasties",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "woodyscafe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 893-0281",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/b346dd3d0e1a74738ae2f179291b92f51436038b\/original.jpeg",
      "instagramHandle" : "woodyscafe",
      "premium" : false,
      "merchantHandle" : "woodyscafe",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Woody's Cafe",
      "city" : "Oakland",
      "companyName" : "Woody's Cafe",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/woodyscafe.weebly.com\/",
      "email" : ""
    }
  },
  "palattecoffeeart" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 802-2626",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/P03H94KNSkIRIrZPWIU3esopXu6Lc6ih.png",
      "instagramHandle" : "palattecoffeeart",
      "premium" : false,
      "merchantHandle" : "palattecoffeeart",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "PaLatte Coffee & Art",
      "city" : "Grand Rapids",
      "companyName" : "PaLatte Coffee & Art",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.facebook.com\/palattecoffee",
      "email" : ""
    }
  },
  "alrahmafoodbank" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 378-3033",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/lkppD8lYfal6NnmD5ipOQ8TNr0r54QmL.png",
      "instagramHandle" : "alrahmafoodbank",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "alrahmafoodbank",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Al Rahma Food Bank",
      "city" : "Moreno Valley",
      "companyName" : "Al Rahma Food Bank",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "italianissimotrattoria" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(619) 651-1457",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "italianissimotrattoria",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "italianissimotrattoria",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Italianissimo Trattoria",
      "city" : "Chula Vista",
      "companyName" : "Italianissimo Trattoria",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/italianissimotrat.com\/",
      "email" : ""
    }
  },
  "mysticeyebotanica" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(562) 543-0313",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/1d0caa9e93e3bf46687e904c65afb5352612944c\/original.jpeg",
      "instagramHandle" : "mysticeyebotanica",
      "merchantHandle" : "mysticeyebotanica",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Mystic Eye Botanica",
      "city" : "Long Beach",
      "companyName" : "Mystic Eye Botanica",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.mysticeyebotanica.com\/",
      "email" : ""
    }
  },
  "detroitminidonut" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(313) 528-6000",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/BHOjWQ5hsddqIqOc8q3pv6GakyVuELzN.png",
      "instagramHandle" : "detroitminidonut",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "detroitminidonut",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "DETROIT MINI DONUT",
      "city" : "Detroit",
      "companyName" : "DETROIT MINI DONUT",
      "websiteUrl" : "http:\/\/www.detroitminidonut.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "littlenoodlespokane" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(509) 994-5157",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Y9TSoOPlyUj0CJ6iRDASr0mHRNqLp9nH.png",
      "instagramHandle" : "littlenoodlespokane",
      "twitterHandle" : "",
      "merchantHandle" : "littlenoodlespokane",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Little noodle spokane",
      "city" : "Spokane",
      "companyName" : "Little noodle spokane",
      "websiteUrl" : "https:\/\/www.littlenoodlespokane.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "pokeatery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(408) 564-0864",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "pokeatery",
      "twitterHandle" : "",
      "merchantHandle" : "pokeatery",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Pokeatery",
      "city" : "San Jose",
      "companyName" : "Pokeatery",
      "websiteUrl" : "http:\/\/www.pokeatery.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "stlfabricationinc" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 823-5033",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "stlfabricationinc",
      "merchantHandle" : "stlfabricationinc",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "STL Fabrication Inc",
      "city" : "Fontana",
      "companyName" : "STL Fabrication Inc",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/stlfabrication.com\/3294.html",
      "email" : ""
    }
  },
  "reyescocacolabottling" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(949) 250-5961",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/uNOfqpdUhB25uruV0HILpaaQ5aH1yjFS.png",
      "instagramHandle" : "reyescocacolabottling",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "reyescocacolabottling",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Reyes Coca-Cola Bottling",
      "city" : "Huntington Beach",
      "companyName" : "Reyes Coca-Cola Bottling",
      "websiteUrl" : "http:\/\/www.coca-colacompany.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "mcdermottsportscomplex" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 398-6900",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/94uGhsmqqVcdGDOg1FudOu340FBPyOWc.png",
      "instagramHandle" : "mcdermottsportscomplex",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "mcdermottsportscomplex",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "McDermott Sports Complex",
      "city" : "Fontana",
      "companyName" : "McDermott Sports Complex",
      "websiteUrl" : "https:\/\/www.fontanaca.gov\/715\/McDermott-Sports-Complex-Park-West",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "cakemittenbakeshop" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(586) 333-5106",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/yOo1j5ta9izR9H8pojtQSncXUJdcoLq1.png",
      "instagramHandle" : "cakemittenbakeshop",
      "twitterHandle" : "",
      "merchantHandle" : "cakemittenbakeshop",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Cake Mitten Bake Shop",
      "city" : "Warren",
      "companyName" : "Cake Mitten Bake Shop",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.cakemitten.com\/",
      "email" : ""
    }
  },
  "sugarbeecustombakeshop" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 335-3059",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/48f6c18cbda413bbf15d2f7f2c30f6a47cb0f408\/original.jpeg",
      "instagramHandle" : "sugarbeecustombakeshop",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "sugarbeecustombakeshop",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Sugarbee Custom Bake Shop",
      "city" : "San Bernardino",
      "companyName" : "Sugarbee Custom Bake Shop",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.sugarbeecustomcakes.com\/",
      "email" : ""
    }
  },
  "lineacaffe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 590-3011",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/XVldK7Z6MIrrFcSMf0u2AI3wcAhcKtxB.png",
      "instagramHandle" : "lineacaffe",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "lineacaffe",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Linea Caffe",
      "city" : "San Francisco",
      "companyName" : "Linea Caffe",
      "websiteUrl" : "http:\/\/www.lineacaffe.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "smootheory" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 363-3418",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/3a6b4d6c33f619d2bed228d34cf471bbe332708f\/original.jpeg",
      "instagramHandle" : "smootheory",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "smootheory",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Smootheory",
      "city" : "Santa Ana",
      "companyName" : "Smootheory",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "chowtogochineserestaurant" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 951-5407",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/28455cd8d3cae5ac8199dd71091d7dcbaed9ece7\/original.jpeg",
      "instagramHandle" : "chowtogochineserestaurant",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "chowtogochineserestaurant",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Chow To Go Chinese Restaurant",
      "city" : "Stockton",
      "companyName" : "Chow To Go Chinese Restaurant",
      "websiteUrl" : "https:\/\/www.facebook.com\/pages\/category\/Chinese-Restaurant\/Chow-To-Go-Restaurant-151251408232142\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "upnorthsurfclub" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/zpn0Vh84QK6pX0gfnCtX4tm2tlahmJdY.png",
      "instagramHandle" : "upnorthsurfclub",
      "premium" : false,
      "merchantHandle" : "upnorthsurfclub",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Up North Surf club",
      "city" : "Vancouver",
      "companyName" : "Up North Surf club",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.upnorthsurfclub.com\/",
      "email" : ""
    }
  },
  "ambitiouskreations" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(810) 230-4813",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/rnPxaMLOxjTi9zClLt8q1d0uqRBQgG57.png",
      "instagramHandle" : "ambitiouskreations",
      "twitterHandle" : "",
      "merchantHandle" : "ambitiouskreations",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Ambitious Kreations",
      "city" : "Flint",
      "companyName" : "Ambitious Kreations",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "panaderiapaloza" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 547-7352",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/i9xvsSJEXoRoTIXCshFic6UbaVK5vWUP.png",
      "instagramHandle" : "panaderiapaloza",
      "premium" : false,
      "merchantHandle" : "panaderiapaloza",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Panaderia Paloza",
      "city" : "Santa Ana",
      "companyName" : "Panaderia Paloza",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "bentokitchencravings" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(517) 580-8103",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/e60f78dd33b4fd9f66ce32926237f5b0bfcac33c\/original.jpeg",
      "instagramHandle" : "bentokitchencravings",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "bentokitchencravings",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Bento Kitchen Cravings",
      "city" : "Lansing",
      "companyName" : "Bento Kitchen Cravings",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/m.facebook.com\/pages\/category\/Korean-Restaurant\/Bento-Kitchen-Cravings-932954157093020\/",
      "email" : ""
    }
  },
  "85cbakerycafemorenovalley" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(951) 208-2249",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/RoBFBlLfllNVzEjy7XdCEJ5JtpLOrFAN.png",
      "instagramHandle" : "85cbakerycafemorenovalley",
      "twitterHandle" : "",
      "merchantHandle" : "85cbakerycafemorenovalley",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "85°C Bakery Cafe - Moreno Valley",
      "city" : "Moreno Valley",
      "companyName" : "85°C Bakery Cafe - Moreno Valley",
      "websiteUrl" : "https:\/\/www.85cbakerycafe.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "bobateaque" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(657) 888-5238",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ny0DZmkQ5ZNIiSzalqI4Hvap1hOqlg6c.png",
      "instagramHandle" : "bobateaque",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "bobateaque",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "bobaTEAque",
      "city" : "Santa Ana",
      "companyName" : "bobaTEAque",
      "websiteUrl" : "https:\/\/www.bobateaque.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "proteinlab" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(657) 246-3122",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/245d4c521d7e2a3d6a041ee56fca6828c1fe67b0\/original.png",
      "instagramHandle" : "proteinlab",
      "twitterHandle" : "",
      "merchantHandle" : "proteinlab",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "Smoothie Bowls",
      "merchantName" : "Protein Lab",
      "city" : "Anaheim",
      "companyName" : "Protein Lab",
      "websiteUrl" : "http:\/\/www.fueledbyproteinlab.com\/",
      "tikTokHandle" : "",
      "email" : "info@fueldbyproteinlab.com"
    }
  },
  "vibewell" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(810) 208-0201",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/vYbADxBJ7gRr7EWO0zXL0uNmmDFxgNgs.png",
      "instagramHandle" : "vibewell",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "vibewell",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Vibe Well",
      "city" : "Flint",
      "companyName" : "Vibe Well",
      "websiteUrl" : "https:\/\/www.vibewell.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "marbledla" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(323) 963-3475",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/29a4pyaAjVx523KX0knpgHATrbDaaQQC.png",
      "instagramHandle" : "marbledla",
      "twitterHandle" : "",
      "merchantHandle" : "marbledla",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Marbled LA",
      "city" : "Glendale",
      "companyName" : "Marbled LA",
      "websiteUrl" : "http:\/\/www.marbled.la\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "villagebakingcompanycafe" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 522-9057",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/jq5eprHeZl3IxaXs1yoKmgUzp0OgdJ0A.png",
      "instagramHandle" : "villagebakingcompanycafe",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "villagebakingcompanycafe",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Village Baking Company & Cafe",
      "city" : "Modesto",
      "companyName" : "Village Baking Company & Cafe",
      "websiteUrl" : "http:\/\/www.villagebakingcompany.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "urokocafe" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 436-5798",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ERKwRdApsslSZxdtHvusG8wCbYU3PCfE.png",
      "instagramHandle" : "urokocafe",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "urokocafe",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Uroko Cafe",
      "city" : "Santa Ana",
      "companyName" : "Uroko Cafe",
      "websiteUrl" : "http:\/\/www.urokocafe.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "smokequeenbarbecue" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 583-8251",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/wg4iWR5yrnkIWbmNZ7kajfqg46oCPMlL.png",
      "instagramHandle" : "smokequeenbarbecue",
      "premium" : false,
      "merchantHandle" : "smokequeenbarbecue",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Smoke Queen Barbecue",
      "city" : "Santa Ana",
      "companyName" : "Smoke Queen Barbecue",
      "websiteUrl" : "http:\/\/www.smokequeenbbq.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "dreamscapedesserts" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(616) 930-4030",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Sc0dBeULYF60ZByClM5AKrVBoip1umTL.png",
      "instagramHandle" : "dreamscapedesserts",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "dreamscapedesserts",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "DreamScape Desserts",
      "city" : "Grand Rapids",
      "companyName" : "DreamScape Desserts",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.dreamscapedesserts.com\/",
      "email" : ""
    }
  },
  "hydrationroomirvinecenter" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(949) 418-7518",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "hydrationroomirvinecenter",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "hydrationroomirvinecenter",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Hydration Room | Irvine Center",
      "city" : "Irvine",
      "companyName" : "Hydration Room | Irvine Center",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.hydrationroom.com\/",
      "email" : ""
    }
  },
  "genesisivhydration" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 495-4050",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "genesisivhydration",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "genesisivhydration",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Genesis IV Hydration",
      "city" : "Santa Ana",
      "companyName" : "Genesis IV Hydration",
      "websiteUrl" : "https:\/\/genesisivhydration.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "smashthatburgersmore" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "smashthatburgersmore",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "smashthatburgersmore",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Smash That Burgers & More",
      "city" : "Modesto",
      "companyName" : "Smash That Burgers & More",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/smashthatca.com\/",
      "email" : ""
    }
  },
  "slinginwiener" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(541) 499-2602",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/h20ItlgKpSiKw4PZSUmDjatJR2eZYeuf.png",
      "instagramHandle" : "slinginwiener",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "slinginwiener",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sling-in Wiener",
      "city" : "Eugene",
      "companyName" : "Sling-in Wiener",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.slinginwiener.com\/",
      "email" : ""
    }
  },
  "firepie" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 213-2300",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/tEgXcl4DlIPICENGSP02dcel8tqarEga.png",
      "instagramHandle" : "firepie",
      "twitterHandle" : "",
      "merchantHandle" : "firepie",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Firepie",
      "city" : "San Francisco",
      "companyName" : "Firepie",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "niritsglutenfreebakery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(734) 730-6826",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/3b107273a6d3650b6aea73c700be5fca3af77807\/original.jpeg",
      "instagramHandle" : "niritsglutenfreebakery",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "niritsglutenfreebakery",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Nirit's Gluten Free Bakery",
      "city" : "Ann Arbor",
      "companyName" : "Nirit's Gluten Free Bakery",
      "websiteUrl" : "http:\/\/niritsglutenfreebakery.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "mayarshalalmeatgrill" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 408-0064",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/2f26f76147300fe84d1ea0c485d5b644b9314337\/original.png",
      "instagramHandle" : "mayarshalalmeatgrill",
      "twitterHandle" : "",
      "merchantHandle" : "mayarshalalmeatgrill",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Mayars Halal Meat & Grill",
      "city" : "Modesto",
      "companyName" : "Mayars Halal Meat & Grill",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/mayarshalalmeatgrillca.com\/",
      "email" : ""
    }
  },
  "beeryland" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(510) 444-8353",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/cnT2aEMSnekWN8bJJl8fyvAfPAa4oVQj.png",
      "instagramHandle" : "beeryland",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "beeryland",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Beeryland",
      "city" : "Oakland",
      "companyName" : "Beeryland",
      "websiteUrl" : "http:\/\/telegraphoakland.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "cottagebar" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 454-9088",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/cu4D43qYzEwDbzsnQmIIUDzCF57FRVMV.png",
      "instagramHandle" : "cottagebar",
      "merchantHandle" : "cottagebar",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Cottage Bar",
      "city" : "Grand Rapids",
      "companyName" : "Cottage Bar",
      "websiteUrl" : "http:\/\/www.cottagebar.biz\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "tcbsweetsandtreats" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(810) 487-9078",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "tcbsweetsandtreats",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "tcbsweetsandtreats",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "TCB Sweets And Treats",
      "city" : "Flint",
      "companyName" : "TCB Sweets And Treats",
      "websiteUrl" : "http:\/\/www.facebook.com\/tcbsweetsandtreats",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "ferronispizza" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 275-1500",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/dmPeWBnH12NC8a3fpJj2xfuxW5Sg0oS4.png",
      "instagramHandle" : "ferronispizza",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "ferronispizza",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Ferroni’s Pizza",
      "city" : "Stockton",
      "companyName" : "Ferroni’s Pizza",
      "websiteUrl" : "https:\/\/www.ferronispizza.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "vibeorganickitchenjuice" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(949) 478-4118",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/49b545ac827e435fc8a3f3c03edeb9c6cce01dd8\/original.jpeg",
      "instagramHandle" : "vibeorganickitchenjuice",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "vibeorganickitchenjuice",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Vibe Organic Kitchen & Juice",
      "city" : "Santa Ana",
      "companyName" : "Vibe Organic Kitchen & Juice",
      "websiteUrl" : "http:\/\/eatdrinkvibe.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thebrunchcaf" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 603-7622",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "thebrunchcaf",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "thebrunchcaf",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Brunch Café",
      "city" : "Anaheim",
      "companyName" : "The Brunch Café",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "monstersincmikesulleytorescue" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "",
      "phone" : "",
      "merchantImage" : "monstersincmikesulleytorescue",
      "instagramHandle" : " Inc. Mike & Sulley to the Rescue!\"",
      "merchantHandle" : "monstersincmikesulleytorescue",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "(714) 781-4636",
      "squareSandboxKey" : "",
      "merchantName" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/znA2VK8bMKxH57EhRIXYJaGWNjUHYtIl.png",
      "city" : "",
      "companyName" : "\"Monsters",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : "https:\/\/disneyland.disney.go.com\/attractions\/disney-california-adventure\/monsters-inc\/?CMP=OKC-353387_GM_DLR_attraction_monstersincmikesulleytotherescue_NA"
    }
  },
  "ifreezecreamery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(248) 260-0313",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/8mR9ovM9Jhqnxe5fYPan9HyrSnDyuGnL.png",
      "instagramHandle" : "ifreezecreamery",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "ifreezecreamery",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "iFreeze Creamery",
      "city" : "Warren",
      "companyName" : "iFreeze Creamery",
      "websiteUrl" : "https:\/\/www.ifreezecreamery15mile.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "rainiervalleycoffeeco" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/dsjFEEfGinj9zFqYRzOPEpgYeBFgdGLz.png",
      "instagramHandle" : "rainiervalleycoffeeco",
      "merchantHandle" : "rainiervalleycoffeeco",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Rainier Valley Coffee Co.",
      "city" : "Tacoma",
      "companyName" : "Rainier Valley Coffee Co.",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/rvccoffee.com\/",
      "email" : ""
    }
  },
  "behappycafjuicebar" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 243-7370",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/HgwjCsUbymGQxDsudtKVxwgtR22BAESa.png",
      "instagramHandle" : "behappycafjuicebar",
      "merchantHandle" : "behappycafjuicebar",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Be Happy Café & Juice Bar",
      "city" : "Moreno Valley",
      "companyName" : "Be Happy Café & Juice Bar",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/behappycafe.com\/",
      "email" : ""
    }
  },
  "tacoselgero" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 565-0108",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/P6J3DJru7mFWCRv2mcj8INuhaIgobZOf.png",
      "instagramHandle" : "tacoselgero",
      "premium" : false,
      "merchantHandle" : "tacoselgero",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Tacos El Güero",
      "city" : "Fontana",
      "companyName" : "Tacos El Güero",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/tacos-el-guero.cafe-inspector.com\/",
      "email" : ""
    }
  },
  "newyorkdonuts" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 463-3272",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Uux8I2ido6LROvwdoybesvlK1VWlSYUx.png",
      "instagramHandle" : "newyorkdonuts",
      "premium" : false,
      "merchantHandle" : "newyorkdonuts",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "New York Donuts",
      "city" : "Stockton",
      "companyName" : "New York Donuts",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "hapahowiesnw" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(503) 610-8420",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/3109d44fb5860e14676e4d9d585901cd85953406\/original.png",
      "instagramHandle" : "hapahowiesnw",
      "merchantHandle" : "hapahowiesnw",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Hapa Howies NW",
      "city" : "Vancouver",
      "companyName" : "Hapa Howies NW",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/kiahakurekportfolio.my.canva.site\/hapahowiesnw",
      "email" : ""
    }
  },
  "psychictiffanysspiritualhealingcenter" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 645-4343",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/DtGMWRyTbFvsVbMaruZm4qtmhwEqyaE1.png",
      "instagramHandle" : "psychictiffanysspiritualhealingcenter",
      "premium" : false,
      "merchantHandle" : "psychictiffanysspiritualhealingcenter",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Psychic Tiffany’s Spiritual Healing Center",
      "city" : "San Bernardino",
      "companyName" : "Psychic Tiffany’s Spiritual Healing Center",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "barrione" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(503) 478-0619",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/90c2eda80bd6b706da0da48b35fda587157c936a\/original.png",
      "instagramHandle" : "barrione",
      "merchantHandle" : "barrione",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Bar Rione",
      "city" : "Portland",
      "companyName" : "Bar Rione",
      "websiteUrl" : "http:\/\/www.barrione.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "coconoirwineshopbar" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 230-0500",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ncOyDKbd38zM6oa9IaQPpStYDfZ09WU4.png",
      "instagramHandle" : "coconoirwineshopbar",
      "twitterHandle" : "",
      "merchantHandle" : "coconoirwineshopbar",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "CoCo Noir Wine Shop & Bar",
      "city" : "Oakland",
      "companyName" : "CoCo Noir Wine Shop & Bar",
      "websiteUrl" : "http:\/\/www.coconoirwine.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "completehealthstore" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 879-1059",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Q7etiQ40xbihtagKZLJJgB2kz66THyos.png",
      "instagramHandle" : "completehealthstore",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "completehealthstore",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Complete Health Store",
      "city" : "San Bernardino",
      "companyName" : "Complete Health Store",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.rialtocompletehealth.com\/",
      "email" : ""
    }
  },
  "watersalt" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(916) 222-2301",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/vest5l9R7YlMmPYeaNr09DnXcoF4StGQ.png",
      "instagramHandle" : "watersalt",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "watersalt",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Water & Salt",
      "city" : "Sacramento",
      "companyName" : "Water & Salt",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/waternsalt.com\/",
      "email" : ""
    }
  },
  "slicepizzeriabar" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(541) 653-9937",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/f456a8bf44ce2645c06c3199d46b7e743b03e050\/original.png",
      "instagramHandle" : "slicepizzeriabar",
      "premium" : false,
      "merchantHandle" : "slicepizzeriabar",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Slice | Pizzeria & Bar",
      "city" : "Eugene",
      "companyName" : "Slice | Pizzeria & Bar",
      "websiteUrl" : "https:\/\/www.slicepizzeriabar.com\/?utm_source=gbp",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "blackbeardinerbakersfield" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(661) 322-5000",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/dVnVjJx1KkKe5Hxsr3ge5s9EOe9UMym4.png",
      "instagramHandle" : "blackbeardinerbakersfield",
      "merchantHandle" : "blackbeardinerbakersfield",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Black Bear Diner Bakersfield",
      "city" : "Bakersfield",
      "companyName" : "Black Bear Diner Bakersfield",
      "websiteUrl" : "https:\/\/blackbeardiner.com\/location\/bakersfield\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sunsoilhealthcompany" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 234-0149",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/7Gz5xbiz09I60QTj1KsiqyygR4ldLw3H.png",
      "instagramHandle" : "sunsoilhealthcompany",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "sunsoilhealthcompany",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sun & Soil Health Company",
      "city" : "Sacramento",
      "companyName" : "Sun & Soil Health Company",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/sunandsoil.health\/",
      "email" : ""
    }
  },
  "layercakebakerylcb" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(949) 786-0223",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/316195fa856ba709161b4e444023d10bccc68d67\/original.png",
      "instagramHandle" : "layercakebakerylcb",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "layercakebakerylcb",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Layer Cake Bakery (LCB)",
      "city" : "Santa Ana",
      "companyName" : "Layer Cake Bakery (LCB)",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.layercakebakery.com\/",
      "email" : ""
    }
  },
  "lolliesanddropsllc" : {
    "info" : {
      "state" : "",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "lolliesanddropsllc",
      "instagramHandle" : " LLC\"",
      "premium" : false,
      "merchantHandle" : "lolliesanddropsllc",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "(415) 619-2777",
      "squareSandboxKey" : "",
      "merchantName" : "No Logo Image Found on Website",
      "city" : "",
      "companyName" : "\"Lollies and Drops",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : "http:\/\/lolliesanddrops.square.site\/"
    }
  },
  "pizzasquared" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 525-3861",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/u7sjb7akF5OXZVlqM40VaUqhjr0CvnR3.png",
      "instagramHandle" : "pizzasquared",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "pizzasquared",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Pizza Squared",
      "city" : "San Francisco",
      "companyName" : "Pizza Squared",
      "websiteUrl" : "https:\/\/www.pizzasquaredsf.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "tamalebox" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(509) 474-1054",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/3lDHat7yIsTF0arNtW7Ambk9vaexvbta.png",
      "instagramHandle" : "tamalebox",
      "merchantHandle" : "tamalebox",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Tamale Box",
      "city" : "Spokane",
      "companyName" : "Tamale Box",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/tamaleboxspokane.com\/",
      "email" : ""
    }
  },
  "sushimo" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(360) 953-8860",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/fFaaBSJMlga8jdBIGASKVYVKPKYk1Dm7.png",
      "instagramHandle" : "sushimo",
      "premium" : false,
      "merchantHandle" : "sushimo",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sushi Mo",
      "city" : "Vancouver",
      "companyName" : "Sushi Mo",
      "websiteUrl" : "http:\/\/www.sushimousa.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "anchorcoffeehouse" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(519) 915-0626",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/eN876sryM0vOPikrWtNVPEbvNCkMg4td.png",
      "instagramHandle" : "anchorcoffeehouse",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "anchorcoffeehouse",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Anchor Coffee House",
      "city" : "Detroit",
      "companyName" : "Anchor Coffee House",
      "websiteUrl" : "http:\/\/anchorcoffeehouse.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "marylousgourmetpaninis" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(509) 328-0428",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/LDYIsFv3zweSLbY6ZPWi3SL5SefJHTV8.png",
      "instagramHandle" : "marylousgourmetpaninis",
      "merchantHandle" : "marylousgourmetpaninis",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "MaryLou's Gourmet Paninis",
      "city" : "Spokane",
      "companyName" : "MaryLou's Gourmet Paninis",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "galamarenterprisesinc" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 467-0621",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "galamarenterprisesinc",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "galamarenterprisesinc",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Galamar Enterprises Inc",
      "city" : "Santa Ana",
      "companyName" : "Galamar Enterprises Inc",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "willamettevalleypiecofarmretailstore" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(503) 362-8857",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/b7RjsOcWMhrMgUMsID5LkJ2tn58WEeOm.png",
      "instagramHandle" : "willamettevalleypiecofarmretailstore",
      "twitterHandle" : "",
      "merchantHandle" : "willamettevalleypiecofarmretailstore",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Willamette Valley Pie Co. Farm Retail Store",
      "city" : "Salem",
      "companyName" : "Willamette Valley Pie Co. Farm Retail Store",
      "websiteUrl" : "http:\/\/www.wvpie.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "theoldspaghettifactory" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(951) 784-4417",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/4805277220da06c9023047258c395085c778500b\/original.jpeg",
      "instagramHandle" : "theoldspaghettifactory",
      "merchantHandle" : "theoldspaghettifactory",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Old Spaghetti Factory",
      "city" : "Riverside",
      "companyName" : "The Old Spaghetti Factory",
      "websiteUrl" : "http:\/\/www.osf.com\/location\/riverside-ca\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "walmartneighborhoodmarket" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 968-7218",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "walmartneighborhoodmarket",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "walmartneighborhoodmarket",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Walmart Neighborhood Market",
      "city" : "Riverside",
      "companyName" : "Walmart Neighborhood Market",
      "websiteUrl" : "https:\/\/www.walmart.com\/store\/5663-jurupa-valley-ca",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "espressometro" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 444-6130",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/84ad470a240b04d75e71ac87108ae61446acb546\/original.jpeg",
      "instagramHandle" : "espressometro",
      "premium" : false,
      "merchantHandle" : "espressometro",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Espresso Metro",
      "city" : "Sacramento",
      "companyName" : "Espresso Metro",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "eaglerockbrewerypublichouse" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/10126f79bcab9f58b40cbe1380007cd98592d98a\/original.jpeg",
      "instagramHandle" : "eaglerockbrewerypublichouse",
      "merchantHandle" : "eaglerockbrewerypublichouse",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Eagle Rock Brewery Public House",
      "city" : "Glendale",
      "companyName" : "Eagle Rock Brewery Public House",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/eaglerockpublichouse.com\/",
      "email" : ""
    }
  },
  "drpatrickwoodman" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(248) 680-8000",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "drpatrickwoodman",
      "twitterHandle" : "",
      "merchantHandle" : "drpatrickwoodman",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Dr. Patrick Woodman",
      "city" : "Warren",
      "companyName" : "Dr. Patrick Woodman",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "outsidecoffee" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/DojaF2MtX8c89BJw4hS7xU00qPtlyNRS.png",
      "instagramHandle" : "outsidecoffee",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "outsidecoffee",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Outside Coffee",
      "city" : "Grand Rapids",
      "companyName" : "Outside Coffee",
      "websiteUrl" : "http:\/\/www.outsidecoffeeco.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "lagocciaespressobar" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(818) 545-9219",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/7C7rRmk0aHTgs0THqN3lGA8TJcBToDMC.png",
      "instagramHandle" : "lagocciaespressobar",
      "merchantHandle" : "lagocciaespressobar",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "La Goccia Espresso Bar",
      "city" : "Glendale",
      "companyName" : "La Goccia Espresso Bar",
      "websiteUrl" : "http:\/\/lagocciaespresso.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "camarillobarrelworks" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(805) 484-9600",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/62AnFDNL52mWLqkZaGhq5tNI2bVEau4Q.png",
      "instagramHandle" : "camarillobarrelworks",
      "twitterHandle" : "",
      "merchantHandle" : "camarillobarrelworks",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Camarillo Barrel Works",
      "city" : "Oxnard",
      "companyName" : "Camarillo Barrel Works",
      "websiteUrl" : "http:\/\/camarillobarrelworks.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "stephenpthompsonod" : {
    "info" : {
      "state" : "",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "stephenpthompsonod",
      "instagramHandle" : " OD\"",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "stephenpthompsonod",
      "squareProductionKey" : "",
      "companyType" : "(517) 977-1598",
      "squareSandboxKey" : "",
      "merchantName" : "No Logo Image Found on Website",
      "city" : "",
      "companyName" : "\"Stephen P. Thompson",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : "http:\/\/eastlansingfamilyeyecare.com\/"
    }
  },
  "theterminalatlinebrewingcompany" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 985-4191",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/XtqJGhNnTK9Bg5NQ3Q3Wo5ZZquN8676c.png",
      "instagramHandle" : "theterminalatlinebrewingcompany",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "theterminalatlinebrewingcompany",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Terminal at Line 51 Brewing Company",
      "city" : "Oakland",
      "companyName" : "The Terminal at Line 51 Brewing Company",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/line51beer.com\/",
      "email" : ""
    }
  },
  "freshfabulouscafe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 486-4547",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/efc9002f54e9b384ad17a80579f0868cfcf60eca\/original.jpeg",
      "instagramHandle" : "freshfabulouscafe",
      "merchantHandle" : "freshfabulouscafe",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Fresh & Fabulous Cafe",
      "city" : "Oxnard",
      "companyName" : "Fresh & Fabulous Cafe",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.myfreshandfabulous.com\/",
      "email" : ""
    }
  },
  "ccwbarandrestaurant" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 922-1319",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Gce0QuXMaP65caaBXLoLeFUzCSBFTiud.png",
      "instagramHandle" : "ccwbarandrestaurant",
      "merchantHandle" : "ccwbarandrestaurant",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "CCW Bar and Restaurant",
      "city" : "Oakland",
      "companyName" : "CCW Bar and Restaurant",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "topofindia" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(509) 927-0500",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/0aGeaegSF1kw19UD0n7iy5S2GRGJ2e9k.png",
      "instagramHandle" : "topofindia",
      "twitterHandle" : "",
      "merchantHandle" : "topofindia",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Top of India",
      "city" : "Spokane",
      "companyName" : "Top of India",
      "websiteUrl" : "http:\/\/www.topofindiarestaurant.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "plenty" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(510) 740-9931",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/memWC207sylEJceXmQbST9HRPNIn9tIj.png",
      "instagramHandle" : "plenty",
      "merchantHandle" : "plenty",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Plenty",
      "city" : "Oakland",
      "companyName" : "Plenty",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/plentyoakland.wixsite.com\/website",
      "email" : ""
    }
  },
  "conniemelaniechoy" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(951) 243-0811",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/R0dexarC2LabzFWk6sobEWFcA6P3DG7k.png",
      "instagramHandle" : "conniemelaniechoy",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "conniemelaniechoy",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "CONNIE MELANIE CHOY",
      "city" : "Moreno Valley",
      "companyName" : "CONNIE MELANIE CHOY",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "laburritafoodtruck" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(510) 491-6660",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "laburritafoodtruck",
      "merchantHandle" : "laburritafoodtruck",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "La Burrita Food Truck",
      "city" : "San Jose",
      "companyName" : "La Burrita Food Truck",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "reborncoffeehuntingtonbeach" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 845-9558",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/OS1jIE6uqJvBadVL6xZRx5vFLfWVaFcS.png",
      "instagramHandle" : "reborncoffeehuntingtonbeach",
      "premium" : false,
      "merchantHandle" : "reborncoffeehuntingtonbeach",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Reborn Coffee - Huntington Beach",
      "city" : "Huntington Beach",
      "companyName" : "Reborn Coffee - Huntington Beach",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/reborncoffee.com\/",
      "email" : ""
    }
  },
  "byronblattywines" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(323) 507-2174",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/rN3S0LoxAtaHTtIxw76HtTcgFFaQ2agj.png",
      "instagramHandle" : "byronblattywines",
      "merchantHandle" : "byronblattywines",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Byron Blatty Wines",
      "city" : "Glendale",
      "companyName" : "Byron Blatty Wines",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/byronblatty.com\/",
      "email" : ""
    }
  },
  "folksart" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(510) 967-1173",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/bd6ae5bee4dce8fd462213c3caaa545b0d258b17\/original.jpeg",
      "instagramHandle" : "folksart",
      "twitterHandle" : "",
      "merchantHandle" : "folksart",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Folks' Art",
      "city" : "Oakland",
      "companyName" : "Folks' Art",
      "websiteUrl" : "http:\/\/www.folksartstore.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "happyfamilyvegetarian" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 783-8928",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/PMvOB9x6YM4R2koBN0B0B5pgaAtVFpuZ.png",
      "instagramHandle" : "happyfamilyvegetarian",
      "premium" : false,
      "merchantHandle" : "happyfamilyvegetarian",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Happy Family Vegetarian",
      "city" : "San Bernardino",
      "companyName" : "Happy Family Vegetarian",
      "websiteUrl" : "https:\/\/www.sweetveg.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sweetartcreativebaking" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(925) 202-7776",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/bd979ea3e1d44f792f7992646885a175b6ecdda5\/original.jpeg",
      "instagramHandle" : "sweetartcreativebaking",
      "merchantHandle" : "sweetartcreativebaking",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sweetart Creative Baking",
      "city" : "Stockton",
      "companyName" : "Sweetart Creative Baking",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/cakesofsweetart.com\/",
      "email" : ""
    }
  },
  "gatherjuiceco" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(253) 999-5610",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/sPD1reapt7Plybn3uIjH8atPyMHKcsan.png",
      "instagramHandle" : "gatherjuiceco",
      "twitterHandle" : "",
      "merchantHandle" : "gatherjuiceco",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Gather Juice Co.",
      "city" : "Tacoma",
      "companyName" : "Gather Juice Co.",
      "websiteUrl" : "http:\/\/www.gatherjuice.co\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "atypicalwafflecompany" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(619) 806-0345",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Dbuo2RvDaM1jBI1WshaO31MB84l5AGfg.png",
      "instagramHandle" : "atypicalwafflecompany",
      "merchantHandle" : "atypicalwafflecompany",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Atypical Waffle Company",
      "city" : "Chula Vista",
      "companyName" : "Atypical Waffle Company",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/atypicalwaffle.com\/",
      "email" : ""
    }
  },
  "smallworldkitchen" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(313) 744-7280",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/b73ddf946259609f4b4e5e9edbcd1832a5afe6cb\/original.jpeg",
      "instagramHandle" : "smallworldkitchen",
      "twitterHandle" : "",
      "merchantHandle" : "smallworldkitchen",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Small World Kitchen",
      "city" : "Detroit",
      "companyName" : "Small World Kitchen",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "theberrypickinbakerllc" : {
    "info" : {
      "state" : "",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "theberrypickinbakerllc",
      "instagramHandle" : " LLC\"",
      "merchantHandle" : "theberrypickinbakerllc",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "(517) 618-1725",
      "merchantName" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Bo22blYOvRaMdb0lav0srNFDYPnJh65L.png",
      "city" : "",
      "companyName" : "\"The Berry Pickin' Baker",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : "https:\/\/www.theberrypickinbaker.com\/"
    }
  },
  " and Cider Tasting & Tap Room\"" : {
    "info" : {
      "state" : "(586) 231-9501",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "superiorlakesmeadwineandcidertastingtaproom",
      "instagramHandle" : " Wine",
      "premium" : false,
      "merchantHandle" : " and Cider. Tasting & Tap Room\"",
      "squareProductionKey" : " Wine",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : " and Cider. Tasting & Tap Room\"",
      "merchantName" : "superiorlakesmeadwineandcidertastingtaproom",
      "city" : "http:\/\/superiorlakes.com\/",
      "companyName" : "\"Superior Lakes Mead",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "teofilocoffeecompany" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 715-7183",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/c9W14Z086glDBRZRqwty4pTaoYJsD8bB.jpg",
      "instagramHandle" : "teofilocoffeecompany",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "teofilocoffeecompany",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Teofilo Coffee Company",
      "city" : "Long Beach",
      "companyName" : "Teofilo Coffee Company",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.teofilocoffeecompany.com\/",
      "email" : ""
    }
  },
  "oxnardbusinesslicenses" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 385-7817",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "oxnardbusinesslicenses",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "oxnardbusinesslicenses",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Oxnard Business Licenses",
      "city" : "Oxnard",
      "companyName" : "Oxnard Business Licenses",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "sipsbistro" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 527-7477",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/IG1Ht0NFuK8Stha7tXVnKOQRaE6afH45.png",
      "instagramHandle" : "sipsbistro",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "sipsbistro",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sip's Bistro",
      "city" : "Modesto",
      "companyName" : "Sip's Bistro",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "thejeffersonmarket" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(734) 665-6666",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/4WVV15YoLM0kOiex1kYQwmlIsCoCTgja.png",
      "instagramHandle" : "thejeffersonmarket",
      "merchantHandle" : "thejeffersonmarket",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Jefferson Market",
      "city" : "Ann Arbor",
      "companyName" : "The Jefferson Market",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.thejeffersonmarket.com\/",
      "email" : ""
    }
  },
  "lospinchestacos" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 286-9923",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "lospinchestacos",
      "merchantHandle" : "lospinchestacos",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Los pinches tacos",
      "city" : "Grand Rapids",
      "companyName" : "Los pinches tacos",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "teachat" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(517) 515-5074",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "teachat",
      "premium" : false,
      "merchantHandle" : "teachat",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Tea Chat",
      "city" : "Lansing",
      "companyName" : "Tea Chat",
      "websiteUrl" : "https:\/\/www.facebook.com\/teachat1llc\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "localbusinesscoach" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(810) 202-8760",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/b8d642cd1fed8d4b2434642c8441fcb02e81193b\/original.jpeg",
      "instagramHandle" : "localbusinesscoach",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "localbusinesscoach",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Local Business Coach",
      "city" : "Flint",
      "companyName" : "Local Business Coach",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/localbusinesscoach.com\/",
      "email" : ""
    }
  },
  "souqlebanesegrill" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(519) 915-1292",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/2dbad85f46653aee5190daf4f598e299c6c738c2\/original.png",
      "instagramHandle" : "souqlebanesegrill",
      "premium" : false,
      "merchantHandle" : "souqlebanesegrill",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Souq Lebanese Grill",
      "city" : "Detroit",
      "companyName" : "Souq Lebanese Grill",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.souqyqg.ca\/",
      "email" : ""
    }
  },
  "ookasushihibachisteakhouse" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 779-0099",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/aa9be018aec94d491a00f40429958ff0d9edd975\/original.jpeg",
      "instagramHandle" : "ookasushihibachisteakhouse",
      "twitterHandle" : "",
      "merchantHandle" : "ookasushihibachisteakhouse",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Ooka Sushi & hibachi steak House",
      "city" : "Riverside",
      "companyName" : "Ooka Sushi & hibachi steak House",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.ookasushi.com\/riverside.html",
      "email" : ""
    }
  },
  "cloudcoffeecompany" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(661) 374-8985",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/bbb77576c3feb37874ee4cfab8ef646ddebdd79a\/original.png",
      "instagramHandle" : "cloudcoffeecompany",
      "merchantHandle" : "cloudcoffeecompany",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Cloud 9 Coffee Company",
      "city" : "Bakersfield",
      "companyName" : "Cloud 9 Coffee Company",
      "websiteUrl" : "http:\/\/cloud9coffeeco.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "nibblechocolate" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(858) 200-7689",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/01af4bd144bb8346362746cc7cf2eb64eeb8f86b\/original.png",
      "instagramHandle" : "nibblechocolate",
      "merchantHandle" : "nibblechocolate",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Nibble Chocolate",
      "city" : "Chula Vista",
      "companyName" : "Nibble Chocolate",
      "websiteUrl" : "http:\/\/nibblechocolate.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "renes" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(951) 242-2914",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/PloGKNXoKkia7gTtHVDqDbUWUHi4niIh.png",
      "instagramHandle" : "renes",
      "premium" : false,
      "merchantHandle" : "renes",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Rene's",
      "city" : "Moreno Valley",
      "companyName" : "Rene's",
      "websiteUrl" : "http:\/\/renes.local-cafes.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "donutskingbakery" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 573-9872",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "donutskingbakery",
      "merchantHandle" : "donutskingbakery",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Donuts King bakery",
      "city" : "Santa Ana",
      "companyName" : "Donuts King bakery",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/orderdonutskingbakery.com\/",
      "email" : ""
    }
  },
  "michaelschocolates" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 817-4777",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/eXAGzYmEprU1XYVNkHKaUpvDCztPicwy.png",
      "instagramHandle" : "michaelschocolates",
      "twitterHandle" : "",
      "merchantHandle" : "michaelschocolates",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Michael's Chocolates",
      "city" : "Oakland",
      "companyName" : "Michael's Chocolates",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/michaelschocolates.com\/",
      "email" : ""
    }
  },
  "ruckustacocompanycanton" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(734) 359-3156",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "ruckustacocompanycanton",
      "merchantHandle" : "ruckustacocompanycanton",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Ruckus Taco Company Canton",
      "city" : "Sterling Heights",
      "companyName" : "Ruckus Taco Company Canton",
      "websiteUrl" : "http:\/\/www.ruckustaco.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "desipdx" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(503) 862-3374",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/jVmBWs9OwKVsSkOklszx51ynFnpNGOrX.png",
      "instagramHandle" : "desipdx",
      "merchantHandle" : "desipdx",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "DesiPDX",
      "city" : "Vancouver",
      "companyName" : "DesiPDX",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.desipdx.com\/",
      "email" : ""
    }
  },
  "dessertoasiscoffeeroastersrochester" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(248) 453-5239",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/pSIE8ASTCwWzpbpAe6GpiY0kQhgUVjRZ.png",
      "instagramHandle" : "dessertoasiscoffeeroastersrochester",
      "twitterHandle" : "",
      "merchantHandle" : "dessertoasiscoffeeroastersrochester",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Dessert Oasis Coffee Roasters Rochester",
      "city" : "Sterling Heights",
      "companyName" : "Dessert Oasis Coffee Roasters Rochester",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.docr.coffee\/",
      "email" : ""
    }
  },
  "heritagebarkitchen" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(509) 863-9235",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/d4rpnQFCh9YiFh5BsCrZ6Hk2ZFqmCgRb.png",
      "instagramHandle" : "heritagebarkitchen",
      "merchantHandle" : "heritagebarkitchen",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Heritage Bar & Kitchen",
      "city" : "Spokane",
      "companyName" : "Heritage Bar & Kitchen",
      "websiteUrl" : "https:\/\/www.heritagebarandkitchen.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "onediamondmarketing" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(248) 987-6700",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/e3oVRpFuUz3s48aL2ITOL2uCwHD5WipC.png",
      "instagramHandle" : "onediamondmarketing",
      "merchantHandle" : "onediamondmarketing",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "One Diamond Marketing",
      "city" : "Grand Rapids",
      "companyName" : "One Diamond Marketing",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.onediamondmarketing.com\/",
      "email" : ""
    }
  },
  "bonappetit" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/zZklesjWxX3uGjwu0uO8saXALYI8CKLi.png",
      "instagramHandle" : "bonappetit",
      "premium" : false,
      "merchantHandle" : "bonappetit",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Bon Appetit",
      "city" : "Irvine",
      "companyName" : "Bon Appetit",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.cui.edu\/studentlife\/food-services\/index\/id\/24298",
      "email" : ""
    }
  },
  "taquizasloschuchysoffice" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(619) 649-1997",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/YfIwDOXrk4NqaFnSz0rI2cVMVAd3YxA9.png",
      "instagramHandle" : "taquizasloschuchysoffice",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "taquizasloschuchysoffice",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Taquizas Los Chuchys- OFFICE",
      "city" : "Chula Vista",
      "companyName" : "Taquizas Los Chuchys- OFFICE",
      "websiteUrl" : "http:\/\/taquizasloschuchys.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "betterthanmamas" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(503) 302-0063",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/c20cdfe921447cb4a488ccb4e1c72d4c23e57a15\/original.png",
      "instagramHandle" : "betterthanmamas",
      "merchantHandle" : "betterthanmamas",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Better Than Mama's",
      "city" : "Salem",
      "companyName" : "Better Than Mama's",
      "websiteUrl" : "http:\/\/www.btmgourmet.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "comparebusinesssystemsinc" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "",
      "phone" : "",
      "merchantImage" : "comparebusinesssystemsinc",
      "instagramHandle" : " Inc.\"",
      "merchantHandle" : "comparebusinesssystemsinc",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "(714) 985-0209",
      "squareSandboxKey" : "",
      "merchantName" : "https:\/\/square-web-production-f.squarecdn.com\/files\/74153ca339b95cc54541ecfe49c36726283468fe\/original.png",
      "city" : "",
      "companyName" : "\"Compare Business Systems",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : "https:\/\/comparebusiness.net\/"
    }
  },
  "sipspoon" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(253) 475-5118",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/eeZ9X1uHklYJ4ZYy4wy1GVF3oTnKaJbI.png",
      "instagramHandle" : "sipspoon",
      "merchantHandle" : "sipspoon",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sip + Spoon",
      "city" : "Tacoma",
      "companyName" : "Sip + Spoon",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "isaacsdowntown" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(503) 551-3939",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "isaacsdowntown",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "isaacsdowntown",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Isaac's Downtown",
      "city" : "Salem",
      "companyName" : "Isaac's Downtown",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.isaacsdowntown.com\/",
      "email" : ""
    }
  },
  "naanncurry" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(360) 719-2749",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/1e8262b3d3e7f4a9211417496df3d2752c2cd58f\/original.jpeg",
      "instagramHandle" : "naanncurry",
      "merchantHandle" : "naanncurry",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Naan N Curry",
      "city" : "Vancouver",
      "companyName" : "Naan N Curry",
      "websiteUrl" : "http:\/\/www.realnaanncurry.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sweetosaur" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/yCyWGv5Rb3XBx4o3N2KBO9saUBemqfV3.png",
      "instagramHandle" : "sweetosaur",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "sweetosaur",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sweetosaur",
      "city" : "San Bernardino",
      "companyName" : "Sweetosaur",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.sweetosaur.com\/",
      "email" : ""
    }
  },
  "smarthospitalityllc" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(559) 636-1710",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/eca3062f5799b755bfa5966ce01df78d23aa4bbb\/original.jpeg",
      "instagramHandle" : "smarthospitalityllc",
      "twitterHandle" : "",
      "merchantHandle" : "smarthospitalityllc",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Smart Hospitality LLC",
      "city" : "Fresno",
      "companyName" : "Smart Hospitality LLC",
      "websiteUrl" : "http:\/\/www.smarthospitality.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thegreenbean" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(810) 232-4400",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/qGmmVH7EsJSR5Smw0VuZ7GiR9xuO31K7.png",
      "instagramHandle" : "thegreenbean",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "thegreenbean",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Green Bean",
      "city" : "Flint",
      "companyName" : "The Green Bean",
      "websiteUrl" : "http:\/\/greenbeanmichigan.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "nadogelatocafe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(619) 522-9053",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/1219246dbd483720a836502258d09351\/original.jpeg",
      "instagramHandle" : "nadogelatocafe",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "nadogelatocafe",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Nado Gelato Cafe",
      "city" : "Chula Vista",
      "companyName" : "Nado Gelato Cafe",
      "websiteUrl" : "http:\/\/www.gelato101.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sidepiecekitchen" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(253) 289-1800",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/jmLmOZjT0JNVWap488xJDg7CIVZtPzYV.png",
      "instagramHandle" : "sidepiecekitchen",
      "merchantHandle" : "sidepiecekitchen",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Side Piece Kitchen",
      "city" : "Tacoma",
      "companyName" : "Side Piece Kitchen",
      "websiteUrl" : "http:\/\/www.sidepiecekitchen.com\/home",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "mkitchenbuffet" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 466-2269",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Zwn7AnZUDolV6x5hMyOsitRAN9by1pTg.png",
      "instagramHandle" : "mkitchenbuffet",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "mkitchenbuffet",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "M kitchen Buffet",
      "city" : "Stockton",
      "companyName" : "M kitchen Buffet",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "elbajio" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(559) 277-3644",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/gsI9Ae3hpR3syZVACMpgxzjLM3eG9Ksv.png",
      "instagramHandle" : "elbajio",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "elbajio",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "El Bajio",
      "city" : "Fresno",
      "companyName" : "El Bajio",
      "websiteUrl" : "https:\/\/www.elbajioca.co\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "risegrindcoffee" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/DOTGddIWP0aOEYVo83UBbd3PjlBP0dgg.png",
      "instagramHandle" : "risegrindcoffee",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "risegrindcoffee",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Rise & Grind Coffee",
      "city" : "Oakland",
      "companyName" : "Rise & Grind Coffee",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.riseandgrind-sf.com\/",
      "email" : ""
    }
  },
  "acornbrewing" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(253) 517-8899",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/107c3d3bff7e4c1bbad645159a652d1c83af8388\/original.jpeg",
      "instagramHandle" : "acornbrewing",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "acornbrewing",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Acorn Brewing",
      "city" : "Tacoma",
      "companyName" : "Acorn Brewing",
      "websiteUrl" : "http:\/\/www.acornbeer.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "kulfi" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/p3YNMmHJq061af07yLcBoxt6QSnYEeIf.png",
      "instagramHandle" : "kulfi",
      "premium" : false,
      "merchantHandle" : "kulfi",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Kulfi",
      "city" : "Vancouver",
      "companyName" : "Kulfi",
      "websiteUrl" : "http:\/\/kulfipdx.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "fabulousacres" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/6MvCow0aZTGGyiPMaNRPuD6oYS1mCfu9.png",
      "instagramHandle" : "fabulousacres",
      "merchantHandle" : "fabulousacres",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Fabulous Acres",
      "city" : "Lansing",
      "companyName" : "Fabulous Acres",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "pranajuices" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(248) 629-4092",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/dfa1f920de1023abe7a6e0fdb244b72d7e17ae98\/original.jpeg",
      "instagramHandle" : "pranajuices",
      "merchantHandle" : "pranajuices",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Prana Juices",
      "city" : "Warren",
      "companyName" : "Prana Juices",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.mypranajuices.com\/",
      "email" : ""
    }
  },
  "squidlys" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 834-3050",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/F4KAdnmZ5sWrmazLsCtMCw7OjZu3uoaC.png",
      "instagramHandle" : "squidlys",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "squidlys",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Squidly’s",
      "city" : "Oxnard",
      "companyName" : "Squidly’s",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/squidlys.square.site\/",
      "email" : ""
    }
  },
  "khamsarestaurant" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 316-9458",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/b918d298de11322ae0a6743c6793c47f59a0d7d0\/original.jpeg",
      "instagramHandle" : "khamsarestaurant",
      "merchantHandle" : "khamsarestaurant",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Khamsa Restaurant",
      "city" : "San Francisco",
      "companyName" : "Khamsa Restaurant",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.khamsasf.com\/",
      "email" : ""
    }
  },
  "folkspizzeria" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 617-4888",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/11d3a9f81fcce6cde905feea127845f090c7523d\/original.jpeg",
      "instagramHandle" : "folkspizzeria",
      "twitterHandle" : "",
      "merchantHandle" : "folkspizzeria",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Folks Pizzeria",
      "city" : "Santa Ana",
      "companyName" : "Folks Pizzeria",
      "websiteUrl" : "https:\/\/folkspizzeria.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "texturesbynefertiti" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(313) 831-4771",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/DlxzezC2js3SwurPZ1vtLmABpTafj40r.jpeg",
      "instagramHandle" : "texturesbynefertiti",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "texturesbynefertiti",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Textures By Nefertiti",
      "city" : "Detroit",
      "companyName" : "Textures By Nefertiti",
      "websiteUrl" : "http:\/\/www.texturesbynef.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "saludjuicebar" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(562) 528-8444",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/b27c4add569e43dbcf68bd8247ab69ddbf763f9d\/original.png",
      "instagramHandle" : "saludjuicebar",
      "merchantHandle" : "saludjuicebar",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Salud Juice Bar",
      "city" : "Long Beach",
      "companyName" : "Salud Juice Bar",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.saludjuice.com\/",
      "email" : ""
    }
  },
  "pennys" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/MULZFjbEk4VqUSa0grL0wd4UGP3qcqsn.png",
      "instagramHandle" : "pennys",
      "merchantHandle" : "pennys",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Penny's",
      "city" : "Flint",
      "companyName" : "Penny's",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "tacosgalloloco" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Urw9pPq7kq2a9VajWkGYGx0u5uP1eCDH.png",
      "instagramHandle" : "tacosgalloloco",
      "merchantHandle" : "tacosgalloloco",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Tacos Gallo Loco",
      "city" : "Long Beach",
      "companyName" : "Tacos Gallo Loco",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "greatkhansmongoliangrillsanbernardino" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 381-9119",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ZSxkj9gKrRvcRZSAR7vdXd3m3DfDyXdq.png",
      "instagramHandle" : "greatkhansmongoliangrillsanbernardino",
      "merchantHandle" : "greatkhansmongoliangrillsanbernardino",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Great Khan's Mongolian Grill San Bernardino",
      "city" : "San Bernardino",
      "companyName" : "Great Khan's Mongolian Grill San Bernardino",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.greatkhans.com\/",
      "email" : ""
    }
  },
  "bkwoodworking" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(734) 812-2202",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/BNhePsjCofznYvUIypy9Rt1bP00OndxG.png",
      "instagramHandle" : "bkwoodworking",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "bkwoodworking",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "BK Woodworking",
      "city" : "Ann Arbor",
      "companyName" : "BK Woodworking",
      "websiteUrl" : "http:\/\/www.bkwoodworking.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "iggyseggies" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(313) 986-1174",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/k1iHKrlZCSZQUYNUH6l8D4NApirZlyJi.png",
      "instagramHandle" : "iggyseggies",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "iggyseggies",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Iggy's Eggies",
      "city" : "Detroit",
      "companyName" : "Iggy's Eggies",
      "websiteUrl" : "http:\/\/www.iggyseggies.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "theunbakedcookieco" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(810) 588-9393",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/cCnm6aFAiJ4WyzWrKSWGrJ8ppavFmTy0.png",
      "instagramHandle" : "theunbakedcookieco",
      "merchantHandle" : "theunbakedcookieco",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The UnBaked Cookie Co.",
      "city" : "Flint",
      "companyName" : "The UnBaked Cookie Co.",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/theunbakedcookieco.square.site\/",
      "email" : ""
    }
  },
  "ovengrandmarais" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(519) 915-6900",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/1dJBtPgSgu8Kkya46pUdxCV1D0jIlBW0.png",
      "instagramHandle" : "ovengrandmarais",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "ovengrandmarais",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Oven 360 Grand Marais",
      "city" : "Detroit",
      "companyName" : "Oven 360 Grand Marais",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/oven360.com\/",
      "email" : ""
    }
  },
  "trapkitchen" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(503) 442-0748",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/U4AuoIHfr7N0pEeAvQcWOnDmppnIb8Og.png",
      "instagramHandle" : "trapkitchen",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "trapkitchen",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Trap Kitchen",
      "city" : "Portland",
      "companyName" : "Trap Kitchen",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.trapkitchenpdx.com\/",
      "email" : ""
    }
  },
  "idealenterprisesinc" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(541) 343-6906",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/c718515f331d1780b0506c728a7f80815e994fba\/original.jpeg",
      "instagramHandle" : "idealenterprisesinc",
      "premium" : false,
      "merchantHandle" : "idealenterprisesinc",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Ideal Enterprises Inc",
      "city" : "Eugene",
      "companyName" : "Ideal Enterprises Inc",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "bluestardonuts" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(503) 265-8410",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/0bUwBzUAeausdVC0a5VdzBhNNsa0UyRa.png",
      "instagramHandle" : "bluestardonuts",
      "merchantHandle" : "bluestardonuts",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Blue Star Donuts",
      "city" : "Portland",
      "companyName" : "Blue Star Donuts",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.bluestardonuts.com\/",
      "email" : ""
    }
  },
  "wallercountryfarms" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(253) 331-6067",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "wallercountryfarms",
      "twitterHandle" : "",
      "merchantHandle" : "wallercountryfarms",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Waller Country Farms",
      "city" : "Tacoma",
      "companyName" : "Waller Country Farms",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "sweetlifeboutique" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 932-9415",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/4FdZElRDCMym8H36MpTuba9ofvjTW4Ar.jpeg",
      "instagramHandle" : "sweetlifeboutique",
      "premium" : false,
      "merchantHandle" : "sweetlifeboutique",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Sweet Life Boutique",
      "city" : "Stockton",
      "companyName" : "Sweet Life Boutique",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "dailybread" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(541) 344-3380",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/KDWIqJitb8GQr0qYbFlPThF4ifOGrHsX.png",
      "instagramHandle" : "dailybread",
      "merchantHandle" : "dailybread",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Daily Bread",
      "city" : "Eugene",
      "companyName" : "Daily Bread",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "poseidonbrewingcompany" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(805) 477-0239",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/82131754403bf7b658c03c518637696fd62dd4e6\/original.png",
      "instagramHandle" : "poseidonbrewingcompany",
      "premium" : false,
      "merchantHandle" : "poseidonbrewingcompany",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Poseidon Brewing Company",
      "city" : "Oxnard",
      "companyName" : "Poseidon Brewing Company",
      "websiteUrl" : "http:\/\/www.poseidonbrewingco.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "liliacomedor" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(541) 900-5836",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/q3bsKAeXbvSZYJMshAETTGKiS9DQVwQY.png",
      "instagramHandle" : "liliacomedor",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "liliacomedor",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Lilia Comedor",
      "city" : "Portland",
      "companyName" : "Lilia Comedor",
      "websiteUrl" : "http:\/\/www.liliapdx.com\/?utm_source=google&utm_medium=wix_google_business_profile&utm_campaign=10116908362801027932",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "mariscoslosmochisbakersfield" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(661) 706-8368",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/aSWssJ62Li6XieZ1wcP89g67XQ9wbYtp.png",
      "instagramHandle" : "mariscoslosmochisbakersfield",
      "premium" : false,
      "merchantHandle" : "mariscoslosmochisbakersfield",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Mariscos Los Mochis Bakersfield",
      "city" : "Bakersfield",
      "companyName" : "Mariscos Los Mochis Bakersfield",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "krepedog" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 648-3510",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/82b0175653b5a137536c5966ace9fadfe98f93ae\/original.jpeg",
      "instagramHandle" : "krepedog",
      "merchantHandle" : "krepedog",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Krepe Dog",
      "city" : "Modesto",
      "companyName" : "Krepe Dog",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "elcaprichorestaurant" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 420-9542",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "elcaprichorestaurant",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "elcaprichorestaurant",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "El Capricho Restaurant",
      "city" : "Oxnard",
      "companyName" : "El Capricho Restaurant",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/elcaprichorestaurantandcantina.com\/",
      "email" : ""
    }
  },
  "whiskeytangofoxtrotrestaurantbargrill" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(925) 658-5600",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/zu6myJzw7uftMiIl67XGTgh0ZOv3yY0w.png",
      "instagramHandle" : "whiskeytangofoxtrotrestaurantbargrill",
      "merchantHandle" : "whiskeytangofoxtrotrestaurantbargrill",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Whiskey Tango Foxtrot Restaurant Bar & Grill",
      "city" : "Stockton",
      "companyName" : "Whiskey Tango Foxtrot Restaurant Bar & Grill",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/wtfrestaurantbargrill.com\/?utm_campaign=gmb",
      "email" : ""
    }
  },
  "naughtypinebrewingcompany" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 906-2140",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/LLgsMR5C1fpm7DO2CEnMSqdGqMxjOlSx.png",
      "instagramHandle" : "naughtypinebrewingcompany",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "naughtypinebrewingcompany",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Naughty Pine Brewing Company",
      "city" : "Oxnard",
      "companyName" : "Naughty Pine Brewing Company",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.naughtypinebrewingco.com\/",
      "email" : ""
    }
  },
  "dessertoasiscoffeeroastersdetroit" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(313) 338-3515",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/pSIE8ASTCwWzpbpAe6GpiY0kQhgUVjRZ.png",
      "instagramHandle" : "dessertoasiscoffeeroastersdetroit",
      "premium" : false,
      "merchantHandle" : "dessertoasiscoffeeroastersdetroit",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Dessert Oasis Coffee Roasters Detroit",
      "city" : "Detroit",
      "companyName" : "Dessert Oasis Coffee Roasters Detroit",
      "websiteUrl" : "http:\/\/www.docr.coffee\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "somethingsweetdough" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/aN7gsPPAihvfYrnV1N8TmCPrwQojLypn.png",
      "instagramHandle" : "somethingsweetdough",
      "merchantHandle" : "somethingsweetdough",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Something Sweet Dough",
      "city" : "Sacramento",
      "companyName" : "Something Sweet Dough",
      "websiteUrl" : "http:\/\/www.somethingsweetdough.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "blissallendale" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 986-1152",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/CsmTlICRKhlClwztJsFhlanJh0hhmSla.png",
      "instagramHandle" : "blissallendale",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "blissallendale",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Bliss Allendale",
      "city" : "Grand Rapids",
      "companyName" : "Bliss Allendale",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "plastic" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(734) 536-3231",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/A9Gflr9pf187eOdUkLf9bPEkXlo206Wv.png",
      "instagramHandle" : "plastic",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "plastic",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "86 Plastic",
      "city" : "Warren",
      "companyName" : "86 Plastic",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/86plasticco.com\/",
      "email" : ""
    }
  },
  "ikeslovesandwiches" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 851-3943",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/8lEmvr0aoVdcOKC8DDzTCou1aPjAr00Q.png",
      "instagramHandle" : "ikeslovesandwiches",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "ikeslovesandwiches",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Ike's Love & Sandwiches",
      "city" : "Stockton",
      "companyName" : "Ike's Love & Sandwiches",
      "websiteUrl" : "https:\/\/www.ikessandwich.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "jjpfisterdistillingco" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(916) 672-9662",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/b54fe2e39fbc1048be87194fcf9ec30a6c7ef0fc\/original.jpeg",
      "instagramHandle" : "jjpfisterdistillingco",
      "premium" : false,
      "merchantHandle" : "jjpfisterdistillingco",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "J.J. Pfister Distilling Co",
      "city" : "Sacramento",
      "companyName" : "J.J. Pfister Distilling Co",
      "websiteUrl" : "http:\/\/www.jjpfister.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "southlyontheaterakathelyontheater" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(734) 347-2555",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Iru4nZ7N2dLwWK8xPTbE9b3X9Jb1VV5M.png",
      "instagramHandle" : "southlyontheaterakathelyontheater",
      "premium" : false,
      "merchantHandle" : "southlyontheaterakathelyontheater",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "South Lyon Theater Aka The Lyon Theater",
      "city" : "Ann Arbor",
      "companyName" : "South Lyon Theater Aka The Lyon Theater",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.thelyon.com\/",
      "email" : ""
    }
  },
  "cafecrepesdeparis" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(661) 374-4946",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/38dVwa06iQCQ5iUIwQt8YfcD2kFFOtXh.png",
      "instagramHandle" : "cafecrepesdeparis",
      "merchantHandle" : "cafecrepesdeparis",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Cafe Crepes de Paris",
      "city" : "Bakersfield",
      "companyName" : "Cafe Crepes de Paris",
      "websiteUrl" : "https:\/\/cafecrepesdeparis.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thankyouforcoming" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(323) 648-2666",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/60ZFtOGa70T0ahQJrOhJyH2iVzM50Hpy.png",
      "instagramHandle" : "thankyouforcoming",
      "twitterHandle" : "",
      "merchantHandle" : "thankyouforcoming",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Thank You for Coming",
      "city" : "Glendale",
      "companyName" : "Thank You for Coming",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "guisados" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(562) 494-5292",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/3hHBYCRLaPF38PeX0PN1Ha0LVNMsVjS7.png",
      "instagramHandle" : "guisados",
      "twitterHandle" : "",
      "merchantHandle" : "guisados",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Guisados",
      "city" : "Long Beach",
      "companyName" : "Guisados",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "mmmcakes" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(619) 500-5162",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ylli4DMnkR4LJiRcC30bAefw7rsG62sg.png",
      "instagramHandle" : "mmmcakes",
      "merchantHandle" : "mmmcakes",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Mmm...Cakes",
      "city" : "Chula Vista",
      "companyName" : "Mmm...Cakes",
      "websiteUrl" : "http:\/\/www.mmmcakessd.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sweetboxmacaronsdragonbeardcandy" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "",
      "phone" : "",
      "merchantImage" : "sweetboxmacaronsdragonbeardcandy",
      "instagramHandle" : " Dragon Beard Candy)\"",
      "merchantHandle" : "sweetboxmacaronsdragonbeardcandy",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "(714) 713-9033",
      "squareSandboxKey" : "",
      "merchantName" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/kmXpVrfBV1ZgcT330aMoQ4vtdfsLlJo6.png",
      "city" : "",
      "companyName" : "\"Sweet Box (Macarons",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : "https:\/\/www.instagram.com\/sweetboxoc\/"
    }
  },
  "kellysdowntown" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(517) 708-2007",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "kellysdowntown",
      "merchantHandle" : "kellysdowntown",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Kelly's Downtown",
      "city" : "Lansing",
      "companyName" : "Kelly's Downtown",
      "websiteUrl" : "http:\/\/www.kellys-downtown.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "ritasitalianicefrozencustard" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(586) 488-3764",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/JEh5MXCefs2by4AZAfq88atMAlEUwnPG.png",
      "instagramHandle" : "ritasitalianicefrozencustard",
      "merchantHandle" : "ritasitalianicefrozencustard",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Rita's Italian Ice & Frozen Custard",
      "city" : "Warren",
      "companyName" : "Rita's Italian Ice & Frozen Custard",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.ritasice.com\/location\/ritas-of-sterling-heights-mi\/",
      "email" : ""
    }
  },
  "theartofbbqcatering" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(408) 545-8552",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/aRoaoLdc0KIQmXKWErv01hX4ar5bNnTE.png",
      "instagramHandle" : "theartofbbqcatering",
      "premium" : false,
      "merchantHandle" : "theartofbbqcatering",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The ART of BBQ- Catering",
      "city" : "San Jose",
      "companyName" : "The ART of BBQ- Catering",
      "websiteUrl" : "http:\/\/www.taobbq.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "methodjuicecafedowntown" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(509) 473-9579",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "methodjuicecafedowntown",
      "premium" : false,
      "merchantHandle" : "methodjuicecafedowntown",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Method Juice Cafe Downtown",
      "city" : "Spokane",
      "companyName" : "Method Juice Cafe Downtown",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.methodjuicecafe.com\/",
      "email" : ""
    }
  },
  "thecaribbeanbitestudiopark" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(616) 516-9284",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/d853cdfb95f55cac8977e537581d93894a305273\/original.jpeg",
      "instagramHandle" : "thecaribbeanbitestudiopark",
      "premium" : false,
      "merchantHandle" : "thecaribbeanbitestudiopark",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Caribbean Bite (Studio Park)",
      "city" : "Grand Rapids",
      "companyName" : "The Caribbean Bite (Studio Park)",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/order.toasttab.com\/online\/caribbeanbite",
      "email" : ""
    }
  },
  "pawstries" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(313) 649-7725",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/6nKiASJyp2CCuG42xmqNagmOKika1IEa.png",
      "instagramHandle" : "pawstries",
      "twitterHandle" : "",
      "merchantHandle" : "pawstries",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Pawstries",
      "city" : "Detroit",
      "companyName" : "Pawstries",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.pawstries.com\/",
      "email" : ""
    }
  },
  "helpinghandspantrywarehousenoservicestopublicatthislocation" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 796-4222",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/lkppD8lYfal6NnmD5ipOQ8TNr0r54QmL.png",
      "instagramHandle" : "helpinghandspantrywarehousenoservicestopublicatthislocation",
      "premium" : false,
      "merchantHandle" : "helpinghandspantrywarehousenoservicestopublicatthislocation",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Helping Hands Pantry Warehouse (No Services to the Public at this location)",
      "city" : "San Bernardino",
      "companyName" : "Helping Hands Pantry Warehouse (No Services to the Public at this location)",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/helpinghandspantry.org\/",
      "email" : ""
    }
  },
  "ojaiicecream" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 646-6075",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ldm9wolFBdgHYtRlKD5GWoGFuKR5eRJ3.png",
      "instagramHandle" : "ojaiicecream",
      "merchantHandle" : "ojaiicecream",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Ojai Ice Cream",
      "city" : "Oxnard",
      "companyName" : "Ojai Ice Cream",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.ojaiicecream.com\/",
      "email" : ""
    }
  },
  "wallyscafeemeryville" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(510) 597-1303",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/QlnihTtv5maHemjopozNulYFdeVTo7Ut.png",
      "instagramHandle" : "wallyscafeemeryville",
      "merchantHandle" : "wallyscafeemeryville",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Wally's Cafe (Emeryville)",
      "city" : "Oakland",
      "companyName" : "Wally's Cafe (Emeryville)",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.wallys.cafe\/",
      "email" : ""
    }
  },
  "jetblackcoffeecompany" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(503) 889-0739",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/951bad86fa07d5b0ad55a92513de769dc9ffb5c3\/original.jpeg",
      "instagramHandle" : "jetblackcoffeecompany",
      "twitterHandle" : "",
      "merchantHandle" : "jetblackcoffeecompany",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Jet Black Coffee Company",
      "city" : "Vancouver",
      "companyName" : "Jet Black Coffee Company",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.jetblackcoffeecompany.com\/",
      "email" : ""
    }
  },
  "villagebreadbreadpastries" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 795-5949",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/8Fd6f74GYbAM1IPE0V9dWOTqltAuk3Rs.png",
      "instagramHandle" : "villagebreadbreadpastries",
      "premium" : false,
      "merchantHandle" : "villagebreadbreadpastries",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Village Bread - Bread & Pastries",
      "city" : "San Bernardino",
      "companyName" : "Village Bread - Bread & Pastries",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.villagebreadcalimesa.com\/",
      "email" : ""
    }
  },
  "janethebakery" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 658-7971",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/qp2vxGHD1ItmoZwE3DYajlU5SSJdHOaZ.png",
      "instagramHandle" : "janethebakery",
      "merchantHandle" : "janethebakery",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Jane The Bakery",
      "city" : "San Francisco",
      "companyName" : "Jane The Bakery",
      "websiteUrl" : "https:\/\/www.itsjane.com\/location\/jane-the-bakery\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "regularsizedwayne" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 394-6206",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/SYAA6LyKgF3gob3Z2gYqmDaLw6kWVv1W.png",
      "instagramHandle" : "regularsizedwayne",
      "merchantHandle" : "regularsizedwayne",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Regular Sized Wayne",
      "city" : "Moreno Valley",
      "companyName" : "Regular Sized Wayne",
      "websiteUrl" : "https:\/\/regular-sized-wayne.ueniweb.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "bewelllifestylecentersandcafebirmingham" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(248) 792-6570",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/DS5apSfzBsyaf8Ea3CvROjuCoHeowbWo.png",
      "instagramHandle" : "bewelllifestylecentersandcafebirmingham",
      "merchantHandle" : "bewelllifestylecentersandcafebirmingham",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Be Well LifeStyle Centers and Cafe' (Birmingham)",
      "city" : "Warren",
      "companyName" : "Be Well LifeStyle Centers and Cafe' (Birmingham)",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/bewelllifestylecenters.com\/",
      "email" : ""
    }
  },
  "sweetielicious" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(517) 669-9300",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/bTJHHP2H4Igs2LrgwPvbJfA5Zp5jVrb4.png",
      "instagramHandle" : "sweetielicious",
      "twitterHandle" : "",
      "merchantHandle" : "sweetielicious",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sweetie-licious",
      "city" : "Lansing",
      "companyName" : "Sweetie-licious",
      "websiteUrl" : "http:\/\/www.sweetie-licious.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "heysugar" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(888) 679-3387",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/4qvkRdJ0NKtwarvWwTESpTN0dM5oYd06.png",
      "instagramHandle" : "heysugar",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "heysugar",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Hey Sugar",
      "city" : "Chula Vista",
      "companyName" : "Hey Sugar",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/heysugarsweets.com\/",
      "email" : ""
    }
  },
  "subvegangardengrove" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 591-5672",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/cc282ef7f71976df7d93ecfcf0892f9d66b2ee79\/original.png",
      "instagramHandle" : "subvegangardengrove",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "subvegangardengrove",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Subvegan - Garden Grove",
      "city" : "Anaheim",
      "companyName" : "Subvegan - Garden Grove",
      "websiteUrl" : "http:\/\/subvegan.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "hawaiianislandgrill" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(541) 246-8293",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/5c51136b464066d3c0f140019fb3f6f322594b42\/original.png",
      "instagramHandle" : "hawaiianislandgrill",
      "merchantHandle" : "hawaiianislandgrill",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Hawaiian Island Grill",
      "city" : "Eugene",
      "companyName" : "Hawaiian Island Grill",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/hawaiianislandgrill.com\/",
      "email" : ""
    }
  },
  "angiesbakerymexicanbread" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 883-3242",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "angiesbakerymexicanbread",
      "merchantHandle" : "angiesbakerymexicanbread",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Angie's Bakery & Mexican Bread",
      "city" : "San Bernardino",
      "companyName" : "Angie's Bakery & Mexican Bread",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "frynot" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 577-8087",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/cd02f68c45b643238ed63eaabbc90523c33fa9eb\/original.jpeg",
      "instagramHandle" : "frynot",
      "premium" : false,
      "merchantHandle" : "frynot",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "FryNot?",
      "city" : "Riverside",
      "companyName" : "FryNot?",
      "websiteUrl" : "https:\/\/frynot.square.site\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thatsmyjam" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 349-0595",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/QwRh8Ru7YzvTduLh0BI1SyVS5PsVmLVV.png",
      "instagramHandle" : "thatsmyjam",
      "merchantHandle" : "thatsmyjam",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "That's My Jam",
      "city" : "San Francisco",
      "companyName" : "That's My Jam",
      "websiteUrl" : "http:\/\/thatsmyjamsf.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "lianglaw" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/yib1ncjN9TWhPHGVWGXqgBBnowEi0a08.png",
      "instagramHandle" : "lianglaw",
      "premium" : false,
      "merchantHandle" : "lianglaw",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Liang Law",
      "city" : "San Jose",
      "companyName" : "Liang Law",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/lianglaw.us\/",
      "email" : ""
    }
  },
  "lucapastry" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(734) 929-4749",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "lucapastry",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "lucapastry",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Luca Pastry",
      "city" : "Ann Arbor",
      "companyName" : "Luca Pastry",
      "websiteUrl" : "https:\/\/luca-pastry-ann-arbor.square.site\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "whisperscafeandcreperie" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(408) 297-2850",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/694407933cc4d4a9bab38f3d244dfdc95987cf03\/original.jpeg",
      "instagramHandle" : "whisperscafeandcreperie",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "whisperscafeandcreperie",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Whispers Cafe and Creperie",
      "city" : "San Jose",
      "companyName" : "Whispers Cafe and Creperie",
      "websiteUrl" : "http:\/\/whisperscafe.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sweetdreamsbakery" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(586) 751-8900",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/7976d742c984e677a8572707643f0e5d8a6e1bce\/original.jpeg",
      "instagramHandle" : "sweetdreamsbakery",
      "merchantHandle" : "sweetdreamsbakery",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sweet Dreams Bakery",
      "city" : "Warren",
      "companyName" : "Sweet Dreams Bakery",
      "websiteUrl" : "http:\/\/sweetdreamsweb.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "empressreadycrystalsnaturalhealing" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/c69d333c8eae476f72ac4498e938fe90879e8d3b\/original.jpeg",
      "instagramHandle" : "empressreadycrystalsnaturalhealing",
      "premium" : false,
      "merchantHandle" : "empressreadycrystalsnaturalhealing",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Empress Ready - Crystals & Natural Healing",
      "city" : "Sacramento",
      "companyName" : "Empress Ready - Crystals & Natural Healing",
      "websiteUrl" : "https:\/\/www.empressready.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "pickledbarrelbakery" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(810) 937-6100",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/bccb4c0dafac3d3c74e8cd6f18d331e2cf258fda\/original.png",
      "instagramHandle" : "pickledbarrelbakery",
      "twitterHandle" : "",
      "merchantHandle" : "pickledbarrelbakery",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Pickled Barrel Bakery",
      "city" : "Flint",
      "companyName" : "Pickled Barrel Bakery",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "anoushcateringatglenoaksballroom" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(818) 306-9818",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/yAC4UZ7e55sKfJCnNl5PN5ZPFVeOhA3U.png",
      "instagramHandle" : "anoushcateringatglenoaksballroom",
      "merchantHandle" : "anoushcateringatglenoaksballroom",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Anoush Catering at Glenoaks Ballroom",
      "city" : "Glendale",
      "companyName" : "Anoush Catering at Glenoaks Ballroom",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.anoush.com\/banquet-halls\/glenoaks-ballroom?utm_source=Google+My+Business&utm_medium=Google+My+Business&utm_campaign=Anoush+Glenoaks",
      "email" : ""
    }
  },
  "donutscoffey" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(279) 345-0691",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/40e8a4fc67231f92e659f12c03849751bbd90966\/original.png",
      "instagramHandle" : "donutscoffey",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "donutscoffey",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Donuts & Coffey",
      "city" : "Sacramento",
      "companyName" : "Donuts & Coffey",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.donutscoffey.com\/",
      "email" : ""
    }
  },
  "amandaskitchencatering" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(734) 332-8981",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/GNxB1BUpSZBz0J0KxLmrh0XzDod1uf3y.png",
      "instagramHandle" : "amandaskitchencatering",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "amandaskitchencatering",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Amanda's Kitchen Catering",
      "city" : "Ann Arbor",
      "companyName" : "Amanda's Kitchen Catering",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.amandaskitchencatering.com\/",
      "email" : ""
    }
  },
  "flourmarket" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(503) 209-5363",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/32XhNimCZ1idAKBSh7jMulitap73bBb4.png",
      "instagramHandle" : "flourmarket",
      "merchantHandle" : "flourmarket",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Flour Market",
      "city" : "Vancouver",
      "companyName" : "Flour Market",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/flourmarketpdx.com\/",
      "email" : ""
    }
  },
  "zuzuspetals" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(562) 283-3109",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/O4Q4Jm2Yr4xDW8QaeDqigrFjNTqp9dwA.png",
      "instagramHandle" : "zuzuspetals",
      "premium" : false,
      "merchantHandle" : "zuzuspetals",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Zuzu's Petals",
      "city" : "Long Beach",
      "companyName" : "Zuzu's Petals",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.zpeatery.com\/",
      "email" : ""
    }
  },
  "bottlebaybrewingco" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(509) 960-8049",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/WTBvUEZBD8NX2rKzhnsBPJtkOR4V7DVF.png",
      "instagramHandle" : "bottlebaybrewingco",
      "twitterHandle" : "",
      "merchantHandle" : "bottlebaybrewingco",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Bottle Bay Brewing Co",
      "city" : "Spokane",
      "companyName" : "Bottle Bay Brewing Co",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "northgategonzalezmarket" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 888-3414",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "northgategonzalezmarket",
      "twitterHandle" : "",
      "merchantHandle" : "northgategonzalezmarket",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Northgate Gonzalez Market",
      "city" : "Riverside",
      "companyName" : "Northgate Gonzalez Market",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.northgatemarket.com\/",
      "email" : ""
    }
  },
  "laothaicocomilkcafe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 996-1204",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/AbjG7Pn3FGGWaA5UIaMc07R940XwN1xF.png",
      "instagramHandle" : "laothaicocomilkcafe",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "laothaicocomilkcafe",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Lao \/ Thai Cocomilk Cafe",
      "city" : "Sacramento",
      "companyName" : "Lao \/ Thai Cocomilk Cafe",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.cocomilkcafe.com\/",
      "email" : ""
    }
  },
  "tuscancafe" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(248) 305-8629",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/7aee1479024f417cc9d52360f7d4ab68abcb7f69\/original.jpeg",
      "instagramHandle" : "tuscancafe",
      "merchantHandle" : "tuscancafe",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Tuscan Cafe",
      "city" : "Ann Arbor",
      "companyName" : "Tuscan Cafe",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.thetuscancafe.com\/",
      "email" : ""
    }
  },
  "frencherytheworldsbestculinaryexperience" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(408) 538-0457",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/balxIIvIwLQHiaYHPETnOtq0KtJogvit.png",
      "instagramHandle" : "frencherytheworldsbestculinaryexperience",
      "premium" : false,
      "merchantHandle" : "frencherytheworldsbestculinaryexperience",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "FRENCHERY - The World's Best Culinary Experience",
      "city" : "San Jose",
      "companyName" : "FRENCHERY - The World's Best Culinary Experience",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/frenchery.com\/",
      "email" : ""
    }
  },
  "matko" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 757-0171",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/k0qVLGj8liibTn03GVhHsGVoHxrJBAJR.png",
      "instagramHandle" : "matko",
      "premium" : false,
      "merchantHandle" : "matko",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Matko",
      "city" : "San Francisco",
      "companyName" : "Matko",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.ordermatkotogo.com\/",
      "email" : ""
    }
  },
  "pizzeriadelfinamission" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 437-6800",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/43c74fec4113e6c1c0848da1df184c0cb4eecf16\/original.png",
      "instagramHandle" : "pizzeriadelfinamission",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "pizzeriadelfinamission",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Pizzeria Delfina - Mission",
      "city" : "San Francisco",
      "companyName" : "Pizzeria Delfina - Mission",
      "websiteUrl" : "https:\/\/www.pizzeriadelfina.com\/location\/mission-district\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "subdivision" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(509) 328-6727",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/WL6dGdtv8A45tI0XxDSr0J9JGLT1WXOF.png",
      "instagramHandle" : "subdivision",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "subdivision",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sub-Division",
      "city" : "Spokane",
      "companyName" : "Sub-Division",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.subdivisionsubs.com\/",
      "email" : ""
    }
  },
  "islandsmoothiesandfruitbowls" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(562) 432-9900",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/550966c4d44758598477bd2c5dbc0dbb1ce287da\/original.jpeg",
      "instagramHandle" : "islandsmoothiesandfruitbowls",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "islandsmoothiesandfruitbowls",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Island smoothies and fruit bowls",
      "city" : "Long Beach",
      "companyName" : "Island smoothies and fruit bowls",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/islandsmoothiesandfruit.com\/",
      "email" : ""
    }
  },
  "rye" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(541) 653-8509",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/5f8dfec103b3f8dffea36e14b55a67440b82b7e1\/original.jpeg",
      "instagramHandle" : "rye",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "rye",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "rye",
      "city" : "Eugene",
      "companyName" : "rye",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.ryeon3rd.com\/",
      "email" : ""
    }
  },
  "halyamashitanapa" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(480) 749-3030",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/GbeyqjRzauKviZiuaMyW64v0hGrWqUaJ.png",
      "instagramHandle" : "halyamashitanapa",
      "premium" : false,
      "merchantHandle" : "halyamashitanapa",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "HAL YAMASHITA Napa",
      "city" : "Santa Ana",
      "companyName" : "HAL YAMASHITA Napa",
      "websiteUrl" : "http:\/\/www.halnapa.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "monstermacwingsdowntown" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(503) 853-6834",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/0CtUwVXTqIJbGzRSpP2nZJOtCeUcDAKG.png",
      "instagramHandle" : "monstermacwingsdowntown",
      "merchantHandle" : "monstermacwingsdowntown",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Monster Mac & Wings - Downtown",
      "city" : "Portland",
      "companyName" : "Monster Mac & Wings - Downtown",
      "websiteUrl" : "https:\/\/order.tryotter.com\/s\/monster-mac-and-wings\/426-southwest-3rd-avenue-portland\/b9f41abc-4d36-4b43-8f39-c4ff857d4f2b",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thecommoncup" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(734) 327-6914",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/cdbc5c7e085d047669262af3d218a567146ddbd6\/original.png",
      "instagramHandle" : "thecommoncup",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "thecommoncup",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Common Cup",
      "city" : "Ann Arbor",
      "companyName" : "The Common Cup",
      "websiteUrl" : "http:\/\/www.commoncupcoffee.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "oaklandunitedbeerworks" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 251-8898",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/bccc48f3e73272d359a3379a827a7682f0147ac0\/original.png",
      "instagramHandle" : "oaklandunitedbeerworks",
      "merchantHandle" : "oaklandunitedbeerworks",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Oakland United Beerworks",
      "city" : "Oakland",
      "companyName" : "Oakland United Beerworks",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/oaklandunitedbeerworks.com\/",
      "email" : ""
    }
  },
  "mezaphotography" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(916) 335-7603",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "mezaphotography",
      "merchantHandle" : "mezaphotography",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Meza photography",
      "city" : "Sacramento",
      "companyName" : "Meza photography",
      "websiteUrl" : "http:\/\/www.mezaphotography.net\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "americanabistrobyguytonshc" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(517) 983-0700",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/27c8a6a18ceaf01d2f52559d3a75738c28356e53\/original.png",
      "instagramHandle" : "americanabistrobyguytonshc",
      "merchantHandle" : "americanabistrobyguytonshc",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Americana Bistro by Guyton's HC",
      "city" : "Lansing",
      "companyName" : "Americana Bistro by Guyton's HC",
      "websiteUrl" : "https:\/\/guytons-hc.square.site\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "undefined" : {
    "analytics" : {
      "records" : {
        "promo-redeem-complete" : {
          "p2gs2rXS" : {
            "user_agent" : "Mozilla\/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/126.0.0.0 Safari\/537.36",
            "redemption_object" : {
              "redeemguid" : "bTDE74JR",
              "merchant_handle" : "maltesecafe",
              "promo_id" : "maltesecafe-d5opi",
              "visit_date" : "Next Friday",
              "environment" : "production",
              "visit_time" : "1:00 PM",
              "merchantName" : "maltesecafe",
              "customer_group_id" : 1222,
              "phone_number" : "+13875445445"
            },
            "merchant_handle" : "maltesecafe",
            "time" : "2024-06-26T20:32:39.064Z",
            "timezone" : "Asia\/Tokyo",
            "host" : "cafe.bio",
            "page" : "promo-redeem-complete\/",
            "path_name" : "\/r\/maltesecafe\/maltesecafe-d5opi"
          },
          "iY2fPfNu" : {
            "user_agent" : "Mozilla\/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/126.0.0.0 Safari\/537.36",
            "redemption_object" : {
              "redeemguid" : "sy2VBUxJ",
              "merchant_handle" : "maltesecafe",
              "promo_id" : "maltesecafe-d5opi",
              "visit_date" : "Next Friday",
              "environment" : "production",
              "visit_time" : "1:00 PM",
              "merchantName" : "maltesecafe",
              "customer_group_id" : 1222,
              "phone_number" : "+14156665767"
            },
            "merchant_handle" : "maltesecafe",
            "time" : "2024-06-26T20:32:49.250Z",
            "timezone" : "Asia\/Tokyo",
            "host" : "cafe.bio",
            "page" : "promo-redeem-complete\/",
            "path_name" : "\/r\/maltesecafe\/maltesecafe-d5opi"
          },
          "tUWPB3tF" : {
            "user_agent" : "Mozilla\/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/126.0.0.0 Safari\/537.36",
            "redemption_object" : {
              "redeemguid" : "gAmASNTf",
              "merchant_handle" : "maltesecafe",
              "promo_id" : "maltesecafe-d5opi",
              "visit_date" : "Next Friday",
              "environment" : "production",
              "visit_time" : "1:00 PM",
              "merchantName" : "maltesecafe",
              "customer_group_id" : 1222,
              "phone_number" : "+13875445445"
            },
            "merchant_handle" : "maltesecafe",
            "time" : "2024-06-26T20:32:19.545Z",
            "timezone" : "Asia\/Tokyo",
            "host" : "cafe.bio",
            "page" : "promo-redeem-complete\/",
            "path_name" : "\/r\/maltesecafe\/maltesecafe-d5opi"
          },
          "td1nC6mT" : {
            "user_agent" : "Mozilla\/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/126.0.0.0 Safari\/537.36",
            "redemption_object" : {
              "redeemguid" : "q1Piyp8Z",
              "merchant_handle" : "maltesecafe",
              "promo_id" : "maltesecafe-d5opi",
              "visit_date" : "Thursday",
              "environment" : "production",
              "visit_time" : "9:00 PM",
              "merchantName" : "maltesecafe",
              "customer_group_id" : 1222,
              "phone_number" : "+14158798798"
            },
            "merchant_handle" : "maltesecafe",
            "time" : "2024-06-26T19:10:27.048Z",
            "timezone" : "Asia\/Tokyo",
            "host" : "cafe.bio",
            "page" : "promo-redeem-complete\/",
            "path_name" : "\/r\/maltesecafe\/maltesecafe-d5opi"
          },
          "x7kz7jV8" : {
            "user_agent" : "Mozilla\/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/126.0.0.0 Safari\/537.36",
            "redemption_object" : {
              "redeemguid" : "wGDsmfcR",
              "merchant_handle" : "maltesecafe",
              "promo_id" : "maltesecafe-d5opi",
              "visit_date" : "Next Friday",
              "environment" : "production",
              "visit_time" : "1:00 PM",
              "merchantName" : "maltesecafe",
              "customer_group_id" : 1222,
              "phone_number" : "+14156665767"
            },
            "merchant_handle" : "maltesecafe",
            "time" : "2024-06-26T20:32:49.273Z",
            "timezone" : "Asia\/Tokyo",
            "host" : "cafe.bio",
            "page" : "promo-redeem-complete\/",
            "path_name" : "\/r\/maltesecafe\/maltesecafe-d5opi"
          }
        }
      }
    }
  },
  "itsbobatimesanbernardino" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 726-1017",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/ef5d510616e8873f7278df80e070e8b1\/original.jpeg",
      "instagramHandle" : "itsbobatimesanbernardino",
      "merchantHandle" : "itsbobatimesanbernardino",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "It's Boba Time - San Bernardino",
      "city" : "San Bernardino",
      "companyName" : "It's Boba Time - San Bernardino",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/itsbobatime.com\/",
      "email" : ""
    }
  },
  "evoofoods" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(949) 432-6752",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/959fc0e6df46506b13c0430d617c5f92db45c7eb\/original.jpeg",
      "instagramHandle" : "evoofoods",
      "merchantHandle" : "evoofoods",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "EVOO Foods",
      "city" : "Irvine",
      "companyName" : "EVOO Foods",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "benjiesnydeli" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 541-6263",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Zej3aag3QHNKwMLuYC30sqFoGE1U2DZu.png",
      "instagramHandle" : "benjiesnydeli",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "benjiesnydeli",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Benjies NY Deli",
      "city" : "Santa Ana",
      "companyName" : "Benjies NY Deli",
      "websiteUrl" : "http:\/\/www.benjiesdeli.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "purplehouse" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "purplehouse",
      "premium" : false,
      "merchantHandle" : "purplehouse",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Purple House",
      "city" : "Ann Arbor",
      "companyName" : "Purple House",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.missiona2.org\/jimmy-hill-house.html",
      "email" : ""
    }
  },
  "villastacoslosangeles" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(818) 741-8011",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/GyFotqQO6vox1haQK0Ve4Gz0keR0zOa0.png",
      "instagramHandle" : "villastacoslosangeles",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "villastacoslosangeles",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Villa's Tacos Los Angeles",
      "city" : "Glendale",
      "companyName" : "Villa's Tacos Los Angeles",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.villastacos.com\/",
      "email" : ""
    }
  },
  "hilltopfarms" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(657) 234-1238",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/UobC1ivs2drDenlg80uoNccHiE9FZZR0.png",
      "instagramHandle" : "hilltopfarms",
      "twitterHandle" : "",
      "merchantHandle" : "hilltopfarms",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Hilltop Farms",
      "city" : "Riverside",
      "companyName" : "Hilltop Farms",
      "websiteUrl" : "https:\/\/hilltopfarmspoultry.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "lumpiallconline" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(509) 598-6058",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/u5E2KWLoUzqIUkCvKQM5vHV5yIuL42KI.png",
      "instagramHandle" : "lumpiallconline",
      "premium" : false,
      "merchantHandle" : "lumpiallconline",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "LUMPIA LLC [Online ]",
      "city" : "Spokane",
      "companyName" : "LUMPIA LLC [Online ]",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "chefscountrycafe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 823-2200",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/xggNmdmPTaaZlRfam7N9pQ4N00uJpwa7.png",
      "instagramHandle" : "chefscountrycafe",
      "merchantHandle" : "chefscountrycafe",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Chef's Country Cafe",
      "city" : "Fontana",
      "companyName" : "Chef's Country Cafe",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "tikizshavediceandicecreamofventuracounty" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(833) 805-0805",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/mQIlSNzqGIbuRMFvCK5uEQbgkZchR93J.png",
      "instagramHandle" : "tikizshavediceandicecreamofventuracounty",
      "premium" : false,
      "merchantHandle" : "tikizshavediceandicecreamofventuracounty",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Tikiz Shaved Ice and Ice Cream of Ventura County",
      "city" : "San Bernardino",
      "companyName" : "Tikiz Shaved Ice and Ice Cream of Ventura County",
      "websiteUrl" : "http:\/\/tikiz.com\/VenturaCounty",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thegoldmineartpostmichiganmadegifts" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(517) 930-0990",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/f9b6406a81705e3968403d78e87255afe3278d06\/original.jpeg",
      "instagramHandle" : "thegoldmineartpostmichiganmadegifts",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "thegoldmineartpostmichiganmadegifts",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Gold Mine Artpost & Michigan Made Gifts",
      "city" : "Lansing",
      "companyName" : "The Gold Mine Artpost & Michigan Made Gifts",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "coppledirecthomecaresupply" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(248) 385-2298",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/e1cfad5c5a075b5c33dd72759f9a5da945b5eb2a\/original.jpeg",
      "instagramHandle" : "coppledirecthomecaresupply",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "coppledirecthomecaresupply",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Copple Direct Home Care Supply",
      "city" : "Warren",
      "companyName" : "Copple Direct Home Care Supply",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.coppledirect.com\/",
      "email" : ""
    }
  },
  "genkoreanbbqhouse" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 944-1878",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "genkoreanbbqhouse",
      "merchantHandle" : "genkoreanbbqhouse",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Gen Korean BBQ House",
      "city" : "Fontana",
      "companyName" : "Gen Korean BBQ House",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/genkoreanbbq.com\/",
      "email" : ""
    }
  },
  "arctoscoffeeandroastingco" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(509) 413-2362",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/FunI0g6b9KKT6Md7z4pHQxqNaICw66HH.png",
      "instagramHandle" : "arctoscoffeeandroastingco",
      "merchantHandle" : "arctoscoffeeandroastingco",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Arctos Coffee and Roasting Co.",
      "city" : "Spokane",
      "companyName" : "Arctos Coffee and Roasting Co.",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/arctoscoffee.com\/",
      "email" : ""
    }
  },
  "crumblsterlingheights" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(586) 580-1270",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/AJgDVJlXIEsdOLupHwjMIF0Cb0aB15vG.png",
      "instagramHandle" : "crumblsterlingheights",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "crumblsterlingheights",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Crumbl - Sterling Heights",
      "city" : "Sterling Heights",
      "companyName" : "Crumbl - Sterling Heights",
      "websiteUrl" : "https:\/\/crumblcookies.com\/misterlingheights?utm_source=google+business&utm_medium=profile&utm_campaign=CRUMBL-US+%7C+GOOGLE+BUSINESS+%7C+STORE+PAGE+%7C+ONGOING+%7C+CONSIDERATION+%7C+PROFILE",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "samsgrillseafoodrestaurant" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 421-0594",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/dd02b786aa1298daff64d55da6f320f8b20702fb\/original.jpeg",
      "instagramHandle" : "samsgrillseafoodrestaurant",
      "merchantHandle" : "samsgrillseafoodrestaurant",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Sam's Grill & Seafood Restaurant",
      "city" : "San Francisco",
      "companyName" : "Sam's Grill & Seafood Restaurant",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.samsgrill-sf.com\/",
      "email" : ""
    }
  },
  "suzyskitchen" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(657) 201-3075",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/xggNmdmPTaaZlRfam7N9pQ4N00uJpwa7.png",
      "instagramHandle" : "suzyskitchen",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "suzyskitchen",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Suzy's Kitchen",
      "city" : "Anaheim",
      "companyName" : "Suzy's Kitchen",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/suzyskitchenoc.com\/",
      "email" : ""
    }
  },
  "soulprovisions" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 947-5313",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/k4lBt3HNHpc17owbguWZpCAZjLfQdvxN.png",
      "instagramHandle" : "soulprovisions",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "soulprovisions",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Soul Provisions",
      "city" : "Oakland",
      "companyName" : "Soul Provisions",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "nvcatering" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(661) 679-3540",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/s0veg74ty2TkEwI5AhZFbYSfA9MOwGaX.png",
      "instagramHandle" : "nvcatering",
      "merchantHandle" : "nvcatering",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "NV Catering",
      "city" : "Bakersfield",
      "companyName" : "NV Catering",
      "websiteUrl" : "http:\/\/nvcatering.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "goldencrownrestaurant" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(503) 362-9560",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "goldencrownrestaurant",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "goldencrownrestaurant",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Golden Crown Restaurant",
      "city" : "Salem",
      "companyName" : "Golden Crown Restaurant",
      "websiteUrl" : "http:\/\/gcrownrestaurant.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "craftgather" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/z73x5YUbyaeSN07UsL98hqy053blA355.png",
      "instagramHandle" : "craftgather",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "craftgather",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Craft & Gather",
      "city" : "Spokane",
      "companyName" : "Craft & Gather",
      "websiteUrl" : "http:\/\/craftandgather.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sextantcoffeeroasters" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 355-1415",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/0ywOa5lhTCDckDXJ2yrXRv9u2KK9dMUw.png",
      "instagramHandle" : "sextantcoffeeroasters",
      "twitterHandle" : "",
      "merchantHandle" : "sextantcoffeeroasters",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sextant Coffee Roasters",
      "city" : "San Francisco",
      "companyName" : "Sextant Coffee Roasters",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/sextantcoffee.com\/",
      "email" : ""
    }
  },
  "jamnbeancoffeecompany" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(616) 432-6683",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "jamnbeancoffeecompany",
      "twitterHandle" : "",
      "merchantHandle" : "jamnbeancoffeecompany",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Jamnbean Coffee Company",
      "city" : "Grand Rapids",
      "companyName" : "Jamnbean Coffee Company",
      "websiteUrl" : "http:\/\/www.jamnbean.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "ieautodetailingtintingppfcarwraps" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(951) 435-8350",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/7f6e205e9208c2e2e35e875faa07fd83ecea8ad1\/original.png",
      "instagramHandle" : "ieautodetailingtintingppfcarwraps",
      "merchantHandle" : "ieautodetailingtintingppfcarwraps",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "IE Auto Detailing- Tinting PPF Car Wraps",
      "city" : "Riverside",
      "companyName" : "IE Auto Detailing- Tinting PPF Car Wraps",
      "websiteUrl" : "https:\/\/www.iewindowtinting.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "20twentycheesebar" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(408) 293-7574",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/43XGhzPwMSRAZwcmP5g5OD8VNrIQ0JXE.png",
      "instagramHandle" : "20twentycheesebar",
      "twitterHandle" : "",
      "merchantHandle" : "20twentycheesebar",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "20twenty cheese bar",
      "city" : "San Jose",
      "companyName" : "20twenty cheese bar",
      "websiteUrl" : "http:\/\/2020cheesebar.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "renegadetaco" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 462-6416",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/wbcTyBd8u64BeuhdiFRl69v0wD8vRuRh.png",
      "instagramHandle" : "renegadetaco",
      "merchantHandle" : "renegadetaco",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Renegade Taco",
      "city" : "Santa Ana",
      "companyName" : "Renegade Taco",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/eatrenegadetaco.square.site\/",
      "email" : ""
    }
  },
  "cocodonuts" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(503) 477-9824",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/BWwxktakA0RL0iv4EBUeqLfxEslLWBcs.png",
      "instagramHandle" : "cocodonuts",
      "merchantHandle" : "cocodonuts",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "coco donuts",
      "city" : "Portland",
      "companyName" : "coco donuts",
      "websiteUrl" : "https:\/\/cocodonuts.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "najeebkabobhouse" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(586) 274-0500",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/DlxzezC2js3SwurPZ1vtLmABpTafj40r.jpeg",
      "instagramHandle" : "najeebkabobhouse",
      "merchantHandle" : "najeebkabobhouse",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Najeeb Kabob House",
      "city" : "Warren",
      "companyName" : "Najeeb Kabob House",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  " Orchard & Cider Mill\"" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "(517) 543-1019",
      "phone" : "",
      "merchantImage" : "countrymillfarmsbakeryorchardcidermill",
      "instagramHandle" : " Bakery",
      "merchantHandle" : " Orchard & Cider Mill\"",
      "premium" : false,
      "squareProductionKey" : " Bakery",
      "twitterHandle" : "",
      "squareSandboxKey" : " Orchard & Cider Mill\"",
      "companyType" : "",
      "merchantName" : "countrymillfarmsbakeryorchardcidermill",
      "city" : "http:\/\/countrymillfarms.com\/",
      "companyName" : "\"Country Mill Farms",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "unincorporatedcoffeeroasters" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(855) 799-3774",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/ccbde461fb07abbd1cb68af057854a66e03c6ed8\/original.png",
      "instagramHandle" : "unincorporatedcoffeeroasters",
      "merchantHandle" : "unincorporatedcoffeeroasters",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Unincorporated Coffee Roasters",
      "city" : "Glendale",
      "companyName" : "Unincorporated Coffee Roasters",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.unincorporated.coffee\/",
      "email" : ""
    }
  },
  "milkhouseshakes" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(916) 389-0098",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/dbosW2AP0JtKlaGxUfu0GWRepU3o6Yz3.png",
      "instagramHandle" : "milkhouseshakes",
      "premium" : false,
      "merchantHandle" : "milkhouseshakes",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Milk House Shakes",
      "city" : "Sacramento",
      "companyName" : "Milk House Shakes",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "relevantcoffee" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(971) 319-5773",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/AlmsQ0pcM1wqDuYrfLqF5Z5mtP2b6MrM.png",
      "instagramHandle" : "relevantcoffee",
      "premium" : false,
      "merchantHandle" : "relevantcoffee",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Relevant Coffee",
      "city" : "Vancouver",
      "companyName" : "Relevant Coffee",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/relevantcoffee.com\/",
      "email" : ""
    }
  },
  "badbakerssantaana" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 760-4319",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/be1ae9ed8a25d86a726fa7729f3f25da4f1ddf0c\/original.jpeg",
      "instagramHandle" : "badbakerssantaana",
      "premium" : false,
      "merchantHandle" : "badbakerssantaana",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "BAD Bakers Santa Ana",
      "city" : "Santa Ana",
      "companyName" : "BAD Bakers Santa Ana",
      "websiteUrl" : "http:\/\/www.badbakers.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "downtownexperiment" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 355-2606",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/gsZiC9R7GMHAZjt7HXEuT3abalSWO0yQ.png",
      "instagramHandle" : "downtownexperiment",
      "merchantHandle" : "downtownexperiment",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Downtown Experiment",
      "city" : "Riverside",
      "companyName" : "Downtown Experiment",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/dteriverside.com\/",
      "email" : ""
    }
  },
  "dougsplacelcc" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(541) 988-1828",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/f8b46d79547bf916d16f30129318e8ba2d7e3b56\/original.jpeg",
      "instagramHandle" : "dougsplacelcc",
      "premium" : false,
      "merchantHandle" : "dougsplacelcc",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Doug's Place @ LCC",
      "city" : "Eugene",
      "companyName" : "Doug's Place @ LCC",
      "websiteUrl" : "https:\/\/www.facebook.com\/dougsplace8\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "awakecafe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(313) 315-3038",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/4933f7134c15ba50ccb00911d8b46659de163270\/original.jpeg",
      "instagramHandle" : "awakecafe",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "awakecafe",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "AWAKE Cafe",
      "city" : "Detroit",
      "companyName" : "AWAKE Cafe",
      "websiteUrl" : "http:\/\/awakedetroit.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "lasdeliciasdelbarriomexicanfoodtruck" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 889-2027",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/qJFnPq9YyaUzu2VQmIbiaR7QzPClP6zb.png",
      "instagramHandle" : "lasdeliciasdelbarriomexicanfoodtruck",
      "merchantHandle" : "lasdeliciasdelbarriomexicanfoodtruck",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Las Delicias Del Barrio Mexican Food Truck",
      "city" : "Stockton",
      "companyName" : "Las Delicias Del Barrio Mexican Food Truck",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "theitalianhomemadecompanylongbeach" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(562) 431-1700",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/MFaB8VMufs9H0qX32i9aIH8uHbpzNx0t.png",
      "instagramHandle" : "theitalianhomemadecompanylongbeach",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "theitalianhomemadecompanylongbeach",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Italian Homemade Company (Long Beach)",
      "city" : "Long Beach",
      "companyName" : "The Italian Homemade Company (Long Beach)",
      "websiteUrl" : "http:\/\/italianhomemade.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "projectlunch" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MT\r",
      "phone" : "(406) 272-2470",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/71e89cd48df795f068a09da2898e0c449277244f\/original.jpeg",
      "instagramHandle" : "projectlunch",
      "merchantHandle" : "projectlunch",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Project Lunch",
      "city" : "Billings",
      "companyName" : "Project Lunch",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "casablancacoffeekiosk" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MT\r",
      "phone" : "(406) 256-7333",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "casablancacoffeekiosk",
      "merchantHandle" : "casablancacoffeekiosk",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Casablanca Coffee Kiosk",
      "city" : "Billings",
      "companyName" : "Casablanca Coffee Kiosk",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "missgigissweets" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MT\r",
      "phone" : "(406) 647-9363",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Jzjxj6BAhR96kFLWgNloKGZbGzKYS0aq.png",
      "instagramHandle" : "missgigissweets",
      "twitterHandle" : "",
      "merchantHandle" : "missgigissweets",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Miss GiGi's Sweets",
      "city" : "Billings",
      "companyName" : "Miss GiGi's Sweets",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "vanderhelmfarms" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 614-8307",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/330e3e63eeafef767d0a0ad9f5ff9b3db7f270ae\/original.jpeg",
      "instagramHandle" : "vanderhelmfarms",
      "merchantHandle" : "vanderhelmfarms",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "VanderHelm Farms",
      "city" : "Modesto",
      "companyName" : "VanderHelm Farms",
      "websiteUrl" : "http:\/\/vanderhelmfarms.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "bowdieschophouse" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(517) 580-4792",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/N8ikdywIiH6dNedojibSawtyiBNhU0zE.png",
      "instagramHandle" : "bowdieschophouse",
      "merchantHandle" : "bowdieschophouse",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Bowdie's Chophouse",
      "city" : "Lansing",
      "companyName" : "Bowdie's Chophouse",
      "websiteUrl" : "http:\/\/bowdieschophouse.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "redbaycoffeeroastery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 399-2441",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/bpYLKmNG3iyiAI3U6RhOlub56ufbSoZx.png",
      "instagramHandle" : "redbaycoffeeroastery",
      "merchantHandle" : "redbaycoffeeroastery",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Red Bay Coffee Roastery",
      "city" : "Oakland",
      "companyName" : "Red Bay Coffee Roastery",
      "websiteUrl" : "http:\/\/www.redbaycoffee.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "tonysbarbecueandbibingkinitanoffontana" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 452-7262",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/3sR3AtvJMaN0Tpf4RifaKSvgH4sj5WBJ.jpeg",
      "instagramHandle" : "tonysbarbecueandbibingkinitanoffontana",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "tonysbarbecueandbibingkinitanoffontana",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Tony's Barbecue and Bibingkinitan of Fontana",
      "city" : "Fontana",
      "companyName" : "Tony's Barbecue and Bibingkinitan of Fontana",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "bruncheonette" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(509) 443-5968",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/D7G4dvaLzfw7nMYD3SVtVFny4bsr06NX.png",
      "instagramHandle" : "bruncheonette",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "bruncheonette",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Bruncheonette",
      "city" : "Spokane",
      "companyName" : "Bruncheonette",
      "websiteUrl" : "http:\/\/bruncheonettespokane.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "norigami" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 643-9263",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/UNfxaFn47PhP404k4NafUQInqoC4pupR.png",
      "instagramHandle" : "norigami",
      "twitterHandle" : "",
      "merchantHandle" : "norigami",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Norigami",
      "city" : "Santa Ana",
      "companyName" : "Norigami",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "capitolgyros" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(916) 373-0122",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/c6a089870e720faf9bb2cf684f7aa815fa9dc601\/original.jpeg",
      "instagramHandle" : "capitolgyros",
      "twitterHandle" : "",
      "merchantHandle" : "capitolgyros",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Capitol Gyros",
      "city" : "Sacramento",
      "companyName" : "Capitol Gyros",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "theskip" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(313) 724-3145",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/a2f68f79e2e4c14aa2c67121ea5e75a6e075ea8e\/original.png",
      "instagramHandle" : "theskip",
      "merchantHandle" : "theskip",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Skip",
      "city" : "Detroit",
      "companyName" : "The Skip",
      "websiteUrl" : "https:\/\/www.theskipdetroit.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "yeoldelamplighter" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 886-4490",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/PloGKNXoKkia7gTtHVDqDbUWUHi4niIh.png",
      "instagramHandle" : "yeoldelamplighter",
      "merchantHandle" : "yeoldelamplighter",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Ye Olde Lamplighter",
      "city" : "San Bernardino",
      "companyName" : "Ye Olde Lamplighter",
      "websiteUrl" : "https:\/\/www.facebook.com\/yeoldelamp\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "humphryslocombe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 338-3120",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/t0p8m0Z1aw1NKxxaeTHMAvr2iJSRyN1d.png",
      "instagramHandle" : "humphryslocombe",
      "merchantHandle" : "humphryslocombe",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Humphry Slocombe",
      "city" : "Oakland",
      "companyName" : "Humphry Slocombe",
      "websiteUrl" : "http:\/\/www.humphryslocombe.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "rosamundesausagegrill" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 338-3108",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/JGIQy0b7DmlHbYdX6ATeUC8K3aBrWExS.png",
      "instagramHandle" : "rosamundesausagegrill",
      "premium" : false,
      "merchantHandle" : "rosamundesausagegrill",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Rosamunde Sausage Grill",
      "city" : "Oakland",
      "companyName" : "Rosamunde Sausage Grill",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.rosamundesausagegrill.com\/?y_source=1_MTQyNTQ3MDctNzE1LWxvY2F0aW9uLndlYnNpdGU%3D",
      "email" : ""
    }
  },
  "caferveille" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 400-4887",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/nKWOErEihJESjrKG5diic447mC15Aulk.png",
      "instagramHandle" : "caferveille",
      "merchantHandle" : "caferveille",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Cafe Réveille",
      "city" : "San Francisco",
      "companyName" : "Cafe Réveille",
      "websiteUrl" : "http:\/\/cafereveille.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "pandelbarrio" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(858) 353-5343",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Z1Jw1aWwz2hIliVG9WiaYlTGFCTMpQdl.png",
      "instagramHandle" : "pandelbarrio",
      "merchantHandle" : "pandelbarrio",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Pan Del Barrio",
      "city" : "Chula Vista",
      "companyName" : "Pan Del Barrio",
      "websiteUrl" : "http:\/\/www.pandelbarrio.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sweetmercyfarmspokanewa" : {
    "info" : {
      "state" : "",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "sweetmercyfarmspokanewa",
      "instagramHandle" : " WA\"",
      "twitterHandle" : "",
      "merchantHandle" : "sweetmercyfarmspokanewa",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "(509) 405-6449",
      "merchantName" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/6LZOHYLvGw2cD3XJmeHD77UuEVcj05Hy.jpeg",
      "city" : "",
      "companyName" : "\"Sweet Mercy Farm - Spokane",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : "http:\/\/www.sweetmercyfarmstead.com\/"
    }
  },
  "lotusbrewcoffeepopup" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(616) 419-8613",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/w841T67zXGUAdApw3yy8zsJgZfo0LvB0.png",
      "instagramHandle" : "lotusbrewcoffeepopup",
      "merchantHandle" : "lotusbrewcoffeepopup",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Lotus Brew Coffee Pop Up",
      "city" : "Grand Rapids",
      "companyName" : "Lotus Brew Coffee Pop Up",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/lotusbrewcoffee.com\/",
      "email" : ""
    }
  },
  "doseofsugar" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(313) 800-5734",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/6pV21M1wFGeSZScabJUgOKHtD2Gx0RME.png",
      "instagramHandle" : "doseofsugar",
      "merchantHandle" : "doseofsugar",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Dose Of Sugar",
      "city" : "Detroit",
      "companyName" : "Dose Of Sugar",
      "websiteUrl" : "http:\/\/www.doseofsugarcafe.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "acailifesuperfoods" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 360-1265",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ffFuI5Ze8LoIxsfGN53lIOLnwR7C45I7.png",
      "instagramHandle" : "acailifesuperfoods",
      "twitterHandle" : "",
      "merchantHandle" : "acailifesuperfoods",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Acai Life Superfoods",
      "city" : "San Bernardino",
      "companyName" : "Acai Life Superfoods",
      "websiteUrl" : "http:\/\/acailifesuperfoods.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "kumquatcoffeehlp" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/TBYDFYE3G1LrdwMrdi186ZypoFJlkTTq.png",
      "instagramHandle" : "kumquatcoffeehlp",
      "twitterHandle" : "",
      "merchantHandle" : "kumquatcoffeehlp",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Kumquat Coffee HLP",
      "city" : "Glendale",
      "companyName" : "Kumquat Coffee HLP",
      "websiteUrl" : "http:\/\/www.kumquatcoffee.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "pushkinsbakery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 376-7752",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/aac26bf4c9b98db8ca0ae5aed582c86b1707d362\/original.jpeg",
      "instagramHandle" : "pushkinsbakery",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "pushkinsbakery",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Pushkin's Bakery",
      "city" : "Sacramento",
      "companyName" : "Pushkin's Bakery",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.pushkinsbakery.com\/",
      "email" : ""
    }
  },
  "thepunchdownwinebarbottleshop" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 788-7877",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/8oLGFKlByGX5IXzkXg54jqNEnR4uC2s0.png",
      "instagramHandle" : "thepunchdownwinebarbottleshop",
      "merchantHandle" : "thepunchdownwinebarbottleshop",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Punchdown Wine Bar + Bottle Shop",
      "city" : "Oakland",
      "companyName" : "The Punchdown Wine Bar + Bottle Shop",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.punchdownwine.com\/",
      "email" : ""
    }
  },
  "kraemerskoffeebistro" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(951) 686-4400",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/ef484aa2a3d2547aa8c7ac0d336a476c\/original.jpeg",
      "instagramHandle" : "kraemerskoffeebistro",
      "premium" : false,
      "merchantHandle" : "kraemerskoffeebistro",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Kraemer's Koffee Bistro",
      "city" : "San Bernardino",
      "companyName" : "Kraemer's Koffee Bistro",
      "websiteUrl" : "https:\/\/www.kraemerskoffee.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thispiggyfliescookiecompany" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(951) 323-2350",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/UWmc7z9mYYmjB0twLtcvaaGCW8FJhk0j.png",
      "instagramHandle" : "thispiggyfliescookiecompany",
      "merchantHandle" : "thispiggyfliescookiecompany",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "This Piggy Flies Cookie Company",
      "city" : "San Bernardino",
      "companyName" : "This Piggy Flies Cookie Company",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/thispiggyfliescookiecompany.com\/",
      "email" : ""
    }
  },
  "smokinjsbbqpit" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(810) 636-9200",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/327889e46680e49f8abf6236c9d3b2ad8691b1e2\/original.png",
      "instagramHandle" : "smokinjsbbqpit",
      "premium" : false,
      "merchantHandle" : "smokinjsbbqpit",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Smokin J's BBQ Pit",
      "city" : "Flint",
      "companyName" : "Smokin J's BBQ Pit",
      "websiteUrl" : "http:\/\/facebook.com\/SmokinJsBarBQuePit\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "tacoselparaso" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(661) 900-4736",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/KKoaIgN8sNK4Uw2mUOAh3RKq6aYQdmUH.png",
      "instagramHandle" : "tacoselparaso",
      "premium" : false,
      "merchantHandle" : "tacoselparaso",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Tacos El Paraíso",
      "city" : "Bakersfield",
      "companyName" : "Tacos El Paraíso",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "saigavietnameseeatery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(949) 346-6701",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/d7zmkK9LTJwkrB6aKaC9d2sUrkU9VNgD.png",
      "instagramHandle" : "saigavietnameseeatery",
      "twitterHandle" : "",
      "merchantHandle" : "saigavietnameseeatery",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Saiga Vietnamese Eatery",
      "city" : "Santa Ana",
      "companyName" : "Saiga Vietnamese Eatery",
      "websiteUrl" : "https:\/\/www.saigaeatery.com\/?utm_source=gmb&utm_medium=organic&utm_campaign=gmb-listing",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "kruabangkokpdx" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(503) 799-3735",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "kruabangkokpdx",
      "merchantHandle" : "kruabangkokpdx",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Krua Bangkok PDX",
      "city" : "Portland",
      "companyName" : "Krua Bangkok PDX",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/kruabangkokpdx.square.site\/",
      "email" : ""
    }
  },
  "kdsdonuts" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 485-9171",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/6ddbcbea5a5e4615009890fed007f976e24a61d7\/original.jpeg",
      "instagramHandle" : "kdsdonuts",
      "premium" : false,
      "merchantHandle" : "kdsdonuts",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "K D's Donuts",
      "city" : "Moreno Valley",
      "companyName" : "K D's Donuts",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "thecraftloungetaproombottleshop" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 797-0337",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/1SbamHqVCR3nE4iVkpOuQA3JhopyHToT.png",
      "instagramHandle" : "thecraftloungetaproombottleshop",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "thecraftloungetaproombottleshop",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Craft Lounge Taproom & Bottleshop",
      "city" : "San Bernardino",
      "companyName" : "The Craft Lounge Taproom & Bottleshop",
      "websiteUrl" : "http:\/\/www.thecraftloungebeer.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "lechon" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(503) 219-9000",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/63chEsbqRqmKa9uBG86GfRzq87FLQf2I.png",
      "instagramHandle" : "lechon",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "lechon",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Lechon",
      "city" : "Portland",
      "companyName" : "Lechon",
      "websiteUrl" : "http:\/\/lechonpdx.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "nomad" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(843) 566-5523",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/syUagSMhHf5yhICXJeMz0TUR7zOCgH2T.png",
      "instagramHandle" : "nomad",
      "merchantHandle" : "nomad",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Nomad",
      "city" : "Vancouver",
      "companyName" : "Nomad",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.nomadshorttermrentals.com\/contact-us",
      "email" : ""
    }
  },
  "tacollynninc" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(877) 490-7625",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/6647e5b8b3e2b35fc53d7b5ebbe6c56dd79ccd4b\/original.jpeg",
      "instagramHandle" : "tacollynninc",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "tacollynninc",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Tacollynn Inc",
      "city" : "San Bernardino",
      "companyName" : "Tacollynn Inc",
      "websiteUrl" : "http:\/\/www.tacollynn.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "bites" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(562) 980-0236",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Ffl2DcOZlfRksdKk3O5PwLxdVQg31BkY.png",
      "instagramHandle" : "bites",
      "merchantHandle" : "bites",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Bites",
      "city" : "Long Beach",
      "companyName" : "Bites",
      "websiteUrl" : "http:\/\/biteslb.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "flavorbrigade" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(925) 425-7686",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/9169a054fb384897303de26b2ede1d49\/original.jpeg",
      "instagramHandle" : "flavorbrigade",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "flavorbrigade",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Flavor Brigade",
      "city" : "Stockton",
      "companyName" : "Flavor Brigade",
      "websiteUrl" : "http:\/\/flavorbrigade.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "ledetroitmacaron" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(313) 338-8006",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/3d0041e59c67bbfaae0aeaea3b293d35d813e2ee\/original.png",
      "instagramHandle" : "ledetroitmacaron",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "ledetroitmacaron",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Le detroit macaron",
      "city" : "Warren",
      "companyName" : "Le detroit macaron",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.ledetroitmacaron.com\/",
      "email" : ""
    }
  },
  "tapaworld" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 442-4353",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/PdyELdtMyn3BF0QDRxgkjZZefjCZkurs.png",
      "instagramHandle" : "tapaworld",
      "premium" : false,
      "merchantHandle" : "tapaworld",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Tapa the World",
      "city" : "Sacramento",
      "companyName" : "Tapa the World",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.tapatheworld.com\/",
      "email" : ""
    }
  },
  "larsarestaurant" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(586) 554-7566",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "larsarestaurant",
      "merchantHandle" : "larsarestaurant",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Larsa restaurant",
      "city" : "Sterling Heights",
      "companyName" : "Larsa restaurant",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.larsapalace.net\/",
      "email" : ""
    }
  },
  "victoriagroveproducemarket" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(951) 888-0305",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/67e405de422a146235179ec238475393cd9855c3\/original.png",
      "instagramHandle" : "victoriagroveproducemarket",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "victoriagroveproducemarket",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Victoria Grove Produce Market",
      "city" : "Riverside",
      "companyName" : "Victoria Grove Produce Market",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/victoria-grove-produce-market.square.site\/",
      "email" : ""
    }
  },
  "gooddeedscoffeebar" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(517) 219-9046",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/plrQks8QWaVgo0BkSGUOTaEL2JgYCfqx.png",
      "instagramHandle" : "gooddeedscoffeebar",
      "twitterHandle" : "",
      "merchantHandle" : "gooddeedscoffeebar",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Good Deeds Coffee Bar",
      "city" : "Lansing",
      "companyName" : "Good Deeds Coffee Bar",
      "websiteUrl" : "https:\/\/gooddeeds.coffee\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "nothingbundtcakes" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(619) 730-7671",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/c1d602fc3b617f55fab7ea0d24ef567a7e3c9c06\/original.jpeg",
      "instagramHandle" : "nothingbundtcakes",
      "merchantHandle" : "nothingbundtcakes",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Nothing Bundt Cakes",
      "city" : "Chula Vista",
      "companyName" : "Nothing Bundt Cakes",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.nothingbundtcakes.com\/all-products\/?utm_source=gbplisting&utm_medium=organic&utm_campaign=81_website_link",
      "email" : ""
    }
  },
  "arvonbrewingcogrtaproom" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 340-9372",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/GoZ7AVJ819ncGuFMJh4hXoHplU3WRzPd.png",
      "instagramHandle" : "arvonbrewingcogrtaproom",
      "twitterHandle" : "",
      "merchantHandle" : "arvonbrewingcogrtaproom",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Arvon Brewing Co. - GR Taproom",
      "city" : "Grand Rapids",
      "companyName" : "Arvon Brewing Co. - GR Taproom",
      "websiteUrl" : "http:\/\/www.arvonbrewingco.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "puregreenjuicebarsterlingheights" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(586) 843-3375",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/a9MCmJbmS3xzfFBRTjD21CCJJiKfYc8o.png",
      "instagramHandle" : "puregreenjuicebarsterlingheights",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "puregreenjuicebarsterlingheights",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Pure Green - Juice Bar Sterling Heights",
      "city" : "Sterling Heights",
      "companyName" : "Pure Green - Juice Bar Sterling Heights",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.puregreenfranchise.com\/michigan\/sterling-heights\/",
      "email" : ""
    }
  },
  "momosmore" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 658-7432",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/zo6n7hBbMp23d71gn3KGlAqD5zvoF95t.png",
      "instagramHandle" : "momosmore",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "momosmore",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "MoMo's & More",
      "city" : "San Francisco",
      "companyName" : "MoMo's & More",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.momosmore.com\/",
      "email" : ""
    }
  },
  "raeraesglutenfreebakery" : {
    "info" : {
      "state" : "MT\r",
      "posSystem" : "Square",
      "phone" : "(406) 200-7948",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/EvvE7ntpMV3jaqKGOuy59kphTdF6JAcg.png",
      "instagramHandle" : "raeraesglutenfreebakery",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "raeraesglutenfreebakery",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Rae Rae's Gluten Free Bakery",
      "city" : "Billings",
      "companyName" : "Rae Rae's Gluten Free Bakery",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.raeraesbakery.com\/",
      "email" : ""
    }
  },
  "kellysdeli" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(650) 240-9870",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "kellysdeli",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "kellysdeli",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Kelly's Deli",
      "city" : "San Jose",
      "companyName" : "Kelly's Deli",
      "websiteUrl" : "https:\/\/www.doordash.com\/store\/23065204?utm_source=mx_share",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "foodshedinc" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(619) 821-8222",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/82f428fd8aa0159c7aaadb245269bfa4db2a1772\/original.png",
      "instagramHandle" : "foodshedinc",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "foodshedinc",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Foodshed Inc",
      "city" : "Chula Vista",
      "companyName" : "Foodshed Inc",
      "websiteUrl" : "http:\/\/www.foodshedsmallfarmdistro.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "poketikiirvine" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(949) 838-5233",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/7c2e0d1134ac7c8288b5982d789de2474817c667\/original.png",
      "instagramHandle" : "poketikiirvine",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "poketikiirvine",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Poke Tiki - Irvine",
      "city" : "Irvine",
      "companyName" : "Poke Tiki - Irvine",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/ptirvine.square.site\/",
      "email" : ""
    }
  },
  "reginasmexicanfoodtruckcatering" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(248) 703-9822",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Pz6wP6l6DEmLbIzJLRFPWejSaLwaCZsc.png",
      "instagramHandle" : "reginasmexicanfoodtruckcatering",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "reginasmexicanfoodtruckcatering",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Regina's Mexican Food Truck & Catering",
      "city" : "Warren",
      "companyName" : "Regina's Mexican Food Truck & Catering",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.reginasmexicanfoodtruck.com\/",
      "email" : ""
    }
  },
  "danweicanting" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(503) 476-9717",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Yg4SZsKpFJE5GKFGzdLiy3xbtiv0waUb.png",
      "instagramHandle" : "danweicanting",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "danweicanting",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Danwei Canting",
      "city" : "Portland",
      "companyName" : "Danwei Canting",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "downtownnutrition" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(661) 412-3201",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/edffecf855b135d5761f6a30354ba54777183bd0\/original.jpeg",
      "instagramHandle" : "downtownnutrition",
      "premium" : false,
      "merchantHandle" : "downtownnutrition",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Downtown Nutrition",
      "city" : "Bakersfield",
      "companyName" : "Downtown Nutrition",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "cascadespiritstastingroombottleshop" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(541) 549-1082",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/fa6bfc0583c2574192f35ded21fb126b371b6a54\/original.jpeg",
      "instagramHandle" : "cascadespiritstastingroombottleshop",
      "merchantHandle" : "cascadespiritstastingroombottleshop",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Cascade Spirits Tasting Room & Bottle Shop",
      "city" : "Portland",
      "companyName" : "Cascade Spirits Tasting Room & Bottle Shop",
      "websiteUrl" : "http:\/\/www.cascadestreetdistillery.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "habanacubarestaurant" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(408) 998-2822",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/7az23aO8HTMI1v3r1qHXBEpx5oRazaj3.png",
      "instagramHandle" : "habanacubarestaurant",
      "premium" : false,
      "merchantHandle" : "habanacubarestaurant",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Habana Cuba Restaurant",
      "city" : "San Jose",
      "companyName" : "Habana Cuba Restaurant",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/998cuba.com\/",
      "email" : ""
    }
  },
  "voodoosworldofoddtiques" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(517) 862-7564",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/yy8a8N54GUUwP1t7vIu7lESuOO0wTpYw.png",
      "instagramHandle" : "voodoosworldofoddtiques",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "voodoosworldofoddtiques",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Voodoo's World of Oddtiques",
      "city" : "Lansing",
      "companyName" : "Voodoo's World of Oddtiques",
      "websiteUrl" : "https:\/\/www.screamcraftstudio.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "bluebowlsuperfoods" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(657) 281-9089",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/rC77b4RXZnhJVZv3dUwzIhrYNntVkoO6.png",
      "instagramHandle" : "bluebowlsuperfoods",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "bluebowlsuperfoods",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Blue Bowl Superfoods",
      "city" : "Santa Ana",
      "companyName" : "Blue Bowl Superfoods",
      "websiteUrl" : "http:\/\/www.mybluebowl.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "ambitiousales" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(562) 285-7199",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/bFijhtSOdmj8SzZW3xaxcRYheW5bl2R4.png",
      "instagramHandle" : "ambitiousales",
      "premium" : false,
      "merchantHandle" : "ambitiousales",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Ambitious Ales",
      "city" : "Long Beach",
      "companyName" : "Ambitious Ales",
      "websiteUrl" : "https:\/\/www.ambitiousales.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "bobaguyscostamesa" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/iXf5pdtCLQ7y9S5sv1jL7OY21ym5LzrO.png",
      "instagramHandle" : "bobaguyscostamesa",
      "premium" : false,
      "merchantHandle" : "bobaguyscostamesa",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Boba Guys Costa Mesa",
      "city" : "Santa Ana",
      "companyName" : "Boba Guys Costa Mesa",
      "websiteUrl" : "https:\/\/boba-guys.square.site\/?location=11ec05347de0f2eb84fc0cc47a2ae330",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sacramentorivercruise" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(916) 800-7887",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/SdWsSlLdi0wpQstUc77FT6kLDturHYJN.png",
      "instagramHandle" : "sacramentorivercruise",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "sacramentorivercruise",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Sacramento River Cruise",
      "city" : "Sacramento",
      "companyName" : "Sacramento River Cruise",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.sacrivercruise.com\/",
      "email" : ""
    }
  },
  "redbarnliquormarket" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 487-1688",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/IWH3vGv4s5pmDMFY5edebp7uqRLXsQ0W.png",
      "instagramHandle" : "redbarnliquormarket",
      "premium" : false,
      "merchantHandle" : "redbarnliquormarket",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Red Barn Liquor Market",
      "city" : "Oxnard",
      "companyName" : "Red Barn Liquor Market",
      "websiteUrl" : "https:\/\/www.instagram.com\/redbarnliquormarket\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "communitypint" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(509) 413-1672",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/1955b2e5c3f927703e76e0852e79eb3b61a933b2\/original.jpeg",
      "instagramHandle" : "communitypint",
      "twitterHandle" : "",
      "merchantHandle" : "communitypint",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Community Pint",
      "city" : "Spokane",
      "companyName" : "Community Pint",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.communitypintspokane.com\/",
      "email" : ""
    }
  },
  "olivewoodpizza" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(562) 522-4445",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/LEfdq3mDccrO2EM09hkjsV6nPxlDlaQ8.png",
      "instagramHandle" : "olivewoodpizza",
      "twitterHandle" : "",
      "merchantHandle" : "olivewoodpizza",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Olive Wood Pizza",
      "city" : "Long Beach",
      "companyName" : "Olive Wood Pizza",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.olivewoodpizza.com\/",
      "email" : ""
    }
  },
  "saintsandsinnersbakeshop" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(562) 676-4090",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/LDUjGv8FCEwvZBIxi9otB4CuzFK4BacF.png",
      "instagramHandle" : "saintsandsinnersbakeshop",
      "merchantHandle" : "saintsandsinnersbakeshop",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Saints and Sinners Bakeshop",
      "city" : "Long Beach",
      "companyName" : "Saints and Sinners Bakeshop",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.saintsandsinnersbakeshop.com\/",
      "email" : ""
    }
  },
  "falafelboy" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(510) 817-4863",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/YYhSeKwzydLMM33ZiR8diFfcmqOsTP9w.png",
      "instagramHandle" : "falafelboy",
      "premium" : false,
      "merchantHandle" : "falafelboy",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Falafel Boy",
      "city" : "Oakland",
      "companyName" : "Falafel Boy",
      "websiteUrl" : "http:\/\/www.falafelboyonline.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "lacostacafe" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/IKGWoqFxGIYHta5Yq1RKASDAUrdx02cD.png",
      "instagramHandle" : "lacostacafe",
      "merchantHandle" : "lacostacafe",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "La Costa Cafe",
      "city" : "Sacramento",
      "companyName" : "La Costa Cafe",
      "websiteUrl" : "https:\/\/www.lacostacafesac.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "jacqndianesjuicebox" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(248) 369-8034",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/d4aa5259e83970f69c6b93d3139a357c7745f9d7\/original.jpeg",
      "instagramHandle" : "jacqndianesjuicebox",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "jacqndianesjuicebox",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Jacq n Diane's Juice Box",
      "city" : "Flint",
      "companyName" : "Jacq n Diane's Juice Box",
      "websiteUrl" : "http:\/\/www.jacqndianesjuicebox.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "rebeccasfamilyrestauranticecreamparlor" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(248) 348-2660",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/aaFcoTLXeQ001mX3gt4sR0vtkwNrlHdh.png",
      "instagramHandle" : "rebeccasfamilyrestauranticecreamparlor",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "rebeccasfamilyrestauranticecreamparlor",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Rebecca's Family Restaurant & Ice Cream Parlor",
      "city" : "Ann Arbor",
      "companyName" : "Rebecca's Family Restaurant & Ice Cream Parlor",
      "websiteUrl" : "http:\/\/rebeccasnorthville.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "coopersfoodanddrink" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(253) 503-0329",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ro0GSoRMaCUZHWpa08e2EFxrVxZ1waYa.png",
      "instagramHandle" : "coopersfoodanddrink",
      "merchantHandle" : "coopersfoodanddrink",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Cooper's Food and Drink",
      "city" : "Tacoma",
      "companyName" : "Cooper's Food and Drink",
      "websiteUrl" : "https:\/\/www.cooperstacoma.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "malloryonielmd" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "",
      "phone" : "",
      "merchantImage" : "malloryonielmd",
      "instagramHandle" : " MD\"",
      "merchantHandle" : "malloryonielmd",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "(313) 745-9048",
      "merchantName" : "No Logo Image Found on Website",
      "city" : "",
      "companyName" : "\"Mallory O'Niel",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : "https:\/\/www.dmcmedicalgroup.com\/find-a-doctor\/detail\/mallory-o%27niel-1528357506"
    }
  },
  "dustyshideaway" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(253) 292-0106",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/CBNwcwfs3j80SJhyJy0fv47pJSDhF8Qz.png",
      "instagramHandle" : "dustyshideaway",
      "twitterHandle" : "",
      "merchantHandle" : "dustyshideaway",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Dusty’s Hideaway",
      "city" : "Tacoma",
      "companyName" : "Dusty’s Hideaway",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/dustyshideaway.com\/",
      "email" : ""
    }
  },
  "goodvibesnutrition" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(559) 360-9436",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/E6TwI3k5CqOUucuEOa8fxdI2Dfjxy0pu.png",
      "instagramHandle" : "goodvibesnutrition",
      "twitterHandle" : "",
      "merchantHandle" : "goodvibesnutrition",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Good Vibes Nutrition",
      "city" : "Fresno",
      "companyName" : "Good Vibes Nutrition",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "jamba" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 473-6300",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/HgwjCsUbymGQxDsudtKVxwgtR22BAESa.png",
      "instagramHandle" : "jamba",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "jamba",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Jamba",
      "city" : "San Bernardino",
      "companyName" : "Jamba",
      "websiteUrl" : "https:\/\/locations.jamba.com\/ca\/san-bernardino\/1725-northpark-boulevard-unit-2?utm_source=google&utm_medium=organic&utm_campaign=locations_partner",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "fingerlickinchickenfish" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(517) 528-3253",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/0a3922438273c3dd85624a9d5a82afe8c6b33bde\/original.jpeg",
      "instagramHandle" : "fingerlickinchickenfish",
      "merchantHandle" : "fingerlickinchickenfish",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Finger Lickin' Chicken & Fish",
      "city" : "Lansing",
      "companyName" : "Finger Lickin' Chicken & Fish",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.fingerlickinchickenfish.com\/",
      "email" : ""
    }
  },
  "simplytastytreats" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(510) 798-9530",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "simplytastytreats",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "simplytastytreats",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Simply Tasty Treats",
      "city" : "Stockton",
      "companyName" : "Simply Tasty Treats",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.simplytastytreats.com\/contact.html",
      "email" : ""
    }
  },
  "thecheneyplace" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(616) 304-6261",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/9aLrkBVkAq6GTEW98sdO8tOPZbVLph0H.png",
      "instagramHandle" : "thecheneyplace",
      "twitterHandle" : "",
      "merchantHandle" : "thecheneyplace",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Cheney Place",
      "city" : "Grand Rapids",
      "companyName" : "The Cheney Place",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.thecheneyplace.com\/",
      "email" : ""
    }
  },
  "metrobytmobile" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(734) 418-9324",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/64795731c7ed92849c690e391cfa152c6da72dea\/original.jpeg",
      "instagramHandle" : "metrobytmobile",
      "premium" : false,
      "merchantHandle" : "metrobytmobile",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Metro by T-Mobile",
      "city" : "Ann Arbor",
      "companyName" : "Metro by T-Mobile",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.metrobyt-mobile.com\/stores\/bd\/metro-by-t-mobile-ann-arbor-mi-10329240\/?utm_source=google&utm_medium=lastmile&utm_campaign=10329240",
      "email" : ""
    }
  },
  "mistacones" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(503) 444-7972",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/WhP1b3jAlebb5vM9mOEEa9544zcv1OHy.jpeg",
      "instagramHandle" : "mistacones",
      "twitterHandle" : "",
      "merchantHandle" : "mistacones",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Mis Tacones",
      "city" : "Vancouver",
      "companyName" : "Mis Tacones",
      "websiteUrl" : "https:\/\/linktr.ee\/mistaconespdx",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "brothascookies" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/gXCfckUze42j0LGasPq4BY2gthicXPDn.png",
      "instagramHandle" : "brothascookies",
      "twitterHandle" : "",
      "merchantHandle" : "brothascookies",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Brothas Cookies",
      "city" : "Santa Ana",
      "companyName" : "Brothas Cookies",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/brothascookies.com\/",
      "email" : ""
    }
  },
  "bibosnypizza" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(408) 217-9084",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/81c274db4c61e37d93d6697ff734a3c6\/original.jpeg",
      "instagramHandle" : "bibosnypizza",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "bibosnypizza",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Bibo's NY Pizza",
      "city" : "San Jose",
      "companyName" : "Bibo's NY Pizza",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.bibosnypizza.com\/",
      "email" : ""
    }
  },
  "daintyinkstudio" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/meaFOEhm9PB92RjsiOsMY75hMGcU6ERi.png",
      "instagramHandle" : "daintyinkstudio",
      "twitterHandle" : "",
      "merchantHandle" : "daintyinkstudio",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Dainty Ink Studio",
      "city" : "Santa Ana",
      "companyName" : "Dainty Ink Studio",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/daintyinkstudioandacademy.com\/",
      "email" : ""
    }
  },
  "cozogrill" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(503) 894-6877",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "cozogrill",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "cozogrill",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Cozo Grill",
      "city" : "Vancouver",
      "companyName" : "Cozo Grill",
      "websiteUrl" : "http:\/\/cozogrillpdx.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "pulponice" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 698-9873",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "pulponice",
      "merchantHandle" : "pulponice",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Pulp on Ice",
      "city" : "Santa Ana",
      "companyName" : "Pulp on Ice",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.pulponice.com\/",
      "email" : ""
    }
  },
  "cafexbyanybeansnecessary" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(619) 537-6632",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Y0wJ7Mdg9QWWR7B4hbcja27uWgamQPfa.png",
      "instagramHandle" : "cafexbyanybeansnecessary",
      "merchantHandle" : "cafexbyanybeansnecessary",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Cafe X: By Any Beans Necessary",
      "city" : "Chula Vista",
      "companyName" : "Cafe X: By Any Beans Necessary",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.byanybeans.com\/",
      "email" : ""
    }
  },
  "nutritionannarbor" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(248) 291-9260",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/oXTxuA0zBvxOWlU8F72TBGpSeFrTZdYp.png",
      "instagramHandle" : "nutritionannarbor",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "nutritionannarbor",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "7.7 Nutrition Ann Arbor",
      "city" : "Ann Arbor",
      "companyName" : "7.7 Nutrition Ann Arbor",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/77diet.com\/",
      "email" : ""
    }
  },
  "rollinfreshglisan" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(971) 386-2110",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/vpHUlaDWxeZafYaa2I40NbYBFrYJF9GU.png",
      "instagramHandle" : "rollinfreshglisan",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "rollinfreshglisan",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Rollin Fresh- Glisan",
      "city" : "Portland",
      "companyName" : "Rollin Fresh- Glisan",
      "websiteUrl" : "http:\/\/www.rollinfreshnw.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "matadorspizzaandtakeout" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(810) 266-5034",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/29b53d02c85eba4764a81e62878144b3\/original.jpeg",
      "instagramHandle" : "matadorspizzaandtakeout",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "matadorspizzaandtakeout",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Matador's Pizza and Take Out",
      "city" : "Flint",
      "companyName" : "Matador's Pizza and Take Out",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.matadorspizza.com\/",
      "email" : ""
    }
  },
  "xpressocoffeeco" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(989) 721-0227",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/e747bcdd0aa67a143153d70fdacf2438d8767ccb\/original.jpeg",
      "instagramHandle" : "xpressocoffeeco",
      "premium" : false,
      "merchantHandle" : "xpressocoffeeco",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Xpress-O Coffee Co.",
      "city" : "Flint",
      "companyName" : "Xpress-O Coffee Co.",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thescoop" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(509) 535-7171",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/bn16qydhrEZWO9aqLK7Hsv4boNKALP6g.png",
      "instagramHandle" : "thescoop",
      "merchantHandle" : "thescoop",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Scoop",
      "city" : "Spokane",
      "companyName" : "The Scoop",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.thescoopspokane.com\/",
      "email" : ""
    }
  },
  "thewickedwolf" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(562) 542-2992",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/zti1Qi0p0aBrV2W0vKxcNthI1YrKmuEj.png",
      "instagramHandle" : "thewickedwolf",
      "merchantHandle" : "thewickedwolf",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Wicked Wolf",
      "city" : "Long Beach",
      "companyName" : "The Wicked Wolf",
      "websiteUrl" : "http:\/\/www.thewickedwolflb.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "julioempanadakitchen" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 698-9535",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/zaqnPdvUpCv5p1klvUPi84n0i8CPthfw.png",
      "instagramHandle" : "julioempanadakitchen",
      "premium" : false,
      "merchantHandle" : "julioempanadakitchen",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "9 Julio Empanada Kitchen",
      "city" : "Oakland",
      "companyName" : "9 Julio Empanada Kitchen",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.9julio.com\/",
      "email" : ""
    }
  },
  "lakearrowheadbrewingcompany" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 744-8322",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/LOplsovFS83MO6K9CkXX3egcIqdzjnvn.png",
      "instagramHandle" : "lakearrowheadbrewingcompany",
      "premium" : false,
      "merchantHandle" : "lakearrowheadbrewingcompany",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Lake Arrowhead Brewing Company",
      "city" : "San Bernardino",
      "companyName" : "Lake Arrowhead Brewing Company",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/lakearrowheadbrewingcompany.com\/",
      "email" : ""
    }
  },
  "thestouthousebrewery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 493-7158",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/2dbyuKKpfdaOEamY68AbYGTOJ98532RD.png",
      "instagramHandle" : "thestouthousebrewery",
      "twitterHandle" : "",
      "merchantHandle" : "thestouthousebrewery",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Stout House Brewery",
      "city" : "San Bernardino",
      "companyName" : "The Stout House Brewery",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.the-stout-house.com\/",
      "email" : ""
    }
  },
  "cxffeeblack" : {
    "analytics" : {
      "records" : {
        "landing-page" : {
          "6UFmLvVr" : {
            "pathname" : "\/cxffeeblack",
            "time" : "2024-05-24T00:40:28.296Z",
            "merchantHandle" : "cxffeeblack",
            "host" : "cafe.bio",
            "timezone" : "America\/Los_Angeles",
            "userAgent" : "Mozilla\/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/124.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "eoAPgzVx" : {
            "pathname" : "\/cxffeeblack",
            "time" : "2024-04-29T01:18:47.365Z",
            "merchantHandle" : "cxffeeblack",
            "host" : "cafe.bio",
            "timezone" : "America\/Chicago",
            "userAgent" : "Mozilla\/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit\/605.1.15 (KHTML, like Gecko) Mobile\/21E236 Instagram 328.1.3.32.89 (iPhone15,3; iOS 17_4_1; en_US; en; scale=3.00; 1290x2796; 591155251)",
            "page" : "landing-page\/"
          }
        }
      }
    }
  },
  "ulcvegan" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 418-0658",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "ulcvegan",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "ulcvegan",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Âu Lạc Vegan",
      "city" : "Santa Ana",
      "companyName" : "Âu Lạc Vegan",
      "websiteUrl" : "http:\/\/www.aulac.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "leafncream" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 200-8453",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/EWjMzF93gE0KkItnjOc2ORuJeLHoQaC7.png",
      "instagramHandle" : "leafncream",
      "twitterHandle" : "",
      "merchantHandle" : "leafncream",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Leaf n Cream",
      "city" : "Santa Ana",
      "companyName" : "Leaf n Cream",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "lulubellescoffeehouseandbakery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 939-0306",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "lulubellescoffeehouseandbakery",
      "twitterHandle" : "",
      "merchantHandle" : "lulubellescoffeehouseandbakery",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "LuluBelle's Coffee House and Bakery",
      "city" : "San Bernardino",
      "companyName" : "LuluBelle's Coffee House and Bakery",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.lulubellesmountainbakery.com\/",
      "email" : ""
    }
  },
  "teazerworldteamarkettowerdistrict" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(559) 442-4207",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/bee671536c825f855347be2a0638512d\/original.jpeg",
      "instagramHandle" : "teazerworldteamarkettowerdistrict",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "teazerworldteamarkettowerdistrict",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Teazer World Tea Market--Tower District",
      "city" : "Fresno",
      "companyName" : "Teazer World Tea Market--Tower District",
      "websiteUrl" : "http:\/\/www.gotteazer.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "satchelsbbq" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(734) 971-5100",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/0880c758294c07e618b28c7aac955dfdc2d89b7d\/original.jpeg",
      "instagramHandle" : "satchelsbbq",
      "merchantHandle" : "satchelsbbq",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Satchel's BBQ",
      "city" : "Ann Arbor",
      "companyName" : "Satchel's BBQ",
      "websiteUrl" : "http:\/\/satchelsbbq.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "twistedburgerdiner" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(810) 820-2999",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/cc6efc0ffa1e5284bf990659660f9d6135340709\/original.jpeg",
      "instagramHandle" : "twistedburgerdiner",
      "merchantHandle" : "twistedburgerdiner",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Twisted Burger Diner",
      "city" : "Flint",
      "companyName" : "Twisted Burger Diner",
      "websiteUrl" : "http:\/\/twistedburgerdiner.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "onnurichurch" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(949) 261-9100",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "onnurichurch",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "onnurichurch",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Onnuri Church",
      "city" : "Santa Ana",
      "companyName" : "Onnuri Church",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/irv.onnuri.or.kr\/",
      "email" : ""
    }
  },
  "lalunesucrepastryshopsanjoseca" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(408) 292-2070",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/18b54a9a6c65e7fd6e5d80862f6239475c9dd3b0\/original.jpeg",
      "instagramHandle" : "lalunesucrepastryshopsanjoseca",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "lalunesucrepastryshopsanjoseca",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "La Lune Sucrée Pastry Shop San Jose CA",
      "city" : "San Jose",
      "companyName" : "La Lune Sucrée Pastry Shop San Jose CA",
      "websiteUrl" : "http:\/\/lalunesucree.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "scratchbreakfast" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(360) 713-8762",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/r07aBOl1vxPNsMy1IqdcoFSTfVb76aXg.png",
      "instagramHandle" : "scratchbreakfast",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "scratchbreakfast",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Scratch Breakfast",
      "city" : "Vancouver",
      "companyName" : "Scratch Breakfast",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "spokanesaladdelivery" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(509) 557-7378",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/QY7BhZAjzZMsAA6OMpPselSyItfaXi41.png",
      "instagramHandle" : "spokanesaladdelivery",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "spokanesaladdelivery",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Spokane Salad Delivery",
      "city" : "Spokane",
      "companyName" : "Spokane Salad Delivery",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "flourchildbakery" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(517) 622-4772",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/m4OikxTAEjGAuH6gE3bXJBSl6a8VWf4B.png",
      "instagramHandle" : "flourchildbakery",
      "premium" : false,
      "merchantHandle" : "flourchildbakery",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Flour Child Bakery",
      "city" : "Lansing",
      "companyName" : "Flour Child Bakery",
      "websiteUrl" : "https:\/\/flourchildbakeryandcafe.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "savvysliders" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(586) 551-7777",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/b27193d52895d8d465066ca3c3c224fbb948beff\/original.jpeg",
      "instagramHandle" : "savvysliders",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "savvysliders",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Savvy Sliders",
      "city" : "Sterling Heights",
      "companyName" : "Savvy Sliders",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.savvysliders.com\/locations\/store-211-sterling-heights_15-mile-15-mile-ryan\/",
      "email" : ""
    }
  },
  "holycannolis" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(248) 804-1409",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/nX1hMc0yQFBgmk3jOcFFsNqpFPyWRbJv.png",
      "instagramHandle" : "holycannolis",
      "merchantHandle" : "holycannolis",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Holy Cannoli's",
      "city" : "Warren",
      "companyName" : "Holy Cannoli's",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "mattarellocookingcucinaitaliana" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(858) 717-0173",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ess4NHdt8fbO0GYpMHSmokU9LxEHx1YS.png",
      "instagramHandle" : "mattarellocookingcucinaitaliana",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "mattarellocookingcucinaitaliana",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Mattarello Cooking - Cucina Italiana",
      "city" : "Chula Vista",
      "companyName" : "Mattarello Cooking - Cucina Italiana",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.mattarellocooking.com\/",
      "email" : ""
    }
  },
  "jwgreenhouses" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 875-7966",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/PLjhDc3uKcbOV1Ly2KmenBsw6kqnQOy7.png",
      "instagramHandle" : "jwgreenhouses",
      "premium" : false,
      "merchantHandle" : "jwgreenhouses",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "JW Greenhouses",
      "city" : "Grand Rapids",
      "companyName" : "JW Greenhouses",
      "websiteUrl" : "http:\/\/jwgreenhouse.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "askerikitcomputerservices" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(541) 359-3111",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/5e32e1f34573c42b83e1bfdcd997a497de3ca828\/original.jpeg",
      "instagramHandle" : "askerikitcomputerservices",
      "premium" : false,
      "merchantHandle" : "askerikitcomputerservices",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Ask Erik IT & Computer Services",
      "city" : "Eugene",
      "companyName" : "Ask Erik IT & Computer Services",
      "websiteUrl" : "https:\/\/askerik.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "s27alehousebrewery" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/YmPqNNsvm6otq8UDRNsTjmW7YTVbn7o4.png",
      "instagramHandle" : "s27alehousebrewery",
      "merchantHandle" : "s27alehousebrewery",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "S27 Alehouse & Brewery",
      "city" : "San Jose",
      "companyName" : "S27 Alehouse & Brewery",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.s27ales.com\/",
      "email" : ""
    }
  },
  "hydrationroomhuntingtonbeachinsidelafitness" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(657) 845-7953",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "hydrationroomhuntingtonbeachinsidelafitness",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "hydrationroomhuntingtonbeachinsidelafitness",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Hydration Room | Huntington Beach (Inside LA Fitness)",
      "city" : "Huntington Beach",
      "companyName" : "Hydration Room | Huntington Beach (Inside LA Fitness)",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.hydrationroom.com\/",
      "email" : ""
    }
  },
  "tamcafe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(253) 272-4258 ext. 3023",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/62e6388b1acce243be70e0e727e8d98d\/original.jpeg",
      "instagramHandle" : "tamcafe",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "tamcafe",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "TAM Cafe",
      "city" : "Tacoma",
      "companyName" : "TAM Cafe",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.tacomaartmuseum.org\/visit\/tam-cafe\/",
      "email" : ""
    }
  },
  "ironhorsecoffeeshop" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 404-2877",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/PaaSbqoqLO1vkJi8BFWghu1GX2NoprVx.png",
      "instagramHandle" : "ironhorsecoffeeshop",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "ironhorsecoffeeshop",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Iron Horse Coffee Shop",
      "city" : "San Francisco",
      "companyName" : "Iron Horse Coffee Shop",
      "websiteUrl" : "https:\/\/www.ironhorsesf.com\/coffee\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "harborsidemarket" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(360) 373-6095",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "harborsidemarket",
      "premium" : false,
      "merchantHandle" : "harborsidemarket",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Harborside Market",
      "city" : "Tacoma",
      "companyName" : "Harborside Market",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.facebook.com\/BremertonHarborsideMarket\/",
      "email" : ""
    }
  },
  "brosdoughsfuller" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 608-6136",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/shaRzKe3g9NMfGGjBIlnnZhefRdyH42j.png",
      "instagramHandle" : "brosdoughsfuller",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "brosdoughsfuller",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Bro's Doughs Fuller",
      "city" : "Grand Rapids",
      "companyName" : "Bro's Doughs Fuller",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.brosdoughs.com\/",
      "email" : ""
    }
  },
  "kisscoffee" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/N6TQvO03aaJiro9T3Hc96Omy3ldaCzjH.png",
      "instagramHandle" : "kisscoffee",
      "twitterHandle" : "",
      "merchantHandle" : "kisscoffee",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "KISS Coffee",
      "city" : "Vancouver",
      "companyName" : "KISS Coffee",
      "websiteUrl" : "http:\/\/kisscoffeepdx.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "deliciosocatering" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 207-1803",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "deliciosocatering",
      "premium" : false,
      "merchantHandle" : "deliciosocatering",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Delicioso Catering",
      "city" : "Stockton",
      "companyName" : "Delicioso Catering",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/delicioso-catering.com\/",
      "email" : ""
    }
  },
  "bobabaranddesserts" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(619) 717-9560",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/7d9d124113073e80698a2a09a0328b5b77dfde18\/original.jpeg",
      "instagramHandle" : "bobabaranddesserts",
      "premium" : false,
      "merchantHandle" : "bobabaranddesserts",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Boba Bar and Desserts",
      "city" : "Chula Vista",
      "companyName" : "Boba Bar and Desserts",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "mariscosmelgoza" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 243-1894",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/5c813a0eccc815e8ffde2423e9fe471ce4093fb1\/original.jpeg",
      "instagramHandle" : "mariscosmelgoza",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "mariscosmelgoza",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Mariscos Melgoza",
      "city" : "Moreno Valley",
      "companyName" : "Mariscos Melgoza",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "gioiapizzeria" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 872-9427",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/adpfvWazomDJV8Q9glh4UUsTwITt4bAM.png",
      "instagramHandle" : "gioiapizzeria",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "gioiapizzeria",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Gioia Pizzeria",
      "city" : "San Francisco",
      "companyName" : "Gioia Pizzeria",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.gioiapizzeria.com\/",
      "email" : ""
    }
  },
  "rgddesigngroupinc" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 972-8182",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ci7o2yUjvL3pB8GWFzizMatqCCrCliUv.png",
      "instagramHandle" : "rgddesigngroupinc",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "rgddesigngroupinc",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "RG&D Design Group Inc",
      "city" : "Santa Ana",
      "companyName" : "RG&D Design Group Inc",
      "websiteUrl" : "http:\/\/rgd-design.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "kitefinteassundries" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(424) 625-8695",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/4205f3fc88ea662290df0c2bc4b321ff85b898d2\/original.png",
      "instagramHandle" : "kitefinteassundries",
      "merchantHandle" : "kitefinteassundries",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Kitefin Teas & Sundries",
      "city" : "Oxnard",
      "companyName" : "Kitefin Teas & Sundries",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/kitefinteas.com\/",
      "email" : ""
    }
  },
  "inspiredbytaste" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 253-0549",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/pzyU4xRivIcuB0CE1YRw4n70RptyjBnf.png",
      "instagramHandle" : "inspiredbytaste",
      "merchantHandle" : "inspiredbytaste",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Inspired by Taste",
      "city" : "Stockton",
      "companyName" : "Inspired by Taste",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/thevaultripon.com\/",
      "email" : ""
    }
  },
  "thestackscoffeehouse" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(503) 384-2324",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/9e5e763696f6b3a4547417d8f6007b3c5b269a7a\/original.png",
      "instagramHandle" : "thestackscoffeehouse",
      "merchantHandle" : "thestackscoffeehouse",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Stacks Coffeehouse",
      "city" : "Vancouver",
      "companyName" : "The Stacks Coffeehouse",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.thestackscoffeehouse.com\/",
      "email" : ""
    }
  },
  "bobaloca" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(818) 842-0406",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/b7a0231d4bffdeff96f7a092d139c9b8f36b3add\/original.jpeg",
      "instagramHandle" : "bobaloca",
      "twitterHandle" : "",
      "merchantHandle" : "bobaloca",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Boba Loca",
      "city" : "Glendale",
      "companyName" : "Boba Loca",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "melaniemartinez" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(951) 441-7549",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/R0dexarC2LabzFWk6sobEWFcA6P3DG7k.png",
      "instagramHandle" : "melaniemartinez",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "melaniemartinez",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Melanie Martinez",
      "city" : "Riverside",
      "companyName" : "Melanie Martinez",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "ledixseptptisserie" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/U0JheaUURuunAw0Da5Sfb4Hk1Df5GDR9.png",
      "instagramHandle" : "ledixseptptisserie",
      "premium" : false,
      "merchantHandle" : "ledixseptptisserie",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Le Dix-Sept Pâtisserie",
      "city" : "San Francisco",
      "companyName" : "Le Dix-Sept Pâtisserie",
      "websiteUrl" : "https:\/\/ledixsept.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "extractocoffeeroasters" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(503) 281-1764",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/620ca27bd66559371a925dcf860c6594\/original.jpeg",
      "instagramHandle" : "extractocoffeeroasters",
      "premium" : false,
      "merchantHandle" : "extractocoffeeroasters",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Extracto Coffee Roasters",
      "city" : "Vancouver",
      "companyName" : "Extracto Coffee Roasters",
      "websiteUrl" : "http:\/\/extractocoffee.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "ezkebabilebanesestreetfood" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 714-0595",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/332069db479e367a6ee5bc360a32180c3e70a09d\/original.jpeg",
      "instagramHandle" : "ezkebabilebanesestreetfood",
      "merchantHandle" : "ezkebabilebanesestreetfood",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "EZ Kebab I Lebanese Street Food",
      "city" : "Santa Ana",
      "companyName" : "EZ Kebab I Lebanese Street Food",
      "websiteUrl" : "http:\/\/www.ezkebab.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "dukescafe" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(541) 419-4041",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/cdec5222e3124f277ff2a27e74ee49d32767a93c\/original.jpeg",
      "instagramHandle" : "dukescafe",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "dukescafe",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Duke's Cafe",
      "city" : "Eugene",
      "companyName" : "Duke's Cafe",
      "websiteUrl" : "http:\/\/dukes-eugene.square.site\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "ketocandygirl" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 340-0700",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/KKPdyoRJiUfQf7IaiRuul8rfjB9Lepav.png",
      "instagramHandle" : "ketocandygirl",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "ketocandygirl",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Keto Candy Girl",
      "city" : "Santa Ana",
      "companyName" : "Keto Candy Girl",
      "websiteUrl" : "https:\/\/ketocandygirl.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "miaminice" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(971) 379-5197",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/AxDf2bgemWN00rvezTHewbWQTQqiS8Kk.png",
      "instagramHandle" : "miaminice",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "miaminice",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Miami Nice",
      "city" : "Portland",
      "companyName" : "Miami Nice",
      "websiteUrl" : "https:\/\/www.miaminicepdx.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "teabazaar" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(619) 310-6516",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/2d3339036a790479476d0db16e3517cbc8030297\/original.jpeg",
      "instagramHandle" : "teabazaar",
      "merchantHandle" : "teabazaar",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Tea Bazaar",
      "city" : "Chula Vista",
      "companyName" : "Tea Bazaar",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/teabazaarca.com\/",
      "email" : ""
    }
  },
  "chazzanocoffeeroasters" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/p1SGEmJHhqk1e8ZPxG2Mxy0aTa1jZHps.png",
      "instagramHandle" : "chazzanocoffeeroasters",
      "premium" : false,
      "merchantHandle" : "chazzanocoffeeroasters",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Chazzano Coffee Roasters",
      "city" : "Warren",
      "companyName" : "Chazzano Coffee Roasters",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "thecoffeeoasistacomayouthsheltertheloft" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(253) 328-6127",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/e48127ae993d049b73807822c53083997ef1965b\/original.jpeg",
      "instagramHandle" : "thecoffeeoasistacomayouthsheltertheloft",
      "premium" : false,
      "merchantHandle" : "thecoffeeoasistacomayouthsheltertheloft",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Coffee Oasis - Tacoma Youth Shelter: The Loft",
      "city" : "Tacoma",
      "companyName" : "The Coffee Oasis - Tacoma Youth Shelter: The Loft",
      "websiteUrl" : "https:\/\/thecoffeeoasis.com\/tacomashelter",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "bobboinaturalgelato" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(858) 214-8240",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/YOHvHcu4Tew3ae7i2LYoF3MPUsQ0m17e.png",
      "instagramHandle" : "bobboinaturalgelato",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "bobboinaturalgelato",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Bobboi Natural Gelato",
      "city" : "Chula Vista",
      "companyName" : "Bobboi Natural Gelato",
      "websiteUrl" : "https:\/\/bobboi.com\/kettner-blvd\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "penelope" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 922-9571",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/HwqSghiNbtMjavi0soN0GTvSkRjINLIR.png",
      "instagramHandle" : "penelope",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "penelope",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Penelope",
      "city" : "Oakland",
      "companyName" : "Penelope",
      "websiteUrl" : "http:\/\/www.penelopeoakland.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "baderbeerwinesupply" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(360) 750-1551",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Qlaw90xUGNaVdvvjhPmOPTTTTzOFjGp0.png",
      "instagramHandle" : "baderbeerwinesupply",
      "merchantHandle" : "baderbeerwinesupply",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Bader Beer & Wine Supply",
      "city" : "Vancouver",
      "companyName" : "Bader Beer & Wine Supply",
      "websiteUrl" : "https:\/\/www.baderbrewing.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "hunt" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(509) 919-3748",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "hunt",
      "premium" : false,
      "merchantHandle" : "hunt",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Hunt",
      "city" : "Spokane",
      "companyName" : "Hunt",
      "websiteUrl" : "http:\/\/www.huntspokane.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "wildrabbitbakery" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(510) 766-2253",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/x2OFEb0a1Ssjfe0lhYSh0n7gM1VQxfsW.png",
      "instagramHandle" : "wildrabbitbakery",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "wildrabbitbakery",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Wild Rabbit Bakery",
      "city" : "Oakland",
      "companyName" : "Wild Rabbit Bakery",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "sweetbarbakery" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(510) 788-4997",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "sweetbarbakery",
      "twitterHandle" : "",
      "merchantHandle" : "sweetbarbakery",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sweet Bar Bakery",
      "city" : "Oakland",
      "companyName" : "Sweet Bar Bakery",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/sweetbarbakery.com\/",
      "email" : ""
    }
  },
  "mochinuteaglerock" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(323) 739-6600",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/RDvET1dgfEdW2bFst5t8YWgKsd806lg3.png",
      "instagramHandle" : "mochinuteaglerock",
      "premium" : false,
      "merchantHandle" : "mochinuteaglerock",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Mochinut - Eagle Rock",
      "city" : "Glendale",
      "companyName" : "Mochinut - Eagle Rock",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/mochinuteaglerock.square.site\/",
      "email" : ""
    }
  },
  "teaothers" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 864-4920",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/abHn1wKvMFk2iIRERa2OLS8oN3UdpslP.png",
      "instagramHandle" : "teaothers",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "teaothers",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Tea & Others",
      "city" : "San Francisco",
      "companyName" : "Tea & Others",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.teaandothers.com\/",
      "email" : ""
    }
  },
  "pressedjuicery" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(916) 497-0702",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "pressedjuicery",
      "merchantHandle" : "pressedjuicery",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Pressed Juicery",
      "city" : "Sacramento",
      "companyName" : "Pressed Juicery",
      "websiteUrl" : "https:\/\/pressed.com\/pages\/juice-bar-locations\/US\/CA\/Sacramento\/1710-R-St-Suite-170?utm_source=google_gmb&utm_medium=organic",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "topnotchnutrition" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(616) 951-1880",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "topnotchnutrition",
      "premium" : false,
      "merchantHandle" : "topnotchnutrition",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "TOP NOTCH NUTRITION",
      "city" : "Grand Rapids",
      "companyName" : "TOP NOTCH NUTRITION",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "jerseymikessubs" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(734) 761-1100",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/gIoNWXakfaPv0nG7K8Y0MrcMW650n6Ns.png",
      "instagramHandle" : "jerseymikessubs",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "jerseymikessubs",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Jersey Mike's Subs",
      "city" : "Ann Arbor",
      "companyName" : "Jersey Mike's Subs",
      "websiteUrl" : "https:\/\/www.jerseymikes.com\/31014\/ann-arbor-mi",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "cosmichaus" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(541) 203-0003",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/z9n7TDmCxz4PNb49ZGjHqsR6ZGVTeSTW.png",
      "instagramHandle" : "cosmichaus",
      "premium" : false,
      "merchantHandle" : "cosmichaus",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Cosmic Haus",
      "city" : "Eugene",
      "companyName" : "Cosmic Haus",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.cosmichaus.co\/",
      "email" : ""
    }
  },
  "luckytea" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(408) 320-4954",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/0EIsMv9Bbhu6mVXo6RF4W2IiKXAFYsUt.png",
      "instagramHandle" : "luckytea",
      "merchantHandle" : "luckytea",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Lucky Tea",
      "city" : "San Jose",
      "companyName" : "Lucky Tea",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.luckyteausa.com\/",
      "email" : ""
    }
  },
  "bikedogbrewingcompany" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 572-0788",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/KkfMfDvH1V0qKbHE7roWCm3Z9vLo19Vw.png",
      "instagramHandle" : "bikedogbrewingcompany",
      "twitterHandle" : "",
      "merchantHandle" : "bikedogbrewingcompany",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Bike Dog Brewing Company",
      "city" : "Sacramento",
      "companyName" : "Bike Dog Brewing Company",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/bikedogbrewing.com\/",
      "email" : ""
    }
  },
  "luckyscoffeeroasters" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 303-3690",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/873d579cb660d1e23a50e9f1a58a5dd2125e8ba4\/original.png",
      "instagramHandle" : "luckyscoffeeroasters",
      "twitterHandle" : "",
      "merchantHandle" : "luckyscoffeeroasters",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Lucky's Coffee Roasters",
      "city" : "San Bernardino",
      "companyName" : "Lucky's Coffee Roasters",
      "websiteUrl" : "http:\/\/www.luckys.coffee\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "redbarcafe" : {
    "analytics" : {
      "records" : {
        "landing-page" : {
          "6mgqbKgZ" : {
            "pathname" : "\/redbarcafe",
            "time" : "2024-06-27T00:09:45.919Z",
            "merchantHandle" : "redbarcafe",
            "host" : "localhost:3000",
            "timezone" : "America\/Los_Angeles",
            "userAgent" : "Mozilla\/5.0 (Linux; Android 6.0; Nexus 5 Build\/MRA58N) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/126.0.0.0 Mobile Safari\/537.36",
            "page" : "landing-page\/"
          },
          "2A96mtdi" : {
            "pathname" : "\/redbarcafe",
            "time" : "2024-06-27T00:09:45.914Z",
            "merchantHandle" : "redbarcafe",
            "host" : "localhost:3000",
            "timezone" : "America\/Los_Angeles",
            "userAgent" : "Mozilla\/5.0 (Linux; Android 6.0; Nexus 5 Build\/MRA58N) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/126.0.0.0 Mobile Safari\/537.36",
            "page" : "landing-page\/"
          }
        }
      }
    }
  },
  "bythewaygrill" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(661) 381-2222",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "bythewaygrill",
      "merchantHandle" : "bythewaygrill",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "By The Way Grill",
      "city" : "Bakersfield",
      "companyName" : "By The Way Grill",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "ozzysapizza" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(877) 386-8268",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "ozzysapizza",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "ozzysapizza",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Ozzy's Apizza",
      "city" : "Glendale",
      "companyName" : "Ozzy's Apizza",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/ozzysapizza.com\/",
      "email" : ""
    }
  },
  " Acai Bowls\"" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "(805) 667-8088",
      "phone" : "",
      "merchantImage" : "channelislandsjuicecohealthyrestaurantwithorganicveganvegetarianglutenfreecoffeeacaibowls",
      "instagramHandle" : " Coffee",
      "premium" : false,
      "merchantHandle" : " Acai Bowls\"",
      "squareProductionKey" : " Coffee",
      "twitterHandle" : "",
      "squareSandboxKey" : " Acai Bowls\"",
      "companyType" : "",
      "merchantName" : "channelislandsjuicecohealthyrestaurantwithorganicveganvegetarianglutenfreecoffeeacaibowls",
      "city" : "https:\/\/channelislandsjuice.com\/",
      "companyName" : "\"Channel Islands Juice Co. | Healthy Restaurant With Organic Vegan Vegetarian Gluten Free",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "bigdippericecream" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MT\r",
      "phone" : "(406) 534-2350",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/h6IlQsfABzADXQql2OwrG6K0pXdnX3FV.png",
      "instagramHandle" : "bigdippericecream",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "bigdippericecream",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Big Dipper Ice Cream",
      "city" : "Billings",
      "companyName" : "Big Dipper Ice Cream",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.bigdippericecream.com\/",
      "email" : ""
    }
  },
  "adoreboardsllc" : {
    "info" : {
      "state" : "",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "adoreboardsllc",
      "instagramHandle" : " LLC.\"",
      "premium" : false,
      "merchantHandle" : "adoreboardsllc",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "(909) 696-9449",
      "merchantName" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/D8rQ3Ulj8RJBkFaSeAOT5SNIc0UjPBhI.png",
      "city" : "",
      "companyName" : "\"Adore Boards",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : "http:\/\/adoreboardscharcuterie.com\/"
    }
  },
  "oaxacankitchenmobile" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(650) 321-8003",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/23qa8oEys15tinY30eSWp1X9I8Hqooy5.jpg",
      "instagramHandle" : "oaxacankitchenmobile",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "oaxacankitchenmobile",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Oaxacan Kitchen Mobile",
      "city" : "San Jose",
      "companyName" : "Oaxacan Kitchen Mobile",
      "websiteUrl" : "http:\/\/www.oaxacankitchenmobile.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "clearicecompany" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(408) 668-2420",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/JvNa84Oo3zltbeKbaNf0e5SaNP18Tro4.png",
      "instagramHandle" : "clearicecompany",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "clearicecompany",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Clear Ice Company",
      "city" : "San Jose",
      "companyName" : "Clear Ice Company",
      "websiteUrl" : "http:\/\/theclearicecompany.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "cvs" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 463-9255",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "cvs",
      "premium" : false,
      "merchantHandle" : "cvs",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "CVS",
      "city" : "Fontana",
      "companyName" : "CVS",
      "websiteUrl" : "https:\/\/www.cvs.com\/store-locator\/fontana-ca-pharmacies\/15286-summit-ave-fontana-ca-92336\/storeid=8589?WT.mc_id=LS_GOOGLE_FS_8589",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "crispcatering" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 552-7477",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "crispcatering",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "crispcatering",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Crisp Catering",
      "city" : "Sacramento",
      "companyName" : "Crisp Catering",
      "websiteUrl" : "https:\/\/crispcatering.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "eaglerockkitchen" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(323) 362-2263",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/8xEFZYBX42RgwpukcWQazn2vaGhXvXuH.png",
      "instagramHandle" : "eaglerockkitchen",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "eaglerockkitchen",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "EagleRock.Kitchen",
      "city" : "Glendale",
      "companyName" : "EagleRock.Kitchen",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.erkitchen.com\/",
      "email" : ""
    }
  },
  "kellyannesforkfryes" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(810) 394-5543",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "kellyannesforkfryes",
      "premium" : false,
      "merchantHandle" : "kellyannesforkfryes",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Kelly Anne’s Forkfryes",
      "city" : "Flint",
      "companyName" : "Kelly Anne’s Forkfryes",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "gallositaliandelicoronadelmar" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(949) 287-6030",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/aBH80BXjCxKJd8nRbh33TPiHBSaKDyGe.png",
      "instagramHandle" : "gallositaliandelicoronadelmar",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "gallositaliandelicoronadelmar",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Gallo's Italian Deli Corona Del Mar",
      "city" : "Irvine",
      "companyName" : "Gallo's Italian Deli Corona Del Mar",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.gallositaliansandwiches.com\/",
      "email" : ""
    }
  },
  "sproutsfarmersmarket" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 320-5360",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/pWwBkkfQWLbQahCe2L7VzMr30mRLyNjK.jpeg",
      "instagramHandle" : "sproutsfarmersmarket",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "sproutsfarmersmarket",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sprouts Farmers Market",
      "city" : "Fontana",
      "companyName" : "Sprouts Farmers Market",
      "websiteUrl" : "https:\/\/www.sprouts.com\/store\/ca\/fontana\/fontana\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "stirrednotshaken" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "",
      "phone" : "",
      "merchantImage" : "stirrednotshaken",
      "instagramHandle" : " Not Shaken\"",
      "premium" : false,
      "merchantHandle" : "stirrednotshaken",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "(415) 993-2007",
      "squareSandboxKey" : "",
      "merchantName" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/YICtXrgrHFY6fRXZNEkhx0FYmQyf48Mb.png",
      "city" : "",
      "companyName" : "\"Stirred",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : "http:\/\/stirredcookies.com\/"
    }
  },
  "devisdonutsandsweets" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(562) 343-5802",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/6ceac485800e2eb8410f10fef4c198c5f2d5842c\/original.jpeg",
      "instagramHandle" : "devisdonutsandsweets",
      "merchantHandle" : "devisdonutsandsweets",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Devi's Donuts and sweets",
      "city" : "Long Beach",
      "companyName" : "Devi's Donuts and sweets",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.devisdonuts.com\/",
      "email" : ""
    }
  },
  "littlechickpea" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/hnb893l4xwVdcgvvnefOYY26uMlItBuO.png",
      "instagramHandle" : "littlechickpea",
      "merchantHandle" : "littlechickpea",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Little Chickpea",
      "city" : "Portland",
      "companyName" : "Little Chickpea",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/littlechickpea.com\/",
      "email" : ""
    }
  },
  "crowncrumpetteasalon" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 771-4252",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/28j6MwplkGW7e59w0rWmWhAnN6DrMOP1.png",
      "instagramHandle" : "crowncrumpetteasalon",
      "twitterHandle" : "",
      "merchantHandle" : "crowncrumpetteasalon",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Crown & Crumpet Tea Salon",
      "city" : "San Francisco",
      "companyName" : "Crown & Crumpet Tea Salon",
      "websiteUrl" : "http:\/\/www.crownandcrumpet.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "oakshirepublichouse" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(541) 654-5520",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/WUrhRdEu2iknXmLCVJpIpslkbouuZxx7.png",
      "instagramHandle" : "oakshirepublichouse",
      "merchantHandle" : "oakshirepublichouse",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Oakshire Public House",
      "city" : "Eugene",
      "companyName" : "Oakshire Public House",
      "websiteUrl" : "http:\/\/www.oakbrew.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "motorcitychemicalllc" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "",
      "phone" : "",
      "merchantImage" : "motorcitychemicalllc",
      "instagramHandle" : " LLC\"",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "motorcitychemicalllc",
      "companyType" : "(586) 354-4991",
      "squareSandboxKey" : "",
      "merchantName" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/4Gl8ypZMHrYl1gaGRoTBggW5N25OfoTE.png",
      "city" : "",
      "companyName" : "\"Motor City Chemical",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : "https:\/\/www.motorcitychemicals.com\/"
    }
  },
  "juliewidzinski" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(586) 427-1000",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "juliewidzinski",
      "merchantHandle" : "juliewidzinski",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Julie Widzinski",
      "city" : "Warren",
      "companyName" : "Julie Widzinski",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "startmycollective" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 224-1440",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "startmycollective",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "startmycollective",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Start My Collective",
      "city" : "Riverside",
      "companyName" : "Start My Collective",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "rebelrosecateringbychefjohnenglish" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 337-2153",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/c71f8a9a17c9fc948c39449071aa815062e526e6\/original.png",
      "instagramHandle" : "rebelrosecateringbychefjohnenglish",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "rebelrosecateringbychefjohnenglish",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Rebel Rose Catering by Chef John English",
      "city" : "Santa Ana",
      "companyName" : "Rebel Rose Catering by Chef John English",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "noblewavelouisianakitchen" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(971) 304-7974",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/9dI4YZcjS1uQhNHTxbfBYm1SCQla5tIS.png",
      "instagramHandle" : "noblewavelouisianakitchen",
      "premium" : false,
      "merchantHandle" : "noblewavelouisianakitchen",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Noble Wave | Louisiana Kitchen",
      "city" : "Salem",
      "companyName" : "Noble Wave | Louisiana Kitchen",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/enjoynoblewave.com\/",
      "email" : ""
    }
  },
  "snowmonster" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 582-6023",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/znA2VK8bMKxH57EhRIXYJaGWNjUHYtIl.png",
      "instagramHandle" : "snowmonster",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "snowmonster",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Snow Monster",
      "city" : "Long Beach",
      "companyName" : "Snow Monster",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/minimonstercafe.square.site\/",
      "email" : ""
    }
  },
  "zenstreetizakayaramensushithai" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(951) 592-4196",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/G6lg6bhUMqKSblDZHbeSIXmb9zuNSuPi.png",
      "instagramHandle" : "zenstreetizakayaramensushithai",
      "merchantHandle" : "zenstreetizakayaramensushithai",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "ZENSTREET Izakaya : Ramen | Sushi | Thai",
      "city" : "Riverside",
      "companyName" : "ZENSTREET Izakaya : Ramen | Sushi | Thai",
      "websiteUrl" : "http:\/\/zen-street.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "kcjonesplatingcompanycorporateoffices" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(586) 755-4900",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "kcjonesplatingcompanycorporateoffices",
      "merchantHandle" : "kcjonesplatingcompanycorporateoffices",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "KC Jones Plating Company - Corporate Offices",
      "city" : "Warren",
      "companyName" : "KC Jones Plating Company - Corporate Offices",
      "websiteUrl" : "https:\/\/kcjplating.com\/?utm_source=google&utm_medium=organic&utm_campaign=gmb&utm_content=corporate",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "rioacabowls" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(661) 348-4159",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/f5aacc3354ff5b55958cbf51c5f6efe416758230\/original.jpeg",
      "instagramHandle" : "rioacabowls",
      "merchantHandle" : "rioacabowls",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Rio Acaí Bowls",
      "city" : "Bakersfield",
      "companyName" : "Rio Acaí Bowls",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/rioacaibowls.com\/",
      "email" : ""
    }
  },
  "valsveganbakeshopgoodgood" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(323) 570-0498",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/3e7092a065b3ae5bd5adc98fded7299cf7817b27\/original.png",
      "instagramHandle" : "valsveganbakeshopgoodgood",
      "premium" : false,
      "merchantHandle" : "valsveganbakeshopgoodgood",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Val’s Vegan Bake Shop (the good good)",
      "city" : "Glendale",
      "companyName" : "Val’s Vegan Bake Shop (the good good)",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.thegoodgoodla.com\/",
      "email" : ""
    }
  },
  "elephantcoroyaloak" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(313) 989-4454",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/0KvA92ptjjhzdDnabZsQiGDpe3a0TCdy.png",
      "instagramHandle" : "elephantcoroyaloak",
      "merchantHandle" : "elephantcoroyaloak",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Elephant & Co. Royal Oak",
      "city" : "Warren",
      "companyName" : "Elephant & Co. Royal Oak",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.elephantand.com\/",
      "email" : ""
    }
  },
  "baesfriedchicken" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(503) 954-1635",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/7eb343ca4ca255852f443c942bea9f90b9d6c351\/original.jpeg",
      "instagramHandle" : "baesfriedchicken",
      "merchantHandle" : "baesfriedchicken",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "BAES Fried Chicken",
      "city" : "Portland",
      "companyName" : "BAES Fried Chicken",
      "websiteUrl" : "http:\/\/www.baeschicken.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "elephantcodetroit" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(313) 989-4454",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/0KvA92ptjjhzdDnabZsQiGDpe3a0TCdy.png",
      "instagramHandle" : "elephantcodetroit",
      "premium" : false,
      "merchantHandle" : "elephantcodetroit",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Elephant & Co. Detroit",
      "city" : "Detroit",
      "companyName" : "Elephant & Co. Detroit",
      "websiteUrl" : "https:\/\/www.elephantand.com\/visit",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "shiromaru" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(248) 792-6015",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "shiromaru",
      "premium" : false,
      "merchantHandle" : "shiromaru",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Shiromaru",
      "city" : "Sterling Heights",
      "companyName" : "Shiromaru",
      "websiteUrl" : "http:\/\/www.shiromarutroy.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "grumpymonkeycookieco" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(509) 901-3176",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/PalbEuvI61meGqtfy6lola6yx008YSsz.png",
      "instagramHandle" : "grumpymonkeycookieco",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "grumpymonkeycookieco",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Grumpy Monkey Cookie CO.",
      "city" : "Tacoma",
      "companyName" : "Grumpy Monkey Cookie CO.",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.grumpymokeycookieco.com\/",
      "email" : ""
    }
  },
  "nekocafe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 299-8737",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/ef5d510616e8873f7278df80e070e8b1\/original.jpeg",
      "instagramHandle" : "nekocafe",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "nekocafe",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Neko Cafe",
      "city" : "Riverside",
      "companyName" : "Neko Cafe",
      "websiteUrl" : "https:\/\/mobile-order.yammii.com\/index.html?shop_name=neko-cafe",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "soyconchabakerysantaana" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 420-2464",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/n16eW0OaE24y9FmruxaqBFMMWqiXFbSy.png",
      "instagramHandle" : "soyconchabakerysantaana",
      "twitterHandle" : "",
      "merchantHandle" : "soyconchabakerysantaana",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Soy Concha Bakery - Santa Ana",
      "city" : "Santa Ana",
      "companyName" : "Soy Concha Bakery - Santa Ana",
      "websiteUrl" : "https:\/\/www.soyconchabakery.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "kanejuicetea" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 600-7272",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/HgwjCsUbymGQxDsudtKVxwgtR22BAESa.png",
      "instagramHandle" : "kanejuicetea",
      "merchantHandle" : "kanejuicetea",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Kane Juice & Tea",
      "city" : "Fontana",
      "companyName" : "Kane Juice & Tea",
      "websiteUrl" : "https:\/\/order.online\/store\/KaneJuiceTea-1959396\/en-US\/?hideModal=true&pickup=true",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "jurassiccart" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/mRr1gaUrpvnrgIpdDUwiZD453J2HtAIH.png",
      "instagramHandle" : "jurassiccart",
      "twitterHandle" : "",
      "merchantHandle" : "jurassiccart",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Jurassic Cart",
      "city" : "Portland",
      "companyName" : "Jurassic Cart",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "hammerburger" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(657) 218-9992",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/7plx0KWGv9f9SCW0rUQEuJg1g7WMshBI.png",
      "instagramHandle" : "hammerburger",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "hammerburger",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Hammer Burger",
      "city" : "Santa Ana",
      "companyName" : "Hammer Burger",
      "websiteUrl" : "https:\/\/www.hammerburger.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "butterfold" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 926-5561",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/NiXFDQGsp62aXAf6yLM7fF5uhpNhvkHK.png",
      "instagramHandle" : "butterfold",
      "merchantHandle" : "butterfold",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Butter & Fold",
      "city" : "Oxnard",
      "companyName" : "Butter & Fold",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/instagram.com\/butterandfold",
      "email" : ""
    }
  },
  "highlightcoffee" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(818) 484-8414",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/LaVGECH27bvTap1JHEMEhNOqqOa6V0J4.png",
      "instagramHandle" : "highlightcoffee",
      "twitterHandle" : "",
      "merchantHandle" : "highlightcoffee",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Highlight Coffee",
      "city" : "Glendale",
      "companyName" : "Highlight Coffee",
      "websiteUrl" : "http:\/\/www.highlightcoffee.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "philhouse" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(805) 488-9886",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "philhouse",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "philhouse",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "PhilHouse",
      "city" : "Oxnard",
      "companyName" : "PhilHouse",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "caesarsdeli" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(661) 588-7004",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/wdBJFCWwBSaz6j7MPB5S0ApQ29uR2EFx.png",
      "instagramHandle" : "caesarsdeli",
      "merchantHandle" : "caesarsdeli",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Caesar’s Deli",
      "city" : "Bakersfield",
      "companyName" : "Caesar’s Deli",
      "websiteUrl" : "http:\/\/www.caesarsdeli.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "yummstrawberrywestsacramento" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 897-6986",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "yummstrawberrywestsacramento",
      "merchantHandle" : "yummstrawberrywestsacramento",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Yumm Strawberry West Sacramento",
      "city" : "Sacramento",
      "companyName" : "Yumm Strawberry West Sacramento",
      "websiteUrl" : "http:\/\/facebook.com\/profile.php?id=100077449971480",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "redhillcoffeeshop" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 829-1591",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/8126f81d34f9bc030d2e131e4b145fe43a958a90\/original.png",
      "instagramHandle" : "redhillcoffeeshop",
      "premium" : false,
      "merchantHandle" : "redhillcoffeeshop",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Redhill Coffee Shop",
      "city" : "Fontana",
      "companyName" : "Redhill Coffee Shop",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.redhillcoffeeshop.com\/",
      "email" : ""
    }
  },
  "pizzadue" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 658-7525",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/WeW3CT2FDsxmqmuu7aY0JcZOcduBsM0b.png",
      "instagramHandle" : "pizzadue",
      "twitterHandle" : "",
      "merchantHandle" : "pizzadue",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Pizza Due",
      "city" : "San Francisco",
      "companyName" : "Pizza Due",
      "websiteUrl" : "http:\/\/pizza-due.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "juanpollo" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 821-3333",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/00v6TEgZfsndR0zkguDNuLUBLAyAupap.png",
      "instagramHandle" : "juanpollo",
      "twitterHandle" : "",
      "merchantHandle" : "juanpollo",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Juan Pollo",
      "city" : "Anaheim",
      "companyName" : "Juan Pollo",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/juanpollo.com\/locations\/anaheim\/",
      "email" : "Kyle \/ Kyleokura@gmail \/ Melanie \/ Melanie@juanpollo.com"
    }
  },
  "taphousespecialtymeatmarket" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(989) 720-6328",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/KK4SFtlMezymCbmqxxYIoXjbNxi3EjXV.png",
      "instagramHandle" : "taphousespecialtymeatmarket",
      "merchantHandle" : "taphousespecialtymeatmarket",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Taphouse Specialty Meat Market",
      "city" : "Flint",
      "companyName" : "Taphouse Specialty Meat Market",
      "websiteUrl" : "https:\/\/taphousemarket.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "cabakehouse" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(408) 287-9188",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/nufFbv7cUU41wZGVUZxJRGRbu0gHyJm3.png",
      "instagramHandle" : "cabakehouse",
      "merchantHandle" : "cabakehouse",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "CA Bakehouse",
      "city" : "San Jose",
      "companyName" : "CA Bakehouse",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.cabakehouse.com\/",
      "email" : ""
    }
  },
  "chandelierbakery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(360) 718-2175",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/vnuxAB0UDasvW5MIGCw2Uzrib7dFHevD.png",
      "instagramHandle" : "chandelierbakery",
      "merchantHandle" : "chandelierbakery",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Chandelier Bakery",
      "city" : "Vancouver",
      "companyName" : "Chandelier Bakery",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/chandelierbakery.net\/",
      "email" : ""
    }
  },
  "maplestreetbistro" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(509) 328-0473",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/f6ccfe5281b22b9b342547daa003f8f3873ed1a8\/original.jpeg",
      "instagramHandle" : "maplestreetbistro",
      "merchantHandle" : "maplestreetbistro",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Maple Street Bistro",
      "city" : "Spokane",
      "companyName" : "Maple Street Bistro",
      "websiteUrl" : "http:\/\/www.maplestreetbistro.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "ginkgoforestwinery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(253) 301-4372",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "ginkgoforestwinery",
      "premium" : false,
      "merchantHandle" : "ginkgoforestwinery",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Ginkgo Forest Winery",
      "city" : "Tacoma",
      "companyName" : "Ginkgo Forest Winery",
      "websiteUrl" : "http:\/\/www.ginkgowinery.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "oliverlemonsterrebonne" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(541) 548-2603",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/a128fa6422729a74b994fe1f2c6735cbbd24a2cf\/original.jpeg",
      "instagramHandle" : "oliverlemonsterrebonne",
      "premium" : false,
      "merchantHandle" : "oliverlemonsterrebonne",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Oliver Lemon's Terrebonne",
      "city" : "Fontana",
      "companyName" : "Oliver Lemon's Terrebonne",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.oliverlemons.com\/terrebonne",
      "email" : ""
    }
  },
  "sweetencounterbakerycafe" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(517) 730-5005",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/4efa456a858bf87ea3009e3f4a5f2165dee559b8\/original.jpeg",
      "instagramHandle" : "sweetencounterbakerycafe",
      "twitterHandle" : "",
      "merchantHandle" : "sweetencounterbakerycafe",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Sweet Encounter Bakery & Cafe",
      "city" : "Lansing",
      "companyName" : "Sweet Encounter Bakery & Cafe",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.sweetencounterbakery.com\/",
      "email" : ""
    }
  },
  "cidercedar" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "cidercedar",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "cidercedar",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Cider & Cedar",
      "city" : "Tacoma",
      "companyName" : "Cider & Cedar",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.ciderandcedar.com\/",
      "email" : ""
    }
  },
  "petuniaspiespastries" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(503) 841-5961",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Z0veNYYKvItNW34gNqDdCJPOvHfgoSf0.png",
      "instagramHandle" : "petuniaspiespastries",
      "twitterHandle" : "",
      "merchantHandle" : "petuniaspiespastries",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Petunia's Pies & Pastries",
      "city" : "Portland",
      "companyName" : "Petunia's Pies & Pastries",
      "websiteUrl" : "http:\/\/www.petuniaspiesandpastries.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "dickiesnoorclothing" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(619) 425-5222",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "dickiesnoorclothing",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "dickiesnoorclothing",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Dickies Noor Clothing",
      "city" : "Chula Vista",
      "companyName" : "Dickies Noor Clothing",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "bangkokstreetfood" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(313) 979-1601",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/73de39bca5da50998661e0d023b595989b820e59\/original.jpeg",
      "instagramHandle" : "bangkokstreetfood",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "bangkokstreetfood",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Bangkok 96 Street Food",
      "city" : "Detroit",
      "companyName" : "Bangkok 96 Street Food",
      "websiteUrl" : "https:\/\/www.b96streetfood.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "oodlesfrozenyogurtinc" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 523-6635",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/85d1426618571df40ca040af2a4ab8b3a99d78a7\/original.jpeg",
      "instagramHandle" : "oodlesfrozenyogurtinc",
      "twitterHandle" : "",
      "merchantHandle" : "oodlesfrozenyogurtinc",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Oodles Frozen Yogurt Inc.",
      "city" : "Stockton",
      "companyName" : "Oodles Frozen Yogurt Inc.",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/oodlesfrozenyogurt.com\/",
      "email" : ""
    }
  },
  "delishpizza" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 201-8888",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/sJxf0XTqgmzZlomGut90C594MLOZoFOO.png",
      "instagramHandle" : "delishpizza",
      "twitterHandle" : "",
      "merchantHandle" : "delishpizza",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Delish Pizza",
      "city" : "Sacramento",
      "companyName" : "Delish Pizza",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.delishpizza.com\/",
      "email" : ""
    }
  },
  "ichina" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(408) 352-5405",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/a45f97286e71f4047137d20bb8b478c3ea002927\/original.png",
      "instagramHandle" : "ichina",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "ichina",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "iCHiNA",
      "city" : "San Jose",
      "companyName" : "iCHiNA",
      "websiteUrl" : "http:\/\/www.ichinarestaurant.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "bbhpsychiatricservicessalem" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(512) 877-5752",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/bWA6Dpa410LR0CBFqPi0mOg2letHwhBx.png",
      "instagramHandle" : "bbhpsychiatricservicessalem",
      "merchantHandle" : "bbhpsychiatricservicessalem",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "BBH Psychiatric Services - Salem",
      "city" : "Salem",
      "companyName" : "BBH Psychiatric Services - Salem",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.bbhpsych.com\/",
      "email" : ""
    }
  },
  "slojoycoffeeroasters" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/f68bf26cd48b90d49641a078272b7e74fdf6583d\/original.png",
      "instagramHandle" : "slojoycoffeeroasters",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "slojoycoffeeroasters",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Slojoy Coffee Roasters",
      "city" : "Oakland",
      "companyName" : "Slojoy Coffee Roasters",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "smokngingersbbq" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(541) 321-8242",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/HezhZM8C0s0aEiaBgg1Vl9tY02RcPf1p.png",
      "instagramHandle" : "smokngingersbbq",
      "twitterHandle" : "",
      "merchantHandle" : "smokngingersbbq",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Smok'n Gingers BBQ",
      "city" : "Eugene",
      "companyName" : "Smok'n Gingers BBQ",
      "websiteUrl" : "http:\/\/smokngingers.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "foodecafecatering" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(360) 735-5927",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/fxEz2mHo0zLjJnMtHc9oVOQ5RQu6i41H.png",
      "instagramHandle" : "foodecafecatering",
      "premium" : false,
      "merchantHandle" : "foodecafecatering",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Foode Cafe & Catering",
      "city" : "Vancouver",
      "companyName" : "Foode Cafe & Catering",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.foodefresh.com\/",
      "email" : ""
    }
  },
  "jacksdonuts" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 371-0777",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/uO19mQWbUv0VDPXbJGwo895JjRivIMbg.png",
      "instagramHandle" : "jacksdonuts",
      "twitterHandle" : "",
      "merchantHandle" : "jacksdonuts",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Jack's Donuts",
      "city" : "Oxnard",
      "companyName" : "Jack's Donuts",
      "websiteUrl" : "http:\/\/www.jacksdonuts.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "ferndalenutritionherbalifenutritionclub" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(248) 955-9777",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/F9VDW0QeIWeYb8hpnPaVtWwlzpZ3OIFQ.png",
      "instagramHandle" : "ferndalenutritionherbalifenutritionclub",
      "merchantHandle" : "ferndalenutritionherbalifenutritionclub",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Ferndale Nutrition (Herbalife Nutrition Club)",
      "city" : "Warren",
      "companyName" : "Ferndale Nutrition (Herbalife Nutrition Club)",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "neilskicknitbbq" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 451-1235",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/fVOPT0XjwWTGZ03KukYSo9nq77Z8E32b.png",
      "instagramHandle" : "neilskicknitbbq",
      "twitterHandle" : "",
      "merchantHandle" : "neilskicknitbbq",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Neil´s Kick N-It Bbq",
      "city" : "Stockton",
      "companyName" : "Neil´s Kick N-It Bbq",
      "websiteUrl" : "https:\/\/neilsbbq.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "junebirdcoffeeco" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(248) 343-6793",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/bGSJJpM8YcGEpA8Dp9YffsBAZyCx3dWj.png",
      "instagramHandle" : "junebirdcoffeeco",
      "twitterHandle" : "",
      "merchantHandle" : "junebirdcoffeeco",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "June Bird Coffee Co",
      "city" : "Flint",
      "companyName" : "June Bird Coffee Co",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "oliofoodusa" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(800) 949-1849",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/959fc0e6df46506b13c0430d617c5f92db45c7eb\/original.jpeg",
      "instagramHandle" : "oliofoodusa",
      "twitterHandle" : "",
      "merchantHandle" : "oliofoodusa",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Olio Food USA",
      "city" : "Santa Ana",
      "companyName" : "Olio Food USA",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.oliofood.com\/",
      "email" : ""
    }
  },
  "edisonsquare" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(253) 348-2692",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/6c98cc6984e97ca81e1d9601755b5890613dbe57\/original.jpeg",
      "instagramHandle" : "edisonsquare",
      "twitterHandle" : "",
      "merchantHandle" : "edisonsquare",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Edison Square",
      "city" : "Tacoma",
      "companyName" : "Edison Square",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.edisonsquare.com\/",
      "email" : ""
    }
  },
  "seaportfudgefactory" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "",
      "phone" : "",
      "merchantImage" : "seaportfudgefactory",
      "instagramHandle" : "\"",
      "twitterHandle" : "",
      "merchantHandle" : "seaportfudgefactory",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "(619) 239-3100",
      "merchantName" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ao0eIw5xe6sS5Ov6k458Sf2qD0h2w0YE.png",
      "city" : "",
      "companyName" : "\"Seaport Fudge Factory",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : "http:\/\/www.seaportfudge.com\/"
    }
  },
  "angelicakes" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(408) 300-3600",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/3H22aUPBDZDwNu0Z87k56spH0gBRmIPQ.png",
      "instagramHandle" : "angelicakes",
      "merchantHandle" : "angelicakes",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Angelicakes",
      "city" : "San Jose",
      "companyName" : "Angelicakes",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.angelicakes.net\/",
      "email" : ""
    }
  },
  "cakeboutiquesanjose" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(408) 462-2241",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/V0XsrsRin9fEiJNIH4l1rDJjaoNFhN3M.png",
      "instagramHandle" : "cakeboutiquesanjose",
      "twitterHandle" : "",
      "merchantHandle" : "cakeboutiquesanjose",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Cake Boutique San Jose",
      "city" : "San Jose",
      "companyName" : "Cake Boutique San Jose",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.facebook.com\/cakeboutiquesanjose\/",
      "email" : ""
    }
  },
  "pastafresca" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(650) 804-6260",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/7abba22add7e2c60aabd0994ae9af7beaf5d83ed\/original.png",
      "instagramHandle" : "pastafresca",
      "merchantHandle" : "pastafresca",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Pasta Fresca",
      "city" : "San Jose",
      "companyName" : "Pasta Fresca",
      "websiteUrl" : "http:\/\/mkt.com\/pastafresca",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "roselinecoffee" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(503) 809-2890",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/8y4sjVa3p8XYgliCWmD8QQTA8VEonnje.png",
      "instagramHandle" : "roselinecoffee",
      "merchantHandle" : "roselinecoffee",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Roseline Coffee",
      "city" : "Portland",
      "companyName" : "Roseline Coffee",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/roselinecoffee.com\/",
      "email" : ""
    }
  },
  "hawkeralley" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 796-3906",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/ca05b42aec94a2b567e1b10db1b069bc745dcdb1\/original.jpeg",
      "instagramHandle" : "hawkeralley",
      "twitterHandle" : "",
      "merchantHandle" : "hawkeralley",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Hawker Alley",
      "city" : "San Francisco",
      "companyName" : "Hawker Alley",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.hawkeralley.com\/",
      "email" : ""
    }
  },
  "oddotterbrewingcompany" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(253) 327-1680",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/0d2QfNtKaEW2cwKrKNIOdWnl1rrTkaTT.png",
      "instagramHandle" : "oddotterbrewingcompany",
      "premium" : false,
      "merchantHandle" : "oddotterbrewingcompany",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Odd Otter Brewing Company",
      "city" : "Tacoma",
      "companyName" : "Odd Otter Brewing Company",
      "websiteUrl" : "http:\/\/oddotterbrewing.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "joleisdiner" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(517) 208-0044",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "joleisdiner",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "joleisdiner",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "JoLei’s Diner",
      "city" : "Lansing",
      "companyName" : "JoLei’s Diner",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thaispoon" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(734) 975-0277",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "thaispoon",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "thaispoon",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Thai Spoon",
      "city" : "Ann Arbor",
      "companyName" : "Thai Spoon",
      "websiteUrl" : "https:\/\/www.thaispoonmi.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "adobiventures" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 504-4904",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "adobiventures",
      "twitterHandle" : "",
      "merchantHandle" : "adobiventures",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Adobi Ventures",
      "city" : "Sacramento",
      "companyName" : "Adobi Ventures",
      "websiteUrl" : "https:\/\/adobiventures.com\/contact-us\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "annarbordistillingcompany" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(734) 882-2169",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/newitKyvnbKWUon27o2eP6U9rRFzaDCs.png",
      "instagramHandle" : "annarbordistillingcompany",
      "twitterHandle" : "",
      "merchantHandle" : "annarbordistillingcompany",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Ann Arbor Distilling Company",
      "city" : "Ann Arbor",
      "companyName" : "Ann Arbor Distilling Company",
      "websiteUrl" : "http:\/\/www.annarbordistilling.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "barrelroom" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(503) 242-0700",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/MCCXuk7j2r475lBakyNfZJtphV0FdLwT.png",
      "instagramHandle" : "barrelroom",
      "merchantHandle" : "barrelroom",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Barrel Room",
      "city" : "Portland",
      "companyName" : "Barrel Room",
      "websiteUrl" : "http:\/\/www.barrelroompdx.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "madewithlovebakery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(509) 919-0041",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/pvhk23s4mD9Uf0ODfMsDilVyQjEPkIT5.png",
      "instagramHandle" : "madewithlovebakery",
      "premium" : false,
      "merchantHandle" : "madewithlovebakery",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Made With Love Bakery",
      "city" : "Spokane",
      "companyName" : "Made With Love Bakery",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/mwlbakery.com\/",
      "email" : ""
    }
  },
  "kingskitchen" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/s97gca94jGPtjPUgZQxpwVvIgKiNReIA.png",
      "instagramHandle" : "kingskitchen",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "kingskitchen",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "King's Kitchen",
      "city" : "Salem",
      "companyName" : "King's Kitchen",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "elegantelephantbakingco" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(541) 556-7540",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/zMaMvuodBqsrRmm1CQsAk3UhaNcIIPYd.png",
      "instagramHandle" : "elegantelephantbakingco",
      "premium" : false,
      "merchantHandle" : "elegantelephantbakingco",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Elegant Elephant Baking Co.",
      "city" : "Eugene",
      "companyName" : "Elegant Elephant Baking Co.",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.elegantelephantbaking.com\/",
      "email" : ""
    }
  },
  "summitbusinesssolutionsllc" : {
    "info" : {
      "state" : "",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "summitbusinesssolutionsllc",
      "instagramHandle" : " LLC\"",
      "twitterHandle" : "",
      "merchantHandle" : "summitbusinesssolutionsllc",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "(909) 333-4144",
      "merchantName" : "No Logo Image Found on Website",
      "city" : "",
      "companyName" : "\"Summit Business Solutions",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : "http:\/\/www.summitbusinesssolutions.com\/"
    }
  },
  "newyorkvinnyspizza" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(253) 344-1499",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/U0FyLKOPmRBwTgCJZ8T483ARdG26LMey.png",
      "instagramHandle" : "newyorkvinnyspizza",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "newyorkvinnyspizza",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "New York Vinny's Pizza",
      "city" : "Tacoma",
      "companyName" : "New York Vinny's Pizza",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/newyorkvinnyspizza.com\/",
      "email" : ""
    }
  },
  "alohacookin" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(517) 657-7963",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/9a54f2046cee25c995716c7613ad4a611fc93685\/original.png",
      "instagramHandle" : "alohacookin",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "alohacookin",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Aloha Cookin'",
      "city" : "Lansing",
      "companyName" : "Aloha Cookin'",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/alohacookin.com\/",
      "email" : ""
    }
  },
  "holdaakchickenshop" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 427-5020",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/8KtyN6Y1zOZjxtFKyNEC3TmxRD6OiIsW.png",
      "instagramHandle" : "holdaakchickenshop",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "holdaakchickenshop",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "HOLDAAK Chicken Shop",
      "city" : "Riverside",
      "companyName" : "HOLDAAK Chicken Shop",
      "websiteUrl" : "http:\/\/holdaak.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "feastworldkitchen" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(509) 608-1313",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/g2YF7H3lJO9copN0oN8zOhX0aYX2bPr9.png",
      "instagramHandle" : "feastworldkitchen",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "feastworldkitchen",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Feast World Kitchen",
      "city" : "Spokane",
      "companyName" : "Feast World Kitchen",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.feastworldkitchen.org\/",
      "email" : ""
    }
  },
  "beastieboutiquellc" : {
    "info" : {
      "state" : "",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "beastieboutiquellc",
      "instagramHandle" : " LLC\"",
      "premium" : false,
      "merchantHandle" : "beastieboutiquellc",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "(360) 574-6400",
      "merchantName" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/GrCQzTaRcxyD0ZOi71fQDymH4UDZBfRO.png",
      "city" : "",
      "companyName" : "\"Beastie Boutique",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : "https:\/\/beastieboutique.com\/?local=true&location=vancouver"
    }
  },
  "ranchpdxnw21st" : {
    "info" : {
      "state" : "",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "ranchpdxnw21st",
      "instagramHandle" : " NW 21st\"",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "ranchpdxnw21st",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "(971) 202-7324",
      "merchantName" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/YoSCG30aCB4X0fsaNwJjlmHVVoJoM2ES.png",
      "city" : "",
      "companyName" : "\"Ranch PDX",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : "http:\/\/ranchpdx.com\/"
    }
  },
  "lovebelizean" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(503) 421-5599",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/6cad8558bf2d77b0239bd27c06adc651834f6aa1\/original.jpeg",
      "instagramHandle" : "lovebelizean",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "lovebelizean",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Love Belizean",
      "city" : "Portland",
      "companyName" : "Love Belizean",
      "websiteUrl" : "https:\/\/twitter.com\/LoveBelizeanPDX",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "lamichoacanacostamesa" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(949) 295-3009",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/ef2ec4d83b53ea24f8732951534a1b7f685b53d0\/original.jpeg",
      "instagramHandle" : "lamichoacanacostamesa",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "lamichoacanacostamesa",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "La Michoacana Costa Mesa",
      "city" : "Santa Ana",
      "companyName" : "La Michoacana Costa Mesa",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/instagram.com\/lamichoacanacostamesa?r=nametag",
      "email" : ""
    }
  },
  "bluefirebliss" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/JSSI2Irx70eYotxL04ALS5HYo5Ktof4N.png",
      "instagramHandle" : "bluefirebliss",
      "twitterHandle" : "",
      "merchantHandle" : "bluefirebliss",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Blue Fire Bliss",
      "city" : "Chula Vista",
      "companyName" : "Blue Fire Bliss",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "holdaak" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 363-3453",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/8KtyN6Y1zOZjxtFKyNEC3TmxRD6OiIsW.png",
      "instagramHandle" : "holdaak",
      "twitterHandle" : "",
      "merchantHandle" : "holdaak",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "HOLDAAK",
      "city" : "Santa Ana",
      "companyName" : "HOLDAAK",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/holdaak.com\/",
      "email" : ""
    }
  },
  "screaminjayshotlunch" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(541) 995-0856",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/T0232AAIjLaJCpa3dsIDoo2UGmjeuOO9.png",
      "instagramHandle" : "screaminjayshotlunch",
      "premium" : false,
      "merchantHandle" : "screaminjayshotlunch",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Screamin' Jay's Hot Lunch",
      "city" : "Eugene",
      "companyName" : "Screamin' Jay's Hot Lunch",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "noreenterryhassel" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(586) 263-2300",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/012921ea5b5f5287725cdee4db7cdc951fee6a96\/original.jpeg",
      "instagramHandle" : "noreenterryhassel",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "noreenterryhassel",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Noreen Terry-Hassel",
      "city" : "Sterling Heights",
      "companyName" : "Noreen Terry-Hassel",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "gondofusioncubanmexicanfood" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(650) 218-5404",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/BAeUJxWPZU06jE5OP59UpF1Wgq16lQrb.png",
      "instagramHandle" : "gondofusioncubanmexicanfood",
      "merchantHandle" : "gondofusioncubanmexicanfood",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Gondo Fusion Cuban Mexican food",
      "city" : "Sacramento",
      "companyName" : "Gondo Fusion Cuban Mexican food",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.gondofusion.com\/",
      "email" : ""
    }
  },
  "indabacoffee" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(509) 822-7182",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/q05aJ90JGRfcnaaat8wlkaO60ji0CYE6.png",
      "instagramHandle" : "indabacoffee",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "indabacoffee",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Indaba Coffee",
      "city" : "Spokane",
      "companyName" : "Indaba Coffee",
      "websiteUrl" : "http:\/\/www.indabacoffee.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "morninglavenderboutiquecafe" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 486-1429",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/TxR0Urn6WXCJJ6GU3xXRYGJUDp0BCTMq.png",
      "instagramHandle" : "morninglavenderboutiquecafe",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "morninglavenderboutiquecafe",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Morning Lavender Boutique & Cafe",
      "city" : "Santa Ana",
      "companyName" : "Morning Lavender Boutique & Cafe",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.morninglavendercafe.com\/",
      "email" : ""
    }
  },
  "nourishorangecounty" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(949) 445-0102",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/n57eLPzaEQSNGl6XPolrBZTkfSEqmgxY.png",
      "instagramHandle" : "nourishorangecounty",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "nourishorangecounty",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Nourish Orange County",
      "city" : "Santa Ana",
      "companyName" : "Nourish Orange County",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "gladstonecommons" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(226) 674-1070",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/BDYJ71c9MkMnerevgShyOD676ogLanWa.png",
      "instagramHandle" : "gladstonecommons",
      "merchantHandle" : "gladstonecommons",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Gladstone Commons",
      "city" : "Detroit",
      "companyName" : "Gladstone Commons",
      "websiteUrl" : "https:\/\/gladstonecommons.ca\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "southcoastbaking" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(949) 851-9654",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/dkEVnndmk7eCPDkyjaNF2acLlfXoeUXQ.png",
      "instagramHandle" : "southcoastbaking",
      "premium" : false,
      "merchantHandle" : "southcoastbaking",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "South Coast Baking",
      "city" : "Irvine",
      "companyName" : "South Coast Baking",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "napolisculinaryacademy" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(916) 971-0600",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/44800b31d78114382b2c4fb1d7ffbcddbd35023a\/original.png",
      "instagramHandle" : "napolisculinaryacademy",
      "merchantHandle" : "napolisculinaryacademy",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Napoli's Culinary Academy",
      "city" : "Sacramento",
      "companyName" : "Napoli's Culinary Academy",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.napoliculinaryacademy.com\/",
      "email" : ""
    }
  },
  "acairepublic" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 427-5193",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/VDv9l6huuwHqFXds8k4ceMMKxy6uJE9Y.png",
      "instagramHandle" : "acairepublic",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "acairepublic",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Acai Republic",
      "city" : "Riverside",
      "companyName" : "Acai Republic",
      "websiteUrl" : "https:\/\/www.acairepublic.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "bellacreamery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(559) 419-9093",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/0nhhI9QDcuQJrGwu82RI9vTbHWhjtcQF.png",
      "instagramHandle" : "bellacreamery",
      "premium" : false,
      "merchantHandle" : "bellacreamery",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Bella Creamery",
      "city" : "Fresno",
      "companyName" : "Bella Creamery",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/m.facebook.com\/profile.php?id=100352084651240&ref=content_filter",
      "email" : ""
    }
  },
  "fariabakery" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(916) 204-8726",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/cf96905dca1794abb7b7bc7ccbb33a1b7fedd33d\/original.jpeg",
      "instagramHandle" : "fariabakery",
      "merchantHandle" : "fariabakery",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Faria Bakery",
      "city" : "Sacramento",
      "companyName" : "Faria Bakery",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/fariabakery.com\/",
      "email" : ""
    }
  },
  "woodspolkstation" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 463-7418",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ws7hasXuKv6hsF53tYDZD44F42Kor0rN.png",
      "instagramHandle" : "woodspolkstation",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "woodspolkstation",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Woods Polk Station",
      "city" : "San Francisco",
      "companyName" : "Woods Polk Station",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.woodsbeer.com\/polk-station\/",
      "email" : ""
    }
  },
  "VALUE!" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/o5c3sIInEUSVxNlWay9q0IuCkBezdHpV.jpeg",
      "instagramHandle" : "#VALUE!",
      "merchantHandle" : "#VALUE!",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : ".",
      "city" : "San Bernardino",
      "companyName" : ".",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "gyromania" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(805) 799-6592",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ZeiCJps5dtIQOZXbzPf3lG1sMI0saIo6.png",
      "instagramHandle" : "gyromania",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "gyromania",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Gyromania",
      "city" : "Oxnard",
      "companyName" : "Gyromania",
      "websiteUrl" : "http:\/\/www.gyromania.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "gibsonwineco" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(559) 875-2505",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/9ChtCswFLn8tNE30o7P0YXZXSimgDWF5.png",
      "instagramHandle" : "gibsonwineco",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "gibsonwineco",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Gibson Wine Co",
      "city" : "Fresno",
      "companyName" : "Gibson Wine Co",
      "websiteUrl" : "http:\/\/gibsonwinecompany.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "myrondlilleymsw" : {
    "info" : {
      "state" : "",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "myrondlilleymsw",
      "instagramHandle" : " MSW\"",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "myrondlilleymsw",
      "squareSandboxKey" : "",
      "companyType" : "(909) 388-4295",
      "merchantName" : "No Logo Image Found on Website",
      "city" : "",
      "companyName" : "\"Myron D. Lilley",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "rnkinternationalinc" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(949) 727-0970",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ci7o2yUjvL3pB8GWFzizMatqCCrCliUv.png",
      "instagramHandle" : "rnkinternationalinc",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "rnkinternationalinc",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "RNK International Inc",
      "city" : "Irvine",
      "companyName" : "RNK International Inc",
      "websiteUrl" : "http:\/\/www.rnkintl.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thesugarchalet" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 295-3447",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Uu0CddaH6pFXU7Pd06fvNfHWHTwfKYmF.png",
      "instagramHandle" : "thesugarchalet",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "thesugarchalet",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Sugar Chalet",
      "city" : "San Bernardino",
      "companyName" : "The Sugar Chalet",
      "websiteUrl" : "https:\/\/www.thesugarchalet.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "yummysweetsgifts" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/ff701b3afcb1cae2a555680d443d85571017a6b2\/original.jpeg",
      "instagramHandle" : "yummysweetsgifts",
      "premium" : false,
      "merchantHandle" : "yummysweetsgifts",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "YummySweets&Gifts",
      "city" : "Long Beach",
      "companyName" : "YummySweets&Gifts",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "tacoslosdesveladosglendale" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(818) 649-1415",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "tacoslosdesveladosglendale",
      "merchantHandle" : "tacoslosdesveladosglendale",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Tacos Los Desvelados Glendale",
      "city" : "Glendale",
      "companyName" : "Tacos Los Desvelados Glendale",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.tacoslosdesvelados.com\/",
      "email" : ""
    }
  },
  "enegrenbrewingcompany" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(805) 552-0602",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Z09PFZRmsPIknsa8Hz0i6uxw4JZAVFrz.png",
      "instagramHandle" : "enegrenbrewingcompany",
      "twitterHandle" : "",
      "merchantHandle" : "enegrenbrewingcompany",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Enegren Brewing Company",
      "city" : "Oxnard",
      "companyName" : "Enegren Brewing Company",
      "websiteUrl" : "http:\/\/enegrenbrewing.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "kensburger" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(519) 999-6092",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/IqqemEubr40bHPnv1shih9PdeE24x9FH.png",
      "instagramHandle" : "kensburger",
      "merchantHandle" : "kensburger",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Ken's Burger",
      "city" : "Detroit",
      "companyName" : "Ken's Burger",
      "websiteUrl" : "https:\/\/www.kensburger.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "bareknucklepizza" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(510) 463-1524",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/6jHS6ZbHELOkSjRogNHtVLEWeAy0HSg5.png",
      "instagramHandle" : "bareknucklepizza",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "bareknucklepizza",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Bare Knuckle Pizza",
      "city" : "Oakland",
      "companyName" : "Bare Knuckle Pizza",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.bareknucklepizza.com\/",
      "email" : ""
    }
  },
  "midnightexpresscafe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 930-3988",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/75c75852a6b517a6dabfaa6e6737a773fb018398\/original.png",
      "instagramHandle" : "midnightexpresscafe",
      "premium" : false,
      "merchantHandle" : "midnightexpresscafe",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Midnight Express Cafe",
      "city" : "Grand Rapids",
      "companyName" : "Midnight Express Cafe",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/midnightexpresscafe.com\/",
      "email" : ""
    }
  },
  "ruckustacoco" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(843) 814-0041",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "ruckustacoco",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "ruckustacoco",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Ruckus Taco Co",
      "city" : "Warren",
      "companyName" : "Ruckus Taco Co",
      "websiteUrl" : "https:\/\/www.ruckustaco.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "dairydelite" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(616) 635-2089",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/6a7a64f438f6434163d0c8b34f0446406190431c\/original.jpeg",
      "instagramHandle" : "dairydelite",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "dairydelite",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Dairy Delite",
      "city" : "Grand Rapids",
      "companyName" : "Dairy Delite",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "kiquestaqueria" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(562) 248-2428",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/pXcTzjGJ5nw4SgEtx8dsNgskZhYAkC5b.png",
      "instagramHandle" : "kiquestaqueria",
      "twitterHandle" : "",
      "merchantHandle" : "kiquestaqueria",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Kique’s Taqueria",
      "city" : "Long Beach",
      "companyName" : "Kique’s Taqueria",
      "websiteUrl" : "http:\/\/www.kiquestaqueria.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "syruptrap" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(360) 836-8321",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/4ef56c750525045b44c9a6a66e208365e555c468\/original.png",
      "instagramHandle" : "syruptrap",
      "twitterHandle" : "",
      "merchantHandle" : "syruptrap",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Syrup Trap",
      "city" : "Vancouver",
      "companyName" : "Syrup Trap",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/syruptrapwaffles.com\/",
      "email" : ""
    }
  },
  "rootscoffee" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(916) 995-1057",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/VG1cs812Phku4mJWwHv80bX1Q4gXiliI.png",
      "instagramHandle" : "rootscoffee",
      "premium" : false,
      "merchantHandle" : "rootscoffee",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Roots Coffee",
      "city" : "Sacramento",
      "companyName" : "Roots Coffee",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/rootscoffee.co\/",
      "email" : ""
    }
  },
  "royaldelhipalace" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(818) 992-0913",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/e8cbfeb1caaeba3ae897d91ab5734b1a83a13c72\/original.png",
      "instagramHandle" : "royaldelhipalace",
      "premium" : false,
      "merchantHandle" : "royaldelhipalace",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Royal Delhi Palace",
      "city" : "Oxnard",
      "companyName" : "Royal Delhi Palace",
      "websiteUrl" : "http:\/\/dinerdp.com\/?utm_source=google&utm_medium=wix_google_business_profile&utm_campaign=9432165477964679330",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "dailyhabit" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(509) 534-3399",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/GR4V8fVxCy4v4fznZLfz2iHQfSLv7OVz.png",
      "instagramHandle" : "dailyhabit",
      "merchantHandle" : "dailyhabit",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Daily Habit",
      "city" : "Spokane",
      "companyName" : "Daily Habit",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "houseofprimerib" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 885-4605",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/e5Zq0CWf81nTgmFoO1InvsI72YXw0CKz.png",
      "instagramHandle" : "houseofprimerib",
      "merchantHandle" : "houseofprimerib",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "House of Prime Rib",
      "city" : "San Francisco",
      "companyName" : "House of Prime Rib",
      "websiteUrl" : "http:\/\/houseofprimerib.net\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thelobsterpot" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(313) 882-7400",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "thelobsterpot",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "thelobsterpot",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Lobster Pot",
      "city" : "Detroit",
      "companyName" : "The Lobster Pot",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.thelobsterpotdetroit.com\/",
      "email" : ""
    }
  },
  "blackknightfinancialservices" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ABygeHoaPpdZ7H5kWlhAaNy1ReJ4l9cm.png",
      "instagramHandle" : "blackknightfinancialservices",
      "premium" : false,
      "merchantHandle" : "blackknightfinancialservices",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Black Knight Financial Services",
      "city" : "Irvine",
      "companyName" : "Black Knight Financial Services",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "humblebragseatery" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(562) 429-3161",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "humblebragseatery",
      "twitterHandle" : "",
      "merchantHandle" : "humblebragseatery",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Humblebrags Eatery",
      "city" : "Long Beach",
      "companyName" : "Humblebrags Eatery",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "oldtownpizzabrewing" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(503) 222-9999",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/qOZyMNI0Z7VYGFdlnCagBSGPCcnSBRI1.png",
      "instagramHandle" : "oldtownpizzabrewing",
      "twitterHandle" : "",
      "merchantHandle" : "oldtownpizzabrewing",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Old Town Pizza & Brewing",
      "city" : "Vancouver",
      "companyName" : "Old Town Pizza & Brewing",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/otbrewing.com\/",
      "email" : ""
    }
  },
  "sweetbird" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 905-9832",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "sweetbird",
      "merchantHandle" : "sweetbird",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sweetbird",
      "city" : "Anaheim",
      "companyName" : "Sweetbird",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "snoweaterbrewingcompany" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(509) 862-6954",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/gEeoZdEiyihU8B80bl7XwhcPulogIXwM.png",
      "instagramHandle" : "snoweaterbrewingcompany",
      "merchantHandle" : "snoweaterbrewingcompany",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Snow Eater Brewing Company",
      "city" : "Spokane",
      "companyName" : "Snow Eater Brewing Company",
      "websiteUrl" : "https:\/\/www.snoweaterbrewingcompany.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "bbacaijuicestore" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(805) 765-4944",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Ukag6ERe51eTTTXXH3I6yAdPMpjDB847.png",
      "instagramHandle" : "bbacaijuicestore",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "bbacaijuicestore",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "bb Acai Juice Store",
      "city" : "Oxnard",
      "companyName" : "bb Acai Juice Store",
      "websiteUrl" : "http:\/\/bbacai.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sweetpeaksicecream" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(509) 474-9096",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/abc3f0a96b1a39565db98841591fbf62c2a0562f\/original.png",
      "instagramHandle" : "sweetpeaksicecream",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "sweetpeaksicecream",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "sweet peaks ice cream",
      "city" : "Spokane",
      "companyName" : "sweet peaks ice cream",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.sweetpeaksicecream.com\/",
      "email" : ""
    }
  },
  "saucyoakland" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 764-3939",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/5b7e68832395f4c9724769687e4de3ff8ebb1b5b\/original.png",
      "instagramHandle" : "saucyoakland",
      "merchantHandle" : "saucyoakland",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Saucy Oakland",
      "city" : "Oakland",
      "companyName" : "Saucy Oakland",
      "websiteUrl" : "http:\/\/www.saucyoakland.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "creswellbakery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(541) 895-5885",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/B066I4NRrlcV4XFwCmwxLW50ON1z0ay2.png",
      "instagramHandle" : "creswellbakery",
      "twitterHandle" : "",
      "merchantHandle" : "creswellbakery",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Creswell Bakery",
      "city" : "Eugene",
      "companyName" : "Creswell Bakery",
      "websiteUrl" : "http:\/\/creswellbakery.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "daddydssouthernstylebbq" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(360) 892-4418",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/FF8dYpa6WXYeNfPvfLfDc0qrs5xZ4hno.png",
      "instagramHandle" : "daddydssouthernstylebbq",
      "merchantHandle" : "daddydssouthernstylebbq",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Daddy D's Southern Style BBQ",
      "city" : "Vancouver",
      "companyName" : "Daddy D's Southern Style BBQ",
      "websiteUrl" : "http:\/\/daddydsbbq.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thefamouskhmerlaorestaurant" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(408) 613-2746",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/wuC543GpbfVQ2sgM1KnP0Hsgnp5mKxUV.png",
      "instagramHandle" : "thefamouskhmerlaorestaurant",
      "merchantHandle" : "thefamouskhmerlaorestaurant",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Famous Khmer-Lao Restaurant",
      "city" : "San Jose",
      "companyName" : "The Famous Khmer-Lao Restaurant",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.khmerlaofoods.com\/",
      "email" : ""
    }
  },
  "swirlonsquareinc" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "swirlonsquareinc",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "swirlonsquareinc",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Swirl On the Square Inc",
      "city" : "Stockton",
      "companyName" : "Swirl On the Square Inc",
      "websiteUrl" : "http:\/\/www.swirlonthesq.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "vivelatarte" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 780-5818",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/xHOGCbnV1taoKteTc5eVAx0BXf6933Sg.png",
      "instagramHandle" : "vivelatarte",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "vivelatarte",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Vive La Tarte",
      "city" : "San Francisco",
      "companyName" : "Vive La Tarte",
      "websiteUrl" : "https:\/\/www.vivelatarte.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "chickenexpressflinttwpmi" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(810) 259-2556",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/kNpB1GCh4IH020lYw6CZ0J9IX2y4a6YD.png",
      "instagramHandle" : "chickenexpressflinttwpmi",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "chickenexpressflinttwpmi",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Chicken Express Flint Twp MI",
      "city" : "Flint",
      "companyName" : "Chicken Express Flint Twp MI",
      "websiteUrl" : "https:\/\/chickenexpressflint.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "tacoman" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(619) 586-8848",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/rvKqjvqnTzQvQ3EEdLtDglZyoeY5yDRH.jpg",
      "instagramHandle" : "tacoman",
      "merchantHandle" : "tacoman",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "TacoMan",
      "city" : "Chula Vista",
      "companyName" : "TacoMan",
      "websiteUrl" : "http:\/\/tacoman.mobile-webview.com\/print.php",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "kawasushiandgrill" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(616) 884-0220",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/5qbJjMwivhoTx2bpaj6flg2kApxe6oYi.png",
      "instagramHandle" : "kawasushiandgrill",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "kawasushiandgrill",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Kawa Sushi and Grill",
      "city" : "Grand Rapids",
      "companyName" : "Kawa Sushi and Grill",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.kawarockford.com\/",
      "email" : ""
    }
  },
  "cathyshouseofbread" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(559) 387-8722",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "cathyshouseofbread",
      "merchantHandle" : "cathyshouseofbread",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Cathy’s House of Bread",
      "city" : "Fresno",
      "companyName" : "Cathy’s House of Bread",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.instagram.com\/cathyshouseofbread\/?hl=es",
      "email" : ""
    }
  },
  "culturedcaveman" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/353a795320aa1ce959bced3aeb540682\/original.png",
      "instagramHandle" : "culturedcaveman",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "culturedcaveman",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Cultured Caveman",
      "city" : "Vancouver",
      "companyName" : "Cultured Caveman",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "acecashexpress" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(805) 981-3548",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "acecashexpress",
      "premium" : false,
      "merchantHandle" : "acecashexpress",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "ACE Cash Express",
      "city" : "Oxnard",
      "companyName" : "ACE Cash Express",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.acecashexpress.com\/locations\/california\/oxnard\/4600?utm_source=google&utm_medium=local&utm_campaign=4600",
      "email" : ""
    }
  },
  "zingermanscreamery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(734) 929-0500",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "zingermanscreamery",
      "merchantHandle" : "zingermanscreamery",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Zingerman's Creamery",
      "city" : "Ann Arbor",
      "companyName" : "Zingerman's Creamery",
      "websiteUrl" : "http:\/\/www.zingermanscreamery.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "santamariabbqandcatering" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(657) 244-3803",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/mKq2OJs3vP8dD05wHvELy4Edbjm0v55F.png",
      "instagramHandle" : "santamariabbqandcatering",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "santamariabbqandcatering",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Santa Maria bbq and Catering",
      "city" : "Huntington Beach",
      "companyName" : "Santa Maria bbq and Catering",
      "websiteUrl" : "https:\/\/www.santamariabbqshack.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "citylinkfoodwarehouse" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 401-1914",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/lkppD8lYfal6NnmD5ipOQ8TNr0r54QmL.png",
      "instagramHandle" : "citylinkfoodwarehouse",
      "merchantHandle" : "citylinkfoodwarehouse",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "CityLink Food Warehouse",
      "city" : "Fontana",
      "companyName" : "CityLink Food Warehouse",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/wateroflifecc.org\/food-warehouse\/",
      "email" : ""
    }
  },
  "barebottlebrewingcompanysantaclara" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(408) 564-5722",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/2RBbsF63ZaeadUINdB2ChZzZcQyIDzOO.png",
      "instagramHandle" : "barebottlebrewingcompanysantaclara",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "barebottlebrewingcompanysantaclara",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Barebottle Brewing Company - Santa Clara",
      "city" : "San Jose",
      "companyName" : "Barebottle Brewing Company - Santa Clara",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.barebottle.com\/",
      "email" : ""
    }
  },
  "jpanini" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(503) 374-2247",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Rml0Ly0FUrFCZxUiAvTdVOHzuAGHSuoU.png",
      "instagramHandle" : "jpanini",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "jpanini",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "JPanini",
      "city" : "Portland",
      "companyName" : "JPanini",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/jpanini.com\/",
      "email" : ""
    }
  },
  "iupaticustomarts" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/y5npL8XIz01L8en8gVcmQWOA92w11QLY.png",
      "instagramHandle" : "iupaticustomarts",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "iupaticustomarts",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Iupati Custom Arts",
      "city" : "San Jose",
      "companyName" : "Iupati Custom Arts",
      "websiteUrl" : "http:\/\/iupaticustomarts.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "elpiln" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(971) 716-5055",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Olu8lATTpZam22Qj4QA0xKF67rQ10Pyr.png",
      "instagramHandle" : "elpiln",
      "premium" : false,
      "merchantHandle" : "elpiln",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "El Pilón",
      "city" : "Portland",
      "companyName" : "El Pilón",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.elpilonpdx.com\/",
      "email" : ""
    }
  },
  "eltorito" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(951) 684-6816",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/G38lsOL0f4EbdRQOLR2Shses8ULMdBsi.png",
      "instagramHandle" : "eltorito",
      "premium" : false,
      "merchantHandle" : "eltorito",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "El Torito",
      "city" : "Riverside",
      "companyName" : "El Torito",
      "websiteUrl" : "https:\/\/www.eltorito.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "zenpharmacy" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(951) 213-2088",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/G6lg6bhUMqKSblDZHbeSIXmb9zuNSuPi.png",
      "instagramHandle" : "zenpharmacy",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "zenpharmacy",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Zen Pharmacy",
      "city" : "Moreno Valley",
      "companyName" : "Zen Pharmacy",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "meijerbakery" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(586) 274-1653",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/860a68c260a3ba5ff059a478bd1e59e3bdb620ba\/original.png",
      "instagramHandle" : "meijerbakery",
      "twitterHandle" : "",
      "merchantHandle" : "meijerbakery",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Meijer Bakery",
      "city" : "Sterling Heights",
      "companyName" : "Meijer Bakery",
      "websiteUrl" : "https:\/\/www.meijer.com\/shopping\/store-locator\/55.html?cmpid=LM:Web::::05112021::GoogleBakery",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "momakenssweettreats" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(313) 627-1538",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/AsFaimrFoO4NW35HkBfsfmSllJ3rabdV.jpg",
      "instagramHandle" : "momakenssweettreats",
      "merchantHandle" : "momakenssweettreats",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Moma Ken's Sweet Treats",
      "city" : "Detroit",
      "companyName" : "Moma Ken's Sweet Treats",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "julianbrothers" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(248) 588-0280",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "julianbrothers",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "julianbrothers",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Julian Brothers",
      "city" : "Warren",
      "companyName" : "Julian Brothers",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.julianbrothers.com\/",
      "email" : ""
    }
  },
  "creamysbycaylajordan" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 594-7402",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/CAZ8DcmMG21RtjS4aUVMEMoMyAlpjcTV.png",
      "instagramHandle" : "creamysbycaylajordan",
      "merchantHandle" : "creamysbycaylajordan",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Creamy's by Cayla Jordan",
      "city" : "Sacramento",
      "companyName" : "Creamy's by Cayla Jordan",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "artisanuprising" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(805) 355-2711",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/GsHabZLcAYMGP6iO01mBUqJzt2FwP5w0.png",
      "instagramHandle" : "artisanuprising",
      "merchantHandle" : "artisanuprising",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Artisan Uprising",
      "city" : "Huntington Beach",
      "companyName" : "Artisan Uprising",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/artisanuprising.com\/",
      "email" : ""
    }
  },
  "seabrightdeli" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(831) 515-7484",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "seabrightdeli",
      "merchantHandle" : "seabrightdeli",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Seabright Deli",
      "city" : "San Jose",
      "companyName" : "Seabright Deli",
      "websiteUrl" : "http:\/\/seabrightdeli.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "tenmilebrewing" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(562) 612-1255",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/e0CF1knSy6fEayyoHnq0lr48ntYbJyRs.png",
      "instagramHandle" : "tenmilebrewing",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "tenmilebrewing",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Ten Mile Brewing",
      "city" : "Long Beach",
      "companyName" : "Ten Mile Brewing",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.tenmilebrewing.com\/",
      "email" : ""
    }
  },
  "expressyourself" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(619) 382-5394",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/0aa9adc26380aaa9947dfe408bc2f0d388efdfdf\/original.jpeg",
      "instagramHandle" : "expressyourself",
      "merchantHandle" : "expressyourself",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Express Your Self",
      "city" : "Chula Vista",
      "companyName" : "Express Your Self",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "bitterrootsipandpaint" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MT\r",
      "phone" : "(406) 318-9681",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/2f515923a7438a9e1fbe346ea4cda6603afe128f\/original.png",
      "instagramHandle" : "bitterrootsipandpaint",
      "merchantHandle" : "bitterrootsipandpaint",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Bitterroot Sip and Paint",
      "city" : "Billings",
      "companyName" : "Bitterroot Sip and Paint",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/bitterrootsipandpaint.com\/",
      "email" : ""
    }
  },
  "johnsbageldeli" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 526-8162",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "johnsbageldeli",
      "merchantHandle" : "johnsbageldeli",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "John's Bagel Deli",
      "city" : "Oxnard",
      "companyName" : "John's Bagel Deli",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "dotdough" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(310) 935-2125",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "dotdough",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "dotdough",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Dot & Dough",
      "city" : "Long Beach",
      "companyName" : "Dot & Dough",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.dotanddough.com\/menu?utm_source=yelp&utm_medium=social&utm_campaign=learn-more-website-button",
      "email" : ""
    }
  },
  "beerwinecider" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(503) 954-1104",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/tXrPoick6WBHVDLWvZkLTnmxVcyCbe4U.png",
      "instagramHandle" : "beerwinecider",
      "twitterHandle" : "",
      "merchantHandle" : "beerwinecider",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "1856 Beer Wine Cider",
      "city" : "Vancouver",
      "companyName" : "1856 Beer Wine Cider",
      "websiteUrl" : "http:\/\/1856pdx.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "kaylascakedowntowndisney" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 869-1522",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/70c9ed4bf530461ec2c4eb16e106ff5bacd22c98\/original.gif",
      "instagramHandle" : "kaylascakedowntowndisney",
      "merchantHandle" : "kaylascakedowntowndisney",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "Bakery",
      "squareSandboxKey" : "",
      "merchantName" : "Kayla's Cake Downtown Disney",
      "city" : "Anaheim",
      "companyName" : "Kayla's Cake Downtown Disney",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/thekaylascake.com\/downtown-disney\/",
      "email" : "info@thekaylascake.com"
    }
  },
  "tuftneedlemattressstore" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(818) 369-3089",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/aujsPnHWivspYR0iMn3X7cJ0M8merPLX.png",
      "instagramHandle" : "tuftneedlemattressstore",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "tuftneedlemattressstore",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Tuft & Needle Mattress Store",
      "city" : "Glendale",
      "companyName" : "Tuft & Needle Mattress Store",
      "websiteUrl" : "https:\/\/www.tuftandneedle.com\/pages\/glendale-ca-mattress-store",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "newtonstaphouse" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(541) 345-2003",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Lg0jlz5ckKZcfVHFJh8yOR0MrS8ThKPj.png",
      "instagramHandle" : "newtonstaphouse",
      "merchantHandle" : "newtonstaphouse",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "New Tons Taphouse",
      "city" : "Eugene",
      "companyName" : "New Tons Taphouse",
      "websiteUrl" : "https:\/\/www.newtonstaps.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "lawsrestaurant" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 354-7021",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "lawsrestaurant",
      "twitterHandle" : "",
      "merchantHandle" : "lawsrestaurant",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Law's Restaurant",
      "city" : "Riverside",
      "companyName" : "Law's Restaurant",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "curryupnow" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 944-4141",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "curryupnow",
      "twitterHandle" : "",
      "merchantHandle" : "curryupnow",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Curry Up Now",
      "city" : "San Francisco",
      "companyName" : "Curry Up Now",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.curryupnow.com\/",
      "email" : ""
    }
  },
  "notfound" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "Not found",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/5f07671e737f3184bea824d3c831405bd217a3b3\/original.png",
      "instagramHandle" : "notfound",
      "merchantHandle" : "notfound",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Not found",
      "city" : "Warren",
      "companyName" : "Not found",
      "websiteUrl" : "Not found",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "happybellyeateryjuicebar" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(253) 365-6706",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/295651f46e972f01245576f76b4304a25d241a9f\/original.png",
      "instagramHandle" : "happybellyeateryjuicebar",
      "merchantHandle" : "happybellyeateryjuicebar",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Happy Belly eatery + juice bar",
      "city" : "Tacoma",
      "companyName" : "Happy Belly eatery + juice bar",
      "websiteUrl" : "http:\/\/www.happybellytacoma.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "clandestinebrewing" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(408) 520-0220",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/HRVvL09IzP2OSlhHdVlzPza56atTftWF.png",
      "instagramHandle" : "clandestinebrewing",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "clandestinebrewing",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Clandestine Brewing",
      "city" : "San Jose",
      "companyName" : "Clandestine Brewing",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.clandestinebrewing.com\/",
      "email" : ""
    }
  },
  " Smart" : {
    "info" : {
      "state" : "",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : " Perry & Christopherson\"",
      "instagramHandle" : " Belcher",
      "merchantHandle" : " Smart",
      "premium" : false,
      "squareProductionKey" : "\"Kroloff",
      "twitterHandle" : " Perry & Christopherson\"",
      "companyType" : "",
      "squareSandboxKey" : " Belcher",
      "merchantName" : "kroloffbelchersmartperrychristopherson",
      "city" : "",
      "companyName" : "\"Kroloff",
      "tikTokHandle" : " Smart",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "vineyardlittleleague" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/FNafnoB2e2yK7sTeCB6hT32EmmIaAfNM.png",
      "instagramHandle" : "vineyardlittleleague",
      "merchantHandle" : "vineyardlittleleague",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Vineyard Little League",
      "city" : "San Bernardino",
      "companyName" : "Vineyard Little League",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.vineyardll.org\/",
      "email" : ""
    }
  },
  "vacancycoffee" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/b3b2ae5f743c9e8888e4d4253f94645dabb3100d\/original.png",
      "instagramHandle" : "vacancycoffee",
      "twitterHandle" : "",
      "merchantHandle" : "vacancycoffee",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Vacancy Coffee",
      "city" : "Huntington Beach",
      "companyName" : "Vacancy Coffee",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.vacancycoffee.com\/",
      "email" : ""
    }
  },
  "aacomicbookstore" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(517) 303-3445",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "aacomicbookstore",
      "merchantHandle" : "aacomicbookstore",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "A&A Comic Book Store",
      "city" : "Lansing",
      "companyName" : "A&A Comic Book Store",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "centerhubattrade" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(949) 536-5652",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/BWSpIcquoZpBWQOmtOeeacvQ2Kr5F9Rx.png",
      "instagramHandle" : "centerhubattrade",
      "twitterHandle" : "",
      "merchantHandle" : "centerhubattrade",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Center Hub at Trade",
      "city" : "Santa Ana",
      "companyName" : "Center Hub at Trade",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.thetrademarketplace.com\/center-hub",
      "email" : ""
    }
  },
  "steepworldclimbingfitness" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MT\r",
      "phone" : "(406) 969-2500",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "steepworldclimbingfitness",
      "twitterHandle" : "",
      "merchantHandle" : "steepworldclimbingfitness",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Steepworld Climbing & Fitness",
      "city" : "Billings",
      "companyName" : "Steepworld Climbing & Fitness",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.steepworld.com\/",
      "email" : ""
    }
  },
  "jewishoasis" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(503) 246-5437",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "jewishoasis",
      "twitterHandle" : "",
      "merchantHandle" : "jewishoasis",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Jewish Oasis",
      "city" : "Portland",
      "companyName" : "Jewish Oasis",
      "websiteUrl" : "https:\/\/www.jewishoasis.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "taqueriaprimecut" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(855) 776-3288",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/5ku0K0nfkVnBu7M7WIZ04gVqg9W86Dvz.png",
      "instagramHandle" : "taqueriaprimecut",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "taqueriaprimecut",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Taqueria Primecut",
      "city" : "Huntington Beach",
      "companyName" : "Taqueria Primecut",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.primecutaqueria.com\/",
      "email" : ""
    }
  },
  "familiajuarezsrestaurant" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 522-7937",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/195701680ee1f89371d763ed2622197c3579b8b7\/original.jpeg",
      "instagramHandle" : "familiajuarezsrestaurant",
      "merchantHandle" : "familiajuarezsrestaurant",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Familia Juarez's Restaurant",
      "city" : "Modesto",
      "companyName" : "Familia Juarez's Restaurant",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "laguerreraskitchen" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(510) 774-6460",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/gGDhGSa6YWZMCLitazgdCtgertx0Gxg7.png",
      "instagramHandle" : "laguerreraskitchen",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "laguerreraskitchen",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "La Guerrera’s Kitchen",
      "city" : "Oakland",
      "companyName" : "La Guerrera’s Kitchen",
      "websiteUrl" : "http:\/\/laguerreraskitchen.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "breadhoney" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(661) 282-8487",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/xs9UHhXLgcL8AA3NKfnvE7rqc1rOgFR4.png",
      "instagramHandle" : "breadhoney",
      "merchantHandle" : "breadhoney",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Bread & Honey",
      "city" : "Bakersfield",
      "companyName" : "Bread & Honey",
      "websiteUrl" : "https:\/\/www.toasttab.com\/bread-and-honey-6314-ashe-rd-suite-400",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "cookiecomissionviejo" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(949) 215-0146",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/9a3d788a1f80206a3591e2626bc4356cad496e17\/original.jpeg",
      "instagramHandle" : "cookiecomissionviejo",
      "twitterHandle" : "",
      "merchantHandle" : "cookiecomissionviejo",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Cookie Co. Mission Viejo",
      "city" : "Irvine",
      "companyName" : "Cookie Co. Mission Viejo",
      "websiteUrl" : "https:\/\/cookieco.com\/mission-viejo?utm_source=google&utm_medium=organic&utm_campaign=gbp-listing&utm-term=mission-viejo",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "tenichirestaurantsushibar" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 346-3477",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "tenichirestaurantsushibar",
      "premium" : false,
      "merchantHandle" : "tenichirestaurantsushibar",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Ten-Ichi Restaurant & Sushi Bar",
      "city" : "San Francisco",
      "companyName" : "Ten-Ichi Restaurant & Sushi Bar",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "themexicanakitchen" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(619) 635-9883",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/d49bb43d1fb795c7768618d367ca0d74e99faeb2\/original.png",
      "instagramHandle" : "themexicanakitchen",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "themexicanakitchen",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Mexicana Kitchen",
      "city" : "Chula Vista",
      "companyName" : "The Mexicana Kitchen",
      "websiteUrl" : "https:\/\/order.online\/business\/the-mexicana-kitchen",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "seniastsurikawas" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 676-9819",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/G6lg6bhUMqKSblDZHbeSIXmb9zuNSuPi.png",
      "instagramHandle" : "seniastsurikawas",
      "premium" : false,
      "merchantHandle" : "seniastsurikawas",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Senia's T'surikawas",
      "city" : "San Bernardino",
      "companyName" : "Senia's T'surikawas",
      "websiteUrl" : "https:\/\/seniassurikawas.myshopify.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "crowdedtablecookies" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(480) 208-9418",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/e796ec72c4d00dc38986e60da2fcaa7c06733009\/original.png",
      "instagramHandle" : "crowdedtablecookies",
      "premium" : false,
      "merchantHandle" : "crowdedtablecookies",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Crowded Table Cookies",
      "city" : "Lansing",
      "companyName" : "Crowded Table Cookies",
      "websiteUrl" : "http:\/\/crowdedtablecookies.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "fatzachspizzadowntownpuyallup" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(253) 328-3415",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/OjSf6cEfWzRmsPK2qaNrP7zaxkElAB1W.png",
      "instagramHandle" : "fatzachspizzadowntownpuyallup",
      "premium" : false,
      "merchantHandle" : "fatzachspizzadowntownpuyallup",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Fat Zach's Pizza - Downtown Puyallup",
      "city" : "Tacoma",
      "companyName" : "Fat Zach's Pizza - Downtown Puyallup",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/fatzachspizza.com\/",
      "email" : ""
    }
  },
  "bellablooms" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 721-3976",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "bellablooms",
      "merchantHandle" : "bellablooms",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Bella Blooms",
      "city" : "Anaheim",
      "companyName" : "Bella Blooms",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.bella-blooms.com\/",
      "email" : ""
    }
  },
  "foodologie" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(562) 523-0816",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/uQJzuAnMpBJgiqSjcyRiqYHYxgvNzjAt.png",
      "instagramHandle" : "foodologie",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "foodologie",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Foodologie",
      "city" : "Long Beach",
      "companyName" : "Foodologie",
      "websiteUrl" : "http:\/\/www.eatfoodologie.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "rollingzonecreamery" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(424) 204-6286",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/dvzgBCZ9nT9fpK5zQZRK5qdrjUmBWTlp.png",
      "instagramHandle" : "rollingzonecreamery",
      "twitterHandle" : "",
      "merchantHandle" : "rollingzonecreamery",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Rolling Zone Creamery",
      "city" : "Long Beach",
      "companyName" : "Rolling Zone Creamery",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.rollingzonecreamery.com\/",
      "email" : ""
    }
  },
  "mindresearchinstituteoffice" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(949) 345-8700",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/EeA3QBRaRjMfavuHcSK1Bxtz3owurc9I.png",
      "instagramHandle" : "mindresearchinstituteoffice",
      "merchantHandle" : "mindresearchinstituteoffice",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "MIND Research Institute office",
      "city" : "Irvine",
      "companyName" : "MIND Research Institute office",
      "websiteUrl" : "http:\/\/www.mindresearch.org\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "revelaesthetics" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 724-4462",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/q380ntnO0mDr8mVHZDxGneh3acbow94T.png",
      "instagramHandle" : "revelaesthetics",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "revelaesthetics",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Revel Aesthetics",
      "city" : "Oxnard",
      "companyName" : "Revel Aesthetics",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.revel-aesthetics.com\/",
      "email" : ""
    }
  },
  "sixthcourse" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 829-2461",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ARn5RbflFGFJ53VeR8bhuDFPWd6IjcdR.png",
      "instagramHandle" : "sixthcourse",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "sixthcourse",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sixth Course",
      "city" : "San Francisco",
      "companyName" : "Sixth Course",
      "websiteUrl" : "http:\/\/www.sixthcourse.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "bigdaddyliquorstore" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(313) 931-1038",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/9RCUGbo3u6EpavRVjhODohpD4rSUQzKK.png",
      "instagramHandle" : "bigdaddyliquorstore",
      "twitterHandle" : "",
      "merchantHandle" : "bigdaddyliquorstore",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Big Daddy Liquor Store",
      "city" : "Detroit",
      "companyName" : "Big Daddy Liquor Store",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "taqueriaelguero" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/P6J3DJru7mFWCRv2mcj8INuhaIgobZOf.png",
      "instagramHandle" : "taqueriaelguero",
      "merchantHandle" : "taqueriaelguero",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Taqueria El Guero",
      "city" : "San Bernardino",
      "companyName" : "Taqueria El Guero",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "twentyseventhheavenscratchbakeshop" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(509) 279-9872",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/5d8b8005a9b0e0d019dedc19378575d2368e1688\/original.png",
      "instagramHandle" : "twentyseventhheavenscratchbakeshop",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "twentyseventhheavenscratchbakeshop",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Twenty-Seventh Heaven Scratch Bake Shop",
      "city" : "Spokane",
      "companyName" : "Twenty-Seventh Heaven Scratch Bake Shop",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.twentyseventhheaven.com\/",
      "email" : ""
    }
  },
  "bubblesteashop" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(253) 627-2111",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/aaep4JKHogFNa5COheGLia2hVbXz00y5.png",
      "instagramHandle" : "bubblesteashop",
      "twitterHandle" : "",
      "merchantHandle" : "bubblesteashop",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Bubbles Tea Shop",
      "city" : "Tacoma",
      "companyName" : "Bubbles Tea Shop",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "blackringcoffeeroasters" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(562) 337-8049",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "blackringcoffeeroasters",
      "merchantHandle" : "blackringcoffeeroasters",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Black Ring Coffee Roasters",
      "city" : "Long Beach",
      "companyName" : "Black Ring Coffee Roasters",
      "websiteUrl" : "https:\/\/black-ring-coffee.square.site\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "properinvestmentstaffing" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(949) 441-0717",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/5ku0K0nfkVnBu7M7WIZ04gVqg9W86Dvz.png",
      "instagramHandle" : "properinvestmentstaffing",
      "merchantHandle" : "properinvestmentstaffing",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Proper Investment Staffing",
      "city" : "Irvine",
      "companyName" : "Proper Investment Staffing",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "snappys" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(503) 265-8710",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/J03AubyJ7iPbkh2e40q2Bk3a62dhOFUR.png",
      "instagramHandle" : "snappys",
      "merchantHandle" : "snappys",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Snappy's",
      "city" : "Portland",
      "companyName" : "Snappy's",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.makeitsnappys.com\/",
      "email" : ""
    }
  },
  "oakstpub" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(925) 626-5547",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/hzVMVRJnOOHL4pm4Lm4GGHnoWCUc5NlR.png",
      "instagramHandle" : "oakstpub",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "oakstpub",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "311 Oak St Pub",
      "city" : "Stockton",
      "companyName" : "311 Oak St Pub",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/311oakstreetpub.net\/",
      "email" : ""
    }
  },
  "sugarmommasbakerycafe" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(616) 957-2122",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/4eoZRmL9mfGsX1BcjzjyY0XkKpWM49TG.png",
      "instagramHandle" : "sugarmommasbakerycafe",
      "merchantHandle" : "sugarmommasbakerycafe",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sugar Momma's Bakery & Cafe",
      "city" : "Grand Rapids",
      "companyName" : "Sugar Momma's Bakery & Cafe",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.sugarmommaspastries.com\/",
      "email" : ""
    }
  },
  "sugarbirdsweetscafemontrose" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(818) 239-7559",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/X8nVDexPd12usMSUOxVm8VfeSsYw88ak.jpg",
      "instagramHandle" : "sugarbirdsweetscafemontrose",
      "premium" : false,
      "merchantHandle" : "sugarbirdsweetscafemontrose",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Sugarbird Sweets Cafe - Montrose",
      "city" : "Glendale",
      "companyName" : "Sugarbird Sweets Cafe - Montrose",
      "websiteUrl" : "http:\/\/www.sugarbirdsweets.com\/cafe",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "elpreferidotacoshopbesttacos" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 933-2266",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/7ed9f3f8dd86cc4a5a17596ce042be13\/original.jpeg",
      "instagramHandle" : "elpreferidotacoshopbesttacos",
      "merchantHandle" : "elpreferidotacoshopbesttacos",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "El Preferido Taco Shop (Best Tacos)",
      "city" : "Oxnard",
      "companyName" : "El Preferido Taco Shop (Best Tacos)",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "cafevanness" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Owy2DquoI6oKolUJnqHGuAJmbjAVtN8T.png",
      "instagramHandle" : "cafevanness",
      "merchantHandle" : "cafevanness",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Cafe Van Ness",
      "city" : "Fresno",
      "companyName" : "Cafe Van Ness",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "starroutefarms" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 868-1658",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/3aeb3f778d97d960c0cf682c02f8644b2bdefaab\/original.png",
      "instagramHandle" : "starroutefarms",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "starroutefarms",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Star Route Farms",
      "city" : "Oakland",
      "companyName" : "Star Route Farms",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.starroutefarms.org\/",
      "email" : ""
    }
  },
  "laddercoffeetoast" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/VIB9zHr4sVRkEd7Qyk3CDnbzNXKEYZcz.png",
      "instagramHandle" : "laddercoffeetoast",
      "merchantHandle" : "laddercoffeetoast",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Ladder Coffee & Toast",
      "city" : "Spokane",
      "companyName" : "Ladder Coffee & Toast",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "primeproper" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(313) 636-3100",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/5JhPd4bmh60qZ4hq15K1UgnByl5C5C8y.png",
      "instagramHandle" : "primeproper",
      "merchantHandle" : "primeproper",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Prime + Proper",
      "city" : "Detroit",
      "companyName" : "Prime + Proper",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/primeandproperdetroit.com\/?olonwp=JjBtp_vMLk25gkYh_bnoiQ",
      "email" : ""
    }
  },
  "salvagehausu" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(859) 907-2432",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/WC9iXsCE4WpmavHsq5ZfZbDRuT1VbwMT.png",
      "instagramHandle" : "salvagehausu",
      "twitterHandle" : "",
      "merchantHandle" : "salvagehausu",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Salvage Hausu",
      "city" : "Oakland",
      "companyName" : "Salvage Hausu",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/salvagehausu.com\/",
      "email" : ""
    }
  },
  "merelysweets" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 529-9909",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/006ce408a16c55fc208a8aeac39b637d\/original.png",
      "instagramHandle" : "merelysweets",
      "merchantHandle" : "merelysweets",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Merely Sweets",
      "city" : "Anaheim",
      "companyName" : "Merely Sweets",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "west" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 550-2230",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/KTKEabbjNn2I0xA46tvvFCwa6YKaREde.png",
      "instagramHandle" : "west",
      "merchantHandle" : "west",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "601 West",
      "city" : "Grand Rapids",
      "companyName" : "601 West",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/601westgr.com\/",
      "email" : ""
    }
  },
  "peckhamfarms" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(517) 316-4050",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/txHygu2u4U3okARYRXbmb0bzzEtFwho7.png",
      "instagramHandle" : "peckhamfarms",
      "twitterHandle" : "",
      "merchantHandle" : "peckhamfarms",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Peckham Farms",
      "city" : "Lansing",
      "companyName" : "Peckham Farms",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.peckham.org\/our-businesses\/peckham-farms\/",
      "email" : ""
    }
  },
  "ventimigliaitalianfoods" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(586) 979-0828",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ilOluBYBtJFPWPmp4kVAhsXhRKVn41fm.png",
      "instagramHandle" : "ventimigliaitalianfoods",
      "merchantHandle" : "ventimigliaitalianfoods",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Ventimiglia Italian Foods",
      "city" : "Sterling Heights",
      "companyName" : "Ventimiglia Italian Foods",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.ventimigliafoods.com\/",
      "email" : ""
    }
  },
  "thewinegroup" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/96546ba5ff899d9fab4c8ecb630d7234969c2a54\/original.jpeg",
      "instagramHandle" : "thewinegroup",
      "merchantHandle" : "thewinegroup",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Wine Group",
      "city" : "Stockton",
      "companyName" : "The Wine Group",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thailand" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(517) 372-8992",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/K4uHCRXVZQCZjpGctsM0tyaiBm38eT0J.png",
      "instagramHandle" : "thailand",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "thailand",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Thailand",
      "city" : "Lansing",
      "companyName" : "Thailand",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.thailandinlansing.com\/",
      "email" : ""
    }
  },
  "fancyway" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(951) 656-2211",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/d334133e733c811df4718c006b75ffb939b92392\/original.jpeg",
      "instagramHandle" : "fancyway",
      "merchantHandle" : "fancyway",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Fancy Way",
      "city" : "Moreno Valley",
      "companyName" : "Fancy Way",
      "websiteUrl" : "http:\/\/fancy-way.keeq.io\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "plantculture" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 463-1690",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/3hMmcJ3UKLy1ou3PlFd42qLn6uVZphBm.png",
      "instagramHandle" : "plantculture",
      "twitterHandle" : "",
      "merchantHandle" : "plantculture",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Plant Culture",
      "city" : "Oakland",
      "companyName" : "Plant Culture",
      "websiteUrl" : "http:\/\/plantculturelove.square.site\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "xieloartisandesserts" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 240-1122",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/cf2fe9d7d2ebfad21108236c01b0f1ed2d229815\/original.png",
      "instagramHandle" : "xieloartisandesserts",
      "twitterHandle" : "",
      "merchantHandle" : "xieloartisandesserts",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Xielo Artisan Desserts",
      "city" : "Oxnard",
      "companyName" : "Xielo Artisan Desserts",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/xielodesserts.com\/",
      "email" : ""
    }
  },
  "kaymaalgerianeatery" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 847-7032",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/aR2IGyYWi9zV2wXUsIszw14V06JWt7e6.png",
      "instagramHandle" : "kaymaalgerianeatery",
      "merchantHandle" : "kaymaalgerianeatery",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "KAYMA Algerian Eatery",
      "city" : "San Francisco",
      "companyName" : "KAYMA Algerian Eatery",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.kaymaeatery.com\/",
      "email" : ""
    }
  },
  "vivebanquethall" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(951) 655-0356",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "vivebanquethall",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "vivebanquethall",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Vive Banquet Hall",
      "city" : "Moreno Valley",
      "companyName" : "Vive Banquet Hall",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.vivebanquethall.com\/",
      "email" : ""
    }
  },
  "alwayssweetcreations" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/HJl8D2DmfRdKATfZVZIjal0wRbhc3ION.jpg",
      "instagramHandle" : "alwayssweetcreations",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "alwayssweetcreations",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Always Sweet Creations",
      "city" : "Fontana",
      "companyName" : "Always Sweet Creations",
      "websiteUrl" : "https:\/\/always-sweet-creations.ueniweb.com\/?utm_campaign=gmb",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "revolve" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/fe44017502b6c1a92a5acfa60c1ac4e9a4a19475\/original.jpeg",
      "instagramHandle" : "revolve",
      "merchantHandle" : "revolve",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "REVOLVE",
      "city" : "Long Beach",
      "companyName" : "REVOLVE",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "gettinfreshcateringrollcallfoodtruck" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 308-1177",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/MBvlT0jPumKbfgDX5qF9JVggxQjQ9f8o.png",
      "instagramHandle" : "gettinfreshcateringrollcallfoodtruck",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "gettinfreshcateringrollcallfoodtruck",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Gettin' Fresh Catering & Roll Call Food Truck",
      "city" : "Grand Rapids",
      "companyName" : "Gettin' Fresh Catering & Roll Call Food Truck",
      "websiteUrl" : "https:\/\/www.gettinfreshevents.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "ratermanbreadhausbistro" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(734) 253-2068",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/boLK6YMFszcQv1TwRvD1EMUiPabzxdba.png",
      "instagramHandle" : "ratermanbreadhausbistro",
      "merchantHandle" : "ratermanbreadhausbistro",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Raterman Bread Haus & Bistro",
      "city" : "Ann Arbor",
      "companyName" : "Raterman Bread Haus & Bistro",
      "websiteUrl" : "http:\/\/www.ratermanbreadorders.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "pattisonsnorth" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(509) 466-2832",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/jaBaNclhSOGgL2P3ODHV22n9fr6yZ88W.png",
      "instagramHandle" : "pattisonsnorth",
      "merchantHandle" : "pattisonsnorth",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Pattison's North",
      "city" : "Spokane",
      "companyName" : "Pattison's North",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.pattisonsnorth.com\/",
      "email" : ""
    }
  },
  "fostercoffeecompany" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(517) 679-0303",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/0ee4a32c157361c5addaa0bff0c359939df3fb78\/original.png",
      "instagramHandle" : "fostercoffeecompany",
      "merchantHandle" : "fostercoffeecompany",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Foster Coffee Company",
      "city" : "Lansing",
      "companyName" : "Foster Coffee Company",
      "websiteUrl" : "http:\/\/fostercoffee.co\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "qinwestnoodle" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(949) 932-0465",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/ba9c0d476efe0f777dd9eb421990a0a434134620\/original.jpeg",
      "instagramHandle" : "qinwestnoodle",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "qinwestnoodle",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Qin West Noodle",
      "city" : "Santa Ana",
      "companyName" : "Qin West Noodle",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/qinwestnoodle.com\/",
      "email" : ""
    }
  },
  "micafecito" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/RoBFBlLfllNVzEjy7XdCEJ5JtpLOrFAN.png",
      "instagramHandle" : "micafecito",
      "merchantHandle" : "micafecito",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Mi cafecito",
      "city" : "Riverside",
      "companyName" : "Mi cafecito",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.micafecitocoffee.com\/",
      "email" : ""
    }
  },
  "bocopop" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(509) 309-3920",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/0f4f3985f460c45ddda31c16c4776bc5fab732d2\/original.png",
      "instagramHandle" : "bocopop",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "bocopop",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "BocoPOP",
      "city" : "Spokane",
      "companyName" : "BocoPOP",
      "websiteUrl" : "https:\/\/facebook.com\/libertylakecoffee\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "smogcitybrewingcosteelcraft" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(562) 269-0531",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/FFEBSQVqqI7mVIrvpntCuCmNn9ira1sa.png",
      "instagramHandle" : "smogcitybrewingcosteelcraft",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "smogcitybrewingcosteelcraft",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Smog City Brewing Co. @ SteelCraft",
      "city" : "Long Beach",
      "companyName" : "Smog City Brewing Co. @ SteelCraft",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.smogcitybrewing.com\/",
      "email" : ""
    }
  },
  "proofbakery" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(323) 664-8633",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/YOkU1NysX3qxqHwind3Or2uClhH0Y5Zs.png",
      "instagramHandle" : "proofbakery",
      "premium" : false,
      "merchantHandle" : "proofbakery",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Proof Bakery",
      "city" : "Glendale",
      "companyName" : "Proof Bakery",
      "websiteUrl" : "http:\/\/proofbakery.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "lurveywhiteventures" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(810) 397-7465",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/N3yteC7gRwq502YIIaRiMaoy9katB4Hh.png",
      "instagramHandle" : "lurveywhiteventures",
      "premium" : false,
      "merchantHandle" : "lurveywhiteventures",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Lurvey White Ventures",
      "city" : "Flint",
      "companyName" : "Lurvey White Ventures",
      "websiteUrl" : "http:\/\/lurveywhiteventures.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "elpanesito" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 356-1470",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/RoBFBlLfllNVzEjy7XdCEJ5JtpLOrFAN.png",
      "instagramHandle" : "elpanesito",
      "merchantHandle" : "elpanesito",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "El Pane-Sito",
      "city" : "Fontana",
      "companyName" : "El Pane-Sito",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "indesignscreenprintingco" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 796-0479",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/YfL28pK7HKWoN5y1V9qvyvB0CBn4Udso.png",
      "instagramHandle" : "indesignscreenprintingco",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "indesignscreenprintingco",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Indesign Screen Printing Co.",
      "city" : "Oxnard",
      "companyName" : "Indesign Screen Printing Co.",
      "websiteUrl" : "http:\/\/www.indesignsp.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "titostaquitos" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(503) 406-5935",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ZFS01k62itGqTWwf4v9Q8aSZnKhSmFBS.png",
      "instagramHandle" : "titostaquitos",
      "merchantHandle" : "titostaquitos",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Titos Taquitos",
      "city" : "Vancouver",
      "companyName" : "Titos Taquitos",
      "websiteUrl" : "https:\/\/titos-taquitos.square.site\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "ujitimedessert" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(628) 444-3003",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/hiDhz1XWlwR2MxmfkV9yOl304sBPzo5t.png",
      "instagramHandle" : "ujitimedessert",
      "premium" : false,
      "merchantHandle" : "ujitimedessert",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Uji Time Dessert",
      "city" : "San Francisco",
      "companyName" : "Uji Time Dessert",
      "websiteUrl" : "https:\/\/www.ujitimedessert.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sugarboxdonuts" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 498-4318",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/OYqhjoBe4ML7nY8sK98H5xI3SAMV9nFn.png",
      "instagramHandle" : "sugarboxdonuts",
      "merchantHandle" : "sugarboxdonuts",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Sugarbox Donuts",
      "city" : "Oxnard",
      "companyName" : "Sugarbox Donuts",
      "websiteUrl" : "http:\/\/www.sugarboxdonuts.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "kantine" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 735-7123",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/SEoG3wFyPXpiMZd1a2fBDNrB7rjGwBql.png",
      "instagramHandle" : "kantine",
      "merchantHandle" : "kantine",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Kantine",
      "city" : "San Francisco",
      "companyName" : "Kantine",
      "websiteUrl" : "http:\/\/www.kantinesf.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "redribbonbakeshop" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(818) 243-6686",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "redribbonbakeshop",
      "merchantHandle" : "redribbonbakeshop",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Red Ribbon Bakeshop",
      "city" : "Glendale",
      "companyName" : "Red Ribbon Bakeshop",
      "websiteUrl" : "https:\/\/locations.redribbonbakeshop.com\/ca\/glendale\/914-e-colorado-st?utm_medium=organic&utm_source=local&utm_campaign=googlelistings&utm_content=website&utm_term=1130201",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "miguelitostacos" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 533-0030",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/7P8F585jpwX79y6yGfDD9kaORzGvCeRj.png",
      "instagramHandle" : "miguelitostacos",
      "twitterHandle" : "",
      "merchantHandle" : "miguelitostacos",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Miguelito's Tacos",
      "city" : "Anaheim",
      "companyName" : "Miguelito's Tacos",
      "websiteUrl" : "http:\/\/anaheimmarketplace.com\/?s=h-02",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "theflavorlabcateringco" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(248) 652-0195",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/vOW6joSaZpIiW8Fkolq2gnY0ZgOsPLYA.png",
      "instagramHandle" : "theflavorlabcateringco",
      "merchantHandle" : "theflavorlabcateringco",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Flavor Lab Catering Co.",
      "city" : "Sterling Heights",
      "companyName" : "The Flavor Lab Catering Co.",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.flavorlabmi.com\/",
      "email" : ""
    }
  },
  "bobame" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 485-1767",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/W1mkehw9ZxZYbE0vbQwNjcUCNfLao5VP.png",
      "instagramHandle" : "bobame",
      "twitterHandle" : "",
      "merchantHandle" : "bobame",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Boba & Me",
      "city" : "Oxnard",
      "companyName" : "Boba & Me",
      "websiteUrl" : "http:\/\/www.bobaandme.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "superjuiced" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(510) 628-0184",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/c0be8433f0d26dd3a607882fce216779\/original.png",
      "instagramHandle" : "superjuiced",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "superjuiced",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Super Juiced",
      "city" : "Oakland",
      "companyName" : "Super Juiced",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.oaklandisjuiced.com\/",
      "email" : ""
    }
  },
  "mimorena" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/8WQNBmuTgg7AocD0AxK3Ek3DWto9O2za.png",
      "instagramHandle" : "mimorena",
      "premium" : false,
      "merchantHandle" : "mimorena",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Mi Morena",
      "city" : "San Francisco",
      "companyName" : "Mi Morena",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/lacocinamarketplace.com\/",
      "email" : ""
    }
  },
  "bolcupop" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(951) 266-6633",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/WdyquIZp5FpqjV2ZCTipDWVwahEzRqG0.png",
      "instagramHandle" : "bolcupop",
      "merchantHandle" : "bolcupop",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Bolcupop",
      "city" : "San Bernardino",
      "companyName" : "Bolcupop",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.riversidefoodlab.com\/bolcupop",
      "email" : ""
    }
  },
  "calamigosranch" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(818) 889-6280",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/KXkRkaDqTUMJnF5LPGg5azP1iOarxang.png",
      "instagramHandle" : "calamigosranch",
      "twitterHandle" : "",
      "merchantHandle" : "calamigosranch",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Calamigos Ranch",
      "city" : "Oxnard",
      "companyName" : "Calamigos Ranch",
      "websiteUrl" : "http:\/\/www.calamigos.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "holymatcha" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/776RLEurVVtMGnus6aPuG8iH3ulG8wbh.png",
      "instagramHandle" : "holymatcha",
      "merchantHandle" : "holymatcha",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Holy Matcha",
      "city" : "Chula Vista",
      "companyName" : "Holy Matcha",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.holymatchasd.com\/",
      "email" : ""
    }
  },
  "granssnacks" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 821-6027",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/WxFTe04AKiqrkXdiJgHbj6YuwsnEGEod.png",
      "instagramHandle" : "granssnacks",
      "twitterHandle" : "",
      "merchantHandle" : "granssnacks",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Grans Snacks",
      "city" : "Grand Rapids",
      "companyName" : "Grans Snacks",
      "websiteUrl" : "http:\/\/facebook.com\/realgranssnacks",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "nicolsonstrawberries" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 982-0086",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/5GjELcaDzKT8dawALcDiWDmgqw6ScHKZ.png",
      "instagramHandle" : "nicolsonstrawberries",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "nicolsonstrawberries",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Nicolson Strawberries",
      "city" : "Fontana",
      "companyName" : "Nicolson Strawberries",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "ernierustyshandcraftedicecreamsodashoppe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/zjY80uVkJWTKWGD2W0rueXJ5lVUaBpez.png",
      "instagramHandle" : "ernierustyshandcraftedicecreamsodashoppe",
      "merchantHandle" : "ernierustyshandcraftedicecreamsodashoppe",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Ernie & Rusty's Handcrafted Ice Cream & Soda Shoppe",
      "city" : "Detroit",
      "companyName" : "Ernie & Rusty's Handcrafted Ice Cream & Soda Shoppe",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "reborncoffeeriverside" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/OS1jIE6uqJvBadVL6xZRx5vFLfWVaFcS.png",
      "instagramHandle" : "reborncoffeeriverside",
      "merchantHandle" : "reborncoffeeriverside",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Reborn Coffee - Riverside",
      "city" : "Riverside",
      "companyName" : "Reborn Coffee - Riverside",
      "websiteUrl" : "https:\/\/reborncoffee.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "bokebowl" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(503) 719-5698",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/3sTKve33xV1OyJ5rQr5m8MYzwxI5sdFV.png",
      "instagramHandle" : "bokebowl",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "bokebowl",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Boke Bowl",
      "city" : "Portland",
      "companyName" : "Boke Bowl",
      "websiteUrl" : "https:\/\/www.bokebowl.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "naijafoodeatsrestaurantandlounge" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(226) 346-1324",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/GoDMb9IgpZ8F8yYbszuLdwTiRtsyIa4s.png",
      "instagramHandle" : "naijafoodeatsrestaurantandlounge",
      "twitterHandle" : "",
      "merchantHandle" : "naijafoodeatsrestaurantandlounge",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "NaijaFoodEats Restaurant and Lounge",
      "city" : "Detroit",
      "companyName" : "NaijaFoodEats Restaurant and Lounge",
      "websiteUrl" : "http:\/\/naijafoodeats.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "tamarack" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 788-7545",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/3FjERCkCaAOtNMSYdCpRxOUh05jvDZqj.png",
      "instagramHandle" : "tamarack",
      "merchantHandle" : "tamarack",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Tamarack",
      "city" : "Oakland",
      "companyName" : "Tamarack",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/tamarackoakland.com\/",
      "email" : ""
    }
  },
  "chickenshackdetroit" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(313) 821-9200",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/kNpB1GCh4IH020lYw6CZ0J9IX2y4a6YD.png",
      "instagramHandle" : "chickenshackdetroit",
      "premium" : false,
      "merchantHandle" : "chickenshackdetroit",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Chicken Shack Detroit",
      "city" : "Detroit",
      "companyName" : "Chicken Shack Detroit",
      "websiteUrl" : "http:\/\/www.chickenshack.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "imperfectlysweet" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(810) 923-0632",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/V8fDsoDpp7g76mbsXmyxKNxAYzAbbp30.png",
      "instagramHandle" : "imperfectlysweet",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "imperfectlysweet",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Imperfectly Sweet",
      "city" : "Ann Arbor",
      "companyName" : "Imperfectly Sweet",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/imperfectlysweet.godaddysites.com\/",
      "email" : ""
    }
  },
  "dontpanicitsorganic" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 883-7227",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "dontpanicitsorganic",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "dontpanicitsorganic",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Don’t panic it’s organic",
      "city" : "Modesto",
      "companyName" : "Don’t panic it’s organic",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "thaibasil" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(916) 442-7690",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/vdQIIzymCmVPr0DPpHo9mb0BMXzAu7wc.png",
      "instagramHandle" : "thaibasil",
      "premium" : false,
      "merchantHandle" : "thaibasil",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Thai Basil",
      "city" : "Sacramento",
      "companyName" : "Thai Basil",
      "websiteUrl" : "http:\/\/www.thaibasilrestaurant.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "cater2usfllc" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 948-5203",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/fi61gV5BqvBXoZkZGhRKoVb69haREO3z.png",
      "instagramHandle" : "cater2usfllc",
      "merchantHandle" : "cater2usfllc",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Cater2uSF LLC",
      "city" : "San Jose",
      "companyName" : "Cater2uSF LLC",
      "websiteUrl" : "http:\/\/www.cater2usf.net\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "pietrzykpierogi" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(313) 614-9393",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/i6k1aLrAj86MxHXPriQtwYQgpAzpRdxe.png",
      "instagramHandle" : "pietrzykpierogi",
      "premium" : false,
      "merchantHandle" : "pietrzykpierogi",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Pietrzyk Pierogi",
      "city" : "Detroit",
      "companyName" : "Pietrzyk Pierogi",
      "websiteUrl" : "http:\/\/www.pietrzykpierogi.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "theboomboomroomtacoma" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(253) 468-3623",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/mcnxnqX2Hg8QAc42ViqbOau0AXDDB8Pd.png",
      "instagramHandle" : "theboomboomroomtacoma",
      "merchantHandle" : "theboomboomroomtacoma",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Boom Boom Room Tacoma",
      "city" : "Tacoma",
      "companyName" : "The Boom Boom Room Tacoma",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/tacomasboomboomroom.com\/",
      "email" : ""
    }
  },
  "shahshalalfood" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(916) 572-0219",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "shahshalalfood",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "shahshalalfood",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Shah's Halal Food",
      "city" : "Sacramento",
      "companyName" : "Shah's Halal Food",
      "websiteUrl" : "https:\/\/www.shahshalalfood.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "beauxberry" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/GVT0D0j1NqsRgAkusQVVTPHIkB5uLPFA.png",
      "instagramHandle" : "beauxberry",
      "merchantHandle" : "beauxberry",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Beaux Berry",
      "city" : "Vancouver",
      "companyName" : "Beaux Berry",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/beauxberry.com\/",
      "email" : ""
    }
  },
  "farmmarket" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 563-0060",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/rMO2LlLv7mu0qMaYWCPpEt1TlwmsAEGG.png",
      "instagramHandle" : "farmmarket",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "farmmarket",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Farm Market",
      "city" : "Moreno Valley",
      "companyName" : "Farm Market",
      "websiteUrl" : "https:\/\/www.farmmarketmv.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "humphryslocombemicrofoodhall" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(650) 529-6236",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/t0p8m0Z1aw1NKxxaeTHMAvr2iJSRyN1d.png",
      "instagramHandle" : "humphryslocombemicrofoodhall",
      "merchantHandle" : "humphryslocombemicrofoodhall",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Humphry Slocombe - Micro Food Hall",
      "city" : "San Jose",
      "companyName" : "Humphry Slocombe - Micro Food Hall",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "ozcoffeefood" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 916-9934",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/dPz2aC656Dal4x61To1r53TAnWeuH7dZ.png",
      "instagramHandle" : "ozcoffeefood",
      "premium" : false,
      "merchantHandle" : "ozcoffeefood",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "1 Oz Coffee & Food",
      "city" : "Huntington Beach",
      "companyName" : "1 Oz Coffee & Food",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/1ozcoffee.com\/",
      "email" : ""
    }
  },
  "wownutrition" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(661) 213-2708",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "wownutrition",
      "twitterHandle" : "",
      "merchantHandle" : "wownutrition",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Wow Nutrition",
      "city" : "Bakersfield",
      "companyName" : "Wow Nutrition",
      "websiteUrl" : "https:\/\/sites.google.com\/view\/wownutritionclub",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "detailgaragefontana" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 320-8577",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/7f6e205e9208c2e2e35e875faa07fd83ecea8ad1\/original.png",
      "instagramHandle" : "detailgaragefontana",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "detailgaragefontana",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Detail Garage - Fontana",
      "city" : "Fontana",
      "companyName" : "Detail Garage - Fontana",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.detailgarage.com\/location",
      "email" : ""
    }
  },
  "thepokebowl" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(810) 882-9009",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/MQALF65GtIQL3ah2TkHN79P69yF2ouif.png",
      "instagramHandle" : "thepokebowl",
      "twitterHandle" : "",
      "merchantHandle" : "thepokebowl",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Poke Bowl",
      "city" : "Flint",
      "companyName" : "The Poke Bowl",
      "websiteUrl" : "http:\/\/thepokebowlflint.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "brecre" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(951) 299-8860",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/AzJVPq9Zp1BUTO9jxyLnV0r3eTTagaaU.png",
      "instagramHandle" : "brecre",
      "premium" : false,
      "merchantHandle" : "brecre",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "BRÜE CRÜE",
      "city" : "Riverside",
      "companyName" : "BRÜE CRÜE",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "optimalwealthnetworkinc" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 487-1212",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/3a94bf5205b96ba727efdd3b61354c27b1f9fe2a\/original.png",
      "instagramHandle" : "optimalwealthnetworkinc",
      "premium" : false,
      "merchantHandle" : "optimalwealthnetworkinc",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Optimal Wealth Network Inc.",
      "city" : "San Bernardino",
      "companyName" : "Optimal Wealth Network Inc.",
      "websiteUrl" : "https:\/\/optimalwealthnetwork.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "paschalrothpublicaffairsinc" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 397-8200",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/PTcQ1l9Ld6ODtdyqumaBWVi0pWCNEOrt.png",
      "instagramHandle" : "paschalrothpublicaffairsinc",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "paschalrothpublicaffairsinc",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Paschal Roth Public Affairs Inc",
      "city" : "Sacramento",
      "companyName" : "Paschal Roth Public Affairs Inc",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.paschalroth.com\/",
      "email" : ""
    }
  },
  "laminuteicecream" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 307-3751",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/bsa06pQo0KakU8bD3akpMvSrNv1b2aC5.png",
      "instagramHandle" : "laminuteicecream",
      "merchantHandle" : "laminuteicecream",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "à la Minute Ice Cream",
      "city" : "San Bernardino",
      "companyName" : "à la Minute Ice Cream",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.alaminuteicecream.com\/",
      "email" : ""
    }
  },
  "mimiscafe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 653-2511",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/44b8cd28ebd5eda93f2773bf9275feb4c7939191\/original.jpeg",
      "instagramHandle" : "mimiscafe",
      "premium" : false,
      "merchantHandle" : "mimiscafe",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Mimi's Cafe",
      "city" : "Moreno Valley",
      "companyName" : "Mimi's Cafe",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.mimiscafe.com\/locations\/moreno-valley\/",
      "email" : ""
    }
  },
  "ampersandgiftsandgoods" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(951) 213-6372",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "ampersandgiftsandgoods",
      "twitterHandle" : "",
      "merchantHandle" : "ampersandgiftsandgoods",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Ampersand Gifts and Goods",
      "city" : "Riverside",
      "companyName" : "Ampersand Gifts and Goods",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.ampersandgifts.com\/",
      "email" : ""
    }
  },
  "food4less" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 823-1286",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/rMO2LlLv7mu0qMaYWCPpEt1TlwmsAEGG.png",
      "instagramHandle" : "food4less",
      "premium" : false,
      "merchantHandle" : "food4less",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Food4Less",
      "city" : "Fontana",
      "companyName" : "Food4Less",
      "websiteUrl" : "https:\/\/www.food4less.com\/stores\/grocery\/ca\/fontana\/valley-center\/704\/00389?cid=loc_70400389_gmb",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "hozapizzeria" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(510) 250-9072",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/n7rGlaXB2UNkwxq6SIkC97V7Zs3HGW36.png",
      "instagramHandle" : "hozapizzeria",
      "merchantHandle" : "hozapizzeria",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Hoza Pizzeria",
      "city" : "Oakland",
      "companyName" : "Hoza Pizzeria",
      "websiteUrl" : "https:\/\/www.hozapizzas.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "eat" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(734) 369-6773",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Nth0a0sd3MXepLBJNt2O45Z0Kr0dYyW0.png",
      "instagramHandle" : "eat",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "eat",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "eat",
      "city" : "Ann Arbor",
      "companyName" : "eat",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.eatannarbor.com\/",
      "email" : ""
    }
  },
  "oishiisushiteriyaki" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 488-1136",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/e21a805786abbaebcae3a348f03439719070ff04\/original.jpeg",
      "instagramHandle" : "oishiisushiteriyaki",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "oishiisushiteriyaki",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Oishii Sushi & Teriyaki",
      "city" : "Moreno Valley",
      "companyName" : "Oishii Sushi & Teriyaki",
      "websiteUrl" : "http:\/\/oishiisushimv.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "baklavahousefresno" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(559) 840-1238",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/LNKZDTbvWAYRHsBOIMah7DQBaqg9OD6K.png",
      "instagramHandle" : "baklavahousefresno",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "baklavahousefresno",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Baklava House Fresno",
      "city" : "Fresno",
      "companyName" : "Baklava House Fresno",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "mestizo" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 695-4142",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/RdpsnaPkKxq4hPyG1O2eHtR3dvfnUU0x.png",
      "instagramHandle" : "mestizo",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "mestizo",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Mestizo",
      "city" : "San Francisco",
      "companyName" : "Mestizo",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/mestizosf.com\/",
      "email" : ""
    }
  },
  "warrennutrition" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/NKHC6OsjhTexU1i6UCXm5P27FK2rRPDl.png",
      "instagramHandle" : "warrennutrition",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "warrennutrition",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Warren Nutrition",
      "city" : "Warren",
      "companyName" : "Warren Nutrition",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "musubisquare" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(657) 256-1177",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/7c2e0d1134ac7c8288b5982d789de2474817c667\/original.png",
      "instagramHandle" : "musubisquare",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "musubisquare",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Musubi Square",
      "city" : "Anaheim",
      "companyName" : "Musubi Square",
      "websiteUrl" : "http:\/\/musubisquare.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "culantroannarbor" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(734) 369-4302",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/8PHvzLQnHzCSqCDM675nv0IC3em2SZC3.png",
      "instagramHandle" : "culantroannarbor",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "culantroannarbor",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Culantro Ann Arbor",
      "city" : "Ann Arbor",
      "companyName" : "Culantro Ann Arbor",
      "websiteUrl" : "https:\/\/www.culantroperu.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "daveschillnngrillin" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(323) 490-0988",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/fc04f940104ced5156b4fc815ddacf7b\/original.jpeg",
      "instagramHandle" : "daveschillnngrillin",
      "merchantHandle" : "daveschillnngrillin",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Dave's Chilln N Grillin",
      "city" : "Glendale",
      "companyName" : "Dave's Chilln N Grillin",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/daveschillin.com\/",
      "email" : ""
    }
  },
  "glessranch" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 653-5991",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/xYrkr55YnIznRtSGdp35PwrRv3D77yNe.png",
      "instagramHandle" : "glessranch",
      "twitterHandle" : "",
      "merchantHandle" : "glessranch",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Gless Ranch",
      "city" : "Riverside",
      "companyName" : "Gless Ranch",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.glessranch.com\/",
      "email" : ""
    }
  },
  "meatmarket" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(517) 887-0404",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/KK4SFtlMezymCbmqxxYIoXjbNxi3EjXV.png",
      "instagramHandle" : "meatmarket",
      "premium" : false,
      "merchantHandle" : "meatmarket",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Meat Market",
      "city" : "Lansing",
      "companyName" : "Meat Market",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "kimusushi" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(562) 612-1108",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/1gykUEHzDic0GKU7RhxGeKwyPkzpH7Ia.png",
      "instagramHandle" : "kimusushi",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "kimusushi",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Kimu Sushi",
      "city" : "Long Beach",
      "companyName" : "Kimu Sushi",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/kimusushilongbeach.com\/",
      "email" : ""
    }
  },
  "wokvteriyaki" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(253) 566-5533",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/33287a3486bb9185786cc2b535ed1ac1\/original.jpeg",
      "instagramHandle" : "wokvteriyaki",
      "merchantHandle" : "wokvteriyaki",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Wok V Teriyaki",
      "city" : "Tacoma",
      "companyName" : "Wok V Teriyaki",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "hottoddys" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(509) 489-6380",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/bfb54c6d038e9dd1270abf9f8d22740a1ae5a6d3\/original.jpeg",
      "instagramHandle" : "hottoddys",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "hottoddys",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Hot Toddy's",
      "city" : "Spokane",
      "companyName" : "Hot Toddy's",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "fidlersongrandrestaurant" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(517) 580-7202",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "fidlersongrandrestaurant",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "fidlersongrandrestaurant",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Fidlers on the Grand Restaurant",
      "city" : "Lansing",
      "companyName" : "Fidlers on the Grand Restaurant",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.facebook.com\/FidlersontheGrand\/",
      "email" : ""
    }
  },
  "noisettepastrykitchen" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(541) 654-5257",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/89323095306ee223bcd4ab58ff794d717f439cf1\/original.jpeg",
      "instagramHandle" : "noisettepastrykitchen",
      "merchantHandle" : "noisettepastrykitchen",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Noisette Pastry Kitchen",
      "city" : "Eugene",
      "companyName" : "Noisette Pastry Kitchen",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.noisettepk.com\/",
      "email" : ""
    }
  },
  "penelopesperfections" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(949) 402-8328",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/6FLOcN2QkUvQxMMIfOdjMVnzrOOgLJYa.png",
      "instagramHandle" : "penelopesperfections",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "penelopesperfections",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Penelope's Perfections",
      "city" : "Santa Ana",
      "companyName" : "Penelope's Perfections",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/penelopesperfections.com\/",
      "email" : ""
    }
  },
  "greatlakespotpies" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(248) 266-1160",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/jAjNcGzQsHjWiIcHi8SSagVNo0hLj43G.png",
      "instagramHandle" : "greatlakespotpies",
      "premium" : false,
      "merchantHandle" : "greatlakespotpies",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Great Lakes Pot Pies",
      "city" : "Warren",
      "companyName" : "Great Lakes Pot Pies",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/greatlakespotpies.com\/",
      "email" : ""
    }
  },
  " Taproom" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "",
      "phone" : "",
      "merchantImage" : " Pleasanton\"",
      "instagramHandle" : " Brewery",
      "merchantHandle" : " Taproom",
      "twitterHandle" : " Pleasanton\"",
      "squareProductionKey" : "\"Gilman Brewing Company",
      "premium" : false,
      "squareSandboxKey" : " Brewery",
      "companyType" : "",
      "merchantName" : "gilmanbrewingcompanybrewerytaproompleasanton",
      "city" : "",
      "companyName" : "\"Gilman Brewing Company",
      "websiteUrl" : "",
      "tikTokHandle" : " Taproom",
      "email" : ""
    }
  },
  "bakedbyak" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "bakedbyak",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "bakedbyak",
      "companyType" : "Bakery",
      "squareSandboxKey" : "",
      "merchantName" : "Baked by A.K.",
      "city" : "Anaheim",
      "companyName" : "Baked by A.K.",
      "websiteUrl" : "http:\/\/bakedbyak.com\/",
      "tikTokHandle" : "",
      "email" : "info@bakedbyak.com"
    }
  },
  "babybacksopenpitrestaurant" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(313) 340-9500",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/2bd0ce36e4bccd9f4914851719628a1550910a1e\/original.jpeg",
      "instagramHandle" : "babybacksopenpitrestaurant",
      "merchantHandle" : "babybacksopenpitrestaurant",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Babybacks Open Pit Restaurant",
      "city" : "Detroit",
      "companyName" : "Babybacks Open Pit Restaurant",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "chifan" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(248) 965-3621",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Fvpw1Ut8iN8Vi6QvOtuKRrbSoVYYMjji.png",
      "instagramHandle" : "chifan",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "chifan",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "CHI FAN",
      "city" : "Detroit",
      "companyName" : "CHI FAN",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.chifanfoods.com\/",
      "email" : ""
    }
  },
  "blacksugarbobabar" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 521-2022",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/54f648bb99a76cdf693b3cba4d30ff1092596323\/original.png",
      "instagramHandle" : "blacksugarbobabar",
      "merchantHandle" : "blacksugarbobabar",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Black Sugar - Boba Bar",
      "city" : "San Francisco",
      "companyName" : "Black Sugar - Boba Bar",
      "websiteUrl" : "http:\/\/www.blacksugarboba.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "firstbite" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(734) 369-4765",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "firstbite",
      "premium" : false,
      "merchantHandle" : "firstbite",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "First Bite",
      "city" : "Ann Arbor",
      "companyName" : "First Bite",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.firstbitefoods.com\/",
      "email" : ""
    }
  },
  "karmacoffeeshopkitchen" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(734) 589-0304",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/1109c4d47ff902d09b20979fcb38621375c5b653\/original.png",
      "instagramHandle" : "karmacoffeeshopkitchen",
      "premium" : false,
      "merchantHandle" : "karmacoffeeshopkitchen",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Karma Coffee Shop & Kitchen",
      "city" : "Ann Arbor",
      "companyName" : "Karma Coffee Shop & Kitchen",
      "websiteUrl" : "https:\/\/www.facebook.com\/karmacoffeeshop\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "traptreatz" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(800) 988-7592",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/acUiLke0b8ScAp6CYFyqwdrzex50TDmf.png",
      "instagramHandle" : "traptreatz",
      "merchantHandle" : "traptreatz",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Trap Treatz",
      "city" : "Stockton",
      "companyName" : "Trap Treatz",
      "websiteUrl" : "http:\/\/www.traptreatzvip.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thecopperq" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 878-4777",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "thecopperq",
      "twitterHandle" : "",
      "merchantHandle" : "thecopperq",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Copper Q",
      "city" : "San Bernardino",
      "companyName" : "The Copper Q",
      "websiteUrl" : "https:\/\/www.copperq.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "fitboxfuelhealthymealprep" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(810) 354-8106",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/z70erLdhFY4rLmefZuJtNUaplTtlHzfe.png",
      "instagramHandle" : "fitboxfuelhealthymealprep",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "fitboxfuelhealthymealprep",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Fit Box Fuel - Healthy Meal Prep",
      "city" : "Flint",
      "companyName" : "Fit Box Fuel - Healthy Meal Prep",
      "websiteUrl" : "https:\/\/fitboxfuel.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "michiganbusinessconsultants" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(586) 939-2980",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/43c139c8b9e16aaee669706b1b1ebd09045076ae\/original.jpeg",
      "instagramHandle" : "michiganbusinessconsultants",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "michiganbusinessconsultants",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Michigan Business Consultants",
      "city" : "Warren",
      "companyName" : "Michigan Business Consultants",
      "websiteUrl" : "https:\/\/splinternetmarketing.com\/local-pros\/mi\/warren\/michigan-business-consultants\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "mestizocoffeeco" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(323) 741-0244",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/97d9d329276378924ea8246fbda2c2d98b794121\/original.png",
      "instagramHandle" : "mestizocoffeeco",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "mestizocoffeeco",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Mestizo Coffee Co.",
      "city" : "Glendale",
      "companyName" : "Mestizo Coffee Co.",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "draughthorsebrewery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(248) 617-3000",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/1RhxmeJ1N26LD3kWkEt8VTL0WFC7CcNN.png",
      "instagramHandle" : "draughthorsebrewery",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "draughthorsebrewery",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Draught Horse Brewery",
      "city" : "Warren",
      "companyName" : "Draught Horse Brewery",
      "websiteUrl" : "https:\/\/www.draughthorsebrewery.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "elitefoodservicedevelopment" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(657) 210-4512",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/c7467e1c3f0b8ecf868fb73cf78def5672aa2fd9\/original.jpeg",
      "instagramHandle" : "elitefoodservicedevelopment",
      "premium" : false,
      "merchantHandle" : "elitefoodservicedevelopment",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Elite Foodservice Development",
      "city" : "Santa Ana",
      "companyName" : "Elite Foodservice Development",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.elitefsd.com\/",
      "email" : ""
    }
  },
  "velmarayshouseofbbqllc" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "",
      "phone" : "",
      "merchantImage" : "velmarayshouseofbbqllc",
      "instagramHandle" : " LLC\"",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "velmarayshouseofbbqllc",
      "companyType" : "(253) 232-3492",
      "squareSandboxKey" : "",
      "merchantName" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Y2EqGUTJ3W4QZVpx1tIek9T99TfT9H2n.png",
      "city" : "",
      "companyName" : "\"Velma Ray's House of BBQ",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : "https:\/\/www.velmaraysbbq.com\/"
    }
  },
  "fizzbakery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 951-3499",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "fizzbakery",
      "twitterHandle" : "",
      "merchantHandle" : "fizzbakery",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Fizz Bakery",
      "city" : "Stockton",
      "companyName" : "Fizz Bakery",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "cocina" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(541) 345-6947",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/6LVNmOra40IPmznqTSc2WPJBjK2aOY5F.png",
      "instagramHandle" : "cocina",
      "merchantHandle" : "cocina",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "1960 Cocina",
      "city" : "Eugene",
      "companyName" : "1960 Cocina",
      "websiteUrl" : "https:\/\/www.1960cocina.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "elfogonmexicangrill" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 782-8959",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/3YJCiBxDgtfalnRI4fkSdwBpWabjpR0W.png",
      "instagramHandle" : "elfogonmexicangrill",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "elfogonmexicangrill",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "El Fogon Mexican Grill",
      "city" : "San Bernardino",
      "companyName" : "El Fogon Mexican Grill",
      "websiteUrl" : "http:\/\/www.elfogonmexgrill.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "evolvefitness" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 284-1246",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/5d9b18e5643030bfb2a06f4cd18fa007ea395319\/original.jpeg",
      "instagramHandle" : "evolvefitness",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "evolvefitness",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Evolve Fitness",
      "city" : "Modesto",
      "companyName" : "Evolve Fitness",
      "websiteUrl" : "http:\/\/www.evolvemodesto.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "pokenoyairvine" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(949) 748-1884",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/WhgP9hVKsOcWvIKpWwLpJIHvz80PLCyR.png",
      "instagramHandle" : "pokenoyairvine",
      "merchantHandle" : "pokenoyairvine",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Pokenoya Irvine",
      "city" : "Irvine",
      "companyName" : "Pokenoya Irvine",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.gopokenoya.com\/menu",
      "email" : ""
    }
  },
  "longbeachvacationrentals" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(626) 590-8722",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/RXCI4xEHmQgIiPI8PRKQVHhBasFYwdZd.png",
      "instagramHandle" : "longbeachvacationrentals",
      "merchantHandle" : "longbeachvacationrentals",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Long beach vacation rentals",
      "city" : "Long Beach",
      "companyName" : "Long beach vacation rentals",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.411seasideway.com\/",
      "email" : ""
    }
  },
  "sweetsweeterinc" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(586) 977-9338",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/EEuKp6oNCOnijGPr7Cv6bVzyF1D5ICnz.png",
      "instagramHandle" : "sweetsweeterinc",
      "premium" : false,
      "merchantHandle" : "sweetsweeterinc",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sweet & Sweeter Inc",
      "city" : "Sterling Heights",
      "companyName" : "Sweet & Sweeter Inc",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "herbalifeypsilantinutrition" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(734) 340-3962",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "herbalifeypsilantinutrition",
      "premium" : false,
      "merchantHandle" : "herbalifeypsilantinutrition",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Herbalife Ypsilanti Nutrition",
      "city" : "Ann Arbor",
      "companyName" : "Herbalife Ypsilanti Nutrition",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.facebook.com\/ypsilantinutrition\/",
      "email" : ""
    }
  },
  "leosconeyislandsterlingheights" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(586) 978-0080",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/9PqzP5K5FN70c9kbKuuLLKYitVPkI0NW.png",
      "instagramHandle" : "leosconeyislandsterlingheights",
      "premium" : false,
      "merchantHandle" : "leosconeyislandsterlingheights",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Leo's Coney Island STERLING HEIGHTS",
      "city" : "Sterling Heights",
      "companyName" : "Leo's Coney Island STERLING HEIGHTS",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/order.leosconeyisland.com\/menu\/sterling-heights",
      "email" : ""
    }
  },
  "myherosubssanbernardino" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 885-4994",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/orbztyFjIOZCQuxbbD97NaKJGWxOWJee.png",
      "instagramHandle" : "myherosubssanbernardino",
      "merchantHandle" : "myherosubssanbernardino",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "My Hero Subs San Bernardino",
      "city" : "San Bernardino",
      "companyName" : "My Hero Subs San Bernardino",
      "websiteUrl" : "http:\/\/myherosb.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "irvineinktattoostudios" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(949) 916-4168",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/meaFOEhm9PB92RjsiOsMY75hMGcU6ERi.png",
      "instagramHandle" : "irvineinktattoostudios",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "irvineinktattoostudios",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Irvine Ink Tattoo Studios",
      "city" : "Irvine",
      "companyName" : "Irvine Ink Tattoo Studios",
      "websiteUrl" : "https:\/\/tattoshopsnearme.com\/irvine-ink-tattoo-studios",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "dhileep" : {
    "redemptions" : {
      "dhileep-hC8T3" : {
        "3-20-2024" : {
          "dhileep_2JtKD" : {
            "redeemguid" : "2JtKD",
            "redeemTimestamp" : 1710955622546,
            "redeemdate" : "3-20-2024",
            "source" : "IG",
            "promoname" : "dhileep-hC8T3",
            "visitdate" : "",
            "merchanthandle" : "dhileep",
            "phonenumber" : "09092793455",
            "visittime" : ""
          }
        }
      },
      "dhileep-iugZ1" : {
        "3-21-2024" : {
          "dhileep_3CVyv" : {
            "redeemguid" : "3CVyv",
            "redeemTimestamp" : 1710960980721,
            "redeemdate" : "3-21-2024",
            "source" : "IG",
            "promoname" : "dhileep-iugZ1",
            "visitdate" : "",
            "merchanthandle" : "dhileep",
            "phonenumber" : "08072433478",
            "visittime" : ""
          }
        }
      },
      "dhileep-8Ebn7" : {
        "3-20-2024" : {
          "dhileep_3gZAq" : {
            "redeemguid" : "3gZAq",
            "redeemTimestamp" : 1710955280694,
            "redeemdate" : "3-20-2024",
            "source" : "IG",
            "promoname" : "dhileep-8Ebn7",
            "visitdate" : "",
            "merchanthandle" : "dhileep",
            "phonenumber" : "09092793455",
            "visittime" : ""
          }
        }
      }
    },
    "info" : {
      "posKey" : "-",
      "instagramHandle" : "",
      "merchantHandle" : "dhileep",
      "companyName" : "chai shop",
      "posSystem" : "Unknown",
      "merchantName" : "chai shop",
      "tikTokHandle" : "",
      "twitterHandle" : ""
    },
    "promo-archive" : {
      "dhileep-8Ebn7" : {
        "style" : {
          "secondarycolor" : "BB0AB5",
          "imagename" : "espresso.png",
          "promotype" : "single-color",
          "primarycolor" : "FFD600"
        },
        "status" : "ACTIVE",
        "dateend" : "04-20-2024",
        "date_created" : "3\/20\/2024",
        "promomessage" : "Buy one espresso at full price, and get one free!",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "03-20-2024",
        "merchantname" : "dhileep",
        "promoimageheader" : "",
        "promoname" : "dhileep-8Ebn7",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Buy one get one free!",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "dhileep-oDUAg" : {
        "style" : {
          "secondarycolor" : "BB0AB5",
          "imagename" : "espresso.png",
          "promotype" : "single-color",
          "primarycolor" : "FFD600"
        },
        "status" : "ACTIVE",
        "dateend" : "04-01-2024",
        "date_created" : "3\/21\/2024",
        "promomessage" : "Buy one espresso at full price, and get one free!",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "03-07-2024",
        "merchantname" : "dhileep",
        "promoimageheader" : "",
        "promoname" : "dhileep-oDUAg",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Buy one get one free!",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "dhileep-pshqn" : {
        "style" : {
          "secondarycolor" : "BB0AB5",
          "imagename" : "espresso.png",
          "promotype" : "single-color",
          "primarycolor" : "FFD600"
        },
        "status" : "ACTIVE",
        "dateend" : "04-30-2024",
        "date_created" : "3\/21\/2024",
        "promomessage" : "Buy one espresso at full price, and get one free!",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "03-23-2024",
        "merchantname" : "dhileep",
        "promoimageheader" : "",
        "promoname" : "dhileep-pshqn",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Buy one get one free!",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "dhileep-5j1WM" : {
        "style" : {
          "secondarycolor" : "BB0AB5",
          "imagename" : "espresso.png",
          "promotype" : "single-color",
          "primarycolor" : "FFD600"
        },
        "status" : "ACTIVE",
        "dateend" : "03-26-2024",
        "date_created" : "3\/21\/2024",
        "promomessage" : "Buy one espresso at full price, and get one free!",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "03-15-2024",
        "merchantname" : "dhileep",
        "promoimageheader" : "",
        "promoname" : "dhileep-5j1WM",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Buy one get one free!",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "dhileep-a1Kas" : {
        "style" : {
          "secondarycolor" : "BB0AB5",
          "imagename" : "coldbrew.png",
          "promotype" : "single-color",
          "primarycolor" : "20201B"
        },
        "status" : "ACTIVE",
        "dateend" : "04-09-2024",
        "date_created" : "3\/20\/2024",
        "promomessage" : "20% off your entire order on your birthday.",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "03-07-2024",
        "merchantname" : "dhileep",
        "promoimageheader" : "",
        "promoname" : "dhileep-a1Kas",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Birthday Bash Bonus",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "dhileep-qAa9f" : {
        "style" : {
          "secondarycolor" : "BB0AB5",
          "imagename" : "espresso.png",
          "promotype" : "single-color",
          "primarycolor" : "FFD600"
        },
        "status" : "ACTIVE",
        "dateend" : "04-28-2024",
        "date_created" : "3\/21\/2024",
        "promomessage" : "Buy one espresso at full price, and get one free!",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "03-27-2024",
        "merchantname" : "dhileep",
        "promoimageheader" : "",
        "promoname" : "dhileep-qAa9f",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Buy one get one free!",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "dhileep-fsgoa" : {
        "style" : {
          "secondarycolor" : "BB0AB5",
          "imagename" : "espresso.png",
          "promotype" : "single-color",
          "primarycolor" : "FFD600"
        },
        "status" : "ACTIVE",
        "dateend" : "04-21-2024",
        "date_created" : "3\/21\/2024",
        "promomessage" : "Buy one espresso at full price, and get one free!",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "03-25-2024",
        "merchantname" : "dhileep",
        "promoimageheader" : "",
        "promoname" : "dhileep-fsgoa",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Buy one get one free!",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "dhileep-iugZ1" : {
        "style" : {
          "secondarycolor" : "BB0AB5",
          "imagename" : "espresso.png",
          "promotype" : "single-color",
          "primarycolor" : "FFD600"
        },
        "status" : "ACTIVE",
        "dateend" : "04-09-2024",
        "date_created" : "3\/21\/2024",
        "promomessage" : "Buy one espresso at full price, and get one free!",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "03-11-2024",
        "merchantname" : "dhileep",
        "promoimageheader" : "",
        "promoname" : "dhileep-iugZ1",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Buy one get one free!",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "dhileep-aYf9v" : {
        "style" : {
          "secondarycolor" : "BB0AB5",
          "imagename" : "espresso.png",
          "promotype" : "single-color",
          "primarycolor" : "FFD600"
        },
        "status" : "ACTIVE",
        "dateend" : "04-08-2024",
        "date_created" : "3\/21\/2024",
        "promomessage" : "Buy one espresso at full price, and get one free!",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "03-14-2024",
        "merchantname" : "dhileep",
        "promoimageheader" : "",
        "promoname" : "dhileep-aYf9v",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Buy one get one free!",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "dhileep-gfQi8" : {
        "style" : {
          "secondarycolor" : "BB0AB5",
          "imagename" : "espresso.png",
          "promotype" : "single-color",
          "primarycolor" : "FFD600"
        },
        "status" : "ACTIVE",
        "dateend" : "04-01-2024",
        "date_created" : "3\/21\/2024",
        "promomessage" : "Buy one espresso at full price, and get one free!",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "03-01-2024",
        "merchantname" : "dhileep",
        "promoimageheader" : "",
        "promoname" : "dhileep-gfQi8",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Buy one get one free!",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "dhileep-pknbx" : {
        "style" : {
          "secondarycolor" : "BB0AB5",
          "imagename" : "espresso.png",
          "promotype" : "single-color",
          "primarycolor" : "FFD600"
        },
        "status" : "ACTIVE",
        "dateend" : "04-10-2024",
        "date_created" : "3\/21\/2024",
        "promomessage" : "Buy one espresso at full price, and get one free!",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "03-08-2024",
        "merchantname" : "dhileep",
        "promoimageheader" : "",
        "promoname" : "dhileep-pknbx",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Buy one get one free!",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "dhileep-gP47D" : {
        "style" : {
          "secondarycolor" : "BB0AB5",
          "imagename" : "espresso.png",
          "promotype" : "single-color",
          "primarycolor" : "FFD600"
        },
        "status" : "ACTIVE",
        "dateend" : "04-16-2024",
        "date_created" : "3\/21\/2024",
        "promomessage" : "Buy one espresso at full price, and get one free!",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "03-15-2024",
        "merchantname" : "dhileep",
        "promoimageheader" : "",
        "promoname" : "dhileep-gP47D",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Buy one get one free!",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "dhileep-u1HRS" : {
        "style" : {
          "secondarycolor" : "BB0AB5",
          "imagename" : "espresso.png",
          "promotype" : "single-color",
          "primarycolor" : "FFD600"
        },
        "status" : "ACTIVE",
        "dateend" : "04-16-2024",
        "date_created" : "3\/20\/2024",
        "promomessage" : "Buy one espresso at full price, and get one free!",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "03-13-2024",
        "merchantname" : "dhileep",
        "promoimageheader" : "",
        "promoname" : "dhileep-u1HRS",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Buy one get one free!",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "dhileep-hC8T3" : {
        "style" : {
          "secondarycolor" : "BB0AB5",
          "imagename" : "espresso.png",
          "promotype" : "single-color",
          "primarycolor" : "FFD600"
        },
        "status" : "ACTIVE",
        "dateend" : "04-29-2024",
        "date_created" : "3\/20\/2024",
        "promomessage" : "Triple points on all drinks every Tuesday.",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "03-28-2024",
        "merchantname" : "dhileep",
        "promoimageheader" : "",
        "promoname" : "dhileep-hC8T3",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "old monk tea",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "dhileep-oqZZx" : {
        "style" : {
          "secondarycolor" : "BB0AB5",
          "imagename" : "espresso.png",
          "promotype" : "single-color",
          "primarycolor" : "FFD600"
        },
        "status" : "ACTIVE",
        "dateend" : "04-08-2024",
        "date_created" : "3\/21\/2024",
        "promomessage" : "Buy one espresso at full price, and get one free!",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "03-09-2024",
        "merchantname" : "dhileep",
        "promoimageheader" : "",
        "promoname" : "dhileep-oqZZx",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Buy one get one free!",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      }
    },
    "promos" : {
      "dhileep-7xGXk" : {
        "style" : {
          "secondarycolor" : "BB0AB5",
          "imagename" : "espresso.png",
          "promotype" : "single-color",
          "primarycolor" : "FFD600"
        },
        "status" : "ACTIVE",
        "dateend" : "04-21-2024",
        "date_created" : "3\/21\/2024",
        "promomessage" : "Buy one espresso at full price, and get one free!",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "03-26-2024",
        "merchantname" : "dhileep",
        "promoimageheader" : "",
        "promoname" : "dhileep-7xGXk",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Buy one get one free!",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      }
    }
  },
  "chartreusesisters" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/G0UKh6mvAoCpzWMGqp1V9nKe6kxrLFNc.png",
      "instagramHandle" : "chartreusesisters",
      "merchantHandle" : "chartreusesisters",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Chartreuse Sisters",
      "city" : "Grand Rapids",
      "companyName" : "Chartreuse Sisters",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.chartreusesisters.com\/",
      "email" : ""
    }
  },
  "bakesum" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 506-9298",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/16eeaf7bedec614e8f87bd4e0a5eed8c6e6bb64c\/original.png",
      "instagramHandle" : "bakesum",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "bakesum",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Bake Sum",
      "city" : "Oakland",
      "companyName" : "Bake Sum",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/bakesum.com\/",
      "email" : ""
    }
  },
  "bakeworksinc" : {
    "info" : {
      "state" : "",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "bakeworksinc",
      "instagramHandle" : " Inc.\"",
      "premium" : false,
      "merchantHandle" : "bakeworksinc",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "(360) 213-2001",
      "merchantName" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/TkShLOkzzdHEgrlefwPG0HidT0kyi1e4.png",
      "city" : "",
      "companyName" : "\"Bake Works",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : "http:\/\/bakeworks.com\/"
    }
  },
  "badbakersnatomas" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 900-4788",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/ec37ad90001f99b08d9d14f604d681772f5dd567\/original.png",
      "instagramHandle" : "badbakersnatomas",
      "merchantHandle" : "badbakersnatomas",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "BAD Bakers Natomas",
      "city" : "Sacramento",
      "companyName" : "BAD Bakers Natomas",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.badbakers.com\/sacramento",
      "email" : ""
    }
  },
  "sephora" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(951) 785-0717",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/4961ea7e59985a00f37a3b3919400980fb575e86\/original.jpeg",
      "instagramHandle" : "sephora",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "sephora",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "SEPHORA",
      "city" : "Riverside",
      "companyName" : "SEPHORA",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.sephora.com\/happening\/stores\/riverside?utm_source=gmb&utm_medium=Yext",
      "email" : ""
    }
  },
  "ruinsrestaurant" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(509) 474-1262",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "ruinsrestaurant",
      "twitterHandle" : "",
      "merchantHandle" : "ruinsrestaurant",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Ruins Restaurant",
      "city" : "Spokane",
      "companyName" : "Ruins Restaurant",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.exploretock.com\/ruins-spokane",
      "email" : ""
    }
  },
  "ricebunnonigiri" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(949) 774-1512",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/HInoaAMZ9Q5yCupdjqDr4AVkXGRAqiJA.png",
      "instagramHandle" : "ricebunnonigiri",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "ricebunnonigiri",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Ricebunn Onigiri",
      "city" : "Santa Ana",
      "companyName" : "Ricebunn Onigiri",
      "websiteUrl" : "http:\/\/www.ricebunn.com\/?utm_source=google&utm_medium=wix_google_business_profile&utm_campaign=14604603526099873964",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "telegraphministrycenterfoodpantrymannafromheavensoupkitchen" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(510) 961-4385",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/SMU3WOKu0xKVykuJvgM4nGb2x5SJ29aY.png",
      "instagramHandle" : "telegraphministrycenterfoodpantrymannafromheavensoupkitchen",
      "merchantHandle" : "telegraphministrycenterfoodpantrymannafromheavensoupkitchen",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Telegraph Ministry Center Food Pantry & Manna From Heaven Soup Kitchen",
      "city" : "Oakland",
      "companyName" : "Telegraph Ministry Center Food Pantry & Manna From Heaven Soup Kitchen",
      "websiteUrl" : "https:\/\/tmcoakland.org\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "twoladdsbakery" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(951) 381-1279",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "twoladdsbakery",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "twoladdsbakery",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Two Ladd’s Bakery",
      "city" : "San Bernardino",
      "companyName" : "Two Ladd’s Bakery",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "awakencaferoasting" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(510) 863-1440",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/00I1G4gXCaoNlT07TLiJKuSRpuBxgTzJ.png",
      "instagramHandle" : "awakencaferoasting",
      "merchantHandle" : "awakencaferoasting",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Awaken Cafe & Roasting",
      "city" : "Oakland",
      "companyName" : "Awaken Cafe & Roasting",
      "websiteUrl" : "http:\/\/www.awakencafe.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "freshnutritionalmeal" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(586) 770-8153",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/rAERAD35I0Yi8FB9mOvkUBOUBYIf6Tyl.png",
      "instagramHandle" : "freshnutritionalmeal",
      "merchantHandle" : "freshnutritionalmeal",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Fresh Nutritional Meal",
      "city" : "Warren",
      "companyName" : "Fresh Nutritional Meal",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "wakishiramen" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/LjdIzDgbRDjBWrE8UgdjYB40mqJSi33F.png",
      "instagramHandle" : "wakishiramen",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "wakishiramen",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Wakishi Ramen",
      "city" : "Long Beach",
      "companyName" : "Wakishi Ramen",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "claypotterystudio" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(562) 495-4362",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/5WRIQ7cV943pwwSkFyaoM1NAEVunRqJ4.png",
      "instagramHandle" : "claypotterystudio",
      "merchantHandle" : "claypotterystudio",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Clay Pottery Studio",
      "city" : "Long Beach",
      "companyName" : "Clay Pottery Studio",
      "websiteUrl" : "http:\/\/www.clayonfirst.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "tacostreet" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(253) 212-9468",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/6e87f9626338e72895d63466d44c15adf8092277\/original.png",
      "instagramHandle" : "tacostreet",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "tacostreet",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Taco Street",
      "city" : "Tacoma",
      "companyName" : "Taco Street",
      "websiteUrl" : "http:\/\/www.tacostreetfood.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "conniescakes" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(616) 455-6214",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ItKcafzcWxtWGtJCk056fjHUg7VAf0AV.png",
      "instagramHandle" : "conniescakes",
      "twitterHandle" : "",
      "merchantHandle" : "conniescakes",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Connie's Cakes",
      "city" : "Grand Rapids",
      "companyName" : "Connie's Cakes",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.iloveconniescakes.com\/",
      "email" : ""
    }
  },
  "cravecookie" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(559) 770-6018",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/AbzCJ09MRv61i0Pts3kNk5nmc6CTOrKe.png",
      "instagramHandle" : "cravecookie",
      "merchantHandle" : "cravecookie",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Crave Cookie",
      "city" : "Fresno",
      "companyName" : "Crave Cookie",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/cravecookie.com\/",
      "email" : ""
    }
  },
  "fortpointbeercompanyproductionbrewery" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 906-4021",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/arxKUJzdSGTtID7fvwYeROurn95cmJLk.png",
      "instagramHandle" : "fortpointbeercompanyproductionbrewery",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "fortpointbeercompanyproductionbrewery",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Fort Point Beer Company Production Brewery",
      "city" : "San Francisco",
      "companyName" : "Fort Point Beer Company Production Brewery",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/fortpointbeer.com\/locations\/fort-point-presidio-brewery",
      "email" : ""
    }
  },
  "kakaiba" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/vuJ4NuaKUkl0pIhCbGS1MrWKbgS0pHhK.png",
      "instagramHandle" : "kakaiba",
      "twitterHandle" : "",
      "merchantHandle" : "kakaiba",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Kakaiba",
      "city" : "Portland",
      "companyName" : "Kakaiba",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/kakaibabakery.com\/",
      "email" : ""
    }
  },
  "taqueriaeduardo" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(408) 295-7343",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "taqueriaeduardo",
      "merchantHandle" : "taqueriaeduardo",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Taqueria Eduardo",
      "city" : "San Jose",
      "companyName" : "Taqueria Eduardo",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "surcoffeehb" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(949) 216-0155",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/4c688486d69a1d4d34f136440082a45cc80f0ec7\/original.png",
      "instagramHandle" : "surcoffeehb",
      "merchantHandle" : "surcoffeehb",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sur Coffee HB",
      "city" : "Huntington Beach",
      "companyName" : "Sur Coffee HB",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.surcoffee.com\/",
      "email" : ""
    }
  },
  "below" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(503) 509-6434",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/C6WijgNMCQVoUIZOcK8GPwkUrmgGtXZb.png",
      "instagramHandle" : "below",
      "twitterHandle" : "",
      "merchantHandle" : "below",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "22 Below",
      "city" : "Portland",
      "companyName" : "22 Below",
      "websiteUrl" : "http:\/\/my22below.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "roysstationcoffeetea" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(408) 286-2236",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/l6deYMcCN65NjmLaznZE91TQ055TYc0Z.png",
      "instagramHandle" : "roysstationcoffeetea",
      "premium" : false,
      "merchantHandle" : "roysstationcoffeetea",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Roy's Station Coffee & Tea",
      "city" : "San Jose",
      "companyName" : "Roy's Station Coffee & Tea",
      "websiteUrl" : "http:\/\/roys-station.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "jazzysammies" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(541) 302-0749",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/0B8rr2090YNnJRlyuIo4QWAF8g6JXy1y.png",
      "instagramHandle" : "jazzysammies",
      "premium" : false,
      "merchantHandle" : "jazzysammies",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Jazzy Sammies",
      "city" : "Eugene",
      "companyName" : "Jazzy Sammies",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/jazzysammies.com\/",
      "email" : ""
    }
  },
  "thecatalyst" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(408) 564-1910",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/NQpsXf2MaOz6WiGD7a2whrx10kQaICVc.png",
      "instagramHandle" : "thecatalyst",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "thecatalyst",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Catalyst",
      "city" : "Stockton",
      "companyName" : "The Catalyst",
      "websiteUrl" : "https:\/\/www.catalyst926.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "delightfultaste" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(313) 365-0287",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "delightfultaste",
      "twitterHandle" : "",
      "merchantHandle" : "delightfultaste",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Delightful Taste",
      "city" : "Warren",
      "companyName" : "Delightful Taste",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "leaningtower" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 444-6824",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/6df0c3116eb7aa86ca4ef11f17530537\/original.png",
      "instagramHandle" : "leaningtower",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "leaningtower",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Leaning Tower",
      "city" : "Oakland",
      "companyName" : "Leaning Tower",
      "websiteUrl" : "http:\/\/www.leaningtowertogo.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "titostacosandgrillinc" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 485-8609",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/EAidEfC8cgmcP7aagrCnZWlR4dngG8qP.png",
      "instagramHandle" : "titostacosandgrillinc",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "titostacosandgrillinc",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Titos Tacos and Grill Inc.",
      "city" : "Modesto",
      "companyName" : "Titos Tacos and Grill Inc.",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "sunsetsquarespizzacraftbeer" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 624-3550",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/VuibmzkLwh4GCdxtpKt3AScukOxMjn3W.png",
      "instagramHandle" : "sunsetsquarespizzacraftbeer",
      "merchantHandle" : "sunsetsquarespizzacraftbeer",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Sunset Squares Pizza & Craft Beer",
      "city" : "San Francisco",
      "companyName" : "Sunset Squares Pizza & Craft Beer",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.sunsetsquares.com\/",
      "email" : ""
    }
  },
  "caffeinesupreme" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(661) 873-4712",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/XOnNK6hc4XolVKGinws9DouuIhaaep2V.png",
      "instagramHandle" : "caffeinesupreme",
      "premium" : false,
      "merchantHandle" : "caffeinesupreme",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Caffeine Supreme",
      "city" : "Bakersfield",
      "companyName" : "Caffeine Supreme",
      "websiteUrl" : "http:\/\/www.caffeinesupreme.coffee\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "hulatangshaveice" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 517-9270",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/mQIlSNzqGIbuRMFvCK5uEQbgkZchR93J.png",
      "instagramHandle" : "hulatangshaveice",
      "merchantHandle" : "hulatangshaveice",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Hula Tang Shave Ice",
      "city" : "Fontana",
      "companyName" : "Hula Tang Shave Ice",
      "websiteUrl" : "http:\/\/www.hulatangshave-ice.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "dessertoasiscoffeeroastersferndale" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(248) 677-3116",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/pSIE8ASTCwWzpbpAe6GpiY0kQhgUVjRZ.png",
      "instagramHandle" : "dessertoasiscoffeeroastersferndale",
      "merchantHandle" : "dessertoasiscoffeeroastersferndale",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Dessert Oasis Coffee Roasters Ferndale",
      "city" : "Warren",
      "companyName" : "Dessert Oasis Coffee Roasters Ferndale",
      "websiteUrl" : "http:\/\/www.docr.coffee\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "rainbowlsuperfoods" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(949) 880-8635",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "rainbowlsuperfoods",
      "merchantHandle" : "rainbowlsuperfoods",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Rainbowl Superfoods",
      "city" : "Santa Ana",
      "companyName" : "Rainbowl Superfoods",
      "websiteUrl" : "http:\/\/rainbowlsuperfoods.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "elitefoods" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ph0Ji3SEOR1pwB0u1gu6d0shwVVvh00d.png",
      "instagramHandle" : "elitefoods",
      "twitterHandle" : "",
      "merchantHandle" : "elitefoods",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Elite Foods",
      "city" : "Lansing",
      "companyName" : "Elite Foods",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "papermooncafe" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(408) 547-4234",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/927158a607b9d672bfd47ac5bd51ed3d3e10a063\/original.jpeg",
      "instagramHandle" : "papermooncafe",
      "twitterHandle" : "",
      "merchantHandle" : "papermooncafe",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Paper Moon Cafe",
      "city" : "San Jose",
      "companyName" : "Paper Moon Cafe",
      "websiteUrl" : "https:\/\/linktr.ee\/papermooncafe",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thegoodchocolate" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 910-2266",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Ul44Gul0XpJP06iolGy5zQgil3whV4nF.png",
      "instagramHandle" : "thegoodchocolate",
      "merchantHandle" : "thegoodchocolate",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Good Chocolate",
      "city" : "San Francisco",
      "companyName" : "The Good Chocolate",
      "websiteUrl" : "https:\/\/www.thegoodchocolate.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "kafncoffee" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(818) 696-2555",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/1Uqjy0ATfiyGnvaCFkAl3HGcd3dkgm05.png",
      "instagramHandle" : "kafncoffee",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "kafncoffee",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Kafn Coffee",
      "city" : "Glendale",
      "companyName" : "Kafn Coffee",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/kafn.coffee\/",
      "email" : ""
    }
  },
  "gabysgourmandise" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(616) 591-1840",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/wYFbuEiPNoXSMzIScSv1afC6q9OkX9vH.png",
      "instagramHandle" : "gabysgourmandise",
      "twitterHandle" : "",
      "merchantHandle" : "gabysgourmandise",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Gaby's Gourmandise",
      "city" : "Grand Rapids",
      "companyName" : "Gaby's Gourmandise",
      "websiteUrl" : "https:\/\/gabysgourmandise.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "cafemoto" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(619) 239-6686",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/lgtFVA3YV1pZXst9Vzcsw11f0hvaak4Q.png",
      "instagramHandle" : "cafemoto",
      "merchantHandle" : "cafemoto",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Cafe Moto",
      "city" : "Chula Vista",
      "companyName" : "Cafe Moto",
      "websiteUrl" : "http:\/\/cafemoto.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "kabobgyrohouse" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 463-3236",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ToY1m3oOdRljIUKM4rj3V5Fg8vLoWdF0.png",
      "instagramHandle" : "kabobgyrohouse",
      "merchantHandle" : "kabobgyrohouse",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Kabob & Gyro House",
      "city" : "Stockton",
      "companyName" : "Kabob & Gyro House",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.facebook.com\/KabobGyroHouse",
      "email" : ""
    }
  },
  "redonionfresno" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(559) 721-7781",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/a558080e39511fe7a9fac96cda8ff78352b11a3e\/original.png",
      "instagramHandle" : "redonionfresno",
      "twitterHandle" : "",
      "merchantHandle" : "redonionfresno",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Red Onion Fresno",
      "city" : "Fresno",
      "companyName" : "Red Onion Fresno",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "hihi" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(313) 698-0100",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/o9p2dN9g4lRzC5T60Ctla5LhLzMUR21m.png",
      "instagramHandle" : "hihi",
      "twitterHandle" : "",
      "merchantHandle" : "hihi",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "hihi",
      "city" : "Detroit",
      "companyName" : "hihi",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/hellohihi.com\/",
      "email" : ""
    }
  },
  "sanfranciscoshometowncreamery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 658-7103",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "sanfranciscoshometowncreamery",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "sanfranciscoshometowncreamery",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "San Francisco’s Hometown Creamery",
      "city" : "San Francisco",
      "companyName" : "San Francisco’s Hometown Creamery",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.sfhometowncreamery.com\/",
      "email" : ""
    }
  },
  "spicensteam" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(541) 654-0338",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/351472549462c4b5fc268d7b72fedd26b52de475\/original.png",
      "instagramHandle" : "spicensteam",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "spicensteam",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Spice N Steam",
      "city" : "Eugene",
      "companyName" : "Spice N Steam",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.spicensteam.com\/",
      "email" : ""
    }
  },
  "magicspicevegetarianindianeats" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(586) 272-2742",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/1f6ef42df11da58434a3ca001cc31bf443d68992\/original.png",
      "instagramHandle" : "magicspicevegetarianindianeats",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "magicspicevegetarianindianeats",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Magic Spice - Vegetarian Indian Eats",
      "city" : "Warren",
      "companyName" : "Magic Spice - Vegetarian Indian Eats",
      "websiteUrl" : "https:\/\/magicspicemi.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "islandbreezejamaicancuisine" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 514-0771",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/PMvOB9x6YM4R2koBN0B0B5pgaAtVFpuZ.png",
      "instagramHandle" : "islandbreezejamaicancuisine",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "islandbreezejamaicancuisine",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Island Breeze Jamaican Cuisine",
      "city" : "Fontana",
      "companyName" : "Island Breeze Jamaican Cuisine",
      "websiteUrl" : "http:\/\/www.ibjamaicancuisine.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "deltabornebrewing" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "deltabornebrewing",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "deltabornebrewing",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Delta Borne Brewing",
      "city" : "Sacramento",
      "companyName" : "Delta Borne Brewing",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.facebook.com\/DeltaBorneBrew",
      "email" : ""
    }
  },
  "bagelkitchengaslight" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(616) 805-3842",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "bagelkitchengaslight",
      "merchantHandle" : "bagelkitchengaslight",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Bagel Kitchen Gaslight",
      "city" : "Grand Rapids",
      "companyName" : "Bagel Kitchen Gaslight",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.bagelkitchengr.com\/",
      "email" : ""
    }
  },
  "parakeetcaf" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(619) 675-7731",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Sk9vsgTJDCqLqZYgdGf2kzs7k1AoRBGC.png",
      "instagramHandle" : "parakeetcaf",
      "twitterHandle" : "",
      "merchantHandle" : "parakeetcaf",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Parakeet Café",
      "city" : "Chula Vista",
      "companyName" : "Parakeet Café",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.parakeetcafe.com\/",
      "email" : ""
    }
  },
  "plantsdeliveryglendale" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(818) 650-2140",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/iHmwYNaOdW7ebdumdp70i4S0laNzL5Ca.png",
      "instagramHandle" : "plantsdeliveryglendale",
      "twitterHandle" : "",
      "merchantHandle" : "plantsdeliveryglendale",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Plants Delivery Glendale",
      "city" : "Glendale",
      "companyName" : "Plants Delivery Glendale",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.plantsdeliveryglendale.com\/",
      "email" : ""
    }
  },
  "labcafe" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(734) 827-2233",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/1ed754391d0d12ebc90244c8f0b8b3ca8577ab3c\/original.jpeg",
      "instagramHandle" : "labcafe",
      "twitterHandle" : "",
      "merchantHandle" : "labcafe",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "lab cafe",
      "city" : "Ann Arbor",
      "companyName" : "lab cafe",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/m.facebook.com\/labcafe\/",
      "email" : ""
    }
  },
  "thecookiedoughcaf" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(503) 477-7985",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/a0cXAdxPEOMaEajhJ4wJHHZB4b4QXa4E.png",
      "instagramHandle" : "thecookiedoughcaf",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "thecookiedoughcaf",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Cookie Dough Café",
      "city" : "Portland",
      "companyName" : "The Cookie Dough Café",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.thecookiedoughcafe.com\/",
      "email" : ""
    }
  },
  "vestergaardfarms" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(734) 929-2875",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/KGC9D19CDFA9zbOvrbO0gIvh0jhe0se5.png",
      "instagramHandle" : "vestergaardfarms",
      "premium" : false,
      "merchantHandle" : "vestergaardfarms",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Vestergaard Farms",
      "city" : "Ann Arbor",
      "companyName" : "Vestergaard Farms",
      "websiteUrl" : "http:\/\/vestergaardfarms.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "hamburgermarysontario" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 944-9400",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/xLWLVuRqL3wuqyrGCf94cqBLB4CKWiaS.png",
      "instagramHandle" : "hamburgermarysontario",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "hamburgermarysontario",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Hamburger Mary's Ontario",
      "city" : "San Bernardino",
      "companyName" : "Hamburger Mary's Ontario",
      "websiteUrl" : "http:\/\/www.hamburgermarys.com\/ontario-cal",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "boardwalkbagelscafe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(562) 233-2127",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/65d377a778f095e14b91260f391efe22c2a7df8a\/original.png",
      "instagramHandle" : "boardwalkbagelscafe",
      "twitterHandle" : "",
      "merchantHandle" : "boardwalkbagelscafe",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "Bagel Shop",
      "merchantName" : "Boardwalk Bagels Cafe",
      "city" : "Anaheim",
      "companyName" : "Boardwalk Bagels Cafe",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : "BWbagelsinfo@gmail.com"
    }
  },
  "thefixxjuicery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(323) 434-6415",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/0qYv7A7CfJGLyMp54VAgoHQJJJqbHyxt.png",
      "instagramHandle" : "thefixxjuicery",
      "premium" : false,
      "merchantHandle" : "thefixxjuicery",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Fixx Juicery",
      "city" : "Anaheim",
      "companyName" : "The Fixx Juicery",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "datikihut" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(253) 625-7690",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "datikihut",
      "merchantHandle" : "datikihut",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Da Tiki Hut",
      "city" : "Tacoma",
      "companyName" : "Da Tiki Hut",
      "websiteUrl" : "https:\/\/www.datikihut.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sciabicascaliforniaoliveoil" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 577-5067",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/65d91e49e518bdd4d3ce4173049c0c5e053af7b9\/original.png",
      "instagramHandle" : "sciabicascaliforniaoliveoil",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "sciabicascaliforniaoliveoil",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sciabica's California Olive Oil",
      "city" : "Stockton",
      "companyName" : "Sciabica's California Olive Oil",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/sunshineinabottle.com\/",
      "email" : ""
    }
  },
  "seeleyfarm" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(734) 604-2820",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/5qkaaoQODwhxBNcTLBhxnksIigoMNAwT.png",
      "instagramHandle" : "seeleyfarm",
      "premium" : false,
      "merchantHandle" : "seeleyfarm",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Seeley Farm",
      "city" : "Ann Arbor",
      "companyName" : "Seeley Farm",
      "websiteUrl" : "http:\/\/www.seeleyfarm.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "otherplacecatering" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(519) 969-6011",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/0238b203307cdec47c6127e2365e7478b0e95996\/original.jpeg",
      "instagramHandle" : "otherplacecatering",
      "merchantHandle" : "otherplacecatering",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Other Place Catering",
      "city" : "Detroit",
      "companyName" : "Other Place Catering",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.otherplacecatering.com\/",
      "email" : ""
    }
  },
  "littlepansantaana" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(657) 232-0154",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "littlepansantaana",
      "twitterHandle" : "",
      "merchantHandle" : "littlepansantaana",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Little Pan Santa Ana",
      "city" : "Santa Ana",
      "companyName" : "Little Pan Santa Ana",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.littlepanfriedbun.com\/",
      "email" : ""
    }
  },
  "jdswingzthingz" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 942-4470",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/AbC36QsBE3TSf5NoabNHC9NRaEFZvtDo.png",
      "instagramHandle" : "jdswingzthingz",
      "premium" : false,
      "merchantHandle" : "jdswingzthingz",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "Restaurant",
      "squareSandboxKey" : "",
      "merchantName" : "JD's WINGZ & THINGZ",
      "city" : "Anaheim",
      "companyName" : "JD's WINGZ & THINGZ",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/jdswingz.com\/",
      "email" : ""
    }
  },
  "outpostsandwiches" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(253) 327-1905",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/896846c3519206e292ad862cacb446f3beb8fd87\/original.png",
      "instagramHandle" : "outpostsandwiches",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "outpostsandwiches",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Outpost Sandwiches",
      "city" : "Tacoma",
      "companyName" : "Outpost Sandwiches",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/outpostsandwich.com\/",
      "email" : ""
    }
  },
  "raspadoseldiablitoloco" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(559) 224-3586",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/9425d9aa80c7de9651d561a5cc8bbc6445adcb24\/original.jpeg",
      "instagramHandle" : "raspadoseldiablitoloco",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "raspadoseldiablitoloco",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Raspados El Diablito Loco",
      "city" : "Fresno",
      "companyName" : "Raspados El Diablito Loco",
      "websiteUrl" : "https:\/\/diablitoloco.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "coffeecreamery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(313) 960-2084",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/25543d7e391644bdfabb18e7e66c90859cf98a7c\/original.jpeg",
      "instagramHandle" : "coffeecreamery",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "coffeecreamery",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "-320° Coffee & Creamery",
      "city" : "Detroit",
      "companyName" : "-320° Coffee & Creamery",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.320cnc.com\/",
      "email" : ""
    }
  },
  "frogtownbrewery" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(323) 452-2739",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/7cb9e0719a877aec8e418678304833aeb5e3808d\/original.png",
      "instagramHandle" : "frogtownbrewery",
      "merchantHandle" : "frogtownbrewery",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Frogtown Brewery",
      "city" : "Glendale",
      "companyName" : "Frogtown Brewery",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/frogtownbrewery.com\/",
      "email" : ""
    }
  },
  "chefskitchentogo" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 229-1522",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/xggNmdmPTaaZlRfam7N9pQ4N00uJpwa7.png",
      "instagramHandle" : "chefskitchentogo",
      "merchantHandle" : "chefskitchentogo",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Chef's Kitchen To-Go",
      "city" : "Moreno Valley",
      "companyName" : "Chef's Kitchen To-Go",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.chefskitchentogo.com\/",
      "email" : ""
    }
  },
  "anandafuara" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 621-1994",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/OCCCafNWChnbYDxc3uADTXEGedVWU04w.png",
      "instagramHandle" : "anandafuara",
      "merchantHandle" : "anandafuara",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Ananda Fuara",
      "city" : "San Francisco",
      "companyName" : "Ananda Fuara",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.anandafuara.com\/",
      "email" : ""
    }
  },
  "corkgabel" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(313) 638-2261",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "corkgabel",
      "merchantHandle" : "corkgabel",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Cork & Gabel",
      "city" : "Detroit",
      "companyName" : "Cork & Gabel",
      "websiteUrl" : "http:\/\/www.corkandgabel.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sidebiscuit" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(734) 531-6777",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/W2aPfzVHUb0BMsbm7rIywR0bOMr5S1WG.png",
      "instagramHandle" : "sidebiscuit",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "sidebiscuit",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Side Biscuit",
      "city" : "Ann Arbor",
      "companyName" : "Side Biscuit",
      "websiteUrl" : "http:\/\/sidebiscuit.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "theboilingbowl" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(971) 888-4255",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/0e148c573365a29c0127617821406e2e02e138e9\/original.jpeg",
      "instagramHandle" : "theboilingbowl",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "theboilingbowl",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Boiling Bowl",
      "city" : "Portland",
      "companyName" : "The Boiling Bowl",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/the-boiling-bowl.square.site\/",
      "email" : ""
    }
  },
  "devilishlittlethingsmuseum" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(503) 841-4232",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/ca68b654e3dec900b45b930a0db4d4ce76f4e0ed\/original.jpeg",
      "instagramHandle" : "devilishlittlethingsmuseum",
      "premium" : false,
      "merchantHandle" : "devilishlittlethingsmuseum",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Devil-ish Little Things Museum",
      "city" : "Vancouver",
      "companyName" : "Devil-ish Little Things Museum",
      "websiteUrl" : "https:\/\/www.facebook.com\/DEVILishLittleThingsMuseum\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "lawaffle" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(562) 430-8639",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "lawaffle",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "lawaffle",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "À La Waffle",
      "city" : "Long Beach",
      "companyName" : "À La Waffle",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/instagram.com\/alawaffle",
      "email" : ""
    }
  },
  "eugenesdumplingteahouse" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(541) 841-5871",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/246e6b53dbcc76db8121ec9cbde60107f540bf04\/original.png",
      "instagramHandle" : "eugenesdumplingteahouse",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "eugenesdumplingteahouse",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Eugene's Dumpling Tea House",
      "city" : "Eugene",
      "companyName" : "Eugene's Dumpling Tea House",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "venturasandwichcompany" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 641-1915",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/AgBRwT0y90BBBhwIVL0CUoGxBL9zN794.png",
      "instagramHandle" : "venturasandwichcompany",
      "merchantHandle" : "venturasandwichcompany",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Ventura Sandwich Company",
      "city" : "Oxnard",
      "companyName" : "Ventura Sandwich Company",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/venturasandwichcompany.com\/",
      "email" : ""
    }
  },
  "gooddillaquesadillas" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(509) 859-8579",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/225aa9352ec538041e7d270f82299873a206f570\/original.jpeg",
      "instagramHandle" : "gooddillaquesadillas",
      "merchantHandle" : "gooddillaquesadillas",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Good 'Dilla Quesadillas",
      "city" : "Spokane",
      "companyName" : "Good 'Dilla Quesadillas",
      "websiteUrl" : "https:\/\/gooddilla.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "christinasphillysteaks" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/1xDl8dNCc0LOEZH4pLcacC6KJhyDNKiN.png",
      "instagramHandle" : "christinasphillysteaks",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "christinasphillysteaks",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Christina's Philly Steaks",
      "city" : "Eugene",
      "companyName" : "Christina's Philly Steaks",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "frostmecafeandbakery" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(619) 287-2253",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Z2YlHlZyaTQxSSU8ilaxmcFoXEx79lPq.png",
      "instagramHandle" : "frostmecafeandbakery",
      "merchantHandle" : "frostmecafeandbakery",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Frost Me Cafe and Bakery",
      "city" : "Chula Vista",
      "companyName" : "Frost Me Cafe and Bakery",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.frostme.com\/",
      "email" : ""
    }
  },
  "sheltonsmobiledetailing" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(562) 306-8034",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/7f6e205e9208c2e2e35e875faa07fd83ecea8ad1\/original.png",
      "instagramHandle" : "sheltonsmobiledetailing",
      "premium" : false,
      "merchantHandle" : "sheltonsmobiledetailing",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Shelton's Mobile detailing",
      "city" : "Moreno Valley",
      "companyName" : "Shelton's Mobile detailing",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "calavera" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ul4O7T89reV8W4NM3UilhfswTnzaDPNt.png",
      "instagramHandle" : "calavera",
      "merchantHandle" : "calavera",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Calavera",
      "city" : "Oakland",
      "companyName" : "Calavera",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "pennyplus" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(586) 231-5220",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/MULZFjbEk4VqUSa0grL0wd4UGP3qcqsn.png",
      "instagramHandle" : "pennyplus",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "pennyplus",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Penny Plus",
      "city" : "Warren",
      "companyName" : "Penny Plus",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/m.facebook.com\/pennyplusclintontwp\/",
      "email" : ""
    }
  },
  "coffeecultures" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 796-3568",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/6e4be54fa2903c5323c410ade6fc85fdf4e4f425\/original.png",
      "instagramHandle" : "coffeecultures",
      "premium" : false,
      "merchantHandle" : "coffeecultures",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Coffee Cultures",
      "city" : "San Francisco",
      "companyName" : "Coffee Cultures",
      "websiteUrl" : "http:\/\/coffee-cultures.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "jazzyladiescafeclub" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(541) 636-9923",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/eBzaFbm06W1CVW8Ltsmg7CqDC458cIY9.png",
      "instagramHandle" : "jazzyladiescafeclub",
      "merchantHandle" : "jazzyladiescafeclub",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Jazzy Ladies Cafe & Club",
      "city" : "Eugene",
      "companyName" : "Jazzy Ladies Cafe & Club",
      "websiteUrl" : "http:\/\/www.jazzyladiescafe.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "iriekitchen" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(616) 512-4163",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/7OjKMB9Tu0IAdVG40BCAge3dKfz5MA1W.png",
      "instagramHandle" : "iriekitchen",
      "twitterHandle" : "",
      "merchantHandle" : "iriekitchen",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "IRIE KITCHEN",
      "city" : "Grand Rapids",
      "companyName" : "IRIE KITCHEN",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/irie.kitchen\/",
      "email" : ""
    }
  },
  "mashousechineseislamicrestaurant" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 446-9553",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/eLCxwdzxP3l0YKLpPzDzIZG9kgqhzWAs.png",
      "instagramHandle" : "mashousechineseislamicrestaurant",
      "merchantHandle" : "mashousechineseislamicrestaurant",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "Restaurant ",
      "squareSandboxKey" : "",
      "merchantName" : "Ma's House Chinese Islamic Restaurant",
      "city" : "Anaheim",
      "companyName" : "Ma's House Chinese Islamic Restaurant",
      "websiteUrl" : "https:\/\/www.mashouserestaurants.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thegreedygoaticecreamcerealbar" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/rB82uOBZUyyGkRzSeFoWK5A2b60IdWko.png",
      "instagramHandle" : "thegreedygoaticecreamcerealbar",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "thegreedygoaticecreamcerealbar",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Greedy Goat Ice Cream Cereal Bar",
      "city" : "Warren",
      "companyName" : "The Greedy Goat Ice Cream Cereal Bar",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thedollfacechef" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(248) 221-2672",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/37e120b982de06ed7d9b98344a5140b5106bc713\/original.png",
      "instagramHandle" : "thedollfacechef",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "thedollfacechef",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "\"The \"\"Doll Face Chef\"\"\"",
      "city" : "Warren",
      "companyName" : "\"The \"\"Doll Face Chef\"\"\"",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.dollfacechef.com\/",
      "email" : ""
    }
  },
  "bagelsgalore" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(310) 514-3699",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/7c26929155762557d731dd4b9baafb994fcd4be8\/original.jpeg",
      "instagramHandle" : "bagelsgalore",
      "premium" : false,
      "merchantHandle" : "bagelsgalore",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Bagels Galore",
      "city" : "Long Beach",
      "companyName" : "Bagels Galore",
      "websiteUrl" : "http:\/\/www.instagram.com\/bagelsgalore1982",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "baonkainanfilipinofoodcart" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/5a5ao1QsFFLWLFm4DoUSk9aa2RZKn1QB.png",
      "instagramHandle" : "baonkainanfilipinofoodcart",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "baonkainanfilipinofoodcart",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Baon Kainan Filipino Food Cart",
      "city" : "Vancouver",
      "companyName" : "Baon Kainan Filipino Food Cart",
      "websiteUrl" : "https:\/\/baonkainan.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "voltairecoffeeroasters" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(408) 747-9697",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/a0dfb85eb8dbd43e46dc38aa92c321559c9bc514\/original.jpeg",
      "instagramHandle" : "voltairecoffeeroasters",
      "merchantHandle" : "voltairecoffeeroasters",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Voltaire Coffee Roasters",
      "city" : "San Jose",
      "companyName" : "Voltaire Coffee Roasters",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.voltairecoffeeroasters.com\/",
      "email" : ""
    }
  },
  "yayabrewingcompany" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/LvrBoiTXnkoU0NEanKYwzfcyEbYWlJTy.png",
      "instagramHandle" : "yayabrewingcompany",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "yayabrewingcompany",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "YaYa Brewing Company",
      "city" : "Spokane",
      "companyName" : "YaYa Brewing Company",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.yayabrewing.com\/",
      "email" : ""
    }
  },
  "bobateahouseannarbor" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(734) 436-4794",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/xRrorJexuJCf6eK1URWq2uGVQeAOw45u.png",
      "instagramHandle" : "bobateahouseannarbor",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "bobateahouseannarbor",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Boba Tea House Ann Arbor",
      "city" : "Ann Arbor",
      "companyName" : "Boba Tea House Ann Arbor",
      "websiteUrl" : "http:\/\/bobateahouseannarbor.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "dish" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(313) 886-2444",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Gye1ZaNja5pVDT1HB9uh00HvQr71lO4d.png",
      "instagramHandle" : "dish",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "dish",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Dish",
      "city" : "Warren",
      "companyName" : "Dish",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.dishdetroit.biz\/",
      "email" : ""
    }
  },
  "dotxott" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(661) 324-7112",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/b2995850f8f0bb3f9f4cf933b94818f07bb37d33\/original.png",
      "instagramHandle" : "dotxott",
      "twitterHandle" : "",
      "merchantHandle" : "dotxott",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "dot x ott",
      "city" : "Bakersfield",
      "companyName" : "dot x ott",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "neighbormarket" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 763-2406",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "neighbormarket",
      "premium" : false,
      "merchantHandle" : "neighbormarket",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Neighbor Market",
      "city" : "San Bernardino",
      "companyName" : "Neighbor Market",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "bakedbyf" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/f168c2ceb34edb4d453ac531df8ff3041ad81775\/original.jpeg",
      "instagramHandle" : "bakedbyf",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "bakedbyf",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Baked By F",
      "city" : "Irvine",
      "companyName" : "Baked By F",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "familylawhouse" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(805) 253-0853",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/0c0OUCYjAakijm4LbMZAggAqMcI14Msl.png",
      "instagramHandle" : "familylawhouse",
      "twitterHandle" : "",
      "merchantHandle" : "familylawhouse",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Family Law House",
      "city" : "Oxnard",
      "companyName" : "Family Law House",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.familylawhouse.com\/",
      "email" : ""
    }
  },
  "birriago" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(805) 799-5149",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/UwIYWYAD7LVSaf0trVidP1JVObe0ToV4.png",
      "instagramHandle" : "birriago",
      "merchantHandle" : "birriago",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Birria 2 Go",
      "city" : "Oxnard",
      "companyName" : "Birria 2 Go",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "bobaplanetsandwiches" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 980-9834",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/ef5d510616e8873f7278df80e070e8b1\/original.jpeg",
      "instagramHandle" : "bobaplanetsandwiches",
      "twitterHandle" : "",
      "merchantHandle" : "bobaplanetsandwiches",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "BOBA PLANET & Sandwiches",
      "city" : "Fontana",
      "companyName" : "BOBA PLANET & Sandwiches",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "culantro" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(248) 632-1055",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/4gx3kX2JYk0U1aSm4hQVgiMdiMuh5Lz5.png",
      "instagramHandle" : "culantro",
      "premium" : false,
      "merchantHandle" : "culantro",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Culantro",
      "city" : "Warren",
      "companyName" : "Culantro",
      "websiteUrl" : "https:\/\/www.culantroperu.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "cornerstonechristianfellowship" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(951) 924-5466",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/12091c7d782ed1eb9a0e1191da75b7d4dfeadc97\/original.png",
      "instagramHandle" : "cornerstonechristianfellowship",
      "merchantHandle" : "cornerstonechristianfellowship",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Cornerstone Christian Fellowship",
      "city" : "Moreno Valley",
      "companyName" : "Cornerstone Christian Fellowship",
      "websiteUrl" : "http:\/\/gotoccf.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "chaupainbakery" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(949) 273-3597",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/FG0nC2z0Fa0p03zpq4qTQbBj7TM35gai.png",
      "instagramHandle" : "chaupainbakery",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "chaupainbakery",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Chaupain Bakery",
      "city" : "Irvine",
      "companyName" : "Chaupain Bakery",
      "websiteUrl" : "http:\/\/www.chaupainbakery.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "brothbar" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/baf5b185f5cb059e855cb20b3347366f2235a37a\/original.png",
      "instagramHandle" : "brothbar",
      "merchantHandle" : "brothbar",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Broth Bar",
      "city" : "Portland",
      "companyName" : "Broth Bar",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "cuppacuppa" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(562) 528-8920",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/6f8b0ce2b2965bf50a26690ccfafd15a783f083a\/original.png",
      "instagramHandle" : "cuppacuppa",
      "merchantHandle" : "cuppacuppa",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Cuppa Cuppa",
      "city" : "Long Beach",
      "companyName" : "Cuppa Cuppa",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "theflyingyolk" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(805) 210-5015",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/oLFtV82hnAef7wMHjU7EPfRvfs7kJmi8.png",
      "instagramHandle" : "theflyingyolk",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "theflyingyolk",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Flying Yolk",
      "city" : "Oxnard",
      "companyName" : "The Flying Yolk",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.theflyingyolk.com\/",
      "email" : ""
    }
  },
  "barverde" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(818) 502-9922",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ifuXEtvy07xK9qGhEVTJmKgcgCjSwM5m.png",
      "instagramHandle" : "barverde",
      "premium" : false,
      "merchantHandle" : "barverde",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Bar Verde",
      "city" : "Glendale",
      "companyName" : "Bar Verde",
      "websiteUrl" : "https:\/\/www.nordstrom.com\/store-details\/nordstrom-at-the-americana-at-brand?utm_source=google&utm_medium=organic&utm_campaign=food&utm_content=21340&utm_channel=low_nd_seo_local&sp_source=google&sp_campaign=food",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "reedssweetwine" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(253) 327-5220",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/9h7BBxd9ocXKXKfIQ00ZKB0tPeTUsVcU.png",
      "instagramHandle" : "reedssweetwine",
      "twitterHandle" : "",
      "merchantHandle" : "reedssweetwine",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Reed's Sweet Wine",
      "city" : "Tacoma",
      "companyName" : "Reed's Sweet Wine",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/reedssweetwine.com\/",
      "email" : ""
    }
  },
  "icecreamgarage" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(810) 458-4298",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/m06t4qAg1aE3yxzfBJsuNI9qG4BENMgX.png",
      "instagramHandle" : "icecreamgarage",
      "merchantHandle" : "icecreamgarage",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Ice Cream Garage",
      "city" : "Flint",
      "companyName" : "Ice Cream Garage",
      "websiteUrl" : "http:\/\/www.facebook.com\/icglinden",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "fuelbarhealth" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(949) 432-5140",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/EHhShPyNbFY0EoxyyjBwdmTwRChQToui.png",
      "instagramHandle" : "fuelbarhealth",
      "merchantHandle" : "fuelbarhealth",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Fuelbar Health",
      "city" : "Irvine",
      "companyName" : "Fuelbar Health",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/fuelbar-health.square.site\/",
      "email" : ""
    }
  },
  "crumblnorthdivision" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(509) 356-1124",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/XzuBST7mhlXLZVSEskrxjptsVEOgLvSa.png",
      "instagramHandle" : "crumblnorthdivision",
      "twitterHandle" : "",
      "merchantHandle" : "crumblnorthdivision",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Crumbl - North Division",
      "city" : "Spokane",
      "companyName" : "Crumbl - North Division",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/crumblcookies.com\/wanorthdivision?utm_source=google+business&utm_medium=profile&utm_campaign=CRUMBL-US+%7C+GOOGLE+BUSINESS+%7C+STORE+PAGE+%7C+ONGOING+%7C+CONSIDERATION+%7C+PROFILE",
      "email" : ""
    }
  },
  "v3pdx" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(503) 504-1955",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/E5amRR5fn6JKNsy6LtxEjSOyCFYPntfi.png",
      "instagramHandle" : "v3pdx",
      "twitterHandle" : "",
      "merchantHandle" : "v3pdx",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "V3 PDX",
      "city" : "Vancouver",
      "companyName" : "V3 PDX",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/v3-pdx.square.site\/",
      "email" : ""
    }
  },
  "suehirocafe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(213) 626-9132",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "suehirocafe",
      "premium" : false,
      "merchantHandle" : "suehirocafe",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Suehiro Cafe",
      "city" : "Glendale",
      "companyName" : "Suehiro Cafe",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "lotusfalafelandshawermaoakland" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(510) 444-0900",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/eeaFhPCZvp4gaxaZ5luQZqHE1qyOJAYB.png",
      "instagramHandle" : "lotusfalafelandshawermaoakland",
      "premium" : false,
      "merchantHandle" : "lotusfalafelandshawermaoakland",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Lotus Falafel and Shawerma-OAKLAND",
      "city" : "Oakland",
      "companyName" : "Lotus Falafel and Shawerma-OAKLAND",
      "websiteUrl" : "http:\/\/www.lotusfalafel.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "kyotojapanesesteakhouse" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(360) 256-3820",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/QAPrMSaBZCDZVPxd0dEomaYaifOrKGnV.png",
      "instagramHandle" : "kyotojapanesesteakhouse",
      "premium" : false,
      "merchantHandle" : "kyotojapanesesteakhouse",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Kyoto Japanese Steakhouse",
      "city" : "Vancouver",
      "companyName" : "Kyoto Japanese Steakhouse",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.kyotovancouver.com\/",
      "email" : ""
    }
  },
  "goodjocoffee" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 413-3043",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/8926378dd45e9cc5f8d5c1e183894ae7d33cbcfe\/original.jpeg",
      "instagramHandle" : "goodjocoffee",
      "merchantHandle" : "goodjocoffee",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Good Jo Coffee",
      "city" : "Sacramento",
      "companyName" : "Good Jo Coffee",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "thedetroitbubbleteacompany" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(248) 239-1131",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "thedetroitbubbleteacompany",
      "premium" : false,
      "merchantHandle" : "thedetroitbubbleteacompany",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Detroit Bubble Tea Company",
      "city" : "Detroit",
      "companyName" : "The Detroit Bubble Tea Company",
      "websiteUrl" : "https:\/\/detroitbubbletea.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "squibbcoffeeandwinebar" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(616) 350-9545",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/543782e7e9d9846ceb5f47c0e0dcdd5e6c48c50e\/original.jpeg",
      "instagramHandle" : "squibbcoffeeandwinebar",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "squibbcoffeeandwinebar",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Squibb Coffee and Wine Bar",
      "city" : "Grand Rapids",
      "companyName" : "Squibb Coffee and Wine Bar",
      "websiteUrl" : "http:\/\/squibbgr.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "marrispizzapasta" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 533-1631",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/ad4d4546758d06793341ee26c844c3765ed49401\/original.jpeg",
      "instagramHandle" : "marrispizzapasta",
      "merchantHandle" : "marrispizzapasta",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Marri's Pizza & Pasta",
      "city" : "Anaheim",
      "companyName" : "Marri's Pizza & Pasta",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/marrispizza.com\/",
      "email" : ""
    }
  },
  "theartofcrunch" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(253) 720-6180",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/vubJUjdcb1ZBpjKdfRBAHRRAvcwn2k4r.png",
      "instagramHandle" : "theartofcrunch",
      "premium" : false,
      "merchantHandle" : "theartofcrunch",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Art of Crunch",
      "city" : "Tacoma",
      "companyName" : "The Art of Crunch",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/theartofcrunch.com\/",
      "email" : ""
    }
  },
  "theshopbyrendezvouswinery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 414-8687",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/hgfqOXuXnGX0KzhF2BhA6Bs0zKeVI2ON.png",
      "instagramHandle" : "theshopbyrendezvouswinery",
      "premium" : false,
      "merchantHandle" : "theshopbyrendezvouswinery",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Shop by Rendez-vous Winery",
      "city" : "Sacramento",
      "companyName" : "The Shop by Rendez-vous Winery",
      "websiteUrl" : "https:\/\/www.rendez-vouswinery.com\/Visit-Us",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "cultivatecoffeeandtaphouse" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/nr70Uthar2TH6SvZBralGD6lU9znnV9g.png",
      "instagramHandle" : "cultivatecoffeeandtaphouse",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "cultivatecoffeeandtaphouse",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Cultivate Coffee And Tap House",
      "city" : "Ann Arbor",
      "companyName" : "Cultivate Coffee And Tap House",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "jmrautodetailing" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 642-9548",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/7f6e205e9208c2e2e35e875faa07fd83ecea8ad1\/original.png",
      "instagramHandle" : "jmrautodetailing",
      "premium" : false,
      "merchantHandle" : "jmrautodetailing",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "JMR Auto Detailing",
      "city" : "San Bernardino",
      "companyName" : "JMR Auto Detailing",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/instagram.com\/jmrautodetailing",
      "email" : ""
    }
  },
  "bluecanaryconfections" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(248) 792-7256",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/QW3aNPTnxJDCr45kR0eCGkRElZBrKl0Y.png",
      "instagramHandle" : "bluecanaryconfections",
      "twitterHandle" : "",
      "merchantHandle" : "bluecanaryconfections",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Blue Canary Confections",
      "city" : "Sterling Heights",
      "companyName" : "Blue Canary Confections",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.bluecanaryconfections.com\/",
      "email" : ""
    }
  },
  "cocoslipsmackingcupcakes" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(310) 290-6815",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/08f9a428646d6ae3706e6b75309e05fe145061d4\/original.png",
      "instagramHandle" : "cocoslipsmackingcupcakes",
      "merchantHandle" : "cocoslipsmackingcupcakes",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Coco's Lip Smacking Cupcakes",
      "city" : "Long Beach",
      "companyName" : "Coco's Lip Smacking Cupcakes",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.cocoslipsmackingcupcakes.com\/",
      "email" : ""
    }
  },
  "yugaa" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(949) 309-8598",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/noEntIeZCncZzl6xOWyjLepF6flR3ciD.png",
      "instagramHandle" : "yugaa",
      "merchantHandle" : "yugaa",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "YUGAa",
      "city" : "Anaheim",
      "companyName" : "YUGAa",
      "websiteUrl" : "https:\/\/www.yugaai.com\/contact\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "riversidehotdogcompany" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 856-3356",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/o5c3sIInEUSVxNlWay9q0IuCkBezdHpV.jpeg",
      "instagramHandle" : "riversidehotdogcompany",
      "twitterHandle" : "",
      "merchantHandle" : "riversidehotdogcompany",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Riverside Hot Dog Company",
      "city" : "Riverside",
      "companyName" : "Riverside Hot Dog Company",
      "websiteUrl" : "http:\/\/riversidehotdog.weebly.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "kitchenwoori" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 829-3159",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/5c9c9c533c81c9655b7b5c7ff68f329bad0995c9\/original.png",
      "instagramHandle" : "kitchenwoori",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "kitchenwoori",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Kitchen Woori",
      "city" : "San Francisco",
      "companyName" : "Kitchen Woori",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.kitchenwoori.com\/",
      "email" : ""
    }
  },
  "mollypitcherscafe" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(248) 667-9908",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/GFDfcYttGpqDhmFTbwO4mdfT1SWGhWAO.png",
      "instagramHandle" : "mollypitcherscafe",
      "merchantHandle" : "mollypitcherscafe",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Molly Pitcher's Cafe",
      "city" : "Ann Arbor",
      "companyName" : "Molly Pitcher's Cafe",
      "websiteUrl" : "http:\/\/molly-pitchers.square.site\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "rollnscoop" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/rv0LW0XQMCGBbxAvRcNVsM6vsRaFUFmT.png",
      "instagramHandle" : "rollnscoop",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "rollnscoop",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Roll 'n Scoop",
      "city" : "Irvine",
      "companyName" : "Roll 'n Scoop",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "piattino" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(971) 983-8000",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/fd1ee038fcf865724235332750d06a77a2d71c10\/original.png",
      "instagramHandle" : "piattino",
      "merchantHandle" : "piattino",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Piattino",
      "city" : "Portland",
      "companyName" : "Piattino",
      "websiteUrl" : "https:\/\/piattinopdx.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "exvotochocolatesandconfections" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(805) 667-9228",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/DlSY0Ivz0QtfgGGklJ2B62j64DBxE8gc.png",
      "instagramHandle" : "exvotochocolatesandconfections",
      "premium" : false,
      "merchantHandle" : "exvotochocolatesandconfections",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Ex Voto Chocolates and Confections",
      "city" : "Oxnard",
      "companyName" : "Ex Voto Chocolates and Confections",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.exvotochocolates.com\/",
      "email" : ""
    }
  },
  "lacentralurbangrillebeergarden" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(619) 932-6264",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "lacentralurbangrillebeergarden",
      "premium" : false,
      "merchantHandle" : "lacentralurbangrillebeergarden",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "La Central Urban Grille Beer Garden",
      "city" : "Chula Vista",
      "companyName" : "La Central Urban Grille Beer Garden",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "telavivdiamondco" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(503) 248-9118",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/b136e508f28a6ba8726137540d205e3428c6cfef\/original.png",
      "instagramHandle" : "telavivdiamondco",
      "merchantHandle" : "telavivdiamondco",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Tel Aviv Diamond Co",
      "city" : "Portland",
      "companyName" : "Tel Aviv Diamond Co",
      "websiteUrl" : "http:\/\/www.telavivdiamond.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "babycakessandiego" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(619) 296-4173",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/G0UPWwV5y4yY74wxijB37OB1wfSikyqt.png",
      "instagramHandle" : "babycakessandiego",
      "twitterHandle" : "",
      "merchantHandle" : "babycakessandiego",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Babycakes San Diego",
      "city" : "Chula Vista",
      "companyName" : "Babycakes San Diego",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.babycakessandiego.com\/",
      "email" : ""
    }
  },
  "i5phothenoodlesouphouse" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(253) 212-0487",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/a51729f296f9d6607feeb1d57ec1f4e0cf278dae\/original.png",
      "instagramHandle" : "i5phothenoodlesouphouse",
      "premium" : false,
      "merchantHandle" : "i5phothenoodlesouphouse",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "i5 pho - The noodle soup house",
      "city" : "Tacoma",
      "companyName" : "i5 pho - The noodle soup house",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.skiplinow.com\/shop\/310085j",
      "email" : ""
    }
  },
  "tatankatakeout" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(253) 752-8778",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/KCbKNVB99XmviO1em2JBPCvjhZk9OXsM.png",
      "instagramHandle" : "tatankatakeout",
      "merchantHandle" : "tatankatakeout",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Tatanka Take-Out",
      "city" : "Tacoma",
      "companyName" : "Tatanka Take-Out",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/tatanka-to.com\/",
      "email" : ""
    }
  },
  "casajalisco" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 373-0800",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/OoJJpLg5CZbZq8gpn7UHJwBISKFcJa4G.png",
      "instagramHandle" : "casajalisco",
      "merchantHandle" : "casajalisco",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Casa Jalisco",
      "city" : "Sacramento",
      "companyName" : "Casa Jalisco",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/casa-jaliscoca.com\/",
      "email" : ""
    }
  },
  "macleodalebrewingco" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(818) 631-1963",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/wd0EmYR0PZapTsf7oc9wnL1AFgnash4g.png",
      "instagramHandle" : "macleodalebrewingco",
      "premium" : false,
      "merchantHandle" : "macleodalebrewingco",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "MacLeod Ale Brewing Co.",
      "city" : "Glendale",
      "companyName" : "MacLeod Ale Brewing Co.",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.macleodale.com\/",
      "email" : ""
    }
  },
  "hunangardenchineserestaurant" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(805) 988-9395",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/2d33e7028456cc835acdcb0d52416da9fd154d56\/original.png",
      "instagramHandle" : "hunangardenchineserestaurant",
      "merchantHandle" : "hunangardenchineserestaurant",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Hunan Garden Chinese Restaurant",
      "city" : "Oxnard",
      "companyName" : "Hunan Garden Chinese Restaurant",
      "websiteUrl" : "http:\/\/www.hunangardenofoxnard.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "biercamp" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(734) 995-2437",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/VrkiFy7dYZrgDibMT2Y0usrAuDVaKGa0.png",
      "instagramHandle" : "biercamp",
      "merchantHandle" : "biercamp",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Biercamp",
      "city" : "Ann Arbor",
      "companyName" : "Biercamp",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.bier-camp.com\/",
      "email" : ""
    }
  },
  "inomonivegandimsum" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(213) 344-8085",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/JGmHXPbvtb10emcMLAZs9cuyuDtsMR3r.png",
      "instagramHandle" : "inomonivegandimsum",
      "merchantHandle" : "inomonivegandimsum",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Inomoni Vegan Dim Sum",
      "city" : "Glendale",
      "companyName" : "Inomoni Vegan Dim Sum",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "veganpizzagardengrove" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 750-8686",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/7eYreaIymffFjmrmEjWNzDWPLGrLL6TK.png",
      "instagramHandle" : "veganpizzagardengrove",
      "twitterHandle" : "",
      "merchantHandle" : "veganpizzagardengrove",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Vegan Pizza - Garden Grove",
      "city" : "Santa Ana",
      "companyName" : "Vegan Pizza - Garden Grove",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/veganpizzaoc.square.site\/",
      "email" : ""
    }
  },
  "maruposeggtarts" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(734) 707-1084",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/DuNpGZAyYpMqu1R0Gc05f0QeZ0EXizRk.png",
      "instagramHandle" : "maruposeggtarts",
      "twitterHandle" : "",
      "merchantHandle" : "maruposeggtarts",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Marupo's Egg Tarts",
      "city" : "Ann Arbor",
      "companyName" : "Marupo's Egg Tarts",
      "websiteUrl" : "https:\/\/marupoeats.square.site\/our-products",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "blankbeverage" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(213) 600-7778",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/0DaHYQ8O39tmABtl7cDaOSBK6nPkswDF.png",
      "instagramHandle" : "blankbeverage",
      "premium" : false,
      "merchantHandle" : "blankbeverage",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Blank Beverage",
      "city" : "Glendale",
      "companyName" : "Blank Beverage",
      "websiteUrl" : "http:\/\/www.blankbeverage.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "funolcakeslasfunnelcake" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(818) 929-4466",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/9aa7ec4dce71b98afa0e9cab4399a8d944bced26\/original.jpeg",
      "instagramHandle" : "funolcakeslasfunnelcake",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "funolcakeslasfunnelcake",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Fun Ol' Cakes - LA’s Funnel Cake",
      "city" : "Glendale",
      "companyName" : "Fun Ol' Cakes - LA’s Funnel Cake",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/instagram.com\/funnelcakesla",
      "email" : ""
    }
  },
  "theteashack" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 846-9736",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/bLRaJThw9c1okJ3joElLcEJiXC7MZPSq.png",
      "instagramHandle" : "theteashack",
      "premium" : false,
      "merchantHandle" : "theteashack",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Tea Shack",
      "city" : "Modesto",
      "companyName" : "The Tea Shack",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "tacotruck" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(619) 806-4171",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/qhODCiJlrfqIAsYYvPa36vSNWk7Z0cpc.png",
      "instagramHandle" : "tacotruck",
      "premium" : false,
      "merchantHandle" : "tacotruck",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Taco truck",
      "city" : "Chula Vista",
      "companyName" : "Taco truck",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "hotbasketbakeshop" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 957-8992",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/aYLitQrRyAofb3zEx805p7MjlVzRRt6y.png",
      "instagramHandle" : "hotbasketbakeshop",
      "merchantHandle" : "hotbasketbakeshop",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Hot Basket Bake Shop",
      "city" : "Stockton",
      "companyName" : "Hot Basket Bake Shop",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "tasteoftea" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(586) 765-1280",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/b2aafc3dffd4704e556b5b3fada48e347ca8eda3\/original.png",
      "instagramHandle" : "tasteoftea",
      "premium" : false,
      "merchantHandle" : "tasteoftea",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Taste Of Tea",
      "city" : "Warren",
      "companyName" : "Taste Of Tea",
      "websiteUrl" : "http:\/\/www.tasteoftea.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "ilapatkirealestateagentcompass" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(408) 634-2233",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "ilapatkirealestateagentcompass",
      "merchantHandle" : "ilapatkirealestateagentcompass",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Ila Patki - Real Estate Agent - Compass",
      "city" : "San Jose",
      "companyName" : "Ila Patki - Real Estate Agent - Compass",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.compass.com\/agents\/ila-patki\/",
      "email" : ""
    }
  },
  "kcpropertyservicellc" : {
    "info" : {
      "state" : "",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "kcpropertyservicellc",
      "instagramHandle" : " LLC\"",
      "twitterHandle" : "",
      "merchantHandle" : "kcpropertyservicellc",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "(248) 586-9700",
      "merchantName" : "No Logo Image Found on Website",
      "city" : "",
      "companyName" : "\"K C Property Service",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : "http:\/\/kcpropertyservice.com\/"
    }
  },
  "themasalatwist" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 832-4945",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "themasalatwist",
      "merchantHandle" : "themasalatwist",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Masala Twist",
      "city" : "Oxnard",
      "companyName" : "The Masala Twist",
      "websiteUrl" : "https:\/\/www.themasalatwistoxnard.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "drutherssutterpark" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 942-9286",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/eMfY7CPhz4WrnhU42ZnQ9bIq0T0U6Fak.png",
      "instagramHandle" : "drutherssutterpark",
      "twitterHandle" : "",
      "merchantHandle" : "drutherssutterpark",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Druthers Sutter Park",
      "city" : "Sacramento",
      "companyName" : "Druthers Sutter Park",
      "websiteUrl" : "http:\/\/drutherssutterpark.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "coffeeworksbypear" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(503) 228-6677",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/x39ddhyNSLgMbExz8O9Vcta8kYPUrHGe.png",
      "instagramHandle" : "coffeeworksbypear",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "coffeeworksbypear",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "coffee works by p:ear",
      "city" : "Portland",
      "companyName" : "coffee works by p:ear",
      "websiteUrl" : "https:\/\/www.facebook.com\/pearcoffeewindow\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "flourbloom" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(971) 888-2256",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/oJgwRH9ZO3ZYcwGY2XGCDGDKCgsUiaKP.jpeg",
      "instagramHandle" : "flourbloom",
      "merchantHandle" : "flourbloom",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Flour Bloom",
      "city" : "Portland",
      "companyName" : "Flour Bloom",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/flourbloompdx.com\/",
      "email" : ""
    }
  },
  "vibehealthbar" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 382-9723",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/XmHWioHPUaqnxlhiN0wCxk6cPhzAarns.png",
      "instagramHandle" : "vibehealthbar",
      "premium" : false,
      "merchantHandle" : "vibehealthbar",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Vibe Health Bar",
      "city" : "Sacramento",
      "companyName" : "Vibe Health Bar",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/vibehealthbar.com\/",
      "email" : ""
    }
  },
  "rayspizza" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(949) 854-5044",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/ad4d4546758d06793341ee26c844c3765ed49401\/original.jpeg",
      "instagramHandle" : "rayspizza",
      "merchantHandle" : "rayspizza",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Ray's Pizza",
      "city" : "Irvine",
      "companyName" : "Ray's Pizza",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.rayspizzainc.com\/",
      "email" : ""
    }
  },
  "popshop" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/g0N9vxPiUK1FpAA7BWdJO8i9fA2QQ6Zp.png",
      "instagramHandle" : "popshop",
      "premium" : false,
      "merchantHandle" : "popshop",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "POP shop",
      "city" : "San Bernardino",
      "companyName" : "POP shop",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/pursuitofperfection.bigcartel.com\/",
      "email" : ""
    }
  },
  "bostwickcafe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(253) 302-5296",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/B1FWQAK4jsCvw8r5H2xQb8m8o4dJBNBx.png",
      "instagramHandle" : "bostwickcafe",
      "premium" : false,
      "merchantHandle" : "bostwickcafe",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Bostwick Cafe",
      "city" : "Tacoma",
      "companyName" : "Bostwick Cafe",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/bostwickcafetacoma.com\/",
      "email" : ""
    }
  },
  "milkmoney" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/f475eeb3112f9bcd0816289760348665d9e07528\/original.png",
      "instagramHandle" : "milkmoney",
      "premium" : false,
      "merchantHandle" : "milkmoney",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Milk Money",
      "city" : "Vancouver",
      "companyName" : "Milk Money",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.milk-money.com\/",
      "email" : ""
    }
  },
  "sunsourcesystemstechnologies" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(586) 977-6900",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/V4ROXwExFuO0e0rcrtk23M2IiHpoKvzw.png",
      "instagramHandle" : "sunsourcesystemstechnologies",
      "merchantHandle" : "sunsourcesystemstechnologies",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sunsource Systems Technologies",
      "city" : "Sterling Heights",
      "companyName" : "Sunsource Systems Technologies",
      "websiteUrl" : "https:\/\/www.sun-source.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "fleetwood" : {
    "promos" : {
      "cafeluna-xh79e" : {
        "style" : {
          "imagename" : "img_030.png",
          "stylename" : "img_030",
          "textcolor" : "EDB537",
          "additionalcolor" : "FDD99F",
          "backgroundcolor" : "371B17"
        },
        "status" : "ACTIVE",
        "dateend" : "05-17-2023",
        "date_created" : "4\/20\/2023",
        "promomessage" : "Buy 1 iced coffee, get 1 50% off.",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "05-15-2023",
        "merchantname" : "cafeluna",
        "promoimageheader" : "B",
        "promoname" : "cafeluna-xh79e",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Coffee o'clock!",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      }
    },
    "promo-archive" : {
      "cafeluna-af5YG" : {
        "style" : {
          "imagename" : "img_041.png",
          "stylename" : "img_041",
          "textcolor" : "FEE6C6",
          "additionalcolor" : "FFA596",
          "backgroundcolor" : "50092C"
        },
        "status" : "ACTIVE",
        "dateend" : "04-22-2023",
        "date_created" : "4\/19\/2023",
        "promomessage" : "Buy 3 espresso drinks and get 1 free.",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "04-19-2023",
        "merchantname" : "cafeluna",
        "promoimageheader" : "img_055",
        "promoname" : "cafeluna-af5YG",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Double shot, double reward!",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "cafeluna-aroXQ" : {
        "style" : {
          "imagename" : "img_044.png",
          "stylename" : "img_044",
          "textcolor" : "FF8769",
          "additionalcolor" : "0CD4FF",
          "backgroundcolor" : "520045"
        },
        "status" : "ACTIVE",
        "dateend" : "05-17-2023",
        "date_created" : "4\/20\/2023",
        "promomessage" : "Buy 6 espresso shots and get 2 free.",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "04-20-2023",
        "merchantname" : "cafeluna",
        "promoimageheader" : "",
        "promoname" : "cafeluna-aroXQ",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Wake up and smell the espresso!",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "cafeluna-vxajU" : {
        "style" : {
          "imagename" : "img_034.png",
          "stylename" : "img_034",
          "textcolor" : "F9FF00",
          "additionalcolor" : "FF7712",
          "backgroundcolor" : "680575"
        },
        "status" : "ACTIVE",
        "dateend" : "05-12-2023",
        "date_created" : "5\/10\/2023",
        "promomessage" : "Get 50% off frappe drinks from 4-6pm every weekday.",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "05-10-2023",
        "merchantname" : "cafeluna",
        "promoimageheader" : "",
        "promoname" : "cafeluna-vxajU",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Go Lakers!",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "cafeluna-6uhtv" : {
        "promoname" : "cafeluna-6uhtv",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "status" : "ACTIVE",
        "timezone" : "",
        "promotype" : "promotion",
        "squareloyaltyID" : "",
        "promobuttontext" : "Redeem",
        "promoshowterms" : "YES",
        "square_loyalty_program_id" : "",
        "promotitle" : "Matcha-mazing deals!",
        "promomessage" : "Buy 2 matcha lattes and get 1 50% off.",
        "date_created" : "4\/15\/2023",
        "redemptionlimit" : "10",
        "datetimeend" : "",
        "dateend" : "04-29-2023",
        "datestart" : "04-15-2023",
        "datetimestart" : "",
        "merchantname" : "cafeluna",
        "promoimageheader" : "img_042"
      },
      "cafeluna-cPEzh" : {
        "style" : {
          "imagename" : "img_110.png",
          "stylename" : "img_110",
          "textcolor" : "FFF4E1",
          "additionalcolor" : "FEC193",
          "backgroundcolor" : "713a7f"
        },
        "status" : "ACTIVE",
        "dateend" : "06-13-2023",
        "date_created" : "5\/5\/2023",
        "promomessage" : "Buy 1 iced tea, get 1 free.",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "05-04-2023",
        "merchantname" : "cafeluna",
        "promoimageheader" : "",
        "promoname" : "cafeluna-cPEzh",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Refresh your day!",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "cafeluna-db3nb" : {
        "style" : {
          "imagename" : "img_010.png",
          "stylename" : "img_010",
          "textcolor" : "D79C68",
          "additionalcolor" : "C16D1C",
          "backgroundcolor" : "07100B"
        },
        "status" : "ACTIVE",
        "dateend" : "05-17-2023",
        "date_created" : "4\/20\/2023",
        "promomessage" : "Spend $15 on caramel macchiatos and get 10% off.",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "05-15-2023",
        "merchantname" : "cafeluna",
        "promoimageheader" : "B",
        "promoname" : "cafeluna-db3nb",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Get your daily dose of caramel!",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "cafeluna-bNKfT" : {
        "style" : {
          "imagename" : "img_039.png",
          "stylename" : "img_039",
          "textcolor" : "ff7c00",
          "additionalcolor" : "FDFEFE",
          "backgroundcolor" : "000000"
        },
        "status" : "ACTIVE",
        "dateend" : "06-13-2023",
        "date_created" : "5\/5\/2023",
        "promomessage" : "Spend $15 on caramel macchiatos and get 20% off.",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "05-04-2023",
        "merchantname" : "cafeluna",
        "promoimageheader" : "",
        "promoname" : "cafeluna-bNKfT",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Giant Size Deal",
        "redemptionlimit" : "5",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "cafeluna-p21Yp" : {
        "style" : {
          "imagename" : "img_057.png",
          "stylename" : "img_057",
          "textcolor" : "fffe00",
          "additionalcolor" : "00A1A4",
          "backgroundcolor" : "e97d21"
        },
        "status" : "ACTIVE",
        "dateend" : "06-13-2023",
        "date_created" : "5\/5\/2023",
        "promomessage" : "Spend $15 on caramel macchiatos and get 10% off.",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "05-04-2023",
        "merchantname" : "cafeluna",
        "promoimageheader" : "",
        "promoname" : "cafeluna-p21Yp",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Get your daily dose of caramel!",
        "redemptionlimit" : "5",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "cafeluna-4SqAB" : {
        "style" : {
          "imagename" : "img_035.png",
          "stylename" : "img_035",
          "textcolor" : "FED1A3",
          "additionalcolor" : "FF9404",
          "backgroundcolor" : "000610"
        },
        "status" : "ACTIVE",
        "dateend" : "05-24-2023",
        "date_created" : "4\/19\/2023",
        "promomessage" : "Spend $20 on mocha drinks and get 15% off.",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "04-20-2023",
        "merchantname" : "cafeluna",
        "promoimageheader" : "c",
        "promoname" : "cafeluna-4SqAB",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Mocha your day better!",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "cafeluna-eNJ4R" : {
        "style" : {
          "imagename" : "img_044.png",
          "stylename" : "img_044",
          "textcolor" : "241fa8",
          "additionalcolor" : "0CD4FF",
          "backgroundcolor" : "7ed321"
        },
        "status" : "ACTIVE",
        "dateend" : "06-13-2023",
        "date_created" : "5\/5\/2023",
        "promomessage" : "Buy 3 espresso drinks and get 1 free. ",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "05-04-2023",
        "merchantname" : "cafeluna",
        "promoimageheader" : "",
        "promoname" : "cafeluna-eNJ4R",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Double shot, double reward! ",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "cafeluna-rohpr" : {
        "style" : {
          "imagename" : "img_090.png",
          "stylename" : "img_090",
          "textcolor" : "F3E380",
          "additionalcolor" : "9FB29C",
          "backgroundcolor" : "002220"
        },
        "status" : "ACTIVE",
        "dateend" : "05-17-2023",
        "date_created" : "4\/22\/2023",
        "promomessage" : "Buy 3 croissants and get 1 free.",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "04-13-2023",
        "merchantname" : "cafeluna",
        "promoimageheader" : "",
        "promoname" : "cafeluna-rohpr",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Savor the flaky goodness of croissants!",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "cafeluna-naZfy" : {
        "style" : {
          "imagename" : "img_096.png",
          "stylename" : "img_096",
          "textcolor" : "EED1AE",
          "additionalcolor" : "FFEED2",
          "backgroundcolor" : "283B2B"
        },
        "status" : "ACTIVE",
        "dateend" : "05-17-2023",
        "date_created" : "4\/20\/2023",
        "promomessage" : "Buy 1 chai latte, get 1 free.",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "04-20-2023",
        "merchantname" : "cafeluna",
        "promoimageheader" : "",
        "promoname" : "cafeluna-naZfy",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Satisfy your sweet tooth with chai lattes!",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "cafeluna-nvCL6" : {
        "style" : {
          "imagename" : "img_041.png",
          "stylename" : "img_041",
          "textcolor" : "FEE6C6",
          "additionalcolor" : "FFA596",
          "backgroundcolor" : "50092C"
        },
        "status" : "ACTIVE",
        "dateend" : "06-08-2023",
        "date_created" : "5\/3\/2023",
        "promomessage" : "Spend $20 on matcha lattes and get 15% off.",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "05-11-2023",
        "merchantname" : "cafeluna",
        "promoimageheader" : "",
        "promoname" : "cafeluna-nvCL6",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Matcha made in heaven!",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "cafeluna-uYuX9" : {
        "style" : {
          "imagename" : "img_042.png",
          "stylename" : "img_042",
          "textcolor" : "F37A00",
          "additionalcolor" : "60946E",
          "backgroundcolor" : "00322E"
        },
        "status" : "ACTIVE",
        "dateend" : "05-09-2023",
        "date_created" : "4\/20\/2023",
        "promomessage" : "Get 25% off frappe drinks from 12-3pm every weekday.",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "04-06-2023",
        "merchantname" : "cafeluna",
        "promoimageheader" : "c",
        "promoname" : "cafeluna-uYuX9",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Beat the heat with our frappes!",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "cafeluna-h3D1Z" : {
        "style" : {
          "imagename" : "img_046.png",
          "stylename" : "img_046",
          "textcolor" : "FFC240",
          "additionalcolor" : "FF7B2F",
          "backgroundcolor" : "033343"
        },
        "status" : "ACTIVE",
        "dateend" : "05-24-2023",
        "date_created" : "4\/19\/2023",
        "promomessage" : "Buy 1 fruit smoothie, get 1 50% off.",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "04-20-2023",
        "merchantname" : "cafeluna",
        "promoimageheader" : "c",
        "promoname" : "cafeluna-h3D1Z",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Sip on some savings!",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "cafeluna-udYJa" : {
        "style" : {
          "imagename" : "img_010.png",
          "stylename" : "img_010",
          "textcolor" : "D79C68",
          "additionalcolor" : "C16D1C",
          "backgroundcolor" : "07100B"
        },
        "status" : "ACTIVE",
        "dateend" : "05-17-2023",
        "date_created" : "4\/20\/2023",
        "promomessage" : "Spend $15 on caramel macchiatos and get 10% off.",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "05-15-2023",
        "merchantname" : "cafeluna",
        "promoimageheader" : "B",
        "promoname" : "cafeluna-udYJa",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Get your daily dose of caramel!",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "cafeluna-tXnp6" : {
        "style" : {
          "imagename" : "img_044.png",
          "stylename" : "img_044",
          "textcolor" : "7037cf",
          "additionalcolor" : "0CD4FF",
          "backgroundcolor" : "ff00d9"
        },
        "status" : "ACTIVE",
        "dateend" : "06-13-2023",
        "date_created" : "5\/5\/2023",
        "promomessage" : "Buy 3 espresso drinks and get 1 free. ",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "05-04-2023",
        "merchantname" : "cafeluna",
        "promoimageheader" : "",
        "promoname" : "cafeluna-tXnp6",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Double shot, double reward! ",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      }
    },
    "analytics" : {
      "records" : {
        "landing-page" : {
          "uTmnEtQi" : {
            "pathname" : "\/fleetwood",
            "time" : "2024-06-16T06:30:01.809Z",
            "merchantHandle" : "fleetwood",
            "host" : "localhost:3000",
            "timezone" : "America\/Los_Angeles",
            "userAgent" : "Mozilla\/5.0 (iPhone; CPU iPhone OS 16_6 like Mac OS X) AppleWebKit\/605.1.15 (KHTML, like Gecko) Version\/16.6 Mobile\/15E148 Safari\/604.1",
            "page" : "landing-page\/"
          },
          "c8JKRfy7" : {
            "pathname" : "\/fleetwood",
            "time" : "2024-04-17T23:59:08.324Z",
            "merchantHandle" : "fleetwood",
            "host" : "cafe.bio",
            "timezone" : "America\/Los_Angeles",
            "userAgent" : "Mozilla\/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/123.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "wwcZc2vY" : {
            "pathname" : "\/fleetwood",
            "time" : "2024-04-17T23:59:38.267Z",
            "merchantHandle" : "fleetwood",
            "host" : "cafe.bio",
            "timezone" : "America\/Los_Angeles",
            "userAgent" : "Mozilla\/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/123.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "wc9BDPKt" : {
            "pathname" : "\/fleetwood",
            "time" : "2024-06-16T06:30:01.805Z",
            "merchantHandle" : "fleetwood",
            "host" : "localhost:3000",
            "timezone" : "America\/Los_Angeles",
            "userAgent" : "Mozilla\/5.0 (iPhone; CPU iPhone OS 16_6 like Mac OS X) AppleWebKit\/605.1.15 (KHTML, like Gecko) Version\/16.6 Mobile\/15E148 Safari\/604.1",
            "page" : "landing-page\/"
          }
        }
      }
    },
    "pos-info" : {
      "square" : {
        "production" : {
          "catalog" : [
            {
              "discountData" : {
                "discountType" : "FIXED_PERCENTAGE",
                "name" : "20% off your next purchase",
                "percentage" : "20.0"
              },
              "id" : "SZIC3PCCHE24W3355WY3SWT4",
              "isDeleted" : false,
              "presentAtAllLocations" : true,
              "type" : "DISCOUNT",
              "updatedAt" : "2022-11-26T06:30:22.141Z"
            },
            {
              "id" : "RZEODRFH3OSSAHIO2VWZ2KYN",
              "isDeleted" : false,
              "presentAtAllLocations" : true,
              "type" : "PRODUCT_SET",
              "productSetData" : {
                "name" : "all products",
                "allProducts" : true
              },
              "updatedAt" : "2022-11-26T06:30:22.141Z"
            },
            {
              "id" : "QTZX5FZH2K4DH7JY2HWFUK6M",
              "isDeleted" : false,
              "presentAtAllLocations" : true,
              "pricingRuleData" : {
                "name" : "20% off your next purchase",
                "matchProductsId" : "RZEODRFH3OSSAHIO2VWZ2KYN",
                "discountId" : "SZIC3PCCHE24W3355WY3SWT4"
              },
              "type" : "PRICING_RULE",
              "updatedAt" : "2022-11-26T06:30:22.141Z"
            },
            {
              "id" : "OPNU32J3BV2OPGYDW6DJVHOX",
              "isDeleted" : false,
              "presentAtAllLocations" : true,
              "type" : "CUSTOM_ATTRIBUTE_DEFINITION",
              "updatedAt" : "2024-02-29T05:11:18.502Z",
              "customAttributeDefinitionData" : {
                "allowedObjectTypes" : [
                  "ITEM"
                ],
                "sellerVisibility" : "SELLER_VISIBILITY_READ_WRITE_VALUES",
                "sourceApplication" : {
                  "name" : "Square Online Store",
                  "applicationId" : "sq0idp-w46nJ_NCNDMSOywaCY0mwA"
                },
                "key" : "is_alcoholic",
                "description" : "Enabling this toggle on an item indicates that it contains alcohol.",
                "appVisibility" : "APP_VISIBILITY_HIDDEN",
                "name" : "Is Alcoholic",
                "type" : "BOOLEAN"
              }
            },
            {
              "id" : "E6UV7JYRLQBKMBIYF2ODLFAI",
              "isDeleted" : false,
              "presentAtAllLocations" : true,
              "type" : "CUSTOM_ATTRIBUTE_DEFINITION",
              "updatedAt" : "2022-11-26T06:34:56.667Z",
              "customAttributeDefinitionData" : {
                "type" : "STRING",
                "allowedObjectTypes" : [
                  "ITEM"
                ],
                "sellerVisibility" : "SELLER_VISIBILITY_HIDDEN",
                "sourceApplication" : {
                  "name" : "Square Online Store",
                  "applicationId" : "sq0idp-w46nJ_NCNDMSOywaCY0mwA"
                },
                "key" : "ecom_target_classic_site_id",
                "description" : "Ecommerce bridge target storefront classic site ID. Used to create site-item associations after copying items.",
                "appVisibility" : "APP_VISIBILITY_HIDDEN",
                "name" : "Ecom Storefront Classic Site ID",
                "stringConfig" : {
                  "enforceUniqueness" : false
                }
              }
            },
            {
              "id" : "BBRUGT7UYGTZA6OLPGPPLWEW",
              "isDeleted" : false,
              "categoryData" : {
                "name" : "Tea"
              },
              "presentAtAllLocations" : true,
              "type" : "CATEGORY",
              "updatedAt" : "2024-02-14T15:16:49.584Z"
            },
            {
              "id" : "3XUJW6GNSGPZVHZPVEHONXVQ",
              "isDeleted" : false,
              "categoryData" : {
                "name" : "Coffee"
              },
              "presentAtAllLocations" : true,
              "type" : "CATEGORY",
              "updatedAt" : "2024-02-14T15:16:49.584Z"
            },
            {
              "id" : "CZJZBWYHEVPMJNETVTBBRFME",
              "isDeleted" : false,
              "categoryData" : {
                "name" : "Chocolate"
              },
              "presentAtAllLocations" : true,
              "type" : "CATEGORY",
              "updatedAt" : "2024-02-14T15:16:49.584Z"
            },
            {
              "isDeleted" : false,
              "id" : "I73SWQ52MS3VPVNFBQXLBQBN",
              "updatedAt" : "2023-07-12T07:48:29.274Z",
              "type" : "ITEM",
              "itemData" : {
                "productType" : "REGULAR",
                "descriptionPlaintext" : "Black tea with steamed milk and spices",
                "categoryId" : "BBRUGT7UYGTZA6OLPGPPLWEW",
                "descriptionHtml" : "<p>Black tea with steamed milk and spices<\/p>",
                "itemOptions" : [
                  {
                    "itemOptionId" : "QMTQUALKCGAAJ62IORWQEJ53"
                  }
                ],
                "description" : "Black tea with steamed milk and spices",
                "name" : "Chai Latte",
                "variations" : [
                  {
                    "version" : 1681306507668,
                    "isDeleted" : false,
                    "id" : "QEZESCEXTNCUTHJVC7UQF7UC",
                    "updatedAt" : "2023-04-12T13:35:07.668Z",
                    "type" : "ITEM_VARIATION",
                    "itemVariationData" : {
                      "itemId" : "I73SWQ52MS3VPVNFBQXLBQBN",
                      "ordinal" : 0,
                      "pricingType" : "FIXED_PRICING",
                      "sku" : "CHA-REG-001",
                      "stockable" : true,
                      "itemOptionValues" : [
                        {
                          "itemOptionValueId" : "Y5RN4ZI6ZJTXHXRK5P5ZSMZD",
                          "itemOptionId" : "QMTQUALKCGAAJ62IORWQEJ53"
                        }
                      ],
                      "priceMoney" : {
                        "amount" : 400,
                        "currency" : "USD"
                      },
                      "sellable" : true,
                      "name" : "Regular"
                    },
                    "presentAtAllLocations" : false,
                    "presentAtLocationIds" : [
                      "0HH56E4D5RJ8D"
                    ]
                  }
                ]
              },
              "presentAtAllLocations" : false,
              "presentAtLocationIds" : [
                "0HH56E4D5RJ8D"
              ]
            },
            {
              "isDeleted" : false,
              "id" : "BB5N7TDBNPQM4EC3RAPRR4MN",
              "updatedAt" : "2023-07-12T07:48:29.274Z",
              "type" : "ITEM",
              "itemData" : {
                "productType" : "REGULAR",
                "descriptionPlaintext" : "Espresso with steamed milk",
                "categoryId" : "3XUJW6GNSGPZVHZPVEHONXVQ",
                "descriptionHtml" : "<p>Espresso with steamed milk<\/p>",
                "itemOptions" : [
                  {
                    "itemOptionId" : "QMTQUALKCGAAJ62IORWQEJ53"
                  }
                ],
                "description" : "Espresso with steamed milk",
                "name" : "Latte",
                "variations" : [
                  {
                    "version" : 1681306507668,
                    "isDeleted" : false,
                    "id" : "W6O2IHU24FJ3A66KBDZG7S7D",
                    "updatedAt" : "2023-04-12T13:35:07.668Z",
                    "type" : "ITEM_VARIATION",
                    "itemVariationData" : {
                      "itemId" : "BB5N7TDBNPQM4EC3RAPRR4MN",
                      "ordinal" : 0,
                      "pricingType" : "FIXED_PRICING",
                      "sku" : "LAT-REG-001",
                      "stockable" : true,
                      "itemOptionValues" : [
                        {
                          "itemOptionValueId" : "Y5RN4ZI6ZJTXHXRK5P5ZSMZD",
                          "itemOptionId" : "QMTQUALKCGAAJ62IORWQEJ53"
                        }
                      ],
                      "priceMoney" : {
                        "amount" : 350,
                        "currency" : "USD"
                      },
                      "sellable" : true,
                      "name" : "Regular"
                    },
                    "presentAtAllLocations" : false,
                    "presentAtLocationIds" : [
                      "0HH56E4D5RJ8D"
                    ]
                  }
                ]
              },
              "presentAtAllLocations" : false,
              "presentAtLocationIds" : [
                "0HH56E4D5RJ8D"
              ]
            },
            {
              "isDeleted" : false,
              "id" : "SJZMD2QZ2LJRKZFD4LMWIDPG",
              "updatedAt" : "2023-07-12T07:48:29.274Z",
              "type" : "ITEM",
              "itemData" : {
                "productType" : "REGULAR",
                "descriptionPlaintext" : "Espresso with hot water",
                "categoryId" : "3XUJW6GNSGPZVHZPVEHONXVQ",
                "descriptionHtml" : "<p>Espresso with hot water<\/p>",
                "itemOptions" : [
                  {
                    "itemOptionId" : "QMTQUALKCGAAJ62IORWQEJ53"
                  }
                ],
                "description" : "Espresso with hot water",
                "name" : "Americano",
                "variations" : [
                  {
                    "version" : 1681306507668,
                    "isDeleted" : false,
                    "id" : "5BNGXH5DRC2GNBQETX4VITRS",
                    "updatedAt" : "2023-04-12T13:35:07.668Z",
                    "type" : "ITEM_VARIATION",
                    "itemVariationData" : {
                      "itemId" : "SJZMD2QZ2LJRKZFD4LMWIDPG",
                      "ordinal" : 0,
                      "pricingType" : "FIXED_PRICING",
                      "sku" : "AME-REG-001",
                      "stockable" : true,
                      "itemOptionValues" : [
                        {
                          "itemOptionValueId" : "Y5RN4ZI6ZJTXHXRK5P5ZSMZD",
                          "itemOptionId" : "QMTQUALKCGAAJ62IORWQEJ53"
                        }
                      ],
                      "priceMoney" : {
                        "amount" : 250,
                        "currency" : "USD"
                      },
                      "sellable" : true,
                      "name" : "Regular"
                    },
                    "presentAtAllLocations" : false,
                    "presentAtLocationIds" : [
                      "0HH56E4D5RJ8D"
                    ]
                  }
                ]
              },
              "presentAtAllLocations" : false,
              "presentAtLocationIds" : [
                "0HH56E4D5RJ8D"
              ]
            },
            {
              "isDeleted" : false,
              "id" : "6MHC6XJPWRHUVLBYDXQYXB5O",
              "updatedAt" : "2023-07-12T07:48:29.274Z",
              "type" : "ITEM",
              "itemData" : {
                "productType" : "REGULAR",
                "descriptionPlaintext" : "Espresso with steamed milk chocolate syrup and whipped cream",
                "categoryId" : "3XUJW6GNSGPZVHZPVEHONXVQ",
                "descriptionHtml" : "<p>Espresso with steamed milk chocolate syrup and whipped cream<\/p>",
                "itemOptions" : [
                  {
                    "itemOptionId" : "QMTQUALKCGAAJ62IORWQEJ53"
                  }
                ],
                "description" : "Espresso with steamed milk chocolate syrup and whipped cream",
                "name" : "Mocha",
                "variations" : [
                  {
                    "version" : 1681306507668,
                    "isDeleted" : false,
                    "id" : "5OTR7DVEYUJXNYLSJKF4AIF3",
                    "updatedAt" : "2023-04-12T13:35:07.668Z",
                    "type" : "ITEM_VARIATION",
                    "itemVariationData" : {
                      "itemId" : "6MHC6XJPWRHUVLBYDXQYXB5O",
                      "ordinal" : 0,
                      "pricingType" : "FIXED_PRICING",
                      "sku" : "MOC-REG-001",
                      "stockable" : true,
                      "itemOptionValues" : [
                        {
                          "itemOptionValueId" : "Y5RN4ZI6ZJTXHXRK5P5ZSMZD",
                          "itemOptionId" : "QMTQUALKCGAAJ62IORWQEJ53"
                        }
                      ],
                      "priceMoney" : {
                        "amount" : 400,
                        "currency" : "USD"
                      },
                      "sellable" : true,
                      "name" : "Regular"
                    },
                    "presentAtAllLocations" : false,
                    "presentAtLocationIds" : [
                      "0HH56E4D5RJ8D"
                    ]
                  }
                ]
              },
              "presentAtAllLocations" : false,
              "presentAtLocationIds" : [
                "0HH56E4D5RJ8D"
              ]
            },
            {
              "isDeleted" : false,
              "id" : "XK3O7WLVKQAI544DF3GXR4WI",
              "updatedAt" : "2023-07-12T07:48:29.274Z",
              "type" : "ITEM",
              "itemData" : {
                "productType" : "REGULAR",
                "descriptionPlaintext" : "Two shots of espresso",
                "categoryId" : "3XUJW6GNSGPZVHZPVEHONXVQ",
                "descriptionHtml" : "<p>Two shots of espresso<\/p>",
                "itemOptions" : [
                  {
                    "itemOptionId" : "QCUCTBJOFUR6PJH7MQZ4RYS5"
                  }
                ],
                "description" : "Two shots of espresso",
                "name" : "Double Espresso",
                "variations" : [
                  {
                    "version" : 1681306507668,
                    "isDeleted" : false,
                    "id" : "7XNH27PMTGZZWVHB7XWLB4PV",
                    "updatedAt" : "2023-04-12T13:35:07.668Z",
                    "type" : "ITEM_VARIATION",
                    "itemVariationData" : {
                      "itemId" : "XK3O7WLVKQAI544DF3GXR4WI",
                      "ordinal" : 0,
                      "pricingType" : "FIXED_PRICING",
                      "sku" : "ESP-DOU-001",
                      "stockable" : true,
                      "itemOptionValues" : [
                        {
                          "itemOptionValueId" : "N267R4EJGLFVL36BDEGHWTTY",
                          "itemOptionId" : "QCUCTBJOFUR6PJH7MQZ4RYS5"
                        }
                      ],
                      "priceMoney" : {
                        "amount" : 300,
                        "currency" : "USD"
                      },
                      "sellable" : true,
                      "name" : "None"
                    },
                    "presentAtAllLocations" : false,
                    "presentAtLocationIds" : [
                      "0HH56E4D5RJ8D"
                    ]
                  }
                ]
              },
              "presentAtAllLocations" : false,
              "presentAtLocationIds" : [
                "0HH56E4D5RJ8D"
              ]
            },
            {
              "isDeleted" : false,
              "id" : "UZLSRTG3LXULYQPZCSE6ODGC",
              "updatedAt" : "2023-07-12T07:48:29.274Z",
              "type" : "ITEM",
              "itemData" : {
                "productType" : "REGULAR",
                "descriptionPlaintext" : "Chilled coffee with ice",
                "categoryId" : "3XUJW6GNSGPZVHZPVEHONXVQ",
                "descriptionHtml" : "<p>Chilled coffee with ice<\/p>",
                "itemOptions" : [
                  {
                    "itemOptionId" : "QMTQUALKCGAAJ62IORWQEJ53"
                  }
                ],
                "description" : "Chilled coffee with ice",
                "name" : "Iced Coffee",
                "variations" : [
                  {
                    "version" : 1681306507668,
                    "isDeleted" : false,
                    "id" : "AQI4VT6KQAK2HYCV5CWTQXBU",
                    "updatedAt" : "2023-04-12T13:35:07.668Z",
                    "type" : "ITEM_VARIATION",
                    "itemVariationData" : {
                      "itemId" : "UZLSRTG3LXULYQPZCSE6ODGC",
                      "ordinal" : 0,
                      "pricingType" : "FIXED_PRICING",
                      "sku" : "ICD-REG-001",
                      "stockable" : true,
                      "itemOptionValues" : [
                        {
                          "itemOptionValueId" : "Y5RN4ZI6ZJTXHXRK5P5ZSMZD",
                          "itemOptionId" : "QMTQUALKCGAAJ62IORWQEJ53"
                        }
                      ],
                      "priceMoney" : {
                        "amount" : 250,
                        "currency" : "USD"
                      },
                      "sellable" : true,
                      "name" : "Regular"
                    },
                    "presentAtAllLocations" : false,
                    "presentAtLocationIds" : [
                      "0HH56E4D5RJ8D"
                    ]
                  }
                ]
              },
              "presentAtAllLocations" : false,
              "presentAtLocationIds" : [
                "0HH56E4D5RJ8D"
              ]
            },
            {
              "isDeleted" : false,
              "id" : "HSHQWFUA67O2PUVKCY5LDITB",
              "updatedAt" : "2023-07-12T07:48:29.274Z",
              "type" : "ITEM",
              "itemData" : {
                "productType" : "REGULAR",
                "descriptionPlaintext" : "Chilled coffee brewed over 12 hours",
                "categoryId" : "3XUJW6GNSGPZVHZPVEHONXVQ",
                "descriptionHtml" : "<p>Chilled coffee brewed over 12 hours<\/p>",
                "itemOptions" : [
                  {
                    "itemOptionId" : "QMTQUALKCGAAJ62IORWQEJ53"
                  }
                ],
                "description" : "Chilled coffee brewed over 12 hours",
                "name" : "Cold Brew",
                "variations" : [
                  {
                    "version" : 1681306507668,
                    "isDeleted" : false,
                    "id" : "QKIHJFX6S7O4HKCIOHJ4EXEM",
                    "updatedAt" : "2023-04-12T13:35:07.668Z",
                    "type" : "ITEM_VARIATION",
                    "itemVariationData" : {
                      "itemId" : "HSHQWFUA67O2PUVKCY5LDITB",
                      "ordinal" : 0,
                      "pricingType" : "FIXED_PRICING",
                      "sku" : "CLD-REG-001",
                      "stockable" : true,
                      "itemOptionValues" : [
                        {
                          "itemOptionValueId" : "Y5RN4ZI6ZJTXHXRK5P5ZSMZD",
                          "itemOptionId" : "QMTQUALKCGAAJ62IORWQEJ53"
                        }
                      ],
                      "priceMoney" : {
                        "amount" : 350,
                        "currency" : "USD"
                      },
                      "sellable" : true,
                      "name" : "Regular"
                    },
                    "presentAtAllLocations" : false,
                    "presentAtLocationIds" : [
                      "0HH56E4D5RJ8D"
                    ]
                  }
                ]
              },
              "presentAtAllLocations" : false,
              "presentAtLocationIds" : [
                "0HH56E4D5RJ8D"
              ]
            },
            {
              "isDeleted" : false,
              "id" : "F4F6JQRDFFH77WAM4ZHCMZFL",
              "updatedAt" : "2023-07-12T07:48:29.274Z",
              "type" : "ITEM",
              "itemData" : {
                "productType" : "REGULAR",
                "descriptionPlaintext" : "Espresso with steamed milk and foam",
                "categoryId" : "3XUJW6GNSGPZVHZPVEHONXVQ",
                "descriptionHtml" : "<p>Espresso with steamed milk and foam<\/p>",
                "itemOptions" : [
                  {
                    "itemOptionId" : "QMTQUALKCGAAJ62IORWQEJ53"
                  }
                ],
                "description" : "Espresso with steamed milk and foam",
                "name" : "Cappuccino",
                "variations" : [
                  {
                    "version" : 1681306507668,
                    "isDeleted" : false,
                    "id" : "AQ2RFTX2H2NOVBG5ZZEYVXBL",
                    "updatedAt" : "2023-04-12T13:35:07.668Z",
                    "type" : "ITEM_VARIATION",
                    "itemVariationData" : {
                      "itemId" : "F4F6JQRDFFH77WAM4ZHCMZFL",
                      "ordinal" : 0,
                      "pricingType" : "FIXED_PRICING",
                      "sku" : "CAP-REG-001",
                      "stockable" : true,
                      "itemOptionValues" : [
                        {
                          "itemOptionValueId" : "Y5RN4ZI6ZJTXHXRK5P5ZSMZD",
                          "itemOptionId" : "QMTQUALKCGAAJ62IORWQEJ53"
                        }
                      ],
                      "priceMoney" : {
                        "amount" : 350,
                        "currency" : "USD"
                      },
                      "sellable" : true,
                      "name" : "Regular"
                    },
                    "presentAtAllLocations" : false,
                    "presentAtLocationIds" : [
                      "0HH56E4D5RJ8D"
                    ]
                  }
                ]
              },
              "presentAtAllLocations" : false,
              "presentAtLocationIds" : [
                "0HH56E4D5RJ8D"
              ]
            },
            {
              "isDeleted" : false,
              "id" : "VDPJZHGSF2L6RU5TSTORGE2X",
              "updatedAt" : "2023-07-12T07:48:29.274Z",
              "type" : "ITEM",
              "itemData" : {
                "productType" : "REGULAR",
                "descriptionPlaintext" : "A shot of espresso",
                "categoryId" : "3XUJW6GNSGPZVHZPVEHONXVQ",
                "descriptionHtml" : "<p>A shot of espresso<\/p>",
                "itemOptions" : [
                  {
                    "itemOptionId" : "QCUCTBJOFUR6PJH7MQZ4RYS5"
                  }
                ],
                "description" : "A shot of espresso",
                "name" : "Single Espresso",
                "variations" : [
                  {
                    "version" : 1681306507668,
                    "isDeleted" : false,
                    "id" : "CGCVMAUU7YSCBHOO6NL7GTXU",
                    "updatedAt" : "2023-04-12T13:35:07.668Z",
                    "type" : "ITEM_VARIATION",
                    "itemVariationData" : {
                      "itemId" : "VDPJZHGSF2L6RU5TSTORGE2X",
                      "ordinal" : 0,
                      "pricingType" : "FIXED_PRICING",
                      "sku" : "ESP-SIN-001",
                      "stockable" : true,
                      "itemOptionValues" : [
                        {
                          "itemOptionValueId" : "N267R4EJGLFVL36BDEGHWTTY",
                          "itemOptionId" : "QCUCTBJOFUR6PJH7MQZ4RYS5"
                        }
                      ],
                      "priceMoney" : {
                        "amount" : 200,
                        "currency" : "USD"
                      },
                      "sellable" : true,
                      "name" : "None"
                    },
                    "presentAtAllLocations" : false,
                    "presentAtLocationIds" : [
                      "0HH56E4D5RJ8D"
                    ]
                  }
                ]
              },
              "presentAtAllLocations" : false,
              "presentAtLocationIds" : [
                "0HH56E4D5RJ8D"
              ]
            },
            {
              "isDeleted" : false,
              "id" : "IDATX5GVK33Q2DEESQDPKNWN",
              "updatedAt" : "2023-07-12T07:48:29.274Z",
              "type" : "ITEM",
              "itemData" : {
                "productType" : "REGULAR",
                "descriptionPlaintext" : "Espresso with steamed milk and caramel syrup",
                "categoryId" : "3XUJW6GNSGPZVHZPVEHONXVQ",
                "descriptionHtml" : "<p>Espresso with steamed milk and caramel syrup<\/p>",
                "itemOptions" : [
                  {
                    "itemOptionId" : "QMTQUALKCGAAJ62IORWQEJ53"
                  }
                ],
                "description" : "Espresso with steamed milk and caramel syrup",
                "name" : "Caramel Macchiato",
                "variations" : [
                  {
                    "version" : 1681306507668,
                    "isDeleted" : false,
                    "id" : "BUUGZXFCZRZOGUBFKH373BJP",
                    "updatedAt" : "2023-04-12T13:35:07.668Z",
                    "type" : "ITEM_VARIATION",
                    "itemVariationData" : {
                      "itemId" : "IDATX5GVK33Q2DEESQDPKNWN",
                      "ordinal" : 0,
                      "pricingType" : "FIXED_PRICING",
                      "sku" : "CAR-REG-001",
                      "stockable" : true,
                      "itemOptionValues" : [
                        {
                          "itemOptionValueId" : "Y5RN4ZI6ZJTXHXRK5P5ZSMZD",
                          "itemOptionId" : "QMTQUALKCGAAJ62IORWQEJ53"
                        }
                      ],
                      "priceMoney" : {
                        "amount" : 450,
                        "currency" : "USD"
                      },
                      "sellable" : true,
                      "name" : "Regular"
                    },
                    "presentAtAllLocations" : false,
                    "presentAtLocationIds" : [
                      "0HH56E4D5RJ8D"
                    ]
                  }
                ]
              },
              "presentAtAllLocations" : false,
              "presentAtLocationIds" : [
                "0HH56E4D5RJ8D"
              ]
            },
            {
              "isDeleted" : false,
              "id" : "WFN766M4HKZKOS4FQCXMDURQ",
              "updatedAt" : "2023-07-12T07:48:29.274Z",
              "type" : "ITEM",
              "itemData" : {
                "productType" : "REGULAR",
                "descriptionPlaintext" : "Matcha green tea with steamed milk",
                "categoryId" : "BBRUGT7UYGTZA6OLPGPPLWEW",
                "descriptionHtml" : "<p>Matcha green tea with steamed milk<\/p>",
                "itemOptions" : [
                  {
                    "itemOptionId" : "QMTQUALKCGAAJ62IORWQEJ53"
                  }
                ],
                "description" : "Matcha green tea with steamed milk",
                "name" : "Green Tea Latte",
                "variations" : [
                  {
                    "version" : 1681306507668,
                    "isDeleted" : false,
                    "id" : "5BGBZSKYHGFL243MY3XOVFEU",
                    "updatedAt" : "2023-04-12T13:35:07.668Z",
                    "type" : "ITEM_VARIATION",
                    "itemVariationData" : {
                      "itemId" : "WFN766M4HKZKOS4FQCXMDURQ",
                      "ordinal" : 0,
                      "pricingType" : "FIXED_PRICING",
                      "sku" : "GRN-REG-001",
                      "stockable" : true,
                      "itemOptionValues" : [
                        {
                          "itemOptionValueId" : "Y5RN4ZI6ZJTXHXRK5P5ZSMZD",
                          "itemOptionId" : "QMTQUALKCGAAJ62IORWQEJ53"
                        }
                      ],
                      "priceMoney" : {
                        "amount" : 450,
                        "currency" : "USD"
                      },
                      "sellable" : true,
                      "name" : "Regular"
                    },
                    "presentAtAllLocations" : false,
                    "presentAtLocationIds" : [
                      "0HH56E4D5RJ8D"
                    ]
                  }
                ]
              },
              "presentAtAllLocations" : false,
              "presentAtLocationIds" : [
                "0HH56E4D5RJ8D"
              ]
            },
            {
              "isDeleted" : false,
              "id" : "RAXI5MBGLW644RHLBSO2F2UA",
              "updatedAt" : "2023-07-12T07:48:29.274Z",
              "type" : "ITEM",
              "itemData" : {
                "productType" : "REGULAR",
                "descriptionPlaintext" : "A selection of herbal tea",
                "categoryId" : "BBRUGT7UYGTZA6OLPGPPLWEW",
                "descriptionHtml" : "<p>A selection of herbal tea<\/p>",
                "itemOptions" : [
                  {
                    "itemOptionId" : "VOE4KQDQE5M2P73WFKWBS6LU"
                  }
                ],
                "description" : "A selection of herbal tea",
                "name" : "Herbal Tea",
                "variations" : [
                  {
                    "version" : 1681306507668,
                    "isDeleted" : false,
                    "id" : "TIRNIPKJ7V6TDGZKXBEPREYX",
                    "updatedAt" : "2023-04-12T13:35:07.668Z",
                    "type" : "ITEM_VARIATION",
                    "itemVariationData" : {
                      "itemId" : "RAXI5MBGLW644RHLBSO2F2UA",
                      "ordinal" : 0,
                      "pricingType" : "FIXED_PRICING",
                      "sku" : "HBL-REG-001",
                      "stockable" : true,
                      "itemOptionValues" : [
                        {
                          "itemOptionValueId" : "EM2GK52VGHE5F2EI3Z7LN2OT",
                          "itemOptionId" : "VOE4KQDQE5M2P73WFKWBS6LU"
                        }
                      ],
                      "priceMoney" : {
                        "amount" : 250,
                        "currency" : "USD"
                      },
                      "sellable" : true,
                      "name" : "Peppermint"
                    },
                    "presentAtAllLocations" : false,
                    "presentAtLocationIds" : [
                      "0HH56E4D5RJ8D"
                    ]
                  }
                ]
              },
              "presentAtAllLocations" : false,
              "presentAtLocationIds" : [
                "0HH56E4D5RJ8D"
              ]
            },
            {
              "isDeleted" : false,
              "id" : "WGTBPNDSH37QSYRHTJN4RDSN",
              "updatedAt" : "2023-07-12T07:48:29.274Z",
              "type" : "ITEM",
              "itemData" : {
                "productType" : "REGULAR",
                "descriptionPlaintext" : "Hot chocolate with whipped cream",
                "categoryId" : "CZJZBWYHEVPMJNETVTBBRFME",
                "descriptionHtml" : "<p>Hot chocolate with whipped cream<\/p>",
                "itemOptions" : [
                  {
                    "itemOptionId" : "QMTQUALKCGAAJ62IORWQEJ53"
                  }
                ],
                "description" : "Hot chocolate with whipped cream",
                "name" : "Hot Chocolate",
                "variations" : [
                  {
                    "version" : 1681306507668,
                    "isDeleted" : false,
                    "id" : "P7SHQ3QVF7CIRERB3I3Q4E24",
                    "updatedAt" : "2023-04-12T13:35:07.668Z",
                    "type" : "ITEM_VARIATION",
                    "itemVariationData" : {
                      "itemId" : "WGTBPNDSH37QSYRHTJN4RDSN",
                      "ordinal" : 0,
                      "pricingType" : "FIXED_PRICING",
                      "sku" : "HOT-REG-001",
                      "stockable" : true,
                      "itemOptionValues" : [
                        {
                          "itemOptionValueId" : "Y5RN4ZI6ZJTXHXRK5P5ZSMZD",
                          "itemOptionId" : "QMTQUALKCGAAJ62IORWQEJ53"
                        }
                      ],
                      "priceMoney" : {
                        "amount" : 400,
                        "currency" : "USD"
                      },
                      "sellable" : true,
                      "name" : "Regular"
                    },
                    "presentAtAllLocations" : false,
                    "presentAtLocationIds" : [
                      "0HH56E4D5RJ8D"
                    ]
                  }
                ]
              },
              "presentAtAllLocations" : false,
              "presentAtLocationIds" : [
                "0HH56E4D5RJ8D"
              ]
            },
            {
              "itemOptionData" : {
                "showColors" : false,
                "displayName" : "None",
                "name" : "None",
                "values" : [
                  {
                    "id" : "N267R4EJGLFVL36BDEGHWTTY",
                    "itemOptionValueData" : {
                      "name" : "None",
                      "ordinal" : 0,
                      "itemOptionId" : "QCUCTBJOFUR6PJH7MQZ4RYS5"
                    },
                    "type" : "ITEM_OPTION_VAL",
                    "version" : 1681306507668
                  }
                ]
              },
              "id" : "QCUCTBJOFUR6PJH7MQZ4RYS5",
              "isDeleted" : false,
              "presentAtAllLocations" : true,
              "type" : "ITEM_OPTION",
              "updatedAt" : "2023-04-12T13:36:50.718Z"
            },
            {
              "itemOptionData" : {
                "showColors" : false,
                "displayName" : "Type",
                "name" : "Type",
                "values" : [
                  {
                    "id" : "EM2GK52VGHE5F2EI3Z7LN2OT",
                    "itemOptionValueData" : {
                      "name" : "Peppermint",
                      "ordinal" : 0,
                      "itemOptionId" : "VOE4KQDQE5M2P73WFKWBS6LU"
                    },
                    "type" : "ITEM_OPTION_VAL",
                    "version" : 1681306507668
                  }
                ]
              },
              "id" : "VOE4KQDQE5M2P73WFKWBS6LU",
              "isDeleted" : false,
              "presentAtAllLocations" : true,
              "type" : "ITEM_OPTION",
              "updatedAt" : "2023-04-12T13:36:40.685Z"
            },
            {
              "itemOptionData" : {
                "showColors" : false,
                "displayName" : "Size",
                "name" : "Size",
                "values" : [
                  {
                    "id" : "Y5RN4ZI6ZJTXHXRK5P5ZSMZD",
                    "itemOptionValueData" : {
                      "name" : "Regular",
                      "ordinal" : 0,
                      "itemOptionId" : "QMTQUALKCGAAJ62IORWQEJ53"
                    },
                    "type" : "ITEM_OPTION_VAL",
                    "version" : 1681306507668
                  }
                ]
              },
              "id" : "QMTQUALKCGAAJ62IORWQEJ53",
              "isDeleted" : false,
              "presentAtAllLocations" : true,
              "type" : "ITEM_OPTION",
              "updatedAt" : "2023-04-12T13:37:01.07Z"
            },
            {
              "id" : "6WBB5SR6N6LJW337PCPFLEU5",
              "isDeleted" : false,
              "presentAtAllLocations" : true,
              "type" : "PRODUCT_SET",
              "productSetData" : {
                "productIdsAny" : [
                  "VDPJZHGSF2L6RU5TSTORGE2X"
                ],
                "allProducts" : false
              },
              "updatedAt" : "2023-04-19T20:32:03.115Z"
            },
            {
              "id" : "EPCGLCCF2BUKHZXOHNZPYE6S",
              "isDeleted" : false,
              "presentAtAllLocations" : true,
              "type" : "TIME_PERIOD",
              "updatedAt" : "2023-04-19T20:32:03.115Z",
              "timePeriodData" : {
                "event" : "BEGIN:VEVENT\nDURATION:PT23H59M\nRRULE:FREQ=WEEKLY;BYDAY=TU\nDTSTART:20170103T000000\nEND:VEVENT\n"
              }
            },
            {
              "id" : "4UIABZQ5FGPBSFEROMVNDV7R",
              "isDeleted" : false,
              "presentAtAllLocations" : true,
              "type" : "TIME_PERIOD",
              "updatedAt" : "2023-04-19T20:32:03.115Z",
              "timePeriodData" : {
                "event" : "BEGIN:VEVENT\nDURATION:PT23H59M\nRRULE:FREQ=WEEKLY;BYDAY=MO\nDTSTART:20170102T000000\nEND:VEVENT\n"
              }
            },
            {
              "id" : "VP6PYKVYGOSWG74HBX2ZRIMQ",
              "isDeleted" : false,
              "presentAtAllLocations" : true,
              "type" : "TIME_PERIOD",
              "updatedAt" : "2023-04-19T20:32:03.115Z",
              "timePeriodData" : {
                "event" : "BEGIN:VEVENT\nDURATION:PT23H59M\nRRULE:FREQ=WEEKLY;BYDAY=TH\nDTSTART:20170105T000000\nEND:VEVENT\n"
              }
            },
            {
              "id" : "DTVL5ECDPK6R4KPKZYMZTEQN",
              "isDeleted" : false,
              "presentAtAllLocations" : true,
              "type" : "TIME_PERIOD",
              "updatedAt" : "2023-04-19T20:32:03.115Z",
              "timePeriodData" : {
                "event" : "BEGIN:VEVENT\nDURATION:PT23H59M\nRRULE:FREQ=WEEKLY;BYDAY=SA\nDTSTART:20170107T000000\nEND:VEVENT\n"
              }
            },
            {
              "discountData" : {
                "modifyTaxBasis" : "MODIFY_TAX_BASIS",
                "percentage" : "50.0",
                "discountType" : "FIXED_PERCENTAGE",
                "name" : "Cafe.Bio 10 Percent"
              },
              "id" : "PBOOH7Q6BOZM7FTVWXNUF4MD",
              "isDeleted" : false,
              "presentAtAllLocations" : true,
              "type" : "DISCOUNT",
              "updatedAt" : "2023-04-19T20:32:03.115Z"
            },
            {
              "id" : "2VAJRTYXEUKJOHTKURIBISY7",
              "isDeleted" : false,
              "presentAtAllLocations" : true,
              "type" : "CUSTOM_ATTRIBUTE_DEFINITION",
              "updatedAt" : "2023-11-13T19:46:04.482Z",
              "customAttributeDefinitionData" : {
                "allowedObjectTypes" : [
                  "ITEM"
                ],
                "sellerVisibility" : "SELLER_VISIBILITY_HIDDEN",
                "sourceApplication" : {
                  "name" : "Square Online Store",
                  "applicationId" : "sq0idp-w46nJ_NCNDMSOywaCY0mwA"
                },
                "key" : "ecom_short_id",
                "type" : "STRING",
                "appVisibility" : "APP_VISIBILITY_HIDDEN",
                "name" : "ECOM Short Id",
                "stringConfig" : {
                  "enforceUniqueness" : false
                }
              }
            },
            {
              "id" : "T6BYFCQCJF2HWEVPTWJBXWGC",
              "isDeleted" : false,
              "presentAtAllLocations" : true,
              "pricingRuleData" : {
                "matchProductsId" : "CCR2CVDBYRP5FX376GCBOWSG",
                "discountId" : "AMRLZZ3GO2IG3JJRCBV6OT6I"
              },
              "type" : "PRICING_RULE",
              "updatedAt" : "2024-02-29T05:05:20.151Z"
            },
            {
              "discountData" : {
                "percentage" : "5.0",
                "discountType" : "FIXED_PERCENTAGE",
                "maximumAmountMoney" : {
                  "amount" : 300,
                  "currency" : "USD"
                },
                "name" : "5% cash bach on any purchase (up to $3.00 off)"
              },
              "id" : "AMRLZZ3GO2IG3JJRCBV6OT6I",
              "isDeleted" : false,
              "presentAtAllLocations" : true,
              "type" : "DISCOUNT",
              "updatedAt" : "2024-02-29T05:05:20.151Z"
            },
            {
              "id" : "CCR2CVDBYRP5FX376GCBOWSG",
              "isDeleted" : false,
              "presentAtAllLocations" : true,
              "type" : "PRODUCT_SET",
              "productSetData" : {
                "allProducts" : true
              },
              "updatedAt" : "2024-02-29T05:05:20.151Z"
            },
            {
              "id" : "O33LNKC2U6PRWWVL4XFOQXTG",
              "isDeleted" : false,
              "presentAtAllLocations" : true,
              "type" : "PRODUCT_SET",
              "productSetData" : {
                "name" : "Coupons MATCH_ALL Product Set",
                "allProducts" : true
              },
              "updatedAt" : "2024-02-29T05:15:34.917Z"
            },
            {
              "id" : "IZVMSBANCWECCHF5XI7HTWN6",
              "isDeleted" : false,
              "presentAtAllLocations" : true,
              "pricingRuleData" : {
                "name" : "Get $5 off the next time you visit!",
                "matchProductsId" : "O33LNKC2U6PRWWVL4XFOQXTG",
                "discountId" : "KXHBVQN42WXFA3JT2QMVVN6G"
              },
              "type" : "PRICING_RULE",
              "updatedAt" : "2024-02-29T05:15:34.917Z"
            },
            {
              "discountData" : {
                "amountMoney" : {
                  "amount" : 500,
                  "currency" : "USD"
                },
                "modifyTaxBasis" : "MODIFY_TAX_BASIS",
                "pinRequired" : false,
                "discountType" : "FIXED_AMOUNT",
                "name" : "Get $5 off the next time you visit!"
              },
              "id" : "KXHBVQN42WXFA3JT2QMVVN6G",
              "isDeleted" : false,
              "presentAtAllLocations" : true,
              "type" : "DISCOUNT",
              "updatedAt" : "2024-02-29T05:15:34.917Z"
            }
          ],
          "customers" : {
            "05SNX5A9G3Y6R" : {
              "status" : "ACTIVE",
              "country" : "US",
              "id" : "05SNX5A9G3Y6R",
              "languageCode" : "en-US",
              "businessName" : "Testcp",
              "createdAt" : "2019-04-03T01:27:04Z",
              "currency" : "USD",
              "mainLocationId" : "0HH56E4D5RJ8D"
            },
            "415CYF5PSXCXZP1N43MQ91DYAC" : {
              "address" : {
                "administrativeDistrictLevel1" : "CA",
                "addressLine1" : "1294 Chewpon Avenue",
                "locality" : "Milpitas",
                "postalCode" : "95035"
              },
              "version" : 1,
              "preferences" : {
                "emailUnsubscribed" : false
              },
              "creationSource" : "DIRECTORY",
              "familyName" : "Pope",
              "customerId" : "415CYF5PSXCXZP1N43MQ91DYAC",
              "createdAt" : "2022-11-26T06:29:03.005Z",
              "givenName" : "x",
              "updatedAt" : "2022-11-26T06:34:16Z",
              "phoneNumber" : "+14159997117"
            },
            "C9JEWSG1F4129Q18TRZDTESSCW" : {
              "address" : {
                "addressLine1" : "37917 STENHAMMER DRIVE",
                "locality" : "FREMONT"
              },
              "version" : 1,
              "preferences" : {
                "emailUnsubscribed" : false
              },
              "creationSource" : "DIRECTORY",
              "familyName" : "Pope",
              "customerId" : "C9JEWSG1F4129Q18TRZDTESSCW",
              "createdAt" : "2022-11-26T06:33:47.983Z",
              "givenName" : "Curtiss",
              "updatedAt" : "2022-11-26T06:34:16Z",
              "phoneNumber" : "+14159997117"
            }
          },
          "locations" : [
            {
              "businessName" : "Testcp",
              "id" : "0HH56E4D5RJ8D",
              "name" : "Testcp",
              "status" : "ACTIVE",
              "merchantId" : "05SNX5A9G3Y6R",
              "mcc" : "5499",
              "languageCode" : "en-US",
              "type" : "PHYSICAL",
              "capabilities" : [
                "CREDIT_CARD_PROCESSING",
                "AUTOMATIC_TRANSFERS"
              ],
              "address" : {
                "administrativeDistrictLevel1" : "CA",
                "addressLine1" : "37917 Stenhammer Drive",
                "country" : "US",
                "locality" : "Fremont",
                "postalCode" : "95035"
              },
              "coordinates" : {
                "longitude" : -121.9594424,
                "latitude" : 37.586516799999998
              },
              "timezone" : "America\/Los_Angeles",
              "country" : "US",
              "createdAt" : "2019-04-03T01:27:03Z",
              "currency" : "USD"
            }
          ]
        }
      }
    },
    "squarecustomers" : {
      "05SNX5A9G3Y6R" : {
        "status" : "ACTIVE",
        "country" : "US",
        "customers" : {
          "C9JEWSG1F4129Q18TRZDTESSCW" : {
            "address" : {
              "addressLine1" : "37917 STENHAMMER DRIVE",
              "locality" : "FREMONT"
            },
            "version" : 1,
            "preferences" : {
              "emailUnsubscribed" : false
            },
            "creationSource" : "DIRECTORY",
            "familyName" : "Pope",
            "customerId" : "C9JEWSG1F4129Q18TRZDTESSCW",
            "createdAt" : "2022-11-26T06:33:47.983Z",
            "givenName" : "Curtiss",
            "updatedAt" : "2022-11-26T06:34:16Z",
            "phoneNumber" : "+14159997117"
          },
          "415CYF5PSXCXZP1N43MQ91DYAC" : {
            "address" : {
              "administrativeDistrictLevel1" : "CA",
              "addressLine1" : "1294 Chewpon Avenue",
              "locality" : "Milpitas",
              "postalCode" : "95035"
            },
            "version" : 1,
            "preferences" : {
              "emailUnsubscribed" : false
            },
            "creationSource" : "DIRECTORY",
            "familyName" : "Pope",
            "customerId" : "415CYF5PSXCXZP1N43MQ91DYAC",
            "createdAt" : "2022-11-26T06:29:03.005Z",
            "givenName" : "x",
            "updatedAt" : "2022-11-26T06:34:16Z",
            "phoneNumber" : "+14159997117"
          }
        },
        "id" : "05SNX5A9G3Y6R",
        "languageCode" : "en-US",
        "businessName" : "Testcp",
        "createdAt" : "2019-04-03T01:27:04Z",
        "currency" : "USD",
        "mainLocationId" : "0HH56E4D5RJ8D"
      }
    },
    "redemptions" : {
      "cafeluna_gudzH" : {
        "redeemguid" : "gudzH",
        "redeemTimestamp" : 1681103013954,
        "redeemdate" : "4\/9\/2023",
        "source" : "IG",
        "promoname" : "CafeLuna-s1a7V8Bfk8RRoqstBsXx3W",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_vV8uL" : {
        "redeemguid" : "vV8uL",
        "redeemTimestamp" : 1681103919451,
        "redeemdate" : "4\/9\/2023",
        "source" : "IG",
        "promoname" : "CafeLuna-s1a7V8Bfk8RRoqstBsXx3W",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_fKMSZ" : {
        "redeemguid" : "fKMSZ",
        "redeemTimestamp" : 1681917435039,
        "redeemdate" : "4\/19\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-6uhtv",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_eniSK" : {
        "redeemguid" : "eniSK",
        "redeemTimestamp" : 1681098470842,
        "redeemdate" : "4\/9\/2023",
        "source" : "IG",
        "promoname" : "4159997117_eniSK",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_dRjkP" : {
        "redeemguid" : "dRjkP",
        "redeemTimestamp" : 1681171009614,
        "redeemdate" : "4\/10\/2023",
        "source" : "IG",
        "promoname" : "CafeLuna-s1a7V8Bfk8RRoqstBsXx3W",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_iNiBJ" : {
        "redeemguid" : "iNiBJ",
        "redeemTimestamp" : 1704081266777,
        "redeemdate" : "12\/31\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-2pZWt",
        "redemptionTimestamp" : 1704081266777,
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_tYkor" : {
        "redeemguid" : "tYkor",
        "source" : "IG",
        "redeemdate" : "4\/9\/2023",
        "visitdate" : "",
        "promoname" : "4159997117_tYkor",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_5CPnP" : {
        "redeemguid" : "5CPnP",
        "redeemTimestamp" : 1703911321137,
        "redeemdate" : "12\/29\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-4fbuE",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_vEgeU" : {
        "redeemguid" : "vEgeU",
        "redeemTimestamp" : 1681104233879,
        "redeemdate" : "4\/9\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-ov8AU",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_4TVCD" : {
        "redeemguid" : "4TVCD",
        "redeemTimestamp" : 1704081446169,
        "redeemdate" : "12\/31\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-2pZWt",
        "redemptionTimestamp" : 1704081446169,
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_g7qxJ" : {
        "redeemguid" : "g7qxJ",
        "redeemTimestamp" : 1681878288489,
        "redeemdate" : "4\/19\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-6uhtv",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "1231231",
        "visittime" : ""
      },
      "cafeluna_rN48R" : {
        "redeemguid" : "rN48R",
        "source" : "IG",
        "redeemdate" : "4\/9\/2023",
        "visitdate" : "",
        "promoname" : "_rN48R",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "",
        "visittime" : ""
      },
      "cafeluna_thN6P" : {
        "redeemguid" : "thN6P",
        "source" : "IG",
        "redeemdate" : "4\/9\/2023",
        "visitdate" : "",
        "promoname" : "4159997117_thN6P",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna-2pZWt" : {
        "cafeluna_xB9Fs" : {
          "redeemguid" : "xB9Fs",
          "redeemTimestamp" : 1704081726496,
          "redeemdate" : "12\/31\/2023",
          "source" : "IG",
          "promoname" : "cafeluna-2pZWt",
          "redemptionTimestamp" : 1704081726496,
          "visitdate" : "",
          "merchanthandle" : "cafeluna",
          "merchantname" : "Cafe Luna",
          "phonenumber" : "4159997117",
          "visittime" : ""
        },
        "1-1-2024" : {
          "cafeluna_ucrw7" : {
            "redeemguid" : "ucrw7",
            "redeemTimestamp" : 1704125422657,
            "redeemdate" : "1-1-2024",
            "source" : "IG",
            "promoname" : "cafeluna-2pZWt",
            "visitdate" : "",
            "merchanthandle" : "cafeluna",
            "merchantname" : "Cafe Luna",
            "phonenumber" : "4159997117",
            "visittime" : ""
          }
        },
        "12-31-2023" : {
          "cafeluna_275Bk" : {
            "redeemguid" : "275Bk",
            "redeemTimestamp" : 1704082349550,
            "redeemdate" : "12-31-2023",
            "source" : "IG",
            "promoname" : "cafeluna-2pZWt",
            "visitdate" : "",
            "merchanthandle" : "cafeluna",
            "merchantname" : "Cafe Luna",
            "phonenumber" : "4159997117",
            "visittime" : ""
          },
          "cafeluna_nJM5X" : {
            "redeemguid" : "nJM5X",
            "redeemTimestamp" : 1704082578355,
            "redeemdate" : "12-31-2023",
            "source" : "IG",
            "promoname" : "cafeluna-2pZWt",
            "visitdate" : "",
            "merchanthandle" : "cafeluna",
            "merchantname" : "Cafe Luna",
            "phonenumber" : "4159997117",
            "visittime" : ""
          }
        },
        "1-4-2024" : {
          "cafeluna_qWmYG" : {
            "redeemguid" : "qWmYG",
            "redeemTimestamp" : 1704427401465,
            "redeemdate" : "1-4-2024",
            "source" : "IG",
            "promoname" : "cafeluna-2pZWt",
            "visitdate" : "",
            "merchanthandle" : "cafeluna",
            "phonenumber" : "4159997117",
            "visittime" : ""
          },
          "cafeluna_sDL8J" : {
            "redeemguid" : "sDL8J",
            "redeemTimestamp" : 1704427977554,
            "redeemdate" : "1-4-2024",
            "source" : "IG",
            "promoname" : "cafeluna-2pZWt",
            "visitdate" : "",
            "merchanthandle" : "cafeluna",
            "phonenumber" : "4159997119",
            "visittime" : ""
          }
        }
      },
      "cafeluna_uhF5B" : {
        "redeemguid" : "uhF5B",
        "redeemTimestamp" : 1681104695257,
        "redeemdate" : "4\/9\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-ov8AU",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_jpyox" : {
        "redeemguid" : "jpyox",
        "redeemTimestamp" : 1684994941797,
        "redeemdate" : "5\/24\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-rohpr",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_2PFxz" : {
        "redeemguid" : "2PFxz",
        "source" : "IG",
        "redeemdate" : "4\/9\/2023",
        "visitdate" : "",
        "promoname" : "_2PFxz",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "",
        "visittime" : ""
      },
      "cafeluna_2gCPB" : {
        "redeemguid" : "2gCPB",
        "source" : "IG",
        "redeemdate" : "4\/9\/2023",
        "visitdate" : "Sunday",
        "promoname" : "4159997117_2gCPB",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : "11:00 AM"
      },
      "cafeluna_qqTto" : {
        "redeemguid" : "qqTto",
        "source" : "IG",
        "redeemdate" : "4\/9\/2023",
        "visitdate" : "",
        "promoname" : "4159997117_qqTto",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_xqfWk" : {
        "redeemguid" : "xqfWk",
        "redeemTimestamp" : 1685206603690,
        "redeemdate" : "5\/27\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-bNKfT",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna-4fbuE" : {
        "12-31-2023" : {
          "cafeluna_4Y1WK" : {
            "redeemguid" : "4Y1WK",
            "redeemTimestamp" : 1704082278609,
            "redeemdate" : "12-31-2023",
            "source" : "IG",
            "promoname" : "cafeluna-4fbuE",
            "visitdate" : "",
            "merchanthandle" : "cafeluna",
            "merchantname" : "Cafe Luna",
            "phonenumber" : "4159997117",
            "visittime" : ""
          }
        }
      },
      "cafeluna_oPmMt" : {
        "redeemguid" : "oPmMt",
        "redeemTimestamp" : 1691861309260,
        "redeemdate" : "8\/12\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-xh79e",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_fSA2H" : {
        "redeemguid" : "fSA2H",
        "source" : "IG",
        "redeemdate" : "4\/9\/2023",
        "visitdate" : "",
        "promoname" : "_fSA2H",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "",
        "visittime" : ""
      },
      "cafeluna_9BLxN" : {
        "redeemguid" : "9BLxN",
        "redeemTimestamp" : 1703109000141,
        "redeemdate" : "12\/20\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-joBfZ",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_czCPa" : {
        "redeemguid" : "czCPa",
        "redeemTimestamp" : 1699212862013,
        "redeemdate" : "11\/5\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-fXyoq",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_rxpYR" : {
        "redeemguid" : "rxpYR",
        "redeemTimestamp" : 1684787708244,
        "redeemdate" : "5\/22\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-bNKfT",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_47WjM" : {
        "redeemguid" : "47WjM",
        "redeemTimestamp" : 1681169576239,
        "redeemdate" : "4\/10\/2023",
        "source" : "IG",
        "promoname" : "CafeLuna-s1a7V8Bfk8RRoqstBsXx3W",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_39C14" : {
        "redeemguid" : "39C14",
        "redeemTimestamp" : 1681100365315,
        "redeemdate" : "4\/9\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-hW4dzFMC1BDKy5h4Uqchz2",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_hfHyf" : {
        "redeemguid" : "hfHyf",
        "redeemTimestamp" : 1681093894671,
        "redeemdate" : "4\/9\/2023",
        "source" : "IG",
        "promoname" : "4159997117_hfHyf",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_njop3" : {
        "redeemguid" : "njop3",
        "source" : "IG",
        "redeemdate" : "4\/9\/2023",
        "visitdate" : "Tuesday",
        "promoname" : "4159997117_njop3",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : "12:00 PM"
      },
      "cafeluna_aBqo6" : {
        "redeemguid" : "aBqo6",
        "redeemTimestamp" : 1684447716170,
        "redeemdate" : "5\/18\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-carpv",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_neMT8" : {
        "redeemguid" : "neMT8",
        "redeemTimestamp" : 1682170884399,
        "redeemdate" : "4\/22\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-carpv",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_eyyQH" : {
        "redeemguid" : "eyyQH",
        "redeemTimestamp" : 1684013042001,
        "redeemdate" : "5\/13\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-af5YG",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_8JFLd" : {
        "redeemguid" : "8JFLd",
        "redeemTimestamp" : 1681098555062,
        "redeemdate" : "4\/9\/2023",
        "source" : "IG",
        "promoname" : "4159997117_8JFLd",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_7QhGk" : {
        "redeemguid" : "7QhGk",
        "redeemTimestamp" : 1681104371235,
        "redeemdate" : "4\/9\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-ov8AU",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_4AW8f" : {
        "redeemguid" : "4AW8f",
        "redeemTimestamp" : 1681098574771,
        "redeemdate" : "4\/9\/2023",
        "source" : "IG",
        "promoname" : "4159997117_4AW8f",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_g8wGn" : {
        "redeemguid" : "g8wGn",
        "redeemTimestamp" : 1681104115113,
        "redeemdate" : "4\/9\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-ov8AU",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_j7zAF" : {
        "redeemguid" : "j7zAF",
        "redeemTimestamp" : 1701537269274,
        "redeemdate" : "12\/2\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-xh79e",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_koPP7" : {
        "redeemguid" : "koPP7",
        "redeemTimestamp" : 1704093107078,
        "redeemdate" : "12\/31\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-4fbuE",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_3arQb" : {
        "redeemguid" : "3arQb",
        "redeemTimestamp" : 1681515992099,
        "redeemdate" : "4\/14\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-ov8AU",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_gM2eV" : {
        "redeemguid" : "gM2eV",
        "redeemTimestamp" : 1683158316475,
        "redeemdate" : "5\/3\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-pBuAW",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_h7WZc" : {
        "redeemguid" : "h7WZc",
        "source" : "IG",
        "redeemdate" : "4\/9\/2023",
        "visitdate" : "Tuesday",
        "promoname" : "4159997117_h7WZc",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : "3:00 PM"
      }
    },
    "info" : {
      "posSystem" : "square",
      "instagramProfileHandle" : "https:\/\/www.instagram.com\/luna",
      "merchantHandle" : "fleetwood",
      "premium" : true,
      "shortBio" : "Short bio here",
      "location" : {
        "phoneNumber" : "4159997117",
        "state" : "California",
        "city" : "Fremont",
        "zip" : "94536",
        "address" : "123 Maple street"
      },
      "merchantName" : "Fleetwood Coffee",
      "cafeBioHandle" : "cafeluna_02",
      "companyName" : "Fleetwood Coffee",
      "posKey" : "123",
      "websiteUrl" : "https:\/\/www.instagram.com\/luna",
      "email" : "curtisspope@gmail.com",
      "merchantBackgroundColor" : "#115885"
    }
  },
  "sanbernardinofireplacewoodstovespecialties" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 862-1330",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/425c9f824fe2719ab9705dd753778c6e295d3ffe\/original.png",
      "instagramHandle" : "sanbernardinofireplacewoodstovespecialties",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "sanbernardinofireplacewoodstovespecialties",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "San Bernardino Fireplace & Woodstove Specialties",
      "city" : "San Bernardino",
      "companyName" : "San Bernardino Fireplace & Woodstove Specialties",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "anaffairtoremembercatering" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(517) 348-9418",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/ff7e11e0407e7cb6faa0a98a020aafed600d9820\/original.png",
      "instagramHandle" : "anaffairtoremembercatering",
      "merchantHandle" : "anaffairtoremembercatering",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "An Affair to Remember Catering",
      "city" : "Lansing",
      "companyName" : "An Affair to Remember Catering",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "billknappsbakeryoutlet" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(734) 316-2894",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/CzYofEAwT1SZyQm4kWjtLupdBsnncOC6.png",
      "instagramHandle" : "billknappsbakeryoutlet",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "billknappsbakeryoutlet",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Bill Knapp's Bakery Outlet",
      "city" : "Ann Arbor",
      "companyName" : "Bill Knapp's Bakery Outlet",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "bobuzz" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(562) 403-4419",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/uGfIHtis39IJsuTMef3RIOpMAmzEkDm7.png",
      "instagramHandle" : "bobuzz",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "bobuzz",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Bobuzz",
      "city" : "Long Beach",
      "companyName" : "Bobuzz",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/bobuzz-menu.square.site\/",
      "email" : ""
    }
  },
  "breakice" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(951) 224-9627",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/mQIlSNzqGIbuRMFvCK5uEQbgkZchR93J.png",
      "instagramHandle" : "breakice",
      "premium" : false,
      "merchantHandle" : "breakice",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Break the Ice",
      "city" : "Riverside",
      "companyName" : "Break the Ice",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "redemptions" : {
    "analytics" : {
      "records" : {
        "landing-page" : {
          "8TmcaT85" : {
            "pathname" : "\/redemptions",
            "time" : "2024-06-26T19:51:44.349Z",
            "merchantHandle" : "redemptions",
            "host" : "cafe.bio",
            "timezone" : "Asia\/Tokyo",
            "userAgent" : "Mozilla\/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/126.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "g8cMjJ4D" : {
            "pathname" : "\/redemptions",
            "time" : "2024-06-26T20:26:16.473Z",
            "merchantHandle" : "redemptions",
            "host" : "localhost:3000",
            "timezone" : "Asia\/Tokyo",
            "userAgent" : "Mozilla\/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/126.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "74YRxhmg" : {
            "pathname" : "\/redemptions",
            "time" : "2024-06-26T20:34:27.871Z",
            "merchantHandle" : "redemptions",
            "host" : "cafe.bio",
            "timezone" : "Asia\/Tokyo",
            "userAgent" : "Mozilla\/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/126.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "nAQJH8NN" : {
            "pathname" : "\/redemptions",
            "time" : "2024-06-26T20:26:16.464Z",
            "merchantHandle" : "redemptions",
            "host" : "localhost:3000",
            "timezone" : "Asia\/Tokyo",
            "userAgent" : "Mozilla\/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/126.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "so68Uo4G" : {
            "pathname" : "\/redemptions",
            "time" : "2024-06-26T19:52:11.734Z",
            "merchantHandle" : "redemptions",
            "host" : "localhost:3000",
            "timezone" : "Asia\/Tokyo",
            "userAgent" : "Mozilla\/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/126.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "uvwWcabS" : {
            "pathname" : "\/redemptions",
            "time" : "2024-06-26T19:52:11.740Z",
            "merchantHandle" : "redemptions",
            "host" : "localhost:3000",
            "timezone" : "Asia\/Tokyo",
            "userAgent" : "Mozilla\/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/126.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          }
        }
      }
    }
  },
  "vivavegetariangrill" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(541) 636-5826",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/tZima8inmhciwK7dy2WgwGzEtA0lOwOa.png",
      "instagramHandle" : "vivavegetariangrill",
      "twitterHandle" : "",
      "merchantHandle" : "vivavegetariangrill",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Viva! Vegetarian Grill",
      "city" : "Eugene",
      "companyName" : "Viva! Vegetarian Grill",
      "websiteUrl" : "http:\/\/www.eugenecooks.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "cornerstoneassemblyofgod" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 478-0875",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/12091c7d782ed1eb9a0e1191da75b7d4dfeadc97\/original.png",
      "instagramHandle" : "cornerstoneassemblyofgod",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "cornerstoneassemblyofgod",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Cornerstone Assembly of God",
      "city" : "San Bernardino",
      "companyName" : "Cornerstone Assembly of God",
      "websiteUrl" : "https:\/\/ag.org\/church?g=ea5a6eba-ae41-41c6-91ba-32b41596c159&d=False",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "backtothegrind" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(951) 784-0800",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/8126f81d34f9bc030d2e131e4b145fe43a958a90\/original.png",
      "instagramHandle" : "backtothegrind",
      "premium" : false,
      "merchantHandle" : "backtothegrind",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Back To The Grind",
      "city" : "Riverside",
      "companyName" : "Back To The Grind",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.facebook.com\/backtothegrind\/",
      "email" : ""
    }
  },
  "cinnaholic" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(734) 707-1955",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/JitENjavgwwlhr1XmKSiYP5Zae3coVMg.png",
      "instagramHandle" : "cinnaholic",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "cinnaholic",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Cinnaholic",
      "city" : "Ann Arbor",
      "companyName" : "Cinnaholic",
      "websiteUrl" : "https:\/\/locations.cinnaholic.com\/ll\/us\/mi\/ann-arbor\/121-e-liberty-st\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "stablecafe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 552-1199",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/P2nzEeiuMB8GW8J02471U43PKxEomLWB.png",
      "instagramHandle" : "stablecafe",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "stablecafe",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Stable Cafe",
      "city" : "San Francisco",
      "companyName" : "Stable Cafe",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/stablecafe.com\/",
      "email" : ""
    }
  },
  "timeoutplaycafe" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(517) 253-7194",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/edfb4e95831df73df747984900f09317ec531bb2\/original.jpeg",
      "instagramHandle" : "timeoutplaycafe",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "timeoutplaycafe",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Timeout Play Cafe",
      "city" : "Lansing",
      "companyName" : "Timeout Play Cafe",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "loveskitchenllc" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(517) 619-9694",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ZjNsSzNIAkoNO3wC0HtVKeJndzAY4H70.png",
      "instagramHandle" : "loveskitchenllc",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "loveskitchenllc",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Loves Kitchen LLC",
      "city" : "Lansing",
      "companyName" : "Loves Kitchen LLC",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "lovedhomestead" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 731-3108",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/AHSqbNpZOML59eK1ZGYagyLXScl52BCc.png",
      "instagramHandle" : "lovedhomestead",
      "merchantHandle" : "lovedhomestead",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Loved Homestead",
      "city" : "San Bernardino",
      "companyName" : "Loved Homestead",
      "websiteUrl" : "https:\/\/www.lovedhomestead.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "souperbowls" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(541) 735-3982",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/e58032e7f4f7a7d007be1d501dc1175a5776c9cf\/original.jpeg",
      "instagramHandle" : "souperbowls",
      "merchantHandle" : "souperbowls",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Souper Bowls",
      "city" : "Eugene",
      "companyName" : "Souper Bowls",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/souperbowlseugene.com\/",
      "email" : ""
    }
  },
  "hillbillybrewsoregon" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(541) 653-2816",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/3d1853b22b9beac2a8c7fe0098ba7b956f6138a4\/original.jpeg",
      "instagramHandle" : "hillbillybrewsoregon",
      "merchantHandle" : "hillbillybrewsoregon",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Hillbilly Brews Oregon",
      "city" : "Eugene",
      "companyName" : "Hillbilly Brews Oregon",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "cafeinacafe" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(619) 861-2104",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/aa832eafbded0868a4bbec891c105d4c013953b8\/original.png",
      "instagramHandle" : "cafeinacafe",
      "merchantHandle" : "cafeinacafe",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Cafeina Cafe",
      "city" : "Chula Vista",
      "companyName" : "Cafeina Cafe",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.cafeinacafe.com\/",
      "email" : ""
    }
  },
  "barrelhousebrewingfresnotaproom" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(559) 389-0722",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/6E80MGLBHhmzgvEdYaYDakiBkoUX0eV8.png",
      "instagramHandle" : "barrelhousebrewingfresnotaproom",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "barrelhousebrewingfresnotaproom",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "BarrelHouse Brewing Fresno - Taproom",
      "city" : "Fresno",
      "companyName" : "BarrelHouse Brewing Fresno - Taproom",
      "websiteUrl" : "https:\/\/barrelhousebrewing.com\/locations-fresno",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "millaysakewinebar" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/JF6pTXIB8OxB0aL0RUqTOaoaI71byB9P.png",
      "instagramHandle" : "millaysakewinebar",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "millaysakewinebar",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Millay Sake + Wine Bar",
      "city" : "San Francisco",
      "companyName" : "Millay Sake + Wine Bar",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.millaysf.com\/",
      "email" : ""
    }
  },
  "adiglobaldistributionwarren" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(586) 759-1455",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/V4ROXwExFuO0e0rcrtk23M2IiHpoKvzw.png",
      "instagramHandle" : "adiglobaldistributionwarren",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "adiglobaldistributionwarren",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "ADI Global Distribution - Warren",
      "city" : "Warren",
      "companyName" : "ADI Global Distribution - Warren",
      "websiteUrl" : "https:\/\/www.adiglobaldistribution.us\/?utm_source=Social&utm_medium=GMB&utm_campaign=ND",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "leselbistro" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(253) 327-1015",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/5gS1WwEbF9uh44NZ8j7OgzFvMKwtgADz.png",
      "instagramHandle" : "leselbistro",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "leselbistro",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Le Sel Bistro",
      "city" : "Tacoma",
      "companyName" : "Le Sel Bistro",
      "websiteUrl" : "http:\/\/www.leselbistro.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "myboyzhibachi" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 684-1036",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/e21a805786abbaebcae3a348f03439719070ff04\/original.jpeg",
      "instagramHandle" : "myboyzhibachi",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "myboyzhibachi",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "MY BOYZ HIBACHI 2",
      "city" : "Fontana",
      "companyName" : "MY BOYZ HIBACHI 2",
      "websiteUrl" : "https:\/\/www.myboyzhibachi.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "lorinasediblegarden" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 402-7567",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/c72f48c1b3bb42da97b4a7e25c5cda45f6ab4c41\/original.png",
      "instagramHandle" : "lorinasediblegarden",
      "merchantHandle" : "lorinasediblegarden",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Lorina's Edible Garden",
      "city" : "Modesto",
      "companyName" : "Lorina's Edible Garden",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "muddworksroastery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(503) 236-2326",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/cIJ1JWB5FjacaY37820YRJCSVhNaPgH1.png",
      "instagramHandle" : "muddworksroastery",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "muddworksroastery",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Mudd Works Roastery",
      "city" : "Portland",
      "companyName" : "Mudd Works Roastery",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/muddworks.com\/",
      "email" : ""
    }
  },
  "zagwesrestaurantllc" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "",
      "phone" : "",
      "merchantImage" : "zagwesrestaurantllc",
      "instagramHandle" : " LLC\"",
      "merchantHandle" : "zagwesrestaurantllc",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "(541) 543-0787",
      "squareSandboxKey" : "",
      "merchantName" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/awDFUJiKhnfc7owgZaeOurOPtaMJuWGT.png",
      "city" : "",
      "companyName" : "\"Zagwe's Restaurant",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : "https:\/\/zagwes-restaurant-llc.square.site\/"
    }
  },
  "daydreamercoffee" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(503) 206-4170",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/YykcaD3bDXyzlXDr8FCGpsjDb4ja0k74.png",
      "instagramHandle" : "daydreamercoffee",
      "merchantHandle" : "daydreamercoffee",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Daydreamer Coffee",
      "city" : "Vancouver",
      "companyName" : "Daydreamer Coffee",
      "websiteUrl" : "http:\/\/daydreamercoffeepdx.com\/order",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "twistedsmoke" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(519) 817-4189",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/hQaenJ0rynzSn5b0fColtUpdFnCaF346.png",
      "instagramHandle" : "twistedsmoke",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "twistedsmoke",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Twisted Smoke",
      "city" : "Detroit",
      "companyName" : "Twisted Smoke",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.facebook.com\/TWISTED.SMOKE.WINDSOR\/",
      "email" : ""
    }
  },
  "chateaugrandrapids" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/VGvxSQqvuR25tJsY23J66SxlRsVx0KDV.png",
      "instagramHandle" : "chateaugrandrapids",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "chateaugrandrapids",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Chateau Grand Rapids",
      "city" : "Grand Rapids",
      "companyName" : "Chateau Grand Rapids",
      "websiteUrl" : "https:\/\/www.chateaugrandrapids.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "houseoficecream" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 278-6096",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/n3HpJxEsRMfcc24P97zG1Z1USAYHjC5K.jpeg",
      "instagramHandle" : "houseoficecream",
      "merchantHandle" : "houseoficecream",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "House of Ice Cream",
      "city" : "Stockton",
      "companyName" : "House of Ice Cream",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "thelittlebarnatnativewestnursery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(619) 423-2284",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/015ad48ef02f5c19084f4c19cb7e68a219404436\/original.png",
      "instagramHandle" : "thelittlebarnatnativewestnursery",
      "merchantHandle" : "thelittlebarnatnativewestnursery",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Little Barn at Native West Nursery",
      "city" : "Chula Vista",
      "companyName" : "The Little Barn at Native West Nursery",
      "websiteUrl" : "https:\/\/nativewest.com\/retail",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "tuktukthaistreetfood" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 760-4660",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/1d323026632d5c8ec48a2cbe062ec1204c5f389a\/original.jpeg",
      "instagramHandle" : "tuktukthaistreetfood",
      "premium" : false,
      "merchantHandle" : "tuktukthaistreetfood",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Tuk Tuk Thai Street Food",
      "city" : "Santa Ana",
      "companyName" : "Tuk Tuk Thai Street Food",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.tuktukthai.us\/",
      "email" : ""
    }
  },
  "ilovetacosllc" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "ilovetacosllc",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "ilovetacosllc",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "I Love Tacos LLC",
      "city" : "Tacoma",
      "companyName" : "I Love Tacos LLC",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/instagram.com\/ilovetacos253",
      "email" : ""
    }
  },
  "lahotdogsmunchies" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 279-4364",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/o5c3sIInEUSVxNlWay9q0IuCkBezdHpV.jpeg",
      "instagramHandle" : "lahotdogsmunchies",
      "merchantHandle" : "lahotdogsmunchies",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "LA Hot Dogs & Munchies",
      "city" : "Fontana",
      "companyName" : "LA Hot Dogs & Munchies",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "bluescoopcreamery" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 729-3419",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/yJU1dy42tOy0GdIkPTOZVxVZ30dW8SoE.png",
      "instagramHandle" : "bluescoopcreamery",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "bluescoopcreamery",
      "squareSandboxKey" : "",
      "companyType" : "Ice Cream Shop",
      "merchantName" : "Blue Scoop Creamery",
      "city" : "Anaheim",
      "companyName" : "Blue Scoop Creamery",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/bluescoopcreamery.com\/",
      "email" : "info@bluescoop.com"
    }
  },
  "craverestaurantdowntownsantaana" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 907-0063",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/kGM7cmWQ02zTyPgzpDfidXqrpy6Bkakf.png",
      "instagramHandle" : "craverestaurantdowntownsantaana",
      "twitterHandle" : "",
      "merchantHandle" : "craverestaurantdowntownsantaana",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Crave Restaurant Downtown Santa Ana",
      "city" : "Santa Ana",
      "companyName" : "Crave Restaurant Downtown Santa Ana",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.crave410.com\/",
      "email" : ""
    }
  },
  "sweetsfactory" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(586) 274-2222",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/p15KaJmxaXJRbcvjmZcKeAxvvkLf0G7s.png",
      "instagramHandle" : "sweetsfactory",
      "merchantHandle" : "sweetsfactory",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Sweets Factory",
      "city" : "Sterling Heights",
      "companyName" : "Sweets Factory",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "oleoleburritoexpress" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(510) 251-9200",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Ce89hCp61NY3eVTiUpOIRaz2HFRq867H.png",
      "instagramHandle" : "oleoleburritoexpress",
      "premium" : false,
      "merchantHandle" : "oleoleburritoexpress",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Ole Ole Burrito Express",
      "city" : "Oakland",
      "companyName" : "Ole Ole Burrito Express",
      "websiteUrl" : "http:\/\/oleolebe.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "macwinecellars" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(503) 753-4977",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/bSq75G3JxABq5oIEcak5iv0M61MCenaa.png",
      "instagramHandle" : "macwinecellars",
      "merchantHandle" : "macwinecellars",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Mac Wine Cellars",
      "city" : "Vancouver",
      "companyName" : "Mac Wine Cellars",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "brewingcompany" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(734) 340-3468",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/S5yGx0ZPcumMRJ95NymrVaY4jvyzVMQu.png",
      "instagramHandle" : "brewingcompany",
      "twitterHandle" : "",
      "merchantHandle" : "brewingcompany",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "734 Brewing Company",
      "city" : "Ann Arbor",
      "companyName" : "734 Brewing Company",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.734brewing.com\/",
      "email" : ""
    }
  },
  "reesefarms" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(517) 484-2868",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/FC0t7ILjUhKZ0OKGrJsdrrcKJSoNiNuK.png",
      "instagramHandle" : "reesefarms",
      "merchantHandle" : "reesefarms",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Reese Farms",
      "city" : "Lansing",
      "companyName" : "Reese Farms",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.reesefarmsmi.com\/",
      "email" : ""
    }
  },
  "madlosramenhouse" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "madlosramenhouse",
      "premium" : false,
      "merchantHandle" : "madlosramenhouse",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "MadLo's Ramen House",
      "city" : "Spokane",
      "companyName" : "MadLo's Ramen House",
      "websiteUrl" : "http:\/\/madlosramen.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "greenlifefitfoods" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(949) 701-8656",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "greenlifefitfoods",
      "twitterHandle" : "",
      "merchantHandle" : "greenlifefitfoods",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Greenlife Fit Foods",
      "city" : "Santa Ana",
      "companyName" : "Greenlife Fit Foods",
      "websiteUrl" : "http:\/\/greenlifefitfoods.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "consciouscreamery" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(916) 407-1175",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/2ihTaJRXfOHaOXd9fGEhKslyUV0PCTPV.png",
      "instagramHandle" : "consciouscreamery",
      "merchantHandle" : "consciouscreamery",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Conscious Creamery",
      "city" : "Sacramento",
      "companyName" : "Conscious Creamery",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.consciouscreamery.com\/",
      "email" : ""
    }
  },
  "denniswhang" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(916) 734-2011",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/LoVAGM0c1owl3tGi2F2x0Kfn1Do3cwba.png",
      "instagramHandle" : "denniswhang",
      "merchantHandle" : "denniswhang",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Dennis Whang",
      "city" : "Sacramento",
      "companyName" : "Dennis Whang",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "cavalettivineyards" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(805) 405-5272",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/bfSJtCJVX2mlnU0PtM97oxF0gSQJLd8z.png",
      "instagramHandle" : "cavalettivineyards",
      "merchantHandle" : "cavalettivineyards",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Cavaletti Vineyards",
      "city" : "Oxnard",
      "companyName" : "Cavaletti Vineyards",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.cavalettivineyards.com\/",
      "email" : ""
    }
  },
  "wildroastcoffeeco" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 431-2500",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/foJPPenBEb5L6gtpd4oK0WNRLb1t0pEM.png",
      "instagramHandle" : "wildroastcoffeeco",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "wildroastcoffeeco",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Wildroast Coffee Co",
      "city" : "Grand Rapids",
      "companyName" : "Wildroast Coffee Co",
      "websiteUrl" : "https:\/\/wildroastcoffeeco.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "lacroque" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/9019d71de9979d09fd0cb972f1a3ead8a6534a41\/original.png",
      "instagramHandle" : "lacroque",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "lacroque",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "LA ‘CROQUE",
      "city" : "Long Beach",
      "companyName" : "LA ‘CROQUE",
      "websiteUrl" : "https:\/\/lacroquesandwiches.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "tomatitomexicanfood" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(619) 575-3388",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/e3dc80f892154f7019964f55e30da74478df1df2\/original.jpeg",
      "instagramHandle" : "tomatitomexicanfood",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "tomatitomexicanfood",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Tomatito Mexican Food",
      "city" : "Chula Vista",
      "companyName" : "Tomatito Mexican Food",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.tomatitorestaurant.com\/",
      "email" : ""
    }
  },
  "civilcoffee" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(323) 596-0000",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/KYRLPckCqSuSCbYMb2VoDHmJyYLzFgDF.png",
      "instagramHandle" : "civilcoffee",
      "premium" : false,
      "merchantHandle" : "civilcoffee",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Civil Coffee",
      "city" : "Glendale",
      "companyName" : "Civil Coffee",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.civilcoffee.com\/",
      "email" : ""
    }
  },
  "sustainnutritionllc" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/sOchqzsfDbvZhlnrvNLITga4thGL1mHg.png",
      "instagramHandle" : "sustainnutritionllc",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "sustainnutritionllc",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sustain Nutrition LLC.",
      "city" : "Sacramento",
      "companyName" : "Sustain Nutrition LLC.",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.sustain-nutrition.com\/",
      "email" : ""
    }
  },
  "dilseindiancuisine" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(503) 804-5619",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ybJpdoaxQNJLyfzGCKSq7mLW84pvOONA.png",
      "instagramHandle" : "dilseindiancuisine",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "dilseindiancuisine",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Dil Se Indian Cuisine",
      "city" : "Portland",
      "companyName" : "Dil Se Indian Cuisine",
      "websiteUrl" : "http:\/\/dilsetakeout.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "baccofarmsweeddispensary" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(810) 877-6538",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "baccofarmsweeddispensary",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "baccofarmsweeddispensary",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Bacco Farms Weed Dispensary",
      "city" : "Flint",
      "companyName" : "Bacco Farms Weed Dispensary",
      "websiteUrl" : "https:\/\/www.baccofarms.com\/about-us\/?utm_source=terrayn_gbp&utm_medium=organic&utm_campaign=flint_gbp",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "goodjudysmarketjuicebar" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/0kErPOr9ZtIGynMpPJPd6pJ72an6R4oF.png",
      "instagramHandle" : "goodjudysmarketjuicebar",
      "merchantHandle" : "goodjudysmarketjuicebar",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Good Judy's Market & Juice Bar",
      "city" : "Grand Rapids",
      "companyName" : "Good Judy's Market & Juice Bar",
      "websiteUrl" : "http:\/\/drinkgoodjudys.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sweeting" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(734) 213-3300",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "sweeting",
      "premium" : false,
      "merchantHandle" : "sweeting",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sweeting",
      "city" : "Ann Arbor",
      "companyName" : "Sweeting",
      "websiteUrl" : "https:\/\/www.fantuanorder.com\/en-US\/store\/sweeting-downtown\/us-17961",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sugarbuzz" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 941-9059",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/L6NzSId2WKaxPTASC7bMtvaYmh30bnnO.png",
      "instagramHandle" : "sugarbuzz",
      "merchantHandle" : "sugarbuzz",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sugarbuzz",
      "city" : "Anaheim",
      "companyName" : "Sugarbuzz",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "thewholebowl" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(503) 753-7071",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/H7H8BVtUEezEIu53a1NK2JjbR3ebowOG.png",
      "instagramHandle" : "thewholebowl",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "thewholebowl",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Whole Bowl",
      "city" : "Portland",
      "companyName" : "The Whole Bowl",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.thewholebowl.com\/",
      "email" : ""
    }
  },
  "lemonadelane" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(250) 488-9554",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/a128fa6422729a74b994fe1f2c6735cbbd24a2cf\/original.jpeg",
      "instagramHandle" : "lemonadelane",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "lemonadelane",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Lemonade Lane",
      "city" : "San Bernardino",
      "companyName" : "Lemonade Lane",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.lemonadelane.com\/",
      "email" : ""
    }
  },
  "thatcherscoffee" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(360) 258-0571",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/7saFN1kY0UsNJO9FWxGoHkv8mgbrSx0r.png",
      "instagramHandle" : "thatcherscoffee",
      "merchantHandle" : "thatcherscoffee",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Thatcher's Coffee",
      "city" : "Vancouver",
      "companyName" : "Thatcher's Coffee",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/thatcherscoffee.com\/",
      "email" : ""
    }
  },
  "taquerialascomadres" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 562-9002",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/0cb3f4278b6690f0f2ad235655a8293b10a25820\/original.jpeg",
      "instagramHandle" : "taquerialascomadres",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "taquerialascomadres",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Taqueria Las Comadres #1",
      "city" : "Anaheim",
      "companyName" : "Taqueria Las Comadres #1",
      "websiteUrl" : "https:\/\/taqueriacomadres.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sterlingcatering" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(971) 221-6965",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/nXHXSexUQR4UEy2AnaeWF0tznWkEYcd9.png",
      "instagramHandle" : "sterlingcatering",
      "twitterHandle" : "",
      "merchantHandle" : "sterlingcatering",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sterling Catering",
      "city" : "Portland",
      "companyName" : "Sterling Catering",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.sterlingcateringpdx.com\/",
      "email" : ""
    }
  },
  "nativepizza" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(805) 667-8991",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/MRY9C5WOCbikc8QkaafcZgCkzob3JbHr.png",
      "instagramHandle" : "nativepizza",
      "twitterHandle" : "",
      "merchantHandle" : "nativepizza",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Native Pizza",
      "city" : "Oxnard",
      "companyName" : "Native Pizza",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "chickenshackwarrenschoenherr" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(586) 755-0180",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/kNpB1GCh4IH020lYw6CZ0J9IX2y4a6YD.png",
      "instagramHandle" : "chickenshackwarrenschoenherr",
      "twitterHandle" : "",
      "merchantHandle" : "chickenshackwarrenschoenherr",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Chicken Shack Warren Schoenherr",
      "city" : "Warren",
      "companyName" : "Chicken Shack Warren Schoenherr",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.chickenshack.com\/",
      "email" : ""
    }
  },
  "onecraftcat" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(253) 600-7967",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/3z6B2hLRTtw83gWqrvNmRMLBEbErCxTd.png",
      "instagramHandle" : "onecraftcat",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "onecraftcat",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "One Craft Cat",
      "city" : "Tacoma",
      "companyName" : "One Craft Cat",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "knoxchocolatiers" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 399-4414",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "knoxchocolatiers",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "knoxchocolatiers",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Knox Chocolatiers",
      "city" : "San Bernardino",
      "companyName" : "Knox Chocolatiers",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/knoxchocolatiers.com\/contact",
      "email" : ""
    }
  },
  "squabischpretzels" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(510) 282-6316",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/OBt2igxR8yzlAj1CdEPopfOnak0N04Nm.png",
      "instagramHandle" : "squabischpretzels",
      "twitterHandle" : "",
      "merchantHandle" : "squabischpretzels",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Squabisch Pretzels",
      "city" : "Oakland",
      "companyName" : "Squabisch Pretzels",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/squabisch.com\/",
      "email" : ""
    }
  },
  "palazzolojosephp" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(586) 979-7272",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/3793747412516be2f0f2ce4108ee22e8\/original.jpeg",
      "instagramHandle" : "palazzolojosephp",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "palazzolojosephp",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Palazzolo Joseph P",
      "city" : "Warren",
      "companyName" : "Palazzolo Joseph P",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.palazzololaw.com\/",
      "email" : ""
    }
  },
  "bresenojustinammd" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 653-1683",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/5GjELcaDzKT8dawALcDiWDmgqw6ScHKZ.png",
      "instagramHandle" : "bresenojustinammd",
      "merchantHandle" : "bresenojustinammd",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Breseno Justina M MD",
      "city" : "Riverside",
      "companyName" : "Breseno Justina M MD",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "beehivebakerygirl" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/B7QDmDw2K0RHLJnrYYoySqHtAVMMwk0W.png",
      "instagramHandle" : "beehivebakerygirl",
      "merchantHandle" : "beehivebakerygirl",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Beehive Bakery Girl",
      "city" : "San Bernardino",
      "companyName" : "Beehive Bakery Girl",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "pachamamacoffee" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(916) 476-4385",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/1c6c2bdf45dbb86b2f018a4cb6c6b231490077f1\/original.png",
      "instagramHandle" : "pachamamacoffee",
      "premium" : false,
      "merchantHandle" : "pachamamacoffee",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Pachamama Coffee",
      "city" : "Sacramento",
      "companyName" : "Pachamama Coffee",
      "websiteUrl" : "http:\/\/pachamamacoffee.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "mexipinocraft" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(619) 422-2044",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "mexipinocraft",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "mexipinocraft",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Mexipino Craft",
      "city" : "Chula Vista",
      "companyName" : "Mexipino Craft",
      "websiteUrl" : "http:\/\/mexipinocraftrestaurant.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "celticattic" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(360) 531-1107",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/ce6dea03c9c4c99e2471b66710862e72291cb3a5\/original.png",
      "instagramHandle" : "celticattic",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "celticattic",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Celtic Attic",
      "city" : "Tacoma",
      "companyName" : "Celtic Attic",
      "websiteUrl" : "http:\/\/www.celticattic.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "goldcoastbakingcompany" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 545-2253",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/dkEVnndmk7eCPDkyjaNF2acLlfXoeUXQ.png",
      "instagramHandle" : "goldcoastbakingcompany",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "goldcoastbakingcompany",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Gold Coast Baking Company.",
      "city" : "Santa Ana",
      "companyName" : "Gold Coast Baking Company.",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.goldcoastbakery.com\/",
      "email" : ""
    }
  },
  "rosarealtyinc" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(408) 259-2988",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/d4ee158015496bbb60a1f6bfbc9a2664a175bc6f\/original.jpeg",
      "instagramHandle" : "rosarealtyinc",
      "premium" : false,
      "merchantHandle" : "rosarealtyinc",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Rosa Realty Inc",
      "city" : "San Jose",
      "companyName" : "Rosa Realty Inc",
      "websiteUrl" : "http:\/\/www.rosarealty.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "imperialsteakhousebar" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(619) 234-3525",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/aIwQ89Bt4UDPE4GPMqdCPKV6hNTJ3OaY.png",
      "instagramHandle" : "imperialsteakhousebar",
      "merchantHandle" : "imperialsteakhousebar",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Imperial Steakhouse & Bar",
      "city" : "Chula Vista",
      "companyName" : "Imperial Steakhouse & Bar",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/imperialhouserestaurant.com\/",
      "email" : ""
    }
  },
  "carmax" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(949) 341-0801",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/eLCxwdzxP3l0YKLpPzDzIZG9kgqhzWAs.png",
      "instagramHandle" : "carmax",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "carmax",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "CarMax",
      "city" : "Irvine",
      "companyName" : "CarMax",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.carmax.com\/stores\/7195?utm_source=SEO_Local&utm_medium=SEO_Local_Snack_Pack&utm_campaign=SEO_CA&utm_content=SEO_Irvine&utm_term=SEO_7195",
      "email" : ""
    }
  },
  "superiorturboinjection" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(313) 842-4616",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/NWGrPHCl8bUCYlfo5RyMEadoZsDHJpDR.png",
      "instagramHandle" : "superiorturboinjection",
      "premium" : false,
      "merchantHandle" : "superiorturboinjection",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Superior Turbo & Injection",
      "city" : "Detroit",
      "companyName" : "Superior Turbo & Injection",
      "websiteUrl" : "http:\/\/www.superiorturbo.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "bluebottlecoffee" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 661-3510",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/N7T1nsRLiGmqJMqncAeXlEJuxGdtXMUj.png",
      "instagramHandle" : "bluebottlecoffee",
      "merchantHandle" : "bluebottlecoffee",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Blue Bottle Coffee",
      "city" : "San Jose",
      "companyName" : "Blue Bottle Coffee",
      "websiteUrl" : "https:\/\/bluebottlecoffee.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "originalpatternbrewing" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 844-4833",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/aHMjM2YGi3pZIlGGQYyoxX6RWKclX4jq.png",
      "instagramHandle" : "originalpatternbrewing",
      "premium" : false,
      "merchantHandle" : "originalpatternbrewing",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Original Pattern Brewing",
      "city" : "Oakland",
      "companyName" : "Original Pattern Brewing",
      "websiteUrl" : "http:\/\/www.originalpatternbeer.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "achilles" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(408) 984-2204",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/fca9eb36003f088aa25940c4e147bd984d741bd1\/original.jpeg",
      "instagramHandle" : "achilles",
      "merchantHandle" : "achilles",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Achilles",
      "city" : "San Jose",
      "companyName" : "Achilles",
      "websiteUrl" : "https:\/\/achillesrestaurant.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "kalschickencoop" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MT\r",
      "phone" : "(406) 206-3154",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/mbtLuUG6o8hFdd7GuMa2mXKQVIEVpLa3.png",
      "instagramHandle" : "kalschickencoop",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "kalschickencoop",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Kal's Chicken Coop",
      "city" : "Billings",
      "companyName" : "Kal's Chicken Coop",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "thegoodhopbarbottleshop" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 338-6598",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/gwjZiQtH2JNxL0aWi82LvRYF3keXEQaA.png",
      "instagramHandle" : "thegoodhopbarbottleshop",
      "merchantHandle" : "thegoodhopbarbottleshop",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Good Hop Bar & Bottle Shop",
      "city" : "Oakland",
      "companyName" : "The Good Hop Bar & Bottle Shop",
      "websiteUrl" : "http:\/\/thegoodhop.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "bethelbaptistchurch" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 839-3600",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/m40YUcclvVuPankwJhmctYpJedFuOGp2.png",
      "instagramHandle" : "bethelbaptistchurch",
      "merchantHandle" : "bethelbaptistchurch",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Bethel Baptist Church",
      "city" : "Santa Ana",
      "companyName" : "Bethel Baptist Church",
      "websiteUrl" : "http:\/\/bbministries.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thejuicyleaf" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(310) 907-5019",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/5a7cf515ec29880881d843b888a6da0b\/original.jpeg",
      "instagramHandle" : "thejuicyleaf",
      "twitterHandle" : "",
      "merchantHandle" : "thejuicyleaf",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Juicy Leaf",
      "city" : "Glendale",
      "companyName" : "The Juicy Leaf",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.thejuicyleaf.com\/",
      "email" : ""
    }
  },
  "blankslatecreamery" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(734) 922-5383",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/2f6be90699033c634aa285d46ea15d86\/original.jpeg",
      "instagramHandle" : "blankslatecreamery",
      "twitterHandle" : "",
      "merchantHandle" : "blankslatecreamery",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Blank Slate Creamery",
      "city" : "Ann Arbor",
      "companyName" : "Blank Slate Creamery",
      "websiteUrl" : "http:\/\/www.blankslatecreamery.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "lannathairestaurant" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(408) 626-7718",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/9417f714ebeff6e9e4299f56bdf4d345284a08f1\/original.png",
      "instagramHandle" : "lannathairestaurant",
      "premium" : false,
      "merchantHandle" : "lannathairestaurant",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Lanna Thai Restaurant",
      "city" : "San Jose",
      "companyName" : "Lanna Thai Restaurant",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.lannathaisanjose.com\/",
      "email" : ""
    }
  },
  "lbscoffeebar" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(503) 329-5782",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/b9ZTFQCZMXGcaXslC0i7FkczrGnrWAhG.png",
      "instagramHandle" : "lbscoffeebar",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "lbscoffeebar",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "40 LBS Coffee Bar",
      "city" : "Portland",
      "companyName" : "40 LBS Coffee Bar",
      "websiteUrl" : "http:\/\/40lbscoffee.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "laplayasoulkitchen" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 874-1177",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/M4RD8FK6XyoS3URcsNQa1t3ffd730q52.png",
      "instagramHandle" : "laplayasoulkitchen",
      "merchantHandle" : "laplayasoulkitchen",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "LA Playa Soul Kitchen",
      "city" : "Oxnard",
      "companyName" : "LA Playa Soul Kitchen",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.laplayasoul.com\/",
      "email" : ""
    }
  },
  "cremacoffeebakery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(503) 234-0206",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/aTLdOCpMtaOV2dqBBjwSWTjPMoMFQrwW.png",
      "instagramHandle" : "cremacoffeebakery",
      "premium" : false,
      "merchantHandle" : "cremacoffeebakery",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Crema Coffee + Bakery",
      "city" : "Portland",
      "companyName" : "Crema Coffee + Bakery",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/cremabakery.com\/",
      "email" : ""
    }
  },
  "wetcoastbrewingco" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(253) 432-4966",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/s3PjfGp4DEHU0dsVn50o5d8iCdLTUSuw.png",
      "instagramHandle" : "wetcoastbrewingco",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "wetcoastbrewingco",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Wet Coast Brewing Co.",
      "city" : "Tacoma",
      "companyName" : "Wet Coast Brewing Co.",
      "websiteUrl" : "http:\/\/www.wetcoastbrewing.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "delskisprimemeats" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 301-6949",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/c37fb1071f5a451aee22a331b8ddf768b68f9da7\/original.jpeg",
      "instagramHandle" : "delskisprimemeats",
      "premium" : false,
      "merchantHandle" : "delskisprimemeats",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Delski's Prime Meats",
      "city" : "Grand Rapids",
      "companyName" : "Delski's Prime Meats",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/delskimeats.com\/",
      "email" : ""
    }
  },
  "olympiaprovisions" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(503) 954-3663",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Utf7ce7PjHdnGU5eaRp2sLx5ICtdmFLH.png",
      "instagramHandle" : "olympiaprovisions",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "olympiaprovisions",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Olympia Provisions",
      "city" : "Portland",
      "companyName" : "Olympia Provisions",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.olympiaprovisions.com\/",
      "email" : ""
    }
  },
  "mosaiccommunitygardenofchulavista" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(619) 457-1299",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "mosaiccommunitygardenofchulavista",
      "twitterHandle" : "",
      "merchantHandle" : "mosaiccommunitygardenofchulavista",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Mosaic Community Garden of Chula Vista",
      "city" : "Chula Vista",
      "companyName" : "Mosaic Community Garden of Chula Vista",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.mosaiccommunitygarden.org\/",
      "email" : ""
    }
  },
  "greenonion" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 683-3363",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/9JvZQCP0W57Lr34d3qxlKsGcmeLZNXQ3.png",
      "instagramHandle" : "greenonion",
      "merchantHandle" : "greenonion",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Green Onion",
      "city" : "Moreno Valley",
      "companyName" : "Green Onion",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.greenonionmexicanrestaurant.com\/",
      "email" : ""
    }
  },
  "ardenrestaurantportland" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(503) 206-6097",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/BKY0V1QRmhDitwc0uajWa4ZUVUNPWeIf.png",
      "instagramHandle" : "ardenrestaurantportland",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "ardenrestaurantportland",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Arden Restaurant Portland",
      "city" : "Portland",
      "companyName" : "Arden Restaurant Portland",
      "websiteUrl" : "http:\/\/bit.ly\/ArdenPDX",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "eboncoffeecollective" : {
    "info" : {
      "state" : "MT\r",
      "posSystem" : "Square",
      "phone" : "(406) 839-2030",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ELfWuANd20evq1OKdelk70mcumo4wrk4.png",
      "instagramHandle" : "eboncoffeecollective",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "eboncoffeecollective",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Ebon Coffee Collective",
      "city" : "Billings",
      "companyName" : "Ebon Coffee Collective",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/eboncoffeecollective.com\/",
      "email" : ""
    }
  },
  "caferhema" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(810) 342-0511",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/xKGFHaUCnbw6ZyvlTbiIy6wUjzV32gpI.png",
      "instagramHandle" : "caferhema",
      "premium" : false,
      "merchantHandle" : "caferhema",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Cafe Rhema",
      "city" : "Flint",
      "companyName" : "Cafe Rhema",
      "websiteUrl" : "http:\/\/caferhema.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "yigebecoffeellc" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(971) 334-6298",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/s17Hrl0PlghqGaFBs2erOuVYHZa21Owc.png",
      "instagramHandle" : "yigebecoffeellc",
      "merchantHandle" : "yigebecoffeellc",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Yigebe Coffee LLC",
      "city" : "Portland",
      "companyName" : "Yigebe Coffee LLC",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.yigebecoffee.com\/",
      "email" : ""
    }
  },
  "hibachikings" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(619) 796-8858",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/ae25045c0d4946c6f6327c5e58de729151dff499\/original.jpeg",
      "instagramHandle" : "hibachikings",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "hibachikings",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "HIBACHI KINGS",
      "city" : "Chula Vista",
      "companyName" : "HIBACHI KINGS",
      "websiteUrl" : "https:\/\/hibachikingsd.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "adya" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 533-2392",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/aaSZTfLrrk1xjae6sUviSEaW9WI02AeS.png",
      "instagramHandle" : "adya",
      "premium" : false,
      "merchantHandle" : "adya",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "ADYA",
      "city" : "Irvine",
      "companyName" : "ADYA",
      "websiteUrl" : "https:\/\/adyaoc.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thesvengrd" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(616) 214-7207",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/nnjvEjLslSw72aSuzbeoxBymWa9pZBrH.png",
      "instagramHandle" : "thesvengrd",
      "merchantHandle" : "thesvengrd",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Søvengård",
      "city" : "Grand Rapids",
      "companyName" : "The Søvengård",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.sovengard.com\/",
      "email" : ""
    }
  },
  "agaveuptown" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(510) 288-3668",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/463SCBx6R7rIHDwt8bcXyNDXPrm5VGYK.png",
      "instagramHandle" : "agaveuptown",
      "merchantHandle" : "agaveuptown",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Agave Uptown",
      "city" : "Oakland",
      "companyName" : "Agave Uptown",
      "websiteUrl" : "http:\/\/agaveuptown.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "quickietoo" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(253) 572-4549",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "quickietoo",
      "merchantHandle" : "quickietoo",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Quickie Too",
      "city" : "Tacoma",
      "companyName" : "Quickie Too",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/quickietoo.com\/",
      "email" : ""
    }
  },
  "losprimosmexicanbarandgrill" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 537-5004",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "losprimosmexicanbarandgrill",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "losprimosmexicanbarandgrill",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Los Primos Mexican Bar and Grill",
      "city" : "Lansing",
      "companyName" : "Los Primos Mexican Bar and Grill",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.losprimoslo.com\/",
      "email" : ""
    }
  },
  "casadeotay" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(619) 600-7268",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/seUwcQfl4EUQUnlTQmdCbimMSir2C7u2.png",
      "instagramHandle" : "casadeotay",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "casadeotay",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Casa De Otay",
      "city" : "Chula Vista",
      "companyName" : "Casa De Otay",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.casadeotay.com\/",
      "email" : ""
    }
  },
  "pjspizzacoffeeicecream" : {
    "info" : {
      "state" : "",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "pjspizzacoffeeicecream",
      "instagramHandle" : " Coffee & Ice Cream\"",
      "merchantHandle" : "pjspizzacoffeeicecream",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "(616) 698-1111",
      "merchantName" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/HaguA0vjc6qKMJe89RXRnCELujCxTJNz.png",
      "city" : "",
      "companyName" : "\"PJ's Pizza",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : "https:\/\/pjspizzashop.com\/"
    }
  },
  "misfitsocietycoffeeclub" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(734) 263-2228",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/LRrZpYWsyII7Pd8o5tJkuWUMIbEFN9LF.png",
      "instagramHandle" : "misfitsocietycoffeeclub",
      "merchantHandle" : "misfitsocietycoffeeclub",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Misfit Society Coffee Club",
      "city" : "Lansing",
      "companyName" : "Misfit Society Coffee Club",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.misfitsocietycoffee.com\/",
      "email" : ""
    }
  },
  "blackdogcoffeehouse" : {
    "info" : {
      "state" : "MT\r",
      "posSystem" : "Square",
      "phone" : "(406) 534-8822",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Q4SCaY3NhSr0iHExhqBEvbspsGMuaY2p.png",
      "instagramHandle" : "blackdogcoffeehouse",
      "twitterHandle" : "",
      "merchantHandle" : "blackdogcoffeehouse",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Black Dog Coffee House",
      "city" : "Billings",
      "companyName" : "Black Dog Coffee House",
      "websiteUrl" : "http:\/\/blackdogcoffeehouse.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "khitimellc" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/9iifwAO8VRB5Yh5CRxAodvjk6lQap9FH.png",
      "instagramHandle" : "khitimellc",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "khitimellc",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Kōhi Time LLC",
      "city" : "Eugene",
      "companyName" : "Kōhi Time LLC",
      "websiteUrl" : "http:\/\/www.kohitime.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "potbelly" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(586) 353-0323",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/a76a77378a50316aeae2792956f5994839f9b62d\/original.png",
      "instagramHandle" : "potbelly",
      "twitterHandle" : "",
      "merchantHandle" : "potbelly",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Potbelly",
      "city" : "Warren",
      "companyName" : "Potbelly",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.potbelly.com\/locations\/michigan\/12-mile-mound-drive-thru",
      "email" : ""
    }
  },
  "sanazscuisineatnuda" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "sanazscuisineatnuda",
      "twitterHandle" : "",
      "merchantHandle" : "sanazscuisineatnuda",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sanaz's Cuisine At Nuda",
      "city" : "Long Beach",
      "companyName" : "Sanaz's Cuisine At Nuda",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "thezendenhb" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 745-2549",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "thezendenhb",
      "twitterHandle" : "",
      "merchantHandle" : "thezendenhb",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Zen Den HB",
      "city" : "Long Beach",
      "companyName" : "The Zen Den HB",
      "websiteUrl" : "https:\/\/www.thezendenhb.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sysnewyorkpizza" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(541) 686-9598",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/OjE0yvps1OG1Wknw740DaQlpHPCbliju.png",
      "instagramHandle" : "sysnewyorkpizza",
      "merchantHandle" : "sysnewyorkpizza",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sy's New York Pizza",
      "city" : "Eugene",
      "companyName" : "Sy's New York Pizza",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.sysnewyorkpizza.com\/",
      "email" : ""
    }
  },
  "mamang" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(810) 510-0049",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/wSwfENE1A7Jy0yqljT9DRXjJa7TcRxsg.png",
      "instagramHandle" : "mamang",
      "premium" : false,
      "merchantHandle" : "mamang",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "MaMang",
      "city" : "Flint",
      "companyName" : "MaMang",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.facebook.com\/pg\/MaMangFlint\/about\/?ref=page_internal",
      "email" : ""
    }
  },
  "blmmeadcider" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(734) 548-9729",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/r5NfOuDkhAN0dowMp4oxQqNrNnLUH7lK.png",
      "instagramHandle" : "blmmeadcider",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "blmmeadcider",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Bløm Mead + Cider",
      "city" : "Ann Arbor",
      "companyName" : "Bløm Mead + Cider",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.drinkblom.com\/",
      "email" : ""
    }
  },
  "stereobrewing" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 993-3390",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Odpng0UVdWtCwqMmKhfnzIXCgWJpGyNO.png",
      "instagramHandle" : "stereobrewing",
      "merchantHandle" : "stereobrewing",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Stereo Brewing",
      "city" : "Anaheim",
      "companyName" : "Stereo Brewing",
      "websiteUrl" : "http:\/\/www.stereobrewing.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "taylorssmokehouseformerlydocsqinpitstop" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 567-2427",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/bb244ad49af816d0ac5d98ec84acbdfb091276a8\/original.jpeg",
      "instagramHandle" : "taylorssmokehouseformerlydocsqinpitstop",
      "twitterHandle" : "",
      "merchantHandle" : "taylorssmokehouseformerlydocsqinpitstop",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Taylor’s Smokehouse(Formerly Doc’s Q’in Pit Stop)",
      "city" : "Modesto",
      "companyName" : "Taylor’s Smokehouse(Formerly Doc’s Q’in Pit Stop)",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.facebook.com\/profile.php?id=61552017805871&mibextid=LQQJ4d",
      "email" : ""
    }
  },
  "thebrewcoffeeco" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(253) 302-3766",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ZJh8u7ZJagKPJg6DJ9CNNNXJ4LK6ShNC.png",
      "instagramHandle" : "thebrewcoffeeco",
      "merchantHandle" : "thebrewcoffeeco",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Brew Coffee Co",
      "city" : "Tacoma",
      "companyName" : "The Brew Coffee Co",
      "websiteUrl" : "http:\/\/www.thebrewcoffeeco.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "villagewineshop" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(313) 821-1177",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "villagewineshop",
      "merchantHandle" : "villagewineshop",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Village Wine Shop",
      "city" : "Detroit",
      "companyName" : "Village Wine Shop",
      "websiteUrl" : "http:\/\/www.thevillagewineshop.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "theburiedbottle" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(253) 888-6829",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/bmritueAOeBFguq8f8CO8mcoqM3XVbzv.png",
      "instagramHandle" : "theburiedbottle",
      "merchantHandle" : "theburiedbottle",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Buried Bottle",
      "city" : "Tacoma",
      "companyName" : "The Buried Bottle",
      "websiteUrl" : "https:\/\/buriedbottlewine.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "biga" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/lkRS0XhYg0TnY0ZQuuPcEOzYrbsCwxFd.png",
      "instagramHandle" : "biga",
      "merchantHandle" : "biga",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Biga",
      "city" : "Chula Vista",
      "companyName" : "Biga",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.bigasandiego.com\/",
      "email" : ""
    }
  },
  "venturaspiritscompany" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 232-4313",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/jBKf5ZZPz0WVSI1K3O1n6MhP4NkNVqj2.png",
      "instagramHandle" : "venturaspiritscompany",
      "twitterHandle" : "",
      "merchantHandle" : "venturaspiritscompany",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Ventura Spirits Company",
      "city" : "Oxnard",
      "companyName" : "Ventura Spirits Company",
      "websiteUrl" : "http:\/\/venturaspirits.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "rockosicecreamtacos" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(408) 320-1225",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ieZNYqYxkqnGyrbVGBypE3MT8Hgt7KfT.png",
      "instagramHandle" : "rockosicecreamtacos",
      "merchantHandle" : "rockosicecreamtacos",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Rocko's Ice Cream Tacos",
      "city" : "San Jose",
      "companyName" : "Rocko's Ice Cream Tacos",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.rockosicecreamtacos.com\/",
      "email" : ""
    }
  },
  "carosellopasta" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(734) 253-2315",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/67089cfdfddac708946a52668cf55cd30c561bea\/original.png",
      "instagramHandle" : "carosellopasta",
      "twitterHandle" : "",
      "merchantHandle" : "carosellopasta",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Carosello Pasta",
      "city" : "Ann Arbor",
      "companyName" : "Carosello Pasta",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.carosellopasta.com\/",
      "email" : ""
    }
  },
  "littlecakesbakery" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(248) 464-0475",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/388W3tnTwz44DFPtXDJkzjRoiBzUpIwQ.png",
      "instagramHandle" : "littlecakesbakery",
      "merchantHandle" : "littlecakesbakery",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Little Cakes Bakery",
      "city" : "Flint",
      "companyName" : "Little Cakes Bakery",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/littlecakesbakery.wixsite.com\/cupcakes",
      "email" : ""
    }
  },
  "originaltommyshamburgers" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 780-4201",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/a128fa6422729a74b994fe1f2c6735cbbd24a2cf\/original.jpeg",
      "instagramHandle" : "originaltommyshamburgers",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "originaltommyshamburgers",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Original Tommy's Hamburgers",
      "city" : "Riverside",
      "companyName" : "Original Tommy's Hamburgers",
      "websiteUrl" : "http:\/\/originaltommys.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "mealsdotkommealprep" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(562) 704-2867",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/7eef6a51084d1d367133c6f30c3ebb03a119fdce\/original.png",
      "instagramHandle" : "mealsdotkommealprep",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "mealsdotkommealprep",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "MealsDotKom Meal Prep",
      "city" : "Long Beach",
      "companyName" : "MealsDotKom Meal Prep",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/letseatmdk.com\/",
      "email" : ""
    }
  },
  "longroaddistillers" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 228-4924",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/qxk6GPGZQaHaqKaYdm31hCOnPuFycvit.png",
      "instagramHandle" : "longroaddistillers",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "longroaddistillers",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Long Road Distillers",
      "city" : "Grand Rapids",
      "companyName" : "Long Road Distillers",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.longroaddistillers.com\/",
      "email" : ""
    }
  },
  "ohmazingcuisine" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(408) 755-5375",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/686907ab9932f55b1333a9001418fca2aa14ab49\/original.jpeg",
      "instagramHandle" : "ohmazingcuisine",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "ohmazingcuisine",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Oh!-Mazing Cuisine",
      "city" : "San Jose",
      "companyName" : "Oh!-Mazing Cuisine",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/ohmazingcuisine.com\/contact-us",
      "email" : ""
    }
  },
  "electricherodowntowngrandrapids" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 454-1319",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/b3a4fae1994ab2983f92bf1b60ff947e4e7f9d20\/original.png",
      "instagramHandle" : "electricherodowntowngrandrapids",
      "premium" : false,
      "merchantHandle" : "electricherodowntowngrandrapids",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Electric Hero Downtown Grand Rapids",
      "city" : "Grand Rapids",
      "companyName" : "Electric Hero Downtown Grand Rapids",
      "websiteUrl" : "http:\/\/www.electricheroshop.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "zagshop" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(509) 313-6390",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/07a11a95a90d5cc72e0be562bec1a565a7c58ab4\/original.jpeg",
      "instagramHandle" : "zagshop",
      "merchantHandle" : "zagshop",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Zag Shop",
      "city" : "Spokane",
      "companyName" : "Zag Shop",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.bkstr.com\/gonzagazagshopstore\/home?cm_mmc=OrganicSearch-_-GoogleMyBusiness-_-Google-_-1296",
      "email" : ""
    }
  },
  "tippycowicecream" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/bH8SjLgkCLhtcgxMf392EBbRz03Z2clA.png",
      "instagramHandle" : "tippycowicecream",
      "twitterHandle" : "",
      "merchantHandle" : "tippycowicecream",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Tippy Cow Ice Cream",
      "city" : "Grand Rapids",
      "companyName" : "Tippy Cow Ice Cream",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/order.dripos.com\/Tippy-Cow-Ice-Cream",
      "email" : ""
    }
  },
  "holdaakfriedchicken" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(323) 507-2196",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/8KtyN6Y1zOZjxtFKyNEC3TmxRD6OiIsW.png",
      "instagramHandle" : "holdaakfriedchicken",
      "merchantHandle" : "holdaakfriedchicken",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "HOLDAAK Fried Chicken",
      "city" : "Glendale",
      "companyName" : "HOLDAAK Fried Chicken",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/holdaak.com\/",
      "email" : ""
    }
  },
  "curryon" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(734) 418-3175",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/72cd8b40c7c1488eea73280638a32bf52c235bb1\/original.jpeg",
      "instagramHandle" : "curryon",
      "merchantHandle" : "curryon",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Curry On",
      "city" : "Ann Arbor",
      "companyName" : "Curry On",
      "websiteUrl" : "https:\/\/curry-on.square.site\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "reborncoffeeirvineintersect" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/OS1jIE6uqJvBadVL6xZRx5vFLfWVaFcS.png",
      "instagramHandle" : "reborncoffeeirvineintersect",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "reborncoffeeirvineintersect",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Reborn Coffee - Irvine Intersect",
      "city" : "Irvine",
      "companyName" : "Reborn Coffee - Irvine Intersect",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/reborncoffee.com\/",
      "email" : ""
    }
  },
  "fivestarcakeco" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(586) 218-7871",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/X4ehaeaSTIxWKOlTtIqb0aLVd0ybd2Rg.png",
      "instagramHandle" : "fivestarcakeco",
      "premium" : false,
      "merchantHandle" : "fivestarcakeco",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Five Star Cake Co.",
      "city" : "Warren",
      "companyName" : "Five Star Cake Co.",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.fivestarcakeco.com\/",
      "email" : ""
    }
  },
  "driftercoffee" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(248) 629-4400",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/DRuV30z70u4IGxiaO9lN0oJrDjEIgrva.png",
      "instagramHandle" : "driftercoffee",
      "twitterHandle" : "",
      "merchantHandle" : "driftercoffee",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Drifter Coffee",
      "city" : "Warren",
      "companyName" : "Drifter Coffee",
      "websiteUrl" : "http:\/\/driftercoffee.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "ttsoldironbreweryandbbq" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(509) 919-4798",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/56ZbNcnLNPDZriR1Md2og9fWu75dF6gp.png",
      "instagramHandle" : "ttsoldironbreweryandbbq",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "ttsoldironbreweryandbbq",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "TT’s Old Iron Brewery and Bbq",
      "city" : "Spokane",
      "companyName" : "TT’s Old Iron Brewery and Bbq",
      "websiteUrl" : "http:\/\/ttsbrewerybbq.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "morenovalleylittleleague" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 319-3510",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/FNafnoB2e2yK7sTeCB6hT32EmmIaAfNM.png",
      "instagramHandle" : "morenovalleylittleleague",
      "merchantHandle" : "morenovalleylittleleague",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Moreno Valley Little League",
      "city" : "Moreno Valley",
      "companyName" : "Moreno Valley Little League",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "leisurepublichouse" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(503) 289-7606",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/630e008a77c27ffc5eb2f50a6f5f5348\/original.gif",
      "instagramHandle" : "leisurepublichouse",
      "twitterHandle" : "",
      "merchantHandle" : "leisurepublichouse",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Leisure Public House",
      "city" : "Vancouver",
      "companyName" : "Leisure Public House",
      "websiteUrl" : "https:\/\/www.leisurepublichouse.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "garlish" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(916) 512-7445",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/zKghudn5h40kp8akNNBQQvGrozGyey8W.png",
      "instagramHandle" : "garlish",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "garlish",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Garlish",
      "city" : "Fresno",
      "companyName" : "Garlish",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.garlish.com\/",
      "email" : ""
    }
  },
  "oldtownbakingcompany" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 945-0400",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/RoBFBlLfllNVzEjy7XdCEJ5JtpLOrFAN.png",
      "instagramHandle" : "oldtownbakingcompany",
      "merchantHandle" : "oldtownbakingcompany",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Old Town Baking Company",
      "city" : "San Bernardino",
      "companyName" : "Old Town Baking Company",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.oldtownbakingco.com\/",
      "email" : ""
    }
  },
  "thebeerco" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 914-6515",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/zxrKeWEDczN50BuISKOuMBZa601xeyPu.png",
      "instagramHandle" : "thebeerco",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "thebeerco",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Beer Co.",
      "city" : "Anaheim",
      "companyName" : "The Beer Co.",
      "websiteUrl" : "http:\/\/www.beercobrewery.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "detroitsaltco" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(313) 841-5144",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/7f290c39824102584b70d35920acaaee264f51b5\/original.png",
      "instagramHandle" : "detroitsaltco",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "detroitsaltco",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Detroit Salt Co",
      "city" : "Detroit",
      "companyName" : "Detroit Salt Co",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/detroitsalt.com\/",
      "email" : ""
    }
  },
  "twincalvesranch" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(509) 263-6381",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/9gq5meBdACeq5UX0zYmmar8aeYrLE6My.png",
      "instagramHandle" : "twincalvesranch",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "twincalvesranch",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Twin Calves Ranch",
      "city" : "Spokane",
      "companyName" : "Twin Calves Ranch",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/twincalvesranch.com\/",
      "email" : ""
    }
  },
  "bluemoonyogaandjuicebar" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(559) 325-2583",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/950ecbb750019f5ba7630b29b9302e7eee7f52b1\/original.png",
      "instagramHandle" : "bluemoonyogaandjuicebar",
      "twitterHandle" : "",
      "merchantHandle" : "bluemoonyogaandjuicebar",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Blue Moon Yoga and Juice Bar",
      "city" : "Fresno",
      "companyName" : "Blue Moon Yoga and Juice Bar",
      "websiteUrl" : "https:\/\/bluemoonyogastudios.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "mochilicious" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(559) 515-6788",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/2d3d28706c6b5ecd00c924f6473d42990e413836\/original.png",
      "instagramHandle" : "mochilicious",
      "merchantHandle" : "mochilicious",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Mochilicious",
      "city" : "Fresno",
      "companyName" : "Mochilicious",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.mochilicioususa.com\/",
      "email" : ""
    }
  },
  "thovomariscosbirria" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(619) 636-3797",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/WeZNDfj0EXpLsTyLMtEzBJRP9d8x9p3k.png",
      "instagramHandle" : "thovomariscosbirria",
      "twitterHandle" : "",
      "merchantHandle" : "thovomariscosbirria",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Thovo Mariscos & Birria",
      "city" : "Chula Vista",
      "companyName" : "Thovo Mariscos & Birria",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "mobicainccalifornia" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(408) 273-4557",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/QDISx2uJvFFJ6jKxnWmawyACOkzLTZne.png",
      "instagramHandle" : "mobicainccalifornia",
      "merchantHandle" : "mobicainccalifornia",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Mobica Inc. California",
      "city" : "San Jose",
      "companyName" : "Mobica Inc. California",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/mobica.com\/",
      "email" : ""
    }
  },
  "edelbraubrewingcompany" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(734) 926-5351",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Jdw4WGMoqM0ZMdSebaNSSfcWO5tGca1z.png",
      "instagramHandle" : "edelbraubrewingcompany",
      "premium" : false,
      "merchantHandle" : "edelbraubrewingcompany",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Edelbrau Brewing Company",
      "city" : "Ann Arbor",
      "companyName" : "Edelbrau Brewing Company",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.edelbraubrewingcompany.com\/",
      "email" : ""
    }
  },
  "coffeeconnection" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(805) 646-7821",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/b1bfec4a968159f72615c176c2fcb795\/original.jpeg",
      "instagramHandle" : "coffeeconnection",
      "premium" : false,
      "merchantHandle" : "coffeeconnection",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Coffee Connection",
      "city" : "Oxnard",
      "companyName" : "Coffee Connection",
      "websiteUrl" : "http:\/\/www.coffeeconnectionojai.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "heimat" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(519) 915-9821",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/d1d81cabd44fdbb48426fb942a7f69c511f54bc2\/original.png",
      "instagramHandle" : "heimat",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "heimat",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Heimat",
      "city" : "Detroit",
      "companyName" : "Heimat",
      "websiteUrl" : "http:\/\/www.heimat.ca\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "jyncaf" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 599-8596",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/e14095f5d57b5b306d12e65ce8a5cdaa5497f098\/original.png",
      "instagramHandle" : "jyncaf",
      "merchantHandle" : "jyncaf",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Jyn Café",
      "city" : "Santa Ana",
      "companyName" : "Jyn Café",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "oxfordsuitesspokane" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(509) 353-9000",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Pf4A9W0vvWnfBkDfBVHOd0DZUqSPad2G.png",
      "instagramHandle" : "oxfordsuitesspokane",
      "twitterHandle" : "",
      "merchantHandle" : "oxfordsuitesspokane",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Oxford Suites Spokane",
      "city" : "Spokane",
      "companyName" : "Oxford Suites Spokane",
      "websiteUrl" : "https:\/\/www.oxfordsuitesspokane.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "marilynsdetroit" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(248) 677-1482",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/FXbs9hkiRooE0G2npGyOaeV270kPWpPG.png",
      "instagramHandle" : "marilynsdetroit",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "marilynsdetroit",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Marilyn's Detroit",
      "city" : "Sterling Heights",
      "companyName" : "Marilyn's Detroit",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/marilynsdetroit.com\/",
      "email" : ""
    }
  },
  "bradburybakehouse" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 604-4884",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/kanEDS25o8ixWsp7FX055wZUSWct0iBF.png",
      "instagramHandle" : "bradburybakehouse",
      "merchantHandle" : "bradburybakehouse",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Bradbury Bakehouse",
      "city" : "Modesto",
      "companyName" : "Bradbury Bakehouse",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/bradburybakehouse.com\/",
      "email" : ""
    }
  },
  "associationofarizmendicoop" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 834-4221",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "associationofarizmendicoop",
      "twitterHandle" : "",
      "merchantHandle" : "associationofarizmendicoop",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Association of Arizmendi Co-Op",
      "city" : "Oakland",
      "companyName" : "Association of Arizmendi Co-Op",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "mannys" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 896-4052",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/AAJSS6mH8a0MjB1YFbZv1OjzdUlckSa4.png",
      "instagramHandle" : "mannys",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "mannys",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Manny’s",
      "city" : "San Francisco",
      "companyName" : "Manny’s",
      "websiteUrl" : "http:\/\/welcometomannys.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "suazostacos" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 595-9588",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/9eZOIiGt9FyPTJyiyGmFdYxOf0xGdFTL.png",
      "instagramHandle" : "suazostacos",
      "merchantHandle" : "suazostacos",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Suazos Tacos",
      "city" : "Anaheim",
      "companyName" : "Suazos Tacos",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/suazostacosoc.com\/",
      "email" : ""
    }
  },
  "nanassoulfoodplacellc" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(313) 772-0214",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "nanassoulfoodplacellc",
      "twitterHandle" : "",
      "merchantHandle" : "nanassoulfoodplacellc",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Nana's Soul Food Place LLC",
      "city" : "Warren",
      "companyName" : "Nana's Soul Food Place LLC",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "elpatoverde" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(541) 686-9700",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/c9e84554795c2ffe92f35cf8b030444f2cdeef82\/original.png",
      "instagramHandle" : "elpatoverde",
      "premium" : false,
      "merchantHandle" : "elpatoverde",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "El Pato Verde",
      "city" : "Eugene",
      "companyName" : "El Pato Verde",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "constellationcatcafe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(517) 918-4919",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/qODdyd8EgbwwdmjWck6FRCoCZqYlTLoX.png",
      "instagramHandle" : "constellationcatcafe",
      "merchantHandle" : "constellationcatcafe",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Constellation Cat Cafe",
      "city" : "Lansing",
      "companyName" : "Constellation Cat Cafe",
      "websiteUrl" : "http:\/\/constellationcatcafe.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "almondandoak" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(510) 250-9550",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/hXa1yynbCjehCnlFQmm2plZQaJ7E92TH.png",
      "instagramHandle" : "almondandoak",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "almondandoak",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Almond and Oak",
      "city" : "Oakland",
      "companyName" : "Almond and Oak",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.almondandoak.com\/",
      "email" : ""
    }
  },
  "nuchaempanadas" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 516-6326",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/zaqnPdvUpCv5p1klvUPi84n0i8CPthfw.png",
      "instagramHandle" : "nuchaempanadas",
      "merchantHandle" : "nuchaempanadas",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Nucha Empanadas",
      "city" : "San Francisco",
      "companyName" : "Nucha Empanadas",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/nucha-empanadas.com\/locations.html",
      "email" : ""
    }
  },
  "northsidecafebycaliente" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 594-3576",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/18aa279bb2740b393b98067b1378f5133809ee23\/original.png",
      "instagramHandle" : "northsidecafebycaliente",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "northsidecafebycaliente",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Northside Cafe by Caliente",
      "city" : "Huntington Beach",
      "companyName" : "Northside Cafe by Caliente",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/northside-cafe.com\/",
      "email" : ""
    }
  },
  "millvillepizzaco" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(253) 514-8626",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/RrEH4IRePKQ2bgGaPN9yg9zYdj4T8BFv.png",
      "instagramHandle" : "millvillepizzaco",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "millvillepizzaco",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Millville Pizza Co.",
      "city" : "Tacoma",
      "companyName" : "Millville Pizza Co.",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.millvillepizzaco.com\/contact-us.html",
      "email" : ""
    }
  },
  "madfirekitchenandcatering" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(509) 655-2358",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "madfirekitchenandcatering",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "madfirekitchenandcatering",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Madfire Kitchen and Catering",
      "city" : "Spokane",
      "companyName" : "Madfire Kitchen and Catering",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/madfirekitchen.com\/",
      "email" : ""
    }
  },
  "topbassfishingcom" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 518-9309",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/JLf1eSHj5jE4VYiOwarUOC0dFq6AWQ1Y.png",
      "instagramHandle" : "topbassfishingcom",
      "twitterHandle" : "",
      "merchantHandle" : "topbassfishingcom",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "topbassfishing.com",
      "city" : "Stockton",
      "companyName" : "topbassfishing.com",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/topbassfishing.com\/",
      "email" : ""
    }
  },
  "chiquitopicosokitchen" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 455-4043",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/G38lsOL0f4EbdRQOLR2Shses8ULMdBsi.png",
      "instagramHandle" : "chiquitopicosokitchen",
      "twitterHandle" : "",
      "merchantHandle" : "chiquitopicosokitchen",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Chiquito picoso kitchen",
      "city" : "Moreno Valley",
      "companyName" : "Chiquito picoso kitchen",
      "websiteUrl" : "http:\/\/www.chiquitopicosokitchen.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "zilkevegetablefarm" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(734) 508-7033",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/kPlLHBMY02bjmHaC8La8qBgmRXheYDaC.png",
      "instagramHandle" : "zilkevegetablefarm",
      "merchantHandle" : "zilkevegetablefarm",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Zilke Vegetable Farm",
      "city" : "Ann Arbor",
      "companyName" : "Zilke Vegetable Farm",
      "websiteUrl" : "http:\/\/zilkevegetablefarm.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "gestaltcraftbeerbar" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/4krREPMMeDRRVma53ubkmhRqHqpnrLVk.png",
      "instagramHandle" : "gestaltcraftbeerbar",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "gestaltcraftbeerbar",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Gestalt Craft Beer Bar",
      "city" : "San Francisco",
      "companyName" : "Gestalt Craft Beer Bar",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "angryferretbrewing" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 209-5963",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/effd842e8e8bb461af6fb4a014428ba21b7b9e7c\/original.png",
      "instagramHandle" : "angryferretbrewing",
      "twitterHandle" : "",
      "merchantHandle" : "angryferretbrewing",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Angry Ferret Brewing",
      "city" : "Oxnard",
      "companyName" : "Angry Ferret Brewing",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.angryferretbrewing.com\/",
      "email" : ""
    }
  },
  "rockcafe" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/d86822a588501e45ce12490c20f102f2890c2ece\/original.png",
      "instagramHandle" : "rockcafe",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "rockcafe",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Rock cafe",
      "city" : "San Bernardino",
      "companyName" : "Rock cafe",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "ladyfalconcoffeeclub" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 347-7507",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ZJ2xyzcsWPgL0M8avf2r0LwOX1exx113.png",
      "instagramHandle" : "ladyfalconcoffeeclub",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "ladyfalconcoffeeclub",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Lady Falcon Coffee Club",
      "city" : "San Francisco",
      "companyName" : "Lady Falcon Coffee Club",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.ladyfalconcoffeeclub.com\/",
      "email" : ""
    }
  },
  "maltesecafeh" : {
    "analytics" : {
      "records" : {
        "landing-page" : {
          "eUQZzUbf" : {
            "pathname" : "\/maltesecafeh",
            "time" : "2024-06-27T05:38:35.021Z",
            "merchantHandle" : "maltesecafeh",
            "host" : "cafe.bio",
            "timezone" : "America\/Los_Angeles",
            "userAgent" : "Mozilla\/5.0 (iPad; CPU OS 17_5 like Mac OS X) AppleWebKit\/605.1.15 (KHTML, like Gecko) CriOS\/126.0.6478.54 Mobile\/15E148 Safari\/604.1",
            "page" : "landing-page\/"
          }
        }
      }
    }
  },
  "monicasgourmetcookies" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(616) 977-7200",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/NZdnU3SaXmDGVXOGYQ7Ya14QHIlUZly3.png",
      "instagramHandle" : "monicasgourmetcookies",
      "twitterHandle" : "",
      "merchantHandle" : "monicasgourmetcookies",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Monica's Gourmet Cookies",
      "city" : "Grand Rapids",
      "companyName" : "Monica's Gourmet Cookies",
      "websiteUrl" : "http:\/\/www.monicasgourmet.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "lushcottoncandy" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(509) 606-5874",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/QI14KYNxLMyUk7WWlqczY5aXvdZkG4Eq.png",
      "instagramHandle" : "lushcottoncandy",
      "twitterHandle" : "",
      "merchantHandle" : "lushcottoncandy",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Lush Cotton Candy",
      "city" : "Spokane",
      "companyName" : "Lush Cotton Candy",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.lushcottoncandy.com\/",
      "email" : ""
    }
  },
  "winecanvasstudio" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 970-1082",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Ms5TvLc7VhFzPza6ePce0ZIJuu7xawWU.png",
      "instagramHandle" : "winecanvasstudio",
      "merchantHandle" : "winecanvasstudio",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Wine & Canvas Studio",
      "city" : "Grand Rapids",
      "companyName" : "Wine & Canvas Studio",
      "websiteUrl" : "https:\/\/wineandcanvas.com\/grand-rapids",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thecookandherfarmer" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 285-6140",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/i30nHhVIV2aisq6aB5Wr4hp3nfqZfX2G.png",
      "instagramHandle" : "thecookandherfarmer",
      "merchantHandle" : "thecookandherfarmer",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Cook and Her Farmer",
      "city" : "Oakland",
      "companyName" : "The Cook and Her Farmer",
      "websiteUrl" : "http:\/\/www.thecookandherfarmer.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sesamedinette" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(562) 337-8064",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "sesamedinette",
      "premium" : false,
      "merchantHandle" : "sesamedinette",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Sesame Dinette",
      "city" : "Long Beach",
      "companyName" : "Sesame Dinette",
      "websiteUrl" : "http:\/\/www.sesamedinette.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sugarsaltbakery" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(360) 977-3492",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "sugarsaltbakery",
      "merchantHandle" : "sugarsaltbakery",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Sugar & Salt Bakery",
      "city" : "Vancouver",
      "companyName" : "Sugar & Salt Bakery",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "cafeluna" : {
    "analytics" : {
      "promos-page" : {
        "records" : {
          "erEHpMsZ" : {
            "pathname" : "\/cafeluna",
            "time" : "2024-03-25T23:25:30.164Z",
            "merchantHandle" : "cafeluna",
            "host" : "cafe.bio",
            "timezone" : "America\/Los_Angeles",
            "userAgent" : "Mozilla\/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "promos-page"
          },
          "qCQHC87A" : {
            "pathname" : "\/cafeluna",
            "time" : "2024-03-23T14:59:47.125Z",
            "merchantHandle" : "cafeluna",
            "host" : "cafe.bio",
            "timezone" : "America\/Los_Angeles",
            "userAgent" : "Mozilla\/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "promos-page"
          }
        }
      },
      "records" : {
        "landing-page" : {
          "sJBuJyad" : {
            "pathname" : "\/cafeluna",
            "time" : "2024-04-26T19:51:39.984Z",
            "merchantHandle" : "cafeluna",
            "host" : "cafe.bio",
            "timezone" : "America\/Los_Angeles",
            "userAgent" : "Mozilla\/5.0 (iPhone; CPU iPhone OS 17_4 like Mac OS X) AppleWebKit\/605.1.15 (KHTML, like Gecko) CriOS\/124.0.6367.88 Mobile\/15E148 Safari\/604.1",
            "page" : "landing-page\/"
          },
          "5QHX9HLi" : {
            "pathname" : "\/cafeluna",
            "time" : "2024-04-03T05:16:48.726Z",
            "merchantHandle" : "cafeluna",
            "host" : "cafe.bio",
            "timezone" : "America\/Los_Angeles",
            "userAgent" : "Mozilla\/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/116.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "eyUHKDRP" : {
            "pathname" : "\/cafeluna",
            "time" : "2024-04-03T05:17:02.725Z",
            "merchantHandle" : "cafeluna",
            "host" : "cafe.bio",
            "timezone" : "America\/Los_Angeles",
            "userAgent" : "Mozilla\/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/116.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "kmPfgyXX" : {
            "pathname" : "\/cafeluna",
            "time" : "2024-04-18T01:25:04.348Z",
            "merchantHandle" : "cafeluna",
            "host" : "cafe.bio",
            "timezone" : "America\/Los_Angeles",
            "userAgent" : "Mozilla\/5.0 (iPad; CPU OS 17_3 like Mac OS X) AppleWebKit\/605.1.15 (KHTML, like Gecko) CriOS\/123.0.6312.52 Mobile\/15E148 Safari\/604.1",
            "page" : "landing-page\/"
          },
          "sVJ4Q24m" : {
            "pathname" : "\/cafeluna",
            "time" : "2024-04-26T19:51:32.951Z",
            "merchantHandle" : "cafeluna",
            "host" : "cafe.bio",
            "timezone" : "America\/Los_Angeles",
            "userAgent" : "Mozilla\/5.0 (iPhone; CPU iPhone OS 17_4 like Mac OS X) AppleWebKit\/605.1.15 (KHTML, like Gecko) CriOS\/124.0.6367.88 Mobile\/15E148 Safari\/604.1",
            "page" : "landing-page\/"
          },
          "f2oi486o" : {
            "pathname" : "\/cafeluna",
            "time" : "2024-04-27T03:18:41.221Z",
            "merchantHandle" : "cafeluna",
            "host" : "cafe.bio",
            "timezone" : "America\/Los_Angeles",
            "userAgent" : "Mozilla\/5.0 (iPhone; CPU iPhone OS 17_4 like Mac OS X) AppleWebKit\/605.1.15 (KHTML, like Gecko) CriOS\/124.0.6367.88 Mobile\/15E148 Safari\/604.1",
            "page" : "landing-page\/"
          },
          "2GPWwRKX" : {
            "pathname" : "\/cafeluna",
            "time" : "2024-06-17T15:54:25.886Z",
            "merchantHandle" : "cafeluna",
            "host" : "cafe.bio",
            "timezone" : "America\/Los_Angeles",
            "userAgent" : "Mozilla\/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/126.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "m1pvLoLZ" : {
            "pathname" : "\/cafeluna",
            "time" : "2024-05-31T14:23:36.902Z",
            "merchantHandle" : "cafeluna",
            "host" : "cafe.bio",
            "timezone" : "America\/Los_Angeles",
            "userAgent" : "Mozilla\/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit\/605.1.15 (KHTML, like Gecko) Mobile\/15E148 [LinkedInApp]\/9.29.7227",
            "page" : "landing-page\/"
          },
          "nYXz2uze" : {
            "pathname" : "\/cafeluna",
            "time" : "2024-04-03T05:16:58.294Z",
            "merchantHandle" : "cafeluna",
            "host" : "cafe.bio",
            "timezone" : "America\/Los_Angeles",
            "userAgent" : "Mozilla\/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/116.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          }
        },
        "promo-loaded" : {
          "rg1M91ii" : {
            "pathname" : "\/cafeluna",
            "time" : "2024-05-31T14:23:37.576Z",
            "merchantHandle" : "cafeluna",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "stylename" : "img_009",
                "textcolor" : "E2A64E",
                "backgroundcolor" : "00191D",
                "primarycolor" : "e57a21",
                "secondarycolor" : "e2a64e",
                "additionalcolor" : "E57A21"
              },
              "status" : "ACTIVE",
              "dateend" : "02-20-2024",
              "date_created" : "12\/15\/2023",
              "promomessage" : "Get 50% off frappe drinks from 4-6pm every weekday.",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "12-14-2023",
              "merchantname" : "cafeluna",
              "promoimageheader" : "",
              "promoname" : "cafeluna-2pZWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Beat the heat with our frappes!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "America\/Los_Angeles",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit\/605.1.15 (KHTML, like Gecko) Mobile\/15E148 [LinkedInApp]\/9.29.7227"
          },
          "czdm7ixh" : {
            "pathname" : "\/cafeluna",
            "time" : "2024-04-18T01:25:05.436Z",
            "merchantHandle" : "cafeluna",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "stylename" : "img_009",
                "textcolor" : "E2A64E",
                "backgroundcolor" : "00191D",
                "primarycolor" : "e57a21",
                "secondarycolor" : "e2a64e",
                "additionalcolor" : "E57A21"
              },
              "status" : "ACTIVE",
              "dateend" : "02-20-2024",
              "date_created" : "12\/15\/2023",
              "promomessage" : "Get 50% off frappe drinks from 4-6pm every weekday.",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "12-14-2023",
              "merchantname" : "cafeluna",
              "promoimageheader" : "",
              "promoname" : "cafeluna-2pZWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Beat the heat with our frappes!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "America\/Los_Angeles",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (iPad; CPU OS 17_3 like Mac OS X) AppleWebKit\/605.1.15 (KHTML, like Gecko) CriOS\/123.0.6312.52 Mobile\/15E148 Safari\/604.1"
          },
          "3yqUnjiu" : {
            "pathname" : "\/cafeluna",
            "time" : "2024-04-27T03:18:42.400Z",
            "merchantHandle" : "cafeluna",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "stylename" : "img_009",
                "textcolor" : "E2A64E",
                "backgroundcolor" : "00191D",
                "primarycolor" : "e57a21",
                "secondarycolor" : "e2a64e",
                "additionalcolor" : "E57A21"
              },
              "status" : "ACTIVE",
              "dateend" : "02-20-2024",
              "date_created" : "12\/15\/2023",
              "promomessage" : "Get 50% off frappe drinks from 4-6pm every weekday.",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "12-14-2023",
              "merchantname" : "cafeluna",
              "promoimageheader" : "",
              "promoname" : "cafeluna-2pZWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Beat the heat with our frappes!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "America\/Los_Angeles",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (iPhone; CPU iPhone OS 17_4 like Mac OS X) AppleWebKit\/605.1.15 (KHTML, like Gecko) CriOS\/124.0.6367.88 Mobile\/15E148 Safari\/604.1"
          },
          "oiwsZWWw" : {
            "pathname" : "\/cafeluna",
            "time" : "2024-04-26T19:51:40.360Z",
            "merchantHandle" : "cafeluna",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "stylename" : "img_009",
                "textcolor" : "E2A64E",
                "backgroundcolor" : "00191D",
                "primarycolor" : "e57a21",
                "secondarycolor" : "e2a64e",
                "additionalcolor" : "E57A21"
              },
              "status" : "ACTIVE",
              "dateend" : "02-20-2024",
              "date_created" : "12\/15\/2023",
              "promomessage" : "Get 50% off frappe drinks from 4-6pm every weekday.",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "12-14-2023",
              "merchantname" : "cafeluna",
              "promoimageheader" : "",
              "promoname" : "cafeluna-2pZWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Beat the heat with our frappes!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "America\/Los_Angeles",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (iPhone; CPU iPhone OS 17_4 like Mac OS X) AppleWebKit\/605.1.15 (KHTML, like Gecko) CriOS\/124.0.6367.88 Mobile\/15E148 Safari\/604.1"
          },
          "pLkwAnK3" : {
            "pathname" : "\/cafeluna",
            "time" : "2024-04-26T19:51:33.738Z",
            "merchantHandle" : "cafeluna",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "stylename" : "img_009",
                "textcolor" : "E2A64E",
                "backgroundcolor" : "00191D",
                "primarycolor" : "e57a21",
                "secondarycolor" : "e2a64e",
                "additionalcolor" : "E57A21"
              },
              "status" : "ACTIVE",
              "dateend" : "02-20-2024",
              "date_created" : "12\/15\/2023",
              "promomessage" : "Get 50% off frappe drinks from 4-6pm every weekday.",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "12-14-2023",
              "merchantname" : "cafeluna",
              "promoimageheader" : "",
              "promoname" : "cafeluna-2pZWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Beat the heat with our frappes!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "America\/Los_Angeles",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (iPhone; CPU iPhone OS 17_4 like Mac OS X) AppleWebKit\/605.1.15 (KHTML, like Gecko) CriOS\/124.0.6367.88 Mobile\/15E148 Safari\/604.1"
          }
        }
      }
    },
    "redemptions" : {
      "cafeluna_gudzH" : {
        "redeemguid" : "gudzH",
        "redeemTimestamp" : 1681103013954,
        "redeemdate" : "4\/9\/2023",
        "source" : "IG",
        "promoname" : "CafeLuna-s1a7V8Bfk8RRoqstBsXx3W",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_vV8uL" : {
        "redeemguid" : "vV8uL",
        "redeemTimestamp" : 1681103919451,
        "redeemdate" : "4\/9\/2023",
        "source" : "IG",
        "promoname" : "CafeLuna-s1a7V8Bfk8RRoqstBsXx3W",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_fKMSZ" : {
        "redeemguid" : "fKMSZ",
        "redeemTimestamp" : 1681917435039,
        "redeemdate" : "4\/19\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-6uhtv",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_eniSK" : {
        "redeemguid" : "eniSK",
        "redeemTimestamp" : 1681098470842,
        "redeemdate" : "4\/9\/2023",
        "source" : "IG",
        "promoname" : "4159997117_eniSK",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_dRjkP" : {
        "redeemguid" : "dRjkP",
        "redeemTimestamp" : 1681171009614,
        "redeemdate" : "4\/10\/2023",
        "source" : "IG",
        "promoname" : "CafeLuna-s1a7V8Bfk8RRoqstBsXx3W",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_iNiBJ" : {
        "redeemguid" : "iNiBJ",
        "redeemTimestamp" : 1704081266777,
        "redeemdate" : "12\/31\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-2pZWt",
        "redemptionTimestamp" : 1704081266777,
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_tYkor" : {
        "redeemguid" : "tYkor",
        "source" : "IG",
        "redeemdate" : "4\/9\/2023",
        "visitdate" : "",
        "promoname" : "4159997117_tYkor",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_5CPnP" : {
        "redeemguid" : "5CPnP",
        "redeemTimestamp" : 1703911321137,
        "redeemdate" : "12\/29\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-4fbuE",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_vEgeU" : {
        "redeemguid" : "vEgeU",
        "redeemTimestamp" : 1681104233879,
        "redeemdate" : "4\/9\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-ov8AU",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_4TVCD" : {
        "redeemguid" : "4TVCD",
        "redeemTimestamp" : 1704081446169,
        "redeemdate" : "12\/31\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-2pZWt",
        "redemptionTimestamp" : 1704081446169,
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_g7qxJ" : {
        "redeemguid" : "g7qxJ",
        "redeemTimestamp" : 1681878288489,
        "redeemdate" : "4\/19\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-6uhtv",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "1231231",
        "visittime" : ""
      },
      "cafeluna_rN48R" : {
        "redeemguid" : "rN48R",
        "source" : "IG",
        "redeemdate" : "4\/9\/2023",
        "visitdate" : "",
        "promoname" : "_rN48R",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "",
        "visittime" : ""
      },
      "cafeluna_thN6P" : {
        "redeemguid" : "thN6P",
        "source" : "IG",
        "redeemdate" : "4\/9\/2023",
        "visitdate" : "",
        "promoname" : "4159997117_thN6P",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna-2pZWt" : {
        "1-4-2024" : {
          "cafeluna_qWmYG" : {
            "redeemguid" : "qWmYG",
            "redeemTimestamp" : 1704427401465,
            "redeemdate" : "1-4-2024",
            "source" : "IG",
            "promoname" : "cafeluna-2pZWt",
            "visitdate" : "",
            "merchanthandle" : "cafeluna",
            "phonenumber" : "4159997117",
            "visittime" : ""
          },
          "cafeluna_sDL8J" : {
            "redeemguid" : "sDL8J",
            "redeemTimestamp" : 1704427977554,
            "redeemdate" : "1-4-2024",
            "source" : "IG",
            "promoname" : "cafeluna-2pZWt",
            "visitdate" : "",
            "merchanthandle" : "cafeluna",
            "phonenumber" : "4159997119",
            "visittime" : ""
          },
          "cafeluna_c3j8e" : {
            "redeemguid" : "c3j8e",
            "redeemTimestamp" : 1704433520432,
            "redeemdate" : "1-4-2024",
            "source" : "IG",
            "promoname" : "cafeluna-2pZWt",
            "visitdate" : "",
            "merchanthandle" : "cafeluna",
            "phonenumber" : "(415) 999-7117",
            "visittime" : ""
          }
        },
        "2-10-2024" : {
          "cafeluna_jzkv6" : {
            "redeemguid" : "jzkv6",
            "redeemTimestamp" : 1707571410138,
            "redeemdate" : "2-10-2024",
            "source" : "IG",
            "promoname" : "cafeluna-2pZWt",
            "visitdate" : "",
            "merchanthandle" : "cafeluna",
            "phonenumber" : "7783831212",
            "visittime" : ""
          }
        },
        "1-22-2024" : {
          "cafeluna_rqW8o" : {
            "redeemguid" : "rqW8o",
            "redeemTimestamp" : 1705979019974,
            "redeemdate" : "1-22-2024",
            "source" : "IG",
            "promoname" : "cafeluna-2pZWt",
            "visitdate" : "",
            "merchanthandle" : "cafeluna",
            "phonenumber" : "4159997117",
            "visittime" : ""
          }
        },
        "cafeluna_xB9Fs" : {
          "redeemguid" : "xB9Fs",
          "redeemTimestamp" : 1704081726496,
          "redeemdate" : "12\/31\/2023",
          "source" : "IG",
          "promoname" : "cafeluna-2pZWt",
          "redemptionTimestamp" : 1704081726496,
          "visitdate" : "",
          "merchanthandle" : "cafeluna",
          "merchantname" : "Cafe Luna",
          "phonenumber" : "4159997117",
          "visittime" : ""
        },
        "1-1-2024" : {
          "cafeluna_ucrw7" : {
            "redeemguid" : "ucrw7",
            "redeemTimestamp" : 1704125422657,
            "redeemdate" : "1-1-2024",
            "source" : "IG",
            "promoname" : "cafeluna-2pZWt",
            "visitdate" : "",
            "merchanthandle" : "cafeluna",
            "merchantname" : "Cafe Luna",
            "phonenumber" : "4159997117",
            "visittime" : ""
          }
        },
        "12-31-2023" : {
          "cafeluna_275Bk" : {
            "redeemguid" : "275Bk",
            "redeemTimestamp" : 1704082349550,
            "redeemdate" : "12-31-2023",
            "source" : "IG",
            "promoname" : "cafeluna-2pZWt",
            "visitdate" : "",
            "merchanthandle" : "cafeluna",
            "merchantname" : "Cafe Luna",
            "phonenumber" : "4159997117",
            "visittime" : ""
          },
          "cafeluna_nJM5X" : {
            "redeemguid" : "nJM5X",
            "redeemTimestamp" : 1704082578355,
            "redeemdate" : "12-31-2023",
            "source" : "IG",
            "promoname" : "cafeluna-2pZWt",
            "visitdate" : "",
            "merchanthandle" : "cafeluna",
            "merchantname" : "Cafe Luna",
            "phonenumber" : "4159997117",
            "visittime" : ""
          }
        },
        "2-12-2024" : {
          "cafeluna_geB2C" : {
            "redeemguid" : "geB2C",
            "redeemTimestamp" : 1707743723116,
            "redeemdate" : "2-12-2024",
            "source" : "IG",
            "promoname" : "cafeluna-2pZWt",
            "visitdate" : "",
            "merchanthandle" : "cafeluna",
            "phonenumber" : "7783831212",
            "visittime" : ""
          }
        },
        "2-16-2024" : {
          "cafeluna_3JPyP" : {
            "redeemguid" : "3JPyP",
            "redeemTimestamp" : 1708090533195,
            "redeemdate" : "2-16-2024",
            "source" : "IG",
            "promoname" : "cafeluna-2pZWt",
            "visitdate" : "",
            "merchanthandle" : "cafeluna",
            "phonenumber" : "8523977088",
            "visittime" : ""
          },
          "cafeluna_879WH" : {
            "redeemguid" : "879WH",
            "redeemTimestamp" : 1708090575485,
            "redeemdate" : "2-16-2024",
            "source" : "IG",
            "promoname" : "cafeluna-2pZWt",
            "visitdate" : "",
            "merchanthandle" : "cafeluna",
            "phonenumber" : "7772637712",
            "visittime" : ""
          },
          "cafeluna_37nTH" : {
            "redeemguid" : "37nTH",
            "redeemTimestamp" : 1708081742749,
            "redeemdate" : "2-16-2024",
            "source" : "IG",
            "promoname" : "cafeluna-2pZWt",
            "visitdate" : "",
            "merchanthandle" : "cafeluna",
            "phonenumber" : "7783831219",
            "visittime" : ""
          }
        }
      },
      "cafeluna_uhF5B" : {
        "redeemguid" : "uhF5B",
        "redeemTimestamp" : 1681104695257,
        "redeemdate" : "4\/9\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-ov8AU",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_jpyox" : {
        "redeemguid" : "jpyox",
        "redeemTimestamp" : 1684994941797,
        "redeemdate" : "5\/24\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-rohpr",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_2PFxz" : {
        "redeemguid" : "2PFxz",
        "source" : "IG",
        "redeemdate" : "4\/9\/2023",
        "visitdate" : "",
        "promoname" : "_2PFxz",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "",
        "visittime" : ""
      },
      "cafeluna_2gCPB" : {
        "redeemguid" : "2gCPB",
        "source" : "IG",
        "redeemdate" : "4\/9\/2023",
        "visitdate" : "Sunday",
        "promoname" : "4159997117_2gCPB",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : "11:00 AM"
      },
      "cafeluna_qqTto" : {
        "redeemguid" : "qqTto",
        "source" : "IG",
        "redeemdate" : "4\/9\/2023",
        "visitdate" : "",
        "promoname" : "4159997117_qqTto",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_xqfWk" : {
        "redeemguid" : "xqfWk",
        "redeemTimestamp" : 1685206603690,
        "redeemdate" : "5\/27\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-bNKfT",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna-4fbuE" : {
        "12-31-2023" : {
          "cafeluna_4Y1WK" : {
            "redeemguid" : "4Y1WK",
            "redeemTimestamp" : 1704082278609,
            "redeemdate" : "12-31-2023",
            "source" : "IG",
            "promoname" : "cafeluna-4fbuE",
            "visitdate" : "",
            "merchanthandle" : "cafeluna",
            "merchantname" : "Cafe Luna",
            "phonenumber" : "4159997117",
            "visittime" : ""
          }
        }
      },
      "cafeluna-fXyoq" : {
        "1-10-2024" : {
          "cafeluna_3wuCq" : {
            "redeemguid" : "3wuCq",
            "redeemTimestamp" : 1704940014142,
            "redeemdate" : "1-10-2024",
            "source" : "IG",
            "promoname" : "cafeluna-fXyoq",
            "visitdate" : "",
            "merchanthandle" : "cafeluna",
            "phonenumber" : "4159997117",
            "visittime" : ""
          }
        }
      },
      "cafeluna_oPmMt" : {
        "redeemguid" : "oPmMt",
        "redeemTimestamp" : 1691861309260,
        "redeemdate" : "8\/12\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-xh79e",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_fSA2H" : {
        "redeemguid" : "fSA2H",
        "source" : "IG",
        "redeemdate" : "4\/9\/2023",
        "visitdate" : "",
        "promoname" : "_fSA2H",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "",
        "visittime" : ""
      },
      "cafeluna_9BLxN" : {
        "redeemguid" : "9BLxN",
        "redeemTimestamp" : 1703109000141,
        "redeemdate" : "12\/20\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-joBfZ",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_czCPa" : {
        "redeemguid" : "czCPa",
        "redeemTimestamp" : 1699212862013,
        "redeemdate" : "11\/5\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-fXyoq",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna-b6XRK" : {
        "1-4-2024" : {
          "cafeluna_mghdW" : {
            "redeemguid" : "mghdW",
            "redeemTimestamp" : 1704437265870,
            "redeemdate" : "1-4-2024",
            "source" : "IG",
            "promoname" : "cafeluna-b6XRK",
            "visitdate" : "",
            "merchanthandle" : "cafeluna",
            "phonenumber" : "4159997117",
            "visittime" : ""
          }
        }
      },
      "cafeluna_47WjM" : {
        "redeemguid" : "47WjM",
        "redeemTimestamp" : 1681169576239,
        "redeemdate" : "4\/10\/2023",
        "source" : "IG",
        "promoname" : "CafeLuna-s1a7V8Bfk8RRoqstBsXx3W",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_39C14" : {
        "redeemguid" : "39C14",
        "redeemTimestamp" : 1681100365315,
        "redeemdate" : "4\/9\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-hW4dzFMC1BDKy5h4Uqchz2",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_hfHyf" : {
        "redeemguid" : "hfHyf",
        "redeemTimestamp" : 1681093894671,
        "redeemdate" : "4\/9\/2023",
        "source" : "IG",
        "promoname" : "4159997117_hfHyf",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_rxpYR" : {
        "redeemguid" : "rxpYR",
        "redeemTimestamp" : 1684787708244,
        "redeemdate" : "5\/22\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-bNKfT",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_njop3" : {
        "redeemguid" : "njop3",
        "source" : "IG",
        "redeemdate" : "4\/9\/2023",
        "visitdate" : "Tuesday",
        "promoname" : "4159997117_njop3",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : "12:00 PM"
      },
      "cafeluna_aBqo6" : {
        "redeemguid" : "aBqo6",
        "redeemTimestamp" : 1684447716170,
        "redeemdate" : "5\/18\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-carpv",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_neMT8" : {
        "redeemguid" : "neMT8",
        "redeemTimestamp" : 1682170884399,
        "redeemdate" : "4\/22\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-carpv",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_eyyQH" : {
        "redeemguid" : "eyyQH",
        "redeemTimestamp" : 1684013042001,
        "redeemdate" : "5\/13\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-af5YG",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_8JFLd" : {
        "redeemguid" : "8JFLd",
        "redeemTimestamp" : 1681098555062,
        "redeemdate" : "4\/9\/2023",
        "source" : "IG",
        "promoname" : "4159997117_8JFLd",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_7QhGk" : {
        "redeemguid" : "7QhGk",
        "redeemTimestamp" : 1681104371235,
        "redeemdate" : "4\/9\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-ov8AU",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_4AW8f" : {
        "redeemguid" : "4AW8f",
        "redeemTimestamp" : 1681098574771,
        "redeemdate" : "4\/9\/2023",
        "source" : "IG",
        "promoname" : "4159997117_4AW8f",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_g8wGn" : {
        "redeemguid" : "g8wGn",
        "redeemTimestamp" : 1681104115113,
        "redeemdate" : "4\/9\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-ov8AU",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_j7zAF" : {
        "redeemguid" : "j7zAF",
        "redeemTimestamp" : 1701537269274,
        "redeemdate" : "12\/2\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-xh79e",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_koPP7" : {
        "redeemguid" : "koPP7",
        "redeemTimestamp" : 1704093107078,
        "redeemdate" : "12\/31\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-4fbuE",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna-qePMW" : {
        "1-5-2024" : {
          "cafeluna_qtB7o" : {
            "redeemguid" : "qtB7o",
            "redeemTimestamp" : 1704479026379,
            "redeemdate" : "1-5-2024",
            "source" : "IG",
            "promoname" : "cafeluna-qePMW",
            "visitdate" : "",
            "merchanthandle" : "cafeluna",
            "phonenumber" : "4159997117",
            "visittime" : ""
          }
        }
      },
      "cafeluna_3arQb" : {
        "redeemguid" : "3arQb",
        "redeemTimestamp" : 1681515992099,
        "redeemdate" : "4\/14\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-ov8AU",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_gM2eV" : {
        "redeemguid" : "gM2eV",
        "redeemTimestamp" : 1683158316475,
        "redeemdate" : "5\/3\/2023",
        "source" : "IG",
        "promoname" : "cafeluna-pBuAW",
        "visitdate" : "",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : ""
      },
      "cafeluna_h7WZc" : {
        "redeemguid" : "h7WZc",
        "source" : "IG",
        "redeemdate" : "4\/9\/2023",
        "visitdate" : "Tuesday",
        "promoname" : "4159997117_h7WZc",
        "merchanthandle" : "cafeluna",
        "merchantname" : "Cafe Luna",
        "phonenumber" : "4159997117",
        "visittime" : "3:00 PM"
      }
    },
    "info" : {
      "posSystem" : "square",
      "instagramProfileHandle" : "https:\/\/www.instagram.com\/luna",
      "merchantHandle" : "cafeluna",
      "premium" : true,
      "shortBio" : "Short bio here",
      "location" : {
        "phoneNumber" : "4159997117",
        "state" : "California",
        "city" : "Fremont",
        "zip" : "94536",
        "address" : "123 Maple street"
      },
      "merchantName" : "Cafe Luna",
      "cafeBioHandle" : "cafeluna_02",
      "companyName" : "Cafe Luna , LLC",
      "posKey" : "123",
      "websiteUrl" : "https:\/\/www.instagram.com\/luna",
      "email" : "curtisspope@gmail.com"
    },
    "promo-archive" : {
      "cafeluna-af5YG" : {
        "style" : {
          "imagename" : "img_041.png",
          "stylename" : "img_041",
          "textcolor" : "FEE6C6",
          "additionalcolor" : "FFA596",
          "backgroundcolor" : "50092C"
        },
        "status" : "ACTIVE",
        "dateend" : "04-22-2023",
        "date_created" : "4\/19\/2023",
        "promomessage" : "Buy 3 espresso drinks and get 1 free.",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "04-19-2023",
        "merchantname" : "cafeluna",
        "promoimageheader" : "img_055",
        "promoname" : "cafeluna-af5YG",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Double shot, double reward!",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "cafeluna-aroXQ" : {
        "style" : {
          "imagename" : "img_044.png",
          "stylename" : "img_044",
          "textcolor" : "FF8769",
          "additionalcolor" : "0CD4FF",
          "backgroundcolor" : "520045"
        },
        "status" : "ACTIVE",
        "dateend" : "05-17-2023",
        "date_created" : "4\/20\/2023",
        "promomessage" : "Buy 6 espresso shots and get 2 free.",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "04-20-2023",
        "merchantname" : "cafeluna",
        "promoimageheader" : "",
        "promoname" : "cafeluna-aroXQ",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Wake up and smell the espresso!",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "cafeluna-vxajU" : {
        "style" : {
          "imagename" : "img_034.png",
          "stylename" : "img_034",
          "textcolor" : "F9FF00",
          "additionalcolor" : "FF7712",
          "backgroundcolor" : "680575"
        },
        "status" : "ACTIVE",
        "dateend" : "05-12-2023",
        "date_created" : "5\/10\/2023",
        "promomessage" : "Get 50% off frappe drinks from 4-6pm every weekday.",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "05-10-2023",
        "merchantname" : "cafeluna",
        "promoimageheader" : "",
        "promoname" : "cafeluna-vxajU",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Go Lakers!",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "cafeluna-6uhtv" : {
        "promoname" : "cafeluna-6uhtv",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "status" : "ACTIVE",
        "timezone" : "",
        "promotype" : "promotion",
        "squareloyaltyID" : "",
        "promobuttontext" : "Redeem",
        "promoshowterms" : "YES",
        "square_loyalty_program_id" : "",
        "promotitle" : "Matcha-mazing deals!",
        "promomessage" : "Buy 2 matcha lattes and get 1 50% off.",
        "date_created" : "4\/15\/2023",
        "redemptionlimit" : "10",
        "datetimeend" : "",
        "dateend" : "04-29-2023",
        "datestart" : "04-15-2023",
        "datetimestart" : "",
        "merchantname" : "cafeluna",
        "promoimageheader" : "img_042"
      },
      "cafeluna-cPEzh" : {
        "style" : {
          "imagename" : "img_110.png",
          "stylename" : "img_110",
          "textcolor" : "FFF4E1",
          "additionalcolor" : "FEC193",
          "backgroundcolor" : "713a7f"
        },
        "status" : "ACTIVE",
        "dateend" : "06-13-2023",
        "date_created" : "5\/5\/2023",
        "promomessage" : "Buy 1 iced tea, get 1 free.",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "05-04-2023",
        "merchantname" : "cafeluna",
        "promoimageheader" : "",
        "promoname" : "cafeluna-cPEzh",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Refresh your day!",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "cafeluna-db3nb" : {
        "style" : {
          "imagename" : "img_010.png",
          "stylename" : "img_010",
          "textcolor" : "D79C68",
          "additionalcolor" : "C16D1C",
          "backgroundcolor" : "07100B"
        },
        "status" : "ACTIVE",
        "dateend" : "05-17-2023",
        "date_created" : "4\/20\/2023",
        "promomessage" : "Spend $15 on caramel macchiatos and get 10% off.",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "05-15-2023",
        "merchantname" : "cafeluna",
        "promoimageheader" : "B",
        "promoname" : "cafeluna-db3nb",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Get your daily dose of caramel!",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "cafeluna-bNKfT" : {
        "style" : {
          "imagename" : "img_039.png",
          "stylename" : "img_039",
          "textcolor" : "ff7c00",
          "additionalcolor" : "FDFEFE",
          "backgroundcolor" : "000000"
        },
        "status" : "ACTIVE",
        "dateend" : "06-13-2023",
        "date_created" : "5\/5\/2023",
        "promomessage" : "Spend $15 on caramel macchiatos and get 20% off.",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "05-04-2023",
        "merchantname" : "cafeluna",
        "promoimageheader" : "",
        "promoname" : "cafeluna-bNKfT",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Giant Size Deal",
        "redemptionlimit" : "5",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "cafeluna-p21Yp" : {
        "style" : {
          "imagename" : "img_057.png",
          "stylename" : "img_057",
          "textcolor" : "fffe00",
          "additionalcolor" : "00A1A4",
          "backgroundcolor" : "e97d21"
        },
        "status" : "ACTIVE",
        "dateend" : "06-13-2023",
        "date_created" : "5\/5\/2023",
        "promomessage" : "Spend $15 on caramel macchiatos and get 10% off.",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "05-04-2023",
        "merchantname" : "cafeluna",
        "promoimageheader" : "",
        "promoname" : "cafeluna-p21Yp",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Get your daily dose of caramel!",
        "redemptionlimit" : "5",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "cafeluna-4SqAB" : {
        "style" : {
          "imagename" : "img_035.png",
          "stylename" : "img_035",
          "textcolor" : "FED1A3",
          "additionalcolor" : "FF9404",
          "backgroundcolor" : "000610"
        },
        "status" : "ACTIVE",
        "dateend" : "05-24-2023",
        "date_created" : "4\/19\/2023",
        "promomessage" : "Spend $20 on mocha drinks and get 15% off.",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "04-20-2023",
        "merchantname" : "cafeluna",
        "promoimageheader" : "c",
        "promoname" : "cafeluna-4SqAB",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Mocha your day better!",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "cafeluna-eNJ4R" : {
        "style" : {
          "imagename" : "img_044.png",
          "stylename" : "img_044",
          "textcolor" : "241fa8",
          "additionalcolor" : "0CD4FF",
          "backgroundcolor" : "7ed321"
        },
        "status" : "ACTIVE",
        "dateend" : "06-13-2023",
        "date_created" : "5\/5\/2023",
        "promomessage" : "Buy 3 espresso drinks and get 1 free. ",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "05-04-2023",
        "merchantname" : "cafeluna",
        "promoimageheader" : "",
        "promoname" : "cafeluna-eNJ4R",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Double shot, double reward! ",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "cafeluna-rohpr" : {
        "style" : {
          "imagename" : "img_090.png",
          "stylename" : "img_090",
          "textcolor" : "F3E380",
          "additionalcolor" : "9FB29C",
          "backgroundcolor" : "002220"
        },
        "status" : "ACTIVE",
        "dateend" : "05-17-2023",
        "date_created" : "4\/22\/2023",
        "promomessage" : "Buy 3 croissants and get 1 free.",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "04-13-2023",
        "merchantname" : "cafeluna",
        "promoimageheader" : "",
        "promoname" : "cafeluna-rohpr",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Savor the flaky goodness of croissants!",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "cafeluna-naZfy" : {
        "style" : {
          "imagename" : "img_096.png",
          "stylename" : "img_096",
          "textcolor" : "EED1AE",
          "additionalcolor" : "FFEED2",
          "backgroundcolor" : "283B2B"
        },
        "status" : "ACTIVE",
        "dateend" : "05-17-2023",
        "date_created" : "4\/20\/2023",
        "promomessage" : "Buy 1 chai latte, get 1 free.",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "04-20-2023",
        "merchantname" : "cafeluna",
        "promoimageheader" : "",
        "promoname" : "cafeluna-naZfy",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Satisfy your sweet tooth with chai lattes!",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "cafeluna-nvCL6" : {
        "style" : {
          "imagename" : "img_041.png",
          "stylename" : "img_041",
          "textcolor" : "FEE6C6",
          "additionalcolor" : "FFA596",
          "backgroundcolor" : "50092C"
        },
        "status" : "ACTIVE",
        "dateend" : "06-08-2023",
        "date_created" : "5\/3\/2023",
        "promomessage" : "Spend $20 on matcha lattes and get 15% off.",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "05-11-2023",
        "merchantname" : "cafeluna",
        "promoimageheader" : "",
        "promoname" : "cafeluna-nvCL6",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Matcha made in heaven!",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "cafeluna-uYuX9" : {
        "style" : {
          "imagename" : "img_042.png",
          "stylename" : "img_042",
          "textcolor" : "F37A00",
          "additionalcolor" : "60946E",
          "backgroundcolor" : "00322E"
        },
        "status" : "ACTIVE",
        "dateend" : "05-09-2023",
        "date_created" : "4\/20\/2023",
        "promomessage" : "Get 25% off frappe drinks from 12-3pm every weekday.",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "04-06-2023",
        "merchantname" : "cafeluna",
        "promoimageheader" : "c",
        "promoname" : "cafeluna-uYuX9",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Beat the heat with our frappes!",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "cafeluna-h3D1Z" : {
        "style" : {
          "imagename" : "img_046.png",
          "stylename" : "img_046",
          "textcolor" : "FFC240",
          "additionalcolor" : "FF7B2F",
          "backgroundcolor" : "033343"
        },
        "status" : "ACTIVE",
        "dateend" : "05-24-2023",
        "date_created" : "4\/19\/2023",
        "promomessage" : "Buy 1 fruit smoothie, get 1 50% off.",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "04-20-2023",
        "merchantname" : "cafeluna",
        "promoimageheader" : "c",
        "promoname" : "cafeluna-h3D1Z",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Sip on some savings!",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "cafeluna-udYJa" : {
        "style" : {
          "imagename" : "img_010.png",
          "stylename" : "img_010",
          "textcolor" : "D79C68",
          "additionalcolor" : "C16D1C",
          "backgroundcolor" : "07100B"
        },
        "status" : "ACTIVE",
        "dateend" : "05-17-2023",
        "date_created" : "4\/20\/2023",
        "promomessage" : "Spend $15 on caramel macchiatos and get 10% off.",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "05-15-2023",
        "merchantname" : "cafeluna",
        "promoimageheader" : "B",
        "promoname" : "cafeluna-udYJa",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Get your daily dose of caramel!",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      },
      "cafeluna-tXnp6" : {
        "style" : {
          "imagename" : "img_044.png",
          "stylename" : "img_044",
          "textcolor" : "7037cf",
          "additionalcolor" : "0CD4FF",
          "backgroundcolor" : "ff00d9"
        },
        "status" : "ACTIVE",
        "dateend" : "06-13-2023",
        "date_created" : "5\/5\/2023",
        "promomessage" : "Buy 3 espresso drinks and get 1 free. ",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "05-04-2023",
        "merchantname" : "cafeluna",
        "promoimageheader" : "",
        "promoname" : "cafeluna-tXnp6",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Double shot, double reward! ",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      }
    },
    "promos" : {
      "cafeluna-2pZWt" : {
        "style" : {
          "imagename" : "espresso.png",
          "promotype" : "single-color",
          "stylename" : "img_009",
          "textcolor" : "E2A64E",
          "backgroundcolor" : "00191D",
          "primarycolor" : "e57a21",
          "secondarycolor" : "e2a64e",
          "additionalcolor" : "E57A21"
        },
        "status" : "ACTIVE",
        "dateend" : "02-20-2024",
        "date_created" : "12\/15\/2023",
        "promomessage" : "Get 50% off frappe drinks from 4-6pm every weekday.",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "12-14-2023",
        "merchantname" : "cafeluna",
        "promoimageheader" : "",
        "promoname" : "cafeluna-2pZWt",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "promotitle" : "Beat the heat with our frappes!",
        "redemptionlimit" : "10",
        "squareloyaltyID" : "",
        "timezone" : "",
        "promobuttontext" : "Redeem",
        "datetimestart" : ""
      }
    }
  },
  "erizo" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(503) 206-8619",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Ee9Ox7VayGaOBaIkHkd3EP1L1uBylJhf.png",
      "instagramHandle" : "erizo",
      "twitterHandle" : "",
      "merchantHandle" : "erizo",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Erizo",
      "city" : "Portland",
      "companyName" : "Erizo",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "sharetea" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(619) 934-1244",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/lRk9Vz4vKElPkXlNGqJU89O5yeA6o8jR.png",
      "instagramHandle" : "sharetea",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "sharetea",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Sharetea",
      "city" : "Chula Vista",
      "companyName" : "Sharetea",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.1992sharetea.com\/",
      "email" : ""
    }
  },
  "coastalpacificfooddistribution" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 983-2454",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "coastalpacificfooddistribution",
      "merchantHandle" : "coastalpacificfooddistribution",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Coastal Pacific Food Distribution",
      "city" : "Stockton",
      "companyName" : "Coastal Pacific Food Distribution",
      "websiteUrl" : "http:\/\/coastal-food.poi.place\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "happymedium" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(619) 310-5642",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/DtGMWRyTbFvsVbMaruZm4qtmhwEqyaE1.png",
      "instagramHandle" : "happymedium",
      "merchantHandle" : "happymedium",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Happy Medium",
      "city" : "Fontana",
      "companyName" : "Happy Medium",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.happymediumsd.com\/",
      "email" : ""
    }
  },
  "inharmonycafsweetsmore" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(586) 307-6450",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/BVZWaz62paMoUK3Bk0otADy0fLrticib.png",
      "instagramHandle" : "inharmonycafsweetsmore",
      "twitterHandle" : "",
      "merchantHandle" : "inharmonycafsweetsmore",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "In Harmony Café Sweets & More",
      "city" : "Detroit",
      "companyName" : "In Harmony Café Sweets & More",
      "websiteUrl" : "https:\/\/inharmonycafesweets.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "cafecup" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 840-8991",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "cafecup",
      "twitterHandle" : "",
      "merchantHandle" : "cafecup",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Cafe Cup",
      "city" : "Huntington Beach",
      "companyName" : "Cafe Cup",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/cafecup-hb.com\/",
      "email" : ""
    }
  },
  "vespermusicproductionsllc" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(810) 221-6111",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/069a3dfc99d86ba1da3c32f7c3dc392c897d5e27\/original.png",
      "instagramHandle" : "vespermusicproductionsllc",
      "premium" : false,
      "merchantHandle" : "vespermusicproductionsllc",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Vesper Music Productions LLC",
      "city" : "Flint",
      "companyName" : "Vesper Music Productions LLC",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.vespermusicproductions.com\/",
      "email" : ""
    }
  },
  "publicthread" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "publicthread",
      "premium" : false,
      "merchantHandle" : "publicthread",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Public Thread",
      "city" : "Grand Rapids",
      "companyName" : "Public Thread",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.publicthread.org\/",
      "email" : ""
    }
  },
  "cornutritionherbalife" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/a7ff88afa35fbf107c81bcaa4f5f2602b8bfd9f8\/original.png",
      "instagramHandle" : "cornutritionherbalife",
      "twitterHandle" : "",
      "merchantHandle" : "cornutritionherbalife",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Cor Nutrition Herbalife",
      "city" : "Flint",
      "companyName" : "Cor Nutrition Herbalife",
      "websiteUrl" : "https:\/\/cornutrition.square.site\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "coffeenature" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 545-2274",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/7YpdpTOffqCPgAjVjtB1cR4L1V4H9CbN.png",
      "instagramHandle" : "coffeenature",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "coffeenature",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Coffee Nature",
      "city" : "Santa Ana",
      "companyName" : "Coffee Nature",
      "websiteUrl" : "https:\/\/m.facebook.com\/CoffeeNatureOC",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "mutantiscultbrewery" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(503) 558-4555",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/nCVC9LIHq5arvaBpJJbHGF8drpodogRA.png",
      "instagramHandle" : "mutantiscultbrewery",
      "merchantHandle" : "mutantiscultbrewery",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Mutantis Cult Brewery",
      "city" : "Vancouver",
      "companyName" : "Mutantis Cult Brewery",
      "websiteUrl" : "https:\/\/mutantis.beer\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "henandharvestfarm" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 410-0682",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ZqLRm5Oy3sB5XUjeIQpYZRJMMOuui4wG.png",
      "instagramHandle" : "henandharvestfarm",
      "merchantHandle" : "henandharvestfarm",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Hen and Harvest Farm",
      "city" : "Modesto",
      "companyName" : "Hen and Harvest Farm",
      "websiteUrl" : "http:\/\/www.henandharvest.farm\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sundoughnuts" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 988-9229",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/0171248546f573f9973259e7df71e0a9\/original.jpeg",
      "instagramHandle" : "sundoughnuts",
      "premium" : false,
      "merchantHandle" : "sundoughnuts",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sun Doughnuts",
      "city" : "Oxnard",
      "companyName" : "Sun Doughnuts",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "paliwineco" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 486-0922",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/rTjG2EqcssbJiayAWNxQyffli2U0UL1B.png",
      "instagramHandle" : "paliwineco",
      "premium" : false,
      "merchantHandle" : "paliwineco",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Pali Wine Co.",
      "city" : "Santa Ana",
      "companyName" : "Pali Wine Co.",
      "websiteUrl" : "http:\/\/www.paliwineco.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "pisapizza" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 864-4400",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/c6MRWXQcniKRKjuIJk65pp1wBoy0Ipnh.png",
      "instagramHandle" : "pisapizza",
      "premium" : false,
      "merchantHandle" : "pisapizza",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Pisa Pizza",
      "city" : "San Bernardino",
      "companyName" : "Pisa Pizza",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.pisapizzasb.com\/",
      "email" : ""
    }
  },
  "latinbakery" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 428-1989",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "latinbakery",
      "premium" : false,
      "merchantHandle" : "latinbakery",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Latin Bakery",
      "city" : "Fontana",
      "companyName" : "Latin Bakery",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "thomasnaturalroots" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(517) 290-3298",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "thomasnaturalroots",
      "merchantHandle" : "thomasnaturalroots",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Thomas' Natural Roots",
      "city" : "Lansing",
      "companyName" : "Thomas' Natural Roots",
      "websiteUrl" : "http:\/\/www.thomasnaturalroots.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sushiuno" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(619) 301-7293",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Jz0k1ltNe6PGQjWraVfJml5ySUWmavOO.png",
      "instagramHandle" : "sushiuno",
      "twitterHandle" : "",
      "merchantHandle" : "sushiuno",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "SUSHI UNO",
      "city" : "Chula Vista",
      "companyName" : "SUSHI UNO",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "qdhq" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/0GtmeYNg05FP8Lq7iVqXo0UmiByA7WGV.png",
      "instagramHandle" : "qdhq",
      "merchantHandle" : "qdhq",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "QD HQ",
      "city" : "Lansing",
      "companyName" : "QD HQ",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "fivebelow" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 463-4378",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/4961ea7e59985a00f37a3b3919400980fb575e86\/original.jpeg",
      "instagramHandle" : "fivebelow",
      "premium" : false,
      "merchantHandle" : "fivebelow",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Five Below",
      "city" : "Fontana",
      "companyName" : "Five Below",
      "websiteUrl" : "https:\/\/locations.fivebelow.com\/ca\/fontana\/15234-summit-ave?utm_source=gmb&utm_medium=yext&y_source=1_MTM1Mzg3MzgtNzE1LWxvY2F0aW9uLndlYnNpdGU%3D",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "lasanta" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(657) 231-6005",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/7138984f989cc0dfc8d1b4a35a07c03493c46140\/original.png",
      "instagramHandle" : "lasanta",
      "merchantHandle" : "lasanta",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "La Santa",
      "city" : "Santa Ana",
      "companyName" : "La Santa",
      "websiteUrl" : "http:\/\/lasantaoc.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "royalkhyber" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 436-1010",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/n0yKGaBYvRKa4XvnMzLCYZUSn6onyLWD.png",
      "instagramHandle" : "royalkhyber",
      "merchantHandle" : "royalkhyber",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Royal Khyber",
      "city" : "Santa Ana",
      "companyName" : "Royal Khyber",
      "websiteUrl" : "https:\/\/royalkhyber.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "susannesbakerydeli" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(253) 853-6220",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/l5Pb3p5l7pQEMbDClyd7s0ya3HqtS1JE.png",
      "instagramHandle" : "susannesbakerydeli",
      "premium" : false,
      "merchantHandle" : "susannesbakerydeli",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Susanne's Bakery & Deli",
      "city" : "Tacoma",
      "companyName" : "Susanne's Bakery & Deli",
      "websiteUrl" : "http:\/\/www.susannesbakery.net\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "tigerstaproom" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 817-4994",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/92H7HUqUHClgQRTme3zjRZh54ymnoOoX.png",
      "instagramHandle" : "tigerstaproom",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "tigerstaproom",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Tiger's Taproom",
      "city" : "Oakland",
      "companyName" : "Tiger's Taproom",
      "websiteUrl" : "http:\/\/tigerstaproom.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "mochilionriversidemochinutbefore" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(951) 534-0756",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/0EnNSODmXdzPGm8g4I6PSTXqDyN0b2lM.png",
      "instagramHandle" : "mochilionriversidemochinutbefore",
      "twitterHandle" : "",
      "merchantHandle" : "mochilionriversidemochinutbefore",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Mochi Lion Riverside (Mochinut before)",
      "city" : "San Bernardino",
      "companyName" : "Mochi Lion Riverside (Mochinut before)",
      "websiteUrl" : "https:\/\/mochilion-riverside.square.site\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "valerieconfections" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(213) 739-8149",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/P6s0XyFHeGmSdmTviQ0wRbztv3KQHqkv.png",
      "instagramHandle" : "valerieconfections",
      "twitterHandle" : "",
      "merchantHandle" : "valerieconfections",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Valerie Confections",
      "city" : "Glendale",
      "companyName" : "Valerie Confections",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.valerieconfections.com\/",
      "email" : ""
    }
  },
  "kungfutea" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(408) 477-2688",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/9DfccTd0wzCc035u6eTJ3XfG54uaEZMK.png",
      "instagramHandle" : "kungfutea",
      "premium" : false,
      "merchantHandle" : "kungfutea",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Kung Fu Tea",
      "city" : "San Jose",
      "companyName" : "Kung Fu Tea",
      "websiteUrl" : "https:\/\/kungfutea.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "lecafeduparcbychocofruit" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(949) 502-4745",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/nPMcuuEdMArMTR3uH9Z0yPqnDLfHz2Ik.png",
      "instagramHandle" : "lecafeduparcbychocofruit",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "lecafeduparcbychocofruit",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Le Cafe Du Parc by Chocofruit",
      "city" : "Irvine",
      "companyName" : "Le Cafe Du Parc by Chocofruit",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.lecafeduparc.us\/",
      "email" : ""
    }
  },
  "epiloguekitchenandcocktails" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(503) 581-1880",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/e9615637c96e5efa41eeaeed5022b6ab41e9b73e\/original.jpeg",
      "instagramHandle" : "epiloguekitchenandcocktails",
      "premium" : false,
      "merchantHandle" : "epiloguekitchenandcocktails",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Epilogue Kitchen and Cocktails",
      "city" : "Salem",
      "companyName" : "Epilogue Kitchen and Cocktails",
      "websiteUrl" : "http:\/\/www.epiloguekitchen.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "caminos" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(360) 695-1797",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/GOb7sSkalesvN3CDluOFKWJYdF6dI8NU.png",
      "instagramHandle" : "caminos",
      "twitterHandle" : "",
      "merchantHandle" : "caminos",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "4 Caminos",
      "city" : "Vancouver",
      "companyName" : "4 Caminos",
      "websiteUrl" : "http:\/\/4caminosmexicanrestaurant.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "moonstruckfarms" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(760) 412-9746",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/6d8863022475b8fef1d9ceac12237be2b249aee1\/original.jpeg",
      "instagramHandle" : "moonstruckfarms",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "moonstruckfarms",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Moonstruck Farms",
      "city" : "San Bernardino",
      "companyName" : "Moonstruck Farms",
      "websiteUrl" : "http:\/\/www.moonstruckfarms.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "bobabarecrafttea" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(586) 421-5579",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/xRrorJexuJCf6eK1URWq2uGVQeAOw45u.png",
      "instagramHandle" : "bobabarecrafttea",
      "premium" : false,
      "merchantHandle" : "bobabarecrafttea",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Boba Bare Craft Tea",
      "city" : "Warren",
      "companyName" : "Boba Bare Craft Tea",
      "websiteUrl" : "http:\/\/bobabarecrafttea.shop\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "porkstorecafe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 864-6981",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/4b3f8dc23667fae1838e731abe0ff93607a9ae57\/original.jpeg",
      "instagramHandle" : "porkstorecafe",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "porkstorecafe",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Pork Store Cafe",
      "city" : "San Francisco",
      "companyName" : "Pork Store Cafe",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.orderporkstore.com\/",
      "email" : ""
    }
  },
  "brewittcafebakery" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(989) 307-8672",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/12773602a1c816cb3cb1427c58d2cbcc87617f41\/original.png",
      "instagramHandle" : "brewittcafebakery",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "brewittcafebakery",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Brewitt Cafe & Bakery",
      "city" : "Lansing",
      "companyName" : "Brewitt Cafe & Bakery",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.brewittcafebakery.com\/",
      "email" : ""
    }
  },
  "fishonastick" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(503) 997-9286",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/1485b42dec46e3c76d294cd87698a983f7f0a41d\/original.jpeg",
      "instagramHandle" : "fishonastick",
      "merchantHandle" : "fishonastick",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Fish On A Stick",
      "city" : "Portland",
      "companyName" : "Fish On A Stick",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sacramentoagentviridianamejiarealestateteam" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(916) 513-0851",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "sacramentoagentviridianamejiarealestateteam",
      "merchantHandle" : "sacramentoagentviridianamejiarealestateteam",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sacramento Agent - Viridiana Mejia Real Estate Team",
      "city" : "Sacramento",
      "companyName" : "Sacramento Agent - Viridiana Mejia Real Estate Team",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/viridianamejia.guiderealestate.com\/",
      "email" : ""
    }
  },
  "hiddenhousecoffee" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 972-4997",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/HSavlLqqwxIcsXGXKrOuVuiH5MCzLfKU.png",
      "instagramHandle" : "hiddenhousecoffee",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "hiddenhousecoffee",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Hidden House Coffee",
      "city" : "Santa Ana",
      "companyName" : "Hidden House Coffee",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/hiddenhousecoffee.com\/",
      "email" : ""
    }
  },
  "openartstreetmarketllc" : {
    "info" : {
      "state" : "",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "openartstreetmarketllc",
      "instagramHandle" : " LLC\"",
      "twitterHandle" : "",
      "merchantHandle" : "openartstreetmarketllc",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "(619) 255-4126",
      "merchantName" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/fdTohm04hCE7lNORe7TYwaUFZ2wKQSsT.png",
      "city" : "",
      "companyName" : "\"Open Art Street Market",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "gourmetapplecart" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(989) 529-2660",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/v4z0yUifNh2HBXQy6ZISjah62e8tilfn.png",
      "instagramHandle" : "gourmetapplecart",
      "merchantHandle" : "gourmetapplecart",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Gourmet Apple Cart",
      "city" : "Flint",
      "companyName" : "Gourmet Apple Cart",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.gourmetapplecart.com\/",
      "email" : ""
    }
  },
  "five15" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(248) 515-2551",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/fNBcsagq1xtLm1znkMvOeluQT2trNu9S.png",
      "instagramHandle" : "five15",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "five15",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Five15",
      "city" : "Warren",
      "companyName" : "Five15",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/five15.net\/",
      "email" : ""
    }
  },
  "sourdoughcomarchlanestockton" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "",
      "phone" : "",
      "merchantImage" : "sourdoughcomarchlanestockton",
      "instagramHandle" : " Stockton\"",
      "merchantHandle" : "sourdoughcomarchlanestockton",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "(209) 323-5047",
      "merchantName" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/c3v5daeEdpbILVzwn3J8T8TDylgEQ1Zj.png",
      "city" : "",
      "companyName" : "\"Sourdough & Co. - March lane",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : "https:\/\/sourdoughandco.com\/stockton"
    }
  },
  "thegardencoffeelocaleats" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(509) 315-8612",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/s9bE5g4Zyo06wbofX17f3xXBFbTI65c1.png",
      "instagramHandle" : "thegardencoffeelocaleats",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "thegardencoffeelocaleats",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Garden Coffee & Local Eats",
      "city" : "Spokane",
      "companyName" : "The Garden Coffee & Local Eats",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.thegarden509.com\/",
      "email" : ""
    }
  },
  "bevmo" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(949) 551-3377",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/GUidObJFbraRxRH7YSZLxWnJ28jWcW7i.png",
      "instagramHandle" : "bevmo",
      "premium" : false,
      "merchantHandle" : "bevmo",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "BevMo!",
      "city" : "Irvine",
      "companyName" : "BevMo!",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.bevmo.com\/",
      "email" : ""
    }
  },
  "boudinsf" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 952-2000",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/f7350b888d6318503f1f365202af2318fd76e78f\/original.jpeg",
      "instagramHandle" : "boudinsf",
      "merchantHandle" : "boudinsf",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Boudin SF",
      "city" : "Stockton",
      "companyName" : "Boudin SF",
      "websiteUrl" : "https:\/\/boudinbakery.com\/location\/boudin-sf-stone-creek-village\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "portalteacompanyformerlyteachaite" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(503) 432-8747",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/ead071f0119bd4a3bf7979f1b7da32ad4decc056\/original.gif",
      "instagramHandle" : "portalteacompanyformerlyteachaite",
      "twitterHandle" : "",
      "merchantHandle" : "portalteacompanyformerlyteachaite",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Portal Tea Company (formerly Tea Chai Te)",
      "city" : "Portland",
      "companyName" : "Portal Tea Company (formerly Tea Chai Te)",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/portaltea.co\/",
      "email" : ""
    }
  },
  "luigisrestaurant" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(810) 234-9545",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/pA0JvylOqZbrDkmhxXqSMBkAbBtiKJxK.png",
      "instagramHandle" : "luigisrestaurant",
      "merchantHandle" : "luigisrestaurant",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Luigi's Restaurant",
      "city" : "Flint",
      "companyName" : "Luigi's Restaurant",
      "websiteUrl" : "http:\/\/www.luigissince1955.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "madronahillcafe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(503) 289-7617",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/hAVs70wTz7ZIwJpa0LPKVoAEQB7aN5dU.png",
      "instagramHandle" : "madronahillcafe",
      "twitterHandle" : "",
      "merchantHandle" : "madronahillcafe",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Madrona Hill Cafe",
      "city" : "Vancouver",
      "companyName" : "Madrona Hill Cafe",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/madronahillcafe.com\/",
      "email" : ""
    }
  },
  "happycatcafe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 202-4750",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/JpSOoQDKaO0W0JsA6B3VN0FjR8BBbPTS.png",
      "instagramHandle" : "happycatcafe",
      "premium" : false,
      "merchantHandle" : "happycatcafe",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Happy Cat Cafe",
      "city" : "Grand Rapids",
      "companyName" : "Happy Cat Cafe",
      "websiteUrl" : "http:\/\/www.happycatgr.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "worldwidelabz" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(888) 930-6458",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "worldwidelabz",
      "merchantHandle" : "worldwidelabz",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "World Wide Labz",
      "city" : "Detroit",
      "companyName" : "World Wide Labz",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "ggiatadelicatessen" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/HjGALmWuawdnNh2rSb04STa05uhLyraW.png",
      "instagramHandle" : "ggiatadelicatessen",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "ggiatadelicatessen",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Ggiata Delicatessen",
      "city" : "Glendale",
      "companyName" : "Ggiata Delicatessen",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "nicoleseay" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(562) 434-4455",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/433f4d8bc0d970991e504259b69276bd6f74b8c4\/original.png",
      "instagramHandle" : "nicoleseay",
      "premium" : false,
      "merchantHandle" : "nicoleseay",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Nicole Seay",
      "city" : "Long Beach",
      "companyName" : "Nicole Seay",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "shepherdsofbethlehem" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(810) 875-8252",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/yw3ujFF25xIqBrbWGbp0m0qO7LpInxTV.png",
      "instagramHandle" : "shepherdsofbethlehem",
      "twitterHandle" : "",
      "merchantHandle" : "shepherdsofbethlehem",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Shepherds of Bethlehem",
      "city" : "Flint",
      "companyName" : "Shepherds of Bethlehem",
      "websiteUrl" : "https:\/\/www.shepherds-bethlehem.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "hqbymegan" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(810) 820-6825",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/0GtmeYNg05FP8Lq7iVqXo0UmiByA7WGV.png",
      "instagramHandle" : "hqbymegan",
      "merchantHandle" : "hqbymegan",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "HQ by Megan",
      "city" : "Flint",
      "companyName" : "HQ by Megan",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/hqbymegan.com\/",
      "email" : ""
    }
  },
  "swankyroots" : {
    "info" : {
      "state" : "MT\r",
      "posSystem" : "Square",
      "phone" : "(406) 200-3828",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/0e58e9a1c7c77dffb15912d85aa357f8b775969d\/original.png",
      "instagramHandle" : "swankyroots",
      "twitterHandle" : "",
      "merchantHandle" : "swankyroots",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Swanky Roots",
      "city" : "Billings",
      "companyName" : "Swanky Roots",
      "websiteUrl" : "http:\/\/www.swankyroots.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "promo-redeem-complete" : {
    "analytics" : {
      "records" : {
        "landing-page" : {
          "36U5wGJX" : {
            "pathname" : "\/promo-redeem-complete",
            "time" : "2024-06-26T20:31:35.735Z",
            "merchantHandle" : "promo-redeem-complete",
            "host" : "cafe.bio",
            "timezone" : "Asia\/Tokyo",
            "userAgent" : "Mozilla\/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/126.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          }
        }
      }
    }
  },
  "mariposabakingcompany" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 595-0955",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/212a3f22662191ad8380394b2e9ffdfb5704acb4\/original.png",
      "instagramHandle" : "mariposabakingcompany",
      "merchantHandle" : "mariposabakingcompany",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Mariposa Baking Company",
      "city" : "Oakland",
      "companyName" : "Mariposa Baking Company",
      "websiteUrl" : "http:\/\/www.mariposabaking.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "mricecream" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(458) 239-9162",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/1ADYwy0Lkbz5Ps0BlU0Z0MQDDPagQvgs.png",
      "instagramHandle" : "mricecream",
      "merchantHandle" : "mricecream",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Mr. Ice Cream",
      "city" : "Eugene",
      "companyName" : "Mr. Ice Cream",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.mricecream.co\/",
      "email" : ""
    }
  },
  "catffeinated" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(253) 572-4993",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/aphPYq5nW67MMxg3Oy5bAaSBKxRUiitg.png",
      "instagramHandle" : "catffeinated",
      "merchantHandle" : "catffeinated",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Catffeinated",
      "city" : "Tacoma",
      "companyName" : "Catffeinated",
      "websiteUrl" : "http:\/\/catffeinated.net\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "tractorsupplyco" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 243-1900",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/zjIsLm1hParyemQwYZqztGJtb9kaamdk.png",
      "instagramHandle" : "tractorsupplyco",
      "twitterHandle" : "",
      "merchantHandle" : "tractorsupplyco",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Tractor Supply Co.",
      "city" : "Moreno Valley",
      "companyName" : "Tractor Supply Co.",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.tractorsupply.com\/tsc\/store_Morenovalley-CA-92555_2458?utm_source=localgmblisting&utm_medium=organic",
      "email" : ""
    }
  },
  "innerrichmondlittlesweet" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 702-6763",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Ki3IM0sW3t8vthnckN3VaXv33Y9hjTsa.png",
      "instagramHandle" : "innerrichmondlittlesweet",
      "merchantHandle" : "innerrichmondlittlesweet",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Inner Richmond- Little Sweet",
      "city" : "San Francisco",
      "companyName" : "Inner Richmond- Little Sweet",
      "websiteUrl" : "http:\/\/www.little-sweet.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "mudpenny" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 259-5353",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/feb393a6ba8c31ec20abbcd284ccac984b21e54a\/original.png",
      "instagramHandle" : "mudpenny",
      "premium" : false,
      "merchantHandle" : "mudpenny",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "MudPenny",
      "city" : "Grand Rapids",
      "companyName" : "MudPenny",
      "websiteUrl" : "http:\/\/www.mudpenny.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "foreversbakery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(360) 314-2756",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/RYwQvU7NFSWtdBHDlbEo3a6c2M1KrhRY.png",
      "instagramHandle" : "foreversbakery",
      "premium" : false,
      "merchantHandle" : "foreversbakery",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Forevers Bakery",
      "city" : "Vancouver",
      "companyName" : "Forevers Bakery",
      "websiteUrl" : "https:\/\/foreversbakery.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "freshdonuts" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(818) 244-6432",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/dusTJaGQ9aNcagdmaGKW1gCtS4a6oBhU.png",
      "instagramHandle" : "freshdonuts",
      "merchantHandle" : "freshdonuts",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Fresh Donuts",
      "city" : "Glendale",
      "companyName" : "Fresh Donuts",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "oaklandstreetfoodco" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(510) 350-8587",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/OdmIlGgnJvbWcIESt0Q2KPer2JSKAqn7.png",
      "instagramHandle" : "oaklandstreetfoodco",
      "merchantHandle" : "oaklandstreetfoodco",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Oakland Street Food Co.",
      "city" : "Oakland",
      "companyName" : "Oakland Street Food Co.",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.oaklandstreetfood.com\/",
      "email" : ""
    }
  },
  "thedailybagel" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(541) 505-7803",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/351a9f4b23bd9d50b69342552a22937f03d7a8ff\/original.jpeg",
      "instagramHandle" : "thedailybagel",
      "merchantHandle" : "thedailybagel",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Daily Bagel",
      "city" : "Eugene",
      "companyName" : "The Daily Bagel",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.daily-bagel.com\/",
      "email" : ""
    }
  },
  "danangvietnameseeatery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(541) 915-0144",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/v5vUecQZx3IZDILPpAmL0ZCvkuiE9awa.png",
      "instagramHandle" : "danangvietnameseeatery",
      "merchantHandle" : "danangvietnameseeatery",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Da Nang Vietnamese Eatery",
      "city" : "Eugene",
      "companyName" : "Da Nang Vietnamese Eatery",
      "websiteUrl" : "https:\/\/www.danangeats.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "gopok" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(503) 602-2283",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/pAf1rmylMptWD9SZlzhwXJnfZqDUavfh.png",
      "instagramHandle" : "gopok",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "gopok",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "goPoké",
      "city" : "Portland",
      "companyName" : "goPoké",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/gopokeco.com\/",
      "email" : ""
    }
  },
  "themeltdown" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "themeltdown",
      "premium" : false,
      "merchantHandle" : "themeltdown",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Meltdown",
      "city" : "Long Beach",
      "companyName" : "The Meltdown",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/themeltdown.com\/?y_source=1_NDk0ODEwODAtNzE1LWxvY2F0aW9uLndlYnNpdGU%3D",
      "email" : ""
    }
  },
  "gaspachos" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(916) 882-8182",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/9c9e2e3515691321353fd901d070c69a8c6e7153\/original.jpeg",
      "instagramHandle" : "gaspachos",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "gaspachos",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Gaspachos",
      "city" : "Sacramento",
      "companyName" : "Gaspachos",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "happysmarket" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(562) 606-2968",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "happysmarket",
      "merchantHandle" : "happysmarket",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Happy's Market",
      "city" : "Long Beach",
      "companyName" : "Happy's Market",
      "websiteUrl" : "https:\/\/www.happysliquor.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "homies" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(503) 572-0425",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/4AyPeaOJaMWJynCgE30LcDFy1ospEyPW.png",
      "instagramHandle" : "homies",
      "premium" : false,
      "merchantHandle" : "homies",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Homies",
      "city" : "Vancouver",
      "companyName" : "Homies",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.homiespdx.com\/",
      "email" : ""
    }
  },
  "acopio" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(408) 293-5241",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/3b7a2491e4d73072eedaaa78164ab25bce9903d2\/original.png",
      "instagramHandle" : "acopio",
      "merchantHandle" : "acopio",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Acopio",
      "city" : "San Jose",
      "companyName" : "Acopio",
      "websiteUrl" : "http:\/\/www.acopiosj.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "townfarebychefmichelemcqueen" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(510) 318-8400",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/SkVIoidgLxvDC6A0d12caDu4gi7gQv4N.png",
      "instagramHandle" : "townfarebychefmichelemcqueen",
      "twitterHandle" : "",
      "merchantHandle" : "townfarebychefmichelemcqueen",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Town Fare by Chef Michele McQueen",
      "city" : "Oakland",
      "companyName" : "Town Fare by Chef Michele McQueen",
      "websiteUrl" : "https:\/\/townfarecafe.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "tincupcafecreamery" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(517) 258-0120",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/2580fd1bdbc006f5b4c7c792c42a9b32c4ee7cd9\/original.png",
      "instagramHandle" : "tincupcafecreamery",
      "merchantHandle" : "tincupcafecreamery",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Tin Cup Cafe & Creamery",
      "city" : "Lansing",
      "companyName" : "Tin Cup Cafe & Creamery",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/tincupcafecreamery.com\/",
      "email" : ""
    }
  },
  "prlnutritionusadietarysupplementsmanufacturer" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 630-3031",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/a9a445a915043e2c6e489be2eca6000e1d78bee3\/original.png",
      "instagramHandle" : "prlnutritionusadietarysupplementsmanufacturer",
      "merchantHandle" : "prlnutritionusadietarysupplementsmanufacturer",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "PRL Nutrition USA – Dietary Supplements Manufacturer",
      "city" : "Anaheim",
      "companyName" : "PRL Nutrition USA – Dietary Supplements Manufacturer",
      "websiteUrl" : "http:\/\/www.prlnutritionusa.com\/contact-us\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "miahotchkinsplace" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "miahotchkinsplace",
      "twitterHandle" : "",
      "merchantHandle" : "miahotchkinsplace",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Mia Hotchkin's Place",
      "city" : "Portland",
      "companyName" : "Mia Hotchkin's Place",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "fareastrestaurant" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(616) 531-7176",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "fareastrestaurant",
      "premium" : false,
      "merchantHandle" : "fareastrestaurant",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Far East Restaurant",
      "city" : "Grand Rapids",
      "companyName" : "Far East Restaurant",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.fareastgr.com\/",
      "email" : ""
    }
  },
  "sharinori" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/6BGnxL5VLjwHdqCMfkfuaINz80teC1HM.png",
      "instagramHandle" : "sharinori",
      "twitterHandle" : "",
      "merchantHandle" : "sharinori",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "ShariNori",
      "city" : "Santa Ana",
      "companyName" : "ShariNori",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.sharinori.com\/",
      "email" : ""
    }
  },
  "masullo" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 443-8929",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/c00837c73699573c61280f887f24403995d50741\/original.jpeg",
      "instagramHandle" : "masullo",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "masullo",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Masullo",
      "city" : "Sacramento",
      "companyName" : "Masullo",
      "websiteUrl" : "http:\/\/www.masullopizza.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "goldfishtea" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(248) 541-5252",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/owqkVE1iXLao0qFapheXVOBt07lT32q2.png",
      "instagramHandle" : "goldfishtea",
      "merchantHandle" : "goldfishtea",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Goldfish Tea",
      "city" : "Warren",
      "companyName" : "Goldfish Tea",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/order.goldfishtea.com\/",
      "email" : ""
    }
  },
  "youmecateringandevents" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(248) 795-5855",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "youmecateringandevents",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "youmecateringandevents",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "You + Me Catering and Events",
      "city" : "Sterling Heights",
      "companyName" : "You + Me Catering and Events",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.youplusmeevents.com\/",
      "email" : ""
    }
  },
  "hellacoastal" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 409-9765",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/X0J0JkELDWAQQLO03b3vLvPHK0Xvqeje.png",
      "instagramHandle" : "hellacoastal",
      "merchantHandle" : "hellacoastal",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Hella Coastal",
      "city" : "Oakland",
      "companyName" : "Hella Coastal",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.hellacoastal.com\/",
      "email" : ""
    }
  },
  "thegreatpnw" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(509) 315-5057",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/C0JUl4xAbzjvccAyqaPrCAaSgbe3o0W2.png",
      "instagramHandle" : "thegreatpnw",
      "premium" : false,
      "merchantHandle" : "thegreatpnw",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Great PNW",
      "city" : "Spokane",
      "companyName" : "The Great PNW",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/thegreatpnw.com\/",
      "email" : ""
    }
  },
  "loxstocksbagels" : {
    "info" : {
      "state" : "",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "loxstocksbagels",
      "instagramHandle" : " Stocks & Bagels\"",
      "premium" : false,
      "merchantHandle" : "loxstocksbagels",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "(541) 692-2435",
      "merchantName" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/4IDv3aMeQIsGzY9p6f4Bqyo4cny06djD.png",
      "city" : "",
      "companyName" : "\"Lox",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : "http:\/\/www.loxstocksandbagels.com\/"
    }
  },
  "sozocoffeeroasting" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 527-0100",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/t8qfF5iCYcg3zvAjLPMVDZuh8v8Jc1Bk.png",
      "instagramHandle" : "sozocoffeeroasting",
      "merchantHandle" : "sozocoffeeroasting",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sozo Coffee Roasting",
      "city" : "Lansing",
      "companyName" : "Sozo Coffee Roasting",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/sozocoffee.com\/",
      "email" : ""
    }
  },
  "markatogrocery" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(510) 338-3699",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/9eca78282727c6898d9948a2e15585b3037b91ee\/original.jpeg",
      "instagramHandle" : "markatogrocery",
      "merchantHandle" : "markatogrocery",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Markato Grocery",
      "city" : "Oakland",
      "companyName" : "Markato Grocery",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "thegoldeneatery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 681-6802",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/7QHr5jby2lwiqZzVV0tOd0EabfPNI7SO.png",
      "instagramHandle" : "thegoldeneatery",
      "merchantHandle" : "thegoldeneatery",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The golden eatery",
      "city" : "Santa Ana",
      "companyName" : "The golden eatery",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "raindanceorganicfarm" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Due87iX4DrmzhLtLyWQv7qaZjr6N1Ems.png",
      "instagramHandle" : "raindanceorganicfarm",
      "premium" : false,
      "merchantHandle" : "raindanceorganicfarm",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Raindance Organic Farm",
      "city" : "Ann Arbor",
      "companyName" : "Raindance Organic Farm",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.raindanceorganic.com\/",
      "email" : ""
    }
  },
  "flavortheory" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 977-9698",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/984183cc60f4584be40302599dc70ec8c70e3080\/original.jpeg",
      "instagramHandle" : "flavortheory",
      "merchantHandle" : "flavortheory",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Flavor Theory",
      "city" : "Riverside",
      "companyName" : "Flavor Theory",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.myflavortheory.com\/",
      "email" : ""
    }
  },
  "heretoservehospitality" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(888) 737-8308",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "heretoservehospitality",
      "merchantHandle" : "heretoservehospitality",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Here.To.Serve Hospitality",
      "city" : "Grand Rapids",
      "companyName" : "Here.To.Serve Hospitality",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.heretoserve.help\/",
      "email" : ""
    }
  },
  "cosmonautcoffeeco" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(253) 254-5052",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "cosmonautcoffeeco",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "cosmonautcoffeeco",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Cosmonaut Coffee Co",
      "city" : "Tacoma",
      "companyName" : "Cosmonaut Coffee Co",
      "websiteUrl" : "https:\/\/cosmonautcoffee.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sagedesigninnovationsllc" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(503) 734-6612",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/ee4181e8d014e24a0e4be1f3bf91830ce03a13c7\/original.jpeg",
      "instagramHandle" : "sagedesigninnovationsllc",
      "merchantHandle" : "sagedesigninnovationsllc",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Sage Design Innovations LLC",
      "city" : "Vancouver",
      "companyName" : "Sage Design Innovations LLC",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "lofi" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 942-9702",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/DDJCLRoHrB61ssjEGcLj4kruABZ1geuK.png",
      "instagramHandle" : "lofi",
      "merchantHandle" : "lofi",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Lo\/Fi",
      "city" : "Sacramento",
      "companyName" : "Lo\/Fi",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "andina" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 697-9160",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Fl71sjk6vOldqsF8dIwZFwoNV8yYyKLS.png",
      "instagramHandle" : "andina",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "andina",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Andina",
      "city" : "San Francisco",
      "companyName" : "Andina",
      "websiteUrl" : "https:\/\/andinasf.square.site\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "stocktongolfandcountryclub" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 466-4313",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "stocktongolfandcountryclub",
      "twitterHandle" : "",
      "merchantHandle" : "stocktongolfandcountryclub",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Stockton Golf and Country Club",
      "city" : "Stockton",
      "companyName" : "Stockton Golf and Country Club",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.stocktongolfcc.com\/",
      "email" : ""
    }
  },
  "reborncoffeeinchq" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 784-6369",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/OS1jIE6uqJvBadVL6xZRx5vFLfWVaFcS.png",
      "instagramHandle" : "reborncoffeeinchq",
      "merchantHandle" : "reborncoffeeinchq",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Reborn Coffee Inc. HQ",
      "city" : "Santa Ana",
      "companyName" : "Reborn Coffee Inc. HQ",
      "websiteUrl" : "https:\/\/www.reborncoffee.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "spokanecheesecakes" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(509) 570-0658",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/3515f3cf4a2f0217ca715be8aa1054c1d71e0bb3\/original.jpeg",
      "instagramHandle" : "spokanecheesecakes",
      "merchantHandle" : "spokanecheesecakes",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Spokane Cheesecakes",
      "city" : "Spokane",
      "companyName" : "Spokane Cheesecakes",
      "websiteUrl" : "http:\/\/www.spokanecheesecake.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "antoinescookieshopcampbell" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(408) 307-4192",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/4db5bf9e95ca2ce311e1781466b91fc044e317b9\/original.jpeg",
      "instagramHandle" : "antoinescookieshopcampbell",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "antoinescookieshopcampbell",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Antoine's Cookie Shop Campbell",
      "city" : "San Jose",
      "companyName" : "Antoine's Cookie Shop Campbell",
      "websiteUrl" : "http:\/\/www.antoinescookieshop.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "evergreenwinecellar" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(360) 993-8880",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/i3gyJXlNFUv7q83dCD8UogxnKYyoF39L.png",
      "instagramHandle" : "evergreenwinecellar",
      "twitterHandle" : "",
      "merchantHandle" : "evergreenwinecellar",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Evergreen Wine Cellar",
      "city" : "Vancouver",
      "companyName" : "Evergreen Wine Cellar",
      "websiteUrl" : "http:\/\/www.evergreenwinecellar.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "redoliverestaurantwarren" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(586) 806-6330",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/b27193d52895d8d465066ca3c3c224fbb948beff\/original.jpeg",
      "instagramHandle" : "redoliverestaurantwarren",
      "merchantHandle" : "redoliverestaurantwarren",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Red Olive Restaurant - Warren",
      "city" : "Warren",
      "companyName" : "Red Olive Restaurant - Warren",
      "websiteUrl" : "http:\/\/redoliverestaurant.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "roundtablepizza" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(360) 892-0450",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/8Cae7eAqiaw511uiClTMUi0PqtQlotTi.png",
      "instagramHandle" : "roundtablepizza",
      "premium" : false,
      "merchantHandle" : "roundtablepizza",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Round Table Pizza",
      "city" : "Vancouver",
      "companyName" : "Round Table Pizza",
      "websiteUrl" : "https:\/\/www.roundtablepizza.com\/location\/1233?utm_source=thatsbiz&utm_medium=gmb",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "camelliacoffeeroasters" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/3szem8tGNzL1H4M5VPfcegUl9LuKNuwq.png",
      "instagramHandle" : "camelliacoffeeroasters",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "camelliacoffeeroasters",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Camellia Coffee Roasters",
      "city" : "Sacramento",
      "companyName" : "Camellia Coffee Roasters",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/camelliacoffeeroasters.com\/",
      "email" : ""
    }
  },
  "hulagirlsshaveice" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(949) 295-0292",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/ecae349f95f6c17be43e883313455432598342b8\/original.jpeg",
      "instagramHandle" : "hulagirlsshaveice",
      "merchantHandle" : "hulagirlsshaveice",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Hula Girls Shave Ice",
      "city" : "Huntington Beach",
      "companyName" : "Hula Girls Shave Ice",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.hulagirlsshaveice.com\/",
      "email" : ""
    }
  },
  "berlins" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(323) 746-5409",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Ar5GEuQYve5ULxvaOGI90J855EyMAucd.png",
      "instagramHandle" : "berlins",
      "premium" : false,
      "merchantHandle" : "berlins",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "BERLINS",
      "city" : "Oxnard",
      "companyName" : "BERLINS",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.eatberlins.com\/",
      "email" : ""
    }
  },
  "lupitasidealbeautylounge" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(313) 554-2332",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "lupitasidealbeautylounge",
      "twitterHandle" : "",
      "merchantHandle" : "lupitasidealbeautylounge",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Lupita's Ideal Beauty Lounge",
      "city" : "Detroit",
      "companyName" : "Lupita's Ideal Beauty Lounge",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "ladogs" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(213) 249-0138",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/BsxkRkKIJa5ao2TGBePWia6NOqHEWGMF.png",
      "instagramHandle" : "ladogs",
      "twitterHandle" : "",
      "merchantHandle" : "ladogs",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "LA Dogs",
      "city" : "Santa Ana",
      "companyName" : "LA Dogs",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "northstartaps" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(509) 498-4052",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/g5m0m4CTpijPefU8SOj8WzuWLRnnhcjf.png",
      "instagramHandle" : "northstartaps",
      "merchantHandle" : "northstartaps",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "NorthStar Taps",
      "city" : "Spokane",
      "companyName" : "NorthStar Taps",
      "websiteUrl" : "http:\/\/northstartaps.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "trackbrewingcompanycurtispark" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 520-4677",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/G6lOOho74wQGqgxwKRT4uTfs2h9mjV7a.png",
      "instagramHandle" : "trackbrewingcompanycurtispark",
      "twitterHandle" : "",
      "merchantHandle" : "trackbrewingcompanycurtispark",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Track 7 Brewing Company - Curtis Park",
      "city" : "Sacramento",
      "companyName" : "Track 7 Brewing Company - Curtis Park",
      "websiteUrl" : "http:\/\/www.track7brewingco.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "kimsdonutscaf" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(951) 221-1553",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/d86822a588501e45ce12490c20f102f2890c2ece\/original.png",
      "instagramHandle" : "kimsdonutscaf",
      "merchantHandle" : "kimsdonutscaf",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Kim’s donuts & Café",
      "city" : "Moreno Valley",
      "companyName" : "Kim’s donuts & Café",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "bethelchurch" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(949) 854-4010",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/m40YUcclvVuPankwJhmctYpJedFuOGp2.png",
      "instagramHandle" : "bethelchurch",
      "merchantHandle" : "bethelchurch",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Bethel Church",
      "city" : "Irvine",
      "companyName" : "Bethel Church",
      "websiteUrl" : "http:\/\/bkc.org\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "marilyndrive" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/FXbs9hkiRooE0G2npGyOaeV270kPWpPG.png",
      "instagramHandle" : "marilyndrive",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "marilyndrive",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Marilyn Drive",
      "city" : "Warren",
      "companyName" : "Marilyn Drive",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "hellosugarkendallyards" : {
    "info" : {
      "state" : "",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "hellosugarkendallyards",
      "instagramHandle" : " Kendall Yards\"",
      "premium" : false,
      "merchantHandle" : "hellosugarkendallyards",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "(509) 868-0475",
      "merchantName" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/lazZmv17HfKLTWiG6ccF8Y5gHXXxTiN6.png",
      "city" : "",
      "companyName" : "\"Hello Sugar",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : "https:\/\/www.orderhellosugar.com\/"
    }
  },
  "biniskitchenllc" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 361-6911",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/zzIcymyzxjKFaLzSqPHaavxcSTnmLkYN.png",
      "instagramHandle" : "biniskitchenllc",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "biniskitchenllc",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Bini's Kitchen LLC",
      "city" : "Stockton",
      "companyName" : "Bini's Kitchen LLC",
      "websiteUrl" : "https:\/\/biniskitchen.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "detroitsushi" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(313) 474-3477",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/FYEs9DmuF0r1G73jxkqqhx6q2SmWIPah.png",
      "instagramHandle" : "detroitsushi",
      "merchantHandle" : "detroitsushi",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Detroit Sushi",
      "city" : "Detroit",
      "companyName" : "Detroit Sushi",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "leoskitchen" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(619) 271-2731",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/NLHic6InCAyxuVQR7ECHm0m4QdI5jFFB.png",
      "instagramHandle" : "leoskitchen",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "leoskitchen",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Leo's Kitchen",
      "city" : "Chula Vista",
      "companyName" : "Leo's Kitchen",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/leoskitchen.net\/",
      "email" : ""
    }
  },
  "matterofcraftgastropub" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 587-9002",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/93stDQUZxieHqasP6q2UYVaQ5CAoBt0Z.png",
      "instagramHandle" : "matterofcraftgastropub",
      "merchantHandle" : "matterofcraftgastropub",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Matter of Craft Gastropub",
      "city" : "Huntington Beach",
      "companyName" : "Matter of Craft Gastropub",
      "websiteUrl" : "http:\/\/www.matter-of-craft.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "estradasmexicangrill" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 799-0977",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "estradasmexicangrill",
      "twitterHandle" : "",
      "merchantHandle" : "estradasmexicangrill",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "ESTRADA'S MEXICAN GRILL",
      "city" : "Huntington Beach",
      "companyName" : "ESTRADA'S MEXICAN GRILL",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.estradascafe.com\/",
      "email" : ""
    }
  },
  "heistrestaurant" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 525-3078",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/e8bc61a8b9a5f2b7df6b9054da8463047ae087a5\/original.png",
      "instagramHandle" : "heistrestaurant",
      "twitterHandle" : "",
      "merchantHandle" : "heistrestaurant",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Heist Restaurant",
      "city" : "San Francisco",
      "companyName" : "Heist Restaurant",
      "websiteUrl" : "http:\/\/heistsf.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "cafeeden" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(408) 320-2957",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/J3Ot4uFtm6XlbOr6eYuClcFj9D86ah1h.png",
      "instagramHandle" : "cafeeden",
      "merchantHandle" : "cafeeden",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Cafe Eden",
      "city" : "San Jose",
      "companyName" : "Cafe Eden",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "heroorvillaindelihq" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(586) 894-8665",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/lCdW1POC0NF0kt4MXdg484nA5eiCdCPi.png",
      "instagramHandle" : "heroorvillaindelihq",
      "merchantHandle" : "heroorvillaindelihq",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Hero Or Villain Deli: HQ",
      "city" : "Warren",
      "companyName" : "Hero Or Villain Deli: HQ",
      "websiteUrl" : "http:\/\/www.heroorvillaindeli.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "windsorcakeemporium" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(519) 919-4200",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/d87dee20af19195e2c4bb81654c05c45c5e66c7b\/original.jpeg",
      "instagramHandle" : "windsorcakeemporium",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "windsorcakeemporium",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Windsor Cake Emporium",
      "city" : "Detroit",
      "companyName" : "Windsor Cake Emporium",
      "websiteUrl" : "https:\/\/windsorcakeemporium.square.site\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "lartesanocafejuicebar" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(313) 903-2857",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/6c3a4002cb772242b73df4bb57af5dbde704b5e8\/original.png",
      "instagramHandle" : "lartesanocafejuicebar",
      "merchantHandle" : "lartesanocafejuicebar",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "L' ArteSano Cafe & Juice Bar",
      "city" : "Detroit",
      "companyName" : "L' ArteSano Cafe & Juice Bar",
      "websiteUrl" : "https:\/\/www.facebook.com\/artesanojuicebar\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "capitolfoodsinc" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(313) 491-3375",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/RvDEPtOKQYpduW3aDMFl6BiwHG5pPzK4.png",
      "instagramHandle" : "capitolfoodsinc",
      "twitterHandle" : "",
      "merchantHandle" : "capitolfoodsinc",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Capitol Foods Inc",
      "city" : "Detroit",
      "companyName" : "Capitol Foods Inc",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "whatupdoughpizzeria" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(586) 991-5805",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/337fa077336725eab7f0d82b22461303\/original.png",
      "instagramHandle" : "whatupdoughpizzeria",
      "merchantHandle" : "whatupdoughpizzeria",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "WHAT UP DOUGH PIZZERIA",
      "city" : "Warren",
      "companyName" : "WHAT UP DOUGH PIZZERIA",
      "websiteUrl" : "http:\/\/whatupdoughpizza.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "fermenschkombucha" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 588-8751",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/7cbc104774139710a37059103df77a74a076ae3b\/original.png",
      "instagramHandle" : "fermenschkombucha",
      "twitterHandle" : "",
      "merchantHandle" : "fermenschkombucha",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Fermensch Kombucha",
      "city" : "Santa Ana",
      "companyName" : "Fermensch Kombucha",
      "websiteUrl" : "http:\/\/www.fermensch.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "enchantations" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(408) 508-5900",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/9ba390fc8013f0e146c54f52bda74d09\/original.jpeg",
      "instagramHandle" : "enchantations",
      "premium" : false,
      "merchantHandle" : "enchantations",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Enchantations",
      "city" : "San Jose",
      "companyName" : "Enchantations",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "smallcakescupcakeryandcreamery" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 253-6469",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/bf77a5da062e3955c35310677fe50fef2222025e\/original.jpeg",
      "instagramHandle" : "smallcakescupcakeryandcreamery",
      "merchantHandle" : "smallcakescupcakeryandcreamery",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Smallcakes Cupcakery And Creamery",
      "city" : "Stockton",
      "companyName" : "Smallcakes Cupcakery And Creamery",
      "websiteUrl" : "https:\/\/www.smallcakescupcakery.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sewellsweets" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(971) 287-7171",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/jOtB6ihA3KHDKZ830gyDHA1SqmY4xFCA.png",
      "instagramHandle" : "sewellsweets",
      "merchantHandle" : "sewellsweets",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sewell Sweets",
      "city" : "Salem",
      "companyName" : "Sewell Sweets",
      "websiteUrl" : "http:\/\/www.sewellsweets.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "hutkayfusion" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(734) 786-8312",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/ec32db239e046bbe6dfd22d69bfda998a54d179e\/original.jpeg",
      "instagramHandle" : "hutkayfusion",
      "merchantHandle" : "hutkayfusion",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Hutkay Fusion",
      "city" : "Ann Arbor",
      "companyName" : "Hutkay Fusion",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "teababy" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(503) 863-6223",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/d196c08561ba7a26e22683930ef174f0c43fb225\/original.jpeg",
      "instagramHandle" : "teababy",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "teababy",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Tea Baby",
      "city" : "Eugene",
      "companyName" : "Tea Baby",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thetradecoffeecoworking" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 538-6878",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Zn6hXaPsg6OTMTGEEWdtLda0GDQHNk5L.png",
      "instagramHandle" : "thetradecoffeecoworking",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "thetradecoffeecoworking",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Trade Coffee & Coworking",
      "city" : "Sacramento",
      "companyName" : "The Trade Coffee & Coworking",
      "websiteUrl" : "http:\/\/thetradecollab.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "snoice" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(619) 432-5735",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/5d6dcd87380881ba62fef3375ba6c54c\/original.png",
      "instagramHandle" : "snoice",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "snoice",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Snoice",
      "city" : "Chula Vista",
      "companyName" : "Snoice",
      "websiteUrl" : "http:\/\/www.snoicesd.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "ketocandygirltustin" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 884-3816",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/KKPdyoRJiUfQf7IaiRuul8rfjB9Lepav.png",
      "instagramHandle" : "ketocandygirltustin",
      "merchantHandle" : "ketocandygirltustin",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Keto Candy Girl Tustin",
      "city" : "Irvine",
      "companyName" : "Keto Candy Girl Tustin",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.ketocandygirl.com\/",
      "email" : ""
    }
  },
  "thedogpit" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 988-1508",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/B1A1MtCzbdjfbqG6dMnxwaplNkSyFSdm.png",
      "instagramHandle" : "thedogpit",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "thedogpit",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Dog Pit",
      "city" : "Grand Rapids",
      "companyName" : "The Dog Pit",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "clementebakingcompany" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 585-8610",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/sa1AbkysP1NmOcM8CNaMx9GK4zGBbz6q.png",
      "instagramHandle" : "clementebakingcompany",
      "premium" : false,
      "merchantHandle" : "clementebakingcompany",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Clemente Baking Company",
      "city" : "Oxnard",
      "companyName" : "Clemente Baking Company",
      "websiteUrl" : "https:\/\/clementebaking.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "taloolacaf" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(519) 254-6652",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/9765145b142ba69a4a5a07c7d26c1e6788cd0be7\/original.png",
      "instagramHandle" : "taloolacaf",
      "merchantHandle" : "taloolacaf",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Taloola Café",
      "city" : "Detroit",
      "companyName" : "Taloola Café",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/taloolacafe.com\/",
      "email" : ""
    }
  },
  "capitalinvestment" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(562) 420-9009",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/3a87bb5f69a96d6d195af62e270d53767fa4ec54\/original.png",
      "instagramHandle" : "capitalinvestment",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "capitalinvestment",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Capital Investment",
      "city" : "Long Beach",
      "companyName" : "Capital Investment",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.ciadvisers.com\/",
      "email" : ""
    }
  },
  "thesilvershamrocks" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(248) 621-4455",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/k8nP56Ghck9G4X3QclsFH8oXs3BA0Zxh.png",
      "instagramHandle" : "thesilvershamrocks",
      "twitterHandle" : "",
      "merchantHandle" : "thesilvershamrocks",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Silver Shamrocks",
      "city" : "Sterling Heights",
      "companyName" : "The Silver Shamrocks",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.thesilvershamrocks.com\/",
      "email" : ""
    }
  },
  "dublinranchgolfcourse" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(925) 556-7040",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/maMAio6UaRlC4zyqvy6PyVcsp64Rl403.png",
      "instagramHandle" : "dublinranchgolfcourse",
      "merchantHandle" : "dublinranchgolfcourse",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Dublin Ranch Golf Course",
      "city" : "Stockton",
      "companyName" : "Dublin Ranch Golf Course",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.dublinranchgolf.com\/",
      "email" : ""
    }
  },
  "playbig" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(810) 820-9084",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/PiuqgOHbv0CAjOBbxfZ6tgrgkwFxFcyG.png",
      "instagramHandle" : "playbig",
      "merchantHandle" : "playbig",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Play Big",
      "city" : "Flint",
      "companyName" : "Play Big",
      "websiteUrl" : "http:\/\/www.playbigmi.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "terrasagehome" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(562) 580-9620",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/VLtS9d5PMxsDnSWuUiJEdsxauR01e9s5.png",
      "instagramHandle" : "terrasagehome",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "terrasagehome",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Terrasage Home",
      "city" : "Long Beach",
      "companyName" : "Terrasage Home",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "malonestavern" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(586) 978-0055",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "malonestavern",
      "merchantHandle" : "malonestavern",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Malone's Tavern",
      "city" : "Warren",
      "companyName" : "Malone's Tavern",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.malonestavern.com\/",
      "email" : ""
    }
  },
  "thecornertwistpretzelco" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/E1bJxX8hW3EmGRVHXzJcJmaOK1p8oLwr.jpeg",
      "instagramHandle" : "thecornertwistpretzelco",
      "merchantHandle" : "thecornertwistpretzelco",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Corner Twist Pretzel Co",
      "city" : "Grand Rapids",
      "companyName" : "The Corner Twist Pretzel Co",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/thecornertwist.com\/",
      "email" : ""
    }
  },
  "jojoshotchicken" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(408) 449-0916",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/781905d6f1607b601ffd01dd5d0d9b3ecca169df\/original.jpeg",
      "instagramHandle" : "jojoshotchicken",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "jojoshotchicken",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Jojo’s Hot Chicken",
      "city" : "San Jose",
      "companyName" : "Jojo’s Hot Chicken",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/jojoshotchicken.com\/",
      "email" : ""
    }
  },
  "aladdincafemediterraneancuisine" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/9bbcd3d092cb1cd03606bcb09b2a857f2a7dcde5\/original.jpeg",
      "instagramHandle" : "aladdincafemediterraneancuisine",
      "merchantHandle" : "aladdincafemediterraneancuisine",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "aladdin cafe mediterranean cuisine",
      "city" : "Vancouver",
      "companyName" : "aladdin cafe mediterranean cuisine",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "soupandsuchshilohcrossing" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MT\r",
      "phone" : "(406) 969-1403",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/spjYAnRubyZRhdIXGbVlbafUjkt2G8TY.png",
      "instagramHandle" : "soupandsuchshilohcrossing",
      "merchantHandle" : "soupandsuchshilohcrossing",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Soup and Such Shiloh Crossing",
      "city" : "Billings",
      "companyName" : "Soup and Such Shiloh Crossing",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/soupandsuch.net\/",
      "email" : ""
    }
  },
  "coffeenaturehuntingtonbeach" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(657) 259-0144",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/7YpdpTOffqCPgAjVjtB1cR4L1V4H9CbN.png",
      "instagramHandle" : "coffeenaturehuntingtonbeach",
      "merchantHandle" : "coffeenaturehuntingtonbeach",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Coffee Nature Huntington Beach",
      "city" : "Huntington Beach",
      "companyName" : "Coffee Nature Huntington Beach",
      "websiteUrl" : "https:\/\/coffeenaturehb.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "streycellars" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(805) 988-1087",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "streycellars",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "streycellars",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Strey Cellars",
      "city" : "Oxnard",
      "companyName" : "Strey Cellars",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.streycellars.com\/",
      "email" : ""
    }
  },
  "redbarcoffee" : {
    "analytics" : {
      "records" : {
        "landing-page" : {
          "m2adCWat" : {
            "pathname" : "\/redbarcoffee",
            "time" : "2024-05-10T16:11:42.969Z",
            "merchantHandle" : "redbarcoffee",
            "host" : "cafe.bio",
            "timezone" : "America\/New_York",
            "userAgent" : "Mozilla\/5.0 (iPhone; CPU iPhone OS 16_3 like Mac OS X) AppleWebKit\/605.1.15 (KHTML, like Gecko) Version\/16.3 Mobile\/15E148 Safari\/604.1",
            "page" : "landing-page\/"
          },
          "kEMQyMWt" : {
            "pathname" : "\/redbarcoffee",
            "time" : "2024-04-02T14:09:50.895Z",
            "merchantHandle" : "redbarcoffee",
            "host" : "cafe.bio",
            "timezone" : "Asia\/Saigon",
            "userAgent" : "Mozilla\/5.0 (Linux; Android 10; K) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/117.0.0.0 Mobile Safari\/537.36",
            "page" : "landing-page\/"
          },
          "cc63SeX3" : {
            "pathname" : "\/redbarcoffee",
            "time" : "2024-04-02T14:09:48.424Z",
            "merchantHandle" : "redbarcoffee",
            "host" : "cafe.bio",
            "timezone" : "Asia\/Saigon",
            "userAgent" : "Mozilla\/5.0 (Linux; Android 10; K) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/117.0.0.0 Mobile Safari\/537.36",
            "page" : "landing-page\/"
          }
        }
      }
    }
  },
  "locopoke" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 625-8739",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/0eKIVA2u4zBgH3o8oZRVDEx4Ya0X3BO4.png",
      "instagramHandle" : "locopoke",
      "twitterHandle" : "",
      "merchantHandle" : "locopoke",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Loco Poke",
      "city" : "Stockton",
      "companyName" : "Loco Poke",
      "websiteUrl" : "http:\/\/www.locopokelodi.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "honeyboxbakersfield" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "honeyboxbakersfield",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "honeyboxbakersfield",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "HoneyBox Bakersfield",
      "city" : "Bakersfield",
      "companyName" : "HoneyBox Bakersfield",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "carries" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 763-3345",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/3sR3AtvJMaN0Tpf4RifaKSvgH4sj5WBJ.jpeg",
      "instagramHandle" : "carries",
      "merchantHandle" : "carries",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Carrie’s",
      "city" : "San Bernardino",
      "companyName" : "Carrie’s",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "theepicureantrader" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(888) 504-8118",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/fcbbeca53a5bb245d5883b8bc4c5a5e108c869e4\/original.png",
      "instagramHandle" : "theepicureantrader",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "theepicureantrader",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Epicurean Trader",
      "city" : "San Francisco",
      "companyName" : "The Epicurean Trader",
      "websiteUrl" : "http:\/\/www.theepicureantrader.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "crumblecateringcoresidential" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(248) 302-5456",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/dc5895c23753b91119d339395f4ea66a7ad06f34\/original.jpeg",
      "instagramHandle" : "crumblecateringcoresidential",
      "twitterHandle" : "",
      "merchantHandle" : "crumblecateringcoresidential",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Crumble Catering Co. (Residential)",
      "city" : "Warren",
      "companyName" : "Crumble Catering Co. (Residential)",
      "websiteUrl" : "http:\/\/www.crumblecateringco.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "boomcrepes" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "boomcrepes",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "boomcrepes",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Boom Crepes",
      "city" : "Portland",
      "companyName" : "Boom Crepes",
      "websiteUrl" : "https:\/\/www.facebook.com\/boomcrepe\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "michiganmoonshinedistillery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 259-1000",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "michiganmoonshinedistillery",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "michiganmoonshinedistillery",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Michigan Moonshine Distillery",
      "city" : "Grand Rapids",
      "companyName" : "Michigan Moonshine Distillery",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/michiganmoonshine.us\/",
      "email" : ""
    }
  },
  "homegrownsmokerveganbbq" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(503) 277-3823",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/92c179744d1ad4fb07fcc40f217ec6b24435cc36\/original.jpeg",
      "instagramHandle" : "homegrownsmokerveganbbq",
      "merchantHandle" : "homegrownsmokerveganbbq",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Homegrown Smoker Vegan BBQ",
      "city" : "Vancouver",
      "companyName" : "Homegrown Smoker Vegan BBQ",
      "websiteUrl" : "http:\/\/www.homegrownsmoker.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "omasanfranciscostation" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "omasanfranciscostation",
      "merchantHandle" : "omasanfranciscostation",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Oma San Francisco Station",
      "city" : "San Francisco",
      "companyName" : "Oma San Francisco Station",
      "websiteUrl" : "http:\/\/www.omasfstation.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "westmichiganfarmlink" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 318-7395",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Jaf50CfNeqqFuwYZA3CGHAjTxer0aoth.png",
      "instagramHandle" : "westmichiganfarmlink",
      "merchantHandle" : "westmichiganfarmlink",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "West Michigan FarmLink",
      "city" : "Grand Rapids",
      "companyName" : "West Michigan FarmLink",
      "websiteUrl" : "http:\/\/wmfarmlink.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "forloveofspice" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(253) 858-0272",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/3cef36d98b15e68a70733e456a8362e4\/original.jpeg",
      "instagramHandle" : "forloveofspice",
      "merchantHandle" : "forloveofspice",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "For the Love of Spice",
      "city" : "Tacoma",
      "companyName" : "For the Love of Spice",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/fortheloveofspice.com\/",
      "email" : ""
    }
  },
  "fontanapho" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 829-1571",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/425c9f824fe2719ab9705dd753778c6e295d3ffe\/original.png",
      "instagramHandle" : "fontanapho",
      "premium" : false,
      "merchantHandle" : "fontanapho",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Fontana Pho",
      "city" : "Fontana",
      "companyName" : "Fontana Pho",
      "websiteUrl" : "https:\/\/fontanapho.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "themixsalon" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(509) 315-4651",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/CSQ7xOQ3JSsiSUnKJ9M7glBF4HpjC04I.png",
      "instagramHandle" : "themixsalon",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "themixsalon",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Mix Salon",
      "city" : "Spokane",
      "companyName" : "The Mix Salon",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/themixsalonandspa.com\/",
      "email" : ""
    }
  },
  "chteaucoffeeco" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(517) 580-4913",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/e747bcdd0aa67a143153d70fdacf2438d8767ccb\/original.jpeg",
      "instagramHandle" : "chteaucoffeeco",
      "twitterHandle" : "",
      "merchantHandle" : "chteaucoffeeco",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Château Coffee Co.",
      "city" : "Lansing",
      "companyName" : "Château Coffee Co.",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.chateaucoffeeco.com\/",
      "email" : ""
    }
  },
  "bbmarket" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(503) 914-3911",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/LVRWkwlbRajstYRgi2pacSSBvvDFqZ7T.png",
      "instagramHandle" : "bbmarket",
      "twitterHandle" : "",
      "merchantHandle" : "bbmarket",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "BB Market",
      "city" : "Portland",
      "companyName" : "BB Market",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/breakbreadpdx.com\/market",
      "email" : ""
    }
  },
  "tacomasa" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(562) 263-4629",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/pEjt0C6M0uNp3priGW9vH1mQrafTjTTN.png",
      "instagramHandle" : "tacomasa",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "tacomasa",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Tacomasa",
      "city" : "Long Beach",
      "companyName" : "Tacomasa",
      "websiteUrl" : "https:\/\/www.tacomasa.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "leonscustommeatcutting" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 685-5208",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/3sR3AtvJMaN0Tpf4RifaKSvgH4sj5WBJ.jpeg",
      "instagramHandle" : "leonscustommeatcutting",
      "merchantHandle" : "leonscustommeatcutting",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Leon's Custom Meat Cutting",
      "city" : "Riverside",
      "companyName" : "Leon's Custom Meat Cutting",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "outaboutburgers" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(425) 923-4259",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/2ede4e0ecc6ecc5b59cf039ada296b429b34e1af\/original.jpeg",
      "instagramHandle" : "outaboutburgers",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "outaboutburgers",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Out & About Burgers",
      "city" : "Tacoma",
      "companyName" : "Out & About Burgers",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.outandaboutburgers.com\/",
      "email" : ""
    }
  },
  "bowlerofontana" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 822-9900",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/EQXMWN9Gs0ioGHikzUkgN1vWNalRzkH4.png",
      "instagramHandle" : "bowlerofontana",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "bowlerofontana",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Bowlero Fontana",
      "city" : "Fontana",
      "companyName" : "Bowlero Fontana",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.bowlero.com\/location\/bowlero-fontana\/",
      "email" : ""
    }
  },
  "skewers" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(509) 456-5656",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/QNEYW1FFUnPxe8kUbRoivaWh0eUpR1IR.png",
      "instagramHandle" : "skewers",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "skewers",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Skewers",
      "city" : "Spokane",
      "companyName" : "Skewers",
      "websiteUrl" : "http:\/\/skewersspokane.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "bbsistockton" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 476-0100",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "bbsistockton",
      "premium" : false,
      "merchantHandle" : "bbsistockton",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "BBSI Stockton",
      "city" : "Stockton",
      "companyName" : "BBSI Stockton",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.bbsi.com\/locations\/stockton",
      "email" : ""
    }
  },
  "longbeachcoffeeandtea" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(562) 619-8299",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Kv7az3gr249mQTnYv3UskLkax6zrUIz0.png",
      "instagramHandle" : "longbeachcoffeeandtea",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "longbeachcoffeeandtea",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Long Beach Coffee and Tea",
      "city" : "Long Beach",
      "companyName" : "Long Beach Coffee and Tea",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "pekoe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(408) 818-0696",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/YUN6uq0Vasr9mP72cYtxwdbcQwwiQtLR.png",
      "instagramHandle" : "pekoe",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "pekoe",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Pekoe",
      "city" : "San Jose",
      "companyName" : "Pekoe",
      "websiteUrl" : "https:\/\/pekoeteabar.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "oxtiger" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 992-3827",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/yrUausQPYW2gyNHgaXXCJqVaAjUqPiGk.png",
      "instagramHandle" : "oxtiger",
      "premium" : false,
      "merchantHandle" : "oxtiger",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Ox & Tiger",
      "city" : "San Francisco",
      "companyName" : "Ox & Tiger",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.oxandtiger.co\/",
      "email" : ""
    }
  },
  "fireupgrill" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 289-9071",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/PloGKNXoKkia7gTtHVDqDbUWUHi4niIh.png",
      "instagramHandle" : "fireupgrill",
      "premium" : false,
      "merchantHandle" : "fireupgrill",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Fire Up Grill",
      "city" : "Riverside",
      "companyName" : "Fire Up Grill",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.fireupgrillca.com\/",
      "email" : ""
    }
  },
  "gracecoffeeroasters" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(916) 706-1200",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/YG5ER9sRCeKYT5nr0nXqlkqqebaXgcii.png",
      "instagramHandle" : "gracecoffeeroasters",
      "merchantHandle" : "gracecoffeeroasters",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Grace Coffee Roasters",
      "city" : "Sacramento",
      "companyName" : "Grace Coffee Roasters",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.gracecoffeeroasters.co\/",
      "email" : ""
    }
  },
  "holycannolicafe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(408) 561-2223",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/K9LUHSLNFqagPqdvkZwDXjoQ2HnEL4i4.png",
      "instagramHandle" : "holycannolicafe",
      "merchantHandle" : "holycannolicafe",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Holy Cannoli! Cafe",
      "city" : "San Jose",
      "companyName" : "Holy Cannoli! Cafe",
      "websiteUrl" : "http:\/\/www.holycannolisj.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "nossafamiliacoffee" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(541) 304-9234",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/RlcPYqj0Lv0bFYfLSUqTOvNYp6LSioGf.png",
      "instagramHandle" : "nossafamiliacoffee",
      "merchantHandle" : "nossafamiliacoffee",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Nossa Familia Coffee",
      "city" : "Portland",
      "companyName" : "Nossa Familia Coffee",
      "websiteUrl" : "http:\/\/nossacoffee.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "littleconejo" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(360) 718-2633",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/OazVHoInjiIvaZvQZfPL1gZBdKmTtHWH.png",
      "instagramHandle" : "littleconejo",
      "twitterHandle" : "",
      "merchantHandle" : "littleconejo",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Little Conejo",
      "city" : "Vancouver",
      "companyName" : "Little Conejo",
      "websiteUrl" : "http:\/\/www.littleconejo.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "nomikai" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(408) 287-7199",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/baai0wh2anlrU00qgvm6O2PcrgcAPDxX.png",
      "instagramHandle" : "nomikai",
      "merchantHandle" : "nomikai",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Nomikai",
      "city" : "San Jose",
      "companyName" : "Nomikai",
      "websiteUrl" : "http:\/\/www.nomikaisj.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "pressocoffeeco" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(360) 869-9897",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/zvqc3wSDMh0rY6auT2UDz0aI05WKkh02.png",
      "instagramHandle" : "pressocoffeeco",
      "premium" : false,
      "merchantHandle" : "pressocoffeeco",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Presso Coffee Co.",
      "city" : "Vancouver",
      "companyName" : "Presso Coffee Co.",
      "websiteUrl" : "https:\/\/pressocoffee.co\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "rivermaiden" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(360) 694-7500",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "rivermaiden",
      "premium" : false,
      "merchantHandle" : "rivermaiden",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "River Maiden",
      "city" : "Vancouver",
      "companyName" : "River Maiden",
      "websiteUrl" : "http:\/\/rivermaiden.online\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "tonscafe" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(541) 485-2700",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/pnOndx0Bo9vqa8zBfONVXEfpiQbZyiMm.png",
      "instagramHandle" : "tonscafe",
      "merchantHandle" : "tonscafe",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "16 Tons Cafe",
      "city" : "Eugene",
      "companyName" : "16 Tons Cafe",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.facebook.com\/16TonsCafe\/about",
      "email" : ""
    }
  },
  "simplyessentialmassagebodyworks" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(586) 991-1759",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/2529208cc038bfa59c3b376817fd2d217ede7b3b\/original.jpeg",
      "instagramHandle" : "simplyessentialmassagebodyworks",
      "merchantHandle" : "simplyessentialmassagebodyworks",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Simply Essential Massage & Bodyworks",
      "city" : "Sterling Heights",
      "companyName" : "Simply Essential Massage & Bodyworks",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/sembodyworks.com\/",
      "email" : ""
    }
  },
  "thehealingchef" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 349-3894",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/785c6aed692d45aeab716a64c0d055f73c8aa759\/original.jpeg",
      "instagramHandle" : "thehealingchef",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "thehealingchef",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Healing Chef",
      "city" : "Santa Ana",
      "companyName" : "The Healing Chef",
      "websiteUrl" : "http:\/\/www.healingchef.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "battlelabairsoftfield" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 395-0055",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/qWv6QtXIa1ygROaogVi0nbdtQp7eOsyV.png",
      "instagramHandle" : "battlelabairsoftfield",
      "twitterHandle" : "",
      "merchantHandle" : "battlelabairsoftfield",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Battle Lab Airsoft Field",
      "city" : "San Bernardino",
      "companyName" : "Battle Lab Airsoft Field",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/battle-lab.com\/",
      "email" : ""
    }
  },
  "thamfnvegan" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 999-8869",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/a2jIzc6Pk6axtttTjy305CUeZ6RGwiAQ.png",
      "instagramHandle" : "thamfnvegan",
      "merchantHandle" : "thamfnvegan",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Tha MF'n Vegan",
      "city" : "Oakland",
      "companyName" : "Tha MF'n Vegan",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thepleasureprinciplesupperclubdiningevents" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 640-6259",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/aO3VoHeFUxxrqdcARErn2oXvj08oyXCD.png",
      "instagramHandle" : "thepleasureprinciplesupperclubdiningevents",
      "merchantHandle" : "thepleasureprinciplesupperclubdiningevents",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Pleasure Principle Supperclub & Dining Events",
      "city" : "Oakland",
      "companyName" : "The Pleasure Principle Supperclub & Dining Events",
      "websiteUrl" : "http:\/\/www.pleasureprinciplediningevents.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "21cellars" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/75e8c08be442b8622833617c3ead0777424a3e2f\/original.jpeg",
      "instagramHandle" : "21cellars",
      "premium" : false,
      "merchantHandle" : "21cellars",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "21Cellars",
      "city" : "Tacoma",
      "companyName" : "21Cellars",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "nicolesspa" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 919-0059",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/mdK3FvyQ1BL8Xe0wXutTIE2PpfwXtdzD.png",
      "instagramHandle" : "nicolesspa",
      "merchantHandle" : "nicolesspa",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Nicole's Spa",
      "city" : "Oxnard",
      "companyName" : "Nicole's Spa",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "menchiesfrozenyogurt" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(559) 412-2444",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/zdaBL0rYuFy7F0ttxW1zS0zLLwQgytsU.png",
      "instagramHandle" : "menchiesfrozenyogurt",
      "twitterHandle" : "",
      "merchantHandle" : "menchiesfrozenyogurt",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Menchie's Frozen Yogurt",
      "city" : "Fresno",
      "companyName" : "Menchie's Frozen Yogurt",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.menchies.com\/locations\/frozen-yogurt-park-crossing-shopping-center-ca",
      "email" : ""
    }
  },
  "bigtownhero" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/cbeQGRpobND7AGlYj1qZyMEJWlHc27YH.png",
      "instagramHandle" : "bigtownhero",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "bigtownhero",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Big Town Hero",
      "city" : "Vancouver",
      "companyName" : "Big Town Hero",
      "websiteUrl" : "https:\/\/bigtownhero.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "cafeorganix" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 521-7485",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/8126f81d34f9bc030d2e131e4b145fe43a958a90\/original.png",
      "instagramHandle" : "cafeorganix",
      "twitterHandle" : "",
      "merchantHandle" : "cafeorganix",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Cafe Organix",
      "city" : "San Bernardino",
      "companyName" : "Cafe Organix",
      "websiteUrl" : "http:\/\/www.cafeorganix.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "poweredbypure" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 285-6052",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/NCJ43TtNqBwkZxyV2Or5H0kUb8P0iJ8j.png",
      "instagramHandle" : "poweredbypure",
      "merchantHandle" : "poweredbypure",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Powered By Pure",
      "city" : "Oakland",
      "companyName" : "Powered By Pure",
      "websiteUrl" : "http:\/\/www.poweredbypure.life\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "pushxpullcoffee" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/gYLM0XWTdOfCU2EBkPuk0T0SngBpAthu.png",
      "instagramHandle" : "pushxpullcoffee",
      "merchantHandle" : "pushxpullcoffee",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "PUSH X PULL COFFEE",
      "city" : "Portland",
      "companyName" : "PUSH X PULL COFFEE",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/pushxpullcoffee.com\/",
      "email" : ""
    }
  },
  "ironkincoffeeco" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 301-0543",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/RhtjuEfI6FpGP2x0RvX4ArlvPSQlagka.png",
      "instagramHandle" : "ironkincoffeeco",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "ironkincoffeeco",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Iron & Kin Coffee Co",
      "city" : "Fontana",
      "companyName" : "Iron & Kin Coffee Co",
      "websiteUrl" : "http:\/\/ironandkin.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "oregoncontemporary" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(503) 286-9449",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/G0XaGO8XWKtpWbLku6pbeIrAvH2BIEFJ.png",
      "instagramHandle" : "oregoncontemporary",
      "merchantHandle" : "oregoncontemporary",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Oregon Contemporary",
      "city" : "Vancouver",
      "companyName" : "Oregon Contemporary",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.oregoncontemporary.org\/",
      "email" : ""
    }
  },
  "delinerds" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(949) 229-1707",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/tbXuIzi2OPhb0a2GWpo05oMAC29MNWuW.png",
      "instagramHandle" : "delinerds",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "delinerds",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Deli Nerds",
      "city" : "Huntington Beach",
      "companyName" : "Deli Nerds",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.delinerds.com\/s\/store-locator",
      "email" : ""
    }
  },
  "youngevitysmarts" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 693-2811",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/3a94bf5205b96ba727efdd3b61354c27b1f9fe2a\/original.png",
      "instagramHandle" : "youngevitysmarts",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "youngevitysmarts",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Youngevity - Smarts",
      "city" : "Fontana",
      "companyName" : "Youngevity - Smarts",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.conceptosproductosnaturales.com\/",
      "email" : ""
    }
  },
  "thefieldsbargrill" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(503) 841-6601",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/o39Mhr5aVuTQwlyFCaiiacNsk96T71h6.png",
      "instagramHandle" : "thefieldsbargrill",
      "twitterHandle" : "",
      "merchantHandle" : "thefieldsbargrill",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Fields Bar & Grill",
      "city" : "Vancouver",
      "companyName" : "The Fields Bar & Grill",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.thefieldspdx.com\/",
      "email" : ""
    }
  },
  "familygrowthcenter" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(517) 371-1347",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/7f401e97356d57b5ba4c9d0975507af02409bac8\/original.png",
      "instagramHandle" : "familygrowthcenter",
      "twitterHandle" : "",
      "merchantHandle" : "familygrowthcenter",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Family Growth Center",
      "city" : "Lansing",
      "companyName" : "Family Growth Center",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "hlproduce" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(253) 471-1830",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Q7a3LrONLMqkR1TW1WUAlF9ROSyuLasl.jpeg",
      "instagramHandle" : "hlproduce",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "hlproduce",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "H & L Produce",
      "city" : "Tacoma",
      "companyName" : "H & L Produce",
      "websiteUrl" : "https:\/\/tacomaboys.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "celebratefestivalincrestaurantsupplystore" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(408) 673-9999",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/vNpktvuVkhEJDpLq7Yn0OnrkTOckv6zD.png",
      "instagramHandle" : "celebratefestivalincrestaurantsupplystore",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "celebratefestivalincrestaurantsupplystore",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Celebrate Festival Inc - Restaurant Supply Store",
      "city" : "San Jose",
      "companyName" : "Celebrate Festival Inc - Restaurant Supply Store",
      "websiteUrl" : "https:\/\/celebratefestivalinc.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "ramboshotchicken" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 210-2019",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/500BTO6CsiWtF3lIxgKowcnNGFdJTIRf.png",
      "instagramHandle" : "ramboshotchicken",
      "twitterHandle" : "",
      "merchantHandle" : "ramboshotchicken",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Rambo's Hot Chicken",
      "city" : "Oxnard",
      "companyName" : "Rambo's Hot Chicken",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/ramboshotchicken.com\/",
      "email" : ""
    }
  },
  "foxandlionbread" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 872-9789",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/1fe5f2ae9367bfeb22ece14cebc67a1b\/original.jpeg",
      "instagramHandle" : "foxandlionbread",
      "premium" : false,
      "merchantHandle" : "foxandlionbread",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Fox and Lion Bread",
      "city" : "San Francisco",
      "companyName" : "Fox and Lion Bread",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.foxandlionbread.com\/",
      "email" : ""
    }
  },
  "ravensmanor" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(971) 319-6182",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/sinrJGT2jBot3FoqC4KmZwUajZ7Yv0mt.png",
      "instagramHandle" : "ravensmanor",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "ravensmanor",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Raven's Manor",
      "city" : "Portland",
      "companyName" : "Raven's Manor",
      "websiteUrl" : "http:\/\/ravensmanorexperience.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thehub" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(519) 253-7699",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/eb9807d45338adf2aaff16a4ddb548c1ab63ce00\/original.png",
      "instagramHandle" : "thehub",
      "premium" : false,
      "merchantHandle" : "thehub",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Hub",
      "city" : "Detroit",
      "companyName" : "The Hub",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/thehubcateringcompany.ca\/",
      "email" : ""
    }
  },
  "snackybrownbakery" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(810) 373-2373",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/1ab656a279e13afbed09c1e688888f1d138b8e41\/original.jpeg",
      "instagramHandle" : "snackybrownbakery",
      "premium" : false,
      "merchantHandle" : "snackybrownbakery",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Snacky Brown Bakery",
      "city" : "Flint",
      "companyName" : "Snacky Brown Bakery",
      "websiteUrl" : "http:\/\/www.snackybrown.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "jojostreatsnsweets" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(734) 788-5453",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/6HHrEzZQngpr0w9LM5HSTKam4LaYE6eZ.png",
      "instagramHandle" : "jojostreatsnsweets",
      "twitterHandle" : "",
      "merchantHandle" : "jojostreatsnsweets",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "JoJo's Treats n' Sweets",
      "city" : "Ann Arbor",
      "companyName" : "JoJo's Treats n' Sweets",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.jojostreats.com\/",
      "email" : ""
    }
  },
  "republikcoffeepasadena" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(626) 529-3714",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/78e855451d1116230370ff86ca6c408df373865c\/original.jpeg",
      "instagramHandle" : "republikcoffeepasadena",
      "twitterHandle" : "",
      "merchantHandle" : "republikcoffeepasadena",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Republik Coffee Pasadena",
      "city" : "Glendale",
      "companyName" : "Republik Coffee Pasadena",
      "websiteUrl" : "http:\/\/www.republikcoffee.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thewillows" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 226-7768",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/23df12bf6f986cfb803379e9d1ed750ed206bd03\/original.jpeg",
      "instagramHandle" : "thewillows",
      "twitterHandle" : "",
      "merchantHandle" : "thewillows",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Willows",
      "city" : "San Francisco",
      "companyName" : "The Willows",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/thewillowssf.com\/",
      "email" : ""
    }
  },
  "thesongbirdcafe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(734) 780-7100",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/uLsTYocjnl65yTYZxlgXd6Jn0lGISDV5.png",
      "instagramHandle" : "thesongbirdcafe",
      "merchantHandle" : "thesongbirdcafe",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Songbird Cafe",
      "city" : "Ann Arbor",
      "companyName" : "The Songbird Cafe",
      "websiteUrl" : "https:\/\/thesongbirdcafeannarbor.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "premierrestorationandcleaningservices" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(517) 512-8332",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "premierrestorationandcleaningservices",
      "premium" : false,
      "merchantHandle" : "premierrestorationandcleaningservices",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Premier Restoration and Cleaning Services",
      "city" : "Lansing",
      "companyName" : "Premier Restoration and Cleaning Services",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.prrcllc.com\/",
      "email" : ""
    }
  },
  "texasroadhouse" : {
    "info" : {
      "state" : "MT\r",
      "posSystem" : "Square",
      "phone" : "(406) 651-5885",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ffXoRFxNuUzAqXqtgxW8V1GIKvaoK7yB.png",
      "instagramHandle" : "texasroadhouse",
      "premium" : false,
      "merchantHandle" : "texasroadhouse",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Texas Roadhouse",
      "city" : "Billings",
      "companyName" : "Texas Roadhouse",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.texasroadhouse.com\/locations\/montana\/billings?utm_source=listing&utm_medium=search",
      "email" : ""
    }
  },
  "irvinebreakfastclub" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(949) 418-7647",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/kGM7cmWQ02zTyPgzpDfidXqrpy6Bkakf.png",
      "instagramHandle" : "irvinebreakfastclub",
      "premium" : false,
      "merchantHandle" : "irvinebreakfastclub",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Irvine Breakfast Club",
      "city" : "Irvine",
      "companyName" : "Irvine Breakfast Club",
      "websiteUrl" : "http:\/\/www.irvinebreakfastclub.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "manifestbarbrewery" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(541) 343-0224",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/LATfKCm6v89wBVjFA3da44ML8GEwoiKq.png",
      "instagramHandle" : "manifestbarbrewery",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "manifestbarbrewery",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Manifest Bar & Brewery",
      "city" : "Eugene",
      "companyName" : "Manifest Bar & Brewery",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/manifestbeer.com\/",
      "email" : ""
    }
  },
  "juiceparadise" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(951) 780-9742",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/HgwjCsUbymGQxDsudtKVxwgtR22BAESa.png",
      "instagramHandle" : "juiceparadise",
      "twitterHandle" : "",
      "merchantHandle" : "juiceparadise",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Juice Paradise",
      "city" : "Riverside",
      "companyName" : "Juice Paradise",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "shabutime" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(408) 275-1888",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "shabutime",
      "premium" : false,
      "merchantHandle" : "shabutime",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Shabu Time",
      "city" : "San Jose",
      "companyName" : "Shabu Time",
      "websiteUrl" : "http:\/\/www.shabutime.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "indigocircle" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "indigocircle",
      "merchantHandle" : "indigocircle",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Indigo Circle",
      "city" : "Huntington Beach",
      "companyName" : "Indigo Circle",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "goldenrabbit" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(949) 220-7789",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/6V2yGes8VCUHI2F8jfHdbl0UaNnnONCR.png",
      "instagramHandle" : "goldenrabbit",
      "merchantHandle" : "goldenrabbit",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Golden Rabbit",
      "city" : "Irvine",
      "companyName" : "Golden Rabbit",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.goldenrabbit.co\/",
      "email" : ""
    }
  },
  "perryspizza" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 891-1341",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/606b8467be97229e7d9e284e1e759a29b057ea0c\/original.jpeg",
      "instagramHandle" : "perryspizza",
      "premium" : false,
      "merchantHandle" : "perryspizza",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Perry's Pizza",
      "city" : "Huntington Beach",
      "companyName" : "Perry's Pizza",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/perryspizzahb.com\/",
      "email" : ""
    }
  },
  "cookiechicks" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 552-9510",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/dd39b0d49258cfe95e1b235a8c3dad0a\/original.jpeg",
      "instagramHandle" : "cookiechicks",
      "merchantHandle" : "cookiechicks",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Cookie Chicks",
      "city" : "Grand Rapids",
      "companyName" : "Cookie Chicks",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/cookie-chicks.com\/",
      "email" : ""
    }
  },
  "edithspie" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(510) 619-5167",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/oXqaJEt0GrcIf0SpE9Bh1av0NFgoK0gV.png",
      "instagramHandle" : "edithspie",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "edithspie",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Edith's Pie",
      "city" : "Oakland",
      "companyName" : "Edith's Pie",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.edithspie.com\/",
      "email" : ""
    }
  },
  "bobadrive" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(408) 429-8145",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/z7xdgS3KFYXoXc6JqZiiJxSr3sSVVr4Q.png",
      "instagramHandle" : "bobadrive",
      "merchantHandle" : "bobadrive",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Boba Drive",
      "city" : "San Jose",
      "companyName" : "Boba Drive",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.bobadrive.shop\/",
      "email" : ""
    }
  },
  "millpond" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/99b1900480d48824157d4aa5d746e49f8970ae01\/original.jpeg",
      "instagramHandle" : "millpond",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "millpond",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Mill Pond",
      "city" : "Ann Arbor",
      "companyName" : "Mill Pond",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "opengatekitchen" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(657) 218-4210",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/LFYH5lVOxM1TTjocJJmqwigj5eRypo0F.png",
      "instagramHandle" : "opengatekitchen",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "opengatekitchen",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Open Gate Kitchen",
      "city" : "Santa Ana",
      "companyName" : "Open Gate Kitchen",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "papaurbsgrillstockton" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 227-8144",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/a1f416e0644a1bfde9a3f04d076c0081ad7c1ab1\/original.jpeg",
      "instagramHandle" : "papaurbsgrillstockton",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "papaurbsgrillstockton",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Papa Urb's Grill | Stockton",
      "city" : "Stockton",
      "companyName" : "Papa Urb's Grill | Stockton",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "lettucesandwichshop" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "lettucesandwichshop",
      "merchantHandle" : "lettucesandwichshop",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "LETTUCE SANDWICH SHOP",
      "city" : "San Jose",
      "companyName" : "LETTUCE SANDWICH SHOP",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "coffeefirstcafebakeshoppe" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(586) 838-5353",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "coffeefirstcafebakeshoppe",
      "premium" : false,
      "merchantHandle" : "coffeefirstcafebakeshoppe",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Coffee First Cafe & Bake Shoppe",
      "city" : "Sterling Heights",
      "companyName" : "Coffee First Cafe & Bake Shoppe",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/facebook.com\/coffee1stcafe\/",
      "email" : ""
    }
  },
  "dadshotdogs" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 478-7793",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/82b0175653b5a137536c5966ace9fadfe98f93ae\/original.jpeg",
      "instagramHandle" : "dadshotdogs",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "dadshotdogs",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Dad's Hot Dogs",
      "city" : "Stockton",
      "companyName" : "Dad's Hot Dogs",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "katalystartgallerygiftboutique" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(517) 708-8916",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/cf0768ada54e90b10277713330b6e56daf89f1bd\/original.jpeg",
      "instagramHandle" : "katalystartgallerygiftboutique",
      "merchantHandle" : "katalystartgallerygiftboutique",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Katalyst Art Gallery & Gift Boutique",
      "city" : "Lansing",
      "companyName" : "Katalyst Art Gallery & Gift Boutique",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "luckysdrivein" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(253) 470-8048",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "luckysdrivein",
      "merchantHandle" : "luckysdrivein",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Lucky's Drive-In",
      "city" : "Tacoma",
      "companyName" : "Lucky's Drive-In",
      "websiteUrl" : "http:\/\/www.luckysparkland.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "salvipdx" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(503) 558-2504",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/5EWeL5E0gVD0MB9Y0lK7CGRDOkhgfN6f.png",
      "instagramHandle" : "salvipdx",
      "premium" : false,
      "merchantHandle" : "salvipdx",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Salvi PDX",
      "city" : "Portland",
      "companyName" : "Salvi PDX",
      "websiteUrl" : "http:\/\/www.salvipdx.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "lovesicecreamchocolate" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(616) 965-1054",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/gRbAGUZtzOZpIv98iHxRFRE1hCyu6BpZ.png",
      "instagramHandle" : "lovesicecreamchocolate",
      "merchantHandle" : "lovesicecreamchocolate",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Love's Ice Cream & Chocolate",
      "city" : "Grand Rapids",
      "companyName" : "Love's Ice Cream & Chocolate",
      "websiteUrl" : "http:\/\/lovesicecream.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "divinosbakeryrestaurant" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(951) 243-5700",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "divinosbakeryrestaurant",
      "merchantHandle" : "divinosbakeryrestaurant",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Divino's Bakery & Restaurant",
      "city" : "Moreno Valley",
      "companyName" : "Divino's Bakery & Restaurant",
      "websiteUrl" : "https:\/\/www.divinosbakeryrestaurantca.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "wateravenuecoffee" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(503) 808-7084",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Hfd5oQXwrIt7jmARLGfzYpOXHFjxWmhd.png",
      "instagramHandle" : "wateravenuecoffee",
      "twitterHandle" : "",
      "merchantHandle" : "wateravenuecoffee",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Water Avenue Coffee",
      "city" : "Portland",
      "companyName" : "Water Avenue Coffee",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/wateravenuecoffee.com\/",
      "email" : ""
    }
  },
  "frogtownsgourmettacos" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(323) 503-0137",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/azbMi0aQHa7a30lxzNcItAMr9uSXaUQi.png",
      "instagramHandle" : "frogtownsgourmettacos",
      "merchantHandle" : "frogtownsgourmettacos",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Frogtowns Gourmet Tacos",
      "city" : "Glendale",
      "companyName" : "Frogtowns Gourmet Tacos",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/order.snackpass.co\/frogtowngourmettacos",
      "email" : ""
    }
  },
  "detroitvineyards" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(313) 265-3938",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/4aG6FY9aavzjuaMJqLzGkcawndnDRGC2.png",
      "instagramHandle" : "detroitvineyards",
      "merchantHandle" : "detroitvineyards",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Detroit Vineyards",
      "city" : "Detroit",
      "companyName" : "Detroit Vineyards",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "tammyskitchenstudio" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(586) 231-3678",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/RkjOnzctHzx1Tru0tf1ZLySJRapLhbez.png",
      "instagramHandle" : "tammyskitchenstudio",
      "merchantHandle" : "tammyskitchenstudio",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Tammy's Kitchen Studio",
      "city" : "Sterling Heights",
      "companyName" : "Tammy's Kitchen Studio",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "zuleeats" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(519) 980-6024",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/5MqJ0hbMwTgw14DnQqaSQM64cudQ20qF.png",
      "instagramHandle" : "zuleeats",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "zuleeats",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "ZuleEats",
      "city" : "Detroit",
      "companyName" : "ZuleEats",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.zuleeats.ca\/",
      "email" : ""
    }
  },
  "tacoschata" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(626) 374-7241",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/G38lsOL0f4EbdRQOLR2Shses8ULMdBsi.png",
      "instagramHandle" : "tacoschata",
      "merchantHandle" : "tacoschata",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Tacos Chata",
      "city" : "Fontana",
      "companyName" : "Tacos Chata",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "mealpreponfly" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(760) 486-6575",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/gk5SiQSbD3lX9Sa5rY0goJ0TgdW0kDrP.png",
      "instagramHandle" : "mealpreponfly",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "mealpreponfly",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Meal Prep on the Fly",
      "city" : "San Bernardino",
      "companyName" : "Meal Prep on the Fly",
      "websiteUrl" : "http:\/\/www.mealpreponthefly.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "impactnutrition" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 485-5816",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/8db16b94aa53f878effa97b9bfd332db66f39226\/original.jpeg",
      "instagramHandle" : "impactnutrition",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "impactnutrition",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Impact Nutrition 91",
      "city" : "Modesto",
      "companyName" : "Impact Nutrition 91",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "georgioscateringco" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(253) 232-3615",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Xl0aKGuK2DOCGM0HQInhFaifOZH3gZ8j.png",
      "instagramHandle" : "georgioscateringco",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "georgioscateringco",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Georgio's Catering Co.",
      "city" : "Tacoma",
      "companyName" : "Georgio's Catering Co.",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.georgioscatering.com\/",
      "email" : ""
    }
  },
  "gardens" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(408) 645-6937",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/IVpzH7jULnK9xeBiuakqVAcORmkaSPOV.png",
      "instagramHandle" : "gardens",
      "merchantHandle" : "gardens",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "420 Gardens",
      "city" : "San Jose",
      "companyName" : "420 Gardens",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "monsterph" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 788-4459",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/8a4uaTzbKHjTjMhvqa7So0q72nCiWb6c.jpeg",
      "instagramHandle" : "monsterph",
      "twitterHandle" : "",
      "merchantHandle" : "monsterph",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Monster Phở",
      "city" : "Oakland",
      "companyName" : "Monster Phở",
      "websiteUrl" : "http:\/\/www.monsterpho.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sincereceramics" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "sincereceramics",
      "twitterHandle" : "",
      "merchantHandle" : "sincereceramics",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Sincere Ceramics",
      "city" : "Sacramento",
      "companyName" : "Sincere Ceramics",
      "websiteUrl" : "http:\/\/sincereceramicssac.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "bbqdaddy" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(586) 334-4777",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "bbqdaddy",
      "premium" : false,
      "merchantHandle" : "bbqdaddy",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "BBQ Daddy",
      "city" : "Warren",
      "companyName" : "BBQ Daddy",
      "websiteUrl" : "http:\/\/www.bbqdaddyeats.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "llhawaiianbbq" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 278-8082",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/828a8a4ab9d6a4d7fc0b34d94405d3c52058975e\/original.jpeg",
      "instagramHandle" : "llhawaiianbbq",
      "twitterHandle" : "",
      "merchantHandle" : "llhawaiianbbq",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "L & L Hawaiian BBQ",
      "city" : "Oxnard",
      "companyName" : "L & L Hawaiian BBQ",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.eatll.com\/",
      "email" : ""
    }
  },
  "warinthai" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(424) 263-4872",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/VtPEuVXYTaxFKFOTJ8CUhDWAk0ts0Jh0.png",
      "instagramHandle" : "warinthai",
      "premium" : false,
      "merchantHandle" : "warinthai",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Warin Thai",
      "city" : "Long Beach",
      "companyName" : "Warin Thai",
      "websiteUrl" : "http:\/\/www.warinthai.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "chillnparroticecreamshop" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(989) 472-3521",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/QkI9mAdPcKTSuDT2zahkHVyS9YxqrRgA.png",
      "instagramHandle" : "chillnparroticecreamshop",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "chillnparroticecreamshop",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Chill’n Parrot Ice Cream Shop",
      "city" : "Lansing",
      "companyName" : "Chill’n Parrot Ice Cream Shop",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sunfreshicecream" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 922-8668",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/f8c75923812efe94fd911e017898946922d20a95\/original.jpeg",
      "instagramHandle" : "sunfreshicecream",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "sunfreshicecream",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "SunFresh Ice Cream",
      "city" : "Santa Ana",
      "companyName" : "SunFresh Ice Cream",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "tummystuffer" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 540-6035",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/6ee61ac487bfbcb507f81e3eac57a34ed85b07b2\/original.jpeg",
      "instagramHandle" : "tummystuffer",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "tummystuffer",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Tummy Stuffer",
      "city" : "Santa Ana",
      "companyName" : "Tummy Stuffer",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "stackburgerwhiskeybarvancouver" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(360) 450-0774",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/2b7241737312eacfc3df1104866329e6871ccb0c\/original.png",
      "instagramHandle" : "stackburgerwhiskeybarvancouver",
      "twitterHandle" : "",
      "merchantHandle" : "stackburgerwhiskeybarvancouver",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Stack 571 Burger & Whiskey Bar - Vancouver",
      "city" : "Vancouver",
      "companyName" : "Stack 571 Burger & Whiskey Bar - Vancouver",
      "websiteUrl" : "https:\/\/stack571.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "steadyeddyscafe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(810) 235-1968",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "steadyeddyscafe",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "steadyeddyscafe",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Steady Eddy’s Cafe",
      "city" : "Flint",
      "companyName" : "Steady Eddy’s Cafe",
      "websiteUrl" : "http:\/\/steadyeddyscafe.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "jointedcuebilliards" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(916) 456-3243",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/af09a3e9ee5952aec676df5c103ef20dc7708778\/original.png",
      "instagramHandle" : "jointedcuebilliards",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "jointedcuebilliards",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "JOINTED CUE BILLIARDS",
      "city" : "Sacramento",
      "companyName" : "JOINTED CUE BILLIARDS",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/jointedcue.com\/",
      "email" : ""
    }
  },
  "looseleafbobacompany" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(562) 435-1559",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ubS6oHDsBOXN6eoNoOtZd0NzIUaPhjW0.png",
      "instagramHandle" : "looseleafbobacompany",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "looseleafbobacompany",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Loose Leaf Boba Company",
      "city" : "Long Beach",
      "companyName" : "Loose Leaf Boba Company",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.bobacompany.com\/",
      "email" : ""
    }
  },
  "portolacoffeeroasters" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 795-8557",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/1GLkoqgiPgRTAPAyN7Z8qsURmSoVybAY.png",
      "instagramHandle" : "portolacoffeeroasters",
      "merchantHandle" : "portolacoffeeroasters",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Portola Coffee Roasters",
      "city" : "Santa Ana",
      "companyName" : "Portola Coffee Roasters",
      "websiteUrl" : "http:\/\/www.portolacoffeelab.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "tankhousebbqbar" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(916) 431-7199",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/KfISLQopa7tOg3MRVz9sc1C9fslt5HHJ.png",
      "instagramHandle" : "tankhousebbqbar",
      "premium" : false,
      "merchantHandle" : "tankhousebbqbar",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Tank House BBQ & Bar",
      "city" : "Sacramento",
      "companyName" : "Tank House BBQ & Bar",
      "websiteUrl" : "http:\/\/tankhousebbq.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "elnopalito" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(541) 357-1016",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Yg7wyUGEjGtH2AuVP5lVnGaDSMNt37a0.png",
      "instagramHandle" : "elnopalito",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "elnopalito",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "El Nopalito",
      "city" : "Eugene",
      "companyName" : "El Nopalito",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "tasteofthai" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(517) 324-0225",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/C0QFU38NnbygzvaxfclhGjFrEgeNR1mL.png",
      "instagramHandle" : "tasteofthai",
      "merchantHandle" : "tasteofthai",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Taste of Thai",
      "city" : "Lansing",
      "companyName" : "Taste of Thai",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.tasteofthaiel.com\/",
      "email" : ""
    }
  },
  "pulpjuicebar" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 771-4400",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "pulpjuicebar",
      "premium" : false,
      "merchantHandle" : "pulpjuicebar",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Pulp Juice Bar",
      "city" : "Anaheim",
      "companyName" : "Pulp Juice Bar",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/pulpjuicebar.com\/",
      "email" : ""
    }
  },
  "kyotogiftfood" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(619) 477-3605",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/TRNIevLJTBYTuJqPCjPaLSw3UhVfzWNe.png",
      "instagramHandle" : "kyotogiftfood",
      "merchantHandle" : "kyotogiftfood",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Kyoto Gift & Food",
      "city" : "Chula Vista",
      "companyName" : "Kyoto Gift & Food",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "qualitycaraccessories" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(559) 293-3723",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/2fa06f79af42aa59d73b9e8b11986e1892cce260\/original.jpeg",
      "instagramHandle" : "qualitycaraccessories",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "qualitycaraccessories",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Quality Car Accessories",
      "city" : "Fresno",
      "companyName" : "Quality Car Accessories",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thehotspot" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(844) 219-7029",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/9ZRfOHcAIuSQSMVnOJ5S6GOE4CV7uSJq.png",
      "instagramHandle" : "thehotspot",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "thehotspot",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Hot Spot",
      "city" : "Sterling Heights",
      "companyName" : "The Hot Spot",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.thehotspotcoffeecompany.com\/",
      "email" : ""
    }
  },
  "detroiteaterymidtown" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(313) 656-4681",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/avzt3I4Zd5WNfHauTEJwX3NozUSvSprl.png",
      "instagramHandle" : "detroiteaterymidtown",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "detroiteaterymidtown",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Detroit Eatery Midtown",
      "city" : "Detroit",
      "companyName" : "Detroit Eatery Midtown",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/detroiteatery.com\/",
      "email" : ""
    }
  },
  "hotzrentalcatering" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(800) 990-1599",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "hotzrentalcatering",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "hotzrentalcatering",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Hotz Rental & Catering",
      "city" : "Warren",
      "companyName" : "Hotz Rental & Catering",
      "websiteUrl" : "https:\/\/hotzcatering.com\/index.html",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "portlandsportsbargrill" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(503) 222-2027",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/4pKusLOwTY8fJQZ5aITE4rev30qaA6lt.png",
      "instagramHandle" : "portlandsportsbargrill",
      "twitterHandle" : "",
      "merchantHandle" : "portlandsportsbargrill",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Portland Sports Bar & Grill",
      "city" : "Portland",
      "companyName" : "Portland Sports Bar & Grill",
      "websiteUrl" : "http:\/\/www.portlandsportsbarandgrill.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "lemnzst" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(818) 396-4755",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/17b05a8235cccc4a984e20d50a5a7fd7\/original.jpeg",
      "instagramHandle" : "lemnzst",
      "merchantHandle" : "lemnzst",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Lemón Zëst",
      "city" : "Glendale",
      "companyName" : "Lemón Zëst",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.lemonzest.cafe\/",
      "email" : ""
    }
  },
  "titusfarms" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(517) 910-3002",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/J93zBdKkJbJUajB0gV1hg07oV1TVuz5M.png",
      "instagramHandle" : "titusfarms",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "titusfarms",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Titus Farms",
      "city" : "Lansing",
      "companyName" : "Titus Farms",
      "websiteUrl" : "http:\/\/www.titusfarms.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "tigersyogurt" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 952-6042",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/BE1OxNqbuO7ZKblGx0af6AsHxyx0G1Pe.png",
      "instagramHandle" : "tigersyogurt",
      "twitterHandle" : "",
      "merchantHandle" : "tigersyogurt",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Tigers Yogurt",
      "city" : "Stockton",
      "companyName" : "Tigers Yogurt",
      "websiteUrl" : "https:\/\/m.facebook.com\/pages\/Tigers-Yogurt\/146452388711014",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "nomadtravelmixllc" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(503) 679-8406",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/syUagSMhHf5yhICXJeMz0TUR7zOCgH2T.png",
      "instagramHandle" : "nomadtravelmixllc",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "nomadtravelmixllc",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Nomad Travel Mix LLC",
      "city" : "Portland",
      "companyName" : "Nomad Travel Mix LLC",
      "websiteUrl" : "http:\/\/nomadmix.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sweetdozen" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(916) 344-2000",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/ff352ac132d403e98911daf66b4575d2a737f120\/original.png",
      "instagramHandle" : "sweetdozen",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "sweetdozen",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sweet Dozen",
      "city" : "Sacramento",
      "companyName" : "Sweet Dozen",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.sweetdozen.com\/",
      "email" : ""
    }
  },
  "poketologypokegrill" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(626) 508-5899",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/EQXMWN9Gs0ioGHikzUkgN1vWNalRzkH4.png",
      "instagramHandle" : "poketologypokegrill",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "poketologypokegrill",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Poketology Poke & Grill",
      "city" : "San Bernardino",
      "companyName" : "Poketology Poke & Grill",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.poketologyca.com\/",
      "email" : ""
    }
  },
  "goodtimepartystore" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(248) 349-1477",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/f4c95db96661a68922fc93f141efbf6e0d508146\/original.jpeg",
      "instagramHandle" : "goodtimepartystore",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "goodtimepartystore",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Good Time Party Store",
      "city" : "Ann Arbor",
      "companyName" : "Good Time Party Store",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.metroalive.com\/michigan\/northville\/goodtimepartystore\/home",
      "email" : ""
    }
  },
  "thebigdirty" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(541) 650-6634",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/5OaB0wKIGjeWMPQlBv77ZaeWPO02hdb1.png",
      "instagramHandle" : "thebigdirty",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "thebigdirty",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Big Dirty",
      "city" : "Eugene",
      "companyName" : "The Big Dirty",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.thebigdirty.live\/",
      "email" : ""
    }
  },
  "confectionsbylynn" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(734) 485-5966",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "confectionsbylynn",
      "merchantHandle" : "confectionsbylynn",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Confections by Lynn",
      "city" : "Ann Arbor",
      "companyName" : "Confections by Lynn",
      "websiteUrl" : "http:\/\/confectionsbylynn.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "angelssharebarrelhouse" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(503) 743-1077",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/XJyCN4sgK0S16Yol6Da6AR320VG0fVnl.png",
      "instagramHandle" : "angelssharebarrelhouse",
      "merchantHandle" : "angelssharebarrelhouse",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Angel's Share Barrel House",
      "city" : "Salem",
      "companyName" : "Angel's Share Barrel House",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.angelssharebarrelhouse.com\/",
      "email" : ""
    }
  },
  "zozossandwichhouse" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(509) 413-2558",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/PlGIc2r0aPGJ5iq2AHgkqvLH0IFySyN8.png",
      "instagramHandle" : "zozossandwichhouse",
      "merchantHandle" : "zozossandwichhouse",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Zozo's Sandwich House",
      "city" : "Spokane",
      "companyName" : "Zozo's Sandwich House",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/zozossandwichhouse.com\/",
      "email" : ""
    }
  },
  "sisterscoffeecompanyinthepearldistrict" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(971) 279-2957",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/KWJmUnphOs8SvUQiMyFXZsAkiPL3pPMr.png",
      "instagramHandle" : "sisterscoffeecompanyinthepearldistrict",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "sisterscoffeecompanyinthepearldistrict",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Sisters Coffee Company in The Pearl District",
      "city" : "Portland",
      "companyName" : "Sisters Coffee Company in The Pearl District",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.sisterscoffee.com\/",
      "email" : ""
    }
  },
  "beessweetshack" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/6ap7b10PoS7f5NPgdA0jw3HruXN9Bvz0.png",
      "instagramHandle" : "beessweetshack",
      "merchantHandle" : "beessweetshack",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Bee's Sweet Shack",
      "city" : "Detroit",
      "companyName" : "Bee's Sweet Shack",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "lakehousedipllc" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "",
      "phone" : "",
      "merchantImage" : "lakehousedipllc",
      "instagramHandle" : " LLC\"",
      "premium" : false,
      "merchantHandle" : "lakehousedipllc",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "(406) 672-5009",
      "merchantName" : "No Logo Image Found on Website",
      "city" : "",
      "companyName" : "\"Lakehouse Dip",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : "http:\/\/lakehousedip.com\/"
    }
  },
  "smoothiequeen" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(517) 999-3914",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/n2ExKQkwPhBDcI8NpczpUA08ncOD0hYx.png",
      "instagramHandle" : "smoothiequeen",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "smoothiequeen",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Smoothie Queen",
      "city" : "Lansing",
      "companyName" : "Smoothie Queen",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "dreamscapebrowsmicroblading" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 715-8401",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/OUOpXb8hQaAcMfmkxuwAhqsCKSrr3so2.png",
      "instagramHandle" : "dreamscapebrowsmicroblading",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "dreamscapebrowsmicroblading",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Dreamscape Brows - Microblading",
      "city" : "Stockton",
      "companyName" : "Dreamscape Brows - Microblading",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.dreamscapebrows.com\/",
      "email" : ""
    }
  },
  "enchantedfairiesofannarbor" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(214) 438-0074",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "enchantedfairiesofannarbor",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "enchantedfairiesofannarbor",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Enchanted Fairies of Ann Arbor",
      "city" : "Ann Arbor",
      "companyName" : "Enchanted Fairies of Ann Arbor",
      "websiteUrl" : "http:\/\/enchanted-fairies.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "tuckys" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(541) 423-2457",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Z8Rk9AKwMIU2HuSHrebM5rFI72WeiE2g.png",
      "instagramHandle" : "tuckys",
      "merchantHandle" : "tuckys",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Tucky's",
      "city" : "Eugene",
      "companyName" : "Tucky's",
      "websiteUrl" : "http:\/\/www.tuckyssoutherneats.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "theproducemarket" : {
    "info" : {
      "state" : "MT\r",
      "posSystem" : "Square",
      "phone" : "(406) 561-3392",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/cfL4mHoWWDIy9s7JLUQ6afpY8dgjhFUP.png",
      "instagramHandle" : "theproducemarket",
      "merchantHandle" : "theproducemarket",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Produce Market",
      "city" : "Billings",
      "companyName" : "The Produce Market",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "belospizza" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(503) 991-5801",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/zxsfKE14t1MEFr7cZ0ooh8jSagE01m0F.png",
      "instagramHandle" : "belospizza",
      "twitterHandle" : "",
      "merchantHandle" : "belospizza",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Belo's Pizza",
      "city" : "Salem",
      "companyName" : "Belo's Pizza",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "topchoicemeatmarket" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(360) 896-8865",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/098ae2ff696e17c8e5bf880f916e432d872cc248\/original.jpeg",
      "instagramHandle" : "topchoicemeatmarket",
      "twitterHandle" : "",
      "merchantHandle" : "topchoicemeatmarket",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Top Choice Meat Market",
      "city" : "Vancouver",
      "companyName" : "Top Choice Meat Market",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/topchoicemeatmarket.com\/",
      "email" : ""
    }
  },
  "buttercakeshoppe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(323) 328-1866",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/aq5PR3P86jYAwAeoW2mI2GQCsv97N0GU.png",
      "instagramHandle" : "buttercakeshoppe",
      "twitterHandle" : "",
      "merchantHandle" : "buttercakeshoppe",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Butter Cake Shoppe",
      "city" : "Glendale",
      "companyName" : "Butter Cake Shoppe",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.buttercakeshoppe.com\/",
      "email" : ""
    }
  },
  "gleasonspremiericecreamco" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 941-8166",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/7pGnJztFXzGqZ7jT0fv7ck3DyiZvzD3C.png",
      "instagramHandle" : "gleasonspremiericecreamco",
      "twitterHandle" : "",
      "merchantHandle" : "gleasonspremiericecreamco",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Gleasons Premier Ice Cream Co",
      "city" : "Stockton",
      "companyName" : "Gleasons Premier Ice Cream Co",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "sozairestaurant" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(248) 677-3232",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/nst5JG0N5tN1Gz4Gmp2yzwmmrXIFWX9K.png",
      "instagramHandle" : "sozairestaurant",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "sozairestaurant",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Sozai Restaurant",
      "city" : "Warren",
      "companyName" : "Sozai Restaurant",
      "websiteUrl" : "https:\/\/www.sozairestaurant.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "chamberseatdrink" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 496-5178",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/707219d4cc886422d17da3b3ce4d0e524666bed5\/original.png",
      "instagramHandle" : "chamberseatdrink",
      "merchantHandle" : "chamberseatdrink",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Chambers eat + drink",
      "city" : "San Francisco",
      "companyName" : "Chambers eat + drink",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.chambers-sf.com\/",
      "email" : ""
    }
  },
  "bobaguyslongbeach" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/iXf5pdtCLQ7y9S5sv1jL7OY21ym5LzrO.png",
      "instagramHandle" : "bobaguyslongbeach",
      "premium" : false,
      "merchantHandle" : "bobaguyslongbeach",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Boba Guys Long Beach",
      "city" : "Long Beach",
      "companyName" : "Boba Guys Long Beach",
      "websiteUrl" : "http:\/\/www.bobaguys.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thesycamore" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 252-7704",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/b541dbb0110a484ecfaca59b639673905ba65571\/original.jpeg",
      "instagramHandle" : "thesycamore",
      "merchantHandle" : "thesycamore",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Sycamore",
      "city" : "San Francisco",
      "companyName" : "The Sycamore",
      "websiteUrl" : "http:\/\/thesycamoresf.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sterlingheightsnutrition" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(586) 992-5585 ext. 2",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "sterlingheightsnutrition",
      "merchantHandle" : "sterlingheightsnutrition",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sterling Heights Nutrition",
      "city" : "Sterling Heights",
      "companyName" : "Sterling Heights Nutrition",
      "websiteUrl" : "https:\/\/order.online\/business\/sterling-heights-nutrition-12757422",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "1stmicrogreenery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 299-1005",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/QdCFSQQPM7FQgIeUg06MLa6h9H4Rk8Lb.png",
      "instagramHandle" : "1stmicrogreenery",
      "merchantHandle" : "1stmicrogreenery",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "1st Micro Greenery",
      "city" : "Grand Rapids",
      "companyName" : "1st Micro Greenery",
      "websiteUrl" : "http:\/\/www.1stmicrogreenery.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "rootedspace" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(541) 636-3177",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "rootedspace",
      "merchantHandle" : "rootedspace",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Rooted Space",
      "city" : "Eugene",
      "companyName" : "Rooted Space",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "walgreens" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 242-8717",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/4961ea7e59985a00f37a3b3919400980fb575e86\/original.jpeg",
      "instagramHandle" : "walgreens",
      "merchantHandle" : "walgreens",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Walgreens",
      "city" : "Moreno Valley",
      "companyName" : "Walgreens",
      "websiteUrl" : "https:\/\/www.walgreens.com\/locator\/walgreens-12275+perris+blvd-moreno+valley-ca-92557\/id=5220",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "liquidationexpress" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(586) 301-1115",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "liquidationexpress",
      "merchantHandle" : "liquidationexpress",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Liquidation Express",
      "city" : "Sterling Heights",
      "companyName" : "Liquidation Express",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.facebook.com\/profile.php?id=61553473528148&mibextid=LQQJ4d",
      "email" : ""
    }
  },
  "junglejuice" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(805) 483-0055",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/A9ifjcLbaBsAKi3N2rNVN9lZmtopkRKX.png",
      "instagramHandle" : "junglejuice",
      "twitterHandle" : "",
      "merchantHandle" : "junglejuice",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Jungle Juice",
      "city" : "Oxnard",
      "companyName" : "Jungle Juice",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.yelp.com\/biz\/jungle-juice-oxnard",
      "email" : ""
    }
  },
  "smicsbarsacramento" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 228-4518",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/tRWYv7rauOmog5fEUxGLL9Le7pmu8rZD.png",
      "instagramHandle" : "smicsbarsacramento",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "smicsbarsacramento",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Smic's Bar Sacramento",
      "city" : "Sacramento",
      "companyName" : "Smic's Bar Sacramento",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.smicsbarsacramento.com\/",
      "email" : ""
    }
  },
  "breanutritionandfitness" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(949) 521-0652",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/ee1a0746f92af8cc852b0f5f1a53d26776b544f1\/original.jpeg",
      "instagramHandle" : "breanutritionandfitness",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "breanutritionandfitness",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Brea Nutrition and Fitness",
      "city" : "Anaheim",
      "companyName" : "Brea Nutrition and Fitness",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "plantslayer" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(559) 262-7752",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/RuLGUasC18p60ilSCMSfvtltAMDJGdPj.png",
      "instagramHandle" : "plantslayer",
      "merchantHandle" : "plantslayer",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "PLANT SLAYER",
      "city" : "Fresno",
      "companyName" : "PLANT SLAYER",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/plant-slayer.square.site\/",
      "email" : ""
    }
  },
  "ululanishawaiianshaveicestockton" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 475-8107",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/LdmT2sNL1e2XS8UXlMZTPcuLbDEBhDf9.png",
      "instagramHandle" : "ululanishawaiianshaveicestockton",
      "premium" : false,
      "merchantHandle" : "ululanishawaiianshaveicestockton",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Ululani’s Hawaiian Shave Ice - Stockton",
      "city" : "Stockton",
      "companyName" : "Ululani’s Hawaiian Shave Ice - Stockton",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.ululanishawaiianshaveice.com\/",
      "email" : ""
    }
  },
  "cumaicacoffee" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 337-1500",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ssDjqa8GDhj6BZ0aPDWgQugYjzw5o0Nq.png",
      "instagramHandle" : "cumaicacoffee",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "cumaicacoffee",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Cumaica Coffee",
      "city" : "San Francisco",
      "companyName" : "Cumaica Coffee",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/cumaica.com\/",
      "email" : ""
    }
  },
  "cafedemiro" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(616) 719-1462",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/ac96977a2f8faa0f1d8c42c64736c3a92e5ca24e\/original.png",
      "instagramHandle" : "cafedemiro",
      "twitterHandle" : "",
      "merchantHandle" : "cafedemiro",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Cafe De Miro",
      "city" : "Grand Rapids",
      "companyName" : "Cafe De Miro",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/cafedemiro.com\/",
      "email" : ""
    }
  },
  "delishbakery" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(253) 325-9822",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/72ca0462cdfda25174a35e59add7c98333914f12\/original.jpeg",
      "instagramHandle" : "delishbakery",
      "merchantHandle" : "delishbakery",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "delish bakery",
      "city" : "Tacoma",
      "companyName" : "delish bakery",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.delish-bakery.com\/",
      "email" : ""
    }
  },
  "quickwatercoffeeroasters" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(616) 466-4723",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/nn6KxaAqDSRZzknJvuB3Tm5yP8OEpoL6.png",
      "instagramHandle" : "quickwatercoffeeroasters",
      "twitterHandle" : "",
      "merchantHandle" : "quickwatercoffeeroasters",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Quickwater Coffee Roasters",
      "city" : "Grand Rapids",
      "companyName" : "Quickwater Coffee Roasters",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.quickwatercoffee.com\/",
      "email" : ""
    }
  },
  "labeerhopvirtualbeertastingsandbeersubscriptionbox" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(323) 230-0812",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/BlL73fEGTmtQIrIlZFPrY6Nq0cqKNgTa.png",
      "instagramHandle" : "labeerhopvirtualbeertastingsandbeersubscriptionbox",
      "merchantHandle" : "labeerhopvirtualbeertastingsandbeersubscriptionbox",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "LA Beer Hop - Virtual Beer Tastings and Beer Subscription Box",
      "city" : "Long Beach",
      "companyName" : "LA Beer Hop - Virtual Beer Tastings and Beer Subscription Box",
      "websiteUrl" : "http:\/\/labeerhop.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "tacoslabonita" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(805) 290-7663",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/91afd3774c61b536dbbea49168ad75b9f1e2c46b\/original.jpeg",
      "instagramHandle" : "tacoslabonita",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "tacoslabonita",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Tacos la bonita",
      "city" : "Oxnard",
      "companyName" : "Tacos la bonita",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "firesidecoffeeco" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(810) 635-9196",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/CwsrHcgPJdaDT4Rxou70tINDcdPsiO1e.png",
      "instagramHandle" : "firesidecoffeeco",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "firesidecoffeeco",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Fireside Coffee Co",
      "city" : "Flint",
      "companyName" : "Fireside Coffee Co",
      "websiteUrl" : "http:\/\/www.firesidecoffee.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "shiftcoffeehouse" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 999-0601",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/QaaLcxUgdrM9ucEHANAbSVpN8JNwNaTy.png",
      "instagramHandle" : "shiftcoffeehouse",
      "twitterHandle" : "",
      "merchantHandle" : "shiftcoffeehouse",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Shift Coffee House",
      "city" : "Sacramento",
      "companyName" : "Shift Coffee House",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "makeprojects" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(858) 633-6852",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/TGNgey704y3lF624CFbKrs1BiN0WzDqS.png",
      "instagramHandle" : "makeprojects",
      "merchantHandle" : "makeprojects",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "MAKE Projects",
      "city" : "Chula Vista",
      "companyName" : "MAKE Projects",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.sdmake.org\/",
      "email" : ""
    }
  },
  "thegrilledcheezguy" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 502-0757",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "thegrilledcheezguy",
      "merchantHandle" : "thegrilledcheezguy",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The GrilledCheezGuy",
      "city" : "San Francisco",
      "companyName" : "The GrilledCheezGuy",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "psychicriverside" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 779-1566",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/DtGMWRyTbFvsVbMaruZm4qtmhwEqyaE1.png",
      "instagramHandle" : "psychicriverside",
      "premium" : false,
      "merchantHandle" : "psychicriverside",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Psychic Riverside",
      "city" : "Riverside",
      "companyName" : "Psychic Riverside",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/psychicriverside.com\/",
      "email" : ""
    }
  },
  "muffincanstopus" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(818) 688-1005",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/PlNSWGXNvo92C2Yi0AcIKlIgQjYiKLUk.png",
      "instagramHandle" : "muffincanstopus",
      "twitterHandle" : "",
      "merchantHandle" : "muffincanstopus",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Muffin Can Stop Us",
      "city" : "Glendale",
      "companyName" : "Muffin Can Stop Us",
      "websiteUrl" : "http:\/\/muffincanstopus.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "chickenshacksterlingheights" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(586) 276-0788",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/kNpB1GCh4IH020lYw6CZ0J9IX2y4a6YD.png",
      "instagramHandle" : "chickenshacksterlingheights",
      "twitterHandle" : "",
      "merchantHandle" : "chickenshacksterlingheights",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Chicken Shack Sterling Heights",
      "city" : "Sterling Heights",
      "companyName" : "Chicken Shack Sterling Heights",
      "websiteUrl" : "http:\/\/www.chickenshack.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "yoonsbakery" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(734) 929-4443",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "yoonsbakery",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "yoonsbakery",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Yoon's Bakery",
      "city" : "Ann Arbor",
      "companyName" : "Yoon's Bakery",
      "websiteUrl" : "https:\/\/www.yoonsbakery.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "eltacorojoincrestaurant" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(661) 381-2222",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/A9J9rzMMKfsrmNVwEXRDqWvoVtprQPBV.png",
      "instagramHandle" : "eltacorojoincrestaurant",
      "premium" : false,
      "merchantHandle" : "eltacorojoincrestaurant",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "El Taco Rojo INC. Restaurant",
      "city" : "Bakersfield",
      "companyName" : "El Taco Rojo INC. Restaurant",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/eltacorojo.square.site\/",
      "email" : ""
    }
  },
  "theveganhooligans" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(323) 257-2229",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/G9NkV9KXz6XEeQrwyphmarp9Cxaya7gB.png",
      "instagramHandle" : "theveganhooligans",
      "merchantHandle" : "theveganhooligans",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Vegan Hooligans",
      "city" : "Glendale",
      "companyName" : "The Vegan Hooligans",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thefastfoodcompany" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(619) 663-8314",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/pRoHFCi6UjvFRszpHmxYiWiX407JA3Xa.jpg",
      "instagramHandle" : "thefastfoodcompany",
      "twitterHandle" : "",
      "merchantHandle" : "thefastfoodcompany",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Fast Food Company",
      "city" : "Chula Vista",
      "companyName" : "The Fast Food Company",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.facebook.com\/TheFastFoodCateringCompany\/",
      "email" : ""
    }
  },
  "thesweetstop" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(951) 901-9750",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/HJl8D2DmfRdKATfZVZIjal0wRbhc3ION.jpg",
      "instagramHandle" : "thesweetstop",
      "merchantHandle" : "thesweetstop",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Sweet Stop",
      "city" : "Riverside",
      "companyName" : "The Sweet Stop",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.thesweetstops.com\/",
      "email" : ""
    }
  },
  "octobermoon" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(517) 485-4100",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/47d3975cbb4682f99ed405e77bd6c53262711c5f\/original.jpeg",
      "instagramHandle" : "octobermoon",
      "merchantHandle" : "octobermoon",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "October Moon",
      "city" : "Lansing",
      "companyName" : "October Moon",
      "websiteUrl" : "https:\/\/www.octobermoon.shop\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "rorisartisanalcreamery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 628-3236",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "rorisartisanalcreamery",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "rorisartisanalcreamery",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Rori's Artisanal Creamery",
      "city" : "Oxnard",
      "companyName" : "Rori's Artisanal Creamery",
      "websiteUrl" : "https:\/\/www.rorisartisanalcreamery.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "vanessasbatterbar" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 510-3511",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/a48c4c722284004f7d9c6d48c950d503f210a4b1\/original.jpeg",
      "instagramHandle" : "vanessasbatterbar",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "vanessasbatterbar",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Vanessa’s Batter Bar",
      "city" : "Chula Vista",
      "companyName" : "Vanessa’s Batter Bar",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "ddgraphx" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 341-0737",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/a5535d4203a7c15ef0e23870e9d352cdbb50816b\/original.jpeg",
      "instagramHandle" : "ddgraphx",
      "merchantHandle" : "ddgraphx",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "D & D GRAPHX",
      "city" : "Modesto",
      "companyName" : "D & D GRAPHX",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.dndgraphx.com\/",
      "email" : ""
    }
  },
  "thunderpiepizza" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(509) 443-4790",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/0O0qmZZGO7qpJwU3gvTdQyJSMaWuo1JZ.png",
      "instagramHandle" : "thunderpiepizza",
      "twitterHandle" : "",
      "merchantHandle" : "thunderpiepizza",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Thunder Pie Pizza",
      "city" : "Spokane",
      "companyName" : "Thunder Pie Pizza",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.thunderpiepizza.com\/",
      "email" : ""
    }
  },
  "mcdonalds" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(619) 422-3668",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/a97333f4a24191c6d1d06f7335a427944e6cd812\/original.png",
      "instagramHandle" : "mcdonalds",
      "twitterHandle" : "",
      "merchantHandle" : "mcdonalds",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "McDonald's",
      "city" : "Chula Vista",
      "companyName" : "McDonald's",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.mcdonalds.com\/us\/en-us\/location\/CA\/CHULA-VISTA\/725-E-ST\/7791.html?cid=RF:YXT:GMB::Clicks",
      "email" : ""
    }
  },
  "sroma" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(818) 446-5237",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/e8ZSkvRYaVsjo1gHTaX4JRT0mRZuvD0S.png",
      "instagramHandle" : "sroma",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "sroma",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Sì Roma",
      "city" : "Glendale",
      "companyName" : "Sì Roma",
      "websiteUrl" : "https:\/\/siroma.restaurant\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sanaoven" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(586) 698-2353",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/1f08f5b6b92366e55c62b687b8ac8c433592a691\/original.png",
      "instagramHandle" : "sanaoven",
      "merchantHandle" : "sanaoven",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sana Oven",
      "city" : "Sterling Heights",
      "companyName" : "Sana Oven",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "originnuts" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(360) 616-1037",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "originnuts",
      "twitterHandle" : "",
      "merchantHandle" : "originnuts",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Origin Nuts",
      "city" : "Vancouver",
      "companyName" : "Origin Nuts",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "econowanaheim" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 603-7980",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/00xZN0f3awtU3zx0G4hGnDZE4lneM60M.png",
      "instagramHandle" : "econowanaheim",
      "merchantHandle" : "econowanaheim",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "Eco Store",
      "squareSandboxKey" : "",
      "merchantName" : "Eco Now - Anaheim",
      "city" : "Anaheim",
      "companyName" : "Eco Now - Anaheim",
      "websiteUrl" : "http:\/\/www.econowca.com\/",
      "tikTokHandle" : "",
      "email" : "Contact@EcoNowCA.com"
    }
  },
  "bigrodstexasbbq" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(509) 218-7087",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/cYHgzrZw6q8CvJ104fhKXbILiHxQYMci.png",
      "instagramHandle" : "bigrodstexasbbq",
      "merchantHandle" : "bigrodstexasbbq",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Big Rod's Texas BBQ",
      "city" : "Spokane",
      "companyName" : "Big Rod's Texas BBQ",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.bigrodstexasbbq.com\/",
      "email" : ""
    }
  },
  "littlepanusc" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(213) 364-1345",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "littlepanusc",
      "merchantHandle" : "littlepanusc",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Little Pan USC",
      "city" : "Huntington Beach",
      "companyName" : "Little Pan USC",
      "websiteUrl" : "https:\/\/littlepanfriedbun.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "peteandmaris" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(619) 500-6611",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/66c1f337ee76c48f5a968ffcc15f4a403641b1c9\/original.png",
      "instagramHandle" : "peteandmaris",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "peteandmaris",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Pete and Mari's",
      "city" : "Chula Vista",
      "companyName" : "Pete and Mari's",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "bombburgertacoma" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(253) 459-9068",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/VSapM2Hju8Ts1yds5gaUvM2NOrBo0InS.png",
      "instagramHandle" : "bombburgertacoma",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "bombburgertacoma",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Bomb Burger Tacoma",
      "city" : "Tacoma",
      "companyName" : "Bomb Burger Tacoma",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "pappyscoffeeshop" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(661) 587-8954",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/D6r9cvut1EArTAJymjpgIWTVvz0hvwrk.png",
      "instagramHandle" : "pappyscoffeeshop",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "pappyscoffeeshop",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Pappy's Coffee Shop",
      "city" : "Bakersfield",
      "companyName" : "Pappy's Coffee Shop",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "econowcostamesa" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "",
      "phone" : "",
      "merchantImage" : "econowcostamesa",
      "instagramHandle" : " costa mesa\"",
      "merchantHandle" : "econowcostamesa",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "(657) 900-2833",
      "squareSandboxKey" : "",
      "merchantName" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/00xZN0f3awtU3zx0G4hGnDZE4lneM60M.png",
      "city" : "",
      "companyName" : "\"eco now",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : "http:\/\/www.econowca.com\/"
    }
  },
  "sugarstreetbakerybistro" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(503) 234-7085",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/737753b24f6ff640677be2d15c417f49c361ea53\/original.jpeg",
      "instagramHandle" : "sugarstreetbakerybistro",
      "twitterHandle" : "",
      "merchantHandle" : "sugarstreetbakerybistro",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Sugar Street Bakery & Bistro",
      "city" : "Vancouver",
      "companyName" : "Sugar Street Bakery & Bistro",
      "websiteUrl" : "http:\/\/sugarstreetportland.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "makotojapaneserestaurant" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(458) 201-7403",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "makotojapaneserestaurant",
      "twitterHandle" : "",
      "merchantHandle" : "makotojapaneserestaurant",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Makoto Japanese Restaurant",
      "city" : "Eugene",
      "companyName" : "Makoto Japanese Restaurant",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/makotoeugene.square.site\/",
      "email" : ""
    }
  },
  "mcconnellsfineicecreamcompany" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(805) 963-8813",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/12j1NxuiwMBQzoZqKL0LbmoyPMFIKsed.png",
      "instagramHandle" : "mcconnellsfineicecreamcompany",
      "merchantHandle" : "mcconnellsfineicecreamcompany",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "McConnell's Fine Ice Cream Company",
      "city" : "Oxnard",
      "companyName" : "McConnell's Fine Ice Cream Company",
      "websiteUrl" : "http:\/\/www.mcconnells.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "front" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/ce6b459f0a79bf42499a90be1ea6d2a92494558c\/original.jpeg",
      "instagramHandle" : "front",
      "premium" : false,
      "merchantHandle" : "front",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Front",
      "city" : "San Francisco",
      "companyName" : "Front",
      "websiteUrl" : "https:\/\/front.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "motzsburgers" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(313) 843-9186",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/OpNZ9L9V7R2NZUyJ8N9HquUjjr7S9XgL.png",
      "instagramHandle" : "motzsburgers",
      "merchantHandle" : "motzsburgers",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Motz’s Burgers",
      "city" : "Detroit",
      "companyName" : "Motz’s Burgers",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/motzs.com\/",
      "email" : ""
    }
  },
  "thegrainshed" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(509) 241-3853",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/d2da5eca209909cae8024b2eec5935889331c179\/original.jpeg",
      "instagramHandle" : "thegrainshed",
      "twitterHandle" : "",
      "merchantHandle" : "thegrainshed",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Grain Shed",
      "city" : "Spokane",
      "companyName" : "The Grain Shed",
      "websiteUrl" : "http:\/\/www.thegrainshed.coop\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "tacolounge" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(619) 600-4489",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/93fd0268180a64563b487a7304fa0166e0396c72\/original.jpeg",
      "instagramHandle" : "tacolounge",
      "merchantHandle" : "tacolounge",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Taco Lounge",
      "city" : "Chula Vista",
      "companyName" : "Taco Lounge",
      "websiteUrl" : "http:\/\/www.tacolounge.net\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "daracafebrew" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(458) 240-2200",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/mkqH7U0TRPyXva50PHOJdq8JE6eyy0eZ.png",
      "instagramHandle" : "daracafebrew",
      "merchantHandle" : "daracafebrew",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Da Ra Cafe & Brew",
      "city" : "Eugene",
      "companyName" : "Da Ra Cafe & Brew",
      "websiteUrl" : "https:\/\/www.facebook.com\/Da-Ra-Caf%C3%A9-Brew-103712852372624",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "shawarmastation" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(503) 915-7040",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "shawarmastation",
      "merchantHandle" : "shawarmastation",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Shawarma station",
      "city" : "Portland",
      "companyName" : "Shawarma station",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.shawarmastations.net\/",
      "email" : ""
    }
  },
  "voyagercraftcoffee" : {
    "info" : {
      "posKey" : "-",
      "premium" : true,
      "instagramHandle" : "https:\/\/www.instagram.com\/voyagercraftcoffee",
      "squareProductionKey" : "EAAAlx7gfL3ariU-3wAcoW3g7LYfnVoeN1jqDyyGlh5Ik5LgULb-xrpsAtfn27Kz",
      "merchantHandle" : "voyagercraftcoffee",
      "companyName" : "Voyager Craft Coffee",
      "posSystem" : "Square",
      "merchantName" : "Voyager Craft Coffee",
      "tikTokHandle" : "voyagercraftcoffee",
      "twitterHandle" : "voyagercoffee",
      "websiteUrl" : "voyagercraftcoffee@gmail.com"
    }
  },
  "humbleabodebrewingllc" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "",
      "phone" : "",
      "merchantImage" : "humbleabodebrewingllc",
      "instagramHandle" : " LLC\"",
      "premium" : false,
      "merchantHandle" : "humbleabodebrewingllc",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "(509) 241-3310",
      "squareSandboxKey" : "",
      "merchantName" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/gMTRr2s5xSopbV6Duj7z62h74FgBzeqf.png",
      "city" : "",
      "companyName" : "\"Humble Abode Brewing",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : "http:\/\/humbleabodebeer.com\/"
    }
  },
  "sanbernardinobusinessregistrationservices" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 384-5302",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "sanbernardinobusinessregistrationservices",
      "merchantHandle" : "sanbernardinobusinessregistrationservices",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "San Bernardino Business Registration Services",
      "city" : "San Bernardino",
      "companyName" : "San Bernardino Business Registration Services",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/sbcity.org\/city_hall\/finance\/business_registration",
      "email" : ""
    }
  },
  "barracruda" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(619) 635-0287",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/IFOOz8ZkiIKkaVx0aruW5y6JeiFfrCQF.png",
      "instagramHandle" : "barracruda",
      "twitterHandle" : "",
      "merchantHandle" : "barracruda",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Barracruda",
      "city" : "Chula Vista",
      "companyName" : "Barracruda",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/barracrudasd.com\/",
      "email" : ""
    }
  },
  "kneaddonutstea" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(562) 248-2858",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/TzjXOc4Tt5DhX7fks0olDHioVtipYG0h.png",
      "instagramHandle" : "kneaddonutstea",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "kneaddonutstea",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Knead Donuts & Tea",
      "city" : "Long Beach",
      "companyName" : "Knead Donuts & Tea",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "farmerstablechulavista" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(619) 207-0325",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/e34a3721d9b1ae56eb1819cf36f6b9fbb37e4f05\/original.png",
      "instagramHandle" : "farmerstablechulavista",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "farmerstablechulavista",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Farmer's Table Chula Vista",
      "city" : "Chula Vista",
      "companyName" : "Farmer's Table Chula Vista",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/myfarmerstable.com\/chula-vista\/",
      "email" : ""
    }
  },
  "stocktonsmashburger" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 227-5556",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "stocktonsmashburger",
      "twitterHandle" : "",
      "merchantHandle" : "stocktonsmashburger",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Stockton Smash Burger",
      "city" : "Stockton",
      "companyName" : "Stockton Smash Burger",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "colesbakerycafe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(509) 413-1739",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/3tHauRcIiXE0aNQdrCMUVf9o74oT0VvO.png",
      "instagramHandle" : "colesbakerycafe",
      "premium" : false,
      "merchantHandle" : "colesbakerycafe",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Cole's Bakery & Cafe",
      "city" : "Spokane",
      "companyName" : "Cole's Bakery & Cafe",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.colesbakeryandcafe.com\/",
      "email" : ""
    }
  },
  "kwenchjuicecafe" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(313) 999-0099",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/T0aU6aKxfTchpgz9wPpzad41l0hubxT6.png",
      "instagramHandle" : "kwenchjuicecafe",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "kwenchjuicecafe",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Kwench Juice Cafe",
      "city" : "Detroit",
      "companyName" : "Kwench Juice Cafe",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "villagegreensfarm" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(747) 224-7208",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/wQEO2rGTJgBUQhKRjGzfZjSxL02Tnq3r.png",
      "instagramHandle" : "villagegreensfarm",
      "premium" : false,
      "merchantHandle" : "villagegreensfarm",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Village Greens Farm",
      "city" : "Oxnard",
      "companyName" : "Village Greens Farm",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "eltaquerorestaurant" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 473-1414",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/G38lsOL0f4EbdRQOLR2Shses8ULMdBsi.png",
      "instagramHandle" : "eltaquerorestaurant",
      "twitterHandle" : "",
      "merchantHandle" : "eltaquerorestaurant",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "El Taquero Restaurant",
      "city" : "San Bernardino",
      "companyName" : "El Taquero Restaurant",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "saladmaster" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(619) 656-4459",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/nM3PvNAc2umlu1HLeNzSAFxioNT1FQrk.png",
      "instagramHandle" : "saladmaster",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "saladmaster",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Salad Master",
      "city" : "Chula Vista",
      "companyName" : "Salad Master",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "zogs" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(253) 549-2124",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/2c553c084840ec1812de6ea6a9f27dba485e16f4\/original.png",
      "instagramHandle" : "zogs",
      "twitterHandle" : "",
      "merchantHandle" : "zogs",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Zog’s",
      "city" : "Tacoma",
      "companyName" : "Zog’s",
      "websiteUrl" : "https:\/\/www.facebook.com\/zogsonfoxisland\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "littlebambinospizzainc" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 247-7300",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/c6MRWXQcniKRKjuIJk65pp1wBoy0Ipnh.png",
      "instagramHandle" : "littlebambinospizzainc",
      "merchantHandle" : "littlebambinospizzainc",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Little Bambino's Pizza Inc.",
      "city" : "Moreno Valley",
      "companyName" : "Little Bambino's Pizza Inc.",
      "websiteUrl" : "http:\/\/little-bambinos-pizza-inc.cafes-nearby.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "farmlot" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(833) 210-3089",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/uMEYgjPRD10Q0iczzYKlyKIJDiBDdA5V.png",
      "instagramHandle" : "farmlot",
      "twitterHandle" : "",
      "merchantHandle" : "farmlot",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Farm Lot 59",
      "city" : "Long Beach",
      "companyName" : "Farm Lot 59",
      "websiteUrl" : "http:\/\/www.farmlot59.org\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "redknappsrochester" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(248) 413-5853",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/74cb924dab1e242aa50840373ea54fbe97f6f0f0\/original.jpeg",
      "instagramHandle" : "redknappsrochester",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "redknappsrochester",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Red Knapp's Rochester",
      "city" : "Sterling Heights",
      "companyName" : "Red Knapp's Rochester",
      "websiteUrl" : "http:\/\/www.redknappsrochester.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "beercitybreadco" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 956-7901",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "beercitybreadco",
      "merchantHandle" : "beercitybreadco",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Beer City Bread CO.",
      "city" : "Grand Rapids",
      "companyName" : "Beer City Bread CO.",
      "websiteUrl" : "https:\/\/www.beercitybread.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sandeesdriveinn" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MT\r",
      "phone" : "(406) 969-1900",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "sandeesdriveinn",
      "merchantHandle" : "sandeesdriveinn",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sandee's Drive Inn",
      "city" : "Billings",
      "companyName" : "Sandee's Drive Inn",
      "websiteUrl" : "https:\/\/www.facebook.com\/sandees406\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "savorstonehearthpizzaandwine" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 705-1456",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Xu6zMs8ouT04hP2K9SPRSTdntxh4iViD.png",
      "instagramHandle" : "savorstonehearthpizzaandwine",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "savorstonehearthpizzaandwine",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Savor Stone Hearth Pizza and Wine",
      "city" : "Anaheim",
      "companyName" : "Savor Stone Hearth Pizza and Wine",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.savorpizzaandwine.com\/",
      "email" : ""
    }
  },
  "slowfoxchili" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(360) 721-0634",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/MTrHRSvZICHlEca0TgD50YLJWE4zaQnX.png",
      "instagramHandle" : "slowfoxchili",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "slowfoxchili",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Slow Fox Chili",
      "city" : "Vancouver",
      "companyName" : "Slow Fox Chili",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/slowfoxchili.com\/",
      "email" : ""
    }
  },
  "taqueriajosefina" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "taqueriajosefina",
      "twitterHandle" : "",
      "merchantHandle" : "taqueriajosefina",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Taqueria Josefina",
      "city" : "Fresno",
      "companyName" : "Taqueria Josefina",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "bellwetherbrewing" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(509) 328-0428",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/0ca4c849fad6f540b8b51cd0ffa5711f\/original.png",
      "instagramHandle" : "bellwetherbrewing",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "bellwetherbrewing",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Bellwether Brewing",
      "city" : "Spokane",
      "companyName" : "Bellwether Brewing",
      "websiteUrl" : "http:\/\/www.bellwetherbrewing.net\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "secondbreakfast" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(541) 357-8825",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Yt27DBnSaWWssaE1Qg4dV8BiJEp7EHVl.png",
      "instagramHandle" : "secondbreakfast",
      "premium" : false,
      "merchantHandle" : "secondbreakfast",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Second Breakfast",
      "city" : "Eugene",
      "companyName" : "Second Breakfast",
      "websiteUrl" : "http:\/\/secondbreakfastpies.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "whatscooking" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(734) 744-3215",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/OHOVj5wb0WlWmZQIKURgSaMF5pTXQ0B8.png",
      "instagramHandle" : "whatscooking",
      "premium" : false,
      "merchantHandle" : "whatscooking",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "What's Cooking!",
      "city" : "Ann Arbor",
      "companyName" : "What's Cooking!",
      "websiteUrl" : "https:\/\/www.whatscookinga2.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "fermentality" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(562) 541-1233",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "fermentality",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "fermentality",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Fermentality",
      "city" : "Long Beach",
      "companyName" : "Fermentality",
      "websiteUrl" : "http:\/\/www.fermentalitylab.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "foxhoundspastryden" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(248) 642-0882",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/C3iqNZvLHrr7IMqZvz0Y9OjzLnifsXoA.png",
      "instagramHandle" : "foxhoundspastryden",
      "premium" : false,
      "merchantHandle" : "foxhoundspastryden",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Fox & Hounds Pastry Den",
      "city" : "Warren",
      "companyName" : "Fox & Hounds Pastry Den",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.foxandhoundspastryden.com\/",
      "email" : ""
    }
  },
  "poboyzcajuncreolekitchen" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(503) 206-7304",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/5c773d743c6fecd987b9b67d2e28b1809d91f3d9\/original.png",
      "instagramHandle" : "poboyzcajuncreolekitchen",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "poboyzcajuncreolekitchen",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "POBOYZ Cajun Creole Kitchen",
      "city" : "Portland",
      "companyName" : "POBOYZ Cajun Creole Kitchen",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/poboyz.com\/",
      "email" : ""
    }
  },
  "banginbowls" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(989) 720-2695",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/6444e5c6e1bef18ec3a7847119021cf873345a59\/original.png",
      "instagramHandle" : "banginbowls",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "banginbowls",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Bangin’ Bowls",
      "city" : "Lansing",
      "companyName" : "Bangin’ Bowls",
      "websiteUrl" : "http:\/\/www.banginbowls.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "spectacularspudz" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(810) 285-9953",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/00c87500f2306b4c67770876374939e295f94633\/original.png",
      "instagramHandle" : "spectacularspudz",
      "merchantHandle" : "spectacularspudz",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Spectacular Spudz",
      "city" : "Flint",
      "companyName" : "Spectacular Spudz",
      "websiteUrl" : "http:\/\/www.spectacularspudz.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "mosbyspopcorn" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 260-6466",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/J0fRWwCt1AHr7w9INayTc0PsJuXq7Vww.png",
      "instagramHandle" : "mosbyspopcorn",
      "merchantHandle" : "mosbyspopcorn",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Mosby's Popcorn",
      "city" : "Grand Rapids",
      "companyName" : "Mosby's Popcorn",
      "websiteUrl" : "https:\/\/mosbyspopcorn.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "ketokatzbakery" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(586) 477-0611",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/09514fbd2a36663d3b5be08ffc27c7ed5c0f8521\/original.jpeg",
      "instagramHandle" : "ketokatzbakery",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "ketokatzbakery",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Keto Katz Bakery",
      "city" : "Sterling Heights",
      "companyName" : "Keto Katz Bakery",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.ketokatz.com\/",
      "email" : ""
    }
  },
  "tasteofbeauty" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 587-9113",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/B50aVPPC5ckX9nbmtC4BY0teFr41q2zK.png",
      "instagramHandle" : "tasteofbeauty",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "tasteofbeauty",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Taste of Beauty",
      "city" : "Santa Ana",
      "companyName" : "Taste of Beauty",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/thetasteofbeauty.com\/",
      "email" : ""
    }
  },
  "partydoll" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 885-0333",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/5c813a0eccc815e8ffde2423e9fe471ce4093fb1\/original.jpeg",
      "instagramHandle" : "partydoll",
      "merchantHandle" : "partydoll",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Party Doll",
      "city" : "San Bernardino",
      "companyName" : "Party Doll",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "applebeesgrillbar" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 854-1003",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/PloGKNXoKkia7gTtHVDqDbUWUHi4niIh.png",
      "instagramHandle" : "applebeesgrillbar",
      "premium" : false,
      "merchantHandle" : "applebeesgrillbar",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Applebee's Grill + Bar",
      "city" : "Fontana",
      "companyName" : "Applebee's Grill + Bar",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/restaurants.applebees.com\/en-us\/ca\/fontana\/16867-sierra-lakes-parkway-79064?utm_source=google&utm_medium=organic&utm_campaign=google_my_business&utm_term=79064&utm_content=website",
      "email" : ""
    }
  },
  "campfirefarms" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(503) 908-4647",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/AVW4SCxasbrnJFxme4ChUd8NwzcOsUpd.png",
      "instagramHandle" : "campfirefarms",
      "merchantHandle" : "campfirefarms",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Campfire Farms",
      "city" : "Portland",
      "companyName" : "Campfire Farms",
      "websiteUrl" : "http:\/\/www.campfirefarms.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "berolinabakerypastryshop" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(818) 249-6506",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "berolinabakerypastryshop",
      "twitterHandle" : "",
      "merchantHandle" : "berolinabakerypastryshop",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Berolina Bakery & Pastry Shop",
      "city" : "Glendale",
      "companyName" : "Berolina Bakery & Pastry Shop",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/berolinabakery.com\/",
      "email" : ""
    }
  },
  "robyngraycounseling" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(916) 572-7243",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "robyngraycounseling",
      "merchantHandle" : "robyngraycounseling",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Robyn Gray Counseling",
      "city" : "Sacramento",
      "companyName" : "Robyn Gray Counseling",
      "websiteUrl" : "http:\/\/robyngraycounseling.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "genesiscoffeeco" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(509) 720-7101",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/QaqSoXtvtQtYZBy06XEZtz80E6f1arwx.png",
      "instagramHandle" : "genesiscoffeeco",
      "merchantHandle" : "genesiscoffeeco",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Genesis Coffee Co",
      "city" : "Spokane",
      "companyName" : "Genesis Coffee Co",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "riversidefoodlab" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/rMO2LlLv7mu0qMaYWCPpEt1TlwmsAEGG.png",
      "instagramHandle" : "riversidefoodlab",
      "premium" : false,
      "merchantHandle" : "riversidefoodlab",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Riverside Food Lab",
      "city" : "Riverside",
      "companyName" : "Riverside Food Lab",
      "websiteUrl" : "http:\/\/www.riversidefoodlab.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sweetlorraineslatkesmore" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(971) 319-5398",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "sweetlorraineslatkesmore",
      "merchantHandle" : "sweetlorraineslatkesmore",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Sweet Lorraine's Latkes & More",
      "city" : "Portland",
      "companyName" : "Sweet Lorraine's Latkes & More",
      "websiteUrl" : "https:\/\/sweetlorraineslatkes.squarespace.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thetinytiki" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/2Bik30J1eHadXNzMJCE1sVE8ZCWZPaba.png",
      "instagramHandle" : "thetinytiki",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "thetinytiki",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Tiny Tiki",
      "city" : "Spokane",
      "companyName" : "The Tiny Tiki",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "tacorosa" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 505-6080",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/GMN6MD88xWQncTJao0eXp8B0wurbR3Nb.png",
      "instagramHandle" : "tacorosa",
      "merchantHandle" : "tacorosa",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Taco Rosa",
      "city" : "Santa Ana",
      "companyName" : "Taco Rosa",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.tacorosa.com\/",
      "email" : ""
    }
  },
  "siporganicjuicebar" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 301-4554",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Bpl9virxpmO9HID84pPCriHNOxkoxF0Z.png",
      "instagramHandle" : "siporganicjuicebar",
      "premium" : false,
      "merchantHandle" : "siporganicjuicebar",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sip Organic Juice Bar",
      "city" : "Grand Rapids",
      "companyName" : "Sip Organic Juice Bar",
      "websiteUrl" : "http:\/\/www.siporganicjuicebar.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "chefbuddablasiansoulfood" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(619) 494-7287",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/NmsB66DxA01r9wlxsvwZbUH52Oq1t9e9.png",
      "instagramHandle" : "chefbuddablasiansoulfood",
      "twitterHandle" : "",
      "merchantHandle" : "chefbuddablasiansoulfood",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Chef Budda Blasian Soul Food",
      "city" : "Chula Vista",
      "companyName" : "Chef Budda Blasian Soul Food",
      "websiteUrl" : "http:\/\/chefbuddablasiansoulfood.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "newgrowthproject" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(616) 824-6068",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/mnCcFZEgPCD8pg9682QmjflvkYxUDCQ1.png",
      "instagramHandle" : "newgrowthproject",
      "premium" : false,
      "merchantHandle" : "newgrowthproject",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "New Growth Project",
      "city" : "Grand Rapids",
      "companyName" : "New Growth Project",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.thenewgrowthproject.org\/",
      "email" : ""
    }
  },
  "seescandies" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(253) 475-4959",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/br6AHyv2XyrvNsf4scWKOp0Rv8gYOAGr.png",
      "instagramHandle" : "seescandies",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "seescandies",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "See's Candies",
      "city" : "Tacoma",
      "companyName" : "See's Candies",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/chocolateshops.sees.com\/wa\/tacoma\/chocolate_shops_tacoma_wa_2wa002.html",
      "email" : ""
    }
  },
  "botanicalbakeshop" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(734) 280-7676",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/QxMWTKGHDXK3V00gClxxzIA7Rj7Gwl15.png",
      "instagramHandle" : "botanicalbakeshop",
      "premium" : false,
      "merchantHandle" : "botanicalbakeshop",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Botanical Bakeshop",
      "city" : "Ann Arbor",
      "companyName" : "Botanical Bakeshop",
      "websiteUrl" : "http:\/\/www.botanicalbakeshop.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thebrewcafe" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(517) 899-1995",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/sRPRjyRDElfHBCSwagwBMtgBNyjqEu4O.jpg",
      "instagramHandle" : "thebrewcafe",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "thebrewcafe",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Brew Cafe",
      "city" : "Lansing",
      "companyName" : "The Brew Cafe",
      "websiteUrl" : "https:\/\/thebrewcafe.net\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "northsideshackpointloma" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(619) 738-8233",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/TgMtFgtZeVJVaWJAV09WtkK9Tq3xEuKf.png",
      "instagramHandle" : "northsideshackpointloma",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "northsideshackpointloma",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Northside Shack - Point Loma",
      "city" : "Chula Vista",
      "companyName" : "Northside Shack - Point Loma",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/northsideshack.com\/",
      "email" : ""
    }
  },
  "poshinescafedelasoul" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(503) 978-9000",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/b71bcdd90ff259d6111e038b1b0c1f3fce4091a8\/original.jpeg",
      "instagramHandle" : "poshinescafedelasoul",
      "merchantHandle" : "poshinescafedelasoul",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Po' Shines Cafe De La Soul",
      "city" : "Vancouver",
      "companyName" : "Po' Shines Cafe De La Soul",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/poshinespdx.com\/",
      "email" : ""
    }
  },
  "crumblstockton" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 337-3337",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/FrllCig4z8BEa4WRvDV9HzxAdDATdSfT.png",
      "instagramHandle" : "crumblstockton",
      "twitterHandle" : "",
      "merchantHandle" : "crumblstockton",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Crumbl - Stockton",
      "city" : "Stockton",
      "companyName" : "Crumbl - Stockton",
      "websiteUrl" : "https:\/\/crumblcookies.com\/castockton?utm_source=google+business&utm_medium=profile&utm_campaign=CRUMBL-US+%7C+GOOGLE+BUSINESS+%7C+STORE+PAGE+%7C+ONGOING+%7C+CONSIDERATION+%7C+PROFILE",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "stocktownnutrition" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 639-4142",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/57794e8dde5e0f65377e6a804c6fece6d911d3b5\/original.jpeg",
      "instagramHandle" : "stocktownnutrition",
      "merchantHandle" : "stocktownnutrition",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Stocktown Nutrition 209",
      "city" : "Stockton",
      "companyName" : "Stocktown Nutrition 209",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "pokiliciousmorenovalley" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(951) 247-3663",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/EQXMWN9Gs0ioGHikzUkgN1vWNalRzkH4.png",
      "instagramHandle" : "pokiliciousmorenovalley",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "pokiliciousmorenovalley",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "POKILICIOUS MORENO VALLEY",
      "city" : "Moreno Valley",
      "companyName" : "POKILICIOUS MORENO VALLEY",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "charrosstreettacos" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(253) 883-9355",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/jV5AL7LZQ0ymor0Ko2VwcQQahuYxLX0F.png",
      "instagramHandle" : "charrosstreettacos",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "charrosstreettacos",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Charro's Street Tacos",
      "city" : "Vancouver",
      "companyName" : "Charro's Street Tacos",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/charrostacos.com\/",
      "email" : ""
    }
  },
  "autozoneautoparts" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 487-4883",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/f7d4d99e7ef94ed8da604ab9ddfeb6cc99ebde2d\/original.jpeg",
      "instagramHandle" : "autozoneautoparts",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "autozoneautoparts",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "AutoZone Auto Parts",
      "city" : "Oxnard",
      "companyName" : "AutoZone Auto Parts",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.autozone.com\/locations\/ca\/oxnard\/2411-saviers-rd.html?cmpid=LOC:US:EN:AD:NL:1000000:GEN:5714",
      "email" : ""
    }
  },
  "simplysweets" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(360) 896-7321",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/db7eead1e097633a8ea75d2a8db75e40dceb7d2e\/original.png",
      "instagramHandle" : "simplysweets",
      "twitterHandle" : "",
      "merchantHandle" : "simplysweets",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Simply Sweets",
      "city" : "Vancouver",
      "companyName" : "Simply Sweets",
      "websiteUrl" : "http:\/\/www.simplysweetsbyjen.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "coquelicotestatevineyardtastingroom" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 688-1500",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/naCt6dd9OFvlaBkTad0gKqjEqWQwnIMx.png",
      "instagramHandle" : "coquelicotestatevineyardtastingroom",
      "merchantHandle" : "coquelicotestatevineyardtastingroom",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Coquelicot Estate Vineyard Tasting Room",
      "city" : "Oxnard",
      "companyName" : "Coquelicot Estate Vineyard Tasting Room",
      "websiteUrl" : "https:\/\/www.coquelicotwines.com\/?utm_source=GBM&utm_medium=google+my+business",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "throughthickorthinfitnessnutrition" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(509) 903-3925",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/8Cmi86cqipbap01b4uGWM0TfDsfYaID0.png",
      "instagramHandle" : "throughthickorthinfitnessnutrition",
      "merchantHandle" : "throughthickorthinfitnessnutrition",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Through Thick or Thin Fitness & Nutrition",
      "city" : "Spokane",
      "companyName" : "Through Thick or Thin Fitness & Nutrition",
      "websiteUrl" : "https:\/\/throughthickorthin.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "macysespotkiosk" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "macysespotkiosk",
      "merchantHandle" : "macysespotkiosk",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Macy's eSpot Kiosk",
      "city" : "San Bernardino",
      "companyName" : "Macy's eSpot Kiosk",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www1.macys.com\/shop\/store\/search?location=92408",
      "email" : ""
    }
  },
  "juliepfeifle" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(586) 314-1400",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "juliepfeifle",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "juliepfeifle",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Julie Pfeifle",
      "city" : "Sterling Heights",
      "companyName" : "Julie Pfeifle",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "hokeepoke" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 629-9211",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/H5rEA2RGR0kez6finMQB4nyOrUhWreAa.png",
      "instagramHandle" : "hokeepoke",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "hokeepoke",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Hokee Poke",
      "city" : "Sacramento",
      "companyName" : "Hokee Poke",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/hokeepoke.com\/",
      "email" : ""
    }
  },
  "theproteinspot" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(206) 850-6173",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "theproteinspot",
      "merchantHandle" : "theproteinspot",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Protein Spot",
      "city" : "Tacoma",
      "companyName" : "The Protein Spot",
      "websiteUrl" : "https:\/\/instagram.com\/thepr_oteinspot?igshid=Y2ZmNzg0YzQ=",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thegreatnorth" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(360) 217-9005",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/8S5LYSLv5C0BfcGxs490WP91B8TfHd4R.png",
      "instagramHandle" : "thegreatnorth",
      "merchantHandle" : "thegreatnorth",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Great North",
      "city" : "Vancouver",
      "companyName" : "The Great North",
      "websiteUrl" : "https:\/\/www.thegreatnorthcoffee.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "lifeissweetbakerycafe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(734) 628-7185",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/acf3c7edde2d62e70cfdbd83e69d16f272aef808\/original.jpeg",
      "instagramHandle" : "lifeissweetbakerycafe",
      "twitterHandle" : "",
      "merchantHandle" : "lifeissweetbakerycafe",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Life is Sweet Bakery & Cafe",
      "city" : "Ann Arbor",
      "companyName" : "Life is Sweet Bakery & Cafe",
      "websiteUrl" : "http:\/\/www.mplifeissweet.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "cafe" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 996-8001",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/c503916195223c8dfbe5a15be7d63df1a351e24d\/original.png",
      "instagramHandle" : "cafe",
      "merchantHandle" : "cafe",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "Cafe",
      "merchantName" : "301 Cafe",
      "city" : "Anaheim",
      "companyName" : "301 Cafe",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "lovebyslicebakingcateringcompany" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(253) 588-2253",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/0add5e33d2648b394c502b82b8caa7126dfc9467\/original.jpeg",
      "instagramHandle" : "lovebyslicebakingcateringcompany",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "lovebyslicebakingcateringcompany",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Love by the Slice Baking & Catering Company",
      "city" : "Tacoma",
      "companyName" : "Love by the Slice Baking & Catering Company",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.lovebytheslice.com\/",
      "email" : ""
    }
  },
  "prontopupgrandrapids" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(231) 527-5569",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/dgrrKRMdYZgztsOuWXtADDftpIBooT6x.png",
      "instagramHandle" : "prontopupgrandrapids",
      "premium" : false,
      "merchantHandle" : "prontopupgrandrapids",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Pronto Pup Grand Rapids",
      "city" : "Grand Rapids",
      "companyName" : "Pronto Pup Grand Rapids",
      "websiteUrl" : "https:\/\/www.prontopupgr.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "macattack" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 854-7700",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/68701e351c74aa24e738891b5ef49353725a18bd\/original.png",
      "instagramHandle" : "macattack",
      "premium" : false,
      "merchantHandle" : "macattack",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Mac Attack",
      "city" : "San Francisco",
      "companyName" : "Mac Attack",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "ashouseofsmokencatering" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 263-8216",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "ashouseofsmokencatering",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "ashouseofsmokencatering",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "A's House of Smoke N Catering",
      "city" : "Stockton",
      "companyName" : "A's House of Smoke N Catering",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "themerchant" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(562) 317-5155",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/6lKsU7HGa2d2b9mSWeW6maDjvs5FjjUn.png",
      "instagramHandle" : "themerchant",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "themerchant",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Merchant",
      "city" : "Long Beach",
      "companyName" : "The Merchant",
      "websiteUrl" : "https:\/\/www.themerchantlb.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "girlcoded" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/ff1d5cd4fc36ab3afab1a26dd3ed171714d6fc5c\/original.png",
      "instagramHandle" : "girlcoded",
      "twitterHandle" : "",
      "merchantHandle" : "girlcoded",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Girlcoded",
      "city" : "Long Beach",
      "companyName" : "Girlcoded",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "littlegianticecream" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/GgN3gqLW60YSCtD00zpNmGLZGVAz01Nn.png",
      "instagramHandle" : "littlegianticecream",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "littlegianticecream",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Little Giant Ice Cream",
      "city" : "Oakland",
      "companyName" : "Little Giant Ice Cream",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "troveprovisions" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(253) 820-9263",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/QbzhTwGCje6ETxfXrLrXUvAkKSUmbwoD.png",
      "instagramHandle" : "troveprovisions",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "troveprovisions",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Trove Provisions",
      "city" : "Tacoma",
      "companyName" : "Trove Provisions",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.troveprovisions.com\/",
      "email" : ""
    }
  },
  "aaijunglebowlscafe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(818) 859-7199",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/31LuQo0aNdJKUJRr1psF1x1ES3s3yPtn.png",
      "instagramHandle" : "aaijunglebowlscafe",
      "merchantHandle" : "aaijunglebowlscafe",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Açai Jungle Bowls Cafe",
      "city" : "Glendale",
      "companyName" : "Açai Jungle Bowls Cafe",
      "websiteUrl" : "http:\/\/acaijunglebowls.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "wildhareorganicfarm" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(253) 778-6257",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/c07d5a45d6aa41bb2f256b90df20aebd\/original.jpeg",
      "instagramHandle" : "wildhareorganicfarm",
      "twitterHandle" : "",
      "merchantHandle" : "wildhareorganicfarm",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Wild Hare Organic Farm",
      "city" : "Tacoma",
      "companyName" : "Wild Hare Organic Farm",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.wildhareorganicfarm.com\/",
      "email" : ""
    }
  },
  "triunecoffee" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 369-2128",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/a6b96d752078b359c603555abedb3878e6b8aa7c\/original.png",
      "instagramHandle" : "triunecoffee",
      "twitterHandle" : "",
      "merchantHandle" : "triunecoffee",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Triune Coffee",
      "city" : "Huntington Beach",
      "companyName" : "Triune Coffee",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "choosingjoy" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 848-9319",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/fwwsmRnQ8wHCSqT2yy7qUdB99vIPAYzx.png",
      "instagramHandle" : "choosingjoy",
      "merchantHandle" : "choosingjoy",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Choosing Joy",
      "city" : "Huntington Beach",
      "companyName" : "Choosing Joy",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "aligndesign" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(616) 916-1071",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/GeFdCgUPVfte1CnaaT4hUlfqr8kQWehw.png",
      "instagramHandle" : "aligndesign",
      "merchantHandle" : "aligndesign",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Align Design",
      "city" : "Grand Rapids",
      "companyName" : "Align Design",
      "websiteUrl" : "http:\/\/aligndesigngr.com",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "voyagersanpedrosquare" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(408) 899-6166",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/fa817dc93e2fad1e140e1c4161df5d4aa4c814db\/original.jpeg",
      "instagramHandle" : "voyagersanpedrosquare",
      "merchantHandle" : "voyagersanpedrosquare",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Voyager-San Pedro Square",
      "city" : "San Jose",
      "companyName" : "Voyager-San Pedro Square",
      "websiteUrl" : "http:\/\/voyagercraftcoffee.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "dreyersgrandicecreamtruckentrance" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(661) 398-3599",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "dreyersgrandicecreamtruckentrance",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "dreyersgrandicecreamtruckentrance",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Dreyer's Grand Ice Cream~truck entrance",
      "city" : "Bakersfield",
      "companyName" : "Dreyer's Grand Ice Cream~truck entrance",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.dreyers.com\/",
      "email" : ""
    }
  },
  "dosecafe" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/7HCSONtSo9GL5270OQ0t0H61HO6VDS0V.png",
      "instagramHandle" : "dosecafe",
      "merchantHandle" : "dosecafe",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Dose Cafe",
      "city" : "Anaheim",
      "companyName" : "Dose Cafe",
      "websiteUrl" : "http:\/\/www.dosecafe.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "solarcbrewing" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/mi6el6kJNZdZTu1w4HsaWG9nQmaJfl0G.png",
      "instagramHandle" : "solarcbrewing",
      "merchantHandle" : "solarcbrewing",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Solarc Brewing",
      "city" : "Glendale",
      "companyName" : "Solarc Brewing",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/solarcbrewing.com\/",
      "email" : ""
    }
  },
  "blackknightlounge" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(949) 646-2401",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ABygeHoaPpdZ7H5kWlhAaNy1ReJ4l9cm.png",
      "instagramHandle" : "blackknightlounge",
      "twitterHandle" : "",
      "merchantHandle" : "blackknightlounge",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Black Knight Lounge",
      "city" : "Huntington Beach",
      "companyName" : "Black Knight Lounge",
      "websiteUrl" : "https:\/\/www.blackknightlounge.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "hobcoffeeeastvillage" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(619) 269-2887",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/01ad1a5bb9686de736c0a5dae505786a12cb3d75\/original.png",
      "instagramHandle" : "hobcoffeeeastvillage",
      "twitterHandle" : "",
      "merchantHandle" : "hobcoffeeeastvillage",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Hob Coffee East Village",
      "city" : "Chula Vista",
      "companyName" : "Hob Coffee East Village",
      "websiteUrl" : "http:\/\/hobcoffee.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "hawaiianpokebowl" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(408) 564-8393",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/rgKD6VSjnh7Aaq42BQ8TDC62deLqHZ20.png",
      "instagramHandle" : "hawaiianpokebowl",
      "premium" : false,
      "merchantHandle" : "hawaiianpokebowl",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Hawaiian Poke Bowl",
      "city" : "San Jose",
      "companyName" : "Hawaiian Poke Bowl",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "tiogasequoiabeergarden" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(559) 486-2337",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/MZH1ySiHe3H1oK51dDq74BXMOdFEZCjH.png",
      "instagramHandle" : "tiogasequoiabeergarden",
      "merchantHandle" : "tiogasequoiabeergarden",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Tioga-Sequoia Beer Garden",
      "city" : "Fresno",
      "companyName" : "Tioga-Sequoia Beer Garden",
      "websiteUrl" : "http:\/\/www.tiogasequoia.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "donchentetacosnbeer" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(562) 806-9100",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/eLSFS5IYAK2KMSMmSAgL3oYZ8sDdsu4s.png",
      "instagramHandle" : "donchentetacosnbeer",
      "premium" : false,
      "merchantHandle" : "donchentetacosnbeer",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Don Chente Tacos N Beer",
      "city" : "Long Beach",
      "companyName" : "Don Chente Tacos N Beer",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "tokyosando" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(971) 254-3744",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/hrVNasyKvYwERhRGvchzaomuQlgVqvhl.png",
      "instagramHandle" : "tokyosando",
      "merchantHandle" : "tokyosando",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Tokyo Sando",
      "city" : "Portland",
      "companyName" : "Tokyo Sando",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.tokyosando.com\/",
      "email" : ""
    }
  },
  "oceanviewbrewworks" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(510) 280-5127",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/wp6mFwSJUqe3v5xqGnEMiW0q9Glh00j1.png",
      "instagramHandle" : "oceanviewbrewworks",
      "premium" : false,
      "merchantHandle" : "oceanviewbrewworks",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Ocean View Brew Works",
      "city" : "San Jose",
      "companyName" : "Ocean View Brew Works",
      "websiteUrl" : "http:\/\/www.oceanviewbrews.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "blackbird" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 872-5310",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/ee82a1b8a8a2999b2ef665f8ab77a65d4743fb6b\/original.jpeg",
      "instagramHandle" : "blackbird",
      "merchantHandle" : "blackbird",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Blackbird",
      "city" : "San Francisco",
      "companyName" : "Blackbird",
      "websiteUrl" : "http:\/\/www.blackbirdbar.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "lavenadita" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 400-4676",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ajHWOxD0uUu0kyNmUf0SdGvTNVRkq8HV.png",
      "instagramHandle" : "lavenadita",
      "merchantHandle" : "lavenadita",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "La Venadita",
      "city" : "Sacramento",
      "companyName" : "La Venadita",
      "websiteUrl" : "http:\/\/lavenaditasac.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "kavacafebakery" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(226) 782-4448",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/7a4c53a8e4e565941f012d8ae6320b962c727050\/original.jpeg",
      "instagramHandle" : "kavacafebakery",
      "merchantHandle" : "kavacafebakery",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Kava Cafe & Bakery",
      "city" : "Detroit",
      "companyName" : "Kava Cafe & Bakery",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.cafekava.ca\/",
      "email" : ""
    }
  },
  "brotherscascadiabrewing" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(360) 718-8927",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/QEBqtkOsawcyjMguy5k6maWhvMkjRyCu.png",
      "instagramHandle" : "brotherscascadiabrewing",
      "premium" : false,
      "merchantHandle" : "brotherscascadiabrewing",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Brothers Cascadia Brewing",
      "city" : "Vancouver",
      "companyName" : "Brothers Cascadia Brewing",
      "websiteUrl" : "https:\/\/www.brotherscascadiabrewing.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "barriobird" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/sZhfvGWxyuhCs6Lxw0LNvJnXC3pp50eC.png",
      "instagramHandle" : "barriobird",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "barriobird",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Barrio Bird",
      "city" : "Chula Vista",
      "companyName" : "Barrio Bird",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "ledoglasoup" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(734) 327-0091",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/04f2e47f4b85b5d4f62b3bc7dcb652e0da0d7e55\/original.jpeg",
      "instagramHandle" : "ledoglasoup",
      "twitterHandle" : "",
      "merchantHandle" : "ledoglasoup",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Le Dog = La Soup",
      "city" : "Ann Arbor",
      "companyName" : "Le Dog = La Soup",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "subsalicious" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(408) 628-4038",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/bZEOx50H0f5y30BpKhi0n0X4O0na8jqQ.png",
      "instagramHandle" : "subsalicious",
      "twitterHandle" : "",
      "merchantHandle" : "subsalicious",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Subs A Licious",
      "city" : "San Jose",
      "companyName" : "Subs A Licious",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "builtrightecoworkshopllc" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(407) 497-8439",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/xMnPaGfUS1cJFnKwwjTvbnyGHghiJSxV.png",
      "instagramHandle" : "builtrightecoworkshopllc",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "builtrightecoworkshopllc",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Built Right Eco Workshop LLC",
      "city" : "Tacoma",
      "companyName" : "Built Right Eco Workshop LLC",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/builtrtecoworkshop.com\/",
      "email" : ""
    }
  },
  "bayoucasinoliquorstore" : {
    "info" : {
      "state" : "MT\r",
      "posSystem" : "Square",
      "phone" : "(406) 272-8333",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/A1qXiZYAeGj54eiScaUrYDHjhRNH4Al0.png",
      "instagramHandle" : "bayoucasinoliquorstore",
      "merchantHandle" : "bayoucasinoliquorstore",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Bayou Casino & Liquor Store",
      "city" : "Billings",
      "companyName" : "Bayou Casino & Liquor Store",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thebistrowillamette" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(503) 370-6900",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/KdSHalzTjGOFuYvxwu8stSjpns4CnEm4.png",
      "instagramHandle" : "thebistrowillamette",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "thebistrowillamette",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Bistro Willamette",
      "city" : "Salem",
      "companyName" : "The Bistro Willamette",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/willamette.edu\/offices\/bistro\/index.html",
      "email" : ""
    }
  },
  "everydayeatery" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(949) 418-7172",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/swKpncbWEAzdNHgsj6aazU0KXEtMMXu5.png",
      "instagramHandle" : "everydayeatery",
      "premium" : false,
      "merchantHandle" : "everydayeatery",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Everyday Eatery",
      "city" : "Irvine",
      "companyName" : "Everyday Eatery",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/everydayeatery.com\/",
      "email" : ""
    }
  },
  "kali" : {
    "promos" : {
      "kali-kekQV" : {
        "discount_details" : {
          "value" : "2",
          "discount_type" : "percentage"
        },
        "style" : {
          "secondarycolor" : "BB0AB5",
          "imagename" : "espresso.png",
          "promotype" : "simplified",
          "primarycolor" : "EEC801"
        },
        "status" : "ACTIVE",
        "dateend" : "05-20-2024",
        "discount_version" : "1714511717120",
        "date_created" : "4\/30\/2024",
        "promomessage" : "Buy one espresso at full price, and get one free!",
        "campaign_name" : "Kali",
        "promoshowterms" : "YES",
        "pricing_rules_version" : "1714511718073",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "04-24-2024",
        "merchantname" : "kali",
        "promoimageheader" : "",
        "promoname" : "kali-kekQV",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
        "promotitle" : "Buy one get one free!",
        "redemptionlimit" : "10",
        "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
        "promobuttontext" : "Redeem",
        "squareloyaltyID" : "",
        "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
        "timezone" : "",
        "datetimestart" : ""
      },
      "kali-pctdy" : {
        "style" : {
          "secondarycolor" : "BB0AB5",
          "imagename" : "espresso.png",
          "promotype" : "single-color",
          "primarycolor" : "FFD600"
        },
        "status" : "ACTIVE",
        "dateend" : "04-27-2024",
        "discount_version" : "1714087328770",
        "date_created" : "4\/26\/2024",
        "promomessage" : "Buy one espresso at full price, and get one free!",
        "campaign_name" : "ADD ONE",
        "promoshowterms" : "YES",
        "pricing_rules_version" : "1714087329540",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "04-24-2024",
        "merchantname" : "kali",
        "promoimageheader" : "",
        "promoname" : "kali-pctdy",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "customer_group_id" : "37RT95TN5VJ0TVW24JDET8ZGR4",
        "promotitle" : "Buy one get one free!",
        "redemptionlimit" : "10",
        "discount_id" : "SMM7HSBILDKN2RNTUUT67ZSZ",
        "promobuttontext" : "Redeem",
        "squareloyaltyID" : "",
        "pricing_rules_id" : "PFL5QMIMAJRJA4NJOJTQ7QNK",
        "timezone" : "",
        "datetimestart" : ""
      },
      "kali-q6UGw" : {
        "discount_details" : {
          "value" : "20",
          "discount_type" : "percentage"
        },
        "style" : {
          "secondarycolor" : "BB0AB5",
          "imagename" : "espresso.png",
          "promotype" : "single-color",
          "primarycolor" : "FFD600"
        },
        "status" : "ACTIVE",
        "dateend" : "04-27-2024",
        "discount_version" : "1714511902704",
        "date_created" : "4\/30\/2024",
        "promomessage" : "Buy one espresso at full price, and get one free!",
        "campaign_name" : "testing la vi",
        "promoshowterms" : "YES",
        "pricing_rules_version" : "1714511903551",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "04-24-2024",
        "merchantname" : "kali",
        "promoimageheader" : "",
        "promoname" : "kali-q6UGw",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "customer_group_id" : "52KC2275Y31N8TXWYBYSSKCPTK",
        "promotitle" : "Buy one get one free!",
        "redemptionlimit" : "10",
        "discount_id" : "7NJWKVFIX42ZY4NZRCDQX4YZ",
        "promobuttontext" : "Redeem",
        "squareloyaltyID" : "",
        "pricing_rules_id" : "FZET4BWMP2YWGI5ASOYUMQJZ",
        "timezone" : "",
        "datetimestart" : ""
      },
      "kali-mfN7h" : {
        "style" : {
          "secondarycolor" : "BB0AB5",
          "imagename" : "espresso.png",
          "promotype" : "single-color",
          "primarycolor" : "FFD600"
        },
        "status" : "ACTIVE",
        "dateend" : "04-26-2024",
        "discount_version" : "1714085524819",
        "date_created" : "4\/25\/2024",
        "promomessage" : "Buy one espresso at full price, and get one free!",
        "promoshowterms" : "YES",
        "promotype" : "promotion",
        "pricing_rules_version" : "1714085525933",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "04-24-2024",
        "merchantname" : "kali",
        "promoimageheader" : "",
        "promoname" : "kali-mfN7h",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "customer_group_id" : "159539FYEMFWKFG2F4F3Z7SACA",
        "promotitle" : "Buy one get one free!",
        "redemptionlimit" : "10",
        "discount_id" : "XJKVFVCD3MAFCNUSBKVA3EJY",
        "promobuttontext" : "Redeem",
        "squareloyaltyID" : "",
        "pricing_rules_id" : "OYDE3TIFS7JZRCUDQTO3KSJK",
        "timezone" : "",
        "datetimestart" : ""
      },
      "kali-hxzr3" : {
        "style" : {
          "secondarycolor" : "BB0AB5",
          "imagename" : "espresso.png",
          "promotype" : "single-color",
          "primarycolor" : "FFD600"
        },
        "status" : "ACTIVE",
        "dateend" : "05-21-2024",
        "discount_version" : "1714087741843",
        "date_created" : "5\/28\/2024",
        "promomessage" : "Buy one espresso at full price, and get one free!!",
        "campaign_name" : "Boris Boyle",
        "promoshowterms" : "YES",
        "pricing_rules_version" : "1714087742664",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "04-25-2024",
        "merchantname" : "kali",
        "promoimageheader" : "",
        "promoname" : "kali-eAPoT",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
        "promotitle" : "Buy one get one free!",
        "redemptionlimit" : "12",
        "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
        "promobuttontext" : "Redeem",
        "squareloyaltyID" : "",
        "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
        "timezone" : "",
        "datetimestart" : ""
      },
      "kali-wb8h8" : {
        "discount_details" : {
          "value" : "20",
          "discount_type" : "percentage"
        },
        "style" : {
          "secondarycolor" : "BB0AB8",
          "imagename" : "cookie.png",
          "promotype" : "2-gradient",
          "primarycolor" : "423F32"
        },
        "status" : "ACTIVE",
        "dateend" : "02-17-2222",
        "discount_version" : "1714176850699",
        "date_created" : "5\/1\/2024",
        "promomessage" : "Buy one espresso at full price, and get one free!32024",
        "campaign_name" : "testing bro",
        "promoshowterms" : "YES",
        "pricing_rules_version" : "1714176851775",
        "promotype" : "promotion",
        "square_loyalty_program_id" : "",
        "datetimeend" : "",
        "datestart" : "02-17-2222",
        "merchantname" : "kali",
        "promoimageheader" : "",
        "promoname" : "kali-5vYG1",
        "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
        "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
        "promotitle" : "Buy one get one free!!!!",
        "redemptionlimit" : "10",
        "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
        "promobuttontext" : "Redeem",
        "squareloyaltyID" : "",
        "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
        "timezone" : "",
        "datetimestart" : ""
      }
    },
    "info" : {
      "posKey" : "-",
      "instagramHandle" : "",
      "squareSandboxKey" : "EAAAEYwCoYgVyO8jQYoUWDeCXf2JecLzmy68h0yeNbGeMP4lfZH84OTu2OjKAL6b",
      "squareProductionKey" : "EAAAEYwCoYgVyO8jQYoUWDeCXf2JecLzmy68h0yeNbGeMP4lfZH84OTu2OjKAL6b",
      "merchantHandle" : "kali",
      "companyName" : "Kali",
      "posSystem" : "Square",
      "merchantName" : "Kali",
      "tikTokHandle" : "",
      "twitterHandle" : "",
      "websiteUrl" : "kali.tn"
    },
    "analytics" : {
      "records" : {
        "landing-page" : {
          "ePod3CRr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:42:00.518Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "vVT6gF4C" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:30.981Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "qXGfHgEs" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:23:15.055Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "wfUyqRtn" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:25:15.426Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "2MbZuq5V" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:07:15.228Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "u6UEYqKN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-21T00:08:39.562Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "qk92gRaU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.694Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "7wiWunQ5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.443Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "3fxRRSeb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:53:22.588Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "pnQaKvRR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.998Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "byFQknmn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:55.905Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "mKhnioJW" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T01:28:06.692Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "67G2M4D6" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T01:25:49.886Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "rbmnBv85" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:18:41.803Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "27hWtWiG" : {
            "pathname" : "\/kali",
            "time" : "2024-05-28T22:42:29.359Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "5LQDrZrB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:06.191Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "voLHpyWG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.695Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "7jjdP54B" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:31:41.329Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "icFN4tQv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-25T23:29:03.838Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "tyyJsHHt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:27.856Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "u1YQmsg1" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:21:12.781Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "5K8a7gYs" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.014Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "tFwm3rKK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:16.625Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "4WRKVuVQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:01:18.848Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "qnRKz9F6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.693Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "dWEpjttY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:47:53.086Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "m7jYvtUc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.649Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "s4Az4z2Y" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.121Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "7aYjHhAR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:16:07.796Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "2FpUe3qd" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T01:31:34.477Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "4UguvuxZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:39.264Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "74qjh7Qw" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:33:53.763Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "d9mvnmXk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:00:32.452Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "ndx8dQMN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:22.279Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "pnvh7egQ" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:20:15.986Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "ccZYv396" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.439Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "wgKhPwG3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:48.189Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "2pvduYjU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:34:43.539Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "odQyxrDQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:36.390Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "6T3DXFL7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-30T21:18:24.698Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "3PzqyCyV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:28.964Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "pGBVENV3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:07:15.230Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "4tRXEVKM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:29:21.957Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "9Vs79nDj" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:19:32.296Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "6xyhHrhD" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:25:25.543Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "sxn7F52P" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:08.515Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "tbbkhDRw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:16.237Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "une69Yt6" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:31:52.587Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "dUa15NWo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.112Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "88vWJAZG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:26.873Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "hAWfWYvF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-25T23:29:03.841Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "6ugdvv4U" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.252Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "rGC2PhC5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.115Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "dDnQuo2V" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.017Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "sjkF6hqv" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T01:31:34.479Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "mD3wnTcw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:34.222Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "o5tU73KU" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:31:52.584Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "nBsYixXe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.730Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "gCSmwPhE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.480Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "ipSD53FJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-30T21:18:24.694Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "TB1Ehnef" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T02:43:21.978Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "5jtHS5Rd" : {
            "pathname" : "\/kali",
            "time" : "2024-05-28T22:42:56.742Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "hh6bsaXM" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:21:12.786Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "2GGprjag" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T01:27:20.276Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "kXBdMSPZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-25T23:22:10.447Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "vEpw1qCt" : {
            "pathname" : "\/kali",
            "time" : "2024-05-28T22:43:18.618Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "xBp1ogDZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.726Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "4GZ7rTrp" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T01:26:32.690Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "jjGJnUZf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:34:22.507Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "wXwKtR91" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:30:07.941Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "44ehcuju" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:20.391Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "rhrwPbai" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:19:51.138Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "65vhQsrQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:48.726Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "i81iRAap" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:16:13.225Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "dJRaUSJV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:30:07.939Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "rQxwbdeh" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:21:55.435Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "faR7obcF" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T01:27:20.272Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "4QR8x5gk" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:24:01.996Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "6afvXncJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:29:21.953Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "qmdJQPv5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:29.183Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "57t5zz8n" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:34:43.542Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "5cR2r6NX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.242Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "c5e2gS5D" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:31.479Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "e8cH8TQP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:31:41.331Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "wuapxWHp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.690Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "bXRe3Dbp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-27T00:01:45.526Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "mFj4sd74" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:21:55.427Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "muqB5RTC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T02:43:21.982Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "wbVSpg3H" : {
            "pathname" : "\/kali",
            "time" : "2024-04-30T21:15:18.643Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "shtEwXKe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.254Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "4hUfu5L1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:32:29.502Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "nnRVwz9E" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:25:15.433Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "uPnTybgo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.489Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "gbbRexqk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:48.713Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "jdprRKer" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T01:25:49.882Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "iVzgwE3y" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:48.708Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "v74CZmvh" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T00:11:29.933Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "b4HqBGFL" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:19:32.301Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "doBpCV8p" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:49.989Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "jFqZF4hA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:21.691Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "ohb1iMPP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-30T21:15:18.650Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "rQXdSkXL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:30.467Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "2141UJS8" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:23:15.058Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "anzLjUcU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.810Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "uo7JRVBJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-25T22:52:06.482Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "unMmrrKt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.492Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "wUjx7U5x" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T04:53:49.623Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "dCPnpxr2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.623Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "2x4Z8gLH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.246Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "8wxcDKEU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.488Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "r4CPzhY6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-27T00:01:45.520Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "sx6obmuq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.681Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "brttgYuv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.710Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "qhJwDuRv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.276Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "a1Km9kYs" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:47:53.084Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "cjMtvaAf" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:24:01.992Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "itt4qSAM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.585Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "C6rybeHZ" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:20:15.990Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "viZBSPed" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T04:53:49.626Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "w4P3jzQw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.463Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "hwHdzFid" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:34:22.512Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "nXTrWRXm" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:19:56.196Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "ghUibMkK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:40:41.296Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "w115ryA9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:45.998Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "shYpJWwR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-25T23:22:10.451Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "2mBxnmBT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.680Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "tAukf8FJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:00:32.456Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "jAfnsXWT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:22.433Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "5WSLjCFT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:53:22.576Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "7VEwx18e" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:06.571Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "a9xStjfg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.424Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "hULfwoTL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:42:00.522Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "nKzW5T1T" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T00:10:23.278Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "pbmaqNGW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T23:27:58.925Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "mUV5qRtC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.683Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "haGSH2sz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.357Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "cutBsUbx" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:31:26.045Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "xz6T8jxW" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:20:36.327Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "veeLLzH9" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:25:25.539Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "69cXF6jR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.278Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "eSaSTWEk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.626Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "sm3Wxw3X" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.292Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "tehpbuii" : {
            "pathname" : "\/kali",
            "time" : "2024-05-28T22:44:09.623Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "bf8x3QhR" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T00:11:29.936Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "BzWAGnRX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.711Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "f3qPJbb2" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T00:10:23.274Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "HgSQxDo7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:41.266Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "aFQ8mrcd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-25T22:52:06.477Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "tmpVnDN5" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T01:28:06.696Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "wH9sx13R" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:40:41.290Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "74DeUeos" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.583Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "tjGu6bhf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:55:10.037Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "4AXUxQTJ" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:31:26.048Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "nSdz1nmC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.131Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "cTS1oxr4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-21T00:06:07.423Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "szzwx7zH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.134Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "uKEamzXY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.807Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "95DQbBVK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:04:14.656Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "nVAvvuPL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:56.996Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "nh7YWFPL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:16:13.228Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "7Fd8Rnpr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.677Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "pfJTphmR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.233Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "xm6DJng4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:30.646Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "wcwYT4Yb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:01:18.851Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "2UD9aT7D" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.686Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "dnkx6j2W" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.399Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "k8caY4gd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:56.999Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "e1bNTcES" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:19:56.189Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "9wdgpasH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.295Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "ieDaCDvX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.466Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "iv71p8Aj" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:20:36.320Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "8Kx8Cfee" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:48.720Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "gyDMfGXf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-27T00:14:12.359Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "r8ki7TAR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:04.159Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "gtDX4LQT" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T01:26:32.686Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "n8itkCtP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:55.902Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "hDVj1Kog" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:55:10.033Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "nAPBM8W9" : {
            "pathname" : "\/kali",
            "time" : "2024-05-28T22:44:20.444Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "nT12jCPD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:16:07.798Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "gk7Gh1ne" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.490Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "ezEFF9S2" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:19:51.128Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "oVY6CuC8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:50:39.610Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "t5jbifv4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.644Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "wBN66xQ8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:50:39.607Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "uSNkE2gn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.591Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "ohEat5gC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.702Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "tVjgwdwD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.585Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "ced5Q96b" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:32:29.505Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "efsNRxmu" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:33:48.202Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "xiLMJ5jg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.124Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "aisLfLCR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:04:14.653Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "itzjwtz3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-21T00:08:52.900Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "c7SYLssV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:32.823Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "n6H63nFH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-27T00:14:12.349Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          },
          "cWiFBpru" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:18:41.801Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "timezone" : "Africa\/Tunis",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36",
            "page" : "landing-page\/"
          }
        },
        "promo-loaded" : {
          "PBRNsS6E" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.692Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vC1EsSr4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.070Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qJSgK",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "B5VNZIHJ5J2JAN3ZGBDZUQFH",
              "promotitle" : "Buy one get one free amount Ya bro !",
              "redemptionlimit" : "10",
              "discount_id" : "63RWVQZNN2NBKFQFK4946QZVJB",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dUuA6uU2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:30:07.952Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4Kjkx2cF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.202Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jZcps1jv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:49.983Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "edzEYtiT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.030Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gZLzGvzN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:31.486Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6g7BjZGT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-27T00:01:45.718Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-27-2024",
              "discount_version" : "1714087328770",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "ADD ONE",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087329540",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pctdy",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "37RT95TN5VJ0TVW24JDET8ZGR4",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "SMM7HSBILDKN2RNTUUT67ZSZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "PFL5QMIMAJRJA4NJOJTQ7QNK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "npR4sHen" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.747Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7tfWh4Jh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.885Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5rMD8n9t" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.696Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6J841uu2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.726Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bAxW7QAh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:32.821Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9GKGQ5iC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.258Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4dxmcETR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.755Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qUNNQfXy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.061Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7rJJbxd4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.024Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kgw9YgqJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.717Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kgswK8E7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:31:41.350Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aWmReijG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.874Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3EkMrxez" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.727Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vxsyNVbc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:21.696Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bqXZq7cq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.921Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7mXL56di" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.701Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jrMdCaKJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.819Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m2RnV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "SB3A73LCRHQTTPA2R7OTLS7T",
              "promotitle" : "Buy one get one free testi,ns!",
              "redemptionlimit" : "10",
              "discount_id" : "9V3AS0RBEBA5ZF8GCGRPRZ58V",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vZg7aPMt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.661Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sXHDMKwu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.074Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3YQTV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5OUAT7Y44KBSRYOB3OUB7NWR",
              "promotitle" : "vvvsdqsds",
              "redemptionlimit" : "10",
              "discount_id" : "7YA7QCWCJ9E5DSG518VE1HQH51",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eBs3PS1b" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.154Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "id5T8ARc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.713Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dVx9gFAm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.696Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hAp7TvXg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.652Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cs2uvmaK" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:19:32.525Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "f1gGYSw2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.151Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5Z3zTnMG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.815Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4H2q7Ve3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.223Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wKKoa8MV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.699Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "54mG3cjg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.076Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8v647Hdz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.719Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mqkCYnsQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.690Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uipXcFL2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.867Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tiG4oRPX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:45.994Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dFjcGrbs" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.851Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rh86UFhQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:39.267Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dbfmrM7V" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.854Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ma58PogL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.699Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nLkt9PpU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.689Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nyebWjhs" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.665Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2DLFfq2R" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.570Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "56Ge1j82" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.072Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-03-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-jLvkU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "LQHDRJJW7KD6PFIJ5RX2OMEC",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "16",
              "discount_id" : "75QPKTRCDXWNPCG2H92XVVJBB",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "io6HqfkA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.513Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "v68Y34ar" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.201Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uupqdFJ6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.664Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rjDd5sBq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:04.166Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "Zn1TVs6K" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.583Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gJKMvms3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:39.273Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uMU5wvYk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.499Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "i5jfr4yP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.855Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-03-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-jLvkU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "LQHDRJJW7KD6PFIJ5RX2OMEC",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "16",
              "discount_id" : "75QPKTRCDXWNPCG2H92XVVJBB",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oPvbwM3Y" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.651Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3jfqxuqG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.679Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wLQ3PRJC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.027Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8gneTbQU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.696Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4akatKwW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.907Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "83UhUkoV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:46.001Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iGcrCyN7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.829Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rSdvwuUf" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T01:31:34.484Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB8",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "02-17-2222",
              "discount_version" : "1714176850699",
              "date_created" : "5\/1\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "testing bro",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714176851775",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "02-17-2222",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-uqMB9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pBWsRqQP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:50:39.800Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fZfVBcj5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.227Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bT76djDh" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:20:16.003Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6eBDzvvc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.720Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uPqWQ59V" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.150Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hW12CKr7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.731Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5Wm1sUE9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.610Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7zLhEpxn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.498Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bmiGkjuD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.733Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vFeeaGLP" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:31:52.612Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7YxtDCEN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.418Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xcKjBptb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.715Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "63u45qgi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:34:22.728Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9HxkKDVb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.703Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hPEJDMaw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.564Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3uw9gZyf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.731Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ujiqwHSN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.824Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oNVD2mBe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:36.399Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gziEeK7x" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.817Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "j78nMssa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:06.576Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8FT2fzRy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.715Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uc4Z89fH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.179Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gYsMqBH1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.166Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buw",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-waVUX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "C6Y2XBFX4XQRSJRRNOJJORTY",
              "promotitle" : "Buy one get one freewwwffffffff",
              "redemptionlimit" : "10",
              "discount_id" : "M6SGDJNMH19AREZAS4EDCG59R",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "h3nxtCbp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.131Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5mAaNsHm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:07:15.246Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rCojYaSZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.730Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xtJXs7TD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.692Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9Efn5smZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.084Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pNBUtkTQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.746Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iYQYoaVC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.179Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "quDqnGtE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:22.243Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bxjZk3a4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.231Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "w7yu4PyZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:34:43.565Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xbu15v1m" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:34:22.726Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gFzcf1gD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.239Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sNstwn1V" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.071Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7D4Nz",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "MY34726D6PRUOOVSEZBP7KTB",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "M5STBYQ7E6XAGCAD80F6TY6ZR",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "d1KFvvdE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.068Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8RB8dkru" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.277Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "f3GwKLYC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:31:41.346Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jBRtbH8K" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:16:07.822Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kjpzx6Zr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:48.197Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4bQuupZn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.696Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qpzzZvQR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:27.853Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "a63jrwef" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.141Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bYJup1rm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.690Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fBmuUww8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.686Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qkYkrCbr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.703Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "etX9aPHQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:30:07.950Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "f6PRCEzY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.580Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jqKuKM4n" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:33:53.766Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB8",
                "imagename" : "cookie.png",
                "promotype" : "2-gradient",
                "primarycolor" : "423F32"
              },
              "status" : "ACTIVE",
              "dateend" : "02-17-2222",
              "discount_version" : "1714176850699",
              "date_created" : "5\/1\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!32024",
              "campaign_name" : "testing bro",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714176851775",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "02-17-2222",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5vYG1",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
              "promotitle" : "Buy one get one free!!!!",
              "redemptionlimit" : "10",
              "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "w4JetrGN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:21.688Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jnv9niiZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.710Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "x37zUuQP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.589Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3hG9msoi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.730Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rCVjbMnC" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:33:48.196Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dacSxGh7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:04.169Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ib16womv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.897Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bRnBkvVF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.282Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fuK7xApR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.485Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7xDyNknF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.762Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-24-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!SSS",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-gHrS5",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "RIET7M5255NTOBCC6XMRV6Y3",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5W0NA67DBB5XW21HWJGZ6140V8",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uv5RbhZL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.667Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jPERNbyX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:16:07.808Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nbR7mRrj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.662Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "muXvSd5N" : {
            "pathname" : "\/redemptions",
            "time" : "2024-04-21T00:10:59.601Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "date_created" : "4\/20\/2024",
              "promomessage" : "Dolor obcaecati nequ",
              "promoshowterms" : "YES",
              "promoId" : "kali-xvcay77",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-xvcay77",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2TP8ED36V8N400ACDMQRGDXVW7",
              "promotitle" : "Nulla veniam placea",
              "redemptionlimit" : "8",
              "discount_id" : "XA65HS743S7Y5LDZ76T5ARU5",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "R4PSCDEGMEVMHYIC6L63ZEJD",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rskMwSMF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.514Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "g9HpWDVG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.662Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "du9tMcKa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.500Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "s3jtB9qJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.602Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8QBQYpyh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:34:22.712Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cwDTHYsc" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:20:37.245Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cK719d7t" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.883Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6ez2RXwU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.878Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5JwoudNL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.748Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qCPdygdZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.489Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eX4U9Fs9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.687Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9JSq7bAQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.668Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oLt4fivs" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.216Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9kJMNsU6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:42:01.477Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dbh2cfCZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.844Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fuZinFs8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.673Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wTpfDCD1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.565Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "G1k9gjqi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.743Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cCJpKriJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.733Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3jUxL3mB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.890Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4Tr1Y2qD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.325Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kbECMSvq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.501Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xeteTdyd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.607Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "v8MRv7KE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.736Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gbDXeXhA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.733Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fd5xYGj8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.217Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ed4cMYBu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.782Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rexUhw75" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.753Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7ummYkHN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.695Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6KK6Digv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.678Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4YdkVo1E" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.072Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iUdZVYkG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:50:39.797Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mqYzpqpA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.266Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3Rocj6Gp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.726Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dC3dzYKb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:30.986Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qCcRyuq8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.503Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9aePJzUA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.681Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "66Kewdxi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.869Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ewhUrzcx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.155Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "f4Tkqs7i" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:19:57.120Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7G6c8WJD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.677Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aKt8nFnC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.984Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dAG94vUd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:01:18.890Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dNzv2PXp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.574Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aTPs6Jvy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:34:22.721Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "Xg3uwvxd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:48.198Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mbMu2xUp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.956Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pEpK38p1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.141Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4AZgvBmW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.710Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kkV3r4vH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.710Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "emRWSYzS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.918Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sg4UZcsQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.137Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ocGguLbF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.707Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qFuABg97" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.751Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qBErDhga" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.496Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gZtTBY6V" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:27.862Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8b9GBHm8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.605Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6sXqFmKB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.506Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3dyYYbqV" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:31:26.251Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "69JLYzFe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.724Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uCxqdVAy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.842Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nEy9aZn3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:28.966Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8XHzaLN9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.668Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9sNyNvWm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.854Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-24-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!SSS",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-gHrS5",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "RIET7M5255NTOBCC6XMRV6Y3",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5W0NA67DBB5XW21HWJGZ6140V8",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jWkfD8xR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.832Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wq4ThNA6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:31:41.339Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pyuL7dSe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.712Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8ya53HmH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:16:13.233Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "j9ZSYmh2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.681Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uc9uQyy2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.691Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uw5KojUe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.919Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oTbt3jEu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.225Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6uu96WiR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.133Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nD4dNtS6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.671Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "okUG3f4P" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.656Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ndf1sNJc" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:19:52.022Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vuy3DeWp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.211Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "42sJ9srt" : {
            "pathname" : "\/redemptions",
            "time" : "2024-04-21T00:10:14.295Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/21\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promoId" : "kali-qUFW3",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-22-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUFW3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4A1CBWMXNY2RZPYWE03XJT2FTW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "GPFWKEX4YSYZNE5SRAHZ7O6T",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "O2SZLUHVK7GSQW552VJBYFWP",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "awa2Dzas" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:50:39.806Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nzmChzH9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.987Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wGZGpgQG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:06.194Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jhVwPqzv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.721Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bXAmKvPC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.731Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vjf6yvPS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.911Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "urTbaSRU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.049Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eoV4CdJX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.726Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "o9MMrt7d" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.065Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uqvKJwpg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.657Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6gLZyH2N" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.901Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gtAkXTM5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.605Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hYVuWQkw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.136Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hCT53r4m" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.938Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5vCWcRMR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.740Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eitdT6fM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:32.837Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pfCrtuqm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.951Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eGE6cdpD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.763Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ukjmz51Q" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.044Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4gwz7GD9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.059Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "Bn6hfeKU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.265Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rX2YFrrt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:27.850Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6daw8b9U" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:36.396Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8EhfMY3j" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.741Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "99TEezEA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.780Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cgEVN8KV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.813Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2VG3QnWj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.707Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oNJ2JdKv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.486Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gT8ML1Mo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.666Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iUh4Ykfb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.670Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ve3nrrrR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.682Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4efmKVA6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.076Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iZXR5cny" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.730Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8BsnPJq2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:55:10.054Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qSTRMoc5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.751Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jYNBL2bH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.684Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nWuaQKPX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.914Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "r4RNCTfr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.711Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "yiurQ7ze" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.824Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uDBLhmnQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:46.003Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3Ya1BUrf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:34:43.569Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "peTRKvTQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:53:22.632Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cc38QaiZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:32.814Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cehQbV9K" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:21:56.446Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8kjcojNy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.603Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "24fzaxqf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.615Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "e4225dVG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:31:41.356Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nRgjdiyf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.129Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "v6pfVQqh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:40:42.077Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9i3AcvdE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:55:10.055Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oMzRtXmf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.939Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dfCqvzVt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.753Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vc1Wg22q" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.690Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gmYQZQsv" : {
            "pathname" : "\/redemptions",
            "time" : "2024-04-21T00:09:40.231Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/21\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promoId" : "kali-cH9SJ",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-22-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cH9SJ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "11YFSTAFW17DCJA48655GE4GZ6",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "L4ZQHJONOMQH37ROMRWQM6JU",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "INKX62DYGAQ4M3U5PZEBJCTQ",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8hKq3QYC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.699Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mjfcCr6y" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:41.269Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nLtit2AD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.219Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "n5xopJdE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-21T00:08:39.734Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/21\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-22-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUFW3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4A1CBWMXNY2RZPYWE03XJT2FTW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "GPFWKEX4YSYZNE5SRAHZ7O6T",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "O2SZLUHVK7GSQW552VJBYFWP",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iAkSfPqQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:28.967Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "u9PQTm4f" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.153Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pS9XvCYa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:45.993Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iy9y7UAE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.583Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6nDfr3dr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.830Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qWrqFhuW" : {
            "pathname" : "\/redemptions",
            "time" : "2024-04-21T00:25:24.906Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/21\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promoId" : "kali-qUFW3",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-22-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUFW3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4A1CBWMXNY2RZPYWE03XJT2FTW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "GPFWKEX4YSYZNE5SRAHZ7O6T",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "O2SZLUHVK7GSQW552VJBYFWP",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wepSVvxr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.655Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iFFyDnod" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:30.985Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "q43yJkkL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.698Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7yNjqWZw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:34:22.706Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ucEGSh6A" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.598Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8pBHTXhu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.892Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "br5b8wgw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.703Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ccfAb19U" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:32.835Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fJYoDi3T" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.720Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fZFwmRJZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.607Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fmXoSrU1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.595Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6ERXyUGJ" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:19:32.509Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oZmWExhw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.734Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6CfpppjW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.720Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6TYJf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "FW75POPSYAFQPSNWBGSLARNQ",
              "promotitle" : "Buy one get one free!yaooddd",
              "redemptionlimit" : "10",
              "discount_id" : "C17GJK7ERQ17EDRD3M62RG3BS",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "caoyZbNY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:30:07.956Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eA8mp5Mt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.703Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gotWz2C5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.719Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oj8diJFR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.066Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "q5wgVoJR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.662Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iSqYixcy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.707Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pFLQz8CE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.844Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wbvYzgj3" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:19:32.524Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kXj62fXj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.680Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "brGqZ1C5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.926Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xsqd8Q99" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.708Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aW6oRk57" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:16.242Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cMr8SpSP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-27T00:14:12.658Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-27-2024",
              "discount_version" : "1714087328770",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "ADD ONE",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087329540",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pctdy",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "37RT95TN5VJ0TVW24JDET8ZGR4",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "SMM7HSBILDKN2RNTUUT67ZSZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "PFL5QMIMAJRJA4NJOJTQ7QNK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kXXQhHrr" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:21:12.833Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "icRdBxfj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.888Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aCpmpqNi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:16:07.804Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fc38tLyW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.408Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3tV41zee" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.692Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wvctbbQ3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.714Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ur9b5wN8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.336Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "a8fiTTrc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:58.008Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nHB9KAzS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.162Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-24-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!SSS",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-gHrS5",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "RIET7M5255NTOBCC6XMRV6Y3",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5W0NA67DBB5XW21HWJGZ6140V8",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5gQj1VJ9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.714Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aSv5tKLE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:40:42.085Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mqnd38Dc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:16:07.807Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3jf3Y1Pv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-21T00:06:08.431Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/21\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-22-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUFW3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4A1CBWMXNY2RZPYWE03XJT2FTW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "GPFWKEX4YSYZNE5SRAHZ7O6T",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "O2SZLUHVK7GSQW552VJBYFWP",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "x5cDpXSv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:42:01.470Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bTqGMAJT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.676Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eiuE96ep" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.750Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xfChUoBn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:30.983Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tKAnZ1U3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:01:18.876Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8yKkvuHE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:07:15.235Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6uCzqTYY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:45.996Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fYhbDFDD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.248Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qmzBRjsk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.195Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hTvJobvc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.248Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eLfzbJBq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.923Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ergusrQW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.684Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jb7d1PrB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.855Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-03-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-jLvkU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "LQHDRJJW7KD6PFIJ5RX2OMEC",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "16",
              "discount_id" : "75QPKTRCDXWNPCG2H92XVVJBB",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7g6nG99i" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.699Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "a7VWKM8S" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.245Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eARywQCM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.512Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3wE4v9XB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:31.477Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cDYEfYbm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.741Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uasvBrra" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.691Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vxUSw3vL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.020Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4RrbkWFT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.493Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uWYajLdn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.723Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "71MYkU1x" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:39.262Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iRgUme4p" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.282Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iMANEx9e" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.755Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qUdSZ4ug" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.480Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cZaQSQBM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:29:22.155Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6Ka8Yyjp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:42:01.478Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bSfE9vEj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.730Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dbQfVFwS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T04:53:49.780Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "91C4nVQd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.780Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eCQn1gNN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.725Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "x8jMF8n9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.611Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "r5U9agfU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.513Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "c2j19T14" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:42:01.467Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4u5oV1v4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:21.684Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kTTQK4F7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.656Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bep6nfdi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:29:22.140Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eNzMTMjZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:29:22.148Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gJs79p38" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.494Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2NRq4MD9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.711Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ukFryLbM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.729Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dPLTjbay" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.316Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tCqtqMFQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.575Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "c5f8a3rW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.471Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ksS714Dq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.554Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "toba4sqm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.341Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sRS5g8Yt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.492Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gnx4ygBu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.848Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8aogH7FD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.674Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6Jv5R8sC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:16.628Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "h25zuRYN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.508Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hbdHRNqP" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T01:25:49.891Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB8",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "02-17-2222",
              "discount_version" : "1714176850699",
              "date_created" : "5\/1\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "testing bro",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714176851775",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "02-17-2222",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9p8MC",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bRarazzf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.672Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hfHrHj8b" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.582Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wk394xkD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.521Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7D4Nz",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "MY34726D6PRUOOVSEZBP7KTB",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "M5STBYQ7E6XAGCAD80F6TY6ZR",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jaeqaz3D" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.663Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "31GqU5vc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.693Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iVNA7Rx8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.741Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mYYD7VoD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.649Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jU4U28Wf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.473Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tjnAP4ZT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.690Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jcehL8HP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.678Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "h4cQeG44" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.673Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5RwAwn6j" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.747Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "m9iby5cX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.019Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sNjj7C37" : {
            "pathname" : "\/kali",
            "time" : "2024-04-27T00:01:45.705Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "u7buR7Ye" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.578Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pwfteYD2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.905Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uzjweekR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.609Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uzxfqpRc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:08.499Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9UYXuNrA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.833Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ie9Jtgnh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.260Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5PMpiU2j" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.670Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cZyMJiis" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:34:22.730Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eV9h7YWS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.556Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uE795ox7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.691Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nNZJ6od6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:34.228Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dX4gqfWa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.828Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nrwoPfqJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.500Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4DvD17ae" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.083Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "k3hVMedU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.726Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3a4iCvaH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:29.178Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cH1wiVLD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.208Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2qoLd2mB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.509Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "faca5How" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:33:53.754Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9dGzQJso" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.246Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "p699CXJg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:32:29.520Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xvfN13s6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.847Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jYeEJRAQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.735Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mQgkeTzv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:08.524Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "g6xYLFf6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.123Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9RThtdXa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.740Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "p85i5Z4E" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.713Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "auiE6VYp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:06.186Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "q3Zt6qGv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:45.995Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rBXcxYY8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.344Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "49rkEWeS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.679Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hNg969iY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.681Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "movd4nHk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.196Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hzPGqECo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.708Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cbRwps89" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.157Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3YQTV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5OUAT7Y44KBSRYOB3OUB7NWR",
              "promotitle" : "vvvsdqsds",
              "redemptionlimit" : "10",
              "discount_id" : "7YA7QCWCJ9E5DSG518VE1HQH51",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "u1jhSgyM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.719Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wuXk1Afq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.713Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tCes1GF4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.711Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eFtJh6jD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.730Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ouc6T9em" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.916Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-20-2024",
              "date_created" : "4\/17\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-keND5",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "52VAVJ3305QSG773RTRFTCP9JE",
              "promotitle" : "Buy one get one free edting !",
              "redemptionlimit" : "10",
              "discount_id" : "W2D4N3MDFEXC2OKW2XSS7CFM",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uUSpuhcE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.714Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qJSgK",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "B5VNZIHJ5J2JAN3ZGBDZUQFH",
              "promotitle" : "Buy one get one free amount Ya bro !",
              "redemptionlimit" : "10",
              "discount_id" : "63RWVQZNN2NBKFQFK4946QZVJB",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "boxD2c2i" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.708Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "r6sUXagM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.714Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "i5v5Drza" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.935Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ctU1Yiab" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.929Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3YQTV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5OUAT7Y44KBSRYOB3OUB7NWR",
              "promotitle" : "vvvsdqsds",
              "redemptionlimit" : "10",
              "discount_id" : "7YA7QCWCJ9E5DSG518VE1HQH51",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kf9bJeR9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.758Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xBs44epc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.695Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tiujor97" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:32:29.512Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fc8Jhk8S" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.709Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qkjENem1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.652Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hAz39yJR" : {
            "pathname" : "\/insights",
            "time" : "2024-05-01T00:55:22.306Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-26-2024",
              "discount_version" : "1714085524819",
              "date_created" : "4\/25\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promoId" : "kali-mfN7h",
              "pricing_rules_version" : "1714085525933",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-mfN7h",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "159539FYEMFWKFG2F4F3Z7SACA",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "XJKVFVCD3MAFCNUSBKVA3EJY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "OYDE3TIFS7JZRCUDQTO3KSJK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "szXoV3cN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.665Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3dF6Y3eS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:32:29.515Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "csYtP4yC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:53:22.648Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jBwfWrYx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.715Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7y3G5SkF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.710Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5whzKm2W" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:24:02.032Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iSTcFa5w" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.653Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "z5JptW38" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:16:07.801Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8P6KLpts" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.142Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mTH4xbgZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:30.978Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tLWvZjsE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.714Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5YqhQ4EM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:04.167Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "n49xW78f" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:36.397Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ffWDsEdj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.701Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tmuPpYUN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.661Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gx7J98LV" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T01:27:20.297Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qHZCCbvV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:30:07.958Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wfSWfiDD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.607Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vht8CPfo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.967Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rFewJVdu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:55:10.060Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ooGJvr5q" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:31.469Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3g1v4SwV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:04.157Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6vK6jHwd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.243Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jrcR3B5k" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:06.577Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "angTMbNm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:16.623Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2CMEBDbS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.918Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6TYJf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "FW75POPSYAFQPSNWBGSLARNQ",
              "promotitle" : "Buy one get one free!yaooddd",
              "redemptionlimit" : "10",
              "discount_id" : "C17GJK7ERQ17EDRD3M62RG3BS",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "einDFBfe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.722Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iYqBXiCg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.706Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jETSQTcb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.817Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mb3Nm5UE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.342Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "e6W7RZvC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.881Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oViaptCU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.034Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9RJpQxkD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.689Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gzMeUHeS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.735Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uLxzjqTd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:31:41.348Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tJB4b94d" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.603Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vNdBQnHu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.692Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bYW7pYBk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.510Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-idJPC",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4ZOOZ5QWMEKMORLC4QIXUX4Q",
              "promotitle" : "Buy one get one free YOO!",
              "redemptionlimit" : "10",
              "discount_id" : "78JD8R3BSSFDN21QB23HPWWD9F",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kUQYH6tA" : {
            "pathname" : "\/insights",
            "time" : "2024-05-01T00:55:26.170Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-27-2024",
              "discount_version" : "1714087328770",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "ADD ONE",
              "promoId" : "kali-pctdy",
              "pricing_rules_version" : "1714087329540",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pctdy",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "37RT95TN5VJ0TVW24JDET8ZGR4",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "SMM7HSBILDKN2RNTUUT67ZSZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "PFL5QMIMAJRJA4NJOJTQ7QNK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "c8QfTvXy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.682Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jCaEVkYv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.494Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5yxaBZRz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.690Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "raxaxGqT" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:33:53.768Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nb2Dxzqm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.664Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wYZhhkmx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.689Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dzZFNZqt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.662Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7NBoMBU7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.105Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "j3oE5Jqi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.671Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tvNWCqJ6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:06.196Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2LKnQZfV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.036Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aqCYnZpW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.243Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "Pk7LdkqU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.660Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oudSA7BG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.934Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7fmM3GJp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.688Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2YSjULPk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-27T00:14:12.605Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-27-2024",
              "discount_version" : "1714176850699",
              "date_created" : "4\/27\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "testing bro",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714176851775",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wb8h8",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fZzpNEUL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.919Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-19-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-h46AF",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7LJTKKZ6JIJWVLWYGZ7XUVAR",
              "promotitle" : "Buy one get one freeaaaasd!",
              "redemptionlimit" : "10",
              "discount_id" : "2YVVCH3T0HW3C5CPMM9BNV5EX3",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "h1NDU9Jk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.814Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fjSaaU3B" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:18:41.812Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "75Lx4biZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:21.694Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rc98EHP6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.837Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "n7dqiEy5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:22.245Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "twkg3WZB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.880Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4zFWap4R" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.740Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cQqwD3aB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.659Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ggKwZ7GZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.971Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nTtpEbv2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:31:41.351Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3faM5aUC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.711Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qag8q2ih" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.930Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "w2jGPh8q" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.512Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6Z1ke85Q" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:50:39.798Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cVR9Yodq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:39.267Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gPy9PGMq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.685Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qGnE18ji" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.742Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4YvSekJL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.991Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "v1NX3cpn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:41.263Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7k4EJe7U" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:06.199Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pSvFmAi8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-27T00:15:41.873Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-27-2024",
              "discount_version" : "1714087328770",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "ADD ONE",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087329540",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pctdy",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "37RT95TN5VJ0TVW24JDET8ZGR4",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "SMM7HSBILDKN2RNTUUT67ZSZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "PFL5QMIMAJRJA4NJOJTQ7QNK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "r2UcbokC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.156Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5z7KX9vM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.510Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6TYJf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "FW75POPSYAFQPSNWBGSLARNQ",
              "promotitle" : "Buy one get one free!yaooddd",
              "redemptionlimit" : "10",
              "discount_id" : "C17GJK7ERQ17EDRD3M62RG3BS",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aLw2GL1R" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:30:07.951Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "23yEMPRc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.675Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tDNq9HzA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.514Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gU4aqcny" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.568Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2VxLh2xa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.727Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9D24sEDZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:48.179Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hwiSNMC8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.225Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wb976ApL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.915Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9f27Af88" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.162Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nVfwc3ga" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:23:15.262Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eBUS9pCW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.508Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3bCvx6Tr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.544Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "niUgn5SN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.666Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iFxTHM3W" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:04.157Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "toTJik4d" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.904Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "o8RuWtNM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.473Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "os4mefW2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.714Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8s7ro8Fp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.327Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wxSTwmzR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.596Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "byrNRhkz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.614Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5LAULk29" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.063Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "g8Nc14qa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.236Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jnx7MhPK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.601Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3YQTV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5OUAT7Y44KBSRYOB3OUB7NWR",
              "promotitle" : "vvvsdqsds",
              "redemptionlimit" : "10",
              "discount_id" : "7YA7QCWCJ9E5DSG518VE1HQH51",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4acaVG8i" : {
            "pathname" : "\/kali",
            "time" : "2024-04-21T00:07:56.015Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "date_created" : "4\/20\/2024",
              "promomessage" : "Dolor obcaecati nequ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-xvcay77",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2TP8ED36V8N400ACDMQRGDXVW7",
              "promotitle" : "Nulla veniam placea",
              "redemptionlimit" : "8",
              "discount_id" : "XA65HS743S7Y5LDZ76T5ARU5",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "R4PSCDEGMEVMHYIC6L63ZEJD",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8BJ8yYbZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.765Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jNeLXeNN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.730Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8fn3EKBC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.673Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rTCsqx4U" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.130Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dtG31XYB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.743Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2jQHW2Rn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.015Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hDPPFxAm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.766Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "v3eZNVCb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.142Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tk7YKwHy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:29.181Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "biat5vtV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.671Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "867WHop7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.848Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cxGXYu2d" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.471Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sCaFpPu1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.752Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nA3izDvg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.709Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5RVPGZMm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.071Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-24-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!SSS",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-gHrS5",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "RIET7M5255NTOBCC6XMRV6Y3",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5W0NA67DBB5XW21HWJGZ6140V8",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bW138qQe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:29:22.136Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kNanBFtB" : {
            "pathname" : "\/insights",
            "time" : "2024-05-28T22:41:59.778Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoId" : "kali-kekQV",
              "pricing_rules_version" : "1714511718073",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xhAehLeE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.692Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tC6HcMYw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.994Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pskQq97D" : {
            "pathname" : "\/redemptions",
            "time" : "2024-04-24T23:36:31.186Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "discount_version" : "1713911382071",
              "date_created" : "4\/20\/2024",
              "promomessage" : "Dolor obcaecati nequ",
              "promoshowterms" : "YES",
              "promoId" : "kali-xvay7",
              "pricing_rules_version" : "1713911382886",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-xvay7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2CXV2QYRHS9SPYXQG4QYNQJ55P",
              "promotitle" : "Nulla veniam placea",
              "redemptionlimit" : "8",
              "discount_id" : "BNCIFG2G3HVFRIXPCMUKZ36E",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "J2NH5KN454ESRJG6Z3ZV4FMA",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3kZnJVCx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.252Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "73esgNfS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.141Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wAiAHhN9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.689Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wtkCH3Fo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.831Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vifudv1v" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.735Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bL3NJq3w" : {
            "pathname" : "\/insights",
            "time" : "2024-04-30T23:18:00.944Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoId" : "kali-hxzr3",
              "pricing_rules_version" : "1714087742664",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vyK5p3Gh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.731Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fsND4zPP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.888Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jeDY69S6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.676Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oaCmufmn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.696Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bLfJ75Ww" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.408Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fDsit3nK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.343Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jAJoWhNA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.722Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nr6pdK5L" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:06.576Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "phGsNrGq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.665Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kjQuLYw1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.318Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eAMa99bU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.659Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7HQ4JNeP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.928Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jZCrkxPE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.698Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oPexM1Fp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.562Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8n7b2vnV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.821Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qJSgK",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "B5VNZIHJ5J2JAN3ZGBDZUQFH",
              "promotitle" : "Buy one get one free amount Ya bro !",
              "redemptionlimit" : "10",
              "discount_id" : "63RWVQZNN2NBKFQFK4946QZVJB",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aJm4bJZ4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.602Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pVywrcHu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.665Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "h6bzG9Gz" : {
            "pathname" : "\/insights",
            "time" : "2024-04-27T02:20:52.247Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-26-2024",
              "discount_version" : "1714085524819",
              "date_created" : "4\/25\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promoId" : "kali-mfN7h",
              "pricing_rules_version" : "1714085525933",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-mfN7h",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "159539FYEMFWKFG2F4F3Z7SACA",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "XJKVFVCD3MAFCNUSBKVA3EJY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "OYDE3TIFS7JZRCUDQTO3KSJK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cWBqV6T6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.747Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "j9RpsDwa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.655Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "putzs31d" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.669Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "njNGivYv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.985Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2voHUn6z" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.566Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mQG7usVV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.551Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4xpVMqHq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.274Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "da5Gnbmu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:16.231Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dyX2EMPJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.583Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "q6JEiy81" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.928Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buw",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-waVUX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "C6Y2XBFX4XQRSJRRNOJJORTY",
              "promotitle" : "Buy one get one freewwwffffffff",
              "redemptionlimit" : "10",
              "discount_id" : "M6SGDJNMH19AREZAS4EDCG59R",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sPmMQtaF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.663Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3GRGwy7Z" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.729Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dQ4DLWbF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.690Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2rnpy5wC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:40:42.082Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pH3DNHSs" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.712Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vWLEYcMo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:31.481Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7AubFrFT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.746Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "m3jb8zbv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:32.836Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tx4en5bu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.567Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jHqhEzKy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:16.235Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6ono1SGH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.515Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "d4fKPw6F" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.952Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vAHyKXYB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.256Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7hrWvo9x" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.932Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "e2fLxWtY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.673Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uB3LPyx5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.116Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9hVFDCso" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.681Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mE7FpdRA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:34.225Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2ZwrrUGK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:31:41.344Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8VaPbAbx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.697Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "k3nEWXeH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.893Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qnAuVrkH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:55:10.047Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tT5sv9N2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:34:43.574Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7nfvik6S" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.943Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5kTMwUwh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.700Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9z8smG3a" : {
            "pathname" : "\/insights",
            "time" : "2024-04-27T02:20:52.239Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoId" : "kali-hxzr3",
              "pricing_rules_version" : "1714087742664",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sifZRbVL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.770Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-03-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-jLvkU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "LQHDRJJW7KD6PFIJ5RX2OMEC",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "16",
              "discount_id" : "75QPKTRCDXWNPCG2H92XVVJBB",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mi2M7EpG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.678Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cezytuCQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:48.181Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9uHb1PQR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:49.984Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7y9PEtZ4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:32.821Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "s1AJ2Eok" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:36.401Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gdoDDbNp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:34:22.723Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qg1p6rWt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.708Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aBQaskEz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.513Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ujLs1RV9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.667Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kxeVS46c" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.758Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9QsZpc6a" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.390Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kN2pvSQL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.709Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "a1hthRAc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.156Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "64w4Yqqg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.569Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6VqJEkGZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.950Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wtfRDXKw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.686Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gZqmgdwY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.329Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3agKi4Sy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.693Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dq7M9Rd5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.866Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rjCXEtUV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.917Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "waKYtWnY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.038Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3xrv9Lmv" : {
            "pathname" : "\/redemptions",
            "time" : "2024-04-21T00:09:53.082Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "date_created" : "4\/20\/2024",
              "promomessage" : "Dolor obcaecati nequ",
              "promoshowterms" : "YES",
              "promoId" : "kali-xvcay77",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-xvcay77",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2TP8ED36V8N400ACDMQRGDXVW7",
              "promotitle" : "Nulla veniam placea",
              "redemptionlimit" : "8",
              "discount_id" : "XA65HS743S7Y5LDZ76T5ARU5",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "R4PSCDEGMEVMHYIC6L63ZEJD",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dEuLVPfn" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T00:11:29.940Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "emDw6wYM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.681Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5Q7PyaAK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:34:43.579Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6d2fqJLx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:16.624Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eF63DK2t" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.241Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "f1p19SaG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.696Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5MxnnoVc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.703Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iy1CS1fV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:21.689Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xbxBTpkS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:49.987Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3XJtUUAK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.735Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tcKi99Wx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:31:41.353Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "c7JavKbV" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T01:31:34.496Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6NZ21UdQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.664Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sEfypHrY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.705Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uxECU5XC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.507Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dN7BtFPJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:18:41.811Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ogFSKndr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.074Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3YQTV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5OUAT7Y44KBSRYOB3OUB7NWR",
              "promotitle" : "vvvsdqsds",
              "redemptionlimit" : "10",
              "discount_id" : "7YA7QCWCJ9E5DSG518VE1HQH51",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "31XFWwa6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.675Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7HBMrC3U" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.553Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wvzTKCUr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:41.262Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vn27Enwj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.925Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3GTEiNrY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.572Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rDdF4heu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.840Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "g7KPGovv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.567Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "j9q57kZJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:29:22.154Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gnUdDhBT" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T01:27:20.281Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB8",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "02-17-2222",
              "discount_version" : "1714176850699",
              "date_created" : "5\/1\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "testing bro",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714176851775",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "02-17-2222",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-vrKoG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "knHF3V9R" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.083Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bVWSGgvo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.045Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fmvriqT7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:29:22.143Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iGTcE424" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.677Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "owUzubBs" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:29.185Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gRuwKwtg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.718Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rJN53ovt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.649Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qcZqwViN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.708Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jJfssc2h" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:31:41.341Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9kjXf886" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:34:43.574Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8vk5yVFP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.879Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vQh9iNod" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.532Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hR612519" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.776Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7JqTNhzw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.332Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rwMoWUD9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:49.987Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uGGX55fu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.921Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "j9GT8yJv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.244Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kDP37Hw9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:31:41.343Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wH8UAyvb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:16:07.822Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5FErDuZn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:27.854Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "35zA37AS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:20.383Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6f2AZJ3j" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.722Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7ygf9Uog" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.840Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "etBzhWzT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:30.977Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sNr4zAcj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.146Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vGtmfpj6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.728Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6GyP7BjF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.895Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aHTvJh5X" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.685Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mcvK365y" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.972Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9eeMobfk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:16.629Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "skQ5GYpL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.741Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fz6ZNRoG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.679Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "n8LZcuSU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.718Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4dx2fETb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:21.697Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jkX2JQwi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.067Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mTpposA5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.774Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "85t5ppy4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.825Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hSfuyeE2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.710Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6xRY9XLz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.694Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "PbNa5a7p" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.755Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aAg1r3hU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T02:43:21.987Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ktihQp5v" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.713Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nQGmWPE7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:16:07.815Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "74whuYkg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.512Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-03-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-jLvkU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "LQHDRJJW7KD6PFIJ5RX2OMEC",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "16",
              "discount_id" : "75QPKTRCDXWNPCG2H92XVVJBB",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nQEJoUgR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.163Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-03-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-jLvkU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "LQHDRJJW7KD6PFIJ5RX2OMEC",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "16",
              "discount_id" : "75QPKTRCDXWNPCG2H92XVVJBB",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nqHi7tYq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.484Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8yM9XPyn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.559Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nCDcfkuw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.714Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "taXvbK31" : {
            "pathname" : "\/kali",
            "time" : "2024-04-25T23:29:03.860Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-26-2024",
              "discount_version" : "1714085524819",
              "date_created" : "4\/25\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "pricing_rules_version" : "1714085525933",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-mfN7h",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "159539FYEMFWKFG2F4F3Z7SACA",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "XJKVFVCD3MAFCNUSBKVA3EJY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "OYDE3TIFS7JZRCUDQTO3KSJK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "u1sQ7jPD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.657Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8hxhvxmV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.928Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7VthpKuk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.707Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rFp9u5QH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:34.226Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9Hx5xGYd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.677Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aFPU9Giq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.699Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pmqZ2via" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.701Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "n5Pvghz1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.112Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nDaW5Ff8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:16.232Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cUXxRoKX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.701Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jvaTTJ2D" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.148Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "81C7sX9f" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:08.511Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "t9tjM45r" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.901Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fLfATnJe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.738Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mWoRHsMR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.493Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nsFKYisb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:16:07.813Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "whfDMFtb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.610Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tbFYxLGw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:42:01.467Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5Ur7yXMr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.212Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uSnZEiuN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:34:43.563Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "euuHcNj3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.656Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vWYphnuk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.561Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pCUvkQRg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.411Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "64MCuRgf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-21T00:08:39.720Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/21\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-22-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cH9SJ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "11YFSTAFW17DCJA48655GE4GZ6",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "L4ZQHJONOMQH37ROMRWQM6JU",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "INKX62DYGAQ4M3U5PZEBJCTQ",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vJ3yQx7w" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.060Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sE91WBCL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:50:39.795Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mN7mj1tC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-25T22:52:06.645Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "discount_version" : "1713911382071",
              "date_created" : "4\/20\/2024",
              "promomessage" : "Dolor obcaecati nequ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "pricing_rules_version" : "1713911382886",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-xvay7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2CXV2QYRHS9SPYXQG4QYNQJ55P",
              "promotitle" : "Nulla veniam placea",
              "redemptionlimit" : "8",
              "discount_id" : "BNCIFG2G3HVFRIXPCMUKZ36E",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "J2NH5KN454ESRJG6Z3ZV4FMA",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "b1en7Fkm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.904Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wvdJRaY9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-21T00:08:53.650Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/21\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-22-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUFW3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4A1CBWMXNY2RZPYWE03XJT2FTW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "GPFWKEX4YSYZNE5SRAHZ7O6T",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "O2SZLUHVK7GSQW552VJBYFWP",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jbSaTm4p" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.857Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4LTjCy8s" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.677Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hFybpwUQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.580Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qDsPd8gD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.704Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eidsNbgi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.687Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cJxp2Q3k" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.709Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ohWzeCqx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.752Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7zT3BM9B" : {
            "pathname" : "\/kali",
            "time" : "2024-04-21T00:08:39.735Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "date_created" : "4\/20\/2024",
              "promomessage" : "Dolor obcaecati nequ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-xvcay77",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2TP8ED36V8N400ACDMQRGDXVW7",
              "promotitle" : "Nulla veniam placea",
              "redemptionlimit" : "8",
              "discount_id" : "XA65HS743S7Y5LDZ76T5ARU5",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "R4PSCDEGMEVMHYIC6L63ZEJD",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "Uz5bk5t6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.794Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hCpLWL1U" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.730Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pLKhyAot" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.317Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jZtKm3Mu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.152Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qqmpx4aJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.257Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gzegb1dZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.502Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "t8Khe56B" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.663Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7akt8GDT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.051Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5itV5pdu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.483Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4frrQQ49" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.689Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cf4Xjg9a" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.586Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mKWgqhxR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.663Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qfiCTKvx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:53:22.658Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cWos6SSm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.157Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3YQTV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5OUAT7Y44KBSRYOB3OUB7NWR",
              "promotitle" : "vvvsdqsds",
              "redemptionlimit" : "10",
              "discount_id" : "7YA7QCWCJ9E5DSG518VE1HQH51",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "whZaFQ2r" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:49.996Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "53Gknqra" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.924Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qJbYJuWH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:29.182Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vvNjxfyZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.654Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cndUWBJo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.904Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "htRDvND5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.571Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "unreb4Rd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.712Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8G4xBqTg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.252Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kRXENjJE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:08.506Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vYiXmef1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:34:43.577Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bwu4CUdX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:34:43.579Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vs9pFhja" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.720Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "w4rJWUSM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:30.984Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iRy8ApFd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.669Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3Jd9QL8u" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.523Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mSz3Xjq7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.757Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "f24Ck1nY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:34.228Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "56quqo5G" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.681Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mwEgy7NH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.703Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wDVMyi86" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.517Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wHTrxdru" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:21.686Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6LMnDLdE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.770Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xARYXvdD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:41.265Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "usgN2n3o" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.135Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rBfyCkdH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.231Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gSx8U5Pj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.602Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3YQTV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5OUAT7Y44KBSRYOB3OUB7NWR",
              "promotitle" : "vvvsdqsds",
              "redemptionlimit" : "10",
              "discount_id" : "7YA7QCWCJ9E5DSG518VE1HQH51",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5BMVmbUv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.666Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "59Aa3JjU" : {
            "pathname" : "\/kali",
            "time" : "2024-05-28T22:44:09.628Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB8",
                "imagename" : "cookie.png",
                "promotype" : "2-gradient",
                "primarycolor" : "423F32"
              },
              "status" : "ACTIVE",
              "dateend" : "02-17-2222",
              "discount_version" : "1714176850699",
              "date_created" : "5\/1\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!32024",
              "campaign_name" : "testing bro",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714176851775",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "02-17-2222",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5vYG1",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
              "promotitle" : "Buy one get one free!!!!",
              "redemptionlimit" : "10",
              "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tKEZhrvi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.927Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2rksnfkm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.851Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "j3xvT1Dw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.843Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kMdQ35sb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.941Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "w4x8r6ed" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.656Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "p2jriWep" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.657Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uZtVy1GH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.219Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mXnAu1gk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.481Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7RfU9ziL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.920Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "q2R9aWSB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.601Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "umCbxKA6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.735Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "d3Esmkou" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.684Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3LXHN8Ty" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.081Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uhZPZV4w" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.869Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bViERSrs" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.057Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hCjzb3JN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.744Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3ZRnC1d3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:20.388Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2xyeXyfq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.666Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uCg9jvhV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.693Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "re1GPZmk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.487Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uSVrk7sn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:16.630Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7f7xPqS1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-30T21:15:18.663Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "m94aZkDF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.507Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vUqzygci" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:45.996Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7d3QytLp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.717Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "21B3AZnj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.697Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mXLQbV2o" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:07:15.239Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kn8A4KjW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.852Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mwRaWRwJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.506Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gh8LivmB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.754Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rTHAPCyw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.698Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fcujH9oB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:28.957Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3DLUfXkk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.676Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "odXWz4uZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.502Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uoMYJ4hx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.707Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3BE5LPJ3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-27T00:15:41.892Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "discount_version" : "1713911382071",
              "date_created" : "4\/20\/2024",
              "promomessage" : "Dolor obcaecati nequ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "pricing_rules_version" : "1713911382886",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-xvay7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2CXV2QYRHS9SPYXQG4QYNQJ55P",
              "promotitle" : "Nulla veniam placea",
              "redemptionlimit" : "8",
              "discount_id" : "BNCIFG2G3HVFRIXPCMUKZ36E",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "J2NH5KN454ESRJG6Z3ZV4FMA",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7bHyGoX3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.697Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6PhiwNn9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.556Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2aUbShvn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.659Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xfQZzhrC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.717Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6FEKLJ39" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.704Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "29n55ZVz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.561Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aKxq9NpT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:31:41.339Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dARbzaAJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.697Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eRJqmWYR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.793Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rEpDJUgy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.689Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sudQaMVa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.164Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-w4joh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DJYL4WS37ULZB72R6AP54BO2",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "2K7RNZ3151V34RX644NMT2FVVX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bM78sfcR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:34:43.555Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "t4XtcLM7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:01:18.884Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jyXzRbuV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.886Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mSTTEbFN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.903Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4qF2F6ih" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.898Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rReMiHnn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.762Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4Mgp4XzN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.724Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8qVdNJBB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:22.271Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rBpcaaBb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.511Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xh6rdr8L" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.836Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7rcvY3Yi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.742Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9pCfFZeh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:34:22.725Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "QkgmdiHd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.722Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "esF4w4j4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.946Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8GSzeD9i" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.659Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4EdbpAMM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.706Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "v9y2iemP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.693Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qk98ARGs" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.719Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dvFpUkpR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.147Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7q1fQxxD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.766Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3opZg8SU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.509Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eWgNgReY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.079Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6bjPoF1Q" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.765Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buw",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-waVUX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "C6Y2XBFX4XQRSJRRNOJJORTY",
              "promotitle" : "Buy one get one freewwwffffffff",
              "redemptionlimit" : "10",
              "discount_id" : "M6SGDJNMH19AREZAS4EDCG59R",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fCjVyhAq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.726Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "k7WFFrDK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-27T00:15:41.876Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8JYHLXiK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.676Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "t623PKep" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:39.271Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uU6QMTL2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:47:53.100Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eaeaAocw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.710Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6TYJf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "FW75POPSYAFQPSNWBGSLARNQ",
              "promotitle" : "Buy one get one free!yaooddd",
              "redemptionlimit" : "10",
              "discount_id" : "C17GJK7ERQ17EDRD3M62RG3BS",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eozg4kdG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.663Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "q9gGKd4j" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.706Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4gpdyUWd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:41.264Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "b4VatYhX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.333Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jaUMc67K" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:27.861Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "khXuujSU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.655Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "noRDioXF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.738Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kpyS2YY5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.841Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pMHuUnKg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.887Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pj4hxiRs" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:16.241Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eNy5k6Bs" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.056Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "otWNoLr9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.338Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8V6eCtrA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.665Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "b8kSHov8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.855Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5hvq3LV2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.581Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wSxUoqmP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.043Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cQakGkrz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.482Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "b5gWQt58" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.686Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9V2ckgMN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.738Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bzYuSm5B" : {
            "pathname" : "\/insights",
            "time" : "2024-05-01T00:55:22.297Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoId" : "kali-hxzr3",
              "pricing_rules_version" : "1714087742664",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7t7BJYx1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:20.385Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ddNRssti" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.698Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hXzYYXtr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.877Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "o7gdcubu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.697Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ubP6zuPH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:06.184Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "e9AhHJUL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.836Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jySosqvn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:49.992Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "552N2enZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:01:18.860Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qREra7an" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:50:39.806Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qX3wP6ch" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.708Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uREcHQEe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.127Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eZ6K11eX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.596Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aXeBQ6oD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.720Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "u8sqmhqe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.896Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uBccMX3F" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.249Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uopDuH3s" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.662Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dy61Sx7s" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.662Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "weA22k99" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.831Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "61scbxQB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:31:41.336Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pPtCyzvC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.701Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vnbbZyyd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.693Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vnsUWvVn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.667Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wJAPQe4G" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.496Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kxpErPxq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.735Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "s5ULVN98" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.162Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9eXzgg61" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.731Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jbjzMaxR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.812Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gzEsujGk" : {
            "pathname" : "\/insights",
            "time" : "2024-05-01T00:55:22.308Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-27-2024",
              "discount_version" : "1714087328770",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "ADD ONE",
              "promoId" : "kali-pctdy",
              "pricing_rules_version" : "1714087329540",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pctdy",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "37RT95TN5VJ0TVW24JDET8ZGR4",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "SMM7HSBILDKN2RNTUUT67ZSZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "PFL5QMIMAJRJA4NJOJTQ7QNK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "do3g2Ynn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.332Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jzKZxtum" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:31.476Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "s2aiTn2P" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.011Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mMFNuM5e" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.664Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "spCKCT45" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:34:43.569Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nQU8das6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.748Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "98MdmdpS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.498Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7U7hJmnS" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T01:31:34.497Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pxkuV7c4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:30.977Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7uFn17wA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.909Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vVZhCog1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.962Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oU19SUcJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.650Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wWK94aF5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.541Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2apAJirL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.692Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pKyU4eRQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.669Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5RzLusgt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:01:18.888Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ftWm6GLN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.734Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ZVLZUfNe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:42:01.476Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wJFB7yZS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.661Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oHqyzJ8K" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.743Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2RVCU9BW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:40:42.088Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rygYjNw8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.500Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6H3tmKJk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.549Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bQ32g4yx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.122Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fR3Y5j3f" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.670Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wpXu6iJb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.045Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jMKZhmak" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:06.200Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "n2aFcCVx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.142Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8x98RuGm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.661Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gL1y9KEQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.607Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2ZnY9Jra" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.712Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2osRUiwk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:40:42.083Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bTKrbZKB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.075Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tS7iJsAk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.474Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tJSnqVgA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:28.955Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "v3yxp8Sz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:47:53.099Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cssxrHHr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.651Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "t8sxBoU6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.563Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hcLYdFMp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.688Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "m9MSnfVT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:29:22.154Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6bMYwvo2" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:19:52.021Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tBXr5spB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.655Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iScAEhNN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.763Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5o8HqkBb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.722Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "27s4FE7T" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.561Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "s7gpCHiE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.121Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pL2gYhAc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.698Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "WMQ63Nni" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:58.006Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dKRJZ8QD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.599Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kPm4SWgP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.272Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3YQTV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5OUAT7Y44KBSRYOB3OUB7NWR",
              "promotitle" : "vvvsdqsds",
              "redemptionlimit" : "10",
              "discount_id" : "7YA7QCWCJ9E5DSG518VE1HQH51",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xsJvq4a1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.493Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8Bd52gWq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:36.382Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gJmhBLkT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.900Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cG5KmnmX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.677Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2ghyPDMQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.755Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "i7Rq66Ss" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.763Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-03-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-jLvkU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "LQHDRJJW7KD6PFIJ5RX2OMEC",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "16",
              "discount_id" : "75QPKTRCDXWNPCG2H92XVVJBB",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cTDJvfMD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.724Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "krsgfP3i" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.720Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-19-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-h46AF",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7LJTKKZ6JIJWVLWYGZ7XUVAR",
              "promotitle" : "Buy one get one freeaaaasd!",
              "redemptionlimit" : "10",
              "discount_id" : "2YVVCH3T0HW3C5CPMM9BNV5EX3",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vGGcsToa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:34.218Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9jXqmBJH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.674Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4UUG3LsP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.767Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5YXqd4Bw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:16:07.806Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7nsGEW4j" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.333Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5JkriT4y" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:16:07.813Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dWg7tQMH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.687Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3iJ2qWWb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.847Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4hpbFiRn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:27.850Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "e5ZrMoLy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.706Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mN6Vr6oU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.521Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2S5363wh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.276Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mvDdYgXM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.745Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eLHHmfZV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:29:22.151Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fjKCEwTP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.724Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dFaxZhi8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.592Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nVcnMs9C" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.678Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "28DxBMMQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.692Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eC396LRC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:16.634Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fCRckntn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.164Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tJa76wE8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.664Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eA4zRDwA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.597Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hEqvReCV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.700Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sBnypjBd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.667Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cBmzRJ6A" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:39.262Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9aEux5ZH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.738Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "crByXAPa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:22.309Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7dg8LBjD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:04.168Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fxywaKM9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.721Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nd14kS2u" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:36.384Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "agUnPvpD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.696Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qETfcXXv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.721Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "64dG4a2s" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.152Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "j7i2KAhG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.216Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ea6XgR5N" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.702Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nHq2eoKF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.840Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dTmEGe4w" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.730Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "duNPifGA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.739Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mvR1A71y" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.588Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qpFjnUFY" : {
            "pathname" : "\/insights",
            "time" : "2024-04-27T02:20:52.254Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-27-2024",
              "discount_version" : "1714176850699",
              "date_created" : "4\/27\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "testing bro",
              "promoId" : "kali-wb8h8",
              "pricing_rules_version" : "1714176851775",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wb8h8",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ojrxp6NV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.890Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ePJ2QxgN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.846Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m2RnV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "SB3A73LCRHQTTPA2R7OTLS7T",
              "promotitle" : "Buy one get one free testi,ns!",
              "redemptionlimit" : "10",
              "discount_id" : "9V3AS0RBEBA5ZF8GCGRPRZ58V",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8SGCPcjK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.252Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "s2SKLUrN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.678Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sDnRT2gj" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:31:52.613Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "icUvgX6o" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:34:22.730Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jfMk8ntF" : {
            "pathname" : "\/insights",
            "time" : "2024-05-01T00:55:22.306Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-26-2024",
              "discount_version" : "1714085524819",
              "date_created" : "4\/25\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promoId" : "kali-mfN7h",
              "pricing_rules_version" : "1714085525933",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-mfN7h",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "159539FYEMFWKFG2F4F3Z7SACA",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "XJKVFVCD3MAFCNUSBKVA3EJY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "OYDE3TIFS7JZRCUDQTO3KSJK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "epwnq7Gd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:49.986Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "h94rBB3a" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.410Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wQSg8tTb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.224Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "arGi9QZk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.939Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qHuugPut" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.963Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5sLLV5gr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:34:43.567Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bjpUcdCU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:21.693Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kuEZ3xTj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.226Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pMUM2WiB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.258Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eRBFXSoE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:50:39.792Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cwCWD2YZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.714Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pJGwSeFw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.909Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "43ZjuqfW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.739Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aiR6rWYF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.702Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kHKHCZsu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.509Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dUkVrRs3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-21T00:07:43.552Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/21\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-22-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUFW3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4A1CBWMXNY2RZPYWE03XJT2FTW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "GPFWKEX4YSYZNE5SRAHZ7O6T",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "O2SZLUHVK7GSQW552VJBYFWP",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "v4rWtGL6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.677Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gRPxrBo5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:21.692Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "x1kqTbMK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.158Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sXZAtNUf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.869Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "94euQfqB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.065Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7933DPb7" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:24:02.023Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qxbSjVL8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.242Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tYEcEUQx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.255Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8oSvA55i" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.707Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ssfuS3zF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.741Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bqQZ5FH5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.479Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wR42jEn1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.415Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oZmLTVpF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:39.271Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rAc8JHcn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:07:15.244Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6rojfRj7" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T01:25:49.890Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB8",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "02-17-2222",
              "discount_version" : "1714176850699",
              "date_created" : "5\/1\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "testing bro",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714176851775",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "02-17-2222",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9p8MC",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "E4LsDAun" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.920Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2sn2q8tG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.500Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5keKu7g6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.730Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7DmrFNJE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.683Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pZ6HpiZL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.620Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aBSjhTgr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.677Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hkaPauJv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.413Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jFRJ4d9g" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:06.580Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "h6MTLgvB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.908Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ersGVjru" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.905Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8y7CsKLf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.258Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "emAdGU93" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.670Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "b8RhRyU5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.707Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "s4P9AWso" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:31:41.356Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9ftayqyG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.769Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-24-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!SSS",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-gHrS5",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "RIET7M5255NTOBCC6XMRV6Y3",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5W0NA67DBB5XW21HWJGZ6140V8",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sxSL5rZp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:39.258Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hkzwxpxZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.750Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iaQ818jA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:45.989Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vxWuryn3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.946Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pN7Hi3dm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.910Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rTjR8Zgo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.534Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "skqApVb7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.664Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mQdD9ojt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.238Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "r1mFqEQQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.506Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bKw2P6bR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.398Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7rNSDFNS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:32:29.517Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cJ2XnGkA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.066Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6kfUDk96" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.979Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uohihwrN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.656Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "biLdy7rQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.672Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kNAbyuKA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.721Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-idJPC",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4ZOOZ5QWMEKMORLC4QIXUX4Q",
              "promotitle" : "Buy one get one free YOO!",
              "redemptionlimit" : "10",
              "discount_id" : "78JD8R3BSSFDN21QB23HPWWD9F",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wPcjVKei" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.704Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jh42BfYr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:18:41.817Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bcBJSP58" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.854Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rUiB4faz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.425Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "byqv1oDi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.580Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iHXcAKFW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.694Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vqV5JA3t" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.685Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "srTMtkiy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:29.178Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7itnNH2z" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:16.628Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "n6Q8qJNY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:41.264Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pNQnBErB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.712Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "53dZMH3n" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.601Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "q7gVE6VJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.113Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3rwWwRqr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.785Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "k9mgRPYe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:49.996Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "urknAWPQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:31.488Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bcDhUrm2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.690Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aKwW3XiJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.534Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "csrapqc9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.583Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5wrLqad4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.822Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-03-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-jLvkU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "LQHDRJJW7KD6PFIJ5RX2OMEC",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "16",
              "discount_id" : "75QPKTRCDXWNPCG2H92XVVJBB",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dKoCHeqM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-25T23:22:10.473Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-26-2024",
              "discount_version" : "1714085524819",
              "date_created" : "4\/25\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "pricing_rules_version" : "1714085525933",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-mfN7h",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "159539FYEMFWKFG2F4F3Z7SACA",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "XJKVFVCD3MAFCNUSBKVA3EJY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "OYDE3TIFS7JZRCUDQTO3KSJK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gPctQHz3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.784Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iSPnajfw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.399Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nD8oWioV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.662Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "w1rNjQWb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.700Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7Y8iv4XY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.510Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m2RnV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "SB3A73LCRHQTTPA2R7OTLS7T",
              "promotitle" : "Buy one get one free testi,ns!",
              "redemptionlimit" : "10",
              "discount_id" : "9V3AS0RBEBA5ZF8GCGRPRZ58V",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "otYWnBjj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:36.395Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "e1AB9eE9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.727Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3YQTV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5OUAT7Y44KBSRYOB3OUB7NWR",
              "promotitle" : "vvvsdqsds",
              "redemptionlimit" : "10",
              "discount_id" : "7YA7QCWCJ9E5DSG518VE1HQH51",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "srwqkqQC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.612Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uHAxFraG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.136Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "95goxTQ1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:30:07.957Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "m9tkwd7H" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.964Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4CQ1JFrb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:32:29.513Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "v4yKLbpU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.284Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6SgDmQfm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.737Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qkVyGpRd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.848Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3YQTV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5OUAT7Y44KBSRYOB3OUB7NWR",
              "promotitle" : "vvvsdqsds",
              "redemptionlimit" : "10",
              "discount_id" : "7YA7QCWCJ9E5DSG518VE1HQH51",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9yKGJGuQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.685Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4ro3vMeH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:55:10.058Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8PetiiUc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.134Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7UXyLDjp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:53:22.628Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oBqgs9By" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.486Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kwd9UPBn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.849Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8k2Kb9jw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.702Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tGZBDAgT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.330Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6sTpEpGH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.654Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eUcTsRNk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.496Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hBgB1Q2G" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.515Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "t75jfAF8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.691Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4uHU1ahF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.748Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "r3vnztEr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.647Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "okyi9EFZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:42:01.472Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nXZo82mD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.866Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pngcnbjq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.914Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "GxQsXoP6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.507Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "d3Nvp67J" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.674Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oRkzh54P" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.507Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "azgodUAL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.699Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uWEpznK4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.673Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5YEgZnTF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.665Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "didQJfDQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.990Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ohtBQHoQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.197Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eaiRiDma" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:23:15.241Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "d3SKixjP" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:24:02.030Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wsPEnqNJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:08.500Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "49SRBQ6w" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.698Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4EbWtzqB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.669Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "353m1Thu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.716Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tCuhkphA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.972Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mjfWHBze" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:33:53.770Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "a2J3pYc9" : {
            "pathname" : "\/redemptions",
            "time" : "2024-04-21T00:09:53.080Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/21\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promoId" : "kali-qUFW3",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-22-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUFW3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4A1CBWMXNY2RZPYWE03XJT2FTW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "GPFWKEX4YSYZNE5SRAHZ7O6T",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "O2SZLUHVK7GSQW552VJBYFWP",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ouK5HV76" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.862Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "m837dL7m" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.508Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rHqrifd7" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:31:26.233Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hn1iRvTQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:06.186Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dzC62aVh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:53:22.656Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "64nY6Zuk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.706Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bDPvFWjB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.665Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uXeN3uiB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.247Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jtGurdbz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.721Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "u4JdPxGp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.647Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "d66Ugbn2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:32.832Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8KHhQ1DS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.398Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uCCBnS4F" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.526Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3YQTV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5OUAT7Y44KBSRYOB3OUB7NWR",
              "promotitle" : "vvvsdqsds",
              "redemptionlimit" : "10",
              "discount_id" : "7YA7QCWCJ9E5DSG518VE1HQH51",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wjkmAnzx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.135Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tkd4ZcUF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.751Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "s8WGELZX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.703Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9JgLCsns" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.724Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iXVg7cvq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.670Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "u8FYwJky" : {
            "pathname" : "\/redemptions",
            "time" : "2024-04-21T00:25:47.816Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "date_created" : "4\/20\/2024",
              "promomessage" : "Dolor obcaecati nequ",
              "promoshowterms" : "YES",
              "promoId" : "kali-xvcay77",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-xvcay77",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2TP8ED36V8N400ACDMQRGDXVW7",
              "promotitle" : "Nulla veniam placea",
              "redemptionlimit" : "8",
              "discount_id" : "XA65HS743S7Y5LDZ76T5ARU5",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "R4PSCDEGMEVMHYIC6L63ZEJD",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uwhFeQAK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.876Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uRFSxpYc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.722Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dtsin8Yt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.650Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eFbXeADP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.737Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nAFiXidH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.201Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hZBFb8Gy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.899Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aD7cMT3q" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.839Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "q6rfFGoE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.691Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "useMgDMc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.702Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kTz6w7cd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.845Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "s34hPjWt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:47:53.104Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sqixwiNA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.683Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kLveCTeR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:22.246Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9pfwFcBC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.255Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aQ9iDg8o" : {
            "pathname" : "\/insights",
            "time" : "2024-05-01T00:55:26.171Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-27-2024",
              "discount_version" : "1714511902704",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "testing la vi",
              "promoId" : "kali-q6UGw",
              "pricing_rules_version" : "1714511903551",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-q6UGw",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "52KC2275Y31N8TXWYBYSSKCPTK",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "7NJWKVFIX42ZY4NZRCDQX4YZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "FZET4BWMP2YWGI5ASOYUMQJZ",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "n81fYvxH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.917Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oxhukEtr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.501Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gZ6Qp5ZZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.682Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "s21PP5BP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.733Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9veCeWS6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.323Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9miw1bg8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:34:22.726Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "a8uHvVyx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:21.686Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3fm92MBX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.147Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8hdzL6nd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.654Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "b5FPhkUC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.676Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mpTqqTLe" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:21:56.490Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dbotfR1H" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:21.698Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kXCkryvK" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:21:12.830Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tbGTzwyQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.912Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "85Dw3SwT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.387Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bDMo2zJ2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:48.195Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aq43NRLz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.510Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "khLZNgyj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.695Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hQiWHJcE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:29:22.142Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2U6samGU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.667Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gWBdEcSh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:50:39.791Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hab8jeJ1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.654Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fBuw3efh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.909Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nYEDRnbD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.491Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2fGiVzXn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.787Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4fwNF1Cq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.845Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "duuRZQ1x" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.675Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hW77Lw12" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:46.003Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "khJLDtuT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.741Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sQMZaZHE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.752Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8phPUnRF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:36.382Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3mSpndiq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.728Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vQMn7Qwh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.675Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "84SMt6h2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.312Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kpcVQJLe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.726Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2FWsXWgk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.702Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dtYzPdiM" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:33:48.216Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "o2B7ioMa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.681Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pZVqq7jQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.777Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vhGuTxKA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.151Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wbg3R1Gc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.040Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qTiTSTvV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.807Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "d6sHeBDg" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:20:16.019Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jkKw2MqB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.603Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bk5Qa4GJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.497Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bBfXs95A" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.713Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qENDPm4T" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.176Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3q7ptAaC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:04:14.825Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fG5nRnqd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:36.388Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gNDjXWoB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:06.582Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tjXzLmG6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:42:01.471Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cz9DUHog" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:06.566Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6exxV7EC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.021Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bQ7aqgi9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:31.485Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pKbnUabN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.503Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iqMvtzyX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.063Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bei2xZbK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.426Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "n3m8Sa7W" : {
            "pathname" : "\/kali",
            "time" : "2024-04-27T00:01:45.706Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uMprErxC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.842Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9Lu6F3MQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.262Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vNsbzYNM" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:21:12.832Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mG9cTJaK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:06.568Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pUAVmgRt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.986Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "74Rn9Avn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.037Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "91F3HiHw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.146Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bDkF1cqm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.726Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2WB5xbtb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.849Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6AY4XiSe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:34:22.715Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8Nuu2PUM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.342Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aihQDDiE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.164Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7zMEvTWY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.819Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-idJPC",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4ZOOZ5QWMEKMORLC4QIXUX4Q",
              "promotitle" : "Buy one get one free YOO!",
              "redemptionlimit" : "10",
              "discount_id" : "78JD8R3BSSFDN21QB23HPWWD9F",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "78vRSrT8" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:31:26.234Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kWA2QvML" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.419Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mqLWLsAQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:47:53.102Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jMcr1V1d" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.547Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xB2QjzrA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.749Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ucHGsDpk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.498Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "XwXrVYRy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.723Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "p9Pd8FyK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.592Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5AtzjTwE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.659Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "e61Dgin6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.600Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hxjZ499H" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.055Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uYYTpXLW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:06.568Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wBRxVhss" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.394Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dSeZLn8h" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:41.270Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "guHsc4MB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.980Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pbVrWkuA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.261Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iZ8qWtf6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.151Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "d7cPf9pA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.107Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "Hs4Pq79y" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:45.997Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jCNVXXGU" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T01:26:32.696Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB8",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "02-17-2222",
              "discount_version" : "1714176850699",
              "date_created" : "5\/1\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "testing bro",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714176851775",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "02-17-2222",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5igma",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uBWXiAGA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.738Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nGesAV6m" : {
            "pathname" : "\/kali",
            "time" : "2024-04-21T00:08:53.636Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/21\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-22-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cH9SJ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "11YFSTAFW17DCJA48655GE4GZ6",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "L4ZQHJONOMQH37ROMRWQM6JU",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "INKX62DYGAQ4M3U5PZEBJCTQ",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6nV61CPr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.605Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3fNGSTq2" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:19:57.118Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "guDY2pHH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.620Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bHUmVvtj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:28.972Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "csSyNEof" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.731Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qgu3UsJ4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.711Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qvMRNtJ7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.125Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jr3MvLdS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-27T00:14:12.650Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2i3VhsNp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.823Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4qqZ3LQv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.665Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "i6t2Z3JH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.128Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "s4FxpBBg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:42:01.469Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5SZvkgtS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.329Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "h1E2MUG9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.598Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buw",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-waVUX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "C6Y2XBFX4XQRSJRRNOJJORTY",
              "promotitle" : "Buy one get one freewwwffffffff",
              "redemptionlimit" : "10",
              "discount_id" : "M6SGDJNMH19AREZAS4EDCG59R",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mb9difns" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:40:42.073Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dbQsrU65" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.558Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jx2uACQG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.060Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ow7Svikg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.556Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nGwYGKkk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.037Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fR9YLeKz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.907Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7f7G4ckN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.686Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kYdbmWHT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.806Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kgAMEUgF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.709Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7yLSuvtz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.670Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "orHufPRi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.868Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "q53qPkNr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.716Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wxq3jo7g" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.883Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "m12FuKWA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.843Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-19-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-h46AF",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7LJTKKZ6JIJWVLWYGZ7XUVAR",
              "promotitle" : "Buy one get one freeaaaasd!",
              "redemptionlimit" : "10",
              "discount_id" : "2YVVCH3T0HW3C5CPMM9BNV5EX3",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gvGMnxFY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:22.241Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aJPL7gxT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.681Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "grRHDiXg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.251Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gGvGKe2E" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.697Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hU4oRyB6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:04.165Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7n8kB823" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.846Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6TYJf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "FW75POPSYAFQPSNWBGSLARNQ",
              "promotitle" : "Buy one get one free!yaooddd",
              "redemptionlimit" : "10",
              "discount_id" : "C17GJK7ERQ17EDRD3M62RG3BS",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "obYTN9nh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:04.156Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3f1g2mQ3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.726Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-w4joh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DJYL4WS37ULZB72R6AP54BO2",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "2K7RNZ3151V34RX644NMT2FVVX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jCPeR6QE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.523Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-03-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-jLvkU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "LQHDRJJW7KD6PFIJ5RX2OMEC",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "16",
              "discount_id" : "75QPKTRCDXWNPCG2H92XVVJBB",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6YxCRK4J" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.204Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mPyFB6pQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.548Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6LZyhGj8" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:21:56.494Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vWM7VVT4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T23:27:58.934Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/21\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-22-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUFW3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4A1CBWMXNY2RZPYWE03XJT2FTW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "GPFWKEX4YSYZNE5SRAHZ7O6T",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "O2SZLUHVK7GSQW552VJBYFWP",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kNhLCtZU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.743Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5WFP4dQY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:40:42.081Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rZuoZU9K" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.656Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3AfAH3eR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.570Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vgRYZQQt" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:25:25.744Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9nTMuNxE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:16.239Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tEcYxjpn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.706Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cPmGW5DJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.122Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7GEhodBr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.567Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bnGNosug" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.751Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "f6114Bgx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:50:39.791Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hbEzDGQ9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.754Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bjRTfK41" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.502Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iX1mMZaa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.711Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fKZHvEJY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:39.272Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wfDEmYBy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.674Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "azfPQVL5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:53:22.646Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tP9zTBbj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.724Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8dfnvYPM" : {
            "pathname" : "\/kali",
            "time" : "2024-05-28T22:44:20.446Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB8",
                "imagename" : "cookie.png",
                "promotype" : "2-gradient",
                "primarycolor" : "423F32"
              },
              "status" : "ACTIVE",
              "dateend" : "02-17-2222",
              "discount_version" : "1714176850699",
              "date_created" : "5\/1\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!32024",
              "campaign_name" : "testing bro",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714176851775",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "02-17-2222",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5vYG1",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
              "promotitle" : "Buy one get one free!!!!",
              "redemptionlimit" : "10",
              "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sXkXxYwk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.865Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dagDmC2X" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.061Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pAStWUCQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.572Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qiNCg4Zy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.569Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3rtn2w6a" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:16.621Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "onEFVdvC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.663Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "r64cyKwP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.135Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3sqrbvC6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:29:22.145Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3AUoqGGU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.582Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nDZUsA7V" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:22.239Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tcihihuK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.159Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3Hvq9SpV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.743Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "c483NfP9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.708Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iv2577p1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:34:43.578Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gT27S24f" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.108Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "s5qe2wHL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.851Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3tcR1Lsk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.149Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bWH6PY5Y" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.750Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vRs4Khqw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.737Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cLQZtQmR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.577Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gzinPSfJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.871Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jwjFCRRY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.707Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uY7DxCLM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.680Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8ZwJqqBk" : {
            "pathname" : "\/insights",
            "time" : "2024-06-25T23:42:43.968Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "5\/28\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!!",
              "campaign_name" : "Boris Boyle",
              "promoId" : "kali-hxzr3",
              "pricing_rules_version" : "1714087742664",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-eAPoT",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/125.0.0.0 Safari\/537.36"
          },
          "iiE2TeQc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.708Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wgBps5js" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.940Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mzWNLd4Z" : {
            "pathname" : "\/kali",
            "time" : "2024-05-28T22:42:29.364Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB8",
                "imagename" : "cookie.png",
                "promotype" : "2-gradient",
                "primarycolor" : "423F32"
              },
              "status" : "ACTIVE",
              "dateend" : "02-17-2222",
              "discount_version" : "1714176850699",
              "date_created" : "5\/1\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!32024",
              "campaign_name" : "testing bro",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714176851775",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "02-17-2222",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5vYG1",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
              "promotitle" : "Buy one get one free!!!!",
              "redemptionlimit" : "10",
              "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oEDi7axH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:22.289Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mE9en8Ho" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.686Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "q2rYVc3E" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.050Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bHeETJTo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.697Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4cwEKHAm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.715Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "59UBF824" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.772Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "665G34Ln" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.173Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "acPakuUj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-30T21:18:25.070Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4qLyB9iu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.692Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "j8jPwmaY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.867Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "38Kf9Qwb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.492Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pafhFACk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.708Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aoReKABU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.721Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sprqDsmz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.736Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "t5Y4GUZo" : {
            "pathname" : "\/redemptions",
            "time" : "2024-04-21T00:11:14.937Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/21\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promoId" : "kali-qUFW3",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-22-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUFW3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4A1CBWMXNY2RZPYWE03XJT2FTW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "GPFWKEX4YSYZNE5SRAHZ7O6T",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "O2SZLUHVK7GSQW552VJBYFWP",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pngE2nyi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.264Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6hZYit3x" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.245Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "53t8cwjg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.718Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mKWvf687" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.653Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gHn7fnvE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.663Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6KpgB4NJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.236Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7EBn93Hz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.721Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eEKXQrac" : {
            "pathname" : "\/kali",
            "time" : "2024-04-21T00:07:56.018Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "date_created" : "4\/20\/2024",
              "promomessage" : "Dolor obcaecati nequ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-xvcay77",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2TP8ED36V8N400ACDMQRGDXVW7",
              "promotitle" : "Nulla veniam placea",
              "redemptionlimit" : "8",
              "discount_id" : "XA65HS743S7Y5LDZ76T5ARU5",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "R4PSCDEGMEVMHYIC6L63ZEJD",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4D6ND6tq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:16.621Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ay3UmyM9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.617Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4ie1j3uJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.570Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xBdSn6SK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.704Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gp2VK545" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.778Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ksQLYQuR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.594Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4RPU6aNf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.249Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cVjGMbPW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.468Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cgu2BqXp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:58.007Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5a9WvHVW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:48.197Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6hfS1Zdy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:50:39.804Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iBRC4eAy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.167Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "h5T8Jt6C" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:19:32.521Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dQSDSg2u" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.701Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cfQydrbs" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.537Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ddGhDi8y" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.909Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aTT8fmzy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.505Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "t7i8eXij" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:39.261Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "h7w71Naw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.731Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5cMvvUnh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.514Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "83FNnGoc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.654Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "opT94Vds" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.045Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cboepyHj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.075Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eKSk41Rh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:07:15.242Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vbPVUMvo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.665Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hMaAsd2T" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.125Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5Pcxopyn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.816Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "b53wCJu7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.699Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nyHYF5oP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.319Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7yFEpPa9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.488Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tELu7P7v" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:49.995Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4MfWznm5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:47:53.093Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ndAWJKVY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:07:15.252Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bxgLyJzC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.283Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6GFYRbD2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.499Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uSEgmqd4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.590Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uyQdsShy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.504Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ev7V3NrZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.526Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pfULFCJA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.168Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dzBrrU1m" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.597Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fmEBrq52" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:04.152Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "txGSDr7K" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.669Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wyn6USGA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:42:01.466Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9dse8Tm8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.831Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ssejQg26" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.280Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sZikCNoK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.684Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2WQ6S239" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.660Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "inZw199D" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.832Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hD17mymL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.877Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vH8fPGRC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.519Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "J7tK53gQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.271Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jNuTFLNi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.910Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nRouYTxt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.917Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-20-2024",
              "date_created" : "4\/17\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-keND5",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "52VAVJ3305QSG773RTRFTCP9JE",
              "promotitle" : "Buy one get one free edting !",
              "redemptionlimit" : "10",
              "discount_id" : "W2D4N3MDFEXC2OKW2XSS7CFM",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kUqRSf4a" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.860Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wSijZpth" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.269Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8r3npR2H" : {
            "pathname" : "\/redemptions",
            "time" : "2024-04-21T00:10:59.597Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/21\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promoId" : "kali-cH9SJ",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-22-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cH9SJ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "11YFSTAFW17DCJA48655GE4GZ6",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "L4ZQHJONOMQH37ROMRWQM6JU",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "INKX62DYGAQ4M3U5PZEBJCTQ",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "maD3qfzh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:34:22.708Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mcL1Nf24" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.499Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8KRshr6V" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:30:07.953Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fSGDX3Yf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.248Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ksDjZxLE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.706Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "21uwBZVv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:29.179Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gQQKQsrg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.307Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3UMVFLbX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.843Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "exfbKj2B" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.516Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6TYJf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "FW75POPSYAFQPSNWBGSLARNQ",
              "promotitle" : "Buy one get one free!yaooddd",
              "redemptionlimit" : "10",
              "discount_id" : "C17GJK7ERQ17EDRD3M62RG3BS",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cDU27NAB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.599Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5AurcXvq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.047Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iB5vTY6Y" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.849Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "j2d7jkhm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-30T21:15:18.675Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8f7Qnr4d" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.825Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2N24QUFS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.525Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buw",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-waVUX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "C6Y2XBFX4XQRSJRRNOJJORTY",
              "promotitle" : "Buy one get one freewwwffffffff",
              "redemptionlimit" : "10",
              "discount_id" : "M6SGDJNMH19AREZAS4EDCG59R",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8SwX1E63" : {
            "pathname" : "\/insights",
            "time" : "2024-04-30T23:18:00.958Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-27-2024",
              "discount_version" : "1714511902704",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "testing la vi",
              "promoId" : "kali-q6UGw",
              "pricing_rules_version" : "1714511903551",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-q6UGw",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "52KC2275Y31N8TXWYBYSSKCPTK",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "7NJWKVFIX42ZY4NZRCDQX4YZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "FZET4BWMP2YWGI5ASOYUMQJZ",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kwmHKiE2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.703Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mS4wfEfz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.889Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qi5t6X54" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.608Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buw",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-waVUX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "C6Y2XBFX4XQRSJRRNOJJORTY",
              "promotitle" : "Buy one get one freewwwffffffff",
              "redemptionlimit" : "10",
              "discount_id" : "M6SGDJNMH19AREZAS4EDCG59R",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "98YRnC5m" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.903Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9xVpQNXL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.140Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "t2ZgBbm6" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:33:53.752Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB8",
                "imagename" : "cookie.png",
                "promotype" : "2-gradient",
                "primarycolor" : "423F32"
              },
              "status" : "ACTIVE",
              "dateend" : "02-17-2222",
              "discount_version" : "1714176850699",
              "date_created" : "5\/1\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!32024",
              "campaign_name" : "testing bro",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714176851775",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "02-17-2222",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5vYG1",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
              "promotitle" : "Buy one get one free!!!!",
              "redemptionlimit" : "10",
              "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qWNg854t" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:19:57.151Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rfhZTQQR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.700Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oJm9DiEJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.551Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9j2xMrnE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.903Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aiPfXQgy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.728Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "trnCXQtp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.887Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bqmzwTNP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.843Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hmTNAKW5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:31:41.359Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "q9qwikV2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.116Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "so1KEEVY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.729Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "w2BAhCv7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:30:07.961Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8SVFadbZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:08.508Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pRucS79u" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.680Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "unCjua3e" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:18:41.808Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jfPfsMym" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:31.470Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "k3AP13zC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.519Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-oiZHu",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "547WFMTLS5PXLOORF3XZSOFK",
              "promotitle" : "Buy one get one free testing Amount!",
              "redemptionlimit" : "10",
              "discount_id" : "2G2MN6MET28GGM0DPKQHW3EXDP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "k8GMCN63" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.659Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "g1YaWqWq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.579Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "n54gJwwi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.515Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-20-2024",
              "date_created" : "4\/17\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-keND5",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "52VAVJ3305QSG773RTRFTCP9JE",
              "promotitle" : "Buy one get one free edting !",
              "redemptionlimit" : "10",
              "discount_id" : "W2D4N3MDFEXC2OKW2XSS7CFM",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4NnakSxc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:39.263Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9fHgVU6J" : {
            "pathname" : "\/kali",
            "time" : "2024-04-27T00:15:41.845Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-27-2024",
              "discount_version" : "1714176850699",
              "date_created" : "4\/27\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "testing bro",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714176851775",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wb8h8",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jyzDQ9Qr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.777Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aERS4vbE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.602Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kNmQgH14" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.891Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wSzSohM4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.153Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-03-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-jLvkU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "LQHDRJJW7KD6PFIJ5RX2OMEC",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "16",
              "discount_id" : "75QPKTRCDXWNPCG2H92XVVJBB",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "s93PBpp2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.729Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3YGy3d7S" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:31.475Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8qkhwLaD" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:19:51.981Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vycg88CP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.704Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kgddEqNJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.340Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4PVC2atu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.758Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8WDNRv9H" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.505Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ADZ99ces" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:27.851Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8n4E4EDM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:48.195Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vm8rGtHv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.817Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dVbfkAJ1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.542Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rRQXAEMo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:01:18.857Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jhEMCroJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:08.526Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pb6iE1xR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:48.174Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ri24a2Dc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.581Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "p5ppQPLb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.386Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pKBEGQ1p" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.745Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pw16jsRi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.728Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7RLbLnwV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:27.854Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jnR36mnM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.213Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "npY8gzcG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.755Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pA4D2QN8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.056Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ejJ3eMy7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.839Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "esTdXysh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.709Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fWqK689p" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.966Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "GgSfEUex" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.680Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7N1jG2zt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.901Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aaQsdvid" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.752Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "d1hphpQN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:31:41.346Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "54EqczMk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.596Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-03-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-jLvkU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "LQHDRJJW7KD6PFIJ5RX2OMEC",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "16",
              "discount_id" : "75QPKTRCDXWNPCG2H92XVVJBB",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dFwkv7Ld" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.174Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fQ146AcL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.060Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "d3kdtbUU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:32.815Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5TjvXdNK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.936Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "j8S9ka8S" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.709Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xaBWsZu2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.246Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "boBCwT1N" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.662Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sRBHDxiR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.704Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cUt4ZAbC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.725Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uJx5QrYm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.655Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xzJqFQgG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.893Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pxwVL1Vm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:04.164Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aem8qzKt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.558Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rLr9kwyC" : {
            "pathname" : "\/redemptions",
            "time" : "2024-04-21T00:25:53.255Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/21\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promoId" : "kali-qUFW3",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-22-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUFW3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4A1CBWMXNY2RZPYWE03XJT2FTW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "GPFWKEX4YSYZNE5SRAHZ7O6T",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "O2SZLUHVK7GSQW552VJBYFWP",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4Z9oB3mA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.678Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "woiAxd21" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.650Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8tHDDm2o" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:29.187Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gNGf7Vyz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.873Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ksqKvbtu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.703Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "twLRje4b" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:34:43.566Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7SrRCjam" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.315Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dAeM73jU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.839Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hKBDaTQW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.681Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gGTv1Mvj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.933Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bx3MJPx4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.618Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oqBtLkVm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.739Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dcEK9Sxx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.818Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6TYJf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "FW75POPSYAFQPSNWBGSLARNQ",
              "promotitle" : "Buy one get one free!yaooddd",
              "redemptionlimit" : "10",
              "discount_id" : "C17GJK7ERQ17EDRD3M62RG3BS",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vssuV4fr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.687Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kq6M47XT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.694Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "52xzsdkd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:29.186Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3euhM8wt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:28.971Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wPnfGJAp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.882Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eEcRKHwk" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:21:12.822Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3zZYBayP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.697Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3bJTyWvP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:40:42.081Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "486SD9ed" : {
            "pathname" : "\/insights",
            "time" : "2024-06-25T23:42:43.970Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoId" : "kali-kekQV",
              "pricing_rules_version" : "1714511718073",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/125.0.0.0 Safari\/537.36"
          },
          "xwrqUv1r" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.153Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-03-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-jLvkU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "LQHDRJJW7KD6PFIJ5RX2OMEC",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "16",
              "discount_id" : "75QPKTRCDXWNPCG2H92XVVJBB",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "t1hcoEBy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.711Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7TSSfvL4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.143Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jxqKsYwn" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:21:12.829Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "DE8VjBAL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:18:41.813Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mViZsHnU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.744Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ofoE2C3P" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.752Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "keVCYTbZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.511Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iWcGimzH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:34:43.560Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wFErysBn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:08.527Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wj88x6hT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.496Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cVWunQLm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:39.270Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kpK2eJeE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.584Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kscZK2uk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:40:42.084Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "w3CNJ7qF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:50:39.801Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fH4a5tWq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.707Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "92RgPhuh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.577Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cUBSCEp5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.580Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "e7PEzy4u" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.673Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gs6GXF8V" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.716Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fYPf1NZM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:16:13.236Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vm7rsiav" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.698Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xzsKhH2D" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.687Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9cYJxQra" : {
            "pathname" : "\/kali",
            "time" : "2024-05-28T22:43:18.778Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB8",
                "imagename" : "cookie.png",
                "promotype" : "2-gradient",
                "primarycolor" : "423F32"
              },
              "status" : "ACTIVE",
              "dateend" : "02-17-2222",
              "discount_version" : "1714176850699",
              "date_created" : "5\/1\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!32024",
              "campaign_name" : "testing bro",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714176851775",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "02-17-2222",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5vYG1",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
              "promotitle" : "Buy one get one free!!!!",
              "redemptionlimit" : "10",
              "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qbgNWKfQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.247Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3drr9kRP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.826Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4Sh16SAj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:08.505Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rfxexqf2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.718Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bQKVPVKG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.901Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hrNZma6V" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:06.567Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8ag5ai6v" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.080Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mtsDr3SJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:39.269Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "43RYt62C" : {
            "pathname" : "\/insights",
            "time" : "2024-04-30T23:18:00.949Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoId" : "kali-kekQV",
              "pricing_rules_version" : "1714511718073",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "972sibN8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.687Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dKj1JwML" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.574Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qTrZdGhM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:29:22.138Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2jQzz81g" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:53:22.660Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9L88imci" : {
            "pathname" : "\/insights",
            "time" : "2024-05-01T00:55:26.168Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-26-2024",
              "discount_version" : "1714085524819",
              "date_created" : "4\/25\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promoId" : "kali-mfN7h",
              "pricing_rules_version" : "1714085525933",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-mfN7h",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "159539FYEMFWKFG2F4F3Z7SACA",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "XJKVFVCD3MAFCNUSBKVA3EJY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "OYDE3TIFS7JZRCUDQTO3KSJK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "x9N8eJAh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.652Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tsBUZNkX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:48.196Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xxoD8uzL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.713Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dEuCBQK8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.704Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xtDS6YqC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.673Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hh3C6Z6B" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.719Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ikDEifzA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.669Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iToB3xC2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.129Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "c48cqkxX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.424Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "huPjZoxE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.848Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-03-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-jLvkU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "LQHDRJJW7KD6PFIJ5RX2OMEC",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "16",
              "discount_id" : "75QPKTRCDXWNPCG2H92XVVJBB",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xvbneEST" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.671Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tTpY2Hzi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.689Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "maPXPJJm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.856Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oZsKuB2k" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:45.990Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9pEaWdqm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.825Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uwrh1wXg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.829Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fVWonT2g" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:32.816Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "n5C18RJA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.722Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pjy7cNiL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.679Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "62tRG83p" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.517Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pkeGppS5" : {
            "pathname" : "\/redemptions",
            "time" : "2024-04-21T00:09:32.624Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/21\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promoId" : "kali-cH9SJ",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-22-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cH9SJ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "11YFSTAFW17DCJA48655GE4GZ6",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "L4ZQHJONOMQH37ROMRWQM6JU",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "INKX62DYGAQ4M3U5PZEBJCTQ",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "r4vbMjoK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:22.264Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fwF7X5Bs" : {
            "pathname" : "\/redemptions",
            "time" : "2024-04-24T23:36:31.190Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "discount_version" : "1713911382071",
              "date_created" : "4\/20\/2024",
              "promomessage" : "Dolor obcaecati nequ",
              "promoshowterms" : "YES",
              "promoId" : "kali-xvay7",
              "pricing_rules_version" : "1713911382886",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-xvay7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2CXV2QYRHS9SPYXQG4QYNQJ55P",
              "promotitle" : "Nulla veniam placea",
              "redemptionlimit" : "8",
              "discount_id" : "BNCIFG2G3HVFRIXPCMUKZ36E",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "J2NH5KN454ESRJG6Z3ZV4FMA",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tAJniwGg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.724Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-03-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-jLvkU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "LQHDRJJW7KD6PFIJ5RX2OMEC",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "16",
              "discount_id" : "75QPKTRCDXWNPCG2H92XVVJBB",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "j8njsVoH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.682Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7MbGwRbz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:31.472Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tFyidbrm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.234Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kuCpssFZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.945Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "37t2a2KC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.697Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bDzdoAJk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.655Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aqurxtcV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.106Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "o4JgfNo8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.695Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xjXxyQpG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.710Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vv9m3c76" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.159Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "q1CQEgoP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.890Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hJTKq7pk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.697Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2YYh9NDR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.709Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6ceYRGxM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.082Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "owYy4fhi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.564Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7tHy2HE6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.712Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3Rzm8y39" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.584Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pj44T7ee" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:31:52.597Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB8",
                "imagename" : "cookie.png",
                "promotype" : "2-gradient",
                "primarycolor" : "423F32"
              },
              "status" : "ACTIVE",
              "dateend" : "02-17-2222",
              "discount_version" : "1714176850699",
              "date_created" : "5\/1\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!32024",
              "campaign_name" : "testing bro",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714176851775",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "02-17-2222",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5vYG1",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
              "promotitle" : "Buy one get one free!!!!",
              "redemptionlimit" : "10",
              "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qbTRriyL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.673Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uQs68AWn" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T00:10:23.299Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "a7ZrBePG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:08.530Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2T1Hm2oG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.730Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "b6hm538K" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.031Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7h62gzPo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.828Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "91pYuBpc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.705Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nNNkFuko" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.894Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rmrvL7Jx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.572Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "u8yNY77w" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.154Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "37p4YdR3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.896Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "g6xBnNk3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.319Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3yzLYHcm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.711Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6nYAxGBR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:39.269Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5vF7kUyV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.752Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3X1Eyk3C" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.713Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fQg4yH2S" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.671Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6pvJMMUB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.896Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xxKUQLMn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.057Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eFgNSMmR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.669Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "twf26hFS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.515Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "onxKVTzk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.711Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oX8vLCB9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.841Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qSKBPtQo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.684Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iVSRTxBb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:29.188Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "54HGcqKe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:31:41.358Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2CD5EYTu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.740Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cyegLsGR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:18:41.810Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mfvr6nkB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:42:01.468Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "woJZj1nT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.720Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "f7iXSrbV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.615Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "q4Zff4fF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.411Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "svUH1ufv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.695Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vJTyhEGh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.872Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "i6VbXQqj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.657Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "obPnGa6R" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.660Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8yU7qwpD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:34:43.568Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iSgJZWxH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.669Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5CJAkicx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:53:22.638Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8Wo9zUwX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:18:41.816Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wAcdttcb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.704Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mazoQEs7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:31:41.355Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3y5Eoibb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:31:41.352Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dReTUVFe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.673Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "okcuTa8M" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.520Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qJSgK",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "B5VNZIHJ5J2JAN3ZGBDZUQFH",
              "promotitle" : "Buy one get one free amount Ya bro !",
              "redemptionlimit" : "10",
              "discount_id" : "63RWVQZNN2NBKFQFK4946QZVJB",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4ycZ5pVX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.335Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nP9Js2Mr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.036Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cgkhZT1S" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.721Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mCJVPXqd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.674Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6MQLRjmi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:41.267Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3G37uPh2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.132Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "DqUNkc1G" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.024Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bKhKoe8y" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.850Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3r42W9Vn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.530Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6m9LSiur" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.576Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dy3Wrgu9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.667Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hff8pDQk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.612Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tKWpMnBE" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:33:48.192Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "x7CkmE8h" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.558Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gjdkSfCD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.732Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ocf7BV9u" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.579Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xkjjarEU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.650Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "48Gwuh41" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.513Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aE9fA9v5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:29.186Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dKMvyfaM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.005Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tw7HA1eK" : {
            "pathname" : "\/redemptions",
            "time" : "2024-04-21T00:09:53.077Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/21\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promoId" : "kali-cH9SJ",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-22-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cH9SJ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "11YFSTAFW17DCJA48655GE4GZ6",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "L4ZQHJONOMQH37ROMRWQM6JU",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "INKX62DYGAQ4M3U5PZEBJCTQ",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cfCmoRji" : {
            "pathname" : "\/kali",
            "time" : "2024-04-25T23:22:10.475Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "discount_version" : "1713911382071",
              "date_created" : "4\/20\/2024",
              "promomessage" : "Dolor obcaecati nequ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "pricing_rules_version" : "1713911382886",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-xvay7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2CXV2QYRHS9SPYXQG4QYNQJ55P",
              "promotitle" : "Nulla veniam placea",
              "redemptionlimit" : "8",
              "discount_id" : "BNCIFG2G3HVFRIXPCMUKZ36E",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "J2NH5KN454ESRJG6Z3ZV4FMA",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fsmnqrVs" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:20.398Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dbLzTkKm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.709Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "paKaKaoo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:34.223Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "u1vYcnxe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.696Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fL3dWuQJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.663Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ptbpkRis" : {
            "pathname" : "\/insights",
            "time" : "2024-04-30T23:18:00.955Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-27-2024",
              "discount_version" : "1714087328770",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "ADD ONE",
              "promoId" : "kali-pctdy",
              "pricing_rules_version" : "1714087329540",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pctdy",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "37RT95TN5VJ0TVW24JDET8ZGR4",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "SMM7HSBILDKN2RNTUUT67ZSZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "PFL5QMIMAJRJA4NJOJTQ7QNK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "g6FiQmSe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.779Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "j43D53fi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.721Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6XcbWMWU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.562Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gMMisMn7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.256Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "q8Cwhb1s" : {
            "pathname" : "\/insights",
            "time" : "2024-05-28T22:41:59.784Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-27-2024",
              "discount_version" : "1714511902704",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "testing la vi",
              "promoId" : "kali-q6UGw",
              "pricing_rules_version" : "1714511903551",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-q6UGw",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "52KC2275Y31N8TXWYBYSSKCPTK",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "7NJWKVFIX42ZY4NZRCDQX4YZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "FZET4BWMP2YWGI5ASOYUMQJZ",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "k2YnJVho" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.487Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uDpBvRqQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.672Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2GJRngZ6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.647Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3bDHkFsS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.565Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5Wp9Rk25" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.691Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fQpAnNg7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.702Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oK9nN87c" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.053Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oSkPmG4G" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.680Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "372Z7g8W" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:29:22.153Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2ifPyPSM" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T01:27:20.295Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7k52tB87" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.524Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-w4joh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DJYL4WS37ULZB72R6AP54BO2",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "2K7RNZ3151V34RX644NMT2FVVX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pXPj8h2b" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.565Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qZJtBL47" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:29.190Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jEAeoSaL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.858Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sLUrGHUo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.690Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5ZGMwPu4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.145Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "crtPtHkQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.947Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7Jr9wgjS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:22.244Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hA7FvPZj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.830Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kxdL2VyK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.709Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4zcdid3j" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.656Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "o2JW2Bba" : {
            "pathname" : "\/kali",
            "time" : "2024-04-25T23:29:03.862Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "discount_version" : "1713911382071",
              "date_created" : "4\/20\/2024",
              "promomessage" : "Dolor obcaecati nequ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "pricing_rules_version" : "1713911382886",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-xvay7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2CXV2QYRHS9SPYXQG4QYNQJ55P",
              "promotitle" : "Nulla veniam placea",
              "redemptionlimit" : "8",
              "discount_id" : "BNCIFG2G3HVFRIXPCMUKZ36E",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "J2NH5KN454ESRJG6Z3ZV4FMA",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "heS8PeZu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.688Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wjbwxWkR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.653Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pdDEv39o" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:07:15.248Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vZ4TdRBQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:39.266Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gUeQwCh9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.743Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "snntCkcg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:48.199Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qgFksYpC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.894Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "f2Ed9Btm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.260Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pXq5vG1B" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.647Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eBjbZjNB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.508Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iYWDKfVL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.498Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7SsHbYH1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.930Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7iMzTvz5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.741Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aLesCcp9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:30.983Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "62XzfTPe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.659Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nG4eX3Km" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.589Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oNyhGm9n" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:06.565Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3UXwZjgA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.702Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vQ81s31s" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.576Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jL2PEGEg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.739Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tohokv5S" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:48.175Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7d4dmFKs" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.924Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3PY78n3F" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.510Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dExCEzWh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.502Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2k2czW4i" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.720Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fjvsnNqq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.162Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7D4Nz",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "MY34726D6PRUOOVSEZBP7KTB",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "M5STBYQ7E6XAGCAD80F6TY6ZR",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gFdsp3UP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.206Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4iSXd7HX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.586Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wamSmmNt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.250Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "p5xWGu1R" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.698Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "m7qGoPGz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.234Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3gCuTfqM" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T00:10:23.283Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mba7i4sk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:16:07.819Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qbNJqQQ6" : {
            "pathname" : "\/insights",
            "time" : "2024-05-28T22:41:59.782Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-27-2024",
              "discount_version" : "1714087328770",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "ADD ONE",
              "promoId" : "kali-pctdy",
              "pricing_rules_version" : "1714087329540",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pctdy",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "37RT95TN5VJ0TVW24JDET8ZGR4",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "SMM7HSBILDKN2RNTUUT67ZSZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "PFL5QMIMAJRJA4NJOJTQ7QNK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ujZ2Y35v" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:34.217Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gvkZ777b" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.669Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ie2eaobM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.705Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "acAp1esn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.332Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oCFZoYoL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:36.385Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tdV8drpd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.892Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4q5DV62Y" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.701Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "a85158jn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:04:14.830Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "37Pfv41v" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:46.002Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uhW1UnrD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.247Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6EfzBxzx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:47:53.091Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cCMzMjNz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.677Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "spfj8cqy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.885Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7AecQ2Kt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:34.216Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kweEsQLX" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:31:26.257Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mozizPaY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.595Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aNaqLC7M" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:16.245Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "92kE3NrE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.600Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gYPxfW1Z" : {
            "pathname" : "\/kali",
            "time" : "2024-04-25T22:52:06.633Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-26-2024",
              "discount_version" : "1714085524819",
              "date_created" : "4\/25\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "pricing_rules_version" : "1714085525933",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-mfN7h",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "159539FYEMFWKFG2F4F3Z7SACA",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "XJKVFVCD3MAFCNUSBKVA3EJY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "OYDE3TIFS7JZRCUDQTO3KSJK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jjccLvcm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:41.272Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3ZRFhuq5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:46.006Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rhP1qp9f" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.567Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iBVG4SGJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:50:39.796Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ivLdvTVM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:20.388Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2UYMmnLa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:00:32.470Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3ephHy6W" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.741Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2VVjDwsK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.702Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hwSn5xgA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.648Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "p8n3xq18" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.226Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bdkNgLyF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:29.179Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rgNWzpiU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.717Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wCZRKR3R" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.603Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wVbzzvy2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.686Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7i4aSkpX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.489Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2rFjpukT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.704Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cpnDuBo1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.578Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aW4p9AmM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.338Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ea1sPRg5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.687Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kduQd7MC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.402Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4h2WfEBm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.042Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sm9KRTq1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:16.246Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jC7dYH7K" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.717Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "J5GA2b2D" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:21.686Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iBerdpkp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.718Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vCEmggdf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:06.195Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eWntvWv9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.660Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sYYvYHFE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:34.224Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nxYthby2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.598Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3256UNia" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.140Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vXWHhXAe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-27T00:15:41.858Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-27-2024",
              "discount_version" : "1714176850699",
              "date_created" : "4\/27\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "testing bro",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714176851775",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wb8h8",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mfAHFNLC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.606Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aXY5HFsA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.738Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "k4wB2cqP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.337Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4qHZTwrb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.517Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8kJywCNM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.722Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wEC36hWf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.652Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7EdWG8gj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.934Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4S4ECokr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:53:22.622Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5gmiCvxn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.712Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "76qL7hN9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.654Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "m7a2KjPk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.886Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5Li3sGHy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.681Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hWDmtNc6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.532Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pPNc5oWX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.713Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m2RnV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "SB3A73LCRHQTTPA2R7OTLS7T",
              "promotitle" : "Buy one get one free testi,ns!",
              "redemptionlimit" : "10",
              "discount_id" : "9V3AS0RBEBA5ZF8GCGRPRZ58V",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tkeakqMp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:40:42.084Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wLqYqjXA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.313Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "goHbcPu3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.700Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "92teL15Z" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.516Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tX4enC9S" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:16:13.235Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xoiF2UbH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.705Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3jW2eeKv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:32:29.511Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pbTQ6Qxk" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:33:48.209Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB8",
                "imagename" : "cookie.png",
                "promotype" : "2-gradient",
                "primarycolor" : "423F32"
              },
              "status" : "ACTIVE",
              "dateend" : "02-17-2222",
              "discount_version" : "1714176850699",
              "date_created" : "5\/1\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!32024",
              "campaign_name" : "testing bro",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714176851775",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "02-17-2222",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5vYG1",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
              "promotitle" : "Buy one get one free!!!!",
              "redemptionlimit" : "10",
              "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6T3CZkLR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.702Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tDkJh6H7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.691Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2xcF4uGA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.720Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "t1xaR2R5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.713Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3NBbxgoi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.163Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nkGGj7tk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.669Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wa2uerLp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.763Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "t8DrVR5o" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.877Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6TtYuQ94" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.606Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m2RnV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "SB3A73LCRHQTTPA2R7OTLS7T",
              "promotitle" : "Buy one get one free testi,ns!",
              "redemptionlimit" : "10",
              "discount_id" : "9V3AS0RBEBA5ZF8GCGRPRZ58V",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "w8CT6MbZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.656Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3gcMs8qf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:31:41.344Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7BMDEExM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:06.574Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kf9DdZqQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.247Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sPhEBBAi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-27T00:14:12.665Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "discount_version" : "1713911382071",
              "date_created" : "4\/20\/2024",
              "promomessage" : "Dolor obcaecati nequ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "pricing_rules_version" : "1713911382886",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-xvay7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2CXV2QYRHS9SPYXQG4QYNQJ55P",
              "promotitle" : "Nulla veniam placea",
              "redemptionlimit" : "8",
              "discount_id" : "BNCIFG2G3HVFRIXPCMUKZ36E",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "J2NH5KN454ESRJG6Z3ZV4FMA",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8sojjfJA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:49.997Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cTwKGygn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.665Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ugnogMok" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.059Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ehVXD3Jx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.925Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "s1ePaHY6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:16.618Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qHECBV7A" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.909Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bsQBT5QU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:34:43.565Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rrbLbi21" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:33:48.188Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB8",
                "imagename" : "cookie.png",
                "promotype" : "2-gradient",
                "primarycolor" : "423F32"
              },
              "status" : "ACTIVE",
              "dateend" : "02-17-2222",
              "discount_version" : "1714176850699",
              "date_created" : "5\/1\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!32024",
              "campaign_name" : "testing bro",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714176851775",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "02-17-2222",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5vYG1",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
              "promotitle" : "Buy one get one free!!!!",
              "redemptionlimit" : "10",
              "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6EgojHw1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.726Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fsz3Di8N" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:06.183Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "amVgKqEA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.942Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vhMobrSW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.725Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8GXYvdqG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.834Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "domk9gB7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.713Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pLwnYVqW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.130Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "263sE8au" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:06.564Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pE9jyvA5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:30:07.956Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "p39A9s8E" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.678Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uk5zdTZV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-27T00:01:45.722Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "discount_version" : "1713911382071",
              "date_created" : "4\/20\/2024",
              "promomessage" : "Dolor obcaecati nequ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "pricing_rules_version" : "1713911382886",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-xvay7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2CXV2QYRHS9SPYXQG4QYNQJ55P",
              "promotitle" : "Nulla veniam placea",
              "redemptionlimit" : "8",
              "discount_id" : "BNCIFG2G3HVFRIXPCMUKZ36E",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "J2NH5KN454ESRJG6Z3ZV4FMA",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5aAQQada" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.035Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3YQTV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5OUAT7Y44KBSRYOB3OUB7NWR",
              "promotitle" : "vvvsdqsds",
              "redemptionlimit" : "10",
              "discount_id" : "7YA7QCWCJ9E5DSG518VE1HQH51",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uf8gaS9x" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:34:22.731Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4WWUfJSM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.064Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aLCSATds" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.717Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wqCEt9Ym" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.675Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "857mbJKf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.669Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "67g5apq7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.885Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oLMTo3s3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.675Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hiY8icvn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:34:22.710Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2oEeHCWB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.228Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iDbsfnG8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.920Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9CgDGWRi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.724Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "u8YXX4LF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.932Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8rktJGzp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-25T22:52:06.634Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-26-2024",
              "discount_version" : "1714085524819",
              "date_created" : "4\/25\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "pricing_rules_version" : "1714085525933",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-mfN7h",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "159539FYEMFWKFG2F4F3Z7SACA",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "XJKVFVCD3MAFCNUSBKVA3EJY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "OYDE3TIFS7JZRCUDQTO3KSJK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "38dNkczB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.392Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2dWLQjq6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-25T23:29:03.847Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6mhsunDL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.879Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mvpVfaAL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:41.268Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8vnrSQAe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.229Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "p39RzkZ6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:00:32.463Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rCszZxes" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.702Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dUKo6DNf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.520Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "s8iKHaGd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:53:22.602Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sMTeRGmi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:55:10.049Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "39NJEZoT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.707Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wot6PRHw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.916Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/20\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-2nmXg",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6UxWTB5h" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.660Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "k6mA1G8K" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.504Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2jTN81tm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:28.959Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fL1YjmjR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.516Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "f3Lf1Myp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:55:10.063Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iVm2mRVP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.475Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3WwmyExq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.683Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cXAMbLCf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.609Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kxMFsSb5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.976Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jighJDVt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.023Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pTRuauLr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.658Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pvgF6ZnP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.527Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wTHKfioY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.703Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "x8qqrHoq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.893Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "723Ckhix" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:34:43.570Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7hjUsmFE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.838Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mkBfz1WZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.717Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8f4esLgb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:34:43.559Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kifrn2LJ" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T01:28:06.891Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cdc6KuS7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:29:22.150Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cYF2Mx5r" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.670Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "e2VmRn1B" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.392Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jFvc3mW5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.709Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tJRTeX9g" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.873Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rVSwVeFJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.124Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hN9tmseJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.143Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sEGjRMmu" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:19:57.144Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3GaAxRKs" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.008Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jTD8DzNj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:40:42.080Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eSHznB6P" : {
            "pathname" : "\/redemptions",
            "time" : "2024-04-21T00:25:34.538Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/21\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promoId" : "kali-cH9SJ",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-22-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cH9SJ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "11YFSTAFW17DCJA48655GE4GZ6",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "L4ZQHJONOMQH37ROMRWQM6JU",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "INKX62DYGAQ4M3U5PZEBJCTQ",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tpZtFnbU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.055Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nD7RvztZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.656Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cARtnmLM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.719Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nnS7jXUP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.598Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ujSPar4F" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:31:41.352Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "S2Xq7Y7x" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:16.632Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dnFeUm4u" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:48.182Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nrg7LqxQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.074Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "n5cBu99W" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.610Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4mrx7K3B" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.727Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jTnTwirx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:42:01.466Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "n7SAhyg8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:36.381Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "63sE8By7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.730Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "n3SasgST" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.706Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mH91J467" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.729Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "w4mtB38Q" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.052Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "21Zc9StN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:31.470Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ijSsmEGA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:42:01.469Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9vL61F8W" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:34:43.547Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rN7VLWxd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.598Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4qwVYux2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.852Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cYdBvX3u" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:16.627Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "heutxZRL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.719Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kgBMaYaV" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:20:37.250Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6Xe5Kf6u" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.837Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wpipFY2D" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.689Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dKqbWzNq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.733Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "x68TK82S" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.518Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "42oDgKEX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.262Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5g5wdmZT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.610Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jJswDrNE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.919Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cjnNka8Y" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:29:22.147Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xqSP2m33" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.712Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9M2FX8Qa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.661Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3EHs9wx3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.657Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "r2M7RpRc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.666Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uCzhVub5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.699Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tYwjqaYg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:08.521Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8Mbutxqk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.244Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wtYMdpwR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.701Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oUvXkNSc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.481Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cZbCh1nx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.735Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jcNdmb9i" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.557Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nwegfMbE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.704Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sAFDLCNE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.498Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "k6YwESZM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.079Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "94w2TiRJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.597Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-03-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-jLvkU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "LQHDRJJW7KD6PFIJ5RX2OMEC",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "16",
              "discount_id" : "75QPKTRCDXWNPCG2H92XVVJBB",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "85M2KSj1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.846Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "751b5MiT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.667Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3E259Pr7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.703Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bHBbSRpn" : {
            "pathname" : "\/insights",
            "time" : "2024-04-30T23:18:00.959Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-27-2024",
              "discount_version" : "1714176850699",
              "date_created" : "4\/27\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "testing bro",
              "promoId" : "kali-wb8h8",
              "pricing_rules_version" : "1714176851775",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wb8h8",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pqnhb64B" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:04:14.822Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cVC9XYno" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:07:15.237Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "npbiKZkB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.684Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2PdjUX6z" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:50:39.804Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2yMeuxyL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.072Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buw",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-waVUX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "C6Y2XBFX4XQRSJRRNOJJORTY",
              "promotitle" : "Buy one get one freewwwffffffff",
              "redemptionlimit" : "10",
              "discount_id" : "M6SGDJNMH19AREZAS4EDCG59R",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5Xwjyi4S" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.227Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "q6ttDVDc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.850Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qdsApEdF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.689Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cWnj1ctu" : {
            "pathname" : "\/redemptions",
            "time" : "2024-04-21T00:09:54.973Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/21\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promoId" : "kali-qUFW3",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-22-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUFW3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4A1CBWMXNY2RZPYWE03XJT2FTW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "GPFWKEX4YSYZNE5SRAHZ7O6T",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "O2SZLUHVK7GSQW552VJBYFWP",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cdbBGaFv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.683Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "x6XB2NhC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.060Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vwUTKUta" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.936Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2ErG8Wmt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.245Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mkfiHiAs" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.890Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eQbGaK68" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.899Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7NywMqbf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.741Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nexVQ7i3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.144Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "styk5UCQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.604Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "m2UE6ZB7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.834Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vm5zqh6o" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.670Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "r5LF6c9N" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.675Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nycywHhA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.713Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "x3HGqGz1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.608Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "w22eHAHx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.579Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6DksuWPJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.958Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6aLUGNd5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:34:22.717Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8ExoQCbi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:20.389Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eda13Pov" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.019Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "968D6edV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.675Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "29Rn2Pnd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.653Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "e7TZiGrB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.695Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kFjcFtyn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.764Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5oSYrwHt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:08.529Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "m3te1BVz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.902Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "idpFYSBu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.851Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "o2Vu8Wkv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.723Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2cULHuKP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:31:41.353Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fzp3adUy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.884Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aNm24qyx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:06.579Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mhEayES6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.746Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oSPv3Ln8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:29:22.137Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pTp44aGW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.244Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wrc5cpTi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.970Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jY3Rnt9z" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.677Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iZ767ZeJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.717Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kawsPAYA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.727Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "87VMTt5g" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.715Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "98bjEVpn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.870Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7CudjhEr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:04.166Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2LHomtZs" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:16.236Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rFVz5acr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.995Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vVTJjz7k" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:20.394Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "m3AZQPFH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.892Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "weV4czGB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.853Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-24-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!SSS",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-gHrS5",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "RIET7M5255NTOBCC6XMRV6Y3",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5W0NA67DBB5XW21HWJGZ6140V8",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rLUM5xD9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-27T00:15:41.866Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gvZsqsks" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:34.219Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2nEnAM3u" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.651Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "c7swyeyN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.250Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "t98eLPWZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.861Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ujL8yyWL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.692Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kL8iEXU4" : {
            "pathname" : "\/insights",
            "time" : "2024-04-27T02:20:52.242Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoId" : "kali-hxzr3",
              "pricing_rules_version" : "1714087742664",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7e2d7seh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.677Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "Zjj76SdD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.698Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jyeLLWgS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.522Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uWndLJY6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:30.976Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "og9VK9Pr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.665Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wSzCPXrA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.723Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9h7rBRo9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.510Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "quWiqkJN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.730Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mb13PGWa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.698Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "f4EVjW5P" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.215Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3ypjrTHM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.023Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "u3auwRga" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.650Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tzGpUVtx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.675Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "shgHzUu2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:47:53.095Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "remAqXcC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:00:32.466Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "piWjGZZo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.702Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9oP6D97G" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:39.261Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hXgfzS2R" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.732Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5jQXbizW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.711Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "s3RG1NNF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.743Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cdH7h4hP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.066Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nBFpZYSr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.703Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2seaampk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.671Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "YMkyzgs7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.653Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "s6qofjdy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.703Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bSma9rcJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:07:15.238Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uD6o16nB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.703Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iiSfQLfH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.668Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "QcRh2kCp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:08.523Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "andw6RDX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.740Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gNUMEQna" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:29.177Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9k5JnX2K" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.004Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8rTYQLGS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.239Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vqR8WVRY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.850Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "czsqrAvw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.725Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sLZt2Qje" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.732Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nyqBxQ6b" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.664Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ckt3QTH3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.852Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rrUAAt86" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:58.003Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "v4Cze3DF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.728Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jeBP47gz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.470Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9oAChh8w" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.711Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3Pg8AdkS" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:25:16.604Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8iNsD69f" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:53:22.606Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3P1iQJyR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.755Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ueByzJZK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:32.815Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2ErHKjHZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.072Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-w4joh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DJYL4WS37ULZB72R6AP54BO2",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "2K7RNZ3151V34RX644NMT2FVVX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mrrNwnh3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.849Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "e9XofdkG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.915Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9rKc47Uw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.504Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3TKmEqjt" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:19:52.013Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mSLqNV3q" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:32.833Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sC3ubRW9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.217Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eNfUE8Ts" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:40:42.082Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kNvJXwPH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.235Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ka4tXxhX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:36.400Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bGRhk15Q" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:08.510Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iSTH3WJ3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:16:13.240Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pNjtk8dN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:08.525Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fMNywGh9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.971Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pEmrPz1F" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.053Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xtQtdNBB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.696Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uFZbxY7C" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.547Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5dA6XqyS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.996Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hfahFPAB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:06.568Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aTC3fhzt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:49.991Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jJLaXcAW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.674Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "c77N9q7Z" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.612Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hEBEdzb4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.824Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9Q2czGpw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.587Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cgfFt2hR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.870Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iimmmsct" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.257Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oLScRk1N" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.227Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kcNzzADy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:16:07.819Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uJLzX6rJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.200Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rYA8iqRX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.550Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "piFkpE1c" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.823Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aSpqPPkd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.652Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ggtzsyQh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.688Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bw7ULZkx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.674Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kKoQrubM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.994Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eRJUWR3K" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.661Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dbhHw733" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.739Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9ZLbuxYJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.482Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gfUW3i5x" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:16.626Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "k5scCjJm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.715Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6ZuzDWMN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.679Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jZcd5FEz" : {
            "pathname" : "\/insights",
            "time" : "2024-05-01T00:55:22.313Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-27-2024",
              "discount_version" : "1714511902704",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "testing la vi",
              "promoId" : "kali-q6UGw",
              "pricing_rules_version" : "1714511903551",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-q6UGw",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "52KC2275Y31N8TXWYBYSSKCPTK",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "7NJWKVFIX42ZY4NZRCDQX4YZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "FZET4BWMP2YWGI5ASOYUMQJZ",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fTGSH87r" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:16:07.809Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kXYxjLCu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.927Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xh253swR" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:33:48.197Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "k6A8hXdi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.710Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2h4cQQdi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.891Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xqXDjaiW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.945Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7s5v39H9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.648Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oNHFVHN5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.743Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "zEsyTiR9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.849Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7u2Hgt3y" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:32:29.515Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5VKGunWU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.709Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "v3L5i3wr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.223Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wcQy7gc1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.742Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6VCHV3sH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.717Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pnZZjfKb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.727Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vuH5ZKVb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.133Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eDrGYPfT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:58.009Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9ELMCoyD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.899Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hGEppkYc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.603Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vfkucz6v" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.268Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6g8p2W4W" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:39.260Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7LasMvxF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.696Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dVHEP84M" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.665Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bArM7MrV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:40:42.079Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fHscMH2v" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:40:42.075Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5j94usVy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.720Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "h2gYeL4p" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:07:15.240Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3LNDqE5e" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.320Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "avAa2jHc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.558Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dLywZYCR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.653Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ruEkGfAC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.144Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mkB8x3tw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:55:10.062Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ryNdG6S1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.590Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vxtdcnat" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.886Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wjyrY855" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:07:15.233Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fPYGjAPt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.659Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bKPJJ7Pr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.039Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jobX8czc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.705Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hUm1UW2J" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.673Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7yPa1hsq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:32:29.509Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8ey94JHD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.651Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fdsMhBdh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:42:01.473Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vhjdvTp7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.995Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "96RA8QZc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.229Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2JzK5npi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.512Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hV1ufcMt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.062Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "niYHF3ad" : {
            "pathname" : "\/redemptions",
            "time" : "2024-04-21T00:11:14.938Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "date_created" : "4\/20\/2024",
              "promomessage" : "Dolor obcaecati nequ",
              "promoshowterms" : "YES",
              "promoId" : "kali-xvcay77",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-xvcay77",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2TP8ED36V8N400ACDMQRGDXVW7",
              "promotitle" : "Nulla veniam placea",
              "redemptionlimit" : "8",
              "discount_id" : "XA65HS743S7Y5LDZ76T5ARU5",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "R4PSCDEGMEVMHYIC6L63ZEJD",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tWH44KgU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.506Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8KPb2KgB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.840Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "q45oAGfZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.343Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kLMRcAvS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.813Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9C9Yh7hG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.728Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hxi815Qg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.929Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3YQTV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5OUAT7Y44KBSRYOB3OUB7NWR",
              "promotitle" : "vvvsdqsds",
              "redemptionlimit" : "10",
              "discount_id" : "7YA7QCWCJ9E5DSG518VE1HQH51",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "or9XzQ95" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:16:07.817Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "snxjXmNN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.608Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "shQh6YKN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.165Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3m3dpna7" : {
            "pathname" : "\/insights",
            "time" : "2024-06-25T23:42:43.971Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-26-2024",
              "discount_version" : "1714085524819",
              "date_created" : "4\/25\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promoId" : "kali-mfN7h",
              "pricing_rules_version" : "1714085525933",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-mfN7h",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "159539FYEMFWKFG2F4F3Z7SACA",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "XJKVFVCD3MAFCNUSBKVA3EJY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "OYDE3TIFS7JZRCUDQTO3KSJK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/125.0.0.0 Safari\/537.36"
          },
          "sdHS8RnT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.077Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sJ4YbDdQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.621Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "d2jLX7UU" : {
            "pathname" : "\/redemptions",
            "time" : "2024-04-21T00:25:47.810Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/21\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promoId" : "kali-cH9SJ",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-22-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cH9SJ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "11YFSTAFW17DCJA48655GE4GZ6",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "L4ZQHJONOMQH37ROMRWQM6JU",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "INKX62DYGAQ4M3U5PZEBJCTQ",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4HX183EJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.149Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "PELuZV2W" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.255Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8B5sXuKB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.650Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "h1mspS8E" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.650Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vdmcTHVK" : {
            "pathname" : "\/insights",
            "time" : "2024-05-28T22:41:59.771Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoId" : "kali-hxzr3",
              "pricing_rules_version" : "1714087742664",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "trKjzeL4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.702Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "x9AYu3f4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:42:01.464Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qaxBWPfm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.922Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ne8x8n6J" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.128Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kzPVHbAT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.732Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hL6RmRGN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.889Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hmefpLwT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:55:10.064Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6ekKKFC1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.487Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gJyCBVEK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.725Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fc1BgrMj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.695Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hNqVe48E" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.678Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "X9W1jAak" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.573Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6TD9naKR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.895Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qbp4nQGQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.913Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kzgTkjmk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.699Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bhFix9CK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.714Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "guxWFy1Y" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.660Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "p6LxBHsG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.662Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sfoTizDc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.693Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fCt2cCC5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.714Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "EDqd3xVo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.331Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qoFNNWLQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:55:10.052Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gYRxW1qe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:34.224Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xt4D7eDp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.663Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cC8B4u9j" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.917Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5Cquxsue" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.693Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dHuU45AR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.239Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7xmpBbsn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.585Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qcL71Lgg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.565Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dR6yPaEc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.313Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cGLCWNaH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.501Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fK3rvzQK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.753Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mgaKNi23" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.689Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jTJFVMK9" : {
            "pathname" : "\/redemptions",
            "time" : "2024-04-21T00:10:14.291Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/21\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promoId" : "kali-cH9SJ",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-22-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cH9SJ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "11YFSTAFW17DCJA48655GE4GZ6",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "L4ZQHJONOMQH37ROMRWQM6JU",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "INKX62DYGAQ4M3U5PZEBJCTQ",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nUA76kyC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.726Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kQZHY8ST" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.683Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rX96zb7Z" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.585Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fJcNgRB9" : {
            "pathname" : "\/insights",
            "time" : "2024-04-30T23:18:00.951Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-26-2024",
              "discount_version" : "1714085524819",
              "date_created" : "4\/25\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promoId" : "kali-mfN7h",
              "pricing_rules_version" : "1714085525933",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-mfN7h",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "159539FYEMFWKFG2F4F3Z7SACA",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "XJKVFVCD3MAFCNUSBKVA3EJY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "OYDE3TIFS7JZRCUDQTO3KSJK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "s599Vbw2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:46.006Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jqwL3iEg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:06.181Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ghJ9AGQw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.911Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pGpoNvtH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.852Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fm2ajQBv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.786Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "YPFDKNzJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.693Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qdxxodyX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.712Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "QDU8WPFL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.706Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oXb4ZDXS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:22.295Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rkEiS1h9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.698Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "a5Zvqzmj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-25T22:52:21.294Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-26-2024",
              "discount_version" : "1714085524819",
              "date_created" : "4\/25\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "pricing_rules_version" : "1714085525933",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-mfN7h",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "159539FYEMFWKFG2F4F3Z7SACA",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "XJKVFVCD3MAFCNUSBKVA3EJY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "OYDE3TIFS7JZRCUDQTO3KSJK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7VjbM4xT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:29:22.156Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2d1EExZS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.711Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xyU23iQS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.724Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8aemJeFd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.172Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fLYjhJWQ" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:23:15.256Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6LS9ViME" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.744Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jEbF8tgd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.834Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "khrLNX4u" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.899Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fYc4gZA4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.649Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vhmRaD36" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.472Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jfY9AQKY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.676Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xv9BJLA6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-27T00:15:41.887Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-27-2024",
              "discount_version" : "1714087328770",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "ADD ONE",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087329540",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pctdy",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "37RT95TN5VJ0TVW24JDET8ZGR4",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "SMM7HSBILDKN2RNTUUT67ZSZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "PFL5QMIMAJRJA4NJOJTQ7QNK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "q3nsNUTW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:01:18.870Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "CuAJAN18" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.656Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tEZJ5DCD" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T01:27:20.282Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB8",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "02-17-2222",
              "discount_version" : "1714176850699",
              "date_created" : "5\/1\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "testing bro",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714176851775",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "02-17-2222",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-vrKoG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "opXK7kiw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:34.218Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dMG12XcY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.564Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pUWXT3cN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.654Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9kKcwctZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.695Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "w3tEszKU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.923Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dRgMbtao" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:30:07.954Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "n6wyrhGT" : {
            "pathname" : "\/kali",
            "time" : "2024-05-28T22:42:56.903Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB8",
                "imagename" : "cookie.png",
                "promotype" : "2-gradient",
                "primarycolor" : "423F32"
              },
              "status" : "ACTIVE",
              "dateend" : "02-17-2222",
              "discount_version" : "1714176850699",
              "date_created" : "5\/1\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!32024",
              "campaign_name" : "testing bro",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714176851775",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "02-17-2222",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5vYG1",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
              "promotitle" : "Buy one get one free!!!!",
              "redemptionlimit" : "10",
              "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tNaBZqio" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.974Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "e1q61bw2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.747Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pFEXdQfX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:16:13.239Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "68ar9Gty" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.684Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eKYFurGG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:55:10.059Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kivHevDc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.658Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ozukqwGp" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T01:31:34.485Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB8",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "02-17-2222",
              "discount_version" : "1714176850699",
              "date_created" : "5\/1\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "testing bro",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714176851775",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "02-17-2222",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-uqMB9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wJYWd1YX" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:21:12.823Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wwjEHfK1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.697Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sU6xU5uT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.133Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dp2g5W94" : {
            "pathname" : "\/insights",
            "time" : "2024-04-30T23:18:00.943Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoId" : "kali-hxzr3",
              "pricing_rules_version" : "1714087742664",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qDjSwSQv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.160Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3hLB7sA1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:42:01.464Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ifSoebYH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.765Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wfx9Xeox" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.198Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jZqN4uat" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:16.231Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "94kiHzuN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:29:22.138Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3WF5yjk1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:31:41.343Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "96iSBt8V" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:19:52.025Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oBUnRtFG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.243Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tskEEn8F" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.737Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pFosnn9M" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.225Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "68YjHKiR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:34:43.575Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3ADdiuLE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.492Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6kV9x2FS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.228Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nmiZKvoZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.170Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "h7fuMFrE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.495Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "msx63GbN" : {
            "pathname" : "\/redemptions",
            "time" : "2024-04-21T00:25:47.815Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/21\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promoId" : "kali-qUFW3",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-22-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUFW3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4A1CBWMXNY2RZPYWE03XJT2FTW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "GPFWKEX4YSYZNE5SRAHZ7O6T",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "O2SZLUHVK7GSQW552VJBYFWP",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kQR2TyVL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.937Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pmxu9ZAx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.166Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uA4Fb2Rq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.661Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4Rf38EhV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.412Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8SbZbLec" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.058Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "88rZDNtj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.131Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kexmYZpP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.695Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4jL8o89w" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.725Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "h9xraLwi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.574Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bsjyYdbx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.936Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oQNhJnzC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.913Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6W3bMvty" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T00:11:29.951Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "386zWiMw" : {
            "pathname" : "\/insights",
            "time" : "2024-05-28T22:41:59.780Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-26-2024",
              "discount_version" : "1714085524819",
              "date_created" : "4\/25\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promoId" : "kali-mfN7h",
              "pricing_rules_version" : "1714085525933",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-mfN7h",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "159539FYEMFWKFG2F4F3Z7SACA",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "XJKVFVCD3MAFCNUSBKVA3EJY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "OYDE3TIFS7JZRCUDQTO3KSJK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4Zm2JCyb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:36.392Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fdwDS3yc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.925Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-24-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!SSS",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-gHrS5",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "RIET7M5255NTOBCC6XMRV6Y3",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5W0NA67DBB5XW21HWJGZ6140V8",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6ez3RE9o" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.326Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "o7J4EcNn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.308Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3MjGeLkH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.131Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7HUjcjfG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:31:41.342Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5zYuRLMv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:16.631Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7PA3HEst" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.139Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dyctNz8z" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.705Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iAs92C2H" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:07:15.234Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ad3RwPbZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.661Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nzcwJj1Y" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.880Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8Si9WtUj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:31:41.337Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wdnEwd8Q" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.781Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mNaQQEL4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:29:22.139Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "434ZvKhb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.931Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mF4dELtj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:20.397Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sa5CXMpi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:34:43.571Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6iTghiST" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.701Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4q45E3VV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.659Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mcwhocPB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.230Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xweaJjmX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:40:42.086Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6bBhgcj4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.672Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tciMrNaB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.651Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hitgQnUU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.718Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iLz7cy2k" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.850Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6FD2L1cj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-21T00:07:43.557Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "date_created" : "4\/20\/2024",
              "promomessage" : "Dolor obcaecati nequ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-xvcay77",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2TP8ED36V8N400ACDMQRGDXVW7",
              "promotitle" : "Nulla veniam placea",
              "redemptionlimit" : "8",
              "discount_id" : "XA65HS743S7Y5LDZ76T5ARU5",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "R4PSCDEGMEVMHYIC6L63ZEJD",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kXDn19K4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.391Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vqadCy6q" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.215Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "37ayCUpw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.068Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eQY4g5yH" : {
            "pathname" : "\/insights",
            "time" : "2024-04-27T02:20:52.251Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-27-2024",
              "discount_version" : "1714087328770",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "ADD ONE",
              "promoId" : "kali-pctdy",
              "pricing_rules_version" : "1714087329540",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pctdy",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "37RT95TN5VJ0TVW24JDET8ZGR4",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "SMM7HSBILDKN2RNTUUT67ZSZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "PFL5QMIMAJRJA4NJOJTQ7QNK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qUiMTese" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:46.003Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5yxTyX6x" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:07:15.247Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "i7bJWy7k" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:28.957Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4nbCef18" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.604Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mX5BxBkP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.727Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9PsJNYng" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.069Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wQLHskc8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.173Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vijN4FTV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:46.005Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qJPUiadR" : {
            "pathname" : "\/insights",
            "time" : "2024-05-01T00:55:26.166Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoId" : "kali-kekQV",
              "pricing_rules_version" : "1714511718073",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sGA5pvem" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.149Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jg4qpyau" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.668Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tiDbjEb6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.891Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3767kdfM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.904Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "axefgufA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.987Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eKjaCcej" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.738Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "i5xStB6x" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.160Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "df3n9hmR" : {
            "pathname" : "\/insights",
            "time" : "2024-05-01T00:55:22.319Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB8",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "02-17-2222",
              "discount_version" : "1714176850699",
              "date_created" : "5\/1\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "testing bro",
              "promoId" : "kali-wb8h8",
              "pricing_rules_version" : "1714176851775",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "02-17-2222",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-iu9xG",
              "promoterms" : "Offerd valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nDLJ1Xoi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.137Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vEffS931" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.666Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vC8134zj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.930Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "DQQAumZZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.680Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "63DjUxUv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.242Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7iVY1gvt" : {
            "pathname" : "\/redemptions",
            "time" : "2024-04-21T00:09:32.628Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/21\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promoId" : "kali-qUFW3",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-22-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUFW3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4A1CBWMXNY2RZPYWE03XJT2FTW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "GPFWKEX4YSYZNE5SRAHZ7O6T",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "O2SZLUHVK7GSQW552VJBYFWP",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "scUs1sAs" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.686Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cFMxu73a" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.879Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "k8k3LYAt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.694Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mkqGRHBL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:32:29.517Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4d9JHREs" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.871Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7qrCMqSc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:08.522Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fkrFMVmu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.163Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-03-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-jLvkU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "LQHDRJJW7KD6PFIJ5RX2OMEC",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "16",
              "discount_id" : "75QPKTRCDXWNPCG2H92XVVJBB",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "92nJJHKz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.677Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hr6MSWut" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.553Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iWLcqC6T" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.934Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "o6BbRxiT" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T01:28:06.890Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oi3QHgvf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.844Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m2RnV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "SB3A73LCRHQTTPA2R7OTLS7T",
              "promotitle" : "Buy one get one free testi,ns!",
              "redemptionlimit" : "10",
              "discount_id" : "9V3AS0RBEBA5ZF8GCGRPRZ58V",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hjPoNRG8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.681Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bctjHg4H" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.665Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oQ38vrV1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.241Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8turfKmC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.670Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8zyHSgip" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.070Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dWFPLxPx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.127Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "u95kui16" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.836Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5he8DHjz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.530Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3NiahUBG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:28.968Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "Bft2UW1G" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.423Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nH3hgHic" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.892Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "otHNywnu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.657Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9eGyetME" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.688Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qvMLovyc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.596Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3feB2ug5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.708Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3id9VZdK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:53:22.652Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4nozSym2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.652Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jVBoyDMd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:48.194Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xvxRRGds" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.992Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dnMonHyp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.850Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3sgLofDu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.922Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oNfcBMAp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.667Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nYpj5qzM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.725Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gJvvhC5w" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.660Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wRitetBB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.663Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nxCtC4C1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.594Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jRYkubMi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.745Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jfPeGyaL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.230Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eaDeR9cn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.605Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fn1tXjSv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.711Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nbRhAWos" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.864Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7E3KM9pU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.599Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "e9JBLLj9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.668Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tXDLKnGg" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:24:02.032Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aHwvcbwP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.424Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cFerxoKc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.701Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dM8gTMTH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-27T00:15:41.849Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-27-2024",
              "discount_version" : "1714176850699",
              "date_created" : "4\/27\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "testing bro",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714176851775",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wb8h8",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rg1L1tMa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.501Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wp27yGwo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.656Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iR73kG9w" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.815Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "syz3HshZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.504Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uTLdUVVM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.915Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jgKAZ1mF" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:20:37.245Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "e3dkkoVU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.565Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xun4gWAG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:16:07.814Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uH4q9hh7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.874Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oMJZ31UX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.721Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m2RnV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "SB3A73LCRHQTTPA2R7OTLS7T",
              "promotitle" : "Buy one get one free testi,ns!",
              "redemptionlimit" : "10",
              "discount_id" : "9V3AS0RBEBA5ZF8GCGRPRZ58V",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "omzgtwdm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:04:14.828Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5rfKUahW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:49.986Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "98cawJLv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.940Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9JMuMPCc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.655Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qf37EqQP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:34:22.722Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rqD7yBAa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.680Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "byzTSyfS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.676Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eHEsH7kC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.988Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fPtYGwt9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.835Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gdynXJ9n" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:50:39.803Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8NbhjvWC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:16:13.237Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jkRZQHbJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:06.579Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8e5r8wYU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:04.161Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "irSxoYDk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.675Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qDSZvQGS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.732Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vhDfbaRm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.746Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gJkzdC2R" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:32.827Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rhPAWjJa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.671Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tV2einVP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.520Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7KuzBiGt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.725Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qTvg7U8a" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.678Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8zX9LKNx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.572Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kcH4qvzf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.321Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nxGSSkuH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.745Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ed1GafkM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.672Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "h1hM6CFD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:32.818Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uURgycfZ" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:25:25.752Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mhU4xeff" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T23:27:58.944Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "date_created" : "4\/20\/2024",
              "promomessage" : "Dolor obcaecati nequ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-xvcay77",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2TP8ED36V8N400ACDMQRGDXVW7",
              "promotitle" : "Nulla veniam placea",
              "redemptionlimit" : "8",
              "discount_id" : "XA65HS743S7Y5LDZ76T5ARU5",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "R4PSCDEGMEVMHYIC6L63ZEJD",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gzTCHRFc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.736Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "JHnXo6TW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.648Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kdja9Au9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:31:41.347Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "omdUe2wY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.148Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nof2zuab" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.516Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gJQZDq35" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.695Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uY39jxCq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.230Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ukbkSykf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.760Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qaa2TMez" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.603Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8JQxPZ1i" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.700Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "frUuxJkx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.152Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gPdyRX9c" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.114Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6Rx5jZo2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.529Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tE3xVt1p" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.675Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "r1Ncu4wi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.698Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vDy74ZuX" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:21:12.806Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "o3HJsfHz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:21.687Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "avLeN3mY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.212Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rxKPrNHf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.484Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "23Y5hgw4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.676Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "g23Te6Qa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.139Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9QLunmKb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.609Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mtRWLvmt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.838Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4an71tsf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.679Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gRoA8wY1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.738Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "h2bLHhQ1" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:31:52.596Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB8",
                "imagename" : "cookie.png",
                "promotype" : "2-gradient",
                "primarycolor" : "423F32"
              },
              "status" : "ACTIVE",
              "dateend" : "02-17-2222",
              "discount_version" : "1714176850699",
              "date_created" : "5\/1\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!32024",
              "campaign_name" : "testing bro",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714176851775",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "02-17-2222",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5vYG1",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
              "promotitle" : "Buy one get one free!!!!",
              "redemptionlimit" : "10",
              "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9EyY9FFV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.687Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aqF4m5CZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:32.817Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iQXFkKTK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-25T22:52:21.295Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-26-2024",
              "discount_version" : "1714085524819",
              "date_created" : "4\/25\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "pricing_rules_version" : "1714085525933",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-mfN7h",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "159539FYEMFWKFG2F4F3Z7SACA",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "XJKVFVCD3MAFCNUSBKVA3EJY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "OYDE3TIFS7JZRCUDQTO3KSJK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "r6itDZyi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.755Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "t7Xgqbhs" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.670Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8kjJL66y" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.833Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nNw56aiT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.705Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eczH8Jjn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:07:15.242Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rQQWweCs" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.511Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7D4Nz",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "MY34726D6PRUOOVSEZBP7KTB",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "M5STBYQ7E6XAGCAD80F6TY6ZR",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "apany7aQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:48.177Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "t749hHLB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.606Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7D4Nz",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "MY34726D6PRUOOVSEZBP7KTB",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "M5STBYQ7E6XAGCAD80F6TY6ZR",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sQa2cMeQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:36.384Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hswrGRdr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.715Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qK7y7686" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.531Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "neZjWAw4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.720Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "yo7wuYUF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:27.863Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wKumkjga" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:47:53.104Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iQPqz8q8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.609Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "biGLyumu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.660Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "a1BY7zmy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.842Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7ez4mHcY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.997Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3kJq1D16" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.148Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "v74sDGvt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.344Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vbaKJp1a" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.909Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tMegCvzn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.706Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "f53ZrK83" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.478Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ggxJ26kU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.725Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "i2P9ueV9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.710Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gBjgLZhs" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.699Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cjUh1UJa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.671Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jLiZHrAd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.723Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6t1x7XvV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.276Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7B7yt8fT" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T01:25:49.903Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nc2i1c2e" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:28.968Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "q1MpGbSd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.718Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bdR4qbHC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.701Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "r6GTgQVQ" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:21:56.479Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kXHS1SpH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.731Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6q3FqJZ5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.718Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8nVNWNhG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.847Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "noWhSAm6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.749Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "k4CNomfE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.513Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buw",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-waVUX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "C6Y2XBFX4XQRSJRRNOJJORTY",
              "promotitle" : "Buy one get one freewwwffffffff",
              "redemptionlimit" : "10",
              "discount_id" : "M6SGDJNMH19AREZAS4EDCG59R",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4j1Vj3xC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.740Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wLJvKqeL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.922Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oiqzdhxj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.032Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "54zR1TUn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:50:39.805Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "s46sqJT6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.735Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3Pkv4KH8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.834Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3Z9iZbmF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:34:22.728Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qjF4iDkJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.495Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aZGs5usr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.837Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uYkgSTRx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.134Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "862MW9Uk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.569Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cUv5RfkX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.927Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-03-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-jLvkU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "LQHDRJJW7KD6PFIJ5RX2OMEC",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "16",
              "discount_id" : "75QPKTRCDXWNPCG2H92XVVJBB",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4uELnBAx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:49.982Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ocEXMzhF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.535Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bqh1NpMH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.700Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hCjNThZX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.707Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oAreEhek" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.654Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9dqCxKCq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.731Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eRRd4GY2" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:25:16.602Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qKoWmqaP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.728Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bNQRN3KQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.646Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3fPYYAT5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.161Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "j23bD5pm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:47:53.095Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6EgwdAkv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.717Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ddHdCEUf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:08.509Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9BF2yVsZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:16.233Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "m1NfrPvM" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:33:48.210Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB8",
                "imagename" : "cookie.png",
                "promotype" : "2-gradient",
                "primarycolor" : "423F32"
              },
              "status" : "ACTIVE",
              "dateend" : "02-17-2222",
              "discount_version" : "1714176850699",
              "date_created" : "5\/1\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!32024",
              "campaign_name" : "testing bro",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714176851775",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "02-17-2222",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5vYG1",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
              "promotitle" : "Buy one get one free!!!!",
              "redemptionlimit" : "10",
              "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6xFuUoxz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.689Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gpMUcbnm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.059Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uqnUqDHY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.157Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jDrKqrBA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.820Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-oiZHu",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "547WFMTLS5PXLOORF3XZSOFK",
              "promotitle" : "Buy one get one free testing Amount!",
              "redemptionlimit" : "10",
              "discount_id" : "2G2MN6MET28GGM0DPKQHW3EXDP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wjQ2rCij" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.402Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4QAWCQGA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.750Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qe4mGmkw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.990Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ugJ8jGek" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.755Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6MJ9ty3a" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.702Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eTHLopvz" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:25:16.612Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sysuqcde" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:49.995Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uP2KyTAi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.619Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "t2WjveUe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.650Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hEwLbpxQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:16.242Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jcEkgzQv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-25T23:22:10.471Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-26-2024",
              "discount_version" : "1714085524819",
              "date_created" : "4\/25\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "pricing_rules_version" : "1714085525933",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-mfN7h",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "159539FYEMFWKFG2F4F3Z7SACA",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "XJKVFVCD3MAFCNUSBKVA3EJY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "OYDE3TIFS7JZRCUDQTO3KSJK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3iKguksb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.599Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "obamRg7E" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.601Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tSmSaHfk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:48.192Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vGTn2Tbx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.758Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-oiZHu",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "547WFMTLS5PXLOORF3XZSOFK",
              "promotitle" : "Buy one get one free testing Amount!",
              "redemptionlimit" : "10",
              "discount_id" : "2G2MN6MET28GGM0DPKQHW3EXDP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vZStg3P6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.709Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qaDDgYtZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.691Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vxESWi27" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.911Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bKofw3Cu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.241Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qbrG8fsM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:32:29.519Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "w4hA4daZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:55:10.056Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cT2bRWuV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.573Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kMcQDZTr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.577Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "prYa2knm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.507Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sjdHJpEa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.828Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "haEB6Ypk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.518Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bVAD7uuJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:42:01.462Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "b4umLSQ4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.716Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "f9jssooy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.712Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eWiseaDk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.925Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sC7rYjm9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.720Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "skw9AzAW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.662Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tPKmfvrM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.335Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "41KkRzS1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.823Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hpKMavHx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.679Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vEneweTb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:01:18.889Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tzXVCowH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.731Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7C4rGUdM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.244Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qKiu5ft2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:04:14.823Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "q6QZTRpu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.407Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8XLtRXYa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.912Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6tCAHQwV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.721Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wbVS7Sg8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.709Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "miE8HAEZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.709Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hHSsDN79" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:42:01.471Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "abi4yG8x" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.506Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "arT9NQsB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.706Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oLBXipMF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.911Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "p4V7MEj4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.674Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qumMxrsq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.579Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sVNRqdgC" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:33:48.214Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7DPeUyeX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.223Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sWSXXEcJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.209Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3kK62Q6S" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:16.246Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3MKAcfWa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.921Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vDrL9ve9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.591Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "829R5stb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.694Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ws2P8DSp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.841Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xq5p4ha1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.711Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8espp1Zd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.921Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8ot9rUoj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.835Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jqjJGTsb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:29:22.150Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kKH8E5mH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:46.004Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tZJPnkSo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.863Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2aunyKZv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.694Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6eD1fMj7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.516Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8zKeRwmK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:36.387Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8YTzytXe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.898Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iky5342b" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.702Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4bZUkYJY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.469Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jZ4heazR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.853Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "p4JtjN8Z" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.070Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pF7NW7jV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.248Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tBSfa2xm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.890Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rFbzjaJK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.847Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-24-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!SSS",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-gHrS5",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "RIET7M5255NTOBCC6XMRV6Y3",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5W0NA67DBB5XW21HWJGZ6140V8",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bPVd7nCd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:28.959Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2xmYym1u" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:19:57.153Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eUu1L9aK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.423Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3VfCAkfh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.046Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8WNJuNwu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:32.829Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "942SLYp7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.032Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "s7EFWVTV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.233Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uqAS3F64" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.923Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tG5pGmY9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.740Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fLRSwGJ3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.575Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bX4cdNZq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.672Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jkwhB4iD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.769Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "m5xoanWi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.253Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mKQPd8kX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.571Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6tW6Th6M" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.922Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "edSxGxQz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.719Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cuYwN9tA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.713Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "x4aLTuUW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.655Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4zoB3wsf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.166Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "b6WqPiff" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:27.859Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hWnKyXRA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.678Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8VPAk72N" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:31.477Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bAFQYemu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:47:53.097Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fj1npXw5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.730Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8y9b86T5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.197Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oAioLhCJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.722Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rTb92Snm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.251Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jJcGHcby" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.651Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "t4P7mDb3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.739Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "thojJ6oB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:48.181Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vLuMmvEr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:41.261Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8VhfW6jJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.719Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iq1FphJL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.078Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "m4MUMBba" : {
            "pathname" : "\/redemptions",
            "time" : "2024-04-21T00:25:24.903Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/21\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promoId" : "kali-cH9SJ",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-22-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cH9SJ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "11YFSTAFW17DCJA48655GE4GZ6",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "L4ZQHJONOMQH37ROMRWQM6JU",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "INKX62DYGAQ4M3U5PZEBJCTQ",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kvS1KB8N" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:29:22.149Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jcYftTUS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.911Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "p7Pxn29J" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:40:42.079Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "c2Umz92j" : {
            "pathname" : "\/insights",
            "time" : "2024-05-01T00:55:26.173Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB8",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "02-17-2222",
              "discount_version" : "1714176850699",
              "date_created" : "5\/1\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "testing bro",
              "promoId" : "kali-wb8h8",
              "pricing_rules_version" : "1714176851775",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "02-17-2222",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-iu9xG",
              "promoterms" : "Offerd valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "onPzED7c" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.259Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pGzGgC4T" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:16.630Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ukkgCk78" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.281Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eaeGRdAx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.685Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pDdum3gG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.161Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7D4Nz",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "MY34726D6PRUOOVSEZBP7KTB",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "M5STBYQ7E6XAGCAD80F6TY6ZR",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "se1T27UN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.759Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3QMFgcau" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:40:42.074Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jorU1ahs" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.928Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rdmYna4A" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.523Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5NVo4jgd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-25T23:29:03.861Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-26-2024",
              "discount_version" : "1714085524819",
              "date_created" : "4\/25\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "pricing_rules_version" : "1714085525933",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-mfN7h",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "159539FYEMFWKFG2F4F3Z7SACA",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "XJKVFVCD3MAFCNUSBKVA3EJY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "OYDE3TIFS7JZRCUDQTO3KSJK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xmvvEDg1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.663Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fX1fDt4q" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.712Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iiYj5C9j" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.677Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kcKsGDPf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:47:53.106Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "csyL4Y4W" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.150Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "v5Ybz2ga" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.968Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2GwFhfDC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.211Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nJhA9U9h" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.162Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-24-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!SSS",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-gHrS5",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "RIET7M5255NTOBCC6XMRV6Y3",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5W0NA67DBB5XW21HWJGZ6140V8",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3gfbXTWE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.714Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mHZ6RrJF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.745Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ocTGwZvh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.694Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vYXnwi6D" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:36.385Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "un2Bx7zY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.688Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rF8GLDLD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.533Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6Vyfg1uW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.850Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cy2T925x" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.719Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wDbgGG3w" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.840Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "KZC9KNaP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:22.300Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dE4ANkEa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.839Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "baY9QEqn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.160Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aAjM523K" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.103Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5r7zyBEZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:20.381Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2XK94Y8W" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.593Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7EbGBDFH" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:19:52.024Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jVcB8rn4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.756Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "q8RCWmey" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:16:07.816Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qFsQEksV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.681Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6oqeDRPj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.899Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bjRp4A9z" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.679Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kXagmxcA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:28.960Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "m8M8FHvC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:34:22.719Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "acQBJ2eq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:30:07.960Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ipikaPay" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.960Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "d1ucWpxw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.704Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7yADm5pD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.712Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rDd1tWGK" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:20:16.025Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jhdZYM5n" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.906Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4ZfzdkE5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.717Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ckN54iVa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.732Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fq6prwS9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.668Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3CaVfaNN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:53:22.626Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3PU8Tzae" : {
            "pathname" : "\/redemptions",
            "time" : "2024-04-21T00:25:53.258Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "date_created" : "4\/20\/2024",
              "promomessage" : "Dolor obcaecati nequ",
              "promoshowterms" : "YES",
              "promoId" : "kali-xvcay77",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-xvcay77",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2TP8ED36V8N400ACDMQRGDXVW7",
              "promotitle" : "Nulla veniam placea",
              "redemptionlimit" : "8",
              "discount_id" : "XA65HS743S7Y5LDZ76T5ARU5",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "R4PSCDEGMEVMHYIC6L63ZEJD",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uUzYsStF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.845Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-24-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!SSS",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-gHrS5",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "RIET7M5255NTOBCC6XMRV6Y3",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5W0NA67DBB5XW21HWJGZ6140V8",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hqSZstNp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:01:18.859Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6GyjhAYn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.034Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nPGB7DCc" : {
            "pathname" : "\/insights",
            "time" : "2024-04-30T23:18:00.960Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-27-2024",
              "discount_version" : "1714176850699",
              "date_created" : "4\/27\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "testing bro",
              "promoId" : "kali-wb8h8",
              "pricing_rules_version" : "1714176851775",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wb8h8",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4jivphYt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:27.860Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tY89vU9d" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:20:16.024Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2sXm57hy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.733Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eQkpTScK" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T01:28:06.873Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB8",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "02-17-2222",
              "discount_version" : "1714176850699",
              "date_created" : "5\/1\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "testing bro",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714176851775",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "02-17-2222",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cHdVC",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "62wq5NFW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.078Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mhJhhWA9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:29.188Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2bWMPpHz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.912Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "egX18JxX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.836Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kevtPpCe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.967Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2Vf7txb9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.666Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wqok7jCX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.491Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5e1bMhCk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.321Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dQ382d51" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:25:16.573Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dYitRREp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.708Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "K5bPwTcB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:39.261Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5Dw74P2u" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:42:01.471Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nEPYyhJw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.671Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uQVAETYm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.664Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sQmA8ddS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.985Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8Ve34rLe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.700Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nmkhKsKZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.861Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "m9sneRyP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.258Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tJ7uNtwV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.657Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vHjvevi3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.707Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "49Mh1GrZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.718Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tsgZjmyS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.670Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "i63Kf8n7" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:20:37.202Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sntyD2sq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.907Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dceSUR4p" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.694Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wWYoDSds" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:20:16.005Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vTALTeKs" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.674Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9QkZmE5X" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:29:22.140Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4j2khzjg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.675Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tZuMo1m7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.895Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "XSc1xiZb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:32:29.513Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gYYPKmbz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.614Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hv5vgnZg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.724Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9st4zuv4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.749Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "78HURevA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.653Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cw9hbRCJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.706Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oQs6Yzyu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.584Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rmLDg8aT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.129Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3Rj8sUT4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.263Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "GqxLYsHw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:34.226Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cgnwe8fF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:40:42.088Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9RNtfwzS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.330Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sXJ8UwUz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.076Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wjJYp47y" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.519Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2V2RVjca" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.697Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3ZYvCWWR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:42:01.461Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gHZRk5XS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:29:22.144Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nGDBe77w" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.275Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dDt2nA3j" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:07:15.241Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "j4XrqiFQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.682Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tGupmTR4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.150Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aD9PtRwA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.124Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tySKdWVP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.691Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nDorcR1U" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.150Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "DDj1gdVy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:34:43.557Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kqLP3hj4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.681Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "n11x5QAA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.696Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cYbYiJji" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:20:16.018Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "j8HyrKEV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.133Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nRv41ZR2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.069Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m2RnV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "SB3A73LCRHQTTPA2R7OTLS7T",
              "promotitle" : "Buy one get one free testi,ns!",
              "redemptionlimit" : "10",
              "discount_id" : "9V3AS0RBEBA5ZF8GCGRPRZ58V",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3AmJ47Bv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.171Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "amfUAZ8q" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:49.993Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3f4C31HB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.690Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "p3eYJTDv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:06.575Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pCFJF3g9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.756Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kSTBiEtS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:06.197Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fjB8c2a3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.765Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qCTjpRm8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.575Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "476s3VCc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.257Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6ccBWQDn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.862Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ofTEP4bi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.760Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tZgjRZcf" : {
            "pathname" : "\/insights",
            "time" : "2024-04-30T23:18:00.955Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-27-2024",
              "discount_version" : "1714087328770",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "ADD ONE",
              "promoId" : "kali-pctdy",
              "pricing_rules_version" : "1714087329540",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pctdy",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "37RT95TN5VJ0TVW24JDET8ZGR4",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "SMM7HSBILDKN2RNTUUT67ZSZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "PFL5QMIMAJRJA4NJOJTQ7QNK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "24ZEkzhv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.565Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "21SKbRp7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.918Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6TYJf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "FW75POPSYAFQPSNWBGSLARNQ",
              "promotitle" : "Buy one get one free!yaooddd",
              "redemptionlimit" : "10",
              "discount_id" : "C17GJK7ERQ17EDRD3M62RG3BS",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "44PBf5mN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.339Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5rJcwcHn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.478Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "idgXpLzU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.390Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qPMUWKrg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:58.004Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "k2SGANfF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.853Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bwPeEi1i" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.919Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qPWVxyUZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.468Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "42dyuXAK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.847Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buw",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-waVUX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "C6Y2XBFX4XQRSJRRNOJJORTY",
              "promotitle" : "Buy one get one freewwwffffffff",
              "redemptionlimit" : "10",
              "discount_id" : "M6SGDJNMH19AREZAS4EDCG59R",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8kToADFc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.705Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cucFyeN3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.280Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mznLF2KB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.744Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "faPMuqrz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:42:01.463Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xyeT8vhx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:22.299Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "r5tMdetH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.690Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "c3SL6UjU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.609Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3YQTV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5OUAT7Y44KBSRYOB3OUB7NWR",
              "promotitle" : "vvvsdqsds",
              "redemptionlimit" : "10",
              "discount_id" : "7YA7QCWCJ9E5DSG518VE1HQH51",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cgfWyM3d" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.170Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vxqtruMN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.700Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "m3FbBszi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.662Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cvGK4bAP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:30.984Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uBE4951m" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:49.992Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qstiNTjY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.674Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nMorMh3H" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.722Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jSQZcZY1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.240Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7ys7qiiq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:29:22.146Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "62L8zKSi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.888Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ox9owBYh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.501Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vBJHBr96" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.906Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gdwzPxsB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.918Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fBXFHWHH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.783Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kgDvUqmZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.480Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kbtMSFoh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.843Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6TYJf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "FW75POPSYAFQPSNWBGSLARNQ",
              "promotitle" : "Buy one get one free!yaooddd",
              "redemptionlimit" : "10",
              "discount_id" : "C17GJK7ERQ17EDRD3M62RG3BS",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3uHNtCVa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.663Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bkswkWwX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.718Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dRVBhbnK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.884Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tBxigCQK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.246Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tWG1fhBQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:41.270Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "x1BxefbZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.399Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fCgNrPRb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.687Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "a9ieULQ1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.672Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qYn6wsuY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.861Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hVQd4vMy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.660Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "e752rXqH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.038Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bPVWDcCV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.679Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "24K2Y3CA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.977Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "98VXNh5Y" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:53:22.654Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pHhvCFeu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.707Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mimfBQmA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.722Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "RiyhVZWt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.619Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6sPjBFkn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.156Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6vbYuvQv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.912Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "r1QAyLGU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.918Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8xPhx5mE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:31:41.341Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "poTsHq1m" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:34:22.731Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2gh6414a" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.933Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rTSsdmQg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.064Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vxocTmMd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.590Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6MimniDg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.733Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9wdAyrXD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:29:22.157Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4agQdP5C" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:50:39.796Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "skEa73GM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.725Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "idZRG8E5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.851Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pTW3Date" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.983Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nySftq3Q" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.876Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tUHRJh1P" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.246Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "geYPMyqs" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.677Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5d78A4EB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.704Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wMf5HdSt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.649Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "v6ieKeKY" : {
            "pathname" : "\/redemptions",
            "time" : "2024-04-21T00:09:40.235Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "date_created" : "4\/20\/2024",
              "promomessage" : "Dolor obcaecati nequ",
              "promoshowterms" : "YES",
              "promoId" : "kali-xvcay77",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-xvcay77",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2TP8ED36V8N400ACDMQRGDXVW7",
              "promotitle" : "Nulla veniam placea",
              "redemptionlimit" : "8",
              "discount_id" : "XA65HS743S7Y5LDZ76T5ARU5",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "R4PSCDEGMEVMHYIC6L63ZEJD",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6nsQYQka" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:01:18.882Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qJhYUPqM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.566Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cCrcvEGD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.884Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eguWkvnX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.733Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dYLnwWnb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.588Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gDeyaJ6L" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.761Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7D4Nz",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "MY34726D6PRUOOVSEZBP7KTB",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "M5STBYQ7E6XAGCAD80F6TY6ZR",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4BUemVxz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.717Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7hpNAc3W" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:41.271Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pXFFUd1e" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:31.487Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qryoq8fp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.317Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "raHsRLfv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.759Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qJSgK",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "B5VNZIHJ5J2JAN3ZGBDZUQFH",
              "promotitle" : "Buy one get one free amount Ya bro !",
              "redemptionlimit" : "10",
              "discount_id" : "63RWVQZNN2NBKFQFK4946QZVJB",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iqdmmaoA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.320Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8sXPvHqg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.323Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eRrz43aN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.046Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "R5uGJARg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.717Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ssygeD9k" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.650Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3FEesi3Q" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:08.502Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8QL1wPpU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-25T23:29:03.863Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "discount_version" : "1713911382071",
              "date_created" : "4\/20\/2024",
              "promomessage" : "Dolor obcaecati nequ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "pricing_rules_version" : "1713911382886",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-xvay7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2CXV2QYRHS9SPYXQG4QYNQJ55P",
              "promotitle" : "Nulla veniam placea",
              "redemptionlimit" : "8",
              "discount_id" : "BNCIFG2G3HVFRIXPCMUKZ36E",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "J2NH5KN454ESRJG6Z3ZV4FMA",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "v1EyZsdo" : {
            "pathname" : "\/insights",
            "time" : "2024-05-01T00:55:26.168Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-26-2024",
              "discount_version" : "1714085524819",
              "date_created" : "4\/25\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promoId" : "kali-mfN7h",
              "pricing_rules_version" : "1714085525933",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-mfN7h",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "159539FYEMFWKFG2F4F3Z7SACA",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "XJKVFVCD3MAFCNUSBKVA3EJY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "OYDE3TIFS7JZRCUDQTO3KSJK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8fniCdR8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-25T23:29:03.857Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-27-2024",
              "discount_version" : "1714087328770",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "ADD ONE",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087329540",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pctdy",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "37RT95TN5VJ0TVW24JDET8ZGR4",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "SMM7HSBILDKN2RNTUUT67ZSZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "PFL5QMIMAJRJA4NJOJTQ7QNK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pWp5B9RT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.693Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "djXNbeak" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:21.689Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "icnAT3ry" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.895Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8yEqGZHT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:32:29.524Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vV28D3YF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.745Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pGEHArau" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.654Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qzp4gaxV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.339Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cmJxAEM7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.708Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "d9frWQ5a" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.667Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nPfDEzo4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.259Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oGMBGNzd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:30.982Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jXUsGgdG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:31:41.349Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xhjTQ9zb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.850Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3YQTV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5OUAT7Y44KBSRYOB3OUB7NWR",
              "promotitle" : "vvvsdqsds",
              "redemptionlimit" : "10",
              "discount_id" : "7YA7QCWCJ9E5DSG518VE1HQH51",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iRc8hZZk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.832Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "p3TjnB57" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T01:27:20.293Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vnfxqHt3" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:23:15.266Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "e7pKDui8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.915Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tQ8BgSAC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.726Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pvGeQSR6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.870Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "t2uiEELD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.715Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qooczpth" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:19:32.513Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sTRW1M28" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.877Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9P6tUQNK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.659Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "u1twjh5q" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.324Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uuw1oLVZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-21T00:07:56.008Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/21\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-22-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUFW3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4A1CBWMXNY2RZPYWE03XJT2FTW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "GPFWKEX4YSYZNE5SRAHZ7O6T",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "O2SZLUHVK7GSQW552VJBYFWP",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gcWZ8Mvx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.895Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8XTsowc9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:28.971Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rFrZg7K9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.062Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ipBn8WKH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:27.862Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oL9tw2mo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.575Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "omG5nC8H" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:07:15.249Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eQtZ6vRk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:28.966Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jBncYGZo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.709Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qQSTGqqx" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:31:26.245Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bCt6xXAj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-21T00:07:56.016Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "date_created" : "4\/20\/2024",
              "promomessage" : "Dolor obcaecati nequ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-xvcay77",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2TP8ED36V8N400ACDMQRGDXVW7",
              "promotitle" : "Nulla veniam placea",
              "redemptionlimit" : "8",
              "discount_id" : "XA65HS743S7Y5LDZ76T5ARU5",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "R4PSCDEGMEVMHYIC6L63ZEJD",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iPXqnmoV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.517Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3VnxpygE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:55:10.053Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kXwrc5xd" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:25:16.572Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "o7sCpt9R" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:33:48.189Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB8",
                "imagename" : "cookie.png",
                "promotype" : "2-gradient",
                "primarycolor" : "423F32"
              },
              "status" : "ACTIVE",
              "dateend" : "02-17-2222",
              "discount_version" : "1714176850699",
              "date_created" : "5\/1\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!32024",
              "campaign_name" : "testing bro",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714176851775",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "02-17-2222",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5vYG1",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
              "promotitle" : "Buy one get one free!!!!",
              "redemptionlimit" : "10",
              "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rezJopim" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.255Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tGwC4ecK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.852Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cXpvfwPP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:36.388Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eApkCjT5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.585Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nCGDebJe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.742Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sXmmKaJs" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.218Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dHbWoGmL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.744Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ivRrXo56" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.665Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eihN3639" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.894Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "t673KLxP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.679Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xafdDPDX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.240Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kd6g6JZ9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.657Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mLaviFXA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:07:15.252Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hRsBgea6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.240Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bgJWScuP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.679Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uV1vYV4m" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:30.975Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dBuKnazb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:49.994Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "m9MF4sVo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:16.241Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aXHNDX6Z" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T00:11:29.941Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mYNYe1Sv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:16.622Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rcRGufKa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.675Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nYcHL6Uz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:50:39.793Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "t4iUFbec" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.560Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hxP7HjGi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:47:53.093Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8xebi8AW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:31:41.340Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uzDqDG9f" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.720Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hrfUXti5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:01:18.873Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "s3p9sUZD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.598Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buw",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-waVUX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "C6Y2XBFX4XQRSJRRNOJJORTY",
              "promotitle" : "Buy one get one freewwwffffffff",
              "redemptionlimit" : "10",
              "discount_id" : "M6SGDJNMH19AREZAS4EDCG59R",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hsxvrWsy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.661Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2gt4cCVe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.728Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pdCSt5M2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.700Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "na9yYXL8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-25T22:52:16.807Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-26-2024",
              "discount_version" : "1714085524819",
              "date_created" : "4\/25\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "pricing_rules_version" : "1714085525933",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-mfN7h",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "159539FYEMFWKFG2F4F3Z7SACA",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "XJKVFVCD3MAFCNUSBKVA3EJY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "OYDE3TIFS7JZRCUDQTO3KSJK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "r6Sq4MyW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.648Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "n4fSYpee" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:16.620Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "Dpct4hyM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:16.236Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dru91Qi1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.476Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9G1bYNNv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.022Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dygoXfJJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.068Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6TYJf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "FW75POPSYAFQPSNWBGSLARNQ",
              "promotitle" : "Buy one get one free!yaooddd",
              "redemptionlimit" : "10",
              "discount_id" : "C17GJK7ERQ17EDRD3M62RG3BS",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "e8X8We3E" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.078Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wnYXz91r" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.274Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pN1MMnqU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.711Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vpL5W939" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.420Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wqWhr5YB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.250Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "m4oKwewc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.957Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2oosGgsY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:31.474Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3q1gP11q" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.662Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oUc7N77k" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.740Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7o5eWfcx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.568Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rfRmxSDB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.111Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4o839Cnn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.699Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qRk4BhV8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.594Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tiTxPTA2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.919Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uqw6zHHw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.176Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6frR3VRq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.747Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qH8XzQ36" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:40:42.084Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wQWyx4Zg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.278Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kuquvhZu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.678Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cReQB5EU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:21.694Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wsqaJcMr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.122Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7VCUamRQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.742Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vf3ScMTs" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.672Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "r8z3Tc2j" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.020Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9bt7VB4a" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.220Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9wprYafM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:31.483Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "d57rR9AS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.571Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cbTstgs7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.702Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "k6nNr1oP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.813Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pULCgV5V" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:34:43.572Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hEcXfL9D" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:42:01.473Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "HLQyqzEV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.927Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buw",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-waVUX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "C6Y2XBFX4XQRSJRRNOJJORTY",
              "promotitle" : "Buy one get one freewwwffffffff",
              "redemptionlimit" : "10",
              "discount_id" : "M6SGDJNMH19AREZAS4EDCG59R",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nD3YMsWd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.706Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mDLTsg2R" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.809Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mf8ZvTuP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.525Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6S4D2sYv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.926Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-24-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!SSS",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-gHrS5",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "RIET7M5255NTOBCC6XMRV6Y3",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5W0NA67DBB5XW21HWJGZ6140V8",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "w9A51SS5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.666Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jKZcYMuo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.592Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uW7S1UUx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.728Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "g3zczeAJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.704Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9rwaEjXr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.322Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rZXsgRj1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.517Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "goxnnkp7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.917Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-26-2024",
              "date_created" : "4\/17\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m5Npf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3SHPB09ZRG49Y3P5NG9WFMNXVT",
              "promotitle" : "Buy one get one free edting 1!",
              "redemptionlimit" : "10",
              "discount_id" : "5VUWUQNVKZNZ2NHSNTO5VGRV",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "waPy4DBx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:29:22.156Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "PundJLNM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.578Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qYb9ehRR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.870Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7ZmG5H2N" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.730Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bFVWhLcn" : {
            "pathname" : "\/insights",
            "time" : "2024-05-01T00:55:26.169Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-27-2024",
              "discount_version" : "1714087328770",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "ADD ONE",
              "promoId" : "kali-pctdy",
              "pricing_rules_version" : "1714087329540",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pctdy",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "37RT95TN5VJ0TVW24JDET8ZGR4",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "SMM7HSBILDKN2RNTUUT67ZSZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "PFL5QMIMAJRJA4NJOJTQ7QNK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "i3mi5JYa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.741Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3UTvmjJV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.878Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bJo1J2iS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:27.852Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5jotu35F" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.589Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sDQT8dAs" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.535Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iiYxfgFX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.682Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oqqxrdkG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.652Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "psMjBBDJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:34:22.720Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "scpsHnZr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.418Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3tTNUqfY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.888Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7XEEN113" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.947Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "m44sVZrE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.700Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6JcRDaHt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.906Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ryz2zTr5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:07:15.250Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2yFXoVdr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.678Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3arYfqTz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:22.302Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8oMX1scD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.505Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9mzmPTFR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.204Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3socZ78S" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.231Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jKmsgCcV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.404Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "atFnSeaN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-30T21:18:25.069Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mGbxWZE1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:16:13.238Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "82fSib2k" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.604Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "883Wffs9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.675Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "o5eG3Yr1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.723Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3LsJwUW6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.058Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uVjr9Zkz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.041Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aK6H5zDJ" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:23:15.265Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vXmKsTVr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.237Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "a5oQauuy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.044Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sm8qEMhD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.678Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eYkVPTfs" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.706Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "byjCajhv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.764Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-w4joh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DJYL4WS37ULZB72R6AP54BO2",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "2K7RNZ3151V34RX644NMT2FVVX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cRauUY6U" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.335Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hkYZo5qT" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:20:37.203Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hJkb34yg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:06.185Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ehgoKqEi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.782Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "k3tPm2KJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.025Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3ojfUYE8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.509Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "m8J2uQMD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.723Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qJSgK",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "B5VNZIHJ5J2JAN3ZGBDZUQFH",
              "promotitle" : "Buy one get one free amount Ya bro !",
              "redemptionlimit" : "10",
              "discount_id" : "63RWVQZNN2NBKFQFK4946QZVJB",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jsqhH2QP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.659Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "x9pFkyCo" : {
            "pathname" : "\/insights",
            "time" : "2024-06-25T23:42:43.972Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-27-2024",
              "discount_version" : "1714087328770",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "ADD ONE",
              "promoId" : "kali-pctdy",
              "pricing_rules_version" : "1714087329540",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pctdy",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "37RT95TN5VJ0TVW24JDET8ZGR4",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "SMM7HSBILDKN2RNTUUT67ZSZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "PFL5QMIMAJRJA4NJOJTQ7QNK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/125.0.0.0 Safari\/537.36"
          },
          "wj1AdMD7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.712Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oW4eYZmG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-21T00:08:53.652Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "date_created" : "4\/20\/2024",
              "promomessage" : "Dolor obcaecati nequ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-xvcay77",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2TP8ED36V8N400ACDMQRGDXVW7",
              "promotitle" : "Nulla veniam placea",
              "redemptionlimit" : "8",
              "discount_id" : "XA65HS743S7Y5LDZ76T5ARU5",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "R4PSCDEGMEVMHYIC6L63ZEJD",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4wJh84rN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:34:22.707Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bJW9bagG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.710Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "q9Uvug4m" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.054Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tXUUtdeS" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T01:27:20.296Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8WCEchPW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:22.247Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aHkGT9fu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.065Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xoQLcxwj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.720Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "46Cg87ti" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.716Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pm7PvA65" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.679Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4VTLG91C" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.749Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sgd4GsnL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.811Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5GJzkXVf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.259Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5nXcJidM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.664Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5gTfo2sn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.750Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hLyGtXTY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.732Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hVipdTbw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.932Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "u3L8PBCx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:46.005Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4CrhXaVE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:48.193Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oVRnfnnc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.211Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jEu2fXNL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:20.393Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2KbSpZhE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.226Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eV9fRAE8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:01:18.886Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "azhsEF8n" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.685Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uNE1B9bB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.205Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "99JkZ7S2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.656Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4beY4Kgi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.728Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5JZ3Eson" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:07:15.236Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ezdCm4YM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.878Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hWU28Ty1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.718Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bkRw9VZW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.504Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9sppPTR7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.684Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9DHGiY7f" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:29:22.142Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jtjdU525" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T04:53:49.779Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kJZEWeSR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:07:15.251Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2oj3W48b" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.575Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "txp3c3QE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.897Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "26yWKpRk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.719Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vUecCxVG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.261Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4Tjcvibi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.896Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6b3T4pB9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.894Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7vnhgGAS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.701Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7edFraBz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.683Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "djd42Fxc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:36.402Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5mmniv63" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:32.826Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7rcJyQJL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:22.297Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "g6FCwxUt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.679Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eXE2dwig" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:21:56.476Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hzyvALDN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.650Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2Bxk1jD9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.562Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "d2DZyewQ" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:33:48.218Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "88EKaMgG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.969Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tb6QKmaH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.924Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9z6JbJpA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.486Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "epQJpyea" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.897Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "brT5Ja5N" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.922Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "922YcpKa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.867Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "giir81be" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:34:22.713Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cWQjcDeL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:28.962Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fRvcStAr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.669Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jf9scrTq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.889Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8C1UbZ52" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.711Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "f5BB8CYL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.167Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fa1f9KVx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.120Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "g8SzRWMw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.611Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "knoq7Kgm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.613Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cebG9Gjm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:04.154Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pBPrCVXY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.683Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4UQ3DnqD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.650Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ksCLfvNh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-25T22:52:06.644Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "discount_version" : "1713911382071",
              "date_created" : "4\/20\/2024",
              "promomessage" : "Dolor obcaecati nequ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "pricing_rules_version" : "1713911382886",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-xvay7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2CXV2QYRHS9SPYXQG4QYNQJ55P",
              "promotitle" : "Nulla veniam placea",
              "redemptionlimit" : "8",
              "discount_id" : "BNCIFG2G3HVFRIXPCMUKZ36E",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "J2NH5KN454ESRJG6Z3ZV4FMA",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ok1Mphqj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.847Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7D4Nz",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "MY34726D6PRUOOVSEZBP7KTB",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "M5STBYQ7E6XAGCAD80F6TY6ZR",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cTuu2Q1j" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T01:26:32.695Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB8",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "02-17-2222",
              "discount_version" : "1714176850699",
              "date_created" : "5\/1\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "testing bro",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714176851775",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "02-17-2222",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5igma",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8iFEebJr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.054Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gT4NiCRZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.530Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bvTacoC7" : {
            "pathname" : "\/redemptions",
            "time" : "2024-04-21T00:09:32.629Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "date_created" : "4\/20\/2024",
              "promomessage" : "Dolor obcaecati nequ",
              "promoshowterms" : "YES",
              "promoId" : "kali-xvcay77",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-xvcay77",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2TP8ED36V8N400ACDMQRGDXVW7",
              "promotitle" : "Nulla veniam placea",
              "redemptionlimit" : "8",
              "discount_id" : "XA65HS743S7Y5LDZ76T5ARU5",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "R4PSCDEGMEVMHYIC6L63ZEJD",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tLYV7yjm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.700Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "q75CBDHj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.139Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vNX8kg4z" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.159Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uHoHf9f1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.555Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sBqB1c1J" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.654Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xs8jjrfT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.933Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pcB57cqa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.657Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "x64zAf4m" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.733Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rdZcLQar" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.533Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aUEHTYGP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.767Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "a962TR4n" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.982Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5rbPLXii" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.707Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aojKpGLm" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:33:53.750Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB8",
                "imagename" : "cookie.png",
                "promotype" : "2-gradient",
                "primarycolor" : "423F32"
              },
              "status" : "ACTIVE",
              "dateend" : "02-17-2222",
              "discount_version" : "1714176850699",
              "date_created" : "5\/1\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!32024",
              "campaign_name" : "testing bro",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714176851775",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "02-17-2222",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5vYG1",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
              "promotitle" : "Buy one get one free!!!!",
              "redemptionlimit" : "10",
              "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bxXi9dqq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:21.685Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iuVmdi4x" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.701Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kjhAFVpM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.697Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ksD8JSV4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:16.631Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fxZDiAue" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T01:25:49.902Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nA81BVQr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.737Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "n5ZbzFee" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.419Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tFJYziL6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.690Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iEgkPcGf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:31:41.357Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aJ3psLpM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.652Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fC8uE4dY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.704Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gcoi5oMj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.718Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3z5arz7w" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.864Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2ocRwBHz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.674Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hoBX59oz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:55:10.051Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fKY32df2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.495Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aGtNuPJU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:49.984Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "r1wUfobm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.147Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rB49mPGj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.826Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7Rcn9Zos" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.714Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oo8rs8Fj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.664Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uWUuBPB7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.816Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "39gcv7Q6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.596Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tcpJokQm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.709Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3DhhyDHx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:22.270Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5BpvZFud" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.503Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fchN1Wgc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.723Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "q5DjuRGW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:18:41.818Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bZuaB6K4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.403Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uRoLM3Af" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.672Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "saxhiwJ3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.497Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gSUjiD5w" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.724Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fKisN5wT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.935Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7nhNt2TM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.256Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2uMd4haL" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T01:26:32.707Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eV8YLrf1" : {
            "pathname" : "\/redemptions",
            "time" : "2024-04-21T00:25:24.907Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "date_created" : "4\/20\/2024",
              "promomessage" : "Dolor obcaecati nequ",
              "promoshowterms" : "YES",
              "promoId" : "kali-xvcay77",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-xvcay77",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2TP8ED36V8N400ACDMQRGDXVW7",
              "promotitle" : "Nulla veniam placea",
              "redemptionlimit" : "8",
              "discount_id" : "XA65HS743S7Y5LDZ76T5ARU5",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "R4PSCDEGMEVMHYIC6L63ZEJD",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "w6gBK5DR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.980Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fBW76Pam" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:07:15.237Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4LdhznuD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.854Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3YyCrJTY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.715Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dAh82uwG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.310Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ofWYNdHJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.719Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oiNzSGcw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.649Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6ayPSRhT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.671Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "q17U1oGb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.818Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "q18KP9fi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.691Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iFsE2EPi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-30T21:15:18.677Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aNnKAYGF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.826Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wXGj7WTW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.682Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "59C2kXuL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:16:13.238Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "q5K8PBom" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:28.969Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eZMzq1EQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:20.387Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vBVQUAuf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.913Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "crD4cuug" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:48.191Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wmboyesY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.727Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kPaqsLtn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.524Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3CuXnxkf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.737Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bkoP6KnH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.923Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ewH4r6jJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.774Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3BxkAHWD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.585Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mW7MZ3Yz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.894Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "a1cKLPJP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.910Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7bLmdqgX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.840Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mBYD6Rhq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.915Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6DqddMZP" : {
            "pathname" : "\/insights",
            "time" : "2024-04-30T23:18:00.957Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-27-2024",
              "discount_version" : "1714511902704",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "testing la vi",
              "promoId" : "kali-q6UGw",
              "pricing_rules_version" : "1714511903551",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-q6UGw",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "52KC2275Y31N8TXWYBYSSKCPTK",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "7NJWKVFIX42ZY4NZRCDQX4YZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "FZET4BWMP2YWGI5ASOYUMQJZ",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "awqp283B" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.842Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aVqQeQqL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.710Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pgqqs5Dx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.835Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ftsavRLQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.560Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iEdKtVhd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.761Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "j61nhk34" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.739Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6DDFrz3t" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:34.219Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rcWeJvyt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.253Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nxMCVX5Q" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:31:26.244Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "k4xWJRRB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.678Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "x9okCatS" : {
            "pathname" : "\/insights",
            "time" : "2024-05-01T00:55:26.173Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB8",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "02-17-2222",
              "discount_version" : "1714176850699",
              "date_created" : "5\/1\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "testing bro",
              "promoId" : "kali-wb8h8",
              "pricing_rules_version" : "1714176851775",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "02-17-2222",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-iu9xG",
              "promoterms" : "Offerd valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cSuF8pv5" : {
            "pathname" : "\/insights",
            "time" : "2024-05-01T00:55:26.171Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-27-2024",
              "discount_version" : "1714511902704",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "testing la vi",
              "promoId" : "kali-q6UGw",
              "pricing_rules_version" : "1714511903551",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-q6UGw",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "52KC2275Y31N8TXWYBYSSKCPTK",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "7NJWKVFIX42ZY4NZRCDQX4YZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "FZET4BWMP2YWGI5ASOYUMQJZ",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pG6JFFhG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.531Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iUiHgrSM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:06.193Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fiD1Tjqn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.062Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uePRYZw3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.397Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wVJTyieM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:55:10.045Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wsPVinEP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.734Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "YrWYKMEm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:50:39.803Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dAN38bWC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.049Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "odbk7ozv" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T01:28:06.893Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cPkzRwuQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.609Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nwmAxJTu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.061Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mbqn8XmH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.143Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9zkbzTbv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.669Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "936hWGq4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.518Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wZeqkwRN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.682Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vg52D6Qi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.651Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "u6YEeqq8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.063Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aHXu1F13" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.573Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wXG3ZVPC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:36.403Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "92r6jcgP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:50:39.794Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cxTbaZTQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.668Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fQEL7ueD" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:19:52.015Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "u46aTuwq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.868Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pe3N3jPS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.853Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bFhPsJwx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:47:53.097Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sBv49o36" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.679Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8tnBzRFP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.561Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nDMeeqXg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.655Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5snceqU6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.676Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dLCTSb73" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:31.472Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "njrMLDhm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.818Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "t2rMwoHM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.540Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mbndqgdo" : {
            "pathname" : "\/insights",
            "time" : "2024-04-27T02:20:52.249Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-26-2024",
              "discount_version" : "1714085524819",
              "date_created" : "4\/25\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promoId" : "kali-mfN7h",
              "pricing_rules_version" : "1714085525933",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-mfN7h",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "159539FYEMFWKFG2F4F3Z7SACA",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "XJKVFVCD3MAFCNUSBKVA3EJY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "OYDE3TIFS7JZRCUDQTO3KSJK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nv1iRHoE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.717Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "izT6BSpr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:06.582Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rTUqZbRz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:50:39.802Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "f2x3w1a8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:34:22.719Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fJ8jVjnD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:30.983Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iZBLfqJz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.679Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xtYpw7vD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:31:41.349Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xfR2pjCs" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.532Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "m8vUnv45" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.252Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "39ASCd2Z" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.716Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6TjhX7Kf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.991Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rN3EN6Wk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.767Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7D4Nz",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "MY34726D6PRUOOVSEZBP7KTB",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "M5STBYQ7E6XAGCAD80F6TY6ZR",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gyECHKXu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:42:01.468Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cg1zpNMm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:07:15.243Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "Zz1APTPy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.130Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "abg8Y7Ge" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.075Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uLPdAmpQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.017Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wWLxq5Ur" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.757Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m2RnV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "SB3A73LCRHQTTPA2R7OTLS7T",
              "promotitle" : "Buy one get one free testi,ns!",
              "redemptionlimit" : "10",
              "discount_id" : "9V3AS0RBEBA5ZF8GCGRPRZ58V",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "swxNvvna" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.716Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "azMiXUiG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.710Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "NXBG6ALZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.508Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tAiRKnGi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.723Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7gMWWohm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.656Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "u4H16ppv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.701Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8jFPMSM6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.690Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ou672jEz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.910Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aT9CxSEX" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:19:32.492Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7Shb3rwS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.665Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pqdKCKgh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.194Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "i3KbFZVV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.491Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "byvedXp8" : {
            "pathname" : "\/insights",
            "time" : "2024-04-27T02:20:52.252Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-27-2024",
              "discount_version" : "1714087328770",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "ADD ONE",
              "promoId" : "kali-pctdy",
              "pricing_rules_version" : "1714087329540",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pctdy",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "37RT95TN5VJ0TVW24JDET8ZGR4",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "SMM7HSBILDKN2RNTUUT67ZSZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "PFL5QMIMAJRJA4NJOJTQ7QNK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "41pPvDbn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.670Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pbAFKpJw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.700Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pwyKGv7j" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.692Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hzhDGmm4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:31.471Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oaNJSGyw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.914Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vQHmkEWZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.073Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buw",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-waVUX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "C6Y2XBFX4XQRSJRRNOJJORTY",
              "promotitle" : "Buy one get one freewwwffffffff",
              "redemptionlimit" : "10",
              "discount_id" : "M6SGDJNMH19AREZAS4EDCG59R",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nb2CsQR4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.912Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jgetWDwf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.062Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iVUP9iHb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.962Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gQxdWzve" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.708Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "b55aeX1m" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:06.198Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ac2XYX8m" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.680Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5Y6Z3ukx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.327Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eM81mDtN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.724Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-24-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!SSS",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-gHrS5",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "RIET7M5255NTOBCC6XMRV6Y3",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5W0NA67DBB5XW21HWJGZ6140V8",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vbat53Ha" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.648Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "h83PcrnC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.742Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9gvutxAH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.067Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tZoxD6qu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:27.849Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uLSHg35P" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:27.863Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vPFARaYi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:47:53.099Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ahyYopAD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.973Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rJGD4KYd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.578Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uWMpNhyX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.753Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mzKxzY7Q" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.262Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2BZqTRdN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.018Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jQvKM1Sa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.008Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ek6PNZsD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.808Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pNiGofgi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.666Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "thV1bMYn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.981Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hBFyM2f8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.714Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gs863qr9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.885Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fPin4dj6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.704Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xiYQESUt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.869Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gXy7JD1K" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:28.961Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "m53pbvW5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.743Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "m8UWZjmg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.683Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ifMhvAfm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.894Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3HbRVSUP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:42:01.477Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vMcqq8U3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.210Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "enLYGSqF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.934Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2QftecnG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.052Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "72fomimF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.666Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "efHWSujM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.406Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oryjfSGy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.226Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tAUfMXQg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:50:39.795Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "orPH8amB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:20.386Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uHY4nyDA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:47:53.101Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jjmd41av" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.716Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8ooBcEqL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.704Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "knCYMDhp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.728Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "r3B8TykB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:34.217Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8RE72iYQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.586Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ddMjALEh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.778Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2uqHpEom" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.420Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rCXL2Wym" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.880Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "i1ytEc96" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.200Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pAbvDA7H" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.750Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "93TjK6r5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.508Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jWVYnswM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.515Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "p1vdC6cT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.775Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qHpo1tgr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.505Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bhhuj1U1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:20.395Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hSzuipP8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.543Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "27MRwFvZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.849Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oLPYcJDk" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:23:15.243Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bBCeY1BQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.805Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sr7XHtvC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.670Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kugebFRD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.174Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fgUYa8gD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.529Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tc91yP2H" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.048Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "v5HkMuJX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:41.270Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6skCwPyV" : {
            "pathname" : "\/insights",
            "time" : "2024-05-01T00:55:26.165Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoId" : "kali-kekQV",
              "pricing_rules_version" : "1714511718073",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7f92cass" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.671Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oV3C5C2G" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.749Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ei56iRHK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.676Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oMWBmcai" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.651Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kLPGaDQh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.679Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qypcynwm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:01:18.887Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rFMTNzRp" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:21:12.804Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kPiztpNg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.695Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5j7NcimN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.732Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9KX6aLpu" : {
            "pathname" : "\/insights",
            "time" : "2024-04-30T23:18:00.948Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoId" : "kali-kekQV",
              "pricing_rules_version" : "1714511718073",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6GVdBa1E" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.230Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bmbjZEGW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.685Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cF9NkhQH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:31.475Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8kbSZULu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:45.991Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6Ggiv542" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.735Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xpTfgWTR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:31:41.351Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tAcs1Tmu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.856Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buw",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-waVUX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "C6Y2XBFX4XQRSJRRNOJJORTY",
              "promotitle" : "Buy one get one freewwwffffffff",
              "redemptionlimit" : "10",
              "discount_id" : "M6SGDJNMH19AREZAS4EDCG59R",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "w92TojB3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:04.148Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vMCdsatS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.516Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tR6niVrD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.676Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jm5T7UsZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:34:22.716Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "syv4saDf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.057Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6xXJGECF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:28.962Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vBR8Xs84" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:40:42.078Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7E5T9qCF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.135Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aRbxDu5j" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.883Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3twQXSgu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.502Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7GNNXYA6" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:19:32.491Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9HfLdfN5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.029Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5EEf2hGS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.718Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "q5MH2hoW" : {
            "pathname" : "\/insights",
            "time" : "2024-05-01T00:55:26.164Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoId" : "kali-hxzr3",
              "pricing_rules_version" : "1714087742664",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tG5RmKJh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-30T21:15:18.661Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ufACFmQm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.595Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kPcq6sZ9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.581Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bRii7uu9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.552Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cTdzM8QN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:31:41.354Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fUQ4EToU" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:19:57.154Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uXnMsVeU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.679Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "g52zdSND" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.156Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vKsd25Yr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.731Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2GMCfSmq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.121Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cYqhZbiV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:53:22.630Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gYQFvWo2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.686Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "p7p4gxWU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.931Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3hDLL9rN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.737Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "veaVNTeu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.762Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "erwmjYRs" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.128Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "t92zA87H" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.699Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3G2mnCTn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.018Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6hzkhKfE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.902Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iCCz9HGK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.682Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rt5WngMW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.903Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tPWGb5C3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.937Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vXv1w3ij" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.680Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9qTeGN99" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:53:22.643Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9rNXExz5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:53:22.610Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "q76snHA2" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:25:25.750Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "orjrRy5p" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:20.397Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ryHj9rXK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.649Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "djxD3Ysv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.681Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dy69X2Hh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.833Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fpFtWPRL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.063Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "o4RJNojt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.508Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6WWtFqWE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.873Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xzPfha2R" : {
            "pathname" : "\/redemptions",
            "time" : "2024-04-21T00:09:40.233Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/21\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promoId" : "kali-qUFW3",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-22-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUFW3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4A1CBWMXNY2RZPYWE03XJT2FTW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "GPFWKEX4YSYZNE5SRAHZ7O6T",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "O2SZLUHVK7GSQW552VJBYFWP",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5UoSNeac" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.545Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "n4binRtb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.658Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rNAR9MHE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.687Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gUyZcUa2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:30.985Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8h5ZwGPq" : {
            "pathname" : "\/insights",
            "time" : "2024-05-01T00:55:22.293Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoId" : "kali-hxzr3",
              "pricing_rules_version" : "1714087742664",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2LA9ZmNa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.653Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pEAJ3fFN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.611Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "im5eLJZF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.716Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wgWqyuEv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.503Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mtW6YyFg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.560Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xg8iXJug" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:53:22.650Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "39ZPi8ww" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:34:22.725Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "j9MY97kT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.071Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bTkM2b79" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.923Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-oiZHu",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "547WFMTLS5PXLOORF3XZSOFK",
              "promotitle" : "Buy one get one free testing Amount!",
              "redemptionlimit" : "10",
              "discount_id" : "2G2MN6MET28GGM0DPKQHW3EXDP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "n7M1MHaw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.511Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "r56nc71M" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.485Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "g6zCYHdA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.724Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oS44FwD6" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:33:53.756Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fTH57qf9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.677Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vhDuAq99" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.138Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4jwnDhbH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.693Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dVcFjYLy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.944Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iSkRaf8X" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.747Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cD38XfFY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.660Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5KiU1bRN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.511Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-24-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!SSS",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-gHrS5",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "RIET7M5255NTOBCC6XMRV6Y3",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5W0NA67DBB5XW21HWJGZ6140V8",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mTfR8h3b" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.029Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "N12HkR3U" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:20:16.028Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2dwacQvH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.232Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "d6Vr7qBx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:41.263Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "inJGoeqv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.673Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "n7MM38Ao" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.837Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xpUaRPhe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-27T00:15:41.856Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-27-2024",
              "discount_version" : "1714176850699",
              "date_created" : "4\/27\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "testing bro",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714176851775",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wb8h8",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8q3rzDN6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.314Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2Zqn9MdH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.815Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4nj95pVa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.762Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bsg4oBve" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.937Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dzwhUwbB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:01:18.877Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cctLqSUN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.712Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3ZN1Wj66" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.656Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7yes3rg2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.504Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2pLrQ5mU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:29:22.147Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "81X7ED2Q" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.069Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-idJPC",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4ZOOZ5QWMEKMORLC4QIXUX4Q",
              "promotitle" : "Buy one get one free YOO!",
              "redemptionlimit" : "10",
              "discount_id" : "78JD8R3BSSFDN21QB23HPWWD9F",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tEnt341K" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.130Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vFBDszh5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.652Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5ytEByuS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.921Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-idJPC",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4ZOOZ5QWMEKMORLC4QIXUX4Q",
              "promotitle" : "Buy one get one free YOO!",
              "redemptionlimit" : "10",
              "discount_id" : "78JD8R3BSSFDN21QB23HPWWD9F",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sLf8BPy3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.665Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tEKicWZ2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:16.232Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8yT1DGqB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.687Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bWNQ4h9G" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.261Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rvWNYm5g" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.670Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qMRZFVfH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.518Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qnCti4oo" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:33:48.193Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "m7TPxmjC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.734Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ueJ3Fcjt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:18:41.809Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "v3fUo7PJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:21.699Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9ShcBNei" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.705Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fnDpJbVq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.612Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "weXsrQXQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.534Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tBSmy8wj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.196Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6VhNeDE4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.707Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "o7SXW8jq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.667Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fV3arury" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.914Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iA737FrW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:06.567Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "96bfimkn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.685Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gqUJACNu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.744Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "te5crnD5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:48.178Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iJf4SzNJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:18:41.815Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8QEXvGzY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:01:18.880Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vsW8KHBN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.706Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dATWZeSy" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T00:10:23.300Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oVx8cS43" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.710Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "favagarZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.923Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m2RnV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "SB3A73LCRHQTTPA2R7OTLS7T",
              "promotitle" : "Buy one get one free testi,ns!",
              "redemptionlimit" : "10",
              "discount_id" : "9V3AS0RBEBA5ZF8GCGRPRZ58V",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "85SvFa2z" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.227Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "v1jbFwg5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.676Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "isSfa1gB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.068Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-19-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-h46AF",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7LJTKKZ6JIJWVLWYGZ7XUVAR",
              "promotitle" : "Buy one get one freeaaaasd!",
              "redemptionlimit" : "10",
              "discount_id" : "2YVVCH3T0HW3C5CPMM9BNV5EX3",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "db1JjrKA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.662Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wauukTn2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.739Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9hNKjb2F" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.727Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "o5Yu3bh8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.218Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uwXEkE39" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.713Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ceWb3Hze" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.775Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kwTpkUeH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.978Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "h32wssQt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:41.264Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "n4cYQpuR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.653Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xpVLiand" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.497Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4EHSWbW1" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:33:48.213Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rbpqpfAC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.536Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5XVjofdG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:16:07.817Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ei1xxMst" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.737Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3rXone27" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.575Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "o1vfPam5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.872Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6uNUs2hR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.241Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fLFENCoi" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T01:26:32.710Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "54ATiuqN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.923Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6k3TVGt9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.722Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cSpi4iaZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.242Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "o3cizBxJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.145Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rnQu5Krd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-27T00:01:45.721Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "discount_version" : "1713911382071",
              "date_created" : "4\/20\/2024",
              "promomessage" : "Dolor obcaecati nequ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "pricing_rules_version" : "1713911382886",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-xvay7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2CXV2QYRHS9SPYXQG4QYNQJ55P",
              "promotitle" : "Nulla veniam placea",
              "redemptionlimit" : "8",
              "discount_id" : "BNCIFG2G3HVFRIXPCMUKZ36E",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "J2NH5KN454ESRJG6Z3ZV4FMA",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xuueGKp7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.993Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oR79SbE1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.730Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "u5GyrJqu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.646Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sPeyPJYd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.699Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8zCWzVgK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:41.271Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eonst8EN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:36.386Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "79vFtJur" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.719Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "daDHjSN2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.673Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gNuxekhT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.490Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jYetmVDz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:01:18.881Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "k75RyYfC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:30.976Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tBox7xnU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-27T00:14:12.656Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-27-2024",
              "discount_version" : "1714087328770",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "ADD ONE",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087329540",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pctdy",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "37RT95TN5VJ0TVW24JDET8ZGR4",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "SMM7HSBILDKN2RNTUUT67ZSZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "PFL5QMIMAJRJA4NJOJTQ7QNK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9yRhBof7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.776Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3YQTV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5OUAT7Y44KBSRYOB3OUB7NWR",
              "promotitle" : "vvvsdqsds",
              "redemptionlimit" : "10",
              "discount_id" : "7YA7QCWCJ9E5DSG518VE1HQH51",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "57ftcbzq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.506Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wQvN6b5r" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:32.831Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kq1Kiqoc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.902Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sAc7n4KV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.490Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eYKE1nsm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.835Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6TTM27wS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.669Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tyCapea1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.789Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "q6N6irxH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.806Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "w7GBMJSd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:31.482Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "q1A5n4iC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.823Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buw",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-waVUX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "C6Y2XBFX4XQRSJRRNOJJORTY",
              "promotitle" : "Buy one get one freewwwffffffff",
              "redemptionlimit" : "10",
              "discount_id" : "M6SGDJNMH19AREZAS4EDCG59R",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rm6x5GTt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.819Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-19-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-h46AF",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7LJTKKZ6JIJWVLWYGZ7XUVAR",
              "promotitle" : "Buy one get one freeaaaasd!",
              "redemptionlimit" : "10",
              "discount_id" : "2YVVCH3T0HW3C5CPMM9BNV5EX3",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "u3HG9pkF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.568Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xreiPrA1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:42:01.470Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "g5XYiq5F" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:30.979Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5nqvZPqU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.206Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gpgLfZ6S" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.826Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9i2fNtaD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.728Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pmagNwmS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.051Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sH52mQBF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.698Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9tMoFXWT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.661Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "r2zUktCX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.651Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jPLN9iQD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:55:10.051Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8fLADjLq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.704Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7xwxe5Gi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T04:53:49.781Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "p6uH2fLe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:40:42.078Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kwrJkFYb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-27T00:14:12.647Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4U3sapt2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.694Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "w1wpf6CL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.658Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fBRpN5QR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.033Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "echyd7qs" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:01:18.856Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fPRqrQUS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.726Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buw",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-waVUX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "C6Y2XBFX4XQRSJRRNOJJORTY",
              "promotitle" : "Buy one get one freewwwffffffff",
              "redemptionlimit" : "10",
              "discount_id" : "M6SGDJNMH19AREZAS4EDCG59R",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pk2kiSwJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.610Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ooCphTKr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.865Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pvNszwzT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.685Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mkEj7u26" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.395Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "w4iujxyi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.881Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "o9QBrERu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.715Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5tkAhGTe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:21.689Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "45CwnaWi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:36.400Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hXEcZFzy" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:20:16.029Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qkvkzh2W" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:19:57.151Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uQy9M9Mw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.882Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "96GTVQnN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:22.238Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4z43HVaY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:36.380Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xamodTPv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.705Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ddwPo8yL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.705Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iTgS5EpE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.918Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jeEKxBUf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.592Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8QxuaYTy" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:23:15.263Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bhaqHyNy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.678Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kpemmVco" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.724Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nNTBTDzA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:48.198Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4FPuhkaE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.881Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kys8yyz5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.694Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7dYEtoaZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.703Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "a4Rd7LJt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.953Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ojD6WxAf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:29.180Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5B1KtxWA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.929Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "p1EhbBot" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.728Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "94uH1Fhc" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:31:52.616Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cZt7gifx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.705Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "keqxicwx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.755Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-19-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-h46AF",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7LJTKKZ6JIJWVLWYGZ7XUVAR",
              "promotitle" : "Buy one get one freeaaaasd!",
              "redemptionlimit" : "10",
              "discount_id" : "2YVVCH3T0HW3C5CPMM9BNV5EX3",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vZJNseAf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.388Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wHoVkWfY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:22.303Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xuShM2ff" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.680Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xfjUJrca" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.814Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "haEkf1Mk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.569Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3DV6Bhwh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.583Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ds75iWru" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:25:16.610Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hUZCSAC9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.832Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gv2twKY5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.710Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tkkjsYQu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.907Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hYwK49Ri" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:16.620Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oVtWDpqa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.722Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oGueQKUB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.683Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wjTfTbi9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.924Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3hzggb2B" : {
            "pathname" : "\/insights",
            "time" : "2024-06-25T23:42:43.973Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-27-2024",
              "discount_version" : "1714511902704",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "testing la vi",
              "promoId" : "kali-q6UGw",
              "pricing_rules_version" : "1714511903551",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-q6UGw",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "52KC2275Y31N8TXWYBYSSKCPTK",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "7NJWKVFIX42ZY4NZRCDQX4YZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "FZET4BWMP2YWGI5ASOYUMQJZ",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/125.0.0.0 Safari\/537.36"
          },
          "3fPV4oPb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.716Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buw",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-waVUX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "C6Y2XBFX4XQRSJRRNOJJORTY",
              "promotitle" : "Buy one get one freewwwffffffff",
              "redemptionlimit" : "10",
              "discount_id" : "M6SGDJNMH19AREZAS4EDCG59R",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "doqRYZ9e" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.253Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oHDzP15z" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.492Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8vPyyfkg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.700Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3KwNMMGc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.735Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jZbAYGgJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.396Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rg3rq3bU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:29.184Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "Epd2B1yv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:39.272Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4myh7tQr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.922Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m2RnV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "SB3A73LCRHQTTPA2R7OTLS7T",
              "promotitle" : "Buy one get one free testi,ns!",
              "redemptionlimit" : "10",
              "discount_id" : "9V3AS0RBEBA5ZF8GCGRPRZ58V",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aSJCUqAj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:20.392Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5UnTDXj7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:16.618Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "r5eB7duy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.673Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pTexwssj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:22.306Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6jhGrew3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.728Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dAgTrrKW" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:25:25.753Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "yMYHhNDt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.591Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vtQCREqZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.916Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pCV5K7XX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.277Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "s6TQecYy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.067Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6Dimph35" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.254Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "b23krQMj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.686Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7UN2CDn9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.745Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9zpGKdjd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.728Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "84oKWudZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.608Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6qGzp2p8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.702Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tFx4KsMx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.670Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "u3WSB1Sd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.671Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fZwLJP9a" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.930Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3Z93uBbV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.720Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2wYJuXd7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.123Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8QoyLGbM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.594Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iWHdg51F" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:29.181Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2xDQQqJe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.882Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fNbBnrRU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.124Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2G7KYQG6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.117Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cdwoMEyv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.949Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "64dgjPV3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.394Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qMY1wG6A" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.674Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "p4T7z7Ao" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:27.849Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pnsgNnAV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.707Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qo8t5f35" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.691Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uVDH8Lmb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.691Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rZ4k8aan" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.055Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "b7FNQNmT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.563Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sjYmnyTD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.490Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bg32DWw7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.397Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7fPq3zvi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.699Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fbQSBBtc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.701Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wGrdMBCA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:55:10.054Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cXvWHto8" : {
            "pathname" : "\/insights",
            "time" : "2024-04-27T02:20:52.255Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-27-2024",
              "discount_version" : "1714176850699",
              "date_created" : "4\/27\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "testing bro",
              "promoId" : "kali-wb8h8",
              "pricing_rules_version" : "1714176851775",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wb8h8",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7dLZs2Sr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.505Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2GARsSi2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.757Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3wHeRrb6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.700Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6wzRbESV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.155Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buw",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-waVUX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "C6Y2XBFX4XQRSJRRNOJJORTY",
              "promotitle" : "Buy one get one freewwwffffffff",
              "redemptionlimit" : "10",
              "discount_id" : "M6SGDJNMH19AREZAS4EDCG59R",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "daf9Wrsk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:50:39.802Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pAdg2Eg8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.254Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vap2vdpL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T04:53:49.781Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bbx1YvEm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.737Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iCgNvJ3W" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T01:31:34.499Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "u5A8TX1F" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.716Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jDTf11oM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.401Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "i6Y1uNvM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.694Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "o6k9HFPx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-27T00:14:12.601Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-27-2024",
              "discount_version" : "1714176850699",
              "date_created" : "4\/27\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "testing bro",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714176851775",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wb8h8",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2rJYGtfb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.506Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oyYWx2qx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.730Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7SX78Ncf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:06.187Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "igHa5pxq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.713Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eqFQvkXS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.475Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7vwn8YVg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.936Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mhTP4ctM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.257Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ad86fVos" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.674Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nSRKUjcf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.016Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tx11ScGf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.769Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-24-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!SSS",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-gHrS5",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "RIET7M5255NTOBCC6XMRV6Y3",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5W0NA67DBB5XW21HWJGZ6140V8",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qqfjPG4R" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.012Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qnQCc4rx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:06.569Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2KG6tmpv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.249Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fTVwd5Xa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:30.984Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4u4QFDaj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.705Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ok2kwc7J" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.920Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-idJPC",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4ZOOZ5QWMEKMORLC4QIXUX4Q",
              "promotitle" : "Buy one get one free YOO!",
              "redemptionlimit" : "10",
              "discount_id" : "78JD8R3BSSFDN21QB23HPWWD9F",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tsu4hkBo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:29:22.148Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5KYxcHxo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:31.485Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dDwxhN1o" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.736Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rokFv65f" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.119Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8hZNcLTu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.913Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oAvEUpDk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.675Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "E8qKc91d" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.608Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-w4joh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DJYL4WS37ULZB72R6AP54BO2",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "2K7RNZ3151V34RX644NMT2FVVX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aYDXSm9B" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.039Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fvsGFTS3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.696Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tbmmv5Ku" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.719Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xmtZA9MW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.192Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aA1VmzU2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.656Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cG9AskNP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.659Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kUfywGjF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.405Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aW57UyTH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.730Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vV4vpCTn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.508Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5tAtGF4L" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.562Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5VvUEoZC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.043Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8JgACywd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.651Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9dgscpw4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:41.260Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aF1aNCoA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.863Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5U2zzKN4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.717Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fGdkPSfq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.318Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "royz6g23" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:08.507Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3SitGRf8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.395Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cex4pLM6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.667Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "otTPsntZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.661Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "P4xq7ULW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:48.187Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6YkaQ6Se" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:16:07.806Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jfjHSnMc" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:19:57.142Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uM91povm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.693Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3AnR8hW1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.518Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8duSGGHw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.693Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "RzyzvBAC" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:33:53.757Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dPWjb1PV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.693Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "n5CYX33C" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.568Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rAKrbHtF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.896Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6LAqmFVB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.691Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3YAVBoqC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.908Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hhpk68K5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:34:43.575Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "v4TjaAMr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.955Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "exqz9XdS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:20.383Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "v745wmEK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:50:39.792Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "d7j3Zxi7" : {
            "pathname" : "\/redemptions",
            "time" : "2024-04-21T00:25:53.253Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/21\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promoId" : "kali-cH9SJ",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-22-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cH9SJ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "11YFSTAFW17DCJA48655GE4GZ6",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "L4ZQHJONOMQH37ROMRWQM6JU",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "INKX62DYGAQ4M3U5PZEBJCTQ",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ksjLM7Jb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.226Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9nzwmHq6" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:33:53.753Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iAxnzbQt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.726Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9J4i2oV3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.704Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "v7wXJEpH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.326Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dU8mTsDr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.747Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gCiSLqfS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:29.187Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jn4ivdhh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:34.227Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7REQWnB9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-25T23:22:10.456Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-27-2024",
              "discount_version" : "1714087328770",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "ADD ONE",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087329540",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pctdy",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "37RT95TN5VJ0TVW24JDET8ZGR4",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "SMM7HSBILDKN2RNTUUT67ZSZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "PFL5QMIMAJRJA4NJOJTQ7QNK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "r54xwZ29" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.938Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "s3wXWzds" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.413Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3uPdjEcK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.729Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "89zFSgid" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.746Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4mzjDJ51" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.720Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fniwGgGo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.566Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7B7LFPyq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:22.298Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "d5RxPbfM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.556Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sogThzE4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.857Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buw",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-waVUX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "C6Y2XBFX4XQRSJRRNOJJORTY",
              "promotitle" : "Buy one get one freewwwffffffff",
              "redemptionlimit" : "10",
              "discount_id" : "M6SGDJNMH19AREZAS4EDCG59R",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9wM1hYac" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:16:07.803Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eWUNXU4r" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.131Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dJrGnE7B" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.669Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "j1H2CoyS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:20.389Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "s2dTugph" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.729Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xg5aoEEr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:42:01.467Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9xMp8A7C" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.887Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pEs5ZioX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.724Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rZrPvH6A" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.576Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nmJHXgDo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.684Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9jdkDj6K" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.198Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rsMrunuh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:04.149Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "noU1C5aC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:55:10.046Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4cdEpXrK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:36.393Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qnFY8Pb2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.754Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6TYJf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "FW75POPSYAFQPSNWBGSLARNQ",
              "promotitle" : "Buy one get one free!yaooddd",
              "redemptionlimit" : "10",
              "discount_id" : "C17GJK7ERQ17EDRD3M62RG3BS",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8w7i53iD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.734Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rdfyZLcL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.851Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wjZ8v33v" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:16:07.808Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jvJKGLw1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.754Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cxTUHvgZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.048Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "i2YLimge" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.618Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "69Cm2nkc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:34:43.552Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sERSu37F" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:24:02.024Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3B2JSss5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.715Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vrnsZwkY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.731Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wPWNxXm6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:16:07.812Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dnyUZnKN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.171Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6sVTjJiX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.828Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hA5V2m83" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.736Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rovz9cGS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.724Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cRoiRqij" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.050Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uQ481Dk7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.690Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dxjkFWK9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.677Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hQN6CTec" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.224Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mWcyxUPY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.691Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2yhXZvpA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:34:43.564Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8Z9A1gQc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:16:13.231Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9APyUMeR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:01:18.874Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8oxgrsf8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.605Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "e7VncpyN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:49.985Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rXHFPgso" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.717Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "byDcmu7v" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.692Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "v8s7UcMe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.681Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "piGUJP1C" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.852Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "53mxb8x5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.831Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nWKL5KzE" : {
            "pathname" : "\/insights",
            "time" : "2024-05-01T00:55:22.302Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoId" : "kali-kekQV",
              "pricing_rules_version" : "1714511718073",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xberjRmg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.197Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "khmotdfP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.716Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rtXmHg1H" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:42:01.472Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gfGvxDbw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-25T22:52:16.808Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-26-2024",
              "discount_version" : "1714085524819",
              "date_created" : "4\/25\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "pricing_rules_version" : "1714085525933",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-mfN7h",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "159539FYEMFWKFG2F4F3Z7SACA",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "XJKVFVCD3MAFCNUSBKVA3EJY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "OYDE3TIFS7JZRCUDQTO3KSJK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "e8BrFLMk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:22.240Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "98xXRL6U" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.693Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dUZqmFWs" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:31:41.358Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "h6CvSPob" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.844Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-idJPC",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4ZOOZ5QWMEKMORLC4QIXUX4Q",
              "promotitle" : "Buy one get one free YOO!",
              "redemptionlimit" : "10",
              "discount_id" : "78JD8R3BSSFDN21QB23HPWWD9F",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bimMS3TX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:39.268Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7sCZjCoV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.496Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "84ZWnzrB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.572Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eSXD4XNP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.248Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gh21oAGX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.812Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dYeRWr6r" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.563Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eqqrJUL9" : {
            "pathname" : "\/insights",
            "time" : "2024-04-30T23:18:00.953Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-26-2024",
              "discount_version" : "1714085524819",
              "date_created" : "4\/25\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promoId" : "kali-mfN7h",
              "pricing_rules_version" : "1714085525933",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-mfN7h",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "159539FYEMFWKFG2F4F3Z7SACA",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "XJKVFVCD3MAFCNUSBKVA3EJY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "OYDE3TIFS7JZRCUDQTO3KSJK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hFoVjXk4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.131Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "k3tCNHgU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.678Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xgqchzct" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.260Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oHMghK7J" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.829Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hRKBvB94" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.901Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "k1uvYRh3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.675Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "q7v82s8R" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.729Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sNSdv5XE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.084Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "r7SUofRk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.595Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pGbM8WXv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.509Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "i4rDvjDo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.661Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6MWwYGQ9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.916Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bWodPocX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.406Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "x9CKFu54" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:39.263Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "w5eYNvZN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:28.970Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mZxVDWf7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.899Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qQRtxnv5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:34:43.572Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9fFhE6qW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:31:41.355Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7KDtHmVJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:06.193Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "k6xhBdYJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.821Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7D4Nz",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "MY34726D6PRUOOVSEZBP7KTB",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "M5STBYQ7E6XAGCAD80F6TY6ZR",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uyb3aeSF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.686Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tTwuZhJ2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.342Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aWgTES1e" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.566Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dC9vYNXn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.513Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9AQV4dPK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.224Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8gWYRi9S" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.669Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gB8TWzmf" : {
            "pathname" : "\/insights",
            "time" : "2024-05-01T00:55:26.163Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoId" : "kali-hxzr3",
              "pricing_rules_version" : "1714087742664",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "68gqtmK6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.560Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "81kBkT35" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.777Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "89uDvhEy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.761Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cuj5wv99" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.899Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ixYD7rtY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:34:43.557Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qjVPuvnM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:04.154Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5Rfh4Qp8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.691Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3oRT2HYE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.699Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aLvZZQ5L" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.833Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cBFph2Fb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.688Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wf1TC8zb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.577Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gSJFDrpr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.483Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6JEX7tKu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.903Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sKVbpwUZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.677Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8VNVjUGW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.341Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bpjtdx82" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.708Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cMEqkfCd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T02:43:21.986Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dg8sSZV6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.691Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vWimbpYi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.912Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oYCM8guD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.653Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iBtQzAES" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.688Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5YPJGM7A" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.953Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "d9htGpSs" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.393Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "NWuQeJwt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.844Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7D4Nz",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "MY34726D6PRUOOVSEZBP7KTB",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "M5STBYQ7E6XAGCAD80F6TY6ZR",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jgG8HVGm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.605Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pJttnCbd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.503Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "i62mfBSw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:32.819Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7W1QfCpQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:21.695Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9e74692C" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:31:26.249Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "smMWeh8g" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.736Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "32b4j6Sb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:29:22.152Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mxC2872P" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.593Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cUeJe7SY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.989Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oKek5rSB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.003Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xtcHq91k" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.788Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ayYjnLSf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.902Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hvyKwYVC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.864Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "BB9JuC28" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:32:29.518Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6tL6sJjw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.729Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "juhjBepC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.706Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iyr4v58c" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.688Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4mThLyF5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.891Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5LeUkzPz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.175Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "Gus1Ricz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:00:32.468Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "knhvGYcc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.111Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "u6CJJCyW" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:33:53.771Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "v3xw28j4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-27T00:01:45.716Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-27-2024",
              "discount_version" : "1714087328770",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "ADD ONE",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087329540",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pctdy",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "37RT95TN5VJ0TVW24JDET8ZGR4",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "SMM7HSBILDKN2RNTUUT67ZSZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "PFL5QMIMAJRJA4NJOJTQ7QNK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4BjRgrRw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.898Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "f2z77XLN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:41.268Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ogLixADi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.948Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xbQQBwKn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.389Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xyv4TnKF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.157Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oz8RrErR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.242Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qjtJHBjr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.725Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uUo4oEsG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-21T00:06:08.444Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "date_created" : "4\/20\/2024",
              "promomessage" : "Dolor obcaecati nequ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-xvcay77",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2TP8ED36V8N400ACDMQRGDXVW7",
              "promotitle" : "Nulla veniam placea",
              "redemptionlimit" : "8",
              "discount_id" : "XA65HS743S7Y5LDZ76T5ARU5",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "R4PSCDEGMEVMHYIC6L63ZEJD",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sCP3cV4w" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.678Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6tWh2gay" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.112Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3JwXLYtY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.135Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8kDQw1LX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.675Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "r3Zzi67f" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.663Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fNNrpiX4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.525Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6HizzthK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.080Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pWeTcvzj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.923Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2A6mZRA5" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:31:52.617Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8J5upVHC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.871Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "egF5pM3Z" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.022Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "v6f3mfyK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.514Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dJ3CJCkm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:21.693Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tbskwpT3" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:24:02.029Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oWTBNoQW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:16.234Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "MEZwoEPd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:06.569Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oFbTVVs1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.512Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-w4joh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DJYL4WS37ULZB72R6AP54BO2",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "2K7RNZ3151V34RX644NMT2FVVX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jrrNfV7V" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.132Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dFMQofPD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:40:42.086Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5vDiviDV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.256Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mn6L63LJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.768Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aNj11dpb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.415Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sGn4rMDo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.648Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xqx9yfW5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.700Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hKAgUZT5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.120Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8FcfHB1h" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.259Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sW678qfx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.073Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nLF2jWjq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.702Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oeNhn9cA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.656Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "atXJXiaX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.682Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3Y2An5oD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.905Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5HcWQEA5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:48.180Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hbkAPBfY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.935Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jrLXyemP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.927Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-03-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-jLvkU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "LQHDRJJW7KD6PFIJ5RX2OMEC",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "16",
              "discount_id" : "75QPKTRCDXWNPCG2H92XVVJBB",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fzsMPmiQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.518Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m2RnV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "SB3A73LCRHQTTPA2R7OTLS7T",
              "promotitle" : "Buy one get one free testi,ns!",
              "redemptionlimit" : "10",
              "discount_id" : "9V3AS0RBEBA5ZF8GCGRPRZ58V",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iDtMMsCz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.920Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mR3mNHgy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:49.990Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dfW7XzNV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.721Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kytqxdhH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.651Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tKTQ4ZaC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.661Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uvezmJ72" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:34:43.576Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "orRvvkcA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.570Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8qHRsuHV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.893Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8JwtJCyw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.730Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9yRgeYFo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.969Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jWrBPs9L" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.710Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-19-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-h46AF",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7LJTKKZ6JIJWVLWYGZ7XUVAR",
              "promotitle" : "Buy one get one freeaaaasd!",
              "redemptionlimit" : "10",
              "discount_id" : "2YVVCH3T0HW3C5CPMM9BNV5EX3",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ujisrdMB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.683Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9b28oE43" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.657Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eG6a3xGW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.311Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3dZ4M4Vh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.194Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5LAUBVJi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.701Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qA7V9ktD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:04:14.827Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8ZJdTFE8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.503Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8i8dpnMK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.118Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hnPcaCvW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:29.189Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2BvZ9G5w" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:16.245Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pTkJtEbi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.657Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5DvCZ9CE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:40:42.083Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pDVnCCCe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:30.978Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8jUNcraw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.079Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4pD4EF4a" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.819Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "b4CQ1Cjo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.279Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9MjwWxJE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.722Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-oiZHu",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "547WFMTLS5PXLOORF3XZSOFK",
              "promotitle" : "Buy one get one free testing Amount!",
              "redemptionlimit" : "10",
              "discount_id" : "2G2MN6MET28GGM0DPKQHW3EXDP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mnhde1ze" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.924Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qJSgK",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "B5VNZIHJ5J2JAN3ZGBDZUQFH",
              "promotitle" : "Buy one get one free amount Ya bro !",
              "redemptionlimit" : "10",
              "discount_id" : "63RWVQZNN2NBKFQFK4946QZVJB",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5AEmzHYy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.676Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vRSR8VrR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.700Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "59tNueM4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.838Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "b69VJFDn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.716Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-03-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-jLvkU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "LQHDRJJW7KD6PFIJ5RX2OMEC",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "16",
              "discount_id" : "75QPKTRCDXWNPCG2H92XVVJBB",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "w4g1aWcH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.251Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2HrzkhM2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.727Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "x2y9VbmM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.690Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "KWwo5XNC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:55:10.048Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9MgVB9zT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.671Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2U5A7Hty" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.585Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fhAfAwDQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:16:07.802Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fFZ1gRMy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.722Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mT3jgRiW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.669Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2L5zsfY7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:34:22.729Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6nokQXbA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:34.227Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "h47Eanro" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.696Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2hVNB2G6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.325Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "myAeQqCL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.760Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "e8HEkzPe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.708Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "scrMe6sH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.664Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rk7PKvXr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.516Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-19-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-h46AF",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7LJTKKZ6JIJWVLWYGZ7XUVAR",
              "promotitle" : "Buy one get one freeaaaasd!",
              "redemptionlimit" : "10",
              "discount_id" : "2YVVCH3T0HW3C5CPMM9BNV5EX3",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "28UyTt39" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.862Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5xWW5kgL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.984Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "h6c9zkwp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.702Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "28p8bWtU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:27.864Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tRUjm7XV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.669Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7etSHugP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.513Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "23AjXcpy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.699Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "v9LSmBpg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.931Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fGcnBHFJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:18:41.812Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oWiCpmXC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.233Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cALPf37N" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.714Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-oiZHu",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "547WFMTLS5PXLOORF3XZSOFK",
              "promotitle" : "Buy one get one free testing Amount!",
              "redemptionlimit" : "10",
              "discount_id" : "2G2MN6MET28GGM0DPKQHW3EXDP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vZpjKa7e" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:27.860Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rDnfmZka" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.912Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "76UDhRup" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.685Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jw2BZoPo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.716Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eLUK42UU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.734Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4xfg9ptz" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:25:25.731Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sribe8QY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:53:22.641Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7GBTzrA6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.621Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7cyDBb6s" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.886Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "akLk2tqt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.071Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buw",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-waVUX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "C6Y2XBFX4XQRSJRRNOJJORTY",
              "promotitle" : "Buy one get one freewwwffffffff",
              "redemptionlimit" : "10",
              "discount_id" : "M6SGDJNMH19AREZAS4EDCG59R",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8kdKLSwX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.705Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tyeP3EZo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:04.162Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aR9MD61t" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.529Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nMsE6YFr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.328Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "muQasrbF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.517Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-idJPC",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4ZOOZ5QWMEKMORLC4QIXUX4Q",
              "promotitle" : "Buy one get one free YOO!",
              "redemptionlimit" : "10",
              "discount_id" : "78JD8R3BSSFDN21QB23HPWWD9F",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tKwo5RL2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.260Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4ZKSbVj9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.889Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mtuG8v5v" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.932Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "23G17gFU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.075Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qnsnPk7t" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.679Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sAvrQgbF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.705Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xjTxfWua" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.898Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "t1Eviqkm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.077Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vv8JcWVD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.666Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "e67zAFX9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:16.633Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "upBqkVAC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.697Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jxnEbo6q" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.668Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mzg3KH2u" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.723Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "d7BKZkCn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:16:07.821Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jSTgFKcX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.944Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qDGYnLcJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.846Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tbbR6mis" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.692Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "n1YiseVx" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T01:28:06.874Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB8",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "02-17-2222",
              "discount_version" : "1714176850699",
              "date_created" : "5\/1\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "testing bro",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714176851775",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "02-17-2222",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cHdVC",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "k2iGP8TT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.667Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "a3G4W7S1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.743Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "px6NEUpG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.421Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9fmcPTVd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.718Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bJrYur4z" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.027Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jHm4KoCX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.167Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buw",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-waVUX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "C6Y2XBFX4XQRSJRRNOJJORTY",
              "promotitle" : "Buy one get one freewwwffffffff",
              "redemptionlimit" : "10",
              "discount_id" : "M6SGDJNMH19AREZAS4EDCG59R",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "g1keUM5p" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.059Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "prFfdZXe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:47:53.103Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "u14SatQN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.746Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8NyWUJAH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.827Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dSES47K3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.133Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ig7KWGqF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.647Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "m6WCFyt2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.134Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rcRhJK6Q" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.701Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9YqhFgpf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:20.385Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dtJMme8J" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.845Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uG4RdV4Q" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.499Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wELebQz8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:27.854Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fsTC3vVX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.804Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "f5Dnmgy1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.587Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kQR3ErZ2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.649Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ACrbLAQb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.273Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3YQTV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5OUAT7Y44KBSRYOB3OUB7NWR",
              "promotitle" : "vvvsdqsds",
              "redemptionlimit" : "10",
              "discount_id" : "7YA7QCWCJ9E5DSG518VE1HQH51",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "stV3KrgF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.275Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "C5dvzFrA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:58.005Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "s4zoHMvc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:06.581Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vaBezift" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:29.180Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vQQxY68A" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:06.564Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aDNT9HSs" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:29:22.143Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wy79kJKo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.676Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8TrD2BJz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.706Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uBM3nwNw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.723Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dkEWQzAh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-27T00:15:41.883Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-27-2024",
              "discount_version" : "1714087328770",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "ADD ONE",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087329540",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pctdy",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "37RT95TN5VJ0TVW24JDET8ZGR4",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "SMM7HSBILDKN2RNTUUT67ZSZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "PFL5QMIMAJRJA4NJOJTQ7QNK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5io1sBgW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.751Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "t4JH2QmB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.681Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vs4RfNMw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.699Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kZb5pkMf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.129Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4VCBy7TR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.318Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kAZsnw4s" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.056Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3KC4ztsP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.981Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nyuKecVv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.209Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aqmRVR2z" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.698Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nJTLJzXJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:01:18.884Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bRpjZB49" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.517Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "r4AcQ745" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:16.233Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2jA5dppM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.694Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "o9R2Gd96" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.210Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aBmmZyFc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.668Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gbG55mSj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:20.394Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uwQwj3Ca" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.650Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iGUscy9c" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.858Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pAokynhV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:16.623Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "deYQjGhm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.707Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "i7fCBn5f" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.817Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7Ky4f8Sf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.653Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oXH3kpCy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.115Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iki4ZNhM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.881Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "x2ipymru" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.670Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vBWCwe38" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.422Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wJzRDuTZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.249Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "irdd86p3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.649Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fG71UVfg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.126Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "g7Na8Zzc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:04.153Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gYbV9fQa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.723Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eZDSnKF3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.272Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oHAUkHjf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.719Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6KjPjnmA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.051Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cm4RHRnB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.709Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dHDZR5rX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.655Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mFr1GKNy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.753Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kEjfFvqU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.736Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xhogbNZc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.703Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "t4MZBdEB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.706Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6ENyFz85" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.684Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gY6PeDEQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.648Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jV7ivgRS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.401Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9fCA9Hez" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.729Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "D7BmnHmk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.694Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "udy2GmC6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.074Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3YQTV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5OUAT7Y44KBSRYOB3OUB7NWR",
              "promotitle" : "vvvsdqsds",
              "redemptionlimit" : "10",
              "discount_id" : "7YA7QCWCJ9E5DSG518VE1HQH51",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qhx3y6Dm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.400Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "soww5njt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.929Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7dyogV2Y" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.345Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fNeWzr8Q" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.569Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6APCv5Pt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.604Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gAAY3wpD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.711Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xftrtRnH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.589Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3WSsR8qs" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.679Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kyCsgYHy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.422Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uNADzNGr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:34:43.563Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tsC55A9m" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.723Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aeuQznc3" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:25:16.613Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bcp1XNR3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:48.176Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ejaocH1Z" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.689Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hwnXZ72a" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.684Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "to9dzGVM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.937Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9GYMFq5M" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.163Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mGkjSB5y" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.773Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buw",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-waVUX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "C6Y2XBFX4XQRSJRRNOJJORTY",
              "promotitle" : "Buy one get one freewwwffffffff",
              "redemptionlimit" : "10",
              "discount_id" : "M6SGDJNMH19AREZAS4EDCG59R",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bXNbm3BE" : {
            "pathname" : "\/redemptions",
            "time" : "2024-04-21T00:25:34.542Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/21\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promoId" : "kali-qUFW3",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-22-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUFW3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4A1CBWMXNY2RZPYWE03XJT2FTW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "GPFWKEX4YSYZNE5SRAHZ7O6T",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "O2SZLUHVK7GSQW552VJBYFWP",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "k5paAirT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.343Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rzaMUVbX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:41.268Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dY1ry39h" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:16:07.812Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7zz7EwEw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.707Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9svioN9G" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:41.261Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fqtb6ey5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:27.864Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wnvgrqT1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:53:22.636Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vaEeWWqF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:20.394Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2qapqDpj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.581Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rtUxasBg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.659Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ejQd4gZ5" : {
            "pathname" : "\/redemptions",
            "time" : "2024-04-21T00:10:59.600Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/21\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promoId" : "kali-qUFW3",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-22-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUFW3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4A1CBWMXNY2RZPYWE03XJT2FTW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "GPFWKEX4YSYZNE5SRAHZ7O6T",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "O2SZLUHVK7GSQW552VJBYFWP",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9zSZGWHZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.491Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mnoJnAvE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.658Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tET5zJ5M" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:04:14.830Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "u7sBZGWM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.572Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vJytQZ9k" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.736Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4UDTab3M" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T01:28:06.892Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pEeJuXmc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.158Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2N1N7WRz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.175Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gE74tTZw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.700Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3i86CaaK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.672Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8GKfrQ9o" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.245Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9bM1gTor" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.135Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eCpqNE5D" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.585Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jW4ZoWf7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:34:43.562Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xi4t5MEY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.203Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3F2BfQgE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.613Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rUn3FA9N" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.768Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7D4Nz",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "MY34726D6PRUOOVSEZBP7KTB",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "M5STBYQ7E6XAGCAD80F6TY6ZR",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hoNYUrMu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.659Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9w52uz85" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.519Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "woPSWWES" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.747Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2WxDf3V1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-25T23:22:10.457Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-27-2024",
              "discount_version" : "1714087328770",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "ADD ONE",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087329540",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pctdy",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "37RT95TN5VJ0TVW24JDET8ZGR4",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "SMM7HSBILDKN2RNTUUT67ZSZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "PFL5QMIMAJRJA4NJOJTQ7QNK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "i6zzL4NL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.028Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dyMthKxV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.511Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "78T8bd6o" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:39.263Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oJUPq1UT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:21.688Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "k9LXHUH8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.810Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mrCHMVw5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.655Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rwQdzmXN" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:19:32.522Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xmxKS5Cr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.669Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vmPRHGLU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:04.168Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3QjHwWPP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.732Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "h8526jW7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.005Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7WyEjxRK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.676Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iGpXBQey" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.225Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4zzdKfEk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.689Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "un3VpooH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:58.011Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vo9qUVcz" : {
            "pathname" : "\/insights",
            "time" : "2024-05-01T00:55:22.315Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-27-2024",
              "discount_version" : "1714511902704",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "testing la vi",
              "promoId" : "kali-q6UGw",
              "pricing_rules_version" : "1714511903551",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-q6UGw",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "52KC2275Y31N8TXWYBYSSKCPTK",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "7NJWKVFIX42ZY4NZRCDQX4YZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "FZET4BWMP2YWGI5ASOYUMQJZ",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8YtDCCGg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.611Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8mB7NTiE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.153Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nRtpbvrv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.172Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fSEc5UNR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.715Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3zPm4Gei" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.710Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gKqdGmnx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.677Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oC8ZuSdz" : {
            "pathname" : "\/redemptions",
            "time" : "2024-04-21T00:09:41.246Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/21\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promoId" : "kali-cH9SJ",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-22-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cH9SJ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "11YFSTAFW17DCJA48655GE4GZ6",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "L4ZQHJONOMQH37ROMRWQM6JU",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "INKX62DYGAQ4M3U5PZEBJCTQ",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "897iMtP9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.860Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aoSFkKcW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.720Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jVasoT6c" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.849Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buw",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-waVUX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "C6Y2XBFX4XQRSJRRNOJJORTY",
              "promotitle" : "Buy one get one freewwwffffffff",
              "redemptionlimit" : "10",
              "discount_id" : "M6SGDJNMH19AREZAS4EDCG59R",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uWvBKS8s" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.213Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4xoMN9zz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:30.986Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iKg5fPPY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.698Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iuksPA4u" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:28.958Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mXLJit6k" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.283Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "s8FEeyDH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.711Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mnYDftHR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.900Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iAvmX2PN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.729Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kT3mE5Zz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.766Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4AAxgN17" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.563Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "moDRVR1w" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.222Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3YQTV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5OUAT7Y44KBSRYOB3OUB7NWR",
              "promotitle" : "vvvsdqsds",
              "redemptionlimit" : "10",
              "discount_id" : "7YA7QCWCJ9E5DSG518VE1HQH51",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rgNCGfWo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.680Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4ZyBn4P7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.690Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dUYHxoNS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.593Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "crhfdsBV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.687Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "d4Prmqf6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.938Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8iXvH2rv" : {
            "pathname" : "\/insights",
            "time" : "2024-05-01T00:55:22.309Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-27-2024",
              "discount_version" : "1714087328770",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "ADD ONE",
              "promoId" : "kali-pctdy",
              "pricing_rules_version" : "1714087329540",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pctdy",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "37RT95TN5VJ0TVW24JDET8ZGR4",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "SMM7HSBILDKN2RNTUUT67ZSZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "PFL5QMIMAJRJA4NJOJTQ7QNK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3zGDxUjS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.821Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dtByRHea" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.657Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hPanxPcw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.753Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uNAgTptG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.935Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rZrGhvSJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.707Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6dEcD2hE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.712Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "apHAbpMz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.719Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "reKq1G5F" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:49.994Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8T4o7k8f" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.578Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eb76oyKH" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T00:11:29.952Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "59sUWdnx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.682Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6Czuo41t" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.776Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3YQTV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5OUAT7Y44KBSRYOB3OUB7NWR",
              "promotitle" : "vvvsdqsds",
              "redemptionlimit" : "10",
              "discount_id" : "7YA7QCWCJ9E5DSG518VE1HQH51",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kNtwUKxG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:40:42.077Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "j3x9reob" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.667Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cqAfkBvX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.872Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "erZWmDp9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.082Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "n9Scrhd1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:06.182Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "stoRqqPf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.671Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qQMfmo3Q" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.688Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "83XmuqU6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.104Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kPyemwxE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.811Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qaeqtXfr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.723Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vnGncjoZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.178Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "swguRJTm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.701Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jtqUjeaZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.607Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-24-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!SSS",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-gHrS5",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "RIET7M5255NTOBCC6XMRV6Y3",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5W0NA67DBB5XW21HWJGZ6140V8",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4gXAuJ29" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.708Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bRFQPU44" : {
            "pathname" : "\/kali",
            "time" : "2024-04-30T21:18:25.056Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5DAvmGSf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.721Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "416kC1m6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.146Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rnfaYwyV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.126Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uYNm4BUH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.669Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "o6JC6RYd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:20.384Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kDkPxCXD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.698Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "HjsJCyP2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:50:39.789Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wpzBrKFX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.726Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hgHXBWVM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.421Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fWFxAjT5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.566Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4BiknthA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:46.004Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7Ks7n7M5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.840Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mjKvVtUL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.668Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rvsSM35B" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.693Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9rcb9jiz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.848Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "d6zo73KW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.503Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4j8G127q" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.571Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rr9QPH1T" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:07:15.247Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3qnzKHgf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:16.240Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kok6mdv8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.066Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bPr9txdc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.649Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "een73AKt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.888Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gAkYbhNp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.875Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9P7ChBDT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.666Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fRgXHqFH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.650Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iZq9f11M" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.587Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bm9vYjms" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.324Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "T7TGhfJC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.827Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jL52AWFN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.518Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "we6w9WhQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.708Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gaFY3Bf7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.915Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bXHdq8wi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.859Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dFQE84eP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.706Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jrjJgCzM" : {
            "pathname" : "\/redemptions",
            "time" : "2024-04-21T00:11:14.935Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/21\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promoId" : "kali-cH9SJ",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-22-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cH9SJ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "11YFSTAFW17DCJA48655GE4GZ6",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "L4ZQHJONOMQH37ROMRWQM6JU",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "INKX62DYGAQ4M3U5PZEBJCTQ",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bZ9JeMky" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:32.828Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nKvvwpqU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:29.186Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4DaPdQnc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.704Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "e7YFpmhd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.586Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eepz2KPQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.664Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "avu1pVLt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.718Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sHSnEyMb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.169Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "613qMQ5p" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:20:37.236Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2iFyHV1q" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.695Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6HfV6Bv4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.672Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nsKwdCx4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.579Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ruFseYGD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:40:42.080Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9nopi6ER" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.772Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-03-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-jLvkU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "LQHDRJJW7KD6PFIJ5RX2OMEC",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "16",
              "discount_id" : "75QPKTRCDXWNPCG2H92XVVJBB",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uvD9uJS1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.741Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bHMZ9Uh9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:29:22.141Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "geuGMAnj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.568Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bgJAzm8R" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.203Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7eqLfoew" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.704Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wZ7ZL2vD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.617Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uATBuYLv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.699Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jAq6AV8T" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:34:22.724Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rJDBEQZv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.979Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6sNc8cwj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.739Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jc9LANHe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.746Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9ndPV3sC" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:21:56.448Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "roScdQFR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:32.818Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2wTLNzeD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.738Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eg7GPxve" : {
            "pathname" : "\/kali",
            "time" : "2024-04-21T00:07:56.013Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/21\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-22-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUFW3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4A1CBWMXNY2RZPYWE03XJT2FTW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "GPFWKEX4YSYZNE5SRAHZ7O6T",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "O2SZLUHVK7GSQW552VJBYFWP",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wczFh6Zv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.931Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6DKN1hro" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:34.218Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9zeaFbG9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.692Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3KZgnUqe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.671Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8imv97d6" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T00:10:23.284Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5bRetCwb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.907Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7PVWX9RF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:31.484Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2Dy5PyNe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.574Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "stU7chUc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.694Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7uvA4fDu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.674Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oyUP2kkB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.515Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nsMmvPv1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.573Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rWKuhrqf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.649Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "59cpfZmx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.682Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mj22gssn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.148Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ctu6kSq2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:01:18.862Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5yJ7E8zX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.167Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hh75PBHF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.875Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qtQP1NK4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.220Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3YQTV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5OUAT7Y44KBSRYOB3OUB7NWR",
              "promotitle" : "vvvsdqsds",
              "redemptionlimit" : "10",
              "discount_id" : "7YA7QCWCJ9E5DSG518VE1HQH51",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vskkqwSn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.654Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xqoToRZv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:00:32.461Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8e5AkPtL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.715Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2WPgEHtx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.759Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "h3iNbnW8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.706Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "esSd1rRh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.709Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fWkXUTyi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.136Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jTsV3KCS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.724Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cXDmaBCo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:34.215Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jAduAvSk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.921Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "77129uzV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.702Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2Yxi4MBK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:50:39.794Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kbDJH56g" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.015Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2VbNEFge" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:32.816Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gAH2yi9i" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.693Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mNgNqnVG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:28.958Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sU5wqnKT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:07:15.245Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dsikWq7A" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.697Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "idLQnghC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.721Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qd6iUViq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.717Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qkQTxKJT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.896Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "x7f7TJdK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.664Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fysbPnRj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.975Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xbDccrK8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:16.233Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nssP7iD2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.852Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nqky4421" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.612Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iE9GGrHb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.891Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kRzTN5Ri" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.664Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oSbzKKZv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.723Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7D4Nz",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "MY34726D6PRUOOVSEZBP7KTB",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "M5STBYQ7E6XAGCAD80F6TY6ZR",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "u83YNxAy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.040Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "38MSSf5B" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.698Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "i8VGzvB2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.714Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7D4Nz",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "MY34726D6PRUOOVSEZBP7KTB",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "M5STBYQ7E6XAGCAD80F6TY6ZR",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wDmn6gg8" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:33:53.766Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB8",
                "imagename" : "cookie.png",
                "promotype" : "2-gradient",
                "primarycolor" : "423F32"
              },
              "status" : "ACTIVE",
              "dateend" : "02-17-2222",
              "discount_version" : "1714176850699",
              "date_created" : "5\/1\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!32024",
              "campaign_name" : "testing bro",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714176851775",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "02-17-2222",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5vYG1",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
              "promotitle" : "Buy one get one free!!!!",
              "redemptionlimit" : "10",
              "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8piJjFbv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.505Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "g4tt2QeW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.678Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sCqT55NP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.192Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wUN26i1v" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.679Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2fBkfKuv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.757Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "w1ihLB8R" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.908Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cL9xo7Yj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:30.978Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "b6vsQCGJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.042Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2jrkscVE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.754Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jhtv1i7C" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.404Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8B4URrfu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.036Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3YQTV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5OUAT7Y44KBSRYOB3OUB7NWR",
              "promotitle" : "vvvsdqsds",
              "redemptionlimit" : "10",
              "discount_id" : "7YA7QCWCJ9E5DSG518VE1HQH51",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vvhEifRm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.660Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eC8eCjW7" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:24:02.011Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oXYqPDeC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.651Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dJm8wGLu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.563Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4DEqV41L" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.914Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qZ4oEv16" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.701Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uJ5cXX7m" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.720Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "i9vLE5HD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:06.185Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hT388cuQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.682Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3XGBpuBW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.745Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hSfMqRvm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.726Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "v4x2pW8g" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.337Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "p29C5ckC" : {
            "pathname" : "\/redemptions",
            "time" : "2024-04-21T00:10:14.295Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "date_created" : "4\/20\/2024",
              "promomessage" : "Dolor obcaecati nequ",
              "promoshowterms" : "YES",
              "promoId" : "kali-xvcay77",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-xvcay77",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2TP8ED36V8N400ACDMQRGDXVW7",
              "promotitle" : "Nulla veniam placea",
              "redemptionlimit" : "8",
              "discount_id" : "XA65HS743S7Y5LDZ76T5ARU5",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "R4PSCDEGMEVMHYIC6L63ZEJD",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uTD8Aez6" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:25:25.733Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bHVNbSsc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.880Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9zJiRozd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.076Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hmU9eYxK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:47:53.090Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tWnhTjvo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.073Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cW55mV6N" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:55:10.050Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "khLNWZEX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:34:43.573Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wnpfb8P1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.078Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "d479YitS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.737Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "n7etczt6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:06.188Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fZ8v5cDt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.725Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5Rr473g8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.237Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ebH8NLH6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:40:42.087Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sUMHSYzR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:29.178Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fFWcfjRT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.692Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gvCUyYP8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.723Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tLdTRD3D" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.161Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tpw1a7PF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.191Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pK85w2us" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:22.291Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dYpJ1CTM" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:19:51.979Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "t37LaMHt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.661Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "d7dWmgRr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.744Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ejaQveQJ" : {
            "pathname" : "\/insights",
            "time" : "2024-05-28T22:41:59.785Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB8",
                "imagename" : "cookie.png",
                "promotype" : "2-gradient",
                "primarycolor" : "423F32"
              },
              "status" : "ACTIVE",
              "dateend" : "02-17-2222",
              "discount_version" : "1714176850699",
              "date_created" : "5\/1\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!32024",
              "campaign_name" : "testing bro",
              "promoId" : "kali-wb8h8",
              "pricing_rules_version" : "1714176851775",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "02-17-2222",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5vYG1",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
              "promotitle" : "Buy one get one free!!!!",
              "redemptionlimit" : "10",
              "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "f1roR5e3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:40:42.089Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "q4igRgEH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.756Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-idJPC",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4ZOOZ5QWMEKMORLC4QIXUX4Q",
              "promotitle" : "Buy one get one free YOO!",
              "redemptionlimit" : "10",
              "discount_id" : "78JD8R3BSSFDN21QB23HPWWD9F",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kqZPuWDb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.052Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "n97WQC1w" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.145Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8Kj9Kq3N" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.681Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tACFFZxt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:30:07.954Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "j2Sj72EK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.988Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xgCfbWn2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:06.187Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wM35nzVL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.874Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "35SADBpD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.677Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "crbwppoq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.671Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hMN2meEQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.917Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-26-2024",
              "date_created" : "4\/17\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m5Npf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3SHPB09ZRG49Y3P5NG9WFMNXVT",
              "promotitle" : "Buy one get one free edting 1!",
              "redemptionlimit" : "10",
              "discount_id" : "5VUWUQNVKZNZ2NHSNTO5VGRV",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wxsmgEZ6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.706Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4xTEXvdR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:45.994Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hv8EDsjU" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:24:02.010Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2dE2mPZS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.169Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3YQTV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5OUAT7Y44KBSRYOB3OUB7NWR",
              "promotitle" : "vvvsdqsds",
              "redemptionlimit" : "10",
              "discount_id" : "7YA7QCWCJ9E5DSG518VE1HQH51",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "reTtWzmg" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:20:37.238Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hRFgFDfm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:32:29.521Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6N65UnDm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.172Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "d5LLfUqg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.146Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iW5QfKZW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.827Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jjhYPsdz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.924Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "41QQyANQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:55:10.057Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qHRAZh61" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.069Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rEVe9nFD" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:23:15.257Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bYHQw7uV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.707Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vzSR6g45" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.147Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ea9qmkbW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:30.980Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "x15VUdCP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:34.220Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "i6wjcDZ5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.493Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pHfZB5w7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.331Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "28pssmyk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.725Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iaLcJbqK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.936Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6ayxBd87" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.414Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ijSyKnHs" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:16:07.810Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gDLG3Bvw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.671Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mWE5PiuL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.546Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pfmVqhFe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.938Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ej5GUDH8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:07:15.251Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "s7wHFf6Z" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.554Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9REfj5qp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.574Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ipoQRwfs" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.158Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5hToE1xn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.007Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sL68hMJG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:50:39.788Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aPArcS65" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.263Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9xc6XM1p" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.705Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uoK45S22" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:34:43.551Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "obKwo1UV" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:31:26.256Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "shAnCLoG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.720Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rDPBsoTj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:32:29.522Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "m5teUV8q" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.324Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "89k8XWD5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-27T00:15:41.879Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3gEZm9xM" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T01:25:49.904Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rAF6PjMM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.477Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qxRVZtb1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-30T21:18:25.057Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hwUc2j75" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.683Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bF7qf5fv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.848Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-w4joh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DJYL4WS37ULZB72R6AP54BO2",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "2K7RNZ3151V34RX644NMT2FVVX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wrX9EfEv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.014Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6hkDU7UW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:29:22.146Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "h1cdtCkN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:41.265Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vt2MhaE1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:28.960Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hjWxqBAL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.509Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8DhWMqB7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.709Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-26-2024",
              "date_created" : "4\/17\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m5Npf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3SHPB09ZRG49Y3P5NG9WFMNXVT",
              "promotitle" : "Buy one get one free edting 1!",
              "redemptionlimit" : "10",
              "discount_id" : "5VUWUQNVKZNZ2NHSNTO5VGRV",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dKygZpmC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.601Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "847xgsQa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.722Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6KKRhBYV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.568Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "figG2Kte" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.476Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sBBS9XBt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:34.220Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "u5JPD7ka" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.774Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7vxi64qK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.242Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hQV1YLay" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:08.504Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jZsNKFUt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.654Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5vuWbcRx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:04.170Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2dLXGao9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.721Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rPsk82c6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.232Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jYkf81NB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.716Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-w4joh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DJYL4WS37ULZB72R6AP54BO2",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "2K7RNZ3151V34RX644NMT2FVVX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "s6eajuga" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.336Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bdpvsNFL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.510Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dxdiyuTS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.724Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iN5tJiTj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.711Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sA1EBgak" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.611Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4Hpdoqqt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.816Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ekh9QSYD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.908Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ai3padBC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.017Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9mRq5RN7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.576Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pRbkKpeY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.514Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pYNjDFSX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.515Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gZCuwVvv" : {
            "pathname" : "\/redemptions",
            "time" : "2024-04-21T00:09:51.763Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/21\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promoId" : "kali-cH9SJ",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-22-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cH9SJ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "11YFSTAFW17DCJA48655GE4GZ6",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "L4ZQHJONOMQH37ROMRWQM6JU",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "INKX62DYGAQ4M3U5PZEBJCTQ",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rqEA4x3x" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.875Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wTAUh9Rf" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T01:26:32.708Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9fFyYcDz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.753Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sf1Mqkfx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:31.486Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8ReD5pW9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.684Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "DUiLdNbp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.707Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sAzHU34F" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.065Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "m8qS4dSx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.604Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gccTDkJR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:46.000Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pTsFjrBZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:16:07.805Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "voH3zANK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:01:18.872Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "EVUnieV1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.666Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bdGTq9gT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.787Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ece2zL65" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:49.986Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4R7eibus" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:34.225Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mXTByKa9" : {
            "pathname" : "\/redemptions",
            "time" : "2024-04-21T00:25:34.543Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "date_created" : "4\/20\/2024",
              "promomessage" : "Dolor obcaecati nequ",
              "promoshowterms" : "YES",
              "promoId" : "kali-xvcay77",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-xvcay77",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2TP8ED36V8N400ACDMQRGDXVW7",
              "promotitle" : "Nulla veniam placea",
              "redemptionlimit" : "8",
              "discount_id" : "XA65HS743S7Y5LDZ76T5ARU5",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "R4PSCDEGMEVMHYIC6L63ZEJD",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fvZ8FdUK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-25T23:29:03.859Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-27-2024",
              "discount_version" : "1714087328770",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "ADD ONE",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087329540",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pctdy",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "37RT95TN5VJ0TVW24JDET8ZGR4",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "SMM7HSBILDKN2RNTUUT67ZSZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "PFL5QMIMAJRJA4NJOJTQ7QNK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4bAfPsrG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.125Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2WnRY8N8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.729Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "h1JKV2AG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:29:22.153Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nNZUhudA" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:21:56.493Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "v78qoSjV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.549Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "i8MvaNpy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:40:42.085Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mXBhLjPA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.488Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tVNuUuKQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.405Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oaoESNJ6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.711Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eadjm3Sn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.727Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5Kg14G3N" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.703Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5d77UcN6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.672Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fGpMRVRj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:27.858Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oMiWqvEz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.671Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pku9Bk5J" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.884Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uvDKp4Nq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.667Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vD9SFR8P" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:01:18.879Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3v4RMM5r" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.195Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "imAZL8Dh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.606Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5db8ZSDj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:28.970Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tHiSJ3mV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.345Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7XKZKXmh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.825Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7CWeQzN2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.579Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "u2Z8TVRE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.268Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6Bvn7DFa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.026Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oxZcRqPL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.320Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4kVjgGPc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:34:43.579Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jtvxnk3e" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.918Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vJKQWthD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:08.512Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aWjHzMLh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.258Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oJ67KmJK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:28.971Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gK8ii1Go" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.869Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "q8Fgpf9g" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:18:41.814Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rAD1RHKo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.668Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rLt8CXDh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.514Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3YQTV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5OUAT7Y44KBSRYOB3OUB7NWR",
              "promotitle" : "vvvsdqsds",
              "redemptionlimit" : "10",
              "discount_id" : "7YA7QCWCJ9E5DSG518VE1HQH51",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wtKviHtN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.688Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3sBcsP26" : {
            "pathname" : "\/kali",
            "time" : "2024-04-27T00:15:41.870Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-27-2024",
              "discount_version" : "1714087328770",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "ADD ONE",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087329540",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pctdy",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "37RT95TN5VJ0TVW24JDET8ZGR4",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "SMM7HSBILDKN2RNTUUT67ZSZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "PFL5QMIMAJRJA4NJOJTQ7QNK",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "51b9xnin" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.662Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2TyNmfGZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.133Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7T6u715t" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.736Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "h3Fmoh9w" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.688Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rgzST96N" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:36.402Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iW8K9Brj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:07:15.245Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ndD4fEXG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.123Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "b9B95xmd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.676Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dAg9zGbu" : {
            "pathname" : "\/insights",
            "time" : "2024-05-01T00:55:22.318Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB8",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "02-17-2222",
              "discount_version" : "1714176850699",
              "date_created" : "5\/1\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "testing bro",
              "promoId" : "kali-wb8h8",
              "pricing_rules_version" : "1714176851775",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "02-17-2222",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-iu9xG",
              "promoterms" : "Offerd valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ra5zhgPu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.709Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qGGL6bpG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.219Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tPHdyhMd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.679Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "imiKFLE2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:16.244Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "erY2sfAq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.700Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aJg6KoGq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:16:07.815Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oZzGysMg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:39.259Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ru5GfBEh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.168Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3YQTV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5OUAT7Y44KBSRYOB3OUB7NWR",
              "promotitle" : "vvvsdqsds",
              "redemptionlimit" : "10",
              "discount_id" : "7YA7QCWCJ9E5DSG518VE1HQH51",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kg953h2v" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.914Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9a4CtR9s" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.671Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9uhiH5EW" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:25:16.609Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mH2nkUBT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:31:41.357Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "k1r6Wj4Z" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.071Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iLngKYjb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.031Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4Qa4wek8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:42:01.476Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8Z3gD8x8" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T01:25:49.901Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9YFreuw1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:34:22.722Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eQvQpP5c" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:29.185Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uwLvNXHz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.561Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gijUMw17" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.573Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iLNYA9A1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.709Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-20-2024",
              "date_created" : "4\/17\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-keND5",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "52VAVJ3305QSG773RTRFTCP9JE",
              "promotitle" : "Buy one get one free edting !",
              "redemptionlimit" : "10",
              "discount_id" : "W2D4N3MDFEXC2OKW2XSS7CFM",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pbTh2dzg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.814Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "42s21bSW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.666Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "z72XT7cH" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:25:25.749Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "XDkbCiNZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.266Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rCihaB6d" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.714Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ayMCAxNk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.730Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aAcyzEHa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.555Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9kqX4uJp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:40:42.076Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qtLn5xeR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.499Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ocAe9x5J" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.660Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "frAPkPKb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.730Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cdDuuGc9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.749Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ryKggjXL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:49.984Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eH11aUxq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.697Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rmpzU7ST" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.681Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "coETAwCA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.924Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qJSgK",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "B5VNZIHJ5J2JAN3ZGBDZUQFH",
              "promotitle" : "Buy one get one free amount Ya bro !",
              "redemptionlimit" : "10",
              "discount_id" : "63RWVQZNN2NBKFQFK4946QZVJB",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2LBNxRBE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.688Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fCYESChS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.722Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "v5EYo3i2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.680Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gNNfK46F" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.711Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-idJPC",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4ZOOZ5QWMEKMORLC4QIXUX4Q",
              "promotitle" : "Buy one get one free YOO!",
              "redemptionlimit" : "10",
              "discount_id" : "78JD8R3BSSFDN21QB23HPWWD9F",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "DjDR7hmh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:04.151Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oY6TjDa3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.567Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mZrKE3Q9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.501Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ooMMZdCX" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:25:25.743Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rZguAdau" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.597Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iNAXMis8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:16.619Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rnQWqkgN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:34.216Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "s6P7qMGV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:00:32.465Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5ubQJhmC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.504Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7naP6hwT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:20.396Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8i5YGDKF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.690Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kp79dLLV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.757Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3ZHfqTbN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.010Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dL2WEz5j" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.511Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "f9ZaH6wv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.600Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "emA4EVXt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.674Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dUoY5nfS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.757Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "k8ws6ugY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.664Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bdvihVPk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.551Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "35wJyCWv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.607Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-03-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-jLvkU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "LQHDRJJW7KD6PFIJ5RX2OMEC",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "16",
              "discount_id" : "75QPKTRCDXWNPCG2H92XVVJBB",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7fn4pnqR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.499Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aMwUiXsu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:48.180Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xAS1KbUs" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:41.271Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xqg5RAdy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.709Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9uf8bXvV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.043Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "n8numMN9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:16.235Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uyd4xJix" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.667Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "n7wLEwd7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:50:39.799Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wmKzNSLx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.682Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hhi9vR5E" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:16:13.234Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7aF3ReiG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.987Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5k8ckdx4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.696Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "Rbgy5BFU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.662Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aqQoWNGK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-25T23:29:03.846Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bhSANbSY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.603Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cMQFGTmk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:20.398Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kHauNFAW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.263Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "m4LYx5VM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:21.698Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "niTDMv6x" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.839Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vUixDDLf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.756Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ifdRMEMg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.923Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-oiZHu",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "547WFMTLS5PXLOORF3XZSOFK",
              "promotitle" : "Buy one get one free testing Amount!",
              "redemptionlimit" : "10",
              "discount_id" : "2G2MN6MET28GGM0DPKQHW3EXDP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kRaKMCMA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.049Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6aRjB2jx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.143Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8Jr7y9xD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.866Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5oncPdtc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.653Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qxG1R8Za" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.727Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3XFeFfbC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.522Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "c4detVJR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.708Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "n1CeGLDP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.711Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "siL3RVFo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.252Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "228BwKkq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.941Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "v5z9BKtR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.526Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "v741rQjX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.727Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mvyBzZ9y" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.672Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hgtfYtex" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.240Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jo9FTPGm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.647Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5ZCD861W" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:27.863Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4werouqb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.686Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dRUveXjY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.726Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3FKgqAB5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.732Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qVrhwzjv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:06.578Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qykhX4PC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.165Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rGc791CG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.247Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cdEqJG3L" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.616Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dL4FLq8J" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:34:43.570Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wzv17nSA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.920Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "78G5xXv2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.109Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9F9Vzx4d" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.704Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xe7crsyC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.707Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mAwUhGUQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.705Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "toPY7FP2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.668Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mKRLWjgg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.734Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pyW4Rnpi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.720Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eYUMt8nR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.659Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5nxWJpLn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-21T00:07:43.555Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/21\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-22-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUFW3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4A1CBWMXNY2RZPYWE03XJT2FTW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "GPFWKEX4YSYZNE5SRAHZ7O6T",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "O2SZLUHVK7GSQW552VJBYFWP",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9Abu69Yu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.914Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uSeCZaW5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:22.301Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3BDWgL44" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:33:53.768Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3nwbbqhH" : {
            "pathname" : "\/insights",
            "time" : "2024-06-25T23:42:43.973Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "discount_details" : {
                "value" : "20",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB8",
                "imagename" : "cookie.png",
                "promotype" : "2-gradient",
                "primarycolor" : "423F32"
              },
              "status" : "ACTIVE",
              "dateend" : "02-17-2222",
              "discount_version" : "1714176850699",
              "date_created" : "5\/1\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!32024",
              "campaign_name" : "testing bro",
              "promoId" : "kali-wb8h8",
              "pricing_rules_version" : "1714176851775",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "02-17-2222",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5vYG1",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2YET53VN8YF30GYCKEDY3968X",
              "promotitle" : "Buy one get one free!!!!",
              "redemptionlimit" : "10",
              "discount_id" : "5LKVMHMMQF2OD5XUPWH2UXPC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "Q4XPNEHL6OJORSMTN5NXUSEW",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/125.0.0.0 Safari\/537.36"
          },
          "aCnaNf9J" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.742Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aVyJVHuK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.528Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "m28vLpZB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:20.396Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "31YXmYLa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.569Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eagemnz5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:34:43.548Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fw5638Bu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.011Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sZt5PYcu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.694Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sygBD3kH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.710Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ppKSKoMf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.693Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "85hT6NgC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.584Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bzjEn2LM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.160Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gXKiavos" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:32.830Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uhRar3HY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:21.699Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jPnUMppS" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.654Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "s5SsNyJV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.230Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tRQtgfCm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.165Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-w4joh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DJYL4WS37ULZB72R6AP54BO2",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "2K7RNZ3151V34RX644NMT2FVVX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fbgXKMiD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:50:39.805Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nFHBZvJy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.712Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "v49or6ga" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.666Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aL7tpzWL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:32.816Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aFrZbX7X" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.690Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cqSKq3xa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:22:17.409Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eVSAMR7V" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:35:41.498Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fvrVKEXL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.647Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kDPTQHPb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:01:18.890Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "t76UJCKE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.735Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "47kzWgVY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.249Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tTbCc7xP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:42:01.465Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "rNjkfqvF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.732Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "roFGY25G" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T01:26:32.709Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kYPYzmvE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.652Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5pk197t5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:55:10.061Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "c3M4K2VJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:45.991Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hzzQrTz8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.283Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hcUqEQ3g" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:30:07.947Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iNAEAs2v" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.959Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nVUy8ogj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:04.155Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sJsnGKtw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.660Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4C1YpsgD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.613Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qGwHep34" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:16.619Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hDauAX8G" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.706Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pUtVoy6r" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:29:22.151Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gqH1j2qo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.718Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wsdFv1NE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.715Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oHwyUPzB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.250Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "68Mjzh75" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.678Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uqpd4quD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.612Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5ZUjVyGr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.933Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7iaSEZvs" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.846Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-w4joh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DJYL4WS37ULZB72R6AP54BO2",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "2K7RNZ3151V34RX644NMT2FVVX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hpoN3wcp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.822Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-24-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!SSS",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-gHrS5",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "RIET7M5255NTOBCC6XMRV6Y3",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5W0NA67DBB5XW21HWJGZ6140V8",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "u54es8Ed" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.986Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "g4VM5r63" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.067Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uSTf4xyP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.025Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oPXh8fkc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.764Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aCHmmgVR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.851Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4UTymeKq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.696Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8o7E5Ax6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.680Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hAx5v3B8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.149Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pV94ddzi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:06.195Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tkmj6Ah2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:34.226Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cqj9L9Fi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-27T00:14:12.663Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "discount_version" : "1713911382071",
              "date_created" : "4\/20\/2024",
              "promomessage" : "Dolor obcaecati nequ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "pricing_rules_version" : "1713911382886",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-xvay7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2CXV2QYRHS9SPYXQG4QYNQJ55P",
              "promotitle" : "Nulla veniam placea",
              "redemptionlimit" : "8",
              "discount_id" : "BNCIFG2G3HVFRIXPCMUKZ36E",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "J2NH5KN454ESRJG6Z3ZV4FMA",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gYnkzPeq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:06.196Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "h85SPQez" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.652Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jFFnNyct" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:47:53.105Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4uHoP8cj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.695Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "62cqQ7sx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:32:29.510Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "igoBYNX4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.479Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jsoow5ns" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.110Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mreSQK3y" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.028Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sCktqnKW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.670Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "366sMJ58" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.687Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fm2fCSTY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.199Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6R34qBkJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:40:42.087Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "52c8Ddqv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.738Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oEFMW4Ac" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:16:07.820Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "adijC8Sx" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.249Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mWmSggj1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.678Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "JKaHjiCx" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:21:56.489Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714511718073",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vcN4ZDDr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.041Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7g4DGqCk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:04.164Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9PdFpo4y" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.867Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fs8go",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XTRSHYGCBNE2FO2USZA64Y33",
              "promotitle" : "Buy one espresso at full price, and get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "28Y5R3EB6744MJT65X6DDCM7J6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oA1YFKjr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.113Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kYaaDNJg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:30.986Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "j83shJcP" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.915Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/20\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-2nmXg",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9uhRo44Z" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:13:16.582Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7LUqS6RQ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.716Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3QxZ3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "J4LJ3S56A52EC2OZYDZAYV46",
              "promotitle" : "Buy one get one free!sssa<wx",
              "redemptionlimit" : "10",
              "discount_id" : "252N3HSVXDWXPYZ52PEVMG3EBP",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iv5fJVFa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:34:22.727Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "o5XpZCRd" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.728Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "odwP6Le3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.734Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "77eVKejc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.919Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-19-2024",
              "date_created" : "4\/15\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-h46AF",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7LJTKKZ6JIJWVLWYGZ7XUVAR",
              "promotitle" : "Buy one get one freeaaaasd!",
              "redemptionlimit" : "10",
              "discount_id" : "2YVVCH3T0HW3C5CPMM9BNV5EX3",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iF1W1GQL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.824Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3YQTV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5OUAT7Y44KBSRYOB3OUB7NWR",
              "promotitle" : "vvvsdqsds",
              "redemptionlimit" : "10",
              "discount_id" : "7YA7QCWCJ9E5DSG518VE1HQH51",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2sSvV9zh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.845Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-03-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-jLvkU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "LQHDRJJW7KD6PFIJ5RX2OMEC",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "16",
              "discount_id" : "75QPKTRCDXWNPCG2H92XVVJBB",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4RVne1Vw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:42:01.477Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mGmBviBC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-25T23:22:10.475Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "discount_version" : "1713911382071",
              "date_created" : "4\/20\/2024",
              "promomessage" : "Dolor obcaecati nequ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "pricing_rules_version" : "1713911382886",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-xvay7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2CXV2QYRHS9SPYXQG4QYNQJ55P",
              "promotitle" : "Nulla veniam placea",
              "redemptionlimit" : "8",
              "discount_id" : "BNCIFG2G3HVFRIXPCMUKZ36E",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "J2NH5KN454ESRJG6Z3ZV4FMA",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "azRLRcK8" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:29.180Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tnj6XY2x" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.682Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sWqipM45" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.705Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cSM2QYb1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.897Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wjxYY7dn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.081Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pVD2TqkH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.717Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3YQTV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5OUAT7Y44KBSRYOB3OUB7NWR",
              "promotitle" : "vvvsdqsds",
              "redemptionlimit" : "10",
              "discount_id" : "7YA7QCWCJ9E5DSG518VE1HQH51",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3a23xZXA" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.728Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "j6ZhPUzu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.860Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3YQTV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5OUAT7Y44KBSRYOB3OUB7NWR",
              "promotitle" : "vvvsdqsds",
              "redemptionlimit" : "10",
              "discount_id" : "7YA7QCWCJ9E5DSG518VE1HQH51",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5ZDcGYX4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:50:39.797Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ecEKYiWE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.703Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8Sd65yDH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.155Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "k5PtWU4C" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:04.150Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "35icBWUy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:27.851Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xmi1fqZH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:49.988Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "t56S3xU4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.668Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hX6bmVgn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.895Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5rYCJr16" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.822Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-w4joh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DJYL4WS37ULZB72R6AP54BO2",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "2K7RNZ3151V34RX644NMT2FVVX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "32eNTr4b" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.773Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buw",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-waVUX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "C6Y2XBFX4XQRSJRRNOJJORTY",
              "promotitle" : "Buy one get one freewwwffffffff",
              "redemptionlimit" : "10",
              "discount_id" : "M6SGDJNMH19AREZAS4EDCG59R",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "47ULQf1k" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.159Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5EVgEyra" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.694Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vTrLXFz7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:06.198Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vEuPBzkD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.704Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2Hkf9Jx6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.838Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aw5YTBZ2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.725Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ob7tQNqZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:30.980Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tiYXkqmy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.904Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uDJa9Fe4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.917Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "38HELPmN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.706Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fNrS3DNJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.646Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "t3YtLQPD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.670Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qdAcLnJC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.144Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "98Hwbiib" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.262Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3HNrnEpg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.177Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sFoN2XRz" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:08.520Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "v6WWvcNq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:27.851Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jE8vcJRt" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.672Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "3p9vWgx5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.723Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2zdPXVit" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.704Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4p8a9GnB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.267Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "n4eFV1mE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.882Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "exXiNdsm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-27T00:15:41.894Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "discount_version" : "1713911382071",
              "date_created" : "4\/20\/2024",
              "promomessage" : "Dolor obcaecati nequ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "pricing_rules_version" : "1713911382886",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-xvay7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2CXV2QYRHS9SPYXQG4QYNQJ55P",
              "promotitle" : "Nulla veniam placea",
              "redemptionlimit" : "8",
              "discount_id" : "BNCIFG2G3HVFRIXPCMUKZ36E",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "J2NH5KN454ESRJG6Z3ZV4FMA",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ezSEJKND" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.533Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9dbaAcSB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.696Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "f3Yrb6ii" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:30:07.948Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4raNvdmV" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.649Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fp58yGF6" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.720Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "o9jG1AWH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:45.995Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cfnChnWw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:25.767Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3YQTV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5OUAT7Y44KBSRYOB3OUB7NWR",
              "promotitle" : "vvvsdqsds",
              "redemptionlimit" : "10",
              "discount_id" : "7YA7QCWCJ9E5DSG518VE1HQH51",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tP3NMYLi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.685Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oBRzVTPv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:31:41.345Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6fCETZwJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.522Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-24-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!SSS",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-gHrS5",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "RIET7M5255NTOBCC6XMRV6Y3",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5W0NA67DBB5XW21HWJGZ6140V8",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wDi7yGee" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:11:57.968Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bc1P3hx1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.720Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "34LQGYmU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.703Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6W5MpydM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.242Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bzZknVgi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.489Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aZ3Puc6J" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.662Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "a69DCyDD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:32.833Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eAGQzb24" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.712Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tZ8JCrWe" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.750Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4fsStVTo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.667Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "aVqwCm7C" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.715Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-24-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!SSS",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-gHrS5",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "RIET7M5255NTOBCC6XMRV6Y3",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "5W0NA67DBB5XW21HWJGZ6140V8",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6ykRDjQW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.606Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "b3brEKUJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.688Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-d6G9Y",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "DN5PGEJIJFOCGFSZ2TWF6WTH",
              "promotitle" : "Buy one get one free!202404",
              "redemptionlimit" : "20",
              "discount_id" : "61BNGASG1CS7X4Y0VK49Q4SBPY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fApyCnWr" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.246Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eJt5eaW3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.706Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "eCZN7FdZ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.883Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jmYLbXg4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.154Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/14\/2024",
              "promomessage" : "Buw",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-waVUX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "C6Y2XBFX4XQRSJRRNOJJORTY",
              "promotitle" : "Buy one get one freewwwffffffff",
              "redemptionlimit" : "10",
              "discount_id" : "M6SGDJNMH19AREZAS4EDCG59R",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mg1wAhrB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:01:18.867Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iZSzeYnC" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.044Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pQoFE",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5YHNDIDC4DC6TQ4SICTWGLON",
              "promotitle" : "Buy one get one free!2025",
              "redemptionlimit" : "10",
              "discount_id" : "3K848454X8289GFXD65MFFBXYZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2jqrUsqm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:31.488Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "udSiiKTi" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.734Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8sXonbj1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.681Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "o8HbccaF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.281Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "crdmeP6c" : {
            "pathname" : "\/kali",
            "time" : "2024-04-21T00:07:43.560Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-16-2024",
              "date_created" : "4\/20\/2024",
              "promomessage" : "Dolor obcaecati nequ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-xvcay77",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2TP8ED36V8N400ACDMQRGDXVW7",
              "promotitle" : "Nulla veniam placea",
              "redemptionlimit" : "8",
              "discount_id" : "XA65HS743S7Y5LDZ76T5ARU5",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "R4PSCDEGMEVMHYIC6L63ZEJD",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "sa9bAPg2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:06.188Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "56ZpiJon" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.537Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "h8y3kUPU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.717Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7Lpcgja1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:21.690Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dC6mkARy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:14:03.838Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gxeKfZ4E" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:17:31.077Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "r52gEgMq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.942Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nxXc33TU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.144Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "t1bGKTD3" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.248Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ppZ7N6cW" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.709Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dn8ZWciy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.600Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iE2Fh99U" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.334Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-19-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wo3ds",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "F00Y02G1VB2C8Q70DDX1774GB",
              "promotitle" : "Buy one get one free kali!",
              "redemptionlimit" : "6",
              "discount_id" : "4G7LB5RLYXGMUWUHXSLW57XY",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kpEBiERh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.550Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bC8u57Wa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.843Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "u5NMa9d2" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:56:20.510Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "obuyr2na" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T06:34:22.711Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "katEyhzP" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T02:20:37.248Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "jMEM7dPp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.717Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ueQ64os9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.745Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "iy66tyYF" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:14:33.894Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hG7TUUts" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.507Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "toHcbRhv" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.711Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-11-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cnVVe",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4YHXND08H5ZGB59AK8YT6ZAW0Q",
              "promotitle" : "Buy one get one free!cvuiou",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xbQ7CgoB" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:00:20.500Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kaP7TKTo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.488Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mUZxT3xf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.741Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hthb9FwU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:33.706Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "wEXCx5HK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-27T00:15:41.864Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "bW9SvDfu" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.725Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2Qwk1MjX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.887Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "f3ykkhJT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.748Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-bCKA9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "I3URPBACSGXYSZDMT35EXIJJ",
              "promotitle" : "Buy one get one freewpromo",
              "redemptionlimit" : "10",
              "discount_id" : "5282GAZQSEMPY1GZXBS2C58RRZ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "drMnT8Xy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.691Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "xiBTDL6u" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.676Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-23-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-9M5ak",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "KX76XKHMQEXTXY5ZWQL63XQW",
              "promotitle" : "Buy one get one free111!",
              "redemptionlimit" : "10",
              "discount_id" : "1139F1RMXZS6DRQMYYSXZG0FAJ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6hdC42wH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.714Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hduky5d9" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:45.669Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "mmYsx6Ma" : {
            "pathname" : "\/kali",
            "time" : "2024-04-21T00:07:56.010Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/21\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-22-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUFW3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "4A1CBWMXNY2RZPYWE03XJT2FTW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "GPFWKEX4YSYZNE5SRAHZ7O6T",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "O2SZLUHVK7GSQW552VJBYFWP",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "i69gVVhw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:08.519Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "npFFQ28p" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:34:43.568Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-cMJUh",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one freed!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ddPRDJDy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.667Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hKuBthig" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:12:17.909Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "54WmMbo5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:41.260Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cjBsgXwy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:10:56.118Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "idNU5G4x" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.026Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "9HJLezUw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.616Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "i34RHB6h" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.931Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ceQs7bDJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.265Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hSBRVBW1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.677Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dM5LthDD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:04:14.826Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "dbNftB9Y" : {
            "pathname" : "\/kali",
            "time" : "2024-04-09T04:17:32.477Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hA5t7WzD" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.674Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-02-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-12-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-pjb5t",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "e4XX8V4q" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.554Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-23-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-u75BU",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZTHHHRKZRTEPPZ5W5EZ43T7B",
              "promotitle" : "Buy one get one freddde!",
              "redemptionlimit" : "10",
              "discount_id" : "K7GA3T42NXPUKV4S53JTLBI7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nc6aSBY4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-16T15:17:27.716Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pRyBfMpJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:18.676Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "s7RAJ8uU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:20.665Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "exjAEpzR" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:58:46.120Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nNosoHKk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:34:43.554Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fRrA77jL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.716Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "chkLLLes" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:05:24.892Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "n2AKwNd7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:12:26.158Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-13-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-qUUTZ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "1AJDCFSR9SEFCK7BJG3EK5KKNX",
              "promotitle" : "Buy one get one free!ddddr",
              "redemptionlimit" : "10",
              "discount_id" : "LH2RKG6BOPOI4ROCOY77NKDN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "93TPcp8j" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.785Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qXaU2hXN" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.656Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pnQccsHc" : {
            "pathname" : "\/kali",
            "time" : "2024-04-17T19:57:01.698Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-15-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Qui reprehenderit r",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-m1bLm",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5P3ZECW9XKK0DZZVGSN5FW76S0",
              "promotitle" : "Laboris culpa incidu",
              "redemptionlimit" : "86",
              "discount_id" : "A4JOHNIH2DNTHMQVRKPGJBP4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2AfR2dh5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:07:21.559Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oghAsTA1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T21:49:50.258Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dCBFQ",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5JYFBH6JH7PTDMHMG8VAQ9VYPJ",
              "promotitle" : "Buy one get one freefdfdsf!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ecsBdeWo" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:08.518Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ewpUmK3Y" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.652Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "m3UYYdV7" : {
            "pathname" : "\/kali",
            "time" : "2024-04-20T00:23:48.902Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "6V4fB6fY" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:50:39.801Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!qsdqs",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kCw74",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3S0YXYP8NB1ABXA6R6T1EAAN3E",
              "promotitle" : "Buy one get one free!ddddddddd",
              "redemptionlimit" : "8",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "amB5A1Lq" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.822Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "oytcKfaj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:06:15.309Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "d8zosJ3R" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:21.654Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-09-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Quis odit ullam maio",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-19-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-fykVG",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5BWQZAGWKXB6B53CW6F9JXQ8M5",
              "promotitle" : "Molestiae nulla duci",
              "redemptionlimit" : "66",
              "discount_id" : "6VMAWHG6D43D46765JODSI3H",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "32RcB4EK" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.680Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "skCkx22N" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.033Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-wzMWt",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "544RR2RPB0QAKC1EV98SQD9754",
              "promotitle" : "Buy one get one free!dsfsdddf",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uHoGH2dM" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T01:52:08.243Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "szrmvqaG" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.663Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4KN1Jx6P" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T02:03:09.754Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "ohoWSUcH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:06.563Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hMycJ31J" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:50:43.610Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "odzQB7ru" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.013Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cwBvW2gJ" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:42:20.859Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-06-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-3YQTV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5OUAT7Y44KBSRYOB3OUB7NWR",
              "promotitle" : "vvvsdqsds",
              "redemptionlimit" : "10",
              "discount_id" : "7YA7QCWCJ9E5DSG518VE1HQH51",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "kmmJe1jw" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.190Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-07-2024",
              "date_created" : "4\/9\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-26-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-revvf",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2A99FEK96PQ61S8PCN1VJ62M05",
              "promotitle" : "Buy one get one free Virtuel Terminal  2",
              "redemptionlimit" : "10",
              "discount_id" : "K4ICNN4E556YBX2JWK6AKXAF",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "h4Q1B6p4" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:01:50.699Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "u9caEwaL" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:50:39.790Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4EF3qtAj" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T00:50:39.798Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qy7eECfb" : {
            "pathname" : "\/kali",
            "time" : "2024-05-01T01:31:34.499Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "discount_version" : "1714087741843",
              "date_created" : "4\/26\/2024",
              "promomessage" : "Ut nobis omnis tempo",
              "campaign_name" : "Boris Boyle",
              "promoshowterms" : "YES",
              "pricing_rules_version" : "1714087742664",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-hxzr3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "EDH8JGNRNKAKR62Y3885W257W",
              "promotitle" : "Voluptas temporibus ",
              "redemptionlimit" : "12",
              "discount_id" : "SHEMAAXIZID4YLWORFMYQHUX",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "6NHFCB3GNRADMFYBBVX4ILCY",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vA4RvGJy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.663Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8ezSta4k" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:30.977Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-30-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-au7is",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "h3rNyJDU" : {
            "pathname" : "\/insights",
            "time" : "2024-05-01T00:55:22.304Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "discount_details" : {
                "value" : "2",
                "discount_type" : "percentage"
              },
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "simplified",
                "primarycolor" : "EEC801"
              },
              "status" : "ACTIVE",
              "dateend" : "05-20-2024",
              "discount_version" : "1714511717120",
              "date_created" : "4\/30\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "campaign_name" : "Kali",
              "promoId" : "kali-kekQV",
              "pricing_rules_version" : "1714511718073",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-24-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kekQV",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "APEM9H2BGMR4SAVJ07Y3V91FW",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "discount_id" : "NHAMWOBPCW6SMVUM45ZLXYON",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "pricing_rules_id" : "KXZDOOWJAXMEN2CHNKFXA63T",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fcigLzW5" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T01:22:33.591Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-24-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-06-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kwvWL",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "7",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "pxKd1XDg" : {
            "pathname" : "\/kali",
            "time" : "2024-04-14T00:52:49.076Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-18-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-25-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-89KQX",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5B5OQQ4BAXNCUCCXUVPEIHGT",
              "promotitle" : "Buy one get one freesqdqsb!",
              "redemptionlimit" : "10",
              "discount_id" : "1R7C54XW8Z9P0XBCH56J9NJX9Q",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "vJKcZD5X" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.744Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4ER21",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3XYBUABBRRHTZRC3Y4V4RZ66",
              "promotitle" : "Use the fiesslsds sbs",
              "redemptionlimit" : "10",
              "discount_id" : "2JP6HHD2RNNV9VDJMS43QN24PC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "cygskndU" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.254Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "4K6Z29Fn" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.006Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-12-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-7x5X7",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "73Y6RZAMAYCTMCYNPV63E7MSEN",
              "promotitle" : "Buy one get one freeccccccccccccc!s",
              "redemptionlimit" : "10",
              "discount_id" : "BU574SPQWFWPKAPS2QIZS766",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "tgg7kWR1" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:57.009Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "2SHodHdm" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:51:46.855Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "nNC4xWTT" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T20:20:36.828Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "n8EH1xhf" : {
            "pathname" : "\/kali",
            "time" : "2024-04-15T19:52:32.518Z",
            "merchantHandle" : "kali",
            "host" : "cafe.bio",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-21-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nzF58",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "XKQQNJCW6CTK2FLGSA6TRBS5",
              "promotitle" : "Buy one get one freessss!",
              "redemptionlimit" : "10",
              "discount_id" : "6TCJ9RCV91MHVWNBETA9HCBHH7",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "i6BVdi6h" : {
            "pathname" : "\/kali",
            "time" : "2024-04-08T02:14:18.598Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-10-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-nVjqY",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5ZQ4WSM291NGF76VQZP3HYTZ55",
              "promotitle" : "Buy one get one free customer group ",
              "redemptionlimit" : "10",
              "discount_id" : "N7IY6S4AFHZHZHU72YTY4PNC",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "prC8zqsa" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:16.721Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "t5yoiFoy" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.240Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-04-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Sequi et voluptatem ",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-18-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6r3Kk",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "3280EBJ8A33DBAB5KJ94DF1A6A",
              "promotitle" : "Et aut optio et magff",
              "redemptionlimit" : "70",
              "discount_id" : "PQ2G5UZCH6TCS3KNHXC4EZHQ",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "qp66WdrH" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:19:48.929Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-15-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-5DaZA",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "536B6C6W9FH0MH8WP4434RVNMD",
              "promotitle" : "Buy one get one free kali !  ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gPQ1uY7D" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:31.481Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "gfGCXbos" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T06:50:36.665Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-08-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-BGec9",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "7S6KTZR3ZF1PTHZH1JCTRZCZE2",
              "promotitle" : "Buy one get one free helleo ",
              "redemptionlimit" : "7",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "fLuHxkyb" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.231Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-20-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-s4Z9u",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "JJT5JWNSA2JJ6PRCJODJOGCQ",
              "promotitle" : "Buy one get one free!2024",
              "redemptionlimit" : "10",
              "discount_id" : "3Z6WMVS4N6JW955CQEKBFJ3GB6",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "8mpCNuXh" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:21:16.243Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-14-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-13-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-dBVB3",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "7DqeQP76" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:08:06.661Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-r1jnb",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5352PBVPVNADB9GVCWQC1HH0CV",
              "promotitle" : "Buy one get one free uinuqe!",
              "redemptionlimit" : "10",
              "discount_id" : "2PB7I4S327QMSPWHQB4ZOUO4",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "5uKmTaEX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.694Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-17-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-17-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kJv9C",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "420ESN5YT238QDHADPZH74T22A",
              "promotitle" : "Buy one get one freeqsdqs!",
              "redemptionlimit" : "10",
              "discount_id" : "WXN44SR5D6NMYJEVP3GZVUIE",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "uaMeETPp" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T07:06:44.674Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-25-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-16-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-4Tue4",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "5DBRJ8WSY9HX3X5QA31Q6P6H2X",
              "promotitle" : "Buy one get one freeyyy!",
              "redemptionlimit" : "10",
              "discount_id" : 1,
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "czujtiPk" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:11:26.697Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "05-29-2024",
              "date_created" : "4\/8\/2024",
              "promomessage" : "Nostrud quia totam o",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-09-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-rmHcW",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "2MMEN2KZG1VV0QBHYPH42A91VA",
              "promotitle" : "Consequat Qui in il",
              "redemptionlimit" : "85",
              "discount_id" : "4MP3DJQHZ2CHPD6JTZ5YVSKN",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "hoKAGEnX" : {
            "pathname" : "\/kali",
            "time" : "2024-04-11T08:14:19.229Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "06-05-2024",
              "date_created" : "4\/11\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "05-01-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-kbg7q",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "customer_group_id" : "QLSGQI3QZ3BYC2LJVBGDWQDY",
              "promotitle" : "Buy one get one free CustomerGroup!",
              "redemptionlimit" : "10",
              "discount_id" : "1VRCZMRWQYX2G2DC3RVCZ9NRPG",
              "promobuttontext" : "Redeem",
              "squareloyaltyID" : "",
              "timezone" : "",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          },
          "garSoJUE" : {
            "pathname" : "\/kali",
            "time" : "2024-04-06T05:20:39.259Z",
            "merchantHandle" : "kali",
            "host" : "localhost:3000",
            "promo" : {
              "style" : {
                "secondarycolor" : "BB0AB5",
                "imagename" : "espresso.png",
                "promotype" : "single-color",
                "primarycolor" : "FFD600"
              },
              "status" : "ACTIVE",
              "dateend" : "04-18-2024",
              "date_created" : "4\/6\/2024",
              "promomessage" : "Buy one espresso at full price, and get one free!",
              "promoshowterms" : "YES",
              "promotype" : "promotion",
              "square_loyalty_program_id" : "",
              "datetimeend" : "",
              "datestart" : "04-10-2024",
              "merchantname" : "kali",
              "promoimageheader" : "",
              "promoname" : "kali-6mv1k",
              "promoterms" : "Offer valid while supplies last.Terms and conditions apply.",
              "promotitle" : "Buy one get one free!",
              "redemptionlimit" : "10",
              "squareloyaltyID" : "",
              "timezone" : "",
              "promobuttontext" : "Redeem",
              "datetimestart" : ""
            },
            "timezone" : "Africa\/Tunis",
            "page" : "promo-loaded\/",
            "userAgent" : "Mozilla\/5.0 (X11; Linux x86_64) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/122.0.0.0 Safari\/537.36"
          }
        }
      }
    }
  },
  "forthewin" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(747) 215-6876",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/1iAeN7HkFWQsiRh37aCQplVEpKcLAh5q.png",
      "instagramHandle" : "forthewin",
      "merchantHandle" : "forthewin",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "For The Win",
      "city" : "Glendale",
      "companyName" : "For The Win",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.forthewinla.com\/",
      "email" : ""
    }
  },
  "rareteaalameda" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(510) 227-5523",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/da99f4eda7dcd7f7de4102a93fa6b911a1859abe\/original.png",
      "instagramHandle" : "rareteaalameda",
      "twitterHandle" : "",
      "merchantHandle" : "rareteaalameda",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Raretea Alameda",
      "city" : "Oakland",
      "companyName" : "Raretea Alameda",
      "websiteUrl" : "https:\/\/order.snackpass.co\/RareTea-Alameda-642f31edb49cd6ca531e5c78",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "popthebubbly" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 426-5196",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ZgQgcySIMEqv9CFhtIo7MlbxbxkcD8Ww.png",
      "instagramHandle" : "popthebubbly",
      "merchantHandle" : "popthebubbly",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Pop The Bubbly",
      "city" : "Stockton",
      "companyName" : "Pop The Bubbly",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "hungryhowiespizza" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(586) 939-3290",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "hungryhowiespizza",
      "twitterHandle" : "",
      "merchantHandle" : "hungryhowiespizza",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Hungry Howie's Pizza",
      "city" : "Warren",
      "companyName" : "Hungry Howie's Pizza",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.hungryhowies.com\/stores\/mi\/warren\/31912-ryan-road?utm_source=google&utm_medium=organic&utm_campaign=googlebusinessprofile",
      "email" : ""
    }
  },
  "miraclepotionpastrycoffeeshop" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(619) 354-9730",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/98274358049d3753e76e05380520b79277df39f3\/original.png",
      "instagramHandle" : "miraclepotionpastrycoffeeshop",
      "premium" : false,
      "merchantHandle" : "miraclepotionpastrycoffeeshop",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Miracle Potion Pastry & Coffee Shop",
      "city" : "Chula Vista",
      "companyName" : "Miracle Potion Pastry & Coffee Shop",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/mymiraclepotion.com\/",
      "email" : ""
    }
  },
  "bettywinebarbottleshop" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 415-8635",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/0PbuTj5qYqspCZSgzEREXBBlnFE07FPU.png",
      "instagramHandle" : "bettywinebarbottleshop",
      "merchantHandle" : "bettywinebarbottleshop",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Betty Wine Bar & Bottle Shop",
      "city" : "Sacramento",
      "companyName" : "Betty Wine Bar & Bottle Shop",
      "websiteUrl" : "https:\/\/www.betty-wine.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "greesonsalesandmarketing" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(616) 648-4977",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/0SDwM2ZQQZpwPqGtjaLOpFOI3wz20Th3.png",
      "instagramHandle" : "greesonsalesandmarketing",
      "premium" : false,
      "merchantHandle" : "greesonsalesandmarketing",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Greeson Sales and Marketing",
      "city" : "Grand Rapids",
      "companyName" : "Greeson Sales and Marketing",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/greesonsales.com\/",
      "email" : ""
    }
  },
  "gajizadumplins" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(313) 415-9725",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/vn0AdfsL17GIOP0Gj7FDtR0GZvxiGqH2.png",
      "instagramHandle" : "gajizadumplins",
      "merchantHandle" : "gajizadumplins",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Gajiza Dumplins",
      "city" : "Detroit",
      "companyName" : "Gajiza Dumplins",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "emon" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 371-3693",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "emon",
      "premium" : false,
      "merchantHandle" : "emon",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "E⁺ MON",
      "city" : "Oxnard",
      "companyName" : "E⁺ MON",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.eplusmon.com\/",
      "email" : ""
    }
  },
  "gourmetcarousel" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 771-2044",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/6ewZhUE3m0UBHed0vZC71GtzOZu8fz69.png",
      "instagramHandle" : "gourmetcarousel",
      "merchantHandle" : "gourmetcarousel",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Gourmet Carousel",
      "city" : "San Francisco",
      "companyName" : "Gourmet Carousel",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/order.mealkeyway.com\/merchant\/7a546d452b4b4e31755a73665371772f7a39562b6b413d3d\/main",
      "email" : ""
    }
  },
  "rioacaibowls" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(559) 412-7725",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/58b81cf6485578db3b2f538b119778939c4e132d\/original.jpeg",
      "instagramHandle" : "rioacaibowls",
      "twitterHandle" : "",
      "merchantHandle" : "rioacaibowls",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Rio Acai Bowls",
      "city" : "Fresno",
      "companyName" : "Rio Acai Bowls",
      "websiteUrl" : "https:\/\/eatrioacai.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "coffeezaunespresso" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/f548e9dfa2fc0c341172e843cb158ac226cf2154\/original.png",
      "instagramHandle" : "coffeezaunespresso",
      "twitterHandle" : "",
      "merchantHandle" : "coffeezaunespresso",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Coffeezaun Espresso",
      "city" : "Tacoma",
      "companyName" : "Coffeezaun Espresso",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.facebook.com\/Coffeezaun\/",
      "email" : ""
    }
  },
  "tarantulahillbrewingco" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 538-1191",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/588dde310945eaaa6e21880ca95516da8840cf6b\/original.jpeg",
      "instagramHandle" : "tarantulahillbrewingco",
      "merchantHandle" : "tarantulahillbrewingco",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Tarantula Hill Brewing Co.",
      "city" : "Oxnard",
      "companyName" : "Tarantula Hill Brewing Co.",
      "websiteUrl" : "https:\/\/tarantulahillbrewingco.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "theroughdraughtcoffeeandbarroom" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(810) 714-3005",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/1RhxmeJ1N26LD3kWkEt8VTL0WFC7CcNN.png",
      "instagramHandle" : "theroughdraughtcoffeeandbarroom",
      "premium" : false,
      "merchantHandle" : "theroughdraughtcoffeeandbarroom",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Rough Draught Coffee and Barroom",
      "city" : "Sterling Heights",
      "companyName" : "The Rough Draught Coffee and Barroom",
      "websiteUrl" : "http:\/\/roughdraughtbarroom.com\/?utm_source=organic-googlemybusiness&utm_medium=organic&utm_campaign=organic-googlemybusiness",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "watergourmet" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 486-3200",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/enCwEooe3elnyvPopPyuxzKF9b8Bzg2b.png",
      "instagramHandle" : "watergourmet",
      "premium" : false,
      "merchantHandle" : "watergourmet",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Water Gourmet",
      "city" : "Oxnard",
      "companyName" : "Water Gourmet",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "growlmovement" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(971) 273-6533",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/c92d8e20668f442643e4fa190f3fc2685495d5e5\/original.jpeg",
      "instagramHandle" : "growlmovement",
      "premium" : false,
      "merchantHandle" : "growlmovement",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Growl Movement",
      "city" : "Salem",
      "companyName" : "Growl Movement",
      "websiteUrl" : "http:\/\/www.growlmovement.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "anonimopizza" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(916) 382-4248",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/FCRqUfXfuBCW7A4FP1hvOg4adQHEWf4O.png",
      "instagramHandle" : "anonimopizza",
      "merchantHandle" : "anonimopizza",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Anonimo Pizza",
      "city" : "Sacramento",
      "companyName" : "Anonimo Pizza",
      "websiteUrl" : "https:\/\/anonimopizza.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "tipsymcstaggers" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(586) 806-4481",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/U4aQEe7MogIfckzEEuevlirFUys8rNyI.png",
      "instagramHandle" : "tipsymcstaggers",
      "twitterHandle" : "",
      "merchantHandle" : "tipsymcstaggers",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Tipsy McStaggers",
      "city" : "Warren",
      "companyName" : "Tipsy McStaggers",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/tipsyswarren.com\/",
      "email" : ""
    }
  },
  "twistedcorkwinery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(248) 437-9463",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/7bd12b7a36a6d38564d29d64166a60cfe010be13\/original.png",
      "instagramHandle" : "twistedcorkwinery",
      "twitterHandle" : "",
      "merchantHandle" : "twistedcorkwinery",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Twisted Cork Winery",
      "city" : "Ann Arbor",
      "companyName" : "Twisted Cork Winery",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/twistedcorkwinery.com\/",
      "email" : ""
    }
  },
  "porvida" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(619) 564-8568",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/orAdQSX54q0BkSFIXv6YhbUaIotxrab3.png",
      "instagramHandle" : "porvida",
      "premium" : false,
      "merchantHandle" : "porvida",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Por Vida",
      "city" : "Chula Vista",
      "companyName" : "Por Vida",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "zerodegrees" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(541) 515-6147",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/9e0a755034e7e025cd2e0b6c33d925cc8cfd17b8\/original.png",
      "instagramHandle" : "zerodegrees",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "zerodegrees",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Zero Degrees",
      "city" : "Eugene",
      "companyName" : "Zero Degrees",
      "websiteUrl" : "http:\/\/zerodegreescompany.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "teranga" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(415) 879-8372",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/bf8f16c8b062dd3ffe5f13ef6722012ceee1c24e\/original.jpeg",
      "instagramHandle" : "teranga",
      "merchantHandle" : "teranga",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Teranga",
      "city" : "San Francisco",
      "companyName" : "Teranga",
      "websiteUrl" : "http:\/\/www.terangafoods.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "eggersmeatsnorth" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(509) 328-7701",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ZflCDteaIremCzk2gF4f7FDLGMW2T3K4.png",
      "instagramHandle" : "eggersmeatsnorth",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "eggersmeatsnorth",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Eggers Meats North",
      "city" : "Spokane",
      "companyName" : "Eggers Meats North",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/eggersmeatsnorth.com\/",
      "email" : ""
    }
  },
  "rallycoffeeroasters" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/8zPMIR5R2tybzav60pe0p90QXZpI17pz.png",
      "instagramHandle" : "rallycoffeeroasters",
      "merchantHandle" : "rallycoffeeroasters",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Rally Coffee Roasters",
      "city" : "Eugene",
      "companyName" : "Rally Coffee Roasters",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "commelliniestate" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(509) 466-0667",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/qiOSY0qoo2PY5E0GQCEXPyhfC5K0KrsU.png",
      "instagramHandle" : "commelliniestate",
      "premium" : false,
      "merchantHandle" : "commelliniestate",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Commellini Estate",
      "city" : "Spokane",
      "companyName" : "Commellini Estate",
      "websiteUrl" : "https:\/\/www.commellini.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "jackrabbitbrewing" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 873-8659",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/5aae6462898ebdd778476cb8e0e0cbbf0c4f6660\/original.jpeg",
      "instagramHandle" : "jackrabbitbrewing",
      "merchantHandle" : "jackrabbitbrewing",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Jackrabbit Brewing",
      "city" : "Sacramento",
      "companyName" : "Jackrabbit Brewing",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.jackrabbitbrewing.com\/",
      "email" : ""
    }
  },
  "tiscarenoscateringevents" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 693-1030",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/1aca4f6eb31767942ea145b6d49b84536cbdb5f7\/original.jpeg",
      "instagramHandle" : "tiscarenoscateringevents",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "tiscarenoscateringevents",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Tiscareno's Catering & Events",
      "city" : "Anaheim",
      "companyName" : "Tiscareno's Catering & Events",
      "websiteUrl" : "http:\/\/tiscatering.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "elsupertacobasiv" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(253) 243-2174",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/pafCGC4MpaZO0ojqnO1hiyCKSBjKLE2P.png",
      "instagramHandle" : "elsupertacobasiv",
      "merchantHandle" : "elsupertacobasiv",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "El Super Taco Bas IV",
      "city" : "Tacoma",
      "companyName" : "El Super Taco Bas IV",
      "websiteUrl" : "https:\/\/elsupertacobasivwa.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "milkglassmrkt" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(503) 395-4742",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/qFJlzkeaZFKIUTQsupLO4NqEBlgWm2VU.png",
      "instagramHandle" : "milkglassmrkt",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "milkglassmrkt",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Milk Glass Mrkt",
      "city" : "Vancouver",
      "companyName" : "Milk Glass Mrkt",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.milkglassmrkt.com\/",
      "email" : ""
    }
  },
  "adingfernandoscasafkakuyascasakitchen" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(360) 718-2779",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/1mtdN7W7CG1lfhmhg1fQHUIlikY8zaJK.png",
      "instagramHandle" : "adingfernandoscasafkakuyascasakitchen",
      "premium" : false,
      "merchantHandle" : "adingfernandoscasafkakuyascasakitchen",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Ading Fernando’s Casa (fka Kuya's Casa Kitchen)",
      "city" : "Vancouver",
      "companyName" : "Ading Fernando’s Casa (fka Kuya's Casa Kitchen)",
      "websiteUrl" : "http:\/\/adingfernandos.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "medhealtheducationtraining" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(949) 415-7292",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/EeA3QBRaRjMfavuHcSK1Bxtz3owurc9I.png",
      "instagramHandle" : "medhealtheducationtraining",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "medhealtheducationtraining",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Med Health Education & Training",
      "city" : "Santa Ana",
      "companyName" : "Med Health Education & Training",
      "websiteUrl" : "http:\/\/www.medhealtheducation.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sweetsbysam" : {
    "info" : {
      "state" : "MT\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "sweetsbysam",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "sweetsbysam",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sweets by Sam",
      "city" : "Billings",
      "companyName" : "Sweets by Sam",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.sweetsbysammt.com\/",
      "email" : ""
    }
  },
  "bawkbyurbanroots" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 376-7531",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/T6041xEJUMO5Pt7heatqlvl0rmbiqJXz.png",
      "instagramHandle" : "bawkbyurbanroots",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "bawkbyurbanroots",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Bawk! by Urban Roots",
      "city" : "Sacramento",
      "companyName" : "Bawk! by Urban Roots",
      "websiteUrl" : "https:\/\/www.bawkfriedchicken.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "cookiedoughdelight" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(253) 267-0276",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/248bf4fc2707da51d623e60701412d3ab6b4e6b1\/original.png",
      "instagramHandle" : "cookiedoughdelight",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "cookiedoughdelight",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Cookie Dough Delight",
      "city" : "Tacoma",
      "companyName" : "Cookie Dough Delight",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "vegetarianhousevegan" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(408) 292-3798",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/SM9BiBlB4mIrLgIKdBZHIvwx0PhJnvr4.png",
      "instagramHandle" : "vegetarianhousevegan",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "vegetarianhousevegan",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Vegetarian House - Vegan",
      "city" : "San Jose",
      "companyName" : "Vegetarian House - Vegan",
      "websiteUrl" : "http:\/\/www.vegetarianhouse.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sterlingheights" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "sterlingheights",
      "premium" : false,
      "merchantHandle" : "sterlingheights",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Sterling Heights",
      "city" : "Sterling Heights",
      "companyName" : "Sterling Heights",
      "websiteUrl" : "http:\/\/www.sterling-heights.net\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "newheightsnutrition" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(248) 955-9777",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "newheightsnutrition",
      "merchantHandle" : "newheightsnutrition",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "New Heights Nutrition",
      "city" : "Warren",
      "companyName" : "New Heights Nutrition",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/newheightsnutritionmi.wordpress.com\/",
      "email" : ""
    }
  },
  "mawnpawbbq" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(559) 348-9559",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/4fdc04f883e098ec12bbc45a973e366c\/original.jpeg",
      "instagramHandle" : "mawnpawbbq",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "mawnpawbbq",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Maw n' Paw BBQ",
      "city" : "Fresno",
      "companyName" : "Maw n' Paw BBQ",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.mawnpawbbq.com\/",
      "email" : ""
    }
  },
  "vegorama" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(734) 335-3952",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/JTRZ2RflzH8wlfYyIqk0rsD2eNiwN13i.png",
      "instagramHandle" : "vegorama",
      "merchantHandle" : "vegorama",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Veg-O-Rama",
      "city" : "Ann Arbor",
      "companyName" : "Veg-O-Rama",
      "websiteUrl" : "http:\/\/www.veg-o-rama.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "cupcakecove" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(510) 444-4401",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/r3mAf1bTfdi4pAFuMsUvszZuLg37g3nI.png",
      "instagramHandle" : "cupcakecove",
      "twitterHandle" : "",
      "merchantHandle" : "cupcakecove",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Cupcake Cove",
      "city" : "Oakland",
      "companyName" : "Cupcake Cove",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.thecupcakecove.com\/",
      "email" : ""
    }
  },
  "sierranoodlehouse" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 356-1688",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/z3Nnh6002SFwTiEKVhqmk6RUrbNLpRlR.png",
      "instagramHandle" : "sierranoodlehouse",
      "twitterHandle" : "",
      "merchantHandle" : "sierranoodlehouse",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Sierra Noodle House",
      "city" : "Fontana",
      "companyName" : "Sierra Noodle House",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/sierranoodlehouse.com\/",
      "email" : ""
    }
  },
  "bosbagelscoffee" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 587-9171",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/cwBioUtoIHezY2zM0iI7qUXhvDahAXvT.png",
      "instagramHandle" : "bosbagelscoffee",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "bosbagelscoffee",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Bo's Bagels & Coffee",
      "city" : "Huntington Beach",
      "companyName" : "Bo's Bagels & Coffee",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.bosbagelsandcoffee.com\/",
      "email" : ""
    }
  },
  "liondancecafe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(800) 952-5210",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/BBygCKV5J4z8JjsK06Zv8TPX6fOzZh20.png",
      "instagramHandle" : "liondancecafe",
      "merchantHandle" : "liondancecafe",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Lion Dance Cafe",
      "city" : "Oakland",
      "companyName" : "Lion Dance Cafe",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.liondancecafe.com\/",
      "email" : ""
    }
  },
  "otisvilledairydelightandtacohut" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(810) 631-6666",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Xvas68iQanMvIOULH3qc2B2aU0eq7SUf.png",
      "instagramHandle" : "otisvilledairydelightandtacohut",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "otisvilledairydelightandtacohut",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Otisville Dairy Delight and Taco Hut",
      "city" : "Flint",
      "companyName" : "Otisville Dairy Delight and Taco Hut",
      "websiteUrl" : "https:\/\/www.facebook.com\/OtisvilleDairyDelight\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "hailsnail" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(503) 893-2440",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/0crTKMXYX0yhCa2NmEbOya0F3t3SmeX3.png",
      "instagramHandle" : "hailsnail",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "hailsnail",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Hail Snail",
      "city" : "Vancouver",
      "companyName" : "Hail Snail",
      "websiteUrl" : "http:\/\/hailsnailpdx.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "graciesapizza" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(971) 264-1864",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Qm9R90XbAzUFwP03wr2JhH8mDlrkD0ma.png",
      "instagramHandle" : "graciesapizza",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "graciesapizza",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Gracie’s Apizza",
      "city" : "Vancouver",
      "companyName" : "Gracie’s Apizza",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.graciesapizza.com\/",
      "email" : ""
    }
  },
  " Team Building Activities\"" : {
    "info" : {
      "state" : "(714) 632-6999",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "k1speedindoorgokartscorporateeventvenueteambuildingactivities",
      "instagramHandle" : " Corporate Event Venue",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : " Corporate Event Venue",
      "merchantHandle" : " Team Building Activities\"",
      "squareSandboxKey" : " Team Building Activities\"",
      "companyType" : "",
      "merchantName" : "k1speedindoorgokartscorporateeventvenueteambuildingactivities",
      "city" : "https:\/\/k1speed.com\/anaheim-location.html?utm_campaign=gbl_anaheim&utm_source=google&utm_medium=directory",
      "companyName" : "\"K1 Speed - Indoor Go Karts",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "oakharvestkitchen" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 350-8406",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/G9IYNn2i0DSDsF8lpzIMCfxlHJ72wTJ8.png",
      "instagramHandle" : "oakharvestkitchen",
      "merchantHandle" : "oakharvestkitchen",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Oak Harvest Kitchen",
      "city" : "Oakland",
      "companyName" : "Oak Harvest Kitchen",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "popocaoakland" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(510) 457-1724",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/g26rh6AZPF0a6Wa0sDMf7Ln2Mq7wXpmF.png",
      "instagramHandle" : "popocaoakland",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "popocaoakland",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Popoca Oakland",
      "city" : "Oakland",
      "companyName" : "Popoca Oakland",
      "websiteUrl" : "http:\/\/www.popocaoakland.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sweetleilanisdessertsllc" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(248) 697-6220",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/tqWdokCqBQCJNY8mHXbn19JEYX60CyQn.png",
      "instagramHandle" : "sweetleilanisdessertsllc",
      "premium" : false,
      "merchantHandle" : "sweetleilanisdessertsllc",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Sweet Leilani's Desserts LLC",
      "city" : "Ann Arbor",
      "companyName" : "Sweet Leilani's Desserts LLC",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "rbicateringtruck" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(408) 903-6522",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/DE6aGPq3WBTksp4CO6yrElKRBrVwar4H.png",
      "instagramHandle" : "rbicateringtruck",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "rbicateringtruck",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "RBI Catering Truck",
      "city" : "San Jose",
      "companyName" : "RBI Catering Truck",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "6thavenueespresso" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(253) 240-1897",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/8f29217bc2d5d3945fa32d9618bc6e540c10c43b\/original.jpeg",
      "instagramHandle" : "6thavenueespresso",
      "premium" : false,
      "merchantHandle" : "6thavenueespresso",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "6th Avenue Espresso",
      "city" : "Tacoma",
      "companyName" : "6th Avenue Espresso",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "dailykombucha" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(949) 416-3685",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/sFjE7qgOGByeQZnN55sCWhiWNTzPDJUP.png",
      "instagramHandle" : "dailykombucha",
      "twitterHandle" : "",
      "merchantHandle" : "dailykombucha",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Daily Kombucha",
      "city" : "Santa Ana",
      "companyName" : "Daily Kombucha",
      "websiteUrl" : "http:\/\/dailykombucha.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "trippingwithtrey" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(810) 259-3096",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/nlj9mjwJYboSuaoeDgmh29HLIVhTtWMT.png",
      "instagramHandle" : "trippingwithtrey",
      "premium" : false,
      "merchantHandle" : "trippingwithtrey",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Tripping With Trey",
      "city" : "Flint",
      "companyName" : "Tripping With Trey",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.myevolutiontravel.com\/",
      "email" : ""
    }
  },
  "huckleberrysbreakfastlunch" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(657) 208-1804",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/kGM7cmWQ02zTyPgzpDfidXqrpy6Bkakf.png",
      "instagramHandle" : "huckleberrysbreakfastlunch",
      "premium" : false,
      "merchantHandle" : "huckleberrysbreakfastlunch",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "Restaurant ",
      "merchantName" : "Huckleberry's Breakfast & Lunch",
      "city" : "Anaheim",
      "companyName" : "Huckleberry's Breakfast & Lunch",
      "websiteUrl" : "https:\/\/huckleberrys.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "frostedelegancecustomcakesandconfections" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 375-3023",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/lUjqURkw08qty43RwVakH4aesFyS9WL5.png",
      "instagramHandle" : "frostedelegancecustomcakesandconfections",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "frostedelegancecustomcakesandconfections",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Frosted Elegance Custom Cakes and Confections",
      "city" : "Grand Rapids",
      "companyName" : "Frosted Elegance Custom Cakes and Confections",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.frosted-elegance.com\/contact-us",
      "email" : ""
    }
  },
  "hairvibessalon" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 672-8999",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "hairvibessalon",
      "merchantHandle" : "hairvibessalon",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Hair Vibes Salon",
      "city" : "Modesto",
      "companyName" : "Hair Vibes Salon",
      "websiteUrl" : "https:\/\/www.vagaro.com\/hairvibessalon",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "lazybird" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 603-7288",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/9JvZQCP0W57Lr34d3qxlKsGcmeLZNXQ3.png",
      "instagramHandle" : "lazybird",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "lazybird",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "Smoothie Bowls",
      "merchantName" : "Lazy Bird",
      "city" : "Anaheim",
      "companyName" : "Lazy Bird",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.lazybirdbowl.com\/",
      "email" : "BTandgo@gmail.com"
    }
  },
  "tropicalsouthernbbq" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 999-2578",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/3sR3AtvJMaN0Tpf4RifaKSvgH4sj5WBJ.jpeg",
      "instagramHandle" : "tropicalsouthernbbq",
      "merchantHandle" : "tropicalsouthernbbq",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Tropical Southern BBQ",
      "city" : "Moreno Valley",
      "companyName" : "Tropical Southern BBQ",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/tropicalsouthernbbq.com\/",
      "email" : ""
    }
  },
  "thelivingroomaprojectofwestcare" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "",
      "phone" : "",
      "merchantImage" : "thelivingroomaprojectofwestcare",
      "instagramHandle" : " a project of WestCare\"",
      "twitterHandle" : "",
      "merchantHandle" : "thelivingroomaprojectofwestcare",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "(559) 486-1469",
      "merchantName" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/RSaab9udWjzsKFjnajWWmuP0o62gGjcD.png",
      "city" : "",
      "companyName" : "\"The Living Room",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : "https:\/\/www.westcare.com\/page\/where-we-serve_CA_01d"
    }
  },
  "ditto" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/I0PAhk8AEo0bfAiyvycTKkHrFjKfDk4K.png",
      "instagramHandle" : "ditto",
      "twitterHandle" : "",
      "merchantHandle" : "ditto",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Ditto",
      "city" : "Vancouver",
      "companyName" : "Ditto",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "benjerrys" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(253) 858-8575",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Zej3aag3QHNKwMLuYC30sqFoGE1U2DZu.png",
      "instagramHandle" : "benjerrys",
      "twitterHandle" : "",
      "merchantHandle" : "benjerrys",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Ben & Jerry's",
      "city" : "Tacoma",
      "companyName" : "Ben & Jerry's",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.benjerry.com\/gigharbor",
      "email" : ""
    }
  },
  "literaticoffee" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "literaticoffee",
      "merchantHandle" : "literaticoffee",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Literati Coffee",
      "city" : "Ann Arbor",
      "companyName" : "Literati Coffee",
      "websiteUrl" : "http:\/\/www.literaticoffee.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "maxamarket" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/ae06d988fc2a221e42bffc640bbde3e2637f1f40\/original.png",
      "instagramHandle" : "maxamarket",
      "twitterHandle" : "",
      "merchantHandle" : "maxamarket",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "MAXA Market",
      "city" : "Chula Vista",
      "companyName" : "MAXA Market",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "thepalettecaf" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(810) 234-1695",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "thepalettecaf",
      "twitterHandle" : "",
      "merchantHandle" : "thepalettecaf",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Palette Café",
      "city" : "Flint",
      "companyName" : "The Palette Café",
      "websiteUrl" : "https:\/\/flintarts.org\/visit\/cafe",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "radhabitsjuiceco" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(619) 235-8423",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/2537802e2b85d29d3147b243cbe4696620d9d98b\/original.jpeg",
      "instagramHandle" : "radhabitsjuiceco",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "radhabitsjuiceco",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "RAD HABITS Juice Co.",
      "city" : "Chula Vista",
      "companyName" : "RAD HABITS Juice Co.",
      "websiteUrl" : "http:\/\/www.radhabitsjuice.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "newhelvetiabrewingcompany" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 469-9889",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/i8ng7BZ41xVuN5MPUFR5rCmxrD3iD1Ql.png",
      "instagramHandle" : "newhelvetiabrewingcompany",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "newhelvetiabrewingcompany",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "New Helvetia Brewing Company",
      "city" : "Sacramento",
      "companyName" : "New Helvetia Brewing Company",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/newhelvetiabrew.com\/",
      "email" : ""
    }
  },
  "huddlesoftserve" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "huddlesoftserve",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "huddlesoftserve",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Huddle Soft Serve",
      "city" : "Detroit",
      "companyName" : "Huddle Soft Serve",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.huddle-softserve.com\/",
      "email" : ""
    }
  },
  "plantemoran" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(810) 767-5350",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/mQem2Xjva2zZU8a0vORQxkASNQaMgKyN.png",
      "instagramHandle" : "plantemoran",
      "twitterHandle" : "",
      "merchantHandle" : "plantemoran",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Plante Moran",
      "city" : "Flint",
      "companyName" : "Plante Moran",
      "websiteUrl" : "http:\/\/www.plantemoran.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "freshandpressedjuice" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(248) 688-9529",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/peJvqR8q7297n78Y0aYBzQhhKmdCG8Ol.png",
      "instagramHandle" : "freshandpressedjuice",
      "premium" : false,
      "merchantHandle" : "freshandpressedjuice",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Fresh and Pressed Juice",
      "city" : "Warren",
      "companyName" : "Fresh and Pressed Juice",
      "websiteUrl" : "https:\/\/www.freshandpressedjuice.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "starsucculentnursery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 639-4028",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/e2a89a4306b56dab108e8a93293471f8d5b4a175\/original.jpeg",
      "instagramHandle" : "starsucculentnursery",
      "premium" : false,
      "merchantHandle" : "starsucculentnursery",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Star Succulent Nursery",
      "city" : "Stockton",
      "companyName" : "Star Succulent Nursery",
      "websiteUrl" : "http:\/\/starsucculentnursery.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "heavyplaysrestaurantcafe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/5599d52fda0534e3149c0941d9809aac4d546c88\/original.jpeg",
      "instagramHandle" : "heavyplaysrestaurantcafe",
      "twitterHandle" : "",
      "merchantHandle" : "heavyplaysrestaurantcafe",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Heavy Plays Restaurant & Cafe",
      "city" : "Vancouver",
      "companyName" : "Heavy Plays Restaurant & Cafe",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/heavy-plays.com\/",
      "email" : ""
    }
  },
  "milkacoffeeroasters" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/KKMb4ztEROacrsuh6LkBCUpnE0GPUlVk.png",
      "instagramHandle" : "milkacoffeeroasters",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "milkacoffeeroasters",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Milka Coffee Roasters",
      "city" : "Sacramento",
      "companyName" : "Milka Coffee Roasters",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.milkacoffee.com\/",
      "email" : ""
    }
  },
  "mrpicklessandwichshopsacramentoca" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "",
      "phone" : "",
      "merchantImage" : "mrpicklessandwichshopsacramentoca",
      "instagramHandle" : " CA\"",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "mrpicklessandwichshopsacramentoca",
      "companyType" : "(916) 731-4000",
      "squareSandboxKey" : "",
      "merchantName" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/c5bVTw88rpTcUd7fxzdrn0E0f2hFPbgY.png",
      "city" : "",
      "companyName" : "\"Mr. Pickle's Sandwich Shop - Sacramento",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : "https:\/\/www.mrpickles.com\/location\/mr-pickles-sacramento-ca-folsom-blvd\/"
    }
  },
  "nazmobileautodetailing" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(213) 800-1254",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "nazmobileautodetailing",
      "premium" : false,
      "merchantHandle" : "nazmobileautodetailing",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Naz Mobile Auto Detailing",
      "city" : "Moreno Valley",
      "companyName" : "Naz Mobile Auto Detailing",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "cluckkitchen" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/xCywxAlUOG970bQSaxvhrKTB5bF7yLWw.png",
      "instagramHandle" : "cluckkitchen",
      "twitterHandle" : "",
      "merchantHandle" : "cluckkitchen",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Cluck Kitchen",
      "city" : "San Bernardino",
      "companyName" : "Cluck Kitchen",
      "websiteUrl" : "https:\/\/www.cluckkitchen.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "bubblenteaportland" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/I1OckvxsaHXK3jqRXgDTuoOPGNLfNxpm.jpg",
      "instagramHandle" : "bubblenteaportland",
      "merchantHandle" : "bubblenteaportland",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Bubble N Tea Portland",
      "city" : "Portland",
      "companyName" : "Bubble N Tea Portland",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "ideologycoffee" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(818) 937-2534",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/beeb9a47f03f6f4c01307b269d8eaba9b1d37dc9\/original.jpeg",
      "instagramHandle" : "ideologycoffee",
      "premium" : false,
      "merchantHandle" : "ideologycoffee",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Ideology Coffee",
      "city" : "Glendale",
      "companyName" : "Ideology Coffee",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "pizzaboccalupo" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/8faf361240ce9af187b5f0fbe1e5d44c\/original.jpeg",
      "instagramHandle" : "pizzaboccalupo",
      "premium" : false,
      "merchantHandle" : "pizzaboccalupo",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Pizza Bocca Lupo",
      "city" : "San Jose",
      "companyName" : "Pizza Bocca Lupo",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.pizzaboccalupo.com\/",
      "email" : ""
    }
  },
  "homegoods" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(949) 451-0086",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/13f0219399704aea8619885d0e7ccfe91241bde2\/original.jpeg",
      "instagramHandle" : "homegoods",
      "merchantHandle" : "homegoods",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "HomeGoods",
      "city" : "Irvine",
      "companyName" : "HomeGoods",
      "websiteUrl" : "http:\/\/www.homegoods.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "mikenikishoneycompany" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(408) 641-7440",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/rOf6cxXa2jPbGdSCF3lyeoawdw98oaZw.png",
      "instagramHandle" : "mikenikishoneycompany",
      "merchantHandle" : "mikenikishoneycompany",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Mike & Niki’s Honey Company",
      "city" : "San Jose",
      "companyName" : "Mike & Niki’s Honey Company",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.mikeandnikishoney.com\/",
      "email" : ""
    }
  },
  "happycakeco" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(509) 924-8455",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/bdfc9ee9a5ebbec0689145e319b02f17337df59c\/original.jpeg",
      "instagramHandle" : "happycakeco",
      "merchantHandle" : "happycakeco",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Happy Cake Co.",
      "city" : "Spokane",
      "companyName" : "Happy Cake Co.",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/happycakeco.com\/",
      "email" : ""
    }
  },
  "burmabear" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 338-6035",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "burmabear",
      "merchantHandle" : "burmabear",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Burma Bear",
      "city" : "Oakland",
      "companyName" : "Burma Bear",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thefatladybarrestaurant" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 465-4996",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/sdkSZ9s9b9pdHDXQgmq2BDdq4lYmO7sd.png",
      "instagramHandle" : "thefatladybarrestaurant",
      "twitterHandle" : "",
      "merchantHandle" : "thefatladybarrestaurant",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Fat Lady Bar & Restaurant",
      "city" : "Oakland",
      "companyName" : "The Fat Lady Bar & Restaurant",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.thefatladyrestaurant.com\/",
      "email" : ""
    }
  },
  "storytellersbreweryandmeethousellc" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "",
      "phone" : "",
      "merchantImage" : "storytellersbreweryandmeethousellc",
      "instagramHandle" : " LLC\"",
      "twitterHandle" : "",
      "merchantHandle" : "storytellersbreweryandmeethousellc",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "(951) 475-7636",
      "squareSandboxKey" : "",
      "merchantName" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/lUBQo9LpD5Nfr7hnWWO7kXacwgLDU5we.png",
      "city" : "",
      "companyName" : "\"Storytellers Brewery and Meet House",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : "http:\/\/www.storytellersbrewery.com\/"
    }
  },
  "henryhigginsboiledbagels" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(503) 242-0055",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "henryhigginsboiledbagels",
      "twitterHandle" : "",
      "merchantHandle" : "henryhigginsboiledbagels",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Henry Higgins Boiled Bagels",
      "city" : "Portland",
      "companyName" : "Henry Higgins Boiled Bagels",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/hhboiledbagels.com\/henryhigginsboiledbagels23ave",
      "email" : ""
    }
  },
  "hyperioncoffeecoannarbor" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(734) 510-0398",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "hyperioncoffeecoannarbor",
      "merchantHandle" : "hyperioncoffeecoannarbor",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Hyperion Coffee Co - Ann Arbor",
      "city" : "Ann Arbor",
      "companyName" : "Hyperion Coffee Co - Ann Arbor",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/hyperioncoffee.com\/",
      "email" : ""
    }
  },
  "tcoffeetea" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 475-8854",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/mXJyaPAC0amA0K9GaDLE6NMiaB7Pgasb.png",
      "instagramHandle" : "tcoffeetea",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "tcoffeetea",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "T% Coffee + Tea",
      "city" : "Stockton",
      "companyName" : "T% Coffee + Tea",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/teapercent.com\/",
      "email" : ""
    }
  },
  "eyeofcat" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(562) 438-3569",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/GER8Q8GXF3wF7wugYw01s03WBBx1GdTi.png",
      "instagramHandle" : "eyeofcat",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "eyeofcat",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Eye of the Cat‎",
      "city" : "Long Beach",
      "companyName" : "Eye of the Cat‎",
      "websiteUrl" : "https:\/\/www.theeyeofthecat.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "harmonyteabar" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(949) 215-3366",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/75ebab8abada382d76fe0c40915f5025b189460b\/original.jpeg",
      "instagramHandle" : "harmonyteabar",
      "premium" : false,
      "merchantHandle" : "harmonyteabar",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Harmony Tea Bar",
      "city" : "Irvine",
      "companyName" : "Harmony Tea Bar",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.harmonyteabar.com\/",
      "email" : ""
    }
  },
  "lahuertainternationalmarket" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(253) 474-1645",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/65983910470fe91db8dcffd8063f87ac3afd3f52\/original.png",
      "instagramHandle" : "lahuertainternationalmarket",
      "merchantHandle" : "lahuertainternationalmarket",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "La Huerta 2 International Market",
      "city" : "Tacoma",
      "companyName" : "La Huerta 2 International Market",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.lahuertamarket.com\/",
      "email" : ""
    }
  },
  "doggosgus" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(760) 427-2409",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/UkQEHRPyn0XbRCTJb4Yg3NDRRUJhn8Wb.png",
      "instagramHandle" : "doggosgus",
      "merchantHandle" : "doggosgus",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Doggos Gus",
      "city" : "Chula Vista",
      "companyName" : "Doggos Gus",
      "websiteUrl" : "http:\/\/www.doggosgus.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "steelheadcoffee" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(562) 317-5700",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/106a4c47320f973ae66743f6c5bfcec489ee8f30\/original.png",
      "instagramHandle" : "steelheadcoffee",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "steelheadcoffee",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Steelhead Coffee",
      "city" : "Long Beach",
      "companyName" : "Steelhead Coffee",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/steelheadcoffee.com\/",
      "email" : ""
    }
  },
  "thegrowersdepot" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 381-9992",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "thegrowersdepot",
      "twitterHandle" : "",
      "merchantHandle" : "thegrowersdepot",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Grower's Depot",
      "city" : "San Bernardino",
      "companyName" : "The Grower's Depot",
      "websiteUrl" : "http:\/\/www.thegrowersdepot.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "revelcoffeeroasting" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(509) 280-0518",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/GQy5RQXEbf4gnMaXDbVe8cvDdPIUZvh0.png",
      "instagramHandle" : "revelcoffeeroasting",
      "premium" : false,
      "merchantHandle" : "revelcoffeeroasting",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Revel 77 Coffee Roasting",
      "city" : "Spokane",
      "companyName" : "Revel 77 Coffee Roasting",
      "websiteUrl" : "http:\/\/www.revel77coffee.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "campcolvosbrewingpizzaco" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(253) 314-5704",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/DyVgbsquVlaZSZnVnfNISpoDb8aWLJdP.png",
      "instagramHandle" : "campcolvosbrewingpizzaco",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "campcolvosbrewingpizzaco",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Camp Colvos Brewing + Pizza Co.",
      "city" : "Tacoma",
      "companyName" : "Camp Colvos Brewing + Pizza Co.",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/campcolvos.com\/",
      "email" : ""
    }
  },
  "oranges" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 376-0494",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/pWwBkkfQWLbQahCe2L7VzMr30mRLyNjK.jpeg",
      "instagramHandle" : "oranges",
      "premium" : false,
      "merchantHandle" : "oranges",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Oranges",
      "city" : "Riverside",
      "companyName" : "Oranges",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "buddyschickenwaffles" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(253) 201-1550",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/CATmaqIKpyCGa4pj7seELKOf14noJzGf.png",
      "instagramHandle" : "buddyschickenwaffles",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "buddyschickenwaffles",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Buddy's Chicken & Waffles",
      "city" : "Tacoma",
      "companyName" : "Buddy's Chicken & Waffles",
      "websiteUrl" : "http:\/\/buddyschickenandwaffles.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "wolfeclubcoffeeroasters" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(253) 328-7117",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/aVs0WFKzjxTLeF7wC9TSYufxF504e0XV.png",
      "instagramHandle" : "wolfeclubcoffeeroasters",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "wolfeclubcoffeeroasters",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Wolfe Club Coffee Roasters",
      "city" : "Tacoma",
      "companyName" : "Wolfe Club Coffee Roasters",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/wolfeclub.com\/",
      "email" : ""
    }
  },
  "pokeramen" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(805) 246-5541",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/L6v0Dtg5GpaprfCGo8YnJK5P75R2xwzc.png",
      "instagramHandle" : "pokeramen",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "pokeramen",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Poke Ramen",
      "city" : "Oxnard",
      "companyName" : "Poke Ramen",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.pokeramen.com\/",
      "email" : ""
    }
  },
  "maxisrestaurant" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 325-8022",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Zwn7AnZUDolV6x5hMyOsitRAN9by1pTg.png",
      "instagramHandle" : "maxisrestaurant",
      "twitterHandle" : "",
      "merchantHandle" : "maxisrestaurant",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Maxi's Restaurant",
      "city" : "Modesto",
      "companyName" : "Maxi's Restaurant",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "bobaguyssantanarow" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/iXf5pdtCLQ7y9S5sv1jL7OY21ym5LzrO.png",
      "instagramHandle" : "bobaguyssantanarow",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "bobaguyssantanarow",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Boba Guys Santana Row",
      "city" : "San Jose",
      "companyName" : "Boba Guys Santana Row",
      "websiteUrl" : "https:\/\/www.bobaguys.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "frostyboyofcascade" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(616) 647-5652",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/P5eY7hfS0pcTymKyYWlt99d3e8LSCAG4.png",
      "instagramHandle" : "frostyboyofcascade",
      "twitterHandle" : "",
      "merchantHandle" : "frostyboyofcascade",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Frosty Boy of Cascade",
      "city" : "Grand Rapids",
      "companyName" : "Frosty Boy of Cascade",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.frostyboyofcascade.com\/",
      "email" : ""
    }
  },
  "pricklypearsouthwestcafe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(734) 930-0047",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/4d0f5f5491ebe5b67a1490b79d48998046617ad1\/original.jpeg",
      "instagramHandle" : "pricklypearsouthwestcafe",
      "merchantHandle" : "pricklypearsouthwestcafe",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Prickly Pear Southwest Cafe",
      "city" : "Ann Arbor",
      "companyName" : "Prickly Pear Southwest Cafe",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "lionshotdogshack" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(916) 228-4610",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/iw7mU6IdTzXWOndv2pTxlvwwdjHYjYtG.png",
      "instagramHandle" : "lionshotdogshack",
      "twitterHandle" : "",
      "merchantHandle" : "lionshotdogshack",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Lions Hotdog Shack",
      "city" : "Sacramento",
      "companyName" : "Lions Hotdog Shack",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.lionshotdogshack.com\/",
      "email" : ""
    }
  },
  "mrdewiescashewcreamery" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(510) 858-5991",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/aekSUrJ0HpK618xkipTPWbAix6KydVow.png",
      "instagramHandle" : "mrdewiescashewcreamery",
      "premium" : false,
      "merchantHandle" : "mrdewiescashewcreamery",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Mr. Dewie's Cashew Creamery",
      "city" : "Oakland",
      "companyName" : "Mr. Dewie's Cashew Creamery",
      "websiteUrl" : "http:\/\/www.mrdewies.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thenutritionfix" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 451-0419",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/9ff32b4777a8eebd0e6abc475c5728542e294413\/original.png",
      "instagramHandle" : "thenutritionfix",
      "merchantHandle" : "thenutritionfix",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Nutrition Fix",
      "city" : "Stockton",
      "companyName" : "The Nutrition Fix",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.instagram.com\/thenutritionfix_",
      "email" : ""
    }
  },
  "zestygyros" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 259-8724",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "zestygyros",
      "merchantHandle" : "zestygyros",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Zesty Gyros",
      "city" : "Grand Rapids",
      "companyName" : "Zesty Gyros",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/facebook.com\/Zesty-Gyros-2414545342110458\/",
      "email" : ""
    }
  },
  "wildcarvery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(818) 748-3404",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/F3jZCFiSX6zgAnN43tZhll8tc2ZhXVGI.png",
      "instagramHandle" : "wildcarvery",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "wildcarvery",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Wild Carvery",
      "city" : "Glendale",
      "companyName" : "Wild Carvery",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.wildcarvery.com\/",
      "email" : ""
    }
  },
  "theyouthconnection" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(313) 826-7099",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "theyouthconnection",
      "premium" : false,
      "merchantHandle" : "theyouthconnection",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Youth Connection",
      "city" : "Detroit",
      "companyName" : "The Youth Connection",
      "websiteUrl" : "http:\/\/www.theyouthconnection.org\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "unoteahouse" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(562) 902-0100",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/cadb08ada492ee36b28aae1dfdffc998766602cf\/original.jpeg",
      "instagramHandle" : "unoteahouse",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "unoteahouse",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Uno Tea House",
      "city" : "Anaheim",
      "companyName" : "Uno Tea House",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "cafgabriela" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(510) 763-2233",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/yC8BurjCF7NjobqdYaeVU31e5DuJW9FQ.png",
      "instagramHandle" : "cafgabriela",
      "twitterHandle" : "",
      "merchantHandle" : "cafgabriela",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Café Gabriela",
      "city" : "Oakland",
      "companyName" : "Café Gabriela",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "vidaeatery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(661) 573-3202",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/qkKIy6npFqTOCZwfsmwEWh0IWX9FUAo6.png",
      "instagramHandle" : "vidaeatery",
      "premium" : false,
      "merchantHandle" : "vidaeatery",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Vida Eatery",
      "city" : "Bakersfield",
      "companyName" : "Vida Eatery",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/vidavegan.co\/",
      "email" : ""
    }
  },
  "lottatreatscookieco" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 391-2187",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/9a3d788a1f80206a3591e2626bc4356cad496e17\/original.jpeg",
      "instagramHandle" : "lottatreatscookieco",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "lottatreatscookieco",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "LottaTreats Cookie Co",
      "city" : "Huntington Beach",
      "companyName" : "LottaTreats Cookie Co",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/lottatreats.com\/",
      "email" : ""
    }
  },
  "lacocinaricarestaurant" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(805) 488-7878",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Es7RVvr74F2zgbDnb0xV5kUxBt688yuT.png",
      "instagramHandle" : "lacocinaricarestaurant",
      "merchantHandle" : "lacocinaricarestaurant",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "La Cocina Rica #2 Restaurant",
      "city" : "Oxnard",
      "companyName" : "La Cocina Rica #2 Restaurant",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "westoaknosh" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 224-8157",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/c7679fc29dd04e31313311d7558057411e30ae1b\/original.jpeg",
      "instagramHandle" : "westoaknosh",
      "twitterHandle" : "",
      "merchantHandle" : "westoaknosh",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "West Oak Nosh",
      "city" : "Stockton",
      "companyName" : "West Oak Nosh",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/westoaknosh.hrpos.heartland.us\/menu",
      "email" : ""
    }
  },
  "dolcesiciliacafe" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(408) 882-3953",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/SS7fb0z0MDf2MbrZVlNtW9NYrKVuco9y.png",
      "instagramHandle" : "dolcesiciliacafe",
      "premium" : false,
      "merchantHandle" : "dolcesiciliacafe",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Dolce Sicilia Cafe",
      "city" : "San Jose",
      "companyName" : "Dolce Sicilia Cafe",
      "websiteUrl" : "http:\/\/www.dolcesicilia.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "furniturecitycreamery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 920-0752",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Wsd5Wz7JPIsFwv0KQvRYv7ObrBb80nVX.png",
      "instagramHandle" : "furniturecitycreamery",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "furniturecitycreamery",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Furniture City Creamery",
      "city" : "Grand Rapids",
      "companyName" : "Furniture City Creamery",
      "websiteUrl" : "http:\/\/furniturecitycreamery.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "stirrednotshakencookies" : {
    "info" : {
      "state" : "",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "stirrednotshakencookies",
      "instagramHandle" : " Not Shaken Cookies\"",
      "merchantHandle" : "stirrednotshakencookies",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "(530) 723-6001",
      "merchantName" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/YICtXrgrHFY6fRXZNEkhx0FYmQyf48Mb.png",
      "city" : "",
      "companyName" : "\"Stirred",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : "http:\/\/stirredcookies.com\/"
    }
  },
  "lklpackinginc" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(313) 833-1590",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/BymgB5aqSPGV1nQUzDMb6UxXdbMcn9BU.png",
      "instagramHandle" : "lklpackinginc",
      "premium" : false,
      "merchantHandle" : "lklpackinginc",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "LKL Packing Inc",
      "city" : "Detroit",
      "companyName" : "LKL Packing Inc",
      "websiteUrl" : "http:\/\/smithmeatpacking.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "lombardospizzaofwyoming" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 534-5600",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "lombardospizzaofwyoming",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "lombardospizzaofwyoming",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Lombardo's Pizza of Wyoming",
      "city" : "Grand Rapids",
      "companyName" : "Lombardo's Pizza of Wyoming",
      "websiteUrl" : "http:\/\/mylombardos.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "kumacaf" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(619) 236-8400",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "kumacaf",
      "merchantHandle" : "kumacaf",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Kuma Café",
      "city" : "Chula Vista",
      "companyName" : "Kuma Café",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.kumacafesd.com\/",
      "email" : ""
    }
  },
  "detroitpizzabar" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(313) 646-4601",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/1nxd2F0KfeL9fMTsgfx6XtyX2N9hKfuw.png",
      "instagramHandle" : "detroitpizzabar",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "detroitpizzabar",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Detroit Pizza Bar",
      "city" : "Detroit",
      "companyName" : "Detroit Pizza Bar",
      "websiteUrl" : "https:\/\/thedetroitpizzabar.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "morningritualcoffeebar" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 272-4970",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/a0685db200b2563e8bc99be7a2051f406bda6b66\/original.jpeg",
      "instagramHandle" : "morningritualcoffeebar",
      "premium" : false,
      "merchantHandle" : "morningritualcoffeebar",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Morning Ritual Coffee Bar",
      "city" : "Grand Rapids",
      "companyName" : "Morning Ritual Coffee Bar",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.morningritualgr.com\/",
      "email" : ""
    }
  },
  "thehouseoframenpdx" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(971) 279-5757",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/stwYzjzutwPgcL064AxAerEBPWB0fW0O.png",
      "instagramHandle" : "thehouseoframenpdx",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "thehouseoframenpdx",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The House of Ramen PDX",
      "city" : "Portland",
      "companyName" : "The House of Ramen PDX",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.skiplinow.com\/shop\/396352g",
      "email" : ""
    }
  },
  "lebonmacaron" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(616) 706-0183",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/1JKaLgiaDOAy7GLYv3TMJHsfkFbKOTdJ.png",
      "instagramHandle" : "lebonmacaron",
      "twitterHandle" : "",
      "merchantHandle" : "lebonmacaron",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Le Bon Macaron",
      "city" : "Grand Rapids",
      "companyName" : "Le Bon Macaron",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.lebonmacaron.com\/",
      "email" : ""
    }
  },
  "thebarbank" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(818) 641-8561",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/nkKRcp2HKm0MghVK2nFejt8a2QH5WzpK.png",
      "instagramHandle" : "thebarbank",
      "merchantHandle" : "thebarbank",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Bar Bank",
      "city" : "Chula Vista",
      "companyName" : "The Bar Bank",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/thebarbank.com\/",
      "email" : ""
    }
  },
  "orangeshowfoodmart" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 885-1175",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/pWwBkkfQWLbQahCe2L7VzMr30mRLyNjK.jpeg",
      "instagramHandle" : "orangeshowfoodmart",
      "merchantHandle" : "orangeshowfoodmart",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Orange Show Food Mart",
      "city" : "San Bernardino",
      "companyName" : "Orange Show Food Mart",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "cestlavie" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(360) 553-5836",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/5tbn9vyPWAgAuCXBbXXBilaAZXu1R7nT.png",
      "instagramHandle" : "cestlavie",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "cestlavie",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "C'est La Vie",
      "city" : "Vancouver",
      "companyName" : "C'est La Vie",
      "websiteUrl" : "http:\/\/www.cestlaviebistro.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "pdxcookieco" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(971) 427-2300",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/324eb5d5d4640425f039017f8943ff2120ee1176\/original.png",
      "instagramHandle" : "pdxcookieco",
      "merchantHandle" : "pdxcookieco",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "PDX Cookie Co",
      "city" : "Portland",
      "companyName" : "PDX Cookie Co",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/pdxcookieco.com\/",
      "email" : ""
    }
  },
  "hamburgersall" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(519) 915-0667",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/bbe89c143a0fc8737d84147df6a7513db6fd07e7\/original.jpeg",
      "instagramHandle" : "hamburgersall",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "hamburgersall",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Hamburgers 4 All",
      "city" : "Detroit",
      "companyName" : "Hamburgers 4 All",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "familydealbins" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "familydealbins",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "familydealbins",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Family Deal Bins",
      "city" : "Warren",
      "companyName" : "Family Deal Bins",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "eversollie" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(810) 202-4966",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/02b26c0ea3887f455b970b308b7de2f7207775f9\/original.png",
      "instagramHandle" : "eversollie",
      "merchantHandle" : "eversollie",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Evers & Ollie",
      "city" : "Flint",
      "companyName" : "Evers & Ollie",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.eversandollie.com\/",
      "email" : ""
    }
  },
  "chefbecky" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(541) 510-0326",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "chefbecky",
      "twitterHandle" : "",
      "merchantHandle" : "chefbecky",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Chef Becky",
      "city" : "Eugene",
      "companyName" : "Chef Becky",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.chefbecky.com\/",
      "email" : ""
    }
  },
  "tacovado" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(509) 290-6221",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ZCOjNH7v5LgoOhnCM2p3xCN7qfe5PaFt.png",
      "instagramHandle" : "tacovado",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "tacovado",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Taco Vado",
      "city" : "Spokane",
      "companyName" : "Taco Vado",
      "websiteUrl" : "https:\/\/tacovado.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "barefootcoffeeroasters" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(408) 293-7200",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/a8faa247763ea86f14c2ccc4699b20cee2f9e404\/original.jpeg",
      "instagramHandle" : "barefootcoffeeroasters",
      "twitterHandle" : "",
      "merchantHandle" : "barefootcoffeeroasters",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Barefoot Coffee Roasters",
      "city" : "San Jose",
      "companyName" : "Barefoot Coffee Roasters",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.barefootcoffee.com\/",
      "email" : ""
    }
  },
  "fukurouramen" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(323) 719-9011",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/ARamSe3SvYrVVzdPHz1SQxiGDXWYFo9y.png",
      "instagramHandle" : "fukurouramen",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "fukurouramen",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Fukurou RAMEN",
      "city" : "Glendale",
      "companyName" : "Fukurou RAMEN",
      "websiteUrl" : "http:\/\/fukurouramen.com\/index.php",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "bikesandcoffee" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(313) 288-0201",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/4uo9rQzsjaRpbAogMZLA2nxgrV0ZJeVa.png",
      "instagramHandle" : "bikesandcoffee",
      "premium" : false,
      "merchantHandle" : "bikesandcoffee",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Bikes And Coffee",
      "city" : "Detroit",
      "companyName" : "Bikes And Coffee",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.bikes-coffee.com\/",
      "email" : ""
    }
  },
  "oldnickspub" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(541) 844-1280",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/aAvFhEw057uAJlpEsF4GUcTIe7Zg7szS.png",
      "instagramHandle" : "oldnickspub",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "oldnickspub",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Old Nick's Pub",
      "city" : "Eugene",
      "companyName" : "Old Nick's Pub",
      "websiteUrl" : "http:\/\/oldnickspub.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "eugenestation" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/YkfnE6CTF1xaZfiXI4yH2tlUuwhFEyIq.png",
      "instagramHandle" : "eugenestation",
      "merchantHandle" : "eugenestation",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Eugene Station",
      "city" : "Eugene",
      "companyName" : "Eugene Station",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.ltd.org\/eugene-station\/",
      "email" : ""
    }
  },
  "nekterjuicebar" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(209) 636-4694",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/bXQ5rlwOGGPJPSdBsXdesa0YhTgeBBm8.png",
      "instagramHandle" : "nekterjuicebar",
      "twitterHandle" : "",
      "merchantHandle" : "nekterjuicebar",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Nekter Juice Bar",
      "city" : "Stockton",
      "companyName" : "Nekter Juice Bar",
      "websiteUrl" : "https:\/\/locations.nekterjuicebar.com\/ll\/us\/ca\/stockton\/211-lincoln-center\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "dragonflyretreatspokane" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/1ebbf5c566375ea5e3b719a4b715e1251bc05e84\/original.jpeg",
      "instagramHandle" : "dragonflyretreatspokane",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "dragonflyretreatspokane",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Dragonfly Retreat Spokane",
      "city" : "Spokane",
      "companyName" : "Dragonfly Retreat Spokane",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.facebook.com\/dragonflyretreatspokane",
      "email" : ""
    }
  },
  "irvingstreettapas" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(503) 564-8977",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/UkSSCjNmxg0Ki84UcLdqozRs0nPbZDbd.png",
      "instagramHandle" : "irvingstreettapas",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "irvingstreettapas",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Irving Street Tapas",
      "city" : "Portland",
      "companyName" : "Irving Street Tapas",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.irvingstreettapas.com\/",
      "email" : ""
    }
  },
  "cornerstonefellowshipbiblechurch" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(951) 781-8174",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/12091c7d782ed1eb9a0e1191da75b7d4dfeadc97\/original.png",
      "instagramHandle" : "cornerstonefellowshipbiblechurch",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "cornerstonefellowshipbiblechurch",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Cornerstone Fellowship Bible Church",
      "city" : "Riverside",
      "companyName" : "Cornerstone Fellowship Bible Church",
      "websiteUrl" : "http:\/\/www.cornerstonebible.org\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "crystalsaestheticsandbodycontouring" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(616) 277-8822",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/k7N2pWrjMbGrCueRHmFIHrU16oU1xO5M.png",
      "instagramHandle" : "crystalsaestheticsandbodycontouring",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "crystalsaestheticsandbodycontouring",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Crystal's Aesthetics and Body Contouring",
      "city" : "Grand Rapids",
      "companyName" : "Crystal's Aesthetics and Body Contouring",
      "websiteUrl" : "http:\/\/www.crystalsaesthetics.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "everybodyeats" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/bftaSIkwx0w6QkouLVC7n2aQec9Ld2bp.png",
      "instagramHandle" : "everybodyeats",
      "premium" : false,
      "merchantHandle" : "everybodyeats",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "everybody eats",
      "city" : "Portland",
      "companyName" : "everybody eats",
      "websiteUrl" : "https:\/\/everybodyeats503.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "rudyssteakhouse" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "OR\r",
      "phone" : "(503) 399-0449",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/EkWEtIX26v5XMdaOiOc2781zH9xVT00J.png",
      "instagramHandle" : "rudyssteakhouse",
      "merchantHandle" : "rudyssteakhouse",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Rudy's Steakhouse",
      "city" : "Salem",
      "companyName" : "Rudy's Steakhouse",
      "websiteUrl" : "http:\/\/www.rudyssteakhouse.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "coffeedoserodeo" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 414-6808",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/7HCSONtSo9GL5270OQ0t0H61HO6VDS0V.png",
      "instagramHandle" : "coffeedoserodeo",
      "premium" : false,
      "merchantHandle" : "coffeedoserodeo",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Coffee Dose Rodeo",
      "city" : "Huntington Beach",
      "companyName" : "Coffee Dose Rodeo",
      "websiteUrl" : "http:\/\/coffeedose.cafe\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "blacklabelbrewingcompany" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(509) 822-7436",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/zao6vfrJBguhHKshN1J17XoA0BU2sLVa.png",
      "instagramHandle" : "blacklabelbrewingcompany",
      "twitterHandle" : "",
      "premium" : false,
      "merchantHandle" : "blacklabelbrewingcompany",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Black Label Brewing Company",
      "city" : "Spokane",
      "companyName" : "Black Label Brewing Company",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.blacklabelbrewing.com\/",
      "email" : ""
    }
  },
  "thelumpiacompany" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/Y7ifVAXdvvEvso4RFQaTe6VjadkYfPs1.png",
      "instagramHandle" : "thelumpiacompany",
      "merchantHandle" : "thelumpiacompany",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Lumpia Company",
      "city" : "Oakland",
      "companyName" : "The Lumpia Company",
      "websiteUrl" : "http:\/\/thelumpiacompany.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "bonniesbestcafe" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(661) 323-7224",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "bonniesbestcafe",
      "premium" : false,
      "merchantHandle" : "bonniesbestcafe",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Bonnie's Best Cafe",
      "city" : "Bakersfield",
      "companyName" : "Bonnie's Best Cafe",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.bonniesbestcafe.com\/",
      "email" : ""
    }
  },
  "keithkingassociates" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(313) 446-6885",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "keithkingassociates",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "keithkingassociates",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Keith King & Associates",
      "city" : "Detroit",
      "companyName" : "Keith King & Associates",
      "websiteUrl" : "http:\/\/www.keithkingassociates.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "guacroll" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(541) 393-7624",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/9ff0bf14b854fe7d3d9a68050ee6142ab72ded70\/original.jpeg",
      "instagramHandle" : "guacroll",
      "premium" : false,
      "merchantHandle" : "guacroll",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Guac & Roll",
      "city" : "Eugene",
      "companyName" : "Guac & Roll",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "delinerdscatering" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(949) 229-1728",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/tbXuIzi2OPhb0a2GWpo05oMAC29MNWuW.png",
      "instagramHandle" : "delinerdscatering",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "delinerdscatering",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Deli Nerds Catering",
      "city" : "Santa Ana",
      "companyName" : "Deli Nerds Catering",
      "websiteUrl" : "https:\/\/catering.delinerds.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "michigancenterforholisticmedicine" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(616) 264-6556",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "michigancenterforholisticmedicine",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "michigancenterforholisticmedicine",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Michigan Center for Holistic Medicine",
      "city" : "Grand Rapids",
      "companyName" : "Michigan Center for Holistic Medicine",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/micenterhm.com\/",
      "email" : ""
    }
  },
  "pizzacafela" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(323) 407-6715",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/af98dd8860e86e1364bae966a7e28e5685acf118\/original.jpeg",
      "instagramHandle" : "pizzacafela",
      "merchantHandle" : "pizzacafela",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Pizza Cafe LA",
      "city" : "Glendale",
      "companyName" : "Pizza Cafe LA",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/pizzacafela.com\/",
      "email" : ""
    }
  },
  "youngevity" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(800) 982-3189",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/x05WBMFsNIzDYZrrtblnmAJhwge4vdIn.png",
      "instagramHandle" : "youngevity",
      "merchantHandle" : "youngevity",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Youngevity",
      "city" : "Chula Vista",
      "companyName" : "Youngevity",
      "websiteUrl" : "https:\/\/youngevity.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "cadieuxcafe" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(313) 882-8560",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/zqNibeKIFjVXh09jd6xHFxX1uwT9BA5I.png",
      "instagramHandle" : "cadieuxcafe",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "cadieuxcafe",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Cadieux Cafe",
      "city" : "Warren",
      "companyName" : "Cadieux Cafe",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.cadieuxcafe.com\/",
      "email" : ""
    }
  },
  "forzacoffeecompany" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(509) 703-7240",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/36cabd6234b2df7500eb43ae41454a6665003055\/original.png",
      "instagramHandle" : "forzacoffeecompany",
      "merchantHandle" : "forzacoffeecompany",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Forza Coffee Company",
      "city" : "Spokane",
      "companyName" : "Forza Coffee Company",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "thecupcakeespressobar" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 653-0037",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/2f8e8f3ee848ae32e9514f8e6afcbb1544cb8579\/original.jpeg",
      "instagramHandle" : "thecupcakeespressobar",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "thecupcakeespressobar",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Cupcake & Espresso Bar",
      "city" : "San Bernardino",
      "companyName" : "The Cupcake & Espresso Bar",
      "websiteUrl" : "http:\/\/www.iheartthecb.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "stonekoreankitchen" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 839-4070",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/aEunUXrPHshuK12axOG6o99CGYcSvxNB.png",
      "instagramHandle" : "stonekoreankitchen",
      "premium" : false,
      "merchantHandle" : "stonekoreankitchen",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Stone Korean Kitchen",
      "city" : "San Francisco",
      "companyName" : "Stone Korean Kitchen",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/smorefood.com\/order-online\/stone-korean-kitchen-san-francisco-94111-foutg3no?utm_source=gmb&utm_medium=website",
      "email" : ""
    }
  },
  "youbitegermansausages" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 308-3682",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/EV0S84qbSCXkmaunLramuSRaEa0LIvam.png",
      "instagramHandle" : "youbitegermansausages",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "youbitegermansausages",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "YOUBITE German Sausages",
      "city" : "Oxnard",
      "companyName" : "YOUBITE German Sausages",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.germanbrats.com\/",
      "email" : ""
    }
  },
  "midtownbakery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 345-4025",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/aalY9SfcacDOTaWncYxKeqEW9FP47IZq.png",
      "instagramHandle" : "midtownbakery",
      "premium" : false,
      "merchantHandle" : "midtownbakery",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Midtown Bakery",
      "city" : "Sacramento",
      "companyName" : "Midtown Bakery",
      "websiteUrl" : "http:\/\/midtown-bakery.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "lovinghutvegancuisine" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(408) 229-2795",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/iz3XdO51DGZ5AxGn9tEoY2g5noCKgi15.png",
      "instagramHandle" : "lovinghutvegancuisine",
      "merchantHandle" : "lovinghutvegancuisine",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Loving Hut Vegan Cuisine",
      "city" : "San Jose",
      "companyName" : "Loving Hut Vegan Cuisine",
      "websiteUrl" : "http:\/\/lovinghut.us\/sanjose\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "xgolfgrandville" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(616) 265-5262",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/09e1359e13ec4b539ceaf3ca951ce01bd47015a7\/original.png",
      "instagramHandle" : "xgolfgrandville",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "xgolfgrandville",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "X-Golf Grandville",
      "city" : "Grand Rapids",
      "companyName" : "X-Golf Grandville",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/playxgolf.com\/grandville",
      "email" : ""
    }
  },
  "ubatubaacaithousandoaks" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 444-9525",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/zf52OWOidnsERDg4IiiGdfM5TyCLzf7V.png",
      "instagramHandle" : "ubatubaacaithousandoaks",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "ubatubaacaithousandoaks",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Ubatuba Acai - Thousand Oaks",
      "city" : "Oxnard",
      "companyName" : "Ubatuba Acai - Thousand Oaks",
      "websiteUrl" : "http:\/\/www.ubatubaacai.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "dailydozen" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(586) 552-5039",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/USWG3CfVNJuscK7e0aGdvaWypoZEMuZn.png",
      "instagramHandle" : "dailydozen",
      "merchantHandle" : "dailydozen",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Daily Dozen",
      "city" : "Warren",
      "companyName" : "Daily Dozen",
      "websiteUrl" : "https:\/\/www.dailydozendoughnuts.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "tonysfinefoodsunfi" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(951) 697-0013",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/pWwBkkfQWLbQahCe2L7VzMr30mRLyNjK.jpeg",
      "instagramHandle" : "tonysfinefoodsunfi",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "tonysfinefoodsunfi",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Tony’s Fine Foods\/ UNFI",
      "city" : "Moreno Valley",
      "companyName" : "Tony’s Fine Foods\/ UNFI",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "fusionburger" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(458) 221-1663",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/eae11c92644cb717b35c0383a47c8e71b6b158eb\/original.png",
      "instagramHandle" : "fusionburger",
      "twitterHandle" : "",
      "merchantHandle" : "fusionburger",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Fusion Burger",
      "city" : "Eugene",
      "companyName" : "Fusion Burger",
      "websiteUrl" : "http:\/\/www.fusionburgereugene.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "leashlessbrewing" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(805) 628-9474",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/OGi6xHz2THarFysnFo6pAvlY6UNVtRhV.png",
      "instagramHandle" : "leashlessbrewing",
      "merchantHandle" : "leashlessbrewing",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Leashless Brewing",
      "city" : "Oxnard",
      "companyName" : "Leashless Brewing",
      "websiteUrl" : "http:\/\/leashlessbrewing.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "lospaisanostacos" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/pafCGC4MpaZO0ojqnO1hiyCKSBjKLE2P.png",
      "instagramHandle" : "lospaisanostacos",
      "merchantHandle" : "lospaisanostacos",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "LOS PAISANOS TACO'S",
      "city" : "Fresno",
      "companyName" : "LOS PAISANOS TACO'S",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "thenewtonofypsilanti" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(734) 340-3345",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/ca42bc1d73b107b7199640694ae06e8528310d17\/original.jpeg",
      "instagramHandle" : "thenewtonofypsilanti",
      "twitterHandle" : "",
      "merchantHandle" : "thenewtonofypsilanti",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Newton of Ypsilanti",
      "city" : "Ann Arbor",
      "companyName" : "The Newton of Ypsilanti",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.thenewtonofypsilanti.com\/",
      "email" : ""
    }
  },
  "blissfulblends" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(509) 981-8103",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/0a9a7bffe4d19e530def13becd3f025b80e52f31\/original.png",
      "instagramHandle" : "blissfulblends",
      "merchantHandle" : "blissfulblends",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Blissful Blends",
      "city" : "Spokane",
      "companyName" : "Blissful Blends",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "retailholdingsllc" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(586) 979-9205",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/T06CmkwEEeWRX1swCHCpZjf9IktYFaot.png",
      "instagramHandle" : "retailholdingsllc",
      "premium" : false,
      "merchantHandle" : "retailholdingsllc",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Retail Holdings LLC",
      "city" : "Warren",
      "companyName" : "Retail Holdings LLC",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.toolsandmore.com\/",
      "email" : ""
    }
  },
  "thecaveprimesteak" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(562) 455-4141",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/d81be4a72dce6cc6347e02066c3a52abe6a0c784\/original.jpeg",
      "instagramHandle" : "thecaveprimesteak",
      "merchantHandle" : "thecaveprimesteak",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "The Cave Prime Steak",
      "city" : "Long Beach",
      "companyName" : "The Cave Prime Steak",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.thecaveprimesteak.com\/",
      "email" : ""
    }
  },
  "matchacafemaiko" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 757-0919",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/DJW4PrtuLpL8nhPPg0Nn6AlxugaAelw3.png",
      "instagramHandle" : "matchacafemaiko",
      "premium" : false,
      "merchantHandle" : "matchacafemaiko",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Matcha Cafe Maiko",
      "city" : "San Francisco",
      "companyName" : "Matcha Cafe Maiko",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.matchacafe-maiko.com\/eng\/",
      "email" : ""
    }
  },
  "javagenerations" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(541) 461-1744",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/bW1M1Yw89IWk12pDB9qRlJie5EetFmj7.png",
      "instagramHandle" : "javagenerations",
      "merchantHandle" : "javagenerations",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Java Generations",
      "city" : "Eugene",
      "companyName" : "Java Generations",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "portersrealbarbecue" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(509) 942-9590 ext. 706",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/TJtq5EtUgb8H6N7duE2buR9dT1CseJra.png",
      "instagramHandle" : "portersrealbarbecue",
      "merchantHandle" : "portersrealbarbecue",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Porter's Real Barbecue",
      "city" : "Spokane",
      "companyName" : "Porter's Real Barbecue",
      "websiteUrl" : "http:\/\/portersrealbbq.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "garlanddrinkery" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "WA\r",
      "phone" : "(509) 290-5245",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/9dt9V7Rzo2KC9ret0Ca740YweT6vU7be.png",
      "instagramHandle" : "garlanddrinkery",
      "twitterHandle" : "",
      "merchantHandle" : "garlanddrinkery",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Garland Drinkery",
      "city" : "Spokane",
      "companyName" : "Garland Drinkery",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.facebook.com\/drinkerynation",
      "email" : ""
    }
  },
  "parsnip" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(323) 739-0240",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/5343ddec75832bfb07cb81c4b09e00360d075dbc\/original.png",
      "instagramHandle" : "parsnip",
      "premium" : false,
      "merchantHandle" : "parsnip",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Parsnip",
      "city" : "Glendale",
      "companyName" : "Parsnip",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.parsnipcafe.com\/",
      "email" : ""
    }
  },
  "worldcupofficecoffeeservice" : {
    "info" : {
      "state" : "OR\r",
      "posSystem" : "Square",
      "phone" : "(503) 228-5503",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/sgWTtenlOA7Eud9n0UpHouUa42VvQwvn.png",
      "instagramHandle" : "worldcupofficecoffeeservice",
      "merchantHandle" : "worldcupofficecoffeeservice",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "World Cup Office Coffee Service",
      "city" : "Portland",
      "companyName" : "World Cup Office Coffee Service",
      "websiteUrl" : "http:\/\/www.worldcupcoffee.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "mazevocoffee" : {
    "info" : {
      "state" : "MT\r",
      "posSystem" : "Square",
      "phone" : "(406) 661-5034",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/7e8a9950e569ca75c1b83d6f059afe68fe065f9a\/original.png",
      "instagramHandle" : "mazevocoffee",
      "merchantHandle" : "mazevocoffee",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Mazevo Coffee",
      "city" : "Billings",
      "companyName" : "Mazevo Coffee",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.mazevocoffee.com\/",
      "email" : ""
    }
  },
  "satellitefoodtruck" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/czCG35mhoAaFwM0qlHbyDUN0CxdQJOWN.png",
      "instagramHandle" : "satellitefoodtruck",
      "merchantHandle" : "satellitefoodtruck",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Satellite Food Truck",
      "city" : "Warren",
      "companyName" : "Satellite Food Truck",
      "websiteUrl" : "http:\/\/www.satellitehospitality.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "sprinklesdonuts" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(559) 434-0614",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/3f37cb2c8552ec0ea038b767844b6c2647b94fe9\/original.jpeg",
      "instagramHandle" : "sprinklesdonuts",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "merchantHandle" : "sprinklesdonuts",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Sprinkles Donuts",
      "city" : "Fresno",
      "companyName" : "Sprinkles Donuts",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.sprinklesdonutsfresno.com\/",
      "email" : ""
    }
  },
  "tiffanyco" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(916) 872-2129",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/203c430d587e8d1232db75f2a1330a27a4b288e0\/original.jpeg",
      "instagramHandle" : "tiffanyco",
      "premium" : false,
      "merchantHandle" : "tiffanyco",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Tiffany & Co.",
      "city" : "Sacramento",
      "companyName" : "Tiffany & Co.",
      "websiteUrl" : "https:\/\/www.tiffany.com\/jewelry-stores\/sacramento-westfield-galleria-at-roseville\/?local=true&y_source=1_MTc5OTUxMi03MTUtbG9jYXRpb24ud2Vic2l0ZQ%3D%3D",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "whitneyfarmstead" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/WwruErVmnBa0BDeCAmbAR1jd5ZD0CXgN.png",
      "instagramHandle" : "whitneyfarmstead",
      "merchantHandle" : "whitneyfarmstead",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Whitney Farmstead",
      "city" : "Ann Arbor",
      "companyName" : "Whitney Farmstead",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/whitneyfarmstead.com\/",
      "email" : ""
    }
  },
  "thethaitexoticthaicuisine" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 350-2299",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/9JvZQCP0W57Lr34d3qxlKsGcmeLZNXQ3.png",
      "instagramHandle" : "thethaitexoticthaicuisine",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "thethaitexoticthaicuisine",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Thai T Exotic Thai Cuisine",
      "city" : "Fontana",
      "companyName" : "The Thai T Exotic Thai Cuisine",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.thaitfontana.com\/",
      "email" : ""
    }
  },
  "paderiabakehouse" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(949) 287-8931",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/i9xvsSJEXoRoTIXCshFic6UbaVK5vWUP.png",
      "instagramHandle" : "paderiabakehouse",
      "premium" : false,
      "merchantHandle" : "paderiabakehouse",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Paderia Bakehouse",
      "city" : "Irvine",
      "companyName" : "Paderia Bakehouse",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/paderia.com\/",
      "email" : ""
    }
  },
  "leosoysterbar" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 459-8335",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "leosoysterbar",
      "merchantHandle" : "leosoysterbar",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Leo's Oyster Bar",
      "city" : "San Francisco",
      "companyName" : "Leo's Oyster Bar",
      "websiteUrl" : "https:\/\/www.opentable.com\/leos-oyster-bar",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "winewizards" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 957-7711",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "winewizards",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "winewizards",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Wine Wizards",
      "city" : "Stockton",
      "companyName" : "Wine Wizards",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "lucystailoring" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 374-4560",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/90255126fbf2218f0f0f1deb2c623983a7bd74b6\/original.png",
      "instagramHandle" : "lucystailoring",
      "merchantHandle" : "lucystailoring",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Lucy's Tailoring",
      "city" : "Huntington Beach",
      "companyName" : "Lucy's Tailoring",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "3uilttacoma" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(253) 301-1730",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "3uilttacoma",
      "merchantHandle" : "3uilttacoma",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "3uilt Tacoma",
      "city" : "Tacoma",
      "companyName" : "3uilt Tacoma",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "botanicayemaya" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 429-3288",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/G6lg6bhUMqKSblDZHbeSIXmb9zuNSuPi.png",
      "instagramHandle" : "botanicayemaya",
      "merchantHandle" : "botanicayemaya",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Botanica Yemaya",
      "city" : "Fontana",
      "companyName" : "Botanica Yemaya",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/botanica-yemaya.wix.com\/botanica-yemaya#!home-2\/biwtr",
      "email" : ""
    }
  },
  "grubhub" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/1b841f06eaa56564ca17b9bcaa79642e2c0c700c\/original.png",
      "instagramHandle" : "grubhub",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "grubhub",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Grub Hub",
      "city" : "Modesto",
      "companyName" : "Grub Hub",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "californiabbqchickenkabab" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(519) 962-3017",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/arscN6a9gLWfZNuc1Zqw84BWyFaUZOwQ.png",
      "instagramHandle" : "californiabbqchickenkabab",
      "twitterHandle" : "",
      "merchantHandle" : "californiabbqchickenkabab",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "California BBQ Chicken & Kabab",
      "city" : "Detroit",
      "companyName" : "California BBQ Chicken & Kabab",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "llhawaiianbarbecue" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(619) 216-7788",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/qpCWdyzeTchcet0q0snu4HTIqTd9jrdR.png",
      "instagramHandle" : "llhawaiianbarbecue",
      "merchantHandle" : "llhawaiianbarbecue",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "L&L Hawaiian Barbecue",
      "city" : "Chula Vista",
      "companyName" : "L&L Hawaiian Barbecue",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.eastlakebarbecue.com\/",
      "email" : ""
    }
  },
  "slicepizzaandpies" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/LecdS50S0MGBEOYAuK6jVoLckGHi0HBh.png",
      "instagramHandle" : "slicepizzaandpies",
      "merchantHandle" : "slicepizzaandpies",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Slice Pizza and Pies",
      "city" : "Flint",
      "companyName" : "Slice Pizza and Pies",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/sliceofflint.pizza\/",
      "email" : ""
    }
  },
  "luckyorange" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(323) 391-7791",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/QYH4kwsy3m4fb10wS0aqJwgQ3ToO3DD3.png",
      "instagramHandle" : "luckyorange",
      "merchantHandle" : "luckyorange",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "LUCKYORANGE",
      "city" : "Glendale",
      "companyName" : "LUCKYORANGE",
      "websiteUrl" : "https:\/\/lucky-orange.square.site\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "spacecatvstro" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(248) 268-3211",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/AzTLVaU0CPZpau9pkzAjA4Kv6buWaDd0.png",
      "instagramHandle" : "spacecatvstro",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "spacecatvstro",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Spacecat V-stro",
      "city" : "Warren",
      "companyName" : "Spacecat V-stro",
      "websiteUrl" : "http:\/\/www.spacecatvstro.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "citysmokehouse" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(415) 735-6558",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/NA5cN5f2ZxvFMmNtuYDaBDz0AbLe0e15.png",
      "instagramHandle" : "citysmokehouse",
      "merchantHandle" : "citysmokehouse",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "City Smoke House",
      "city" : "San Francisco",
      "companyName" : "City Smoke House",
      "websiteUrl" : "http:\/\/www.citysmokehousesf.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "lanas" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(510) 250-9240",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/H3UdIH0OiMWKDLjrZdravxRoMQSwLsBe.png",
      "instagramHandle" : "lanas",
      "merchantHandle" : "lanas",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Lana's",
      "city" : "Oakland",
      "companyName" : "Lana's",
      "websiteUrl" : "http:\/\/lanasoakland.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "gburger" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(714) 731-7758",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/e040F4E9nE0HTP62GF3fI0cHab2Kt9Aq.png",
      "instagramHandle" : "gburger",
      "merchantHandle" : "gburger",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "G Burger",
      "city" : "Irvine",
      "companyName" : "G Burger",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.gburger.com\/",
      "email" : ""
    }
  },
  "klutchherbalifenutrition" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(909) 493-1038",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/wJjmIwFeEuWEMNo8c3aShalGlC0C0XBO.png",
      "instagramHandle" : "klutchherbalifenutrition",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "klutchherbalifenutrition",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Klutch (Herbalife) Nutrition",
      "city" : "San Bernardino",
      "companyName" : "Klutch (Herbalife) Nutrition",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "chefchuan" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(949) 553-9988",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/xggNmdmPTaaZlRfam7N9pQ4N00uJpwa7.png",
      "instagramHandle" : "chefchuan",
      "merchantHandle" : "chefchuan",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Chef Chuan",
      "city" : "Irvine",
      "companyName" : "Chef Chuan",
      "websiteUrl" : "https:\/\/www.chefchuanirvine.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "pokionenhalf" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(619) 271-7373",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/6p4z8o00jLOTlVmwPdOs6t0DLqe7NTEl.png",
      "instagramHandle" : "pokionenhalf",
      "merchantHandle" : "pokionenhalf",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Poki One N Half",
      "city" : "Chula Vista",
      "companyName" : "Poki One N Half",
      "websiteUrl" : "https:\/\/www.onenhalf.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "themeekhouse" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(909) 335-8006",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/VfOuQNaGacjGbhRpJrgaVbpFi7YFaoM4.png",
      "instagramHandle" : "themeekhouse",
      "merchantHandle" : "themeekhouse",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "The Meek House",
      "city" : "San Bernardino",
      "companyName" : "The Meek House",
      "websiteUrl" : "http:\/\/meekhousecoffee.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "blueowlcoffee" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(517) 575-6836",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/zyiVgEmhYKazao10asS7IO3QHTH1mem0.png",
      "instagramHandle" : "blueowlcoffee",
      "merchantHandle" : "blueowlcoffee",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Blue Owl Coffee",
      "city" : "Lansing",
      "companyName" : "Blue Owl Coffee",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/blueowlcoffee.net\/",
      "email" : ""
    }
  },
  "nomaddonuts" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(619) 431-5000",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/ce2ad9c45a7545ee6ea8fef518e9b826\/original.jpeg",
      "instagramHandle" : "nomaddonuts",
      "premium" : false,
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "merchantHandle" : "nomaddonuts",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Nomad Donuts",
      "city" : "Chula Vista",
      "companyName" : "Nomad Donuts",
      "websiteUrl" : "http:\/\/nomaddonuts.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "juicecolg" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(408) 819-1588",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/pKmiQyrqBSCQz12bAythGdNXwG7HzXsE.png",
      "instagramHandle" : "juicecolg",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "juicecolg",
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "juice co. LG",
      "city" : "San Jose",
      "companyName" : "juice co. LG",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/juicecolg.com\/",
      "email" : ""
    }
  },
  "dynamicnutrition" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(209) 831-9220",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/fa1a605ad35eea067980900be293419815f8b5a8\/original.png",
      "instagramHandle" : "dynamicnutrition",
      "premium" : false,
      "merchantHandle" : "dynamicnutrition",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Dynamic Nutrition",
      "city" : "Stockton",
      "companyName" : "Dynamic Nutrition",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "healthworkswellnesshealthysmoothiebar" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(586) 243-2268",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/JyNQxjAl0VbAibCfTKBu9A0HzPYG0IEM.png",
      "instagramHandle" : "healthworkswellnesshealthysmoothiebar",
      "premium" : false,
      "twitterHandle" : "",
      "merchantHandle" : "healthworkswellnesshealthysmoothiebar",
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Healthworks Wellness -Healthy Smoothie Bar",
      "city" : "Warren",
      "companyName" : "Healthworks Wellness -Healthy Smoothie Bar",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.facebook.com\/healthworkswellness",
      "email" : ""
    }
  },
  "confidentialcoffee" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/5afdaf7b972d9b17d45707620508df54679b0b01\/original.png",
      "instagramHandle" : "confidentialcoffee",
      "premium" : false,
      "merchantHandle" : "confidentialcoffee",
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Confidential Coffee",
      "city" : "Long Beach",
      "companyName" : "Confidential Coffee",
      "websiteUrl" : "http:\/\/www.confidentialcoffee.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "bustaurant" : {
    "info" : {
      "state" : "MI\r",
      "posSystem" : "Square",
      "phone" : "(734) 934-0969",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/t3bs8EdmHCLjynLdvYCkld8w7nXlDA3Y.png",
      "instagramHandle" : "bustaurant",
      "merchantHandle" : "bustaurant",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "\"1923 \"\"Bustaurant\"\"\"",
      "city" : "Ann Arbor",
      "companyName" : "\"1923 \"\"Bustaurant\"\"\"",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/1923.llc\/",
      "email" : ""
    }
  },
  "espressoelevado" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(734) 904-8323",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/4cb7b40f5709d6d4e21e5a9b2a396d26\/original.jpeg",
      "instagramHandle" : "espressoelevado",
      "merchantHandle" : "espressoelevado",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Espresso Elevado",
      "city" : "Ann Arbor",
      "companyName" : "Espresso Elevado",
      "websiteUrl" : "http:\/\/espressoelevado.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "churriogourmetdessert" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 884-4775",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/R1TFwaVBup0Si2iWI9A7pZT3m06Xu0Nz.png",
      "instagramHandle" : "churriogourmetdessert",
      "twitterHandle" : "",
      "merchantHandle" : "churriogourmetdessert",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Churriño Gourmet Dessert",
      "city" : "Santa Ana",
      "companyName" : "Churriño Gourmet Dessert",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.churrino.com\/",
      "email" : ""
    }
  },
  "bakersdozendonutsdelidelights" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(951) 736-7571",
      "merchantImage" : "https:\/\/square-web-production-f.squarecdn.com\/files\/12b978c33fd979e58e1e0e658b306278d2b7f48b\/original.jpeg",
      "instagramHandle" : "bakersdozendonutsdelidelights",
      "merchantHandle" : "bakersdozendonutsdelidelights",
      "twitterHandle" : "",
      "premium" : false,
      "squareProductionKey" : "",
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Baker's Dozen Donuts Deli & Delights",
      "city" : "San Bernardino",
      "companyName" : "Baker's Dozen Donuts Deli & Delights",
      "websiteUrl" : "http:\/\/bakersdozendonuts.com\/",
      "tikTokHandle" : "",
      "email" : ""
    }
  },
  "templobetel" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "CA\r",
      "phone" : "(714) 973-1025",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/m40YUcclvVuPankwJhmctYpJedFuOGp2.png",
      "instagramHandle" : "templobetel",
      "merchantHandle" : "templobetel",
      "premium" : false,
      "squareProductionKey" : "",
      "twitterHandle" : "",
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Templo Betel",
      "city" : "Huntington Beach",
      "companyName" : "Templo Betel",
      "tikTokHandle" : "",
      "websiteUrl" : "",
      "email" : ""
    }
  },
  "intentionsjuiceandsmoothiebar" : {
    "info" : {
      "state" : "WA\r",
      "posSystem" : "Square",
      "phone" : "(253) 301-4890",
      "merchantImage" : "https:\/\/postoffice-production-f.squarecdn.com\/images\/VK32jKi6l86h6wXj1n2Vib70irHNolLd.png",
      "instagramHandle" : "intentionsjuiceandsmoothiebar",
      "merchantHandle" : "intentionsjuiceandsmoothiebar",
      "twitterHandle" : "",
      "squareProductionKey" : "",
      "premium" : false,
      "squareSandboxKey" : "",
      "companyType" : "",
      "merchantName" : "Intentions Juice and Smoothie Bar",
      "city" : "Tacoma",
      "companyName" : "Intentions Juice and Smoothie Bar",
      "tikTokHandle" : "",
      "websiteUrl" : "http:\/\/www.intentionsjuicebar.com\/",
      "email" : ""
    }
  },
  "checosa" : {
    "info" : {
      "posSystem" : "Square",
      "state" : "MI\r",
      "phone" : "(586) 477-1200",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "checosa",
      "twitterHandle" : "",
      "merchantHandle" : "checosa",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Che Cosa",
      "city" : "Warren",
      "companyName" : "Che Cosa",
      "tikTokHandle" : "",
      "websiteUrl" : "https:\/\/www.checosacoffee.com\/",
      "email" : ""
    }
  },
  "maryspaleteriayneveriatastytraditionalfamilyflavor" : {
    "info" : {
      "state" : "CA\r",
      "posSystem" : "Square",
      "phone" : "(408) 493-5671",
      "merchantImage" : "No Logo Image Found on Website",
      "instagramHandle" : "maryspaleteriayneveriatastytraditionalfamilyflavor",
      "twitterHandle" : "",
      "merchantHandle" : "maryspaleteriayneveriatastytraditionalfamilyflavor",
      "squareProductionKey" : "",
      "premium" : false,
      "companyType" : "",
      "squareSandboxKey" : "",
      "merchantName" : "Mary's Paleteria Y Neveria & Tasty Traditional Family Flavor",
      "city" : "San Jose",
      "companyName" : "Mary's Paleteria Y Neveria & Tasty Traditional Family Flavor",
      "websiteUrl" : "",
      "tikTokHandle" : "",
      "email" : ""
    }
  }
}]