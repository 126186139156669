import React from 'react';



const widthNow = '100%'
const BetaSignUp=()=>(

   


        <>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfEJPdeYgTR1p-DAnBHohXU5N9M49ZX_yUD-Tq1tj4jem5pCQ/viewform?embedded=true" width={widthNow} height="2312" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
        
        </>
    )


export default BetaSignUp;