import React from 'react';
import PropTypes from 'prop-types';
import styles from './Terms.module.css';


const Terms = () => {
  const containerStyle = {
    fontFamily: 'productsans-regular',
    padding: '20px',
    lineHeight: '1.6',
    color: '#333',
    maxWidth: '800px',
    margin: '0 auto',
  };

  const headerStyle = {
    textAlign: 'center',
    marginBottom: '40px',
  };

  const sectionStyle = {
    marginBottom: '20px',
  };

  const titleStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '10px',
  };

  const subtitleStyle = {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '10px',
  };

  const contactInfoStyle = {
    marginTop: '20px',
    fontStyle: 'italic',
  };

  return (
    <div style={containerStyle}>
      <header style={headerStyle}>
        <h1>Terms of Service for CafeText, LLC dba Cafe</h1>
        <p>Last Updated: 1 March 2024</p>
      </header>
      <section style={sectionStyle}>
        <p>
          CafeText, LLC dba Cafe ("Cafe," "we," "our," or "us") is pleased to
          have you here. These Terms of Service ("Terms") govern your use of our
          services, including our website, apps, and related software or
          features (collectively referred to as the "Platform" or "Cafe"). By
          using Cafe, you agree to these Terms, so please read them carefully.
        </p>
      </section>
      <section style={sectionStyle}>
        <h2 style={titleStyle}>1. Welcome to Cafe!</h2>
        <p>
          By using Cafe, you are agreeing to these Terms and our Privacy Policy.
          If you do not agree with any part of these Terms, please do not use
          our services. Feel free to contact us if you have any questions.
        </p>
      </section>
      <section style={sectionStyle}>
        <h2 style={titleStyle}>2. Changes to These Terms</h2>
        <p>
          Cafe is constantly evolving. We may update these Terms from time to
          time to reflect changes in our business, services, or for legal and
          regulatory reasons. We will notify you of any significant changes by
          posting the new Terms on our website and updating the "Last Updated"
          date at the top of these Terms. Your continued use of our services
          after such changes indicates your acceptance of the new Terms.
        </p>
      </section>
      <section style={sectionStyle}>
        <h2 style={titleStyle}>3. Eligibility</h2>
        <p>
          To use Cafe, you must be at least 18 years old. By using our services,
          you confirm that you are of legal age and have the legal capacity to
          enter into a binding contract with us.
        </p>
      </section>
      <section style={sectionStyle}>
        <h2 style={titleStyle}>4. Consent to Receive Messages</h2>
        <p>
          By providing your phone number and using our services, you consent to
          receive text messages, including marketing messages, from Cafe. These
          messages may be sent using automated technology. Your consent to
          receive these messages is not a condition of purchasing any goods or
          services.
        </p>
      </section>
      <section style={sectionStyle}>
        <h2 style={titleStyle}>5. Sharing of Information</h2>
        <p>
          You agree that Cafe may share your personal information with
          third-party partners and affiliates for marketing, promotional, and
          other purposes. By using our services, you consent to such sharing of
          information.
        </p>
      </section>
      <section style={sectionStyle}>
        <h2 style={titleStyle}>6. Your Account</h2>
        <p>
          To create an account with Cafe, you must provide accurate information
          about yourself. You are responsible for maintaining the confidentiality
          of your account credentials and for all activities that occur under
          your account. If you believe your account has been compromised, please
          contact us immediately.
        </p>
      </section>
      <section style={sectionStyle}>
        <h2 style={titleStyle}>7. Content</h2>
        <p>
          You are responsible for any content you post on Cafe. By posting
          content, you represent that you own the rights to the content or have
          permission to use it, and that the content does not violate any laws
          or third-party rights. Cafe reserves the right to remove any content
          that violates these Terms or is deemed inappropriate.
        </p>
      </section>
      <section style={sectionStyle}>
        <h2 style={titleStyle}>8. Termination</h2>
        <p>
          We may terminate or suspend your account at any time if you violate
          these Terms or engage in any conduct that we deem harmful to Cafe or
          our users. You may also terminate your account at any time by
          contacting us.
        </p>
      </section>
      <section style={sectionStyle}>
        <h2 style={titleStyle}>9. Limitation of Liability</h2>
        <p>
          To the fullest extent permitted by law, Cafe shall not be liable for
          any indirect, incidental, special, consequential, or punitive damages,
          or any loss of profits or revenues, whether incurred directly or
          indirectly, or any loss of data, use, goodwill, or other intangible
          losses, resulting from (a) your use or inability to use the services;
          (b) any unauthorized access to or use of our servers and/or any
          personal information stored therein; (c) any interruption or cessation
          of transmission to or from the services; (d) any bugs, viruses, trojan
          horses, or the like that may be transmitted to or through our services
          by any third party; (e) any errors or omissions in any content; and/or
          (f) any defamatory, offensive, or illegal conduct of any third party.
          In no event shall Cafe’s aggregate liability for all claims relating
          to the services exceed the amount you paid Cafe, if any, in the last
          12 months.
        </p>
      </section>
      <section style={sectionStyle}>
        <h2 style={titleStyle}>10. Data Security</h2>
        <p>
          While we strive to protect your personal information, we cannot
          guarantee the security of any information you transmit to Cafe. You
          acknowledge and agree that any information you send to Cafe is at your
          own risk, and Cafe is not responsible for any information being
          breached, accessed without authorization, or otherwise unprotected.
          It is your responsibility to protect your own data by using secure
          passwords and other security measures.
        </p>
      </section>
      <section style={sectionStyle}>
        <h2 style={titleStyle}>11. Governing Law</h2>
        <p>
          These Terms shall be governed by and construed in accordance with the
          laws of the state in which CafeText, LLC is headquartered, without
          regard to its conflict of law principles.
        </p>
      </section>
      <section style={sectionStyle}>
        <h2 style={titleStyle}>12. Contact Information</h2>
        <p style={contactInfoStyle}>
          If you have any questions about these Terms, please contact us at:
        </p>
        <p style={contactInfoStyle}>
          CafeText, LLC
          <br />
          Email: support@cafe.bio
       
        </p>
      </section>
      <footer style={headerStyle}>
        <p>By using Cafe's services, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.</p>
        <p>Last Updated: 1 March 2024</p>
      </footer>
    </div>
  );
};

export default Terms;