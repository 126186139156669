import React from 'react';
import PropTypes from 'prop-types';
import styles from './Footer.module.css';

const currentYear = new Date().getFullYear();
const footerStyle = {
  textAlign: 'center',
  backgroundColor: '#fff',  
  color: '#6c757d'  ,
  fontFamily: 'geologica-regular',
  fontSize: '0.8rem',
  fontStyle: 'normal',
};
const CBFooter = () => (
  
  <div style={footerStyle}>
     <p> Copyright &copy; {currentYear} CafeText, LLC. dba Cafe All rights reserved. </p> 
     <div className='basetext' style={{fontSize:'10px'}}> 
      
      <a href='/terms'>Terms & Conditions</a>  | <a href='/privacypolicy'>Privacy Policy</a>
     
     </div> 
  </div>
);

CBFooter.propTypes = {};

export default CBFooter;

