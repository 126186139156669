export const promoimages=[
    {
        "imagename": "espresso.png",
        "name": "Espresso"
      },
      {
        "imagename": "cookie.png",
        "name": "Cookie"
      },
      {
        "imagename": "croissant.png",
        "name": "Croissant"
      },
      {
        "imagename": "cupofcoffee.png",
        "name": "Cup of Coffee"
      },
      {
        "imagename": "coldbrew.png",
        "name": "Cold Brew"
      },
      {
        "imagename": "cappuccino.png",
        "name": "Cappuccino"
      },
      {
        "imagename": "espressowithwater.png",
        "name": "Espresso with Water"
      },
      {
        "imagename": "latte.png",
        "name": "Latte"
      },
      {
        "imagename": "americano.png",
        "name": "Americano"
      },
      {
        "imagename": "biscotti.png",
        "name": "Biscotti"
      },
      {
        "imagename": "raspberrydanish.png",
        "name": "Raspberry Danish"
      }

]