import React from 'react';

const PrivacyPolicy = () => {
  const containerStyle = {
    fontFamily: 'productsans-regular',
    padding: '20px',
    lineHeight: '1.6',
    color: '#333',
    maxWidth: '800px',
    margin: '0 auto',
  };

  const headerStyle = {
    textAlign: 'center',
    marginBottom: '40px',
  };

  const sectionStyle = {
    marginBottom: '20px',
  };

  const titleStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '10px',
  };

  const contactInfoStyle = {
    marginTop: '20px',
    fontStyle: 'italic',
  };

  return (
    <div style={containerStyle}>
      <header style={headerStyle}>
        <h1>Privacy Policy for CafeText, LLC dba Cafe</h1>
        <p>Last Updated: 1 March 2024</p>
      </header>
      <section style={sectionStyle}>
        <p>
          CafeText, LLC dba Cafe ("Cafe," "we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, share, and protect your personal information when you use our services, including our website, apps, and related software or features (collectively referred to as the "Platform" or "Cafe").
        </p>
      </section>
      <section style={sectionStyle}>
        <h2 style={titleStyle}>1. Information We Collect</h2>
        <p>We may collect the following types of information:</p>
        <ul>
          <li><strong>Personal Information</strong>: Information that can be used to identify you, such as your name, email address, phone number, and payment information.</li>
          <li><strong>Usage Data</strong>: Information about how you use our services, such as your browsing history, search queries, and interactions with our Platform.</li>
          <li><strong>Device Information</strong>: Information about the device you use to access our services, including your IP address, browser type, and operating system.</li>
          <li><strong>Location Data</strong>: Information about your location if you have enabled location services on your device.</li>
        </ul>
      </section>
      <section style={sectionStyle}>
        <h2 style={titleStyle}>2. How We Use Your Information</h2>
        <p>We may use your information for the following purposes:</p>
        <ul>
          <li><strong>To Provide and Improve Our Services</strong>: We use your information to operate, maintain, and improve our services, and to develop new features and functionality.</li>
          <li><strong>To Communicate with You</strong>: We use your information to send you updates, notifications, and other information related to your use of our services. This includes marketing messages if you have consented to receive them.</li>
          <li><strong>To Personalize Your Experience</strong>: We use your information to tailor the content and advertisements you see on our Platform to your interests.</li>
          <li><strong>To Process Transactions</strong>: We use your information to process payments and manage your account.</li>
          <li><strong>To Ensure Security</strong>: We use your information to detect and prevent fraud, abuse, and other harmful activities.</li>
        </ul>
      </section>
      <section style={sectionStyle}>
        <h2 style={titleStyle}>3. Sharing Your Information</h2>
        <p>We may share your information with third parties in the following circumstances:</p>
        <ul>
          <li><strong>With Your Consent</strong>: We may share your information with third parties when you have given us your consent to do so.</li>
          <li><strong>With Service Providers</strong>: We may share your information with third-party service providers who perform services on our behalf, such as payment processing, data analysis, and marketing.</li>
          <li><strong>With Business Partners</strong>: We may share your information with our business partners for marketing, promotional, and other purposes.</li>
          <li><strong>For Legal Reasons</strong>: We may share your information if we believe it is necessary to comply with a legal obligation, protect our rights, or prevent fraud or other illegal activities.</li>
        </ul>
      </section>
      <section style={sectionStyle}>
        <h2 style={titleStyle}>4. Data Security</h2>
        <p>
          While we strive to protect your personal information, we cannot guarantee the security of any information you transmit to Cafe. You acknowledge and agree that any information you send to Cafe is at your own risk, and Cafe is not responsible for any information being breached, accessed without authorization, or otherwise unprotected. It is your responsibility to protect your own data by using secure passwords and other security measures.
        </p>
      </section>
      <section style={sectionStyle}>
        <h2 style={titleStyle}>5. Your Choices</h2>
        <p>You have the following choices regarding your information:</p>
        <ul>
          <li><strong>Access and Update</strong>: You can access and update your personal information by logging into your account and making the necessary changes.</li>
          <li><strong>Opt-Out</strong>: You can opt-out of receiving marketing messages from us by following the instructions in those messages or by contacting us directly.</li>
          <li><strong>Delete</strong>: You can request that we delete your personal information by contacting us. Please note that we may need to retain certain information for legal or business purposes.</li>
        </ul>
      </section>
      <section style={sectionStyle}>
        <h2 style={titleStyle}>6. Children's Privacy</h2>
        <p>
          Our services are not intended for children under the age of 18. We do not knowingly collect personal information from children under 18. If we become aware that we have collected personal information from a child under 18, we will take steps to delete that information.
        </p>
      </section>
      <section style={sectionStyle}>
        <h2 style={titleStyle}>7. Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time to reflect changes in our business, services, or for legal and regulatory reasons. We will notify you of any significant changes by posting the new Privacy Policy on our website and updating the "Last Updated" date at the top of this Privacy Policy. Your continued use of our services after such changes indicates your acceptance of the new Privacy Policy.
        </p>
      </section>
      <section style={sectionStyle}>
        <h2 style={titleStyle}>8. Contact Information</h2>
        <p style={contactInfoStyle}>
          If you have any questions about this Privacy Policy, please contact us at:
        </p>
        <p style={contactInfoStyle}>
          CafeText, LLC
          <br />
          Email: support@cafe.bio
         
        </p>
      </section>
      <footer style={headerStyle}>
        <p>By using Cafe's services, you acknowledge that you have read, understood, and agree to be bound by this Privacy Policy.</p>
      
      </footer>
    </div>
  );
};

export default PrivacyPolicy;