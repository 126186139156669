import React from 'react';
import PropTypes from 'prop-types';
import styles from './FeaturedClients.module.css';
import voyager from '../../images/customer_img/voyager.svg';
import helladac from '../../images/customer_img/helladac.svg';
import brownbear from '../../images/customer_img/brownbear.svg';
import fleetwood from '../../images/customer_img/fleetwood.svg';

const clients = {
  "Voyager" : voyager,
  "Hella Dac Biet" : helladac,
  "Brown Bear's Hotdogs" : brownbear,
  "Mick Fleetwood Coffee" : fleetwood,
};

const FeaturedClients = () => (
  <div className={styles.FeaturedClients}>
    <div className={styles.SectionTitle}>Featured Brands We work with</div>
    <div className={styles.ClientsList}>
      {Object.entries(clients).map(([name, filePath]) => (
        <div key={name} className={styles.Client}>
          <img 
            src={filePath} 
            alt={`${name} logo`} 
            className={styles.ClientImage}
            style={{maxWidth:'75px'}}
          />
          {/* <p className={styles.ClientName}>{name}</p> */}
        </div>
      ))}
    </div>
  </div>
);

FeaturedClients.propTypes = {
  // You can specify any prop types if needed
};

FeaturedClients.defaultProps = {
  // You can specify any default props if needed
};

export default FeaturedClients;

