import React from 'react';
import PropTypes from 'prop-types';
import styles from './CompletionScreen.module.css';
import {Steps} from 'antd-mobile';
import { Result,Button, Space } from 'antd';
import { useParams,useNavigate,Link } from 'react-router-dom';
import { completeScreen } from '../../utils/AppStrings';
import BrandingFooter from '../BrandingFooter/BrandingFooter';

const { Step } = Steps
export default function CompletionScreen () {
  const navigate = useNavigate();
  const routeParams = useParams();
  const offer = "Offer number :"+ routeParams.redeemguid  
  return(
    
  <div className={styles.CompletionScreen}>
    <Steps current={2}>
          <Step title='Select Offer' description=''  className='basefont'  />
          <Step title='Redeem' description=''  className='basefont'  />
          <Step title='Complete' description=''  className='basefont'  />
        </Steps>
        <Result
        className='basefont'
    status="success"
    title={completeScreen.header}
    subTitle={offer}
    extra={[
      <>
      <div  className='basefont' style={{padding:'1rem'}}>
      {completeScreen.subtitle}
      </div>
      <Link to={'https://www.cafe.bio/' + routeParams.merchanthandle }
      className='solidbutton'
      >
      {completeScreen.backToOffers}
      </Link>
      
      </>
    ]}
  />
  <Space/>
  <BrandingFooter/>

  </div>
)};

CompletionScreen.propTypes = {};

CompletionScreen.defaultProps = {};

// export default CompletionScreen;
